<!--
  * @Author: your name
  * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2024-06-12 18:46:41
 * @LastEditors: 冉桂精 156189868@qq.com
  * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\custom-component\bindLatLng\index.vue
 -->
<template>
  <div class="lat-choose-cont">
    <div class="header absolute">
      <div class="relative">
        <el-page-header class="page-header" :content="title" @back="back" />
        <div
          :class="{
            btn: true,
            singleBtn: drawType !== 0,
          }"
        >
          <div
            :class="['select-btn',{
              active: switchPoint === true,
              ponit: +config?.locateType === 0,
              hiddenPoi: +config?.locateType === 2,
            }]"
            @click="handleDrawType('point', true)"
          >
            <img
              :src="
                switchPoint
                  ? 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/3e723d5cba9cd81f079d8b4019cf7288.png'
                  : 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/7cad7dd2c46aae4b63a29376d18a2b38.png'
              "
              alt=""
            />
            {{ $t('bindLatLng.index.774051-0') }}
          </div>
          <div
            :class="{
              active: switchPoint === false,
              hiddenCov: +config?.locateType === 1,
            }"
            @click="handleDrawType('cover', false)"
          >
            <img
              :src="
                switchPoint === false
                  ? 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/cf62016845572053e116d1e7d33341d4.png'
                  : 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/0177145d7d150f70280ee83229e0b852.png'
              "
              alt=""
            />
            {{ $t('bindLatLng.index.774051-1') }}
            <ul
              class="dropdown"
              v-if="componentType === 'latlng' && switchPoint === false"
            >
              <li
                v-for="graph in graphList"
                :key="graph.value"
                :class="{ active: graph.value === graphType }"
                @click="handleCommand(graph.value)"
              >
                <img :src="graph.icon" alt="" /> {{ graph.label }}
              </li>
            </ul>
          </div>
        </div>
        <el-button
          class="absolute"
          size="mini"
          style="top: 8px; right: 78px"
          type="default"
          @click="clearChooseData()"
        >
          {{ $t('bindLatLng.index.774051-2') }}
        </el-button>
        <el-button
          size="mini"
          class="commit-btn absolute"
          type="primary"
          @click="commit()"
        >
          {{ $t('bindLatLng.index.774051-3') }}
        </el-button>
      </div>
    </div>
    <div v-if="showInfoModal && componentType === 'latlng'" class="point-info">
      <div class="info-header">
        <div class="info-header-top">
          <div class="icon">
            <img
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/16c174bf623249a11921137ac92c13bb.png"
              alt=""
            />
          </div>
          <div class="maker">{{ $t('bindLatLng.index.774051-4') }}</div>
          <div class="name">{{ info.city }}</div>
        </div>
        <div class="detail-address">
          {{ info.fullName }}
        </div>
      </div>
      <div class="info-desc">
        <div class="desc">
          <div class="icon">
            <img
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/3a72bcd7ceb0c8375f6891757b1a9e65.png"
              alt=""
            />
          </div>
          <div class="text">{{ $t('bindLatLng.index.774051-5') }}</div>
        </div>
        <div class="item-container">
          <div class="item">{{ $t('bindLatLng.index.774051-6') }}:{{ latLng?.lng }}</div>
          <div class="item">{{ $t('bindLatLng.index.774051-7') }}:{{ latLng?.lat }}</div>
          <div class="item">{{ $t('bindLatLng.index.774051-8') }}:{{ modelsInfo[0]?.A }}</div>
          <div class="item">{{ $t('bindLatLng.index.774051-9') }}:{{ modelsInfo[0]?.B }}</div>
        </div>
      </div>
    </div>
    <div class="w-full h-full" style="flex-direction: columns; display: flex">
      <List
        v-if="componentType === 'gis'"
        class="list-cont scroller"
        ref="listRef"
        :model-data="modelData"
        @show-model="showModel"
        @clear-obk="clearObk"
      />
      <div id="map2dCont" class="relative flex1"></div>
    </div>
    <!-- 搜索 -->
    <div v-show="componentType === 'latlng'" class="bind-search-cont">
      <el-select
        popper-class="bind-select-list"
        v-model="value"
        filterable
        remote
        style="width: 88%"
        reserve-keyword
        :placeholder="$t('bindLatLng.index.774051-10')"
        :loading-text="$t('bindLatLng.index.774051-11')"
        :remote-method="remoteMethod"
        @change="checkList"
        :loading="loading"
        @focus="onFocus"
        @blur="onBlur"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.address"
          :value="item.id"
        >
          <p class="item-desc">
            <img
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/d3173395c39d6ac8dc0e0799378b30db.png"
              alt=""
            />
            <span class="item-address">{{ item.address }}</span>
            <span class="item-adname">{{ item.adname }}</span>
          </p>
        </el-option>
      </el-select>
      <el-button
        class="search-btn"
        slot="append"
        icon="el-icon-search"
        type="primary"
      ></el-button>
    </div>
  </div>
</template>

<script>
import {formRequest} from "@/apis/home";
import List from "@/custom-component/bindLatLng/modelList.vue";
import { PageHeader } from "element-ui";
let viewerGis;
export default {
  name: "Bind",
  components: {
    "el-page-header": PageHeader,
    List,
  },
  props: {
    modelData: {
      type: [Object, Array],
      default: null,
    },
    componentType: {
      type: String,
      default: "",
    },
    appId: {
      type: String,
      default: "",
    },
    appSecret: {
      type: String,
      default: "",
    },
    drawType: {
      type: Number,
      default: 0,
    },
		config:{
			type: Object,
      default: null,
		}
  },
  data() {
    return {
      latLng: null,
      info: {
        area: "",
        fullName: "",
        address: "",
      },
      switchPoint: null,
      loading: false,
      // 模型绑定数据
      modelsInfo: [
        {
          model_id: "",
          select: [],
          polygon: [],
          location_gis: null,
          location_name: "",
          A: "",
          B: "",
        },
      ],
      chooseType: "",
      options: [],
      value: "",
      showInfoModal: false,
      graphList: [
        {
          label: this.$t('bindLatLng.index.774051-12'),
          value: "rectangle",
          icon: "https://openim-1309784708.cos.ap-shanghai.myqcloud.com/5181caffdead86d8b0136b49fc82a8ec.png",
        },
        {
          label: this.$t('bindLatLng.index.774051-13'),
          value: "circular",
          icon: "https://openim-1309784708.cos.ap-shanghai.myqcloud.com/45eb1b20f8ab8d1338617648b0eaf5e7.png",
        },
        {
          label: this.$t('bindLatLng.index.774051-14'),
          value: "polygon",
          icon: "https://openim-1309784708.cos.ap-shanghai.myqcloud.com/c6b7bfe693faaea34a60b3f40fc032b7.png",
        },
      ],
      graphType: "polygon",
    };
  },
  computed: {
    title() {
      if (this.componentType === "latlng") {
        return this.$t('bindLatLng.index.774051-15');
      } else if (this.componentType === "gis") {
        return this.$t('bindLatLng.index.774051-16');
      } else {
        return "";
      }
    },
  },
  watch: {
    componentType: {
      handler: function () {
        switch (this.componentType) {
          case "gis":
            viewerGis.Scene.setImageryLayer("TianDiTu");
            this.drawPoint();
            break;
          case "latlng":
            viewerGis.Scene.setImageryLayer("AMapStreets");
            this.drawPolygon();
            break;
          default:
        }
      },
    },
    // 监听switchPoint的变化，若为true则切换为经纬度，若为false则切换为画区域
    chooseType: {
      handler: function (newVal) {
        if (this.modelsInfo?.length) {
          this.modelsInfo = [
            {
              model_id: this.modelsInfo[0].model_id || "",
              select: this.modelsInfo[0].select || [],
              polygon: this.modelsInfo[0].polygon || [],
              location_gis: this.modelsInfo[0].location_gis || null,
              location_name: this.modelsInfo[0].location_name || "",
              A: this.modelsInfo[0].A || "",
              B: this.modelsInfo[0].B || "",
            },
          ];
        }
        if (newVal === "point") {
          this.drawPoint();
        } else if (newVal === "cover") {
          this.drawPolygon();
        }
      },
    },
  },
  methods: {
    /**
     * @name:
     * @description:
     * @msg:
     * @return {*}
     */
    lngLatTransformAB(list = []) {
      if (!viewerGis) return;
      return viewerGis.Coordinate.NanNingTransform(list);
    },
    /**
     * @description: 切换搜索地址
     * @return {*}
     */
    checkList(id) {
      if (viewerGis !== null) {
        const findData = this.options.filter((v) => v.id === id);
        if (findData.length) {
          viewerGis.Poi.locationPOI(id);
          this.latLng = findData[0].position;
          this.info = {
            area: `${findData[0].pname}/${findData[0].cityname}/${findData[0].adname}`,
            fullName: `${findData[0].pname}${findData[0].cityname}${findData[0].adname}${findData[0].address}`,
            address: findData[0].address,
          };
          const abCoord = this.lngLatTransformAB([findData[0].position]);
          this.modelsInfo = [
            {
              location_gis: findData[0].position,
              location_name: this.info.area + "|" + this.info.fullName,
              A: abCoord[0].A,
              B: abCoord[0].A,
            },
          ];
        }
      }
    },
    /**
     * @name:
     * @description: 聚焦展示详情面板
     * @msg:
     * @return {*}
     */
    onFocus() {
      this.showInfoModal = false;
    },
    /**
     * @name:
     * @description: 失去焦点隐藏详情面板
     * @msg:
     * @return {*}
     */
    onBlur() {
      if (
        this.info.address &&
        this.modelsInfo.length &&
        this.chooseType === "point"
      ) {
        this.showInfoModal = true;
      }
    },
    /**
     * @name:
     * @description:
     * @msg:
     * @return {*}
     */
    handleDrawType(type, value) {
      this.chooseType = type;
      this.switchPoint = value;
      if (type === "cover") {
        this.showInfoModal = false;
      }
    },
    /**
     * @name:
     * @description: 点击下拉菜单绘制图形
     * @msg:
     * @return {*}
     */
    handleCommand(command) {
      this.graphType = command;
      if (viewerGis) {
        this.clearChooseData();
        this.drawPolygon();
      }
    },
    /**
     * @description: 远程搜索，调用gis接口
     * @param {*} query
     * @return {*}
     */
    async remoteMethod(query) {
      this.loading = true;
      if (query !== "") {
        if (viewerGis !== null) {
          this.options = await viewerGis.Poi.createPOI(query);
          this.loading = false;
        }
      } else {
        this.latLng = null;
        this.options = [];
        this.info = {
          area: "",
          fullName: "",
        };
      }
    },
    /**
     * @description: 加载模型
     * @param {*} id
     * @return {*}
     */
    showModel(id, bol) {
      if (viewerGis !== null) {
        if (bol) {
          viewerGis.Model.createModel(id, { isLocation: true });
        } else {
          viewerGis.Model.removeModel(id);
        }
      }
    },
    clearObk() {
      this.modelsInfo = [
        {
          model_id: "",
          select: [],
          polygon: [],
          location_gis: null,
          location_name: "",
          A: "",
          B: "",
        },
      ];
      if (viewerGis !== null) {
        const loadedModesId = viewerGis.Model.getModelKeys();
        if (loadedModesId?.length) {
          loadedModesId.forEach((item) => {
            viewerGis.Model.removeModel(item);
          });
        }
      }
    },
    /**
     * @description: 清除绘制的面
     * @return {*}
     */
    clearChooseData() {
      this.showInfoModal = null;
      this.switchPoint = null;
      this.modelsInfo = [
        {
          model_id: "",
          select: [],
          polygon: [],
          location_gis: null,
          location_name: "",
          A: "",
          B: "",
        },
      ];
      this.latLng = null;
      this.info = {
        area: "",
        fullName: "",
        address: "",
      };
      if (viewerGis) {
        viewerGis.Plot.removeAllPlot();
        viewerGis.Poi.removePOI();
        viewerGis.MouseCustomEvent.removeDrawing(true);
        this.chooseType = "";
        viewerGis.Marker.removeMarker("point");
      }
    },
    /**
     * @description: 返回
     * @return {*}
     */
    back() {
      this.$emit("back");
    },
    /**
     * @description: 提交数据
     * @return {*}
     */
    commit() {
      //删除空对象以及空数字空字符串
      if (this.modelsInfo.length) {
        for (let i = 0; i < this.modelsInfo.length; i++) {
          for (let k in this.modelsInfo[i]) {
            if (
              Array.isArray(this.modelsInfo[i][k]) &&
              !this.modelsInfo[i][k].length
            ) {
              delete this.modelsInfo[i][k];
            } else if (!this.modelsInfo[i][k]) {
              delete this.modelsInfo[i][k];
            }
          }
        }
      }
      this.$emit("set-data", this.modelsInfo);
    },
    /**
     * @description: 经纬度转换省市区
     * @param {*} val
     * @return {*}
     */
    getInfo(val) {
      formRequest('post', 'api/language/baiduMap' ,{location:`${val.lat},${val.lng}`,language:this.$i18n.locale}).then(res=>{
        if(res.data.code === 200 && res.data?.data?.result?.addressComponent){
          this.info.area = "";
          this.info.fullName = "";
          this.modelsInfo[0].location_name = "";
          const data = res.data.data.result.addressComponent;
          let arr = ["country", "province", "city", "district", "street", "street_number"];
          let n = 4;
          if (data.country && data.country === "中国") {
            arr = arr.splice(1, arr.length - 1);
            n = 3;
          }
          arr.forEach((item, i) => {
            if (data[item]) {
              if (i === 0) {
                if (i < n) {
                  this.info.area += data[item] + "/";
                }
                this.info.fullName += data[item];
              } else if (data[arr[i - 1]] !== data[item]) {
                if (i < n) {
                  this.info.area += data[item] + "/";
                }
                this.info.fullName += ' ' + data[item] ;
              }
            }
            if (item === "city") {
              this.info[item] = data[item];
            }
          });
          if (this.info.area && this.info.area.includes("/")) {
            this.info.area = this.info.area.substring(
              0,
              this.info.area.length - 1
            );
          }
          this.modelsInfo[0].location_name = this.info.area && this.info.fullName ? this.info.area + "|" + this.info.fullName : "";
        }
      })
    },
    // 监听绘制面结束，返回面的经纬度数组
    drawPolygon() {
      viewerGis.Scene.setGroundAtmosphere(false);
      viewerGis.MouseCustomEvent.removeDrawing();
      if (this.graphType === "polygon")
        viewerGis.MouseCustomEvent.drawPolygon(false);
      if (this.graphType === "circular") {
        viewerGis.MouseCustomEvent.drawCircle(false);
      }
      if (this.graphType === "rectangle") {
        viewerGis.MouseCustomEvent.drawRectangle(false);
      }
    },
    // 监听绘制点结束，返回点的经纬度
    drawPoint() {
      if (viewerGis) {
        viewerGis.Plot.removeAllPlot();
      }
      viewerGis.Scene.setGroundAtmosphere(false);
      viewerGis.MouseCustomEvent.removeDrawing();
      viewerGis.MouseCustomEvent.drawPoint(false);
    },
		//获取kml
		getKml () {
      const kmlArr = [
        // {id:'1995145ff6e94797b9eb4c44c24b2a53',path:'https://models.bimcc.net/files/kml/daa7881e-f452-4b14-9d3b-f29fc75849dd.kml'},
        {id:'dfb30c85e63a4e2191051cac4d495b79',path:'https://models.bimcc.net/files/kml/56079f35-0bc0-4f42-b306-5b4fafe17130.kml'},
        {id:'35b0165bcdf04a159e6140491ed5cf69',path:'https://models.bimcc.net/files/kml/a5720d55-4c6a-4203-983f-58812c3feae9.kml'}
      ]
      for(let i=0;i<kmlArr.length;i++){
        viewerGis.Plot.createKmlByServe(kmlArr[i].id, {
          kmlFile: {
            isModels: false,
            path: kmlArr[i].path
          }
        })
      }
		},
  },
	/* eslint-disable */
  async mounted() {
    viewerGis = new global.BIMCC_GIS_Base.Viewer("map2dCont", {
      host: "models.bimcc.net",
      port: "",
      isHttps: true,
      appId: this.appId || process.env.VUE_APP_MODEL_APPID,
      appSecret: this.appSecret || process.env.VUE_APP_MODEL_APPSECRET,
    });
    await viewerGis.init();
		viewerGis.Scene.flyCameraView({
			heading: 359.9999999999998,
			height: 7385.5,
			lat: 21.54056195,
			lng: 39.12442739,
			pitch: -89.9927067630661,
			roll: 0
		});
		this.getKml()
    window.viewerGis = viewerGis;
    //切换成2d地图
    if (!this.config?.is3D) {
			viewerGis.MouseController.mouseStyleByGIS();
			viewerGis.Scene.setImageryLayer(BIMCC_GIS_Base.ImageryType.MAPBOX, {styleId: 'cluwgjfrv001b01qphxtt1q3x'});
      if (navigator.geolocation && !this.modelsInfo[0].location_gis) {
        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          if (!this.modelsInfo[0].location_gis) {
            viewerGis.Scene.flyTo({ lng, lat }, 4000, 90, 0);
          }
        });
      }
    } else {
			viewerGis.MouseController.mouseStyleByGIS();
			viewerGis.Scene.setImageryLayer(BIMCC_GIS_Base.ImageryType.MAPBOX, {styleId: 'cluwfz24f001501qr0kw35q8u'});
		}
		if(this.config?.mapUrl){
			if(this.config.mapUrl.includes('|')){
				const arr = this.config.mapUrl.split("|")
				if(arr.length){
					for(let i = 0; i < arr.length; i++){
						if(arr[i].includes('_WMS') || arr[i].includes('_WFS')){
							viewerGis.Scene.addWMSImagery({
									mapUrl: arr[i],
							});
						} else {
							viewerGis.Scene.addCustomImagery({
									mapUrl: arr[i]
							})
						}
					}
				}
			} else {
				if(this.config.mapUrl.includes('_WMS') || this.config.mapUrl.includes('_WFS')){
					viewerGis.Scene.addWMSImagery({
							mapUrl: this.config.mapUrl,
					});
				} else {
					viewerGis.Scene.addCustomImagery({
							mapUrl: this.config.mapUrl
					})
				}
			}
		}

    //监听模型加载完毕
    viewerGis.on(viewerGis.EventType.modelLoaded, (modelId) => {
      if (modelId) {
        if (
          Object.prototype.toString.call(this.modelData) ===
            "[object Object]" &&
          this.modelData?.models.length
        ) {
          //回显构件选中，兼容老格式
          this.modelData?.models.forEach((val) => {
            if (val.model_id === modelId) {
              if (val.dbids?.length) {
                viewerGis.Model.selectFeature(val.model_id, val.dbids);
              } else if (val.select?.length) {
                viewerGis.Model.selectFeature(val.model_id, val.select);
              }
            }
          });
        } else if (
          Object.prototype.toString.call(this.modelData) === "[object Array]"
        ) {
          //回显构件选中,新格式
          this.modelData.forEach((val) => {
            if (val.model_id === modelId) {
              if (val.select?.length) {
                viewerGis.Model.selectFeature(val.model_id, val.select);
              } else if (val.select?.length) {
                viewerGis.Model.selectFeature(val.model_id, val.select);
              }
            }
          });
        }
      }
    });
    //监听绘面多边形返回数据
    viewerGis.on(viewerGis.EventType.drawPolygonByMouseCustom, (res) => {
      const arr = res.map((item) => ({
        lng: item[0],
        lat: item[1],
        height: 0,
      }));
      viewerGis.Plot.createPlot(Date.now(), {
        plotType: "polygon",
        positions: arr,
        options: {
          plotType: "polygon",
          perPositionHeight: true,
          height: 5,
          fill: true,
          fillType: "color",
          fillColor: "#2FA45A",
          alpha: 20,
          outline: true,
          outlineColor: "#14ae5c",
          outlineWidth: 2,
          isClampToGround: false,
          stRotation: 0,
        },
      });
      const position = {
        lat: res[0][1],
        lng: res[0][0],
      };
      const ABCoord = viewerGis.Coordinate.NanNingTransform([position]);
      const positionItem = {
        location_gis: position,
        A: ABCoord[0].A,
        B: ABCoord[0].B,
        polygon: [{ position: arr }],
      };
      this.latLng = position;
      this.showInfoModal = true;
      this.modelsInfo = [...this.modelsInfo, positionItem].filter(
        (item) => item.location_gis
      );
      this.getInfo(position);
      //绘制后就可以清除绘制状态，若想一直绘制则可以不用写这句
      viewerGis.MouseCustomEvent.removeDrawing();
    });
    //监听绘面矩形返回数据
    viewerGis.on(viewerGis.EventType.drawRectangleByMouseCustom, (res) => {
      const arr = [
        { lng: res.east, lat: res.north, height: 0 },
        { lng: res.west, lat: res.north, height: 0 },
        { lng: res.west, lat: res.south, height: 0 },
        { lng: res.east, lat: res.south, height: 0 },
      ];

      viewerGis.Plot.createPlot(Date.now(), {
        plotType: "polygon",
        positions: arr,
        options: {
          plotType: "polygon",
          perPositionHeight: true,
          height: 5,
          fill: true,
          fillType: "color",
          fillColor: "#2FA45A",
          alpha: 20,
          outline: true,
          outlineColor: "#14ae5c",
          outlineWidth: 2,
          isClampToGround: false,
          stRotation: 0,
        },
      });

      this.modelsInfo[0].polygon = [{ position: arr }];
      const position = {
        lat: (res.north + res.south) / 2,
        lng: (res.east + res.west) / 2,
      };
      const ABCoord = viewerGis.Coordinate.NanNingTransform([position]);
      const positionItem = {
        location_gis: position,
        A: ABCoord[0].A,
        B: ABCoord[0].B,
        polygon: [{ position: arr }],
      };
      this.latLng = position;
      this.showInfoModal = true;
      this.modelsInfo = [...this.modelsInfo, positionItem].filter(
        (item) => item.location_gis
      );
      this.getInfo(position);
      //绘制后就可以清除绘制状态，若想一直绘制则可以不用写这句
      viewerGis.MouseCustomEvent.removeDrawing();
    });
    //监听绘面圆形返回数据
    viewerGis.on(viewerGis.EventType.drawCircleByMouseCustom, (res) => {
      viewerGis.Plot.createPlot(Date.now(), {
        plotType: "circle",
        positions: [res.position],
        options: {
          radius: res.radius,
          perPositionHeight: true,
          height: 5,
          fill: true,
          fillType: "color",
          fillColor: "#2FA45A",
          alpha: 20,
          outline: true,
          outlineColor: "#14ae5c",
          outlineWidth: 2,
          isClampToGround: false,
          stRotation: 0,
        },
      });
      const position = res.position;
      this.getInfo(position);
      const ABCoord = viewerGis.Coordinate.NanNingTransform([position]);
      const positionItem = {
        location_gis: position,
        A: ABCoord[0].A,
        B: ABCoord[0].B,
        polygon: [{ position: [res.position], radius: res.radius }],
      };
      this.latLng = position;
      this.showInfoModal = true;
      this.modelsInfo = [...this.modelsInfo, positionItem].filter(
        (item) => item.location_gis
      );
      this.getInfo(position);
      //绘制后就可以清除绘制状态，若想一直绘制则可以不用写这句
      viewerGis.MouseCustomEvent.removeDrawing();
    });
    //监听绘制点的经纬度数据
    viewerGis.on(viewerGis.EventType.drawPointByMouseCustom, async (res) => {
      const ABCoord = viewerGis.Coordinate.NanNingTransform([
        { lat: res.lat, lng: res.lng },
      ]);

      if (res) {
        this.modelsInfo[0].location_gis = res;
        await this.getInfo(res);
        this.latLng = { lat: res?.lat, lng: res?.lng };
        this.value = "";
        this.options = [];
        this.showInfoModal = true;
        this.modelsInfo[0].A = ABCoord[0].A;
        this.modelsInfo[0].B = ABCoord[0].B;
      }
    });
    if (
      (Array.isArray(this.modelData) && !this.modelData?.length) ||
      this.modelData === null
    ) {
      //默认绑定经纬度
      if (this.drawType === 2) {
        this.drawPolygon();
        this.chooseType = "cover";
      } else {
        this.drawPoint();
        this.chooseType = "point";
      }
    } else {
      // 回显框选区域
      if (
        this.modelData?.coverData &&
        Array.isArray(this.modelData.coverData) &&
        this.modelData.coverData.length
      ) {
        this.modelsInfo[0].polygon = [
          {
            position: this.modelData.coverData,
          },
        ];
        this.modelData.coverData.forEach((item, index) => {
          //定位到第一个点
          if (index === 0) {
            viewerGis.Scene.flyTo(item[0], 4000, 90, 0);
          }
          viewerGis.Plot.createPlot(index, {
            plotType: "polygon",
            positions: item,
            options: {
              plotType: "polygon",
              perPositionHeight: true,
              height: 5,
              fill: true,
              fillType: "color",
              fillColor: "#eabebf",
              alpha: 50,
              outline: true,
              outlineColor: "#dc2e41",
              outlineWidth: 1,
              isClampToGround: false,
              stRotation: 0,
            },
          });
        });
      }
      //  标点回显，对象老格式
      if (
        this.modelData !== null &&
        ((this.modelData?.lat && this.modelData?.lng) ||
          this.modelData.position)
      ) {
        let position = this.modelData;
        if (this.modelData.position) {
          position = this.modelData.position;
        }
        this.modelsInfo[0].location_gis = position;
        this.getInfo(position);
        this.latLng = { lat: position?.lat, lng: position?.lng };
        viewerGis.Marker.createMarker("point", {
          type: "Billboard",
          position: {
            lng: position?.lng,
            lat: position?.lat,
          },
          options: {
            image: viewerGis.Image.getIconBlendImage({
              url: "https://openim-1309784708.cos.ap-shanghai.myqcloud.com/d52679369b5eefa811be37c436f50584.png",
            }),
            width: 36,
            height: 36,
          },
        });
        //定位到这个点
        viewerGis.Scene.flyTo(position, 4000, 90, 0);
      }
      // 回显，数组新格式
      if (
        this.modelData !== null &&
        Array.isArray(this.modelData) &&
        this.modelData.length
      ) {
        for (let k in this.modelData[0]) {
          this.modelsInfo[0][k] = this.modelData[0][k];
        }
        for (let i = 0; i < this.modelData.length; i++) {
          if (this.modelData[i].location_gis) {
            this.latLng = {
              lat: this.modelData[i].location_gis?.lat,
              lng: this.modelData[i].location_gis?.lng,
            };
            viewerGis.Marker.createMarker("point", {
              type: "Billboard",
              position: this.modelData[i].location_gis || null,
              options: {
                image: viewerGis.Image.getIconBlendImage({
                  url: "https://openim-1309784708.cos.ap-shanghai.myqcloud.com/d52679369b5eefa811be37c436f50584.png",
                }),
                width: 36,
                height: 36,
              },
            });
            viewerGis.Scene.flyTo(this.modelData[i].location_gis, 4000, 90, 0);
          }
          if (this.modelData[i].location_name) {
            const addressType = this.modelData[i].location_name.split("|");
            if (addressType.length === 2) {
              this.info.area = addressType[0];
              this.info.fullName = addressType[1];
              this.info.address = addressType[1];
            }
          }
          if (
            this.modelsInfo[0]?.location_gis &&
            this.componentType === "latlng"
          ) {
            this.showInfoModal = true;
          }
          if (this.modelData[i].polygon?.length) {
            viewerGis.Plot.createPlot(
              this.modelsInfo[0].A + this.modelsInfo[0].B,
              {
                plotType: this.modelData[i].polygon[0].radius
                  ? "circle"
                  : "polygon",
                positions:
                  this.modelData[i].polygon[0].position ||
                  this.modelData[i].polygon,
                options: {
                  plotType: this.modelData[i].polygon[0].radius
                    ? "circle"
                    : "polygon",
                  perPositionHeight: true,
                  height: 5,
                  fill: true,
                  fillType: "color",
                  fillColor: "#eabebf",
                  alpha: 50,
                  outline: true,
                  outlineColor: "#dc2e41",
                  outlineWidth: 1,
                  isClampToGround: false,
                  stRotation: 0,
                  radius: this.modelData[i].polygon[0].radius,
                },
              }
            );

            if (this.modelData[i].polygon[0]?.position) {
              viewerGis.Scene.flyTo(
                this.modelData[i].polygon[0]?.position[0],
                4000,
                90,
                0
              );
            } else {
              viewerGis.Scene.flyTo(this.modelData[i].polygon[0], 4000, 90, 0);
            }
          }
        }
      }
    }
    // 监听构件选中;
    viewerGis.on(viewerGis.EventType.featureSelect, (res) => {
      if (Array.isArray(res) && res.length) {
        let models = [];
        for (let i = 0; i < res.length; i++) {
          if (i === 0) {
            models.push({
              model_id: res[i].modelId,
              dbids: [res[i].dbid],
              select: [res[i].dbid],
            });
          } else {
            const index = models.findIndex(
              (v) => v.model_id === res[i].modelId
            );
            if (index > -1) {
              if (!models[index].dbids.includes(res[i].dbid)) {
                models[index].dbids.push(res[i].dbid);
                models[index].select.push(res[i].dbid);
              }
            } else {
              models.push({
                model_id: res[i].modelId,
                dbids: [res[i].dbid],
                select: [res[i].dbid],
              });
            }
          }
        }
        if (models.length) {
          this.modelsInfo[0].model_id = models[0].model_id;
          this.modelsInfo[0].dbids = models[0].dbids;
          this.modelsInfo[0].select = models[0].select;
        }
      }
    });
  },
  beforeDestroy() {
    viewerGis.MouseCustomEvent.removeDrawing();
    viewerGis.off(viewerGis.EventType.drawPolygonByMouseCustom);
    viewerGis.off(viewerGis.EventType.drawPointByMouseCustom);
    viewerGis.off(viewerGis.EventType.drawCircleByMouseCustom);
    viewerGis.off(viewerGis.EventType.drawRectangleByMouseCustom);
  },
};
</script>
<style lang="less">
.el-button--primary{
  background-color: var(--el-color-primary);
  border-color: var(--el-color-primary);
}
.lat-choose-cont {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .header {
    height: 48px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: #fff;
    .page-header {
      line-height: 47px;
      padding-left: 15px;
      border-bottom: 1px solid #dcdfe6;
    }
  }
  .el-page-header__content {
    font-size: 14px !important;
  }
  .el-page-header__left {
    color: var(--el-color-primary);
    animation: fade 1500ms infinite;
    -webkit-animation: fade 1500ms infinite;
  }
  .el-input__inner {
    height: 40px !important;
    border-radius: 6px 0 0 6px;
    border-right: 0;
  }
  .search-btn {
    height: 40px;
    border-radius: 0 6px 6px 0;
    padding: 10px 18px;
  }
  @keyframes fade {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  .flex1 {
    flex: 1;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .commit-btn{
    background-color: var(--el-color-primary);
    border-color:var(--el-color-primary);
    top: 8px; 
    right: 10px;
  }
  .singleBtn {
    width: 90px !important;
  }
  .hiddenPoi,
  .hiddenCov {
    display: none !important;
  }

  .btn {
    top: 70px;
    right: 20px;
    height: 40px;
    background: #fff;
    align-items: center;
    display: flex;
    border-radius: 6px;
    width: auto;
    position: absolute;
    div {
      line-height: 16px;
      width: 83px;
      background: #fff;
      border: none;
      padding: 0;
      color: #181b22;
      font-family: PingFang SC;
      font-size: 14px;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: 6px;
      ul {
        position: absolute;
        right: -12px;
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 6px;
        background: #fff;
        top: 40px;
        box-shadow: 0px 2px 16px 0px rgba(25, 27, 35, 0.08),
          0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset,
          0px 1px 3px 0px rgba(25, 27, 35, 0.04);
        li {
          display: flex;
          width: 60px;
          height: 32px;
          padding: 0px 12px;
          align-items: center;
          color: #181b22;
          gap: 4px;
          border-radius: 4px;
          white-space: nowrap;
          overflow: hidden;
          &:hover {
            background: #f4f6f9;
          }
        }
        .active {
          background: #f4f6f9;
          color: #181b22;
        }
      }
    }
    .ponit {
      border-right: 1px dashed #c5c7d0 !important;
    }
    .hiddenBor {
      border: none !important;
    }
    .active {
      color: #387ffc;
    }
  }
  [lang="en"] & {
    .select-btn {
      width: 120px;
    }
  }

  .zIndexUp {
    z-index: 100;
    position: absolute;
  }
  .zIndexDown {
    z-index: 0;
    position: absolute;
  }
  .choose-detail {
    left: 0px;
    top: 45px;
    background: #f5f5f5;
    z-index: 99;
    opacity: 0.8;
    p {
      padding: 5px 2px;
    }
  }
  .list-cont {
    width: 300px;
    padding-top: 50px;
  }
}
.point-info {
  position: absolute;
  display: flex !important;
  top: 128px;
  width: 380px;
  padding: 24px 20px 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-6, 12px);
  border-radius: var(--radius-6, 12px);
  background: rgba(255, 255, 255, 0.94);
  box-shadow: 0px 2px 16px 0px rgba(25, 27, 35, 0.08),
    0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset,
    0px 1px 3px 0px rgba(25, 27, 35, 0.04);
  z-index: 1;
  left: 20px;
  .info-header {
    width: 100%;
    overflow: hidden;
    padding-bottom: 12px;
    border-bottom: 1px solid #f0f1f4;
    &-top {
      display: flex;
      align-items: center;
    }
    .maker {
      line-height: 20px;
      border-radius: 4px;
      border: 1px solid #e1e4ec;
      padding: 0 6px;
      white-space: nowrap;
    }
    .icon {
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/da1644e4ef3b7cf46f5a26c9a1e3d09d.png);
    }
    .name {
      overflow: hidden;
      color: #181b22;
      text-overflow: ellipsis;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.5px;
      margin-left: 6px;
      white-space: nowrap;
      flex: 1;
    }
    .detail-address {
      overflow: hidden;
      color: #707786;
      text-overflow: ellipsis;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.5px;
      margin-left: 40px;
      white-space: nowrap;
    }
  }

  .info-desc {
    width: 100%;
    .desc {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/6310a92661f16fcbe971a577eab2d7cd.png);
        img {
          width: 16px;
          height: 16px;
        }
      }
      .text {
        overflow: hidden;
        color: #181b22;
        text-overflow: ellipsis;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .item-container {
      margin-left: 40px;

      .item {
        line-height: 40px;
        color: #424751;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid #f0f1f4;
      }
    }
  }
}
</style>
<style>
.bind-search-cont {
  width: 420px;
  position: absolute;
  top: 70px;
  left: 20px;
  display: flex;
  align-items: center;
}
.bind-select-list {
  width: 420px !important;
  right: 10% !important;
}
.bind-select-list .el-select-dropdown__item {
  word-wrap: break-word !important;
  word-break: normal !important;
  height: auto;
  white-space: inherit !important;
  overflow: inherit !important;
  text-overflow: inherit !important;
  position: relative;
}
.bind-select-list .el-select-dropdown__item:hover {
  border-radius: 6px;
}
.bind-select-list .el-select-dropdown__wrap {
  padding: 8px;
}
.cesium-viewer-message {
  z-index: 100;
}
.item-desc {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: inherit;
}
.item-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-adname {
  font-family: PingFang SC;
  font-size: 12px;
  color: #a4acbd;
  white-space: nowrap;
}
</style>
