import { render, staticRenderFns } from "./EzuiFullPlayer.vue?vue&type=template&id=6ae70e80&scoped=true"
import script from "./EzuiFullPlayer.vue?vue&type=script&lang=js"
export * from "./EzuiFullPlayer.vue?vue&type=script&lang=js"
import style0 from "./EzuiFullPlayer.vue?vue&type=style&index=0&id=6ae70e80&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ae70e80",
  null
  
)

export default component.exports