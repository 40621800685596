<!--
    @name: EdgeProp
    @description：GateProp
    @author: ZengWei
    @date: 2022-08-27 11:26
-->
<template>
  <div>
    <el-form
      ref="form"
      :model="flowBranch"
      :label-width="{ en: '150px' }[$i18n.locale] || '80px'"
      label-position="left"
      size="small"
    >
      <el-collapse v-model="activeNames">
        <el-collapse-item name="basic">
          <template #title>
            <span class="collapse-title">{{ $t('cell-panel.EdgeProp.382481-0') }}</span>
          </template>
          <el-form-item :label="$t('cell-panel.EdgeProp.382481-1')">
            <el-input v-model="flowBranch.name" @change="onChange" :disabled="disabled"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cell-panel.EdgeProp.382481-2')">
            <el-radio-group
              v-model="flowBranch.priority"
              style="float: right"
              :disabled="disabled"
              @change="onChange"
            >
              <el-radio-button :label="1">{{ $t('cell-panel.EdgeProp.382481-3') }}</el-radio-button>
              <el-radio-button :label="2">{{ $t('cell-panel.EdgeProp.382481-4') }}</el-radio-button>
              <el-radio-button :label="3">{{ $t('cell-panel.EdgeProp.382481-5') }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item name="condition">
          <template #title>
            <span class="collapse-title">{{ $t('cell-panel.EdgeProp.382481-6') }}</span>
          </template>
          <div class="form-table">
            <div v-for="(item, index) in flowBranch.condition" :key="index">
              <div class="clearfix self-define" v-if="!disabled">
                <span>{{ index > 9 ? index : "0" + (index + 1) }}</span>
                <span class="float-r" @click="delRule(index)">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="float-r" @click="upGo(index)">
                  <i class="el-icon-top"></i>
                </span>
                <span class="float-r" @click="downGo(index)">
                  <i class="el-icon-bottom"></i>
                </span>
              </div>
              <el-form-item :label="$t('cell-panel.EdgeProp.382481-7')">
                <el-radio-group v-model="item.logicType" @change="onChange" :disabled="disabled">
                  <el-radio :label="1">{{ $t('cell-panel.EdgeProp.382481-8') }} (and)</el-radio>
                  <el-radio :label="2">{{ $t('cell-panel.EdgeProp.382481-9') }} (or)</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('cell-panel.EdgeProp.382481-10')">
                <el-radio-group
                  class="rule-radio"
                  v-model="item.judgeType"
                  :disabled="disabled"
                  @change="onChange"
                >
                  <el-radio-button :label="1">{{ $t('cell-panel.EdgeProp.382481-11') }}</el-radio-button>
                  <el-radio-button :label="2">{{ $t('cell-panel.EdgeProp.382481-12') }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('cell-panel.EdgeProp.382481-13')" v-if="item.judgeType === 1">
                <UserPicker
                  :show-choose-button="true"
                  :show-list="item.publisher"
                  @on-select-change="onSelectUser($event,item)">
                </UserPicker>
              </el-form-item>
              <template v-else>
                <el-form-item :label="$t('cell-panel.EdgeProp.382481-14')">
                  <el-select
                    v-model="item.field"
                    :placeholder="$t('cell-panel.EdgeProp.382481-15')"
                    :disabled="disabled"
                    @change="onChange"
                  >
                    <el-option
                      v-for="dd in formItem"
                      :key="dd.formId"
                      :label="dd.label"
                      :value="dd.field"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('cell-panel.EdgeProp.382481-16')">
                  <el-select
                    v-model="item.judge"
                    :placeholder="$t('cell-panel.EdgeProp.382481-15')"
                    :disabled="disabled"
                    @change="onChange"
                  >
                    <el-option
                      v-for="(dd, ii) in conditionList"
                      :key="ii"
                      :label="dd.name"
                      :value="dd.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('cell-panel.EdgeProp.382481-17')">
                  <el-input v-model="item.value" @change="onChange" :disabled="disabled"></el-input>
                </el-form-item>
              </template>
            </div>
            <el-button
              @click="addRule"
              size="small"
              class="add-rule-btn"
              icon="el-icon-plus"
              :disabled="disabled"
              >{{ $t('cell-panel.EdgeProp.382481-18') }}</el-button
            >
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import {Collapse, CollapseItem, RadioButton, RadioGroup, Radio} from "element-ui";
import UserPicker from '@/custom-component/form/formParser/selectUser'

export default {
  name: 'EdgeProp',
  components: {
    UserPicker,
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-radio': Radio,
  },
  props: {
    curCellProp: {
      type: Object,
      default: () => {},
    },
    formItem: {
      type: Array,
      default: () => [],
    },
    userData: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formItemList: [],
      activeNames: 'basic',
      flowBranch: {
        name: '',
        priority: 1,
        condition: [],
      },
      conditionList: [
        { name: this.$t('cell-panel.EdgeProp.382481-19'), value: 'gt' },
        { name: this.$t('cell-panel.EdgeProp.382481-20'), value: 'lt' },
        { name: this.$t('cell-panel.EdgeProp.382481-21'), value: 'eq' },
        { name: this.$t('cell-panel.EdgeProp.382481-22'), value: 'and' },
        { name: this.$t('cell-panel.EdgeProp.382481-23'), value: 'neq' },
        { name: this.$t('cell-panel.EdgeProp.382481-24'), value: 'egt' },
        { name: this.$t('cell-panel.EdgeProp.382481-25'), value: 'elt' },
        { name: this.$t('cell-panel.EdgeProp.382481-26'), value: 'in' },
        { name: this.$t('cell-panel.EdgeProp.382481-27'), value: 'notin' },
        { name: this.$t('cell-panel.EdgeProp.382481-28'), value: 'between' },
      ],
    };
  },
  mounted() {
    this.initProp();
  },
  methods: {
    onSelectUser(userData,item){
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          name: item.name,
          user_table: item.user_table,
          section_id: item.section_id,
          user_id: item.user_id,
        };
        users.push(user);
      }
      item.publisher = users;
      this.onChange();
    },
    initProp() {
      this.flowBranch = this.curCellProp;
    },
    addRule() {
      let item = {
        logicType: 1,
        judgeType: 1,
        userType: 1,
        userArch: 0,
        publisher: [],
        field: '',
        judge: 'gt',
        value: '',
      };
      this.flowBranch.condition.push(item)
      this.onChange();
    },
    delRule(index) {
      this.flowBranch.condition.splice(index,1)
      this.onChange();
    },
    upGo(index) {
      let fieldData = this.flowBranch.condition;
      if(index !== 0){
        fieldData[index] = fieldData.splice(index-1, 1, fieldData[index])[0];
      }else{
        fieldData.push(fieldData.shift());
      }
      this.flowBranch.condition = fieldData;
      this.onChange();
    },
    downGo(index) {
      let fieldData = this.flowBranch.condition;
      if(index !== fieldData.length-1){
        fieldData[index] = fieldData.splice(index+1, 1, fieldData[index])[0];
      }else{
        fieldData.unshift( fieldData.splice(index,1)[0]);
      }
      this.flowBranch.condition = fieldData;
      this.onChange();
    },
    onChange() {
      this.$emit('on-change', this.flowBranch);
    },
  },
};
</script>

<style lang="less" scoped>
.float-r {
  float: right;
  padding: 0 1px;
  cursor: pointer;
}
.self-define {
  margin: 8px 0;
  text-indent: 2px;
}

.collapse-title {
  text-indent: 15px;
}

:deep(.el-collapse-item__content) {
  padding-bottom: 0;
}

:deep(.el-collapse-item__header) {
  position: relative;

  &:before {
    position: absolute;
    top: 16px;
    width: 3px;
    height: 14px;
    margin-right: 10px;
    background-color: var(--themeColor)!important;
    content: "";
  }
}

:deep(.form-table) {
  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    /*margin-bottom: -1px !important;*/

    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      /*margin-bottom: 1px;*/

      .el-select,
      .el-cascader {
        width: 100%;
      }

      .self-type {
        .el-radio {
          margin-right: 16px;
        }
      }
    }
  }
}

.add-rule-btn {
  margin: 15px 0;
  width: 100%;
}
</style>
