/*
 * @Author: hw
 * @Date: 2024-03-08 11:30:20
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-08 11:30:56
 * @FilePath: \dataview-next\src\custom-component\container\lang\index.js
 */
import zh from "./zh";
import en from "./en";

export default {
  zh,
  en
}