<!--
    @name: FileFlowDetail
    @description：FileFlowDetail
    @author: ZengWei
    @date: 2022-04-28 15:24
-->
<template>
  <div class="">
    <el-form class="details-form" label-width="100">
      <div class="type-label">
        基本信息
      </div>
      <el-form-item label="名称:">
        {{ data.name ? data.name : '-' }}
      </el-form-item>
      <el-form-item label="描述:">
        {{ data.desc ? data.desc : '-' }}
      </el-form-item>
      <div class="type-label">
        文件
      </div>
      <UploadFiles :disabled="true" :files="files" />
    </el-form>
    <FlowRecover
      v-if="editData"
      :key="keyID1"
      :canvas-data="editData.recover"
      :logs="editData.logs"
    />
    <FlowOperate
      v-if="editData"
      :key="keyID2"
      :edit-data="editData"
      @change-end="refresh"
    />
  </div>
</template>

<script>
import FlowRecover from "@/custom-component/form/formParser/flowEngine/components/FlowRecoverNew";
import FlowOperate from "@/custom-component/form/formParser/flowEngine/components/FlowOperate";
import UploadFiles from "@/components/fileUpload/newFiles/index";
import {flowEngine} from "@/apis/data/workflow";
import { dataInterface } from "@/apis/data";

export default {
  name: "FileFlowDetail",
  components: {FlowRecover,FlowOperate,UploadFiles},
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      editData: null,
      keyID1: new Date().getTime() + 'a',
      keyID2: new Date().getTime() + 'b',
      files: []
    }
  },
  created() {
    this.getModule()
    this.getFiles()
  },
  methods: {
    getFiles() {
      dataInterface({ id: this.data.id }, 'api/repository/inspectFileList').then(res => {
        if(res.data.code === 200) {
          if(Array.isArray(res.data.data)) {
            res.data.data.forEach(el => {
              el.filename = el.name
            })
            this.files = res.data.data
          }
        }
      })
    },
    refresh(){
      this.$emit('refresh')
      this.getModule()
      this.keyID1 = new Date().getTime() + 'a'
      this.keyID2 = new Date().getTime() + 'b'
    },
    getModule(){
      const params = { module: 'view624d36e3425a4' }
      flowEngine.flowDataDetail(this.data.id, params).then((res) => {
        if (res.data.code === 200) {
          this.editData = res.data.data
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
:deep(.details-form) {
  .el-form-item{
    border-top: 1px solid #f5f5f5;
    margin-bottom: 0px;
    padding: 10px 0;
    // display: block;
    .el-form-item__label{
      display: block;
      font-size: 12px;
      font-family: var(--systemFontFamily);
      font-weight: 500;
      color: #445E96;
      line-height: 20px;
      padding: 4px 0;
      // text-align: left;
    }
    .el-form-item__content{
      padding-left: 6px;
    }
  }
}
</style>
