/*
 * @LastEditTime: 2024-08-26 17:04:28
 * @Description: 3.0 版本的数据响应处理
 * @FilePath: \dataview-viewer-test\src\utils\VersionThreeMessage.js
 * @Date: 2021-12-22 19:01:06
 * @Author: lisushuang
 * @LastEditors: qinmengyuan 2715025514@qq.com
 */

import { setToken } from "@/utils/tools";
import eventBus from "@/plugins/eventBus";
import GlobalDialog from "@/components/global/Dailog";
import KnowDialog from "@/components/global/KnowDialog";
import WorkOrderDialog from "@/components/global/WorkOrderDialog";
import Vue from "vue";
import store from "@/store";
import i18n from "@/locale/index";

export default class VersionThreeMessage {
  // 防抖数据搜集
  static collect = [];

  instance = null;
  data = null;
  origin = null;
  title = "";

  // 弹窗实例
  dialogInstance = null;

  knowModule = {
    repository_new: "知识库审批",
    repository_file_inspect: "知识库文件入库审批",
    repository_page: "知识库页面审批",
  };

  constructor(data) {
    this.data = data?.data;
    this.origin = data.origin;
    if (this.data.object_name !== undefined) {
      this.title = this.data.object_name;
    }

    this.instance = null;
  }

  handler() {
    if (this.data && this.data.event !== undefined) {
      if (this.data.event == "iframe-ver3.0") {
        this.handleIframeEvent(this.data.type);
      }
      if (this.data.event == "showDetailModal") {
        this.handleShowDetailModel();
      }
    }
  }

  handleIframeEvent(data) {
    if (data == "logout") {
      this.onQuit();
    }
  }

  /**
   * @description: 显示弹窗
   * @param {*}
   * @return {*}
   */
  handleShowDetailModel() {
    let _this = this;
    let tag = store.getters.getActiveItemTag;
    // 判断当前路由是否需要给表单请求添加tag
    // todo: 后续需要添加判断是否需要添加tag
    this.dialogInstance = new Vue({
      store,
      i18n: i18n,
      data: {
        visible: true,
      },
      methods: {
        closeMe() {
          this.visible = false;
          window.parent.postMessage({ event: "close-todo-list-form" }, "*");
          setTimeout(() => {
            _this.instance.$el.removeChild(_this.dialogInstance.$el);
            _this.dialogInstance = null;
            // _this = null
          }, 500);
        },
      },
      render() {
        const module = Object.keys(_this.knowModule);
        if (module.includes(_this.data.module_name)) {
          _this.title = _this.knowModule[_this.data.module_name];
          return (
            <KnowDialog
              title={_this.title}
              data_id={_this.data.data_id}
              form_uuid={_this.data.form_uuid}
              tag={_this.data.module_name}
              archi_type={_this.data.archi_type}
              v-on:close={() => this.closeMe()}
            ></KnowDialog>
          );
        } else if (_this.data.module_name === "sys_task_work_form") {
          let { taskConfig } = _this.data;
          // 工单跳转
          return (
            <WorkOrderDialog
              title={_this.title}
              data_id={_this.data.data_id}
              tag={tag}
              taskConfig={taskConfig}
              archi_type={_this.data.archi_type}
              v-on:close={() => this.closeMe()}
            ></WorkOrderDialog>
          );
        } else {
          return (
            <GlobalDialog
              title={_this.title}
              data_id={_this.data.data_id}
              form_uuid={_this.data.form_uuid}
              tag={tag}
              archi_type={_this.data.archi_type}
              disabled={_this.data.disabled}
              show_footer={_this.data.show_footer}
              use_object_uuid={_this.data.use_object_uuid}
              is_read={_this.data.is_read}
              msg_id={_this.data.msg_id}
              hide_btn={_this.data.show_btn ? false : true}
              deal_object_uuid={_this.data?.deal_object_uuid}
              width={_this.data.width}
              v-on:close={() => this.closeMe()}
            ></GlobalDialog>
          );
        }
      },
    }).$mount();
    this.instance.$el.appendChild(this.dialogInstance.$el);
  }

  handleGeneralEvent() {}

  /**
   * @desc: 退出登陆
   */
  onQuit() {
    // 移除路由文件重新获取一次
    setToken("");
    sessionStorage.clear();
    localStorage.clear();
    this.instance.$router.replace({
      name: "Login",
    });
    eventBus.$emit("updateRoute");
    // 刷新数据
    sessionStorage.removeItem("navHistoryList");
    this.$store.commit("setNavHistory", []);
  }

  bind(instance) {
    this.instance = instance;
    return this;
  }
}
