/*
 * @Description:
 * @Author: luocheng
 * @Date: 2021-12-24 09:53:15
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-26 16:19:40
 */

export const routes = [
  {
    path: "/",
    redirect: process.env.VUE_APP_HOME_PATH,
  },
  {
    path: "/home",
    name: "MyHome",
    component: () => import(/*index*/ "@/views/appEntry/Index"),
    children: [
      {
        path: "/accident-report",
        name: "AccidentReport",
        meta: {
          title: "事故上报",
        },
        component: () => import(/* login */ "@/views/accident/Index"),
      },
      {
        path: "/base-home",
        name: "NewHome",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/newHome/Index"),
      },
      {
        path: "/todo",
        name: "Todo",
        meta: {
          title: "待办",
        },
        component: () => import("@/views/todo/index"),
      },
      {
        path: "/apps",
        name: "apps",
        meta: {
          title: "应用列表",
        },
        component: () => import("@/views/apphome/Index"),
      },

      {
        path: "/compny-info",
        name: "compny-info",
        meta: {
          title: "公司概况",
        },
        component: () => import("@/views/company/info"),
      },
      {
        path: "/task-flow",
        name: "TaskFlow",
        meta: {
          title: "发布工单",
        },
        component: () => import("@/views/workOrder/task-flow/WorkTodo"),
      },
      {
        path: "/topology",
        name: "Topology",
        meta: {
          title: "拓扑图",
        },
        component: () => import("@/views/topology/index"),
      },
      {
        path: "/task-engine",
        name: "TaskEngine",
        meta: {
          title: "任务引擎",
        },
        component: () => import("@/views/workOrder/TaskEngineList.vue"),
      },
      {
        path: "/only-form-page",
        name: "form-one",
        meta: {
          title: "独立表单测试",
        },
        component: () => import("@/views/general/onlyForm"),
      },
      {
        path: "/enter-paper",
        name: "enterPaper",
        meta: {
          title: "试卷渲染测试",
          keepAlive: false,
        },
        component: () => import("@/views/question/PaperPage"),
      },
      {
        path: "/ques-designer",
        name: "ques-designer",
        meta: {
          title: "试卷设计器",
          keepAlive: true,
        },
        component: () => import("@/views/question/QuesDesigner"),
      },
      {
        path: "/form-func-meter",
        name: "formFuncMeter",
        meta: {
          title: "表单功能设计",
        },
        component: () => import("@/views/qualityMeter/index"),
      },
      {
        path: "/self-form-func/:others",
        name: "formFuncOthers",
        meta: {
          title: "自定义功能设计",
        },
        component: () => import("@/views/qualityMeter/index"),
      },
      {
        path: "/form-data-list",
        name: "formDataList",
        meta: {
          title: "表单功能列表",
        },
        component: () => import("@/views/subitem/formDataList"),
      },
      // 过程管理
      {
        path: "/subitem-data-table/supervision",
        name: "SupervisionTable",
        meta: {
          title: "监理用表",
        },
        component: () => import("@/views/subitem/TableData"),
      },
      {
        path: "/subitem-data-table/construction",
        name: "ConstructionTable",
        meta: {
          title: "施工用表",
        },
        component: () => import("@/views/subitem/TableData"),
      },
      {
        path: "/subitem-data-table/quality",
        name: "QualityTable",
        meta: {
          title: "质量验收",
        },
        component: () => import("@/views/subitem/index"),
      },
      {
        path: "/subitem-data-table/parallel",
        name: "ParallelTest",
        meta: {
          title: "平行检验",
        },
        component: () => import("@/views/subitem/index"),
      },
      {
        path: "/subitem-data-table/side-station",
        name: "SideStationTable",
        meta: {
          title: "旁站记录",
        },
        component: () => import("@/views/subitem/TableData"),
      },
      {
        path: "/pedestal-user-center",
        name: "PedestalUserCenter",
        meta: {},
        component: () => import("@/views/userCenter/Index"),
      },
      {
        path: "/work-order",
        name: "WorkOrder",
        meta: {
          title: "我的工单",
        },
        component: () => import("@/views/workOrder/index"),
      },
      {
        path: "/work-order-details",
        name: "WorkOrderDetails",
        meta: {
          title: "工单详情",
        },
        component: () => import("@/views/workOrder/Details.vue"),
      },
      {
        path: "/work-order-editor",
        name: "WorkOrderEditor",
        meta: {
          title: "工单编辑",
        },
        component: () => import("@/views/workOrder/ReleaseOrder.vue"),
      },
      {
        path: "/news-share",
        name: "NewsShare",
        component: () => import("@/views/newsShare/index"),
        meta: {
          title: "新闻分享",
        },
      },
      // APP配置
      {
        path: "/app-config",
        name: "AppConfig",
        meta: {
          title: "APP配置",
        },
        component: () => import("@/views/appConfig/Index.vue"),
      },
    ],
  },
  {
    path: "/entry",
    name: "Entry",
    component: () => import(/*index*/ "@/views/appEntry/Index"),
  },
  {
    path: "/newProject",
    name: "newProject",
    meta: {
      title: "新项目管理",
    },
    component: () => import("@/custom-component/proInfo/index.vue"),
  },
  {
    path: "/bindLatLng",
    name: "BindLatLng",
    meta: {
      title: "绑定经纬度",
    },
    component: () => import("@/custom-component/bindLatLng/index.vue"),
  },
  {
    path: "/dataview/show",
    name: "DataviewShow",
    meta: {
      title: "大屏",
    },
    component: () => import("@/views/dataview/Show.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "BIM+项目建管平台",
    },
    component: () => import(/* login */ "@/views/login/Index"),
  },
  {
    path: "/public-transfer-page",
    name: "PublicTransferPage",
    meta: {
      title: "BIM+项目建管平台",
    },
    component: () => import(/* login */ "@/views/PublicTransferPage"),
  },
  {
    path: "/second-screen",
    name: "SecondScreen",
    meta: {
      title: "第二屏",
    },
    // component: () => import(/* login */'@/views/login/SecondScreen')
    // component: () => import(/* login */'@/views/secondScreen/index')
    component: () => import(/* login */ "@/views/newSecond/Index"),
  },
  {
    path: "/personnel-pass",
    name: "PersonnelPass",
    meta: {
      title: "人员通行证",
    },
    component: () => import("@/views/passport/personnelPass/index.vue"),
  },
  {
    path: "/vehicle-details",
    name: "VehicleDetails",
    meta: {
      title: "车辆详情",
    },
    component: () => import("@/views/passport/vehicleDetails/index.vue"),
  },
  {
    path: "/peer-information",
    name: "PeerInformation",
    meta: {
      title: "同行人员信息",
    },
    component: () => import("@/views/passport/peerInformation/index.vue"),
  },
  {
    path: "/404",
    name: "404Page",
    meta: {
      title: "出错了",
    },
    component: () => import(/* 404 */ "@/views/404"),
  },
  {
    path: "/page-error",
    name: "PageError",
    meta: {
      title: "出错了",
    },
    component: () => import(/* page-error */ "@/views/PageError.vue"),
  },
  {
    path: "/test-video",
    name: "TestVideo",
    meta: {
      title: "测试",
    },
    component: () => import(/* page-error */ "@/views/TestVideo.vue"),
  },
];
