/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-26 18:44:13
 * @FilePath: \dataview-viewer-test\src\views\userCenter\lang\zh.js
 */

export default {
  "components.AccountInfo.233336-0": "用户名",
  "components.AccountInfo.233336-1": "请输入用户名",
  "components.AccountInfo.233336-2": "取消",
  "components.AccountInfo.233336-3": "保存",
  "components.AccountInfo.233336-4": "编辑",
  "components.AccountInfo.233336-5": "密码",
  "components.AccountInfo.233336-6": "忘记密码",
  "components.AccountInfo.233336-7": "修改",
  "components.AccountInfo.233336-8": "手机号",
  "components.AccountInfo.233336-9": "企业微信",
  "components.AccountInfo.233336-10": "已绑定",
  "components.AccountInfo.233336-11": "未绑定",
  "components.AccountInfo.233336-12": "解除绑定",
  "components.AccountInfo.233336-13": "绑定",
  "components.AccountInfo.233336-14": "修改密码",
  "components.AccountInfo.233336-15": "密码由数字、字母、特殊字符三者组成,长度8-16位!",
  "components.AccountInfo.233336-16": "当前密码",
  "components.AccountInfo.233336-17": "请输入当前密码",
  "components.AccountInfo.233336-18": "新密码",
  "components.AccountInfo.233336-19": "请输入新密码",
  "components.AccountInfo.233336-20": "确认新密码",
  "components.AccountInfo.233336-21": "请确认新密码",
  "components.AccountInfo.233336-22": "取 消",
  "components.AccountInfo.233336-23": "返回登录",
  "components.AccountInfo.233336-24": "确 定",
  "components.AccountInfo.233336-25": "请输入新密码!",
  "components.AccountInfo.233336-26": "请确认新密码!",
  "components.AccountInfo.233336-27": "两次输入密码不一致!",
  "components.AccountInfo.233336-28": "是否确认修改头像？",
  "components.AccountInfo.233336-29": "修改成功！",
  "components.AccountInfo.233336-30": "密码已修改，是否立即重新登录？",
  "components.AccountInfo.233336-31": "提示",
  "components.AccountInfo.233336-32": "前往登录",
  "components.AccountInfo.233336-33": "操作失败！",
  "components.AccountInfo.233336-34": "移除路由文件重新获取一次",
  "components.AccountInfo.233336-35": "请输入正确手机号!",
  "components.AccountInfo.233336-36": "是否确认解除企业微信绑定?",
  "components.AccountInfo.233336-37": "操作失败",
  "components.AccountInfo.233336-38": "已解除企业微信绑定!",
  "components.AccountInfo.233336-39": "绑定成功!",
  "components.AccountInfo.233336-40": "操作成功！",
  "components.UserInfo.300893-0": "头像",
  "components.UserInfo.300893-1": "修改",
  "components.forgetPass.325653-0": "请输入手机号",
  "components.forgetPass.325653-1": "请输入验证码",
  "components.forgetPass.325653-2": "获取验证码",
  "components.forgetPass.325653-3": "请输入新密码",
  "components.forgetPass.325653-4": "请再次输入新密码",
  "components.forgetPass.325653-5": "重置密码",
  "components.forgetPass.325653-6": "请输入新密码!",
  "components.forgetPass.325653-7": "密码由数字、字母、特殊字符三者组成,长度8-16位!",
  "components.forgetPass.325653-8": "请确认新密码!",
  "components.forgetPass.325653-9": "两次输入密码不一致!",
  "components.forgetPass.325653-10": "验证不能为空！",
  "components.forgetPass.325653-11": "验证码格式不正确",
  "components.forgetPass.325653-12": "请重复新密码",
  "components.forgetPass.325653-13": "提交成功！即将前往登陆页面",
  "components.forgetPass.325653-14": "发送成功",
  "userCenter.Index.360471-0": "账号信息",
  "components.AccountInfo.761762-0": "邮箱",
  "components.AccountInfo.761762-1": "请输入邮箱",
  "components.AccountInfo.761762-2": "取消",
  "components.AccountInfo.761762-3": "保存",
  "components.AccountInfo.761762-4": "修改"
}


