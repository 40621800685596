<!--
    @name: widget-time-range
    @description：widget-time-range
    @author: ZengWei
    @date: 2022-03-25 09:37
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ getTimeRange }}</div>
    <el-time-picker
      v-else
      v-model="element.value"
      is-range
      :disabled="disabled || element.config.disabled"
      :range-separator="element.config['range-separator']"
      :start-placeholder="element.config['start-placeholder']"
      :end-placeholder="element.config['end-placeholder']"
      :value-format="element.config['value-format']"
      :placeholder="$getLocalizedLabel({'zhLabel': element.config.zhPlaceholder, 'enLabel': element?.config.enPlaceholder})"
      style="width: 100%"
    >
    </el-time-picker>
  </div>
</template>

<script>
import { TimePicker } from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetTimeRange",
  components: {
    'el-time-picker':TimePicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    getTimeRange() {
      let value = this.element.value.split(',')
      return `${value[0]} - ${value[1]}`
    },
  },
  methods: {},
}
</script>

<style scoped>
.time-range-show {
  width: 100%;
  height: 32px;
  padding: 0 12px;
  box-sizing: border-box;
  background: #F2F4F7;
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--systemFontFamily);
  font-weight: 400;
  color: #2A2F3D;
  cursor: default;
}
</style>
