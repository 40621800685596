<!--
    @name: widget-upload
    @description：widget-upload
    @author: ZengWei
    @date: 2022-03-25 09:38
-->
<template>
  <uploader
    :disabled="disabled || element.config.disabled"
    :files="['default','schedule'].includes(goalType)?element.value : filesData"
    :fileTypeList="fileTypeList"
    :onlyoffice="element?.config?.__config__?.onlyoffice"
    :element="element"
    :drag="true"
    :objectUuid="contentConfig?.objectUUID"
    :dataIds="dataIds"
		:formMode="formMode"
    @extra-files="onSuccess"
		:goalType="goalType"
  >
  </uploader>
</template>

<script>
import axios from "axios";
import uploader from "@/components/fileUpload/newFiles/index";
import { baseUrl } from '@/apis/http/request';
import { Message } from "element-ui"
import { bytesToSize, parseTime, getToken } from '@/utils/tools.js'
import { dataInterface } from '@/apis/data/index';
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetUpload",
  components: {uploader},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData','formData', 'contentConfig', 'dataIds', 'formMode'],
  data() {
    return {
			token: getToken(),
			filesData:[],
			userInfo: null,
		}
  },
  computed:{
    fileTypeList(){
      const type = this.element.config.accept.split(',')
      const typeArr = type.filter(item => {return item && item.trim()})
      return typeArr
    },
		//上传模式：
		goalType(){
			return this.element.config.__config__.goalType || 'default'
		}
  },
  methods: {
    onSuccess(files) {
			if(this.goalType === 'bim' || this.goalType === 'gis'){
				if(files.name && files.raw){
					this.uploadModel(files);
				}
			}else{
        this.element.value = files;
      }
    },
		//获取请求模型参数token
		getModelToken(){
			return new Promise(resolve => {
				axios.post('https://models.bimcc.net/api/v3/auth/token', { appId: process.env.VUE_APP_MODEL_APPID, appSecret: process.env.VUE_APP_MODEL_APPSECRET }).then(res => {
					if (res.status === 200 && res.data?.data?.token) {
						resolve(res.data.data.token)
					}else {
						resolve('')
					}
				})
			})
			
		},
		//上传bim或gis模型
		async uploadModel (files){
			if(this.element.config?.accept ){
				let types = files.name.split('.')
				if(!this.element.config?.accept.includes(types[types.length-1])){
					Message.error("上传格式错误！");
					return
				}
			}
			this.$loading({
				text: '上传中....'
			});
			let nameData = files.name.split('.')
			let requestUrl = ''
			let timestamp = Date.parse(new Date()) / 1000
			let formData = new window.FormData()
			formData.append('file', files.raw)
			let baseUploadResult = await this.requestUploadFun(formData)
			console.log(baseUploadResult,'baseUploadResult')
			if(baseUploadResult && baseUploadResult?.data?.code === 200 && baseUploadResult?.data?.data){
				const baseData = baseUploadResult.data.data
				if(files.name.includes('.svfzip') || files.name.includes('.3dtilezip')){
					requestUrl = 'https://models.bimcc.net/api/v3/upload/file'
					formData.append('name', nameData[0] + timestamp)
					let modelToken = await this.getModelToken()
					if(modelToken){
						axios({
							method: 'post',
							url: requestUrl ,
							headers: {
								'Content-Type': 'multipart/form-data',
								authorization: `Bearer ${modelToken}`
							}, // 请求头参数
							data: formData
						}).then((res) => {
							if(res.status === 200 && res.data.data){
								//回显上传文件
								this.filesData = [{filename:files.name,path:res.data.data.path,upTime: parseTime(new Date()),upUser:this.userInfo.name}]
								let obj = {
									model_id:'',
									img_path:'',
									used_version:'',
									name:baseData.filename,
									filesize:baseData.filesize,
									modelSize: bytesToSize(baseData.filesize),
									filepath:baseData.filepath,
									filename:baseData.filename,
								}
								if(res.data.data.info?.modelId){
									obj.model_id = res.data.data.info.modelId
								}
								if(res.data.data.info?.option?.picture){
									obj.img_path = 'https://models.bimcc.net/' + res.data.data.info.option.picture
								}
								
								if(this.goalType === 'bim'){
									this.$set(obj,'light_model_id','')
									//bim创建版本
									this.creatModelVersion(obj,res.data.data)
								} else {
									//gis目前暂时无版本
									this.element.value = [obj]
									this.$loading().close();
								}
								
							} else {
								this.$loading().close();
							}
						}).catch(err => {
							this.$loading().close();
							console.log(err, '-----');
						});
					}
				} else {
					//回显上传文件
					let resetData = JSON.parse(JSON.stringify(baseData))
					resetData.upTime = parseTime(new Date())
					resetData.upUser = baseData.user_name
					this.filesData = [resetData]
					let obj = {
						light_model_id:baseData.id,
						model_id:baseData.model_id,
						model_file_id:baseData.model_file_id,
						modelSize:bytesToSize(baseData.filesize),
						filepath:baseData.filepath,
						filesize:baseData.filesize,
						filename:baseData.filename,
						img_path:'',used_version:''
					}
					this.creatModelVersion(obj,baseData)
				}
			}
		},
		//存入数据
		requestUploadFun(formData){
			let result =  axios({
				method: 'post',
				url: baseUrl + 'api/mapi?__method_name__=file&token=' + this.token ,
				headers: {
					'Content-Type': 'multipart/form-data',
				}, // 请求头参数
				data: formData
			}).then((res) => {
				console.log(res,'res')
				if(res){
					return res
				} else {
					return null
				}
			})
			return result
		},
		//创建bim模型版本
		creatModelVersion (data, result) {
			// let version_code = name+'/'+parseTime(new Date(),'{y}-{m}-{d}')
			dataInterface({
				model_id:data.model_id,
				object_uuid:'object61766c46480b6',
				// version_code:version_code,
				__method_name__: "createData"
			}).then((res) => {
				this.$loading().close();
				if(res.data.code === 200){
					if(res.data.data?.id){
						data.used_version = res.data.data.id
					}
					if(result?.info?.f2d?.length){
						console.log(result.info.f2d,'result.f2d')
						data.f2dIdArr = []
						for( let i=0; i<result.info.f2d.length; i++ ){
							if(result.info.f2d[i].model_id){
								data.f2dIdArr.push(result.info.f2d[i].model_id)
							}
						}
					}
					this.element.value = [data]
				}
			}).catch(err => {
				this.$loading().close();
				console.log(err, '-----');
			});
		},

  },
	created() {
		this.userInfo = this.$GetUserInfo();
	}
}
</script>
