<!--
    @name: widget-form
    @description：widget-form
    @author: ZengWei
    @date: 2022-03-25 09:38
-->
<template>
  <el-form
    ref="childForm"
    size="medium"
    :model="element"
    :class="{'form-parser':formData.tableStyle,'normal-parser': !formData.tableStyle}"
    :show-message="!formData.tableStyle"
    :label-width="formData.labelWidth+'px'"
    :label-position="formData.labelPosition"
  >
    <template v-if="element.config.__config__.display === 'table' && showHideField(element.config.__config__)">
      <div class="table-wrapper" ref="tableWrapper" >
        <h5 class="table-title">{{ element.config.__config__.componentName }}</h5>
        <el-table ref="tableRef" max-height="700px" :data="virtualData" border class="w-full table-ref">
          <el-table-column align="center" :label="$t('FormItem.widget-form.245043-0')" width="60">
            <template slot-scope="scope">{{ start + scope.$index + 1 }}</template>
          </el-table-column>
          <template v-for="(eleItem,eleInd) in element.config.__config__.children">
            <el-table-column
              :key="eleItem.__config__?.formId + eleInd"
              :label="$getLocalizedLabel({'zhLabel': eleItem.__config__.label, 'enLabel': eleItem.__config__?.enLabel})"
              :min-width="eleItem.__config__.width || 160"
            >
              <template slot-scope="{ row, $index }">
                <div v-if="showHideField(row[eleInd].config.__config__)">
                  <div v-if="tableAllData.length < 100">
                    <el-form-item
                      :label="showLabel(row[eleInd])"
                      :label-width="showLabel(row[eleInd]) === '' ? '0px' : '120px'"
                      :prop="'value.' + $index + '.'+ eleInd + '.value'"
                      :rules="row[eleInd].validate"
                    >
                      <InputItem
                        :element="row[eleInd]"
                        :disabled="canEditField(row[eleInd].config.__config__?.formId)"
                        :editFields="editFields"
                        @change-calc="calcFormula($index)"
                        @trigger-active="triggerActive(arguments,$index)"
                      >
                      </InputItem>
                    </el-form-item>
                  </div>
                  <div v-else @mouseover="editable(row[eleInd])">
                    <span v-if="!row[eleInd].editable || notShowFormItem">
                      {{ row[eleInd].value ? row[eleInd].value : '&nbsp;' }}
                    </span>
                    <el-form-item
                      v-else-if="row[eleInd].editable"
                      :label="showLabel(row[eleInd])"
                      :label-width="showLabel(row[eleInd]) === '' ? '0px' : '120px'"
                      :prop="'value.' + $index + '.'+ eleInd + '.value'"
                      :rules="row[eleInd].validate"
                    >
                      <InputItem
                        :element="row[eleInd]"
                        :disabled="canEditField(row[eleInd].config.__config__?.formId)"
                        :editFields="editFields"
                        @trigger-active="triggerActive(arguments,$index)"
                      >
                      </InputItem>
                    </el-form-item>
                  </div>


                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column v-if="!disabled && element.config.__config__.cycle" :label="$t('FormItem.widget-form.245043-1')" fixed="right" width="50">
            <template slot="header">
              <i
                class="el-icon-circle-plus-outline add"
                @click="addChildForm(virtualData)"
              >
              </i>
            </template>
            <template slot-scope="scope">
              <i
                class="el-icon-remove-outline remove"
                @click="virtualData.splice(scope.$index,1)"
              >
              </i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template
      v-else-if="element.config.__config__.display === 'table-relate' && showHideField(element.config.__config__)">
      <div class="table-relate">
        <el-form-item
          :label="showLabel(element)"
          :label-width="showLabel(element) === '' ? '0px' : '120px'"
          class="child-form no-padding table-wrapper">
          <el-table ref="tableRef" :data="element.value" border class="w-full table-ref" v-loading="loading" >
            <el-table-column
              align="center"
              :fixed="fixed(element.config.__config__.relateConfig.__slot__.options)"
              width="65">
              <template slot="header">
                <el-button
                  v-if="!element.config.__config__.relateConfig.disabled && !canEditField(element.config.__config__?.formId)"
                  type="primary" size="mini" icon="el-icon-notebook-2"
                  @click="openSelectRelate(element)">
                </el-button>
              </template>
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column
              v-for="(itemR,indexR) in element.config.__config__.relateConfig.__slot__.options"
              :key="indexR"
              :label="$getLocalizedLabel({'zhLabel': itemR.label, 'enLabel': itemR?.enLabel})"
              :prop="itemR.value"
              :fixed="itemR?.fixed"
              :min-width="itemR.width || 130"
            >
              <template slot-scope="{ row, $index }">
                <template v-if="row[indexR].type">
                  <el-form-item
                    v-if="showHideField(row[indexR].config.__config__)"
                    :prop="'value.' + $index+ '.'+ indexR + '.value'"
                    :rules="row[indexR].validate"
                  >
                    <InputItem
                      :element="row[indexR]"
                      :disabled="canEditField(row[indexR].config.__config__?.formId)"
                      :editFields="editFields"
                      @change-calc="calcFormula($index)"
                      @trigger-active="triggerActive(arguments,$index)"
                    >
                    </InputItem>
                  </el-form-item>
                </template>
                <template v-else>
                  <div v-if="judgeImage(row[indexR].text) === 'img'">
                    <el-image
                      v-for="(item, imgIndex) in row[indexR].text"
                      :key="item.file_md5 + imgIndex"
                      style="width: 60px; height: 60px"
                      :src="item.url"
                      :preview-src-list="imageShow(row[indexR].text)">
                    </el-image>
                  </div>
                  <div v-else-if="judgeImage(row[indexR].text) === 'office'">
                    <el-link
                      v-for="(item, imgIndex) in row[indexR].text"
                      :key="item.file_md5 + imgIndex"
                      type="primary"
                      @click="openShowFile(item.path)">
                      {{ $t('FormItem.widget-form.245043-2') }}
                    </el-link>
                  </div>
                  <span v-else>{{ row[indexR].text }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-if="!element.config.__config__.relateConfig.disabled && !canEditField(element.config.__config__?.formId)"
              align="center" :label="$t('FormItem.widget-form.245043-1')" fixed="right"
              :width="element.config.__config__.relateConfig.__config__.detailShow ? 130 : 60">
              <template slot-scope="scope">
                <div style="display: flex;justify-content: space-around">
                  <div v-if="element.config.__config__.relateConfig.__config__.detailShow">
                    <el-button type="text" size="mini" icon="el-icon-view">{{ $t('FormItem.widget-form.245043-3') }}</el-button>
                  </div>
                  <div>
                    <i class="el-icon-remove-outline remove"
                       @click="element.value.splice(scope.$index,1)">
                    </i>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </div>
    </template>
    <template v-else-if="showHideField(element.config.__config__)">
      {{ labelName }}
      <el-form-item :label="labelName" class="child-form no-padding table-wrapper">
        <p style="margin-bottom: 8px;margin-right: 15px" v-if="cycleBool(element) && !disabled">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="addChildForm(element.value)">
            {{ $t('FormItem.widget-form.245043-4') }}
          </el-button>
        </p>
        <el-row
          v-for="(dd, nn) in element.value"
          :key="nn"
          :gutter="gutter"
          :type="element.config.type === 'flex' ? element.config.type : 'flex'"
          :align="element.config.align ? element.config.align : 'middle'"
          :justify="element.config.justify ? element.config.justify : 'start'"
          style="position: relative"
          :class="{ 'cycle-pr': cycleBool(element) }"
        >
          <el-col
            v-for="(element, ii) in dd.filter(i => i.config)"
            :key="ii + element.config.__config__?.formId"
            :span="element.config.__config__.span"
          >
            <el-form-item
              v-if="showHideField(element.config.__config__)"
              :prop="'value.' + nn + '.' + ii + '.value'"
              :rules="element.validate"
              :label="showLabel(element)"
            >
              <InputItem
                :element="element"
                :disabled="canEditField(element.config.__config__?.formId)"
                :editFields="editFields"
                @trigger-active="triggerActive(arguments,nn)"
              >
              </InputItem>
            </el-form-item>
          </el-col>
          <div class="type-action-btn" v-if="cycleBool(element) && !disabled">
            <i
              class="el-icon-remove-outline remove"
              @click="delChildForm(nn, element.value)"
            ></i>
          </div>
        </el-row>
      </el-form-item>
    </template>

    <el-dialog
      :title="$t('FormItem.widget-form.245043-5')"
      top="6vh"
      :visible.sync="relateVisible"
      width="80%"
      custom-class="common-dialog"
      :before-close="closeModal"
      append-to-body>
      <div class="dialog-main" v-if="relateVisible && relateConfig">
        <relateData
          ref="relateRef"
          :filter-rule="relateFilterRule"
          :body-params="relateBodyParams"
          :relate-data="relateData"
          :relate-config="relateConfig"
          :pageUUID="relatePageUUID"
          :pageParams="relateDialogParams"
        ></relateData>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModal">{{ $t('FormItem.widget-form.245043-6') }}</el-button>
        <el-button type="primary" @click="getRelateResult">{{ $t('FormItem.widget-form.245043-7') }}</el-button>
      </div>
    </el-dialog>
  </el-form>
</template>

<script>
/* eslint-disable */
import {Image,Link} from "element-ui";
import eventBus from '@/plugins/eventBus';
import {formRequest} from "@/apis/data/form";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import relateData from "@/custom-component/form/newParser/relateData";
import {formatValue, getBodyParams, getSearchData, optionData} from "@/custom-component/form/newParser/scripts/tools";
import {openUrl} from '@/utils/tools'
import { baseUrl } from '@/apis/http/request';
import {formValidate} from "@/custom-component/form/newParser/scripts/itemDataCopy";
import {calcImpl} from "@/plugins/calcRuleEngine";

export default {
  name: "WidgetForm",
  components: {
    relateData,
    'el-image': Image,
    'el-link': Link
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData', 'editFields', 'formData'],
  inject: ["parser"],
  data() {
    return {
      tableRef: null,
      tableWarp: null,
      virtualData: [], // 虚拟初始数据
      tableAllData: [],
      scrollTop: 0,
      num: 0,
      start: 0,
      end: 30,
      starts: 0,
      ends: 30,
      pageList: 10,
      itemHeight: 40,
      baseUrl: baseUrl,
      firstLineData: [],
      relateData: [],
      relateItemInd: '',
      relateVisible: false,
      relateConfig: null,
      relatePageUUID: '',
      relateDialogParams: {},
      relateFilterRule:[],
      relateBodyParams:{},
      initEleValue:[],
      relatePageData: null
    }
  },
  computed: {
    notShowFormItem(){
      return this.disabled === true && this.tableAllData.length > 10
    },
    gutter(){
      if(this.formData.tableStyle){
        return 0
      } else {
        return this.formData.gutter || 15
      }
    },
    imageShow(){
      return function(data){
        if(data instanceof Array){
          const imgArr = Array.from(data,item=>item.url)
          return imgArr
        }
        return []
      }
    },
    judgeImage(){
      return function(data){
        if(data instanceof Array){
          const str = JSON.stringify(data)
          if(
            str.indexOf('.jpg') || str.indexOf('.png') || str.indexOf('.gif') || str.indexOf('.jpeg')
          ) {
            return 'img'
          } else if (
            str.indexOf('.pdf') || str.indexOf('.doc') || str.indexOf('.docx') ||
            str.indexOf('.xls') || str.indexOf('.xlsx') ||
            str.indexOf('.ppt') || str.indexOf('.pptx')
          ) {
            return 'office'
          }
        }
        return false
      }
    },
    labelName() {
      return this.element.config.__config__.componentName.length > 6
        ? this.element.config.__config__.componentName.substr(0, 6) + '...'
        : this.element.config.__config__.componentName
    },
    cycleBool() {
      return function (item) {
        const config = item.config.__config__
        if (this.disabled) return false;
        if (config.cycle === undefined || config.cycle === true) return true;
        return config.cycle;
      };
    },
    showLabel() {
      return function (item) {
        const config = item.config.__config__
        if (config.showLabel === undefined || config.showLabel === false)
          return "";
        const labelName = this.element.config.__config__.componentName
        if(labelName){
          return this.labelName
        }
        return config.label;
      };
    },
    canEditField() {
      return function (formId) {
        const editFormId = Object.keys(this.editFields);
        if (editFormId.length > 0 && editFormId.includes(formId)){
          if(this.parser?.newFlow){
            return !(this.editFields[formId] === 1);
          }
          return this.editFields[formId];
        }
        return this.disabled;
      };
    },
    showHideField() {
      return function (config) {
        const formId = config.formId;
        const editFormId = Object.keys(this.editFields);
        if (editFormId.includes(formId)){
          if(this.parser?.newFlow){
            return [1,2].includes(this.editFields[formId]);
          }
          return true;
        }
        return config.addShow;
      };
    },
    fixed(){
      return function (conf){
        return !!conf.filter(item => item.fixed === true)?.length
      }
    }
  },
  watch: {
    virtualData: {
      handler(value) {
        if(this.tableAllData.length < 100){
          // 此处虚拟滚动，element赋值问题导致验证bug
          this.element.value = value
        }
      },
      deep: true
    },
    num(newVal){
      if (newVal > 1) {
        this.start = (newVal - 1) * this.pageList
        this.end = (newVal + 2) * this.pageList
        requestAnimationFrame(() => {
          this.tableWarp.style.transform = `translateY(${this.start * this.itemHeight}px)`
          this.virtualData = this.tableAllData.slice(this.start, this.end)
        })
      } else {
        this.start = 0
        requestAnimationFrame(() => {
          this.tableWarp.style.transform = `translateY(0px)`
          this.virtualData = this.tableAllData.slice(this.starts, this.ends)
        })
      }
    },
    // 切换显示修复层级问题
		relateVisible: {
			handler() {
				this.$nextTick(() => {
					try {
						const modalBox = document.querySelector('.v-modal');
            if (modalBox) {
              modalBox.style.zIndex = '3000'
            }
					} catch (err) {
						console.log(err, this.$t('FormItem.widget-form.245043-8'));
					}
				});
			},
			immediate: true,
			deep: true
    }
  },
  created() {
    this.firstLineData = formValidate(this.element.config.__config__.children,{});
    const eleConf = this.element.config.__config__;
    const field = this.element.field
    const autoFill = eleConf?.relateConfig?.__config__?.autoFill || false;
    const existRecover = Object.keys(this.displayData);

    if (existRecover.length > 0) {
      if(!existRecover.includes(field)) return;
      const childValue = this.displayData[field];
      if(childValue instanceof Array && childValue.length>0){
        //编辑时关联子表数据为空，展示第一条数据
        if (eleConf.display !== 'table-relate') {
          let goalData = this.firstLineData, valueData = [];
          for (let ele of childValue) {
            //深拷贝问题，表单同步变化bug
            let itemData = JSON.parse(JSON.stringify(goalData));
            const valueKey = []
            itemData.map((item1) => {
              item1.editable = false
              const tagIcon = item1.config.__config__.tagIcon;
              let multiple = item1.config.multiple || ''
              if(item1.type === 'treeSelect') {
                multiple =  item1.config.props.props.multiple
              }
              const valueWait = ele[item1.field];
              let newVal = formatValue(tagIcon,valueWait,multiple)
              if(newVal === null || newVal === "null") newVal = ''
              item1.value = newVal;
              valueKey.push(item1.field)
            });
            const keys = Object.keys(ele); // 将后端返回的其他数据原样回传，包含ID
            for (let val of keys){
              if(!valueKey.includes(val)){
                itemData.push({field:val, value:ele[val], type: 'hide'})
              }
            }
            valueData.push(itemData);
          }

          this.tableAllData = valueData
          if(this.tableAllData.length>100){
            this.virtualData = this.tableAllData.slice(this.start, this.end)
          } else {
            this.virtualData = valueData
          }
          this.element.value =  valueData;
        } else {
          this.recoverRelateData(eleConf,childValue)
        }
      }
    } else if (eleConf.display === 'table-relate' && autoFill) {
      this.autoFillRelateData(eleConf, field);
    } /*else if(eleConf.display !== 'table-relate') {
      this.element.value = [this.firstLineData]
    }*/
  },
  mounted() {
    eventBus.$on('auto-fill-relate',(comp)=>{
      const eleConf = this.element.config.__config__;
      const field = this.element.field
      const autoFill = eleConf?.relateConfig?.__config__?.autoFill || false;
      const formId = eleConf?.relateConfig?.__config__?.formId || false;
      if(autoFill && comp.includes(formId)){
        this.autoFillRelateData(eleConf, field);
      }
    });
    eventBus.$on('EDITOR_setTableSelectData',(data)=>{
      this.relatePageData = data
    })
    eventBus.$on('TRIGGER_selectChange',(formId)=>{
      const eleConf = this.element.config.__config__;
      const field = this.element.field
      const autoFill = eleConf?.relateConfig?.__config__?.autoFill || false;
      const filterRule = eleConf?.relateConfig?.__config__?.filterRule || []
      const formIdArr = filterRule.filter(item => item.valueType === 2)
      const compFormId = Array.from(formIdArr,item => item.value)
      if(autoFill && compFormId.includes(formId)){
        this.autoFillRelateData(eleConf, field);
      }
    })

    if(this.tableAllData.length > 100){
      this.$nextTick(()=>{
        this.tableRef = this.$refs.tableRef.bodyWrapper
        const rowDom = document.querySelector(".table-ref .el-table__row")
        this.itemHeight = rowDom.offsetHeight
        const rowDomAll = document.querySelectorAll(".table-ref .el-table__row")
        for (let row of rowDomAll){
          row.style.height = this.itemHeight + 'px'
        }

        // 主体改造
        let divWarpPar = document.createElement('div')
        divWarpPar.style.height = this.tableAllData.length * this.itemHeight + 'px'
        let divWarpChild = document.createElement('div')
        divWarpChild.className = 'fix-warp'
        divWarpChild.append(this.tableRef.children[0])
        divWarpPar.append(divWarpChild)
        this.tableRef.append(divWarpPar)
        // 被设置的transform元素
        this.tableWarp = document.querySelector(
          '.el-table .el-table__body-wrapper .fix-warp'
        )
        this.tableRef.addEventListener('scroll', this.scrollListener)
      })
    }
  },
  methods: {
    openShowFile(filepath){
      let url = 'https://weboffice.bimcc.net?furl='
      if (filepath.includes('https://')) {
        url = 'https://weboffice.bimcc.net?ssl=1&furl='
      }
      openUrl(url + filepath,filepath)
      // window.open(url + filepath)
    },
    editable(item){
      if(item.config?.disabled !== true){
        item.editable = true
      }
    },
    scrollListener(){
      // 滚动监听，虚拟滚动数据处理
      this.scrollTop = this.tableRef.scrollTop
      this.num = Math.floor(this.scrollTop / (this.itemHeight * this.pageList))
    },
    closeModal(){
      this.relateVisible = false;
      this.$nextTick(() => {
        try {
          const modalBox = document.querySelector('.v-modal');
          modalBox.style.zIndex = '3000'
        } catch (err) {
          console.log(err, this.$t('FormItem.widget-form.245043-8'));
        }
      });
    },
    // 子表单必填项数据验证
    validateFormInput(){
      if(this.$refs.childForm){
        this.$refs.childForm.validate((valid) => {
          if (!valid) {
            throw new Error(this.$t('FormItem.widget-form.245043-9'))
          }
        })
      }
    },
    // 详情回显关联子表数据
    async recoverRelateData(eleConf, cycleData) {
      this.loading = true
      const objectUuid = eleConf.relateConfig.__config__.objectUuid || '';
      const viewUuid = eleConf.relateConfig.__config__.viewUuid || '';
      const vModelKey = eleConf.relateConfig.__vModel__;
      let  vModelVal = Array.from(cycleData, item => item[vModelKey]);
      vModelVal = vModelVal.filter(function (s) {return s})
      const openFilter = eleConf.relateConfig.__config__.openFilter || false;
      const relateFilterRule = eleConf.relateConfig.__config__.filterRule || [];
      let relateFormId = eleConf.relateConfig.__config__.formId;
      let searchData = [], bodyParams = {}; //过滤条件
      if(openFilter){
        searchData = getSearchData(relateFilterRule,this.parser.getFormIdValue);
        bodyParams = getBodyParams(relateFilterRule,this.parser.getFormIdValue);
      }
      let url,params;
      if(objectUuid && viewUuid && !vModelVal.includes(undefined)){
        url = '/api/mapi'
        params = {
          __method_name__: 'dataList',
          object_uuid: objectUuid,
          view_uuid: viewUuid,
          __now_archi_type: this.parser.nowArchiType,
          // transcode: 0,
        }
        if(searchData.length) params.search = searchData
        if(Object.keys(bodyParams).length){
          params = Object.assign(params, bodyParams)
        }
        let response = await formRequest('post', url, params);
        let responseData;
        if (response.data.data.data) {
          responseData = response.data.data.data
        } else {
          responseData = response.data.data
        }
        const metadata = response.data.metadata
        let idKey = ''; //查找id的键字段
        for (let key in metadata){
          if(metadata[key] === 'id'){
            idKey = key; break;
          }
        }
        // metadata中存在多个ID bug vModelKey
        let rows = responseData.filter(
          item => (
            vModelVal.includes(item[idKey]) ||
            vModelVal.includes(item[idKey]+'') ||
            vModelVal.includes(item[vModelKey]) ||
            vModelVal.includes(item[vModelKey]+'')
          )
        );
        const configData = eleConf.relateConfig.__slot__.options;
        let itemValue=[];
        if(rows instanceof Array && cycleData instanceof Array && rows.length){
          for (let itemC of cycleData){
            let itemData = [], itemOtherKey = [];
            for (let item of configData) {
              if(item.element){
                let fieldKey = item.element.field
                // 后端数据格式化
                const tagIcon = item.element.config.__config__.tagIcon;
                let multiple = item.element.config.multiple || ''
                if(item.element.type === 'treeSelect')
                  multiple = item.element.config.props.props.multiple
                const valueWait = itemC[fieldKey];
                item.element.value = formatValue(tagIcon,valueWait,multiple)
                let conf = JSON.parse(JSON.stringify(item.element))
                const confR = eleConf.relateConfig;
                const rowFV = itemC[confR.__vModel__];
                let row = rows.find(item1=>item1[idKey] == rowFV)
                if(row){
                  //关联子表下拉动态数据依赖关联数据ID
                  const options = await this.dynamicSelectData(conf,relateFormId,row[idKey])
                  if(options) conf.config.__slot__.options = options;
                }
                // conf.other = row
                itemData.push(conf)
              } else {
                let conf = JSON.parse(JSON.stringify(item))
                const confR = eleConf.relateConfig;
                const rowFV = itemC[confR.__vModel__];
                let row = rows.find(item1=>item1[idKey] == rowFV)
                if(!row) {
                  // 关联数据id不是id字段而是关联字段  @万元满 设备进场4.0
                  idKey = confR.__vModel__
                  row = rows.find(item1=>item1[idKey] == rowFV)
                }
                conf.showField = conf.value
                conf.text = row?.[conf.value];
                conf.field = confR?.__vModel__;
                conf.value = row?.[idKey]
                itemData.push(conf)
              }
              itemOtherKey.push(item.value)
            }
            // 将后端返回的其他数据原样回传，包含ID
            const keys = Object.keys(itemC);
            for (let val of keys){
              if(!itemOtherKey.includes(val)){
                itemData.push({field:val, value:itemC[val], type: 'hide'})
              }
            }
            itemValue.push(itemData)
          }
          sessionStorage.setItem(this.element.field, itemValue.length)
        }
        this.initEleValue = itemValue
        this.element.value = itemValue
      }
      this.loading = false
    },
    // 子表下拉选择依赖关联数据ID过滤
    async dynamicSelectData(conf,relateFormId,relateId){
      const eleConfig = conf.config;
      if(
        conf.type === 'select' &&
        eleConfig.__config__.dataType === 'dynamic' &&
        eleConfig.__config__.openFilter
      ) {
        let searchData = [], bodyParams = {}; //过滤条件
        if(eleConfig.__config__.filterRule){
          searchData = getSearchData(eleConfig.__config__.filterRule,this.parser.getFormIdValue);
          bodyParams = getBodyParams(eleConfig.__config__.filterRule,this.parser.getFormIdValue);
        }
        let url, params, options = [];
        url = '/api/mapi'
        params = {
          __method_name__: 'dataList',
          object_uuid: eleConfig.__config__.objectUuid,
          view_uuid: eleConfig.__config__.viewUuid,
          size: 1000,
          __now_archi_type: this.parser.nowArchiType,
          // transcode: 0,
        }
        if(searchData.length) params.search = searchData
        if(Object.keys(bodyParams).length) {
          params = Object.assign(params, bodyParams)
        }
        let response = await formRequest('post', url, params);
        let userData;
        if (response.data.data.data) {
          userData = response.data.data.data
        } else {
          userData = response.data.data
        }
        let label = eleConfig.props.props.label;
        let value = eleConfig.props.props.value;
        if (userData instanceof Array) {
          for (let element of userData) {
            if (element[label] && element[value]) {
              let item = {label: element[label], value: element[value],allField:element};
              options.push(item);
            }
          }
        }
        return options
      }
      return false;
    },
    getRelateResult(){
      if(this.relatePageUUID){
        const dataIdUUid = this.relatePageData?.idUUID || ''
        const selectedList = this.relatePageData?.data || []
        if(dataIdUUid){
          this.closeModal()
          this.selectRelateResult(dataIdUUid,selectedList)
        }
      } else {
        this.$nextTick(()=>{
          this.closeModal()
          const selection = this.$refs.relateRef.getSelection()
          this.selectRelateResult(selection.idKey,selection.rows)
        })
      }
    },
    //选择关联数据后处理
    async selectRelateResult(idKey,rows){
      const element = this.element;
      let configData = element.config.__config__.relateConfig.__slot__.options;
      // let relateFormId = element.config.__config__.relateConfig.__config__.formId;
      // let otherComp = configData.filter(item => item.element);
      let relateField = element.config.__config__.relateConfig.__vModel__;
      let existId = [] // 已存在的回显数据 - 作为是否清空数据的条件
      for (let eleVal of this.initEleValue){
        Array.from(eleVal,item=>{
          if(item.field === relateField && !existId.includes(item.value)){
            existId.push(item.value);
            return item.value
          }
        })
      }
      let itemValue=[];
      /******************子表数据填充start*****************/
      const fillMatch = this.element._fillMatch
      const fillData = this.element._fillData
      const fillMatchKeys = Object.keys(fillMatch)
      const fillDataKeys = Object.keys(fillData)
      /******************子表数据填充End*****************/
      for (let row of rows){
        row.id = row[idKey]
        let itemData = [];
        for (let item of configData) {
          if(item.element){
            let conf = JSON.parse(JSON.stringify(item.element))
            if(['select','user','cascader','treeSelect'].includes(conf.type)){
              await this.getDynamicData(conf);
            }
            const defaultVal = conf.config.__config__.defaultValue
            if(!existId.includes(row.id)){
              // 详情回显新增情况子表数据清空
              if(conf.value instanceof Array){
                conf.value = []
              } else if(conf.value instanceof Object){
                conf.value = {}
              } else if(conf.value instanceof Boolean){
                conf.value = false
              } else {
                conf.value = ''
              }
              if(defaultVal) {
                conf.value = defaultVal + ''
              }
            }
            conf.other = row
            itemData.push(conf)
          }else{
            let conf = JSON.parse(JSON.stringify(item))
            const confR = element.config.__config__.relateConfig;
            conf.showField = conf.value
            conf.text = row[conf.value];
            conf.field = confR.__vModel__;
            conf.value = row[idKey]
            itemData.push(conf)
          }
        }
        // 查找已存在并且有输入的值的数据，并保持数据不变
        const goal = itemData.find(t => t.field === relateField)
        if(goal){
          const existData = this.findExistRelate(relateField,goal.value)
          if(existData){
            itemData = existData
          }
        }
        /******************子表数据填充start*****************/
        for (let ele of itemData){
          if(ele.config){
            const formId = ele.config.__config__.formId;
            if(fillMatchKeys.includes(formId)){
              const matchVal = fillMatch[formId]
              if(fillDataKeys.includes(matchVal)){
                ele.value = fillData[matchVal]
              }
            }
          }
        }
        /******************子表数据填充end*****************/
        itemValue.push(itemData)
      }
      this.element.value = itemValue;
    },
    findExistRelate(key,val){
      for (let itemData of this.element.value){
        const goal = itemData.find(t=> t.field === key && t.value === val)
        if(goal){
          return itemData
        }
      }
      return false
    },
    async autoFillRelateData(eleConf, field) {
      this.loading = true
      //关联数据不点击选择按钮，筛选条件自动填充
      const objectUuid = eleConf.relateConfig.__config__.objectUuid || '';
      const viewUuid = eleConf.relateConfig.__config__.viewUuid || '';
      const openFilter = eleConf.relateConfig.__config__.openFilter || false;
      const relateFilterRule = eleConf.relateConfig.__config__.filterRule || [];

      let searchData = [], bodyParams = {}; //过滤条件
      if (openFilter) {
        searchData = getSearchData(relateFilterRule,this.parser.getFormIdValue);
        bodyParams = getBodyParams(relateFilterRule,this.parser.getFormIdValue);
      }
      let url, params;
      if (objectUuid && viewUuid) {
        url = '/api/mapi'
        params = {
          __method_name__: 'dataList',
          object_uuid: objectUuid,
          view_uuid: viewUuid,
          __now_archi_type: this.parser.nowArchiType,
        }
        if(searchData.length>0){
          params.search = searchData
        }
        if(Object.keys(bodyParams).length) {
          params = Object.assign(params, bodyParams)
        }

        let response = await formRequest('post', url, params);
        let responseData;
        if (response.data.data.data) {
          responseData = response.data.data.data
        } else {
          responseData = response.data.data
        }
        const metadata = response.data.metadata
        let idKey = ''; //查找id的键字段
        for (let key in metadata) {
          if (metadata[key] === 'id') {
            idKey = key;
            break;
          }
        }
        let rows = responseData
        const configData = eleConf.relateConfig.__slot__.options;
        let itemValue = [];
        /******************子表数据填充start*****************/
        const fillMatch = this.element._fillMatch
        const fillData = this.element._fillData
        const fillMatchKeys = Object.keys(fillMatch)
        const fillDataKeys = Object.keys(fillData)
        /******************子表数据填充End*****************/
        if (rows instanceof Array && rows.length) {
          for (let itemC of rows){
            let itemData = [];
            for (let item of configData) {
              if (item.element) {
                let conf = JSON.parse(JSON.stringify(item.element))
                if(['select','user','cascader','treeSelect'].includes(conf.type)){
                  await this.getDynamicData(conf);
                }
                // 关联数回显 - 存在详情数据时
                const relateIdKey = eleConf.relateConfig.__vModel__;
                const recoverKeys = Object.keys(this.displayData);
                if(recoverKeys.includes(field)){
                  const cycleData = this.displayData[field]
                  const goal = cycleData.find(el=>el[relateIdKey] == itemC[idKey])
                  if(goal){
                    conf.value = goal[0][conf.field]
                  }
                }
                // conf.other = row
                itemData.push(conf)
              } else {
                let conf = JSON.parse(JSON.stringify(item))
                const confR = eleConf.relateConfig;
                conf.showField = conf.value
                conf.text = itemC[conf.value];
                conf.field = confR.__vModel__;
                conf.value = itemC[idKey]
                itemData.push(conf)
              }
            }
            /******************子表数据填充start*****************/
            for (let ele of itemData){
              if(ele.config){
                const formId = ele.config.__config__.formId;
                if(fillMatchKeys.includes(formId)){
                  const matchVal = fillMatch[formId]
                  if(fillDataKeys.includes(matchVal)){
                    ele.value = fillData[matchVal]
                  }
                }
              }
            }
            /******************子表数据填充end*****************/
            itemValue.push(itemData)
          }
          const maxNumber = this.element.config.__config__?.maxNumber || 0
          if(maxNumber>0 && itemValue.length > maxNumber) {
            itemValue = itemValue.slice(0, maxNumber)
          }
          sessionStorage.setItem(this.element.field, itemValue.length)
          this.element.value = itemValue
        }
      }
      this.loading = false
    },
    //选择关联数据弹窗
    openSelectRelate(element){
      const configData = element.config.__config__.relateConfig
      let searchData = [], bodyParams = {};
      if(configData.__config__.dialogParams && configData.__config__.dialogParams.length>0){
        const formIdValue = this.parser.getFormIdData()
        const dialogParams = {}
        for (const item of configData.__config__.dialogParams) {
          if(formIdValue[item.value]){
            dialogParams[item.label] = formIdValue[item.value]
          }
        }
        console.log(dialogParams, this.$t('FormItem.widget-form.245043-10'))
        this.relateDialogParams = dialogParams
      }
      if(
        configData.__config__.openFilter &&
        configData.__config__.filterRule &&
        configData.__config__.filterRule instanceof Array
      ){
        let filterRule = configData.__config__.filterRule
        searchData = getSearchData(filterRule,this.parser.getFormIdValue);
        bodyParams = getBodyParams(filterRule,this.parser.getFormIdValue);
      }
      let valueId = [];
      for (let item of element.value){
        if(item[0]?.value) valueId.push(item[0].value)
      }
      // TIPS 如果没有有效的值的话。relatePageData 不会刷新，导致在外面删除的数据像幽灵数据存在
      if(!valueId.length) {
        this.relatePageData = null;
      }
      this.relateData = valueId; //回显数据
      this.relateFilterRule = searchData; // 组装筛选条件
      this.relateBodyParams = bodyParams; // 组装筛选条件
      this.relateConfig = configData;
      this.relatePageUUID = configData.__config__?.pageUuid || ''
      this.relateVisible = true
    },
    /**
     * 子表公式计算
     * @param index
     */
    calcFormula(index){
      const rowInput = this.element.value[index]

      const formIdData = this.parser.getFormIdData();
      const formIdValue = {};
      for (const item of rowInput) {
        if(item?.type && item.type !== 'hide') {
          let field = item.config.__config__.formId
          formIdValue[field] = item.value
        }
      }
      const formIdDataObj = Object.assign(formIdData,formIdValue)

      // 公式计算
      const formulaInput = rowInput.find(t=>t.type === 'input' && t.config.__config__.openRule)
      if(formulaInput) {
        const rowValue = {};
        let childFormField = this.element.field
        const formData = this.parser.getFormData();
        for (const item of rowInput) {
          if(item?.type) {
            let field = childFormField + '.' + item.field
            rowValue[field] = item.value
          } else {
            let field = item.field + '.' + item.showField
            rowValue[field] = item.text
          }
        }
        const formDataObj = Object.assign(formData,rowValue)
        localStorage.setItem('TEMP_formData',JSON.stringify(formDataObj))
        const calcRules = formulaInput.config.__config__.calcRules
        let express = '';
        if(calcRules instanceof Array){
          for (let ele of calcRules) {
            if(ele.ruleType === 'FUNCTION'){
              express += ele.funcName
            } else if(ele.ruleType === 'COMPONENT_VALUE'){
              express += `calcField('`+ele.value+`')`
            } else if(ele.ruleType === 'STATIC'){
              if (parseInt(ele.value)){
                express += ele.value
              } else {
                express += `'`+ele.value+`'`
              }
            } else {
              express += ele.displayName
            }
          }
        } else {
          express = calcRules.express
        }
        try {
          let value = calcImpl(express)
          if(value && formulaInput.config?.precision){
            value = parseFloat(value).toFixed(formulaInput.config.precision);
          }
          if(value) formulaInput.value = ''+value
        } catch (e) {
          console.error(e, this.$t('FormItem.widget-form.245043-11'))
          this.$message.error(this.$t('FormItem.widget-form.245043-12'));
          return
        }
      }

      // 数据过滤处理
      for (const col of rowInput) {
        const filterRule = col?.config?.__config__?.filterRule || []
        if(col.type === 'select' && filterRule.length) {
          console.log(formIdDataObj, 9999)
          let url= '/api/mapi', params;
          const objectUuid = col.config.__config__.objectUuid
          const viewUuid = col.config.__config__.viewUuid
          const getFormIdValue = (key) => {
            return formIdDataObj[key] || ''
          }
          let searchData = getSearchData(filterRule,getFormIdValue);
          params = {
            __method_name__: 'dataList', object_uuid: objectUuid,
            view_uuid: viewUuid, search: searchData, size: 1000,
            __now_archi_type: this.parser.nowArchiType,
          }
          if(searchData.length) params.search = searchData
          let bodyParams = getBodyParams(filterRule,getFormIdValue);
          if(Object.keys(bodyParams).length) {
            params = Object.assign(params, bodyParams)
          }
          if(objectUuid && viewUuid){
            formRequest('post', url, params).then(res => {
              let respData;
              respData = res.data.data;
              if (res.data.data.data) {
                respData = res.data.data.data;
              }
              const tag = col.config.__config__.tagIcon;
              const propLabel = col.config.props.props.label;
              const propValue = col.config.props.props.value;
              const options = optionData(respData,tag,propLabel,propValue)
              if(options){
                col.config.__slot__.options = options
              }
            });
          }
        }
      }
    },
    triggerActive(params, index) {
      const compShowHide = params[0]; // 受控控件
      const ctrlType = params[1] ?? '' // 交互类型 - 默认显示隐藏
      const optionData = params[2] ?? ''// 级联数据
      if (ctrlType === 'cascader') {
        // this.$emit('trigger-active',compShowHide,ctrlType,optionData)
        const childFormItem = this.element.value
        if (childFormItem instanceof Array) {
          for (let eleItem of childFormItem[index]) {
            if (eleItem.config) { //关联子表展示数据无配置
              let childFormId = eleItem.config.__config__.formId;
              for (let val of compShowHide){
                if(val === childFormId && eleItem.type ==='position'){
                  // 修改部位级联参数值
                  setTimeout(()=>{
                    // 加载顺序时间问题导致部位不能回显
                    eleItem.config.__config__.cascade = parseInt(optionData)
                  },200)
                }
                if(val === childFormId){
                  eleItem.config.__slot__.options = optionData;
                }
              }
            }
          }
        }
      } else if (ctrlType === 'selectFill') {

      } else if(ctrlType === 'positionModel'){
        this.positionModel(optionData,index)
      } else {
        // 显示隐藏交互
        const ctrlFormId = Object.keys(compShowHide);
        const childFormItem = this.element.value;
        if (childFormItem instanceof Array) {
          for (let eleItem of childFormItem[index]) {
            if (eleItem.config) { //关联子表展示数据无配置
              const childFormId = eleItem.config.__config__.formId;
              if (ctrlFormId.includes(childFormId)) {
                eleItem.config.__config__.addShow = compShowHide[childFormId]
              }
            }
          }
        }
      }
    },
    // 部位改变（部位绑定模型）联动模型
    positionModel(model,index){
      const childFormItem = this.element.value;
      for (const element of childFormItem[index]){
        if(element.type === 'bimList'){
          let arr = []
          if(model.length !== 0){
            for(let i=0; i<model.length; i++){
              arr = arr.concat(model[i])
            }
            this.$nextTick(()=>{
              element.value = arr
            })
          }else{
            this.$nextTick(()=>{
              element.value = []
            })
					}
        }
      }
    },
    addChildForm(data) {
      /******************子表数据填充start*****************/
      const fillMatch = this.element._fillMatch
      const fillData = this.element._fillData
      const fillMatchKeys = Object.keys(fillMatch)
      const fillDataKeys = Object.keys(fillData)
      /******************子表数据填充start*****************/
      const item = this.firstLineData
      //深拷贝问题，表单同步变化bug
      let itemData = [];
      const newCopyData = JSON.parse(JSON.stringify(item))
      for (let ele of newCopyData) {
        const defVal = ele.config?.__config__?.defaultValue
        if (ele.config.__config__.addShow && (['',null,undefined].includes(defVal))) { //隐藏字段不重置
          if (typeof ele.value == "string") {
            ele.value = "";
          } else if (typeof ele.value == "number") {
            ele.value = 0;
          } else if (typeof ele.value == "boolean") {
            ele.value = false;
          } else {
            ele.value = [];
          }
        }
        /******************子表数据填充start*****************/
        const formId = ele.config?.__config__?.formId
        if (fillMatchKeys.includes(formId)) {
          const matchVal = fillMatch[formId]
          if (fillDataKeys.includes(matchVal)) {
            ele.value = fillData[matchVal]
          }
        }
        /******************子表数据填充start*****************/
        // ele.config.disabled = false;
        let itemDd = {
          config: ele.config,
          field: ele.field,
          type: ele.type,
          validate: ele.validate,
          value: ele.value,
        };
        itemData.push(itemDd);
      }
      const maxNumber = this.element.config.__config__?.maxNumber || 0
      if(maxNumber > 0  && data.length <= maxNumber) {
        data.push(itemData);
      } else if (maxNumber === 0) {
        data.push(itemData);
      }
    },
    delChildForm(index, data) {
      data.splice(index, 1);
    },
  },
  beforeDestroy() {
    // eventBus.$off('auto-fill-relate');
    // eventBus.$off('EDITOR_setTableSelectData');
    // eventBus.$off('TRIGGER_selectChange');
    // 移除暂存子表数据
    sessionStorage.removeItem(this.element.field);
  }
}
</script>
<style>
.topest{
  z-index: 9999 !important;
}
</style>
<style lang="less" scoped>
:deep(.common-dialog) {

  .el-dialog__body{
    padding: 0;
  }

  .dialog-main {
    height: calc(100vh - 220px);
  }
}


:deep(.el-table) {
  width: 100%;
  /*.el-table__header-wrapper table,.el-table__body-wrapper table{
    width: 100% !important;
  }*/
  .el-table__body, .el-table__footer, .el-table__header{
    table-layout: auto;
  }
}
.table-title {
  font-size: 14px;
  line-height: 44px;
  text-align: center;
  background: #f8f8f9;
  border: 1px solid #eaebed;
  border-bottom: none;
}

.cycle-pr {
  padding-right: 40px;
  margin-bottom: 10px;
}

.type-action-btn {
  position: absolute;
  right: 10px;
  top: 7px;
}

.add {
  color: #0076ff;
  cursor: pointer;
  font-size: 23px;
}

.remove {
  color: #e83525;
  cursor: pointer;
  font-size: 23px;
}
</style>
