/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-23 17:10:27
 * @FilePath: \dataview-viewer-test\src\custom-component\special\lang\zh.js
 */

export default {
  "special.CommonConfigPage.371916-0": "编辑",
  "special.CommonConfigPage.371916-1": "提交",
  "special.CommonConfigPage.371916-2": "无效的数据，请稍后再试",
  "special.CommonConfigPage.371916-3": "请求数据失败，请稍后再试",
  "special.CommonConfigPage.371916-4": "新增数据失败",
  "special.CommonConfigPage.371916-5": "保存成功"
}


