<!--
 * @Description: 柱状图
 * @Author: luocheng
 * @Date: 2022-01-07 09:22:05
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-07-09 10:30:28
-->
<template>
	<div
		class="chart-bar"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
		:element-loading-background="loadingBackColor"
	>
		<ChartContainer
			v-if="option && canShow"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
		></ChartContainer>
		<el-empty
			v-else
		></el-empty>
	</div>
</template>

<script>
/* eslint-disable */
import mixin from './mixin';

export default {
	name: 'ChartBar',
	mixins: [mixin],
	data() {
		return {
			option: null,
			attributes: null
		};
	},
	created() {
		this.getOptions();
	},
	watch: {
		'$i18n.locale': {
			handler(n, o) {
				if(n && n !== o) {
					this.getOptions()
				}
			}
		}
	},
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {Array} data
		 */
		async setOptions(data) {
			if (!data || !data.length) return false;
			const { xData = [], series = [], names = '' } = data[0];
			if (!xData?.length) {
				this.canShow = false;
				return false;
			}
			const { attributes: sourceAttributes = {} } = this.chartData;
			const attributes = JSON.parse(JSON.stringify(sourceAttributes));
			/**
			 * 国际化 英文翻译
			 */
			if(this?.$i18n?.locale === 'en') {
				// 转换内容
				const tasks = []
				// 需要转换的内容
				if(attributes.showTitle) {
					tasks.push({ target: attributes, key: 'title' });
				}
				if(attributes.xAxisText) {
					tasks.push({ target: attributes, key: 'xAxisText' });
				}
				if(attributes.yAxisText) {
					tasks.push({ target: attributes, key: 'yAxisText' });
				}
				names.forEach((el, index) => {
					tasks.push({ target: names, key: index });
				})
				series.forEach(el => {
					tasks.push({ target: el, key: 'name' });
				})
				Object.keys(xData).forEach((key) => {
					tasks.push({ target: xData, key: key });
				})
				await this.translate2English(tasks);
			}
			this.attributes = attributes;
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				showTooltip = true,
				tooltipFontsize = 14,
				showLegend = true,
				legendTextColor = '#000000',
				legenFontSize = 12,
				colorRange = [],
				barWidth = 20,
				showLine,
				showBarLabel = true,
				barChart = false,
				axisFontSize = 12,
				axisTextColor = '#ffffff',
				xAxisText = '',
				xAxisTextFontSize = 12,
				xAxisTextColor = '#ffffff',
				yAxisText = '',
				yAxisTextFontSize = 12,
				yAxisTextColor = '#ffffff',
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
				legendIcon = 'circle',
				showDataZoom = false,
				dataZoomBottom = 'auto',
				dataZoomStart = 0,
				dataZoomEnd = 100,
				legendOrient = 'vertical',
				legendType = 'plain',
				usePolar = false,
				minInterval = 0,
				labelRotate = 0,
        labelHideLength = 0,
				dataZoomBackGround ='rgba(47,69,84,0)',
				dataZoomFill = 'rgba(167,183,204,0.4)',
				areaStyleColor = '#d2dbee',
				areaStyleOpacity=  0.2,
				areaStyleShadowColor = '',
				selectedDataAreaColor ='#8fb0f7',
				selectedDataAreaOpacity = 0.2,
				selectedDataAreaShadowColor = '',
				dateZoomHeight = 30,
				moveHandleColor = '#D2DBEE',
				hiddenXAxis = false,
				hiddenYAxis = false
			} = attributes;
			const xAxis = {
				show: !hiddenXAxis,
				type: 'category',
				data: xData,
				name: xAxisText,
				nameTextStyle: {
					color: xAxisTextColor,
					fontSize: this.scaleSize(xAxisTextFontSize),
					align: 'right'
				},
				axisTick: {
					alignWithLabel: true,
					show: false
				},
				splitLine: {
					show: false //去掉网格线
				},
				axisLabel: {
					textStyle: {
						color: axisTextColor,
						fontSize: this.scaleSize(axisFontSize)
					},
					rotate: labelRotate,
					// interval: 0,
					margin: 16, // 文案与坐标轴间距
          formatter: function (params){
            if(labelHideLength){
              if( params.length > labelHideLength )
                return params.substring(0, labelHideLength) + '...';
              else
                return params;
            }else {
              return params;
            }
          },
        },
				axisLine: {
					show: true // 隐藏坐标轴
				}
			};
			const yAxis = [
				{
					show: !hiddenYAxis,
					type: 'value',
					name: yAxisText,
					nameTextStyle: {
						color: yAxisTextColor,
						fontSize: this.scaleSize(yAxisTextFontSize),
						align: 'center'
					},
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: true,
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						}
					}
				},
				{
					type: 'value',
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: false // 隐藏坐标轴
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: false,
						textStyle: {
							color: axisTextColor,
							fontSize: this.scaleSize(axisFontSize)
						}
					}
				}
			];
			if(!isNaN(Number(minInterval))) {
				const number = Number(minInterval)
				yAxis.forEach(el => {
					el.minInterval = number
				})
				if(labelHideLength>0){
					xAxis.axisLabel.interval=0;
				}
			}
			this.option = {
				title: {
					show: showTitle,
					text: title,
					left: 'left',
					y: 'top',
					textStyle: {
						color: textColor,
						fontFamily: '微软雅黑',
						fontSize: this.scaleSize(titleFontSize || 12),
						fontWeight: titleFontWeight || 400,
						left: titleLeft,
						top: titleTop,
						bottom: titleBottom,
						right: titleRight
					}
				},
				tooltip: {
					show: showTooltip,
					trigger: 'axis',
					axisPointer: {
						type: 'line'
					},
					textStyle: {
						fontSize: this.scaleSize(tooltipFontsize || 14),
					},
					appendToBody: true
				},
				legend: {
					show: showLegend,
					itemWidth: 10,
					itemHeight: 10,
					data: this.getLegendData(data[0], legendTextColor, showLine),
					icon: legendIcon,
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
					textStyle: {
						fontSize: this.scaleSize(legenFontSize)
					}
				},
				color: colorRange || [],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				dataZoom: [
					{
						type: 'slider',
						show: showDataZoom,
						backgroundColor: dataZoomBackGround,
						fillerColor: dataZoomFill,
						dataBackground: {
							areaStyle: {
								color: areaStyleColor,
								opacity: areaStyleOpacity,
								shadowColor: areaStyleShadowColor
							}
						},
						selectedDataBackground: {
								areaStyle: {
									color: selectedDataAreaColor,
									opacity:selectedDataAreaOpacity
								},
								shadowColor: selectedDataAreaShadowColor
						},
						start: dataZoomStart || 0,
						end: dataZoomEnd || 100,
						// bottom: isNaN(dataZoomBottom) ? dataZoomBottom : Number(dataZoomBottom),
						// height: dateZoomHeight,
						moveHandleStyle: {
							color: moveHandleColor
						}
					}
				],
				series: this.getSeries(series, this.scaleSize(barWidth))
			};
			// 条状态缩放兼容
			if(barChart) {
				this.option.dataZoom[0].yAxisIndex = [0]
				this.option.dataZoom[0].left = isNaN(dataZoomBottom) ? dataZoomBottom : Number(dataZoomBottom)
				this.option.dataZoom[0].width = dateZoomHeight
			} else {
				this.option.dataZoom[0].xAxisIndex = [0]
				this.option.dataZoom[0].bottom = isNaN(dataZoomBottom) ? dataZoomBottom : Number(dataZoomBottom)
				this.option.dataZoom[0].height = dateZoomHeight
			}
			// 使用极坐标
			if(usePolar) {
				this.option.polar = {
					radius: [30, '80%']
				}
				this.option.angleAxis = {
					max: Math.max(...this.option.series.map(el => Math.max(...el.data.map(ele => ele.value)))) * (4 / 3),
					startAngle: 90
				}
				this.option.radiusAxis = {
					type: 'category',
					data: yAxisText
				}
				this.option.tooltip.show = false
			} else {
				this.option.xAxis = barChart ? yAxis : xAxis
				this.option.yAxis = barChart ? xAxis : yAxis
			}
		},
		/**
		 * @desc: 获取series
		 * @param {Array} series
		 * @param {Number} barWidth
		 */
		getSeries(series, barWidth) {
			let s = [];
			if (!series) {
				return [];
			}
			// const { attributes = {} } = this.chartData;
			const { XColorRange = [], distinguishColor = false, barChart = false, toFixedCount = -1, useGradient = false, gradientRange = [], usePolar = false, markLines } = this.attributes;
			for (let i = 0, len = series.length; i < len; i++) {
				const el = series[i];
				// TIPS 新增保留位数确认。默认全部显示（undefined/nul, -1）, 数字表示位数
				let data = (el.data || []).map(el => {
					let v = el;
					if (toFixedCount === -1 || toFixedCount === undefined || toFixedCount === null) {
						return v;
					}
					if (!isNaN(+el)) {
						return (+el).toFixed(toFixedCount);
					}
					// 判断小数位数（暂时去掉，不具备通用性，需要再说)
					// if(parseInt(v) !== v) {
					// 	const t = v.toString();
					// 	if (t.includes('.')) {
					// 		const tarr = t.split('.');
					// 		if(tarr[tarr.length - 1].length > toFixedCount) {
					// 			v = Number((+el).toFixed(toFixedCount));
					// 		}
					// 	}
					// }
					return v;
				});
				if (distinguishColor || useGradient) {
					data = data.map((value, index) => {
						return {
							value,
							itemStyle: this.getItemStyle(distinguishColor, useGradient, barChart, index, i, XColorRange, gradientRange)
						}
					})
				}
				if(this.indialog) {
					barWidth = barWidth * 2;
				}
				const markLine = {
					data: []
				}
				if(Array.isArray(markLines) && markLines.length) {
					const markLineData = []
					markLines.forEach(item => {
						const { labelName = '默认标题', lineColor = '', type = '', fixedValOne = '', fixedValTow = '', attachmentVal = '' } = item
						const obj = {
							name: labelName,
							label: {
								formatter: '{b}-{c}',
								position: 'insideStart'
							}
						}
						if(type === 'attachment' && !isNaN(attachmentVal) && i === 0) {
							obj.yAxis = attachmentVal
						} else if (type === 'fixed' && !isNaN(fixedValOne) && !isNaN(fixedValTow) && i === 0) {
							const startObj = {
								name: 'start: ' + labelName,
								coord: [0, Number(fixedValOne)],
								label: {
									formatter: labelName,
									position: 'insideStart'
								}
							}
							const endObj = {
								name: 'end: ' + labelName,
								coord: [el.data.length - 1, Number(fixedValTow)]
							}
							if(lineColor) {
								startObj.lineStyle = {
									color: lineColor
								}
								endObj.lineStyle = {
									color: lineColor
								}
							}
							markLineData.push([startObj, endObj])
						} else if(type === 'average') {
							const sum = el.data.reduce((a, b) =>{
								const av = a.value ?? a
								const bv = b.value ?? b
								return Number(av) + Number(bv)
							}, 0)
							const val = sum / el.data.length
							obj.yAxis = val
						}
						if(lineColor) {
							obj.lineStyle = {
								color: lineColor
							}
						}
						if(type !== 'fixed' && type !== 'attachment') {
							markLineData.push(obj)
						}
						if(type === 'attachment' && i === 0) {
							markLineData.push(obj)
						}
					})
					if(markLineData.length) {
						markLine.data = markLineData
					}
				}
				s.push({
					name: el.name,
					type: 'bar',
					barWidth: barWidth,
					coordinateSystem: usePolar ? 'polar' : 'cartesian2d',
					data,
					label: {
						show: this.attributes.showBarLabel,
						position: usePolar ? 'middle' : this.attributes.barChart ? 'right' : 'top',
						fontSize: this.scaleSize(this.attributes.barLabelFontsize || 12),
						color: useGradient ? this.attributes.textColor : 'inherit', // 设置跟随为视觉映射得到的颜色，如系列色
					},
					markLine
				});
			}
			// // 显示折线图
			// if (this.attributes.showLine && series.length > 1) {
			// 	const realy = series[1].data;
			// 	const { lineColorRange } = this.attributes;
			// 	let total = 0;
			// 	const lineData = [];
			// 	for (let i = 0; i < realy.length; i++) {
			// 		total += realy[i];
			// 		lineData.push(total);
			// 	}
			// 	s.push({
			// 		name: '累计',
			// 		type: 'line',
			// 		lineStyle: {
			// 			color: lineColorRange[0].color[0]
			// 		},
			// 		itemStyle: {
			// 			color: lineColorRange[0].color[0]
			// 		},
			// 		yAxisIndex: 1,
			// 		data: lineData
			// 	});
			// }
			return s;
		},
		/**
		 * @desc: 生成单项颜色, 渐变优先级高于区分主轴
		 */
		getItemStyle(distinguishColor, useGradient, barChart, index, i, XColorRange, gradientRange) {
			if (distinguishColor && useGradient) {
				return {
					color: {
						type: 'linear',
						x: barChart ? 0 : 0.5,
						y: barChart ? 0.5 : 1,
						x2: barChart ? 1 : 0.5,
						y2: barChart ? 0.5 : 0,
						colorStops: Array.isArray(gradientRange[index % gradientRange.length]) ?
						gradientRange[index % gradientRange.length].map((el, sindex) => {
							if(sindex === 0) {
								return ({
									offset: 0,
									color: el
								})
							}
							return ({
								offset: ( 1 / gradientRange[index % gradientRange.length].length) * (sindex + 1),
								color: el
							})
						}) : [],
						global: false // 缺省为 false
					}
				}
			} else if(useGradient) {
				return {
					color: {
						type: 'linear',
						x: barChart ? 0 : 0.5,
						y: barChart ? 0.5 : 1,
						x2: barChart ? 1 : 0.5,
						y2: barChart ? 0.5 : 0,
						colorStops: Array.isArray(gradientRange[i % gradientRange.length]) ?
						gradientRange[i % gradientRange.length].map((el, index) => {
							if(index === 0) {
								return ({
									offset: 0,
									color: el
								})
							}
							return ({
								offset: ( 1 / gradientRange[i % gradientRange.length].length) * (index + 1),
								color: el
							})
						}) : [],
						global: false // 缺省为 false
					}
				}
			} else {
				return ({
					color: XColorRange[index % XColorRange.length]
				})
			}
		},
		/**
		 * @desc: 获取图例数据
		 * @param {Object} dataFrom 配置数据
		 */
		getLegendData(dataFrom, color = '#000', showLine = false) {
			let s = [];
			if (!dataFrom || !dataFrom.names) {
				return [];
			}
			for (let i = 0; i < dataFrom.names.length; i++) {
				s.push({
					name: dataFrom.names[i],
					textStyle: {
						color,
						// fontSize: this.scaleSize(12)
					}
				});
			}
			// 折线
			if (showLine && dataFrom.series.length > 1) {
				s.push({
					name: '累计',
					textStyle: {
						color,
						fontSize: this.scaleSize(12)
					}
				});
			}
			return s;
		}
	}
};
</script>

<style lang="less" scoped>
.chart-bar {
	height: 100%;
	width: 100%;
	:deep(.el-empty) {
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image {
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
	}
}
</style>
