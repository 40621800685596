<!--
    @name: widget-longlat
    @description：widget-longlat
    @author: ZengWei
    @date: 2022-04-24 16:21
-->
<template>
	<div v-if="show">
		<BindLatLng
			ref="chooseLngLat"
			@set-data="setData"
			componentType="latlng"
			:modelData="bindInfo"
			:config="config"
		>
			<template v-slot:showType>
				<div class="content-box" v-if="element.value && element.value != null">
					<el-button
						v-if="!disabled"
						style="width: 118px; margin-bottom: 10px"
						@click.stop="openModelDrawer('chooseLngLat')"
						size="medium"
						type=""
					>
						<i class="el-icon-s-flag el-icon--left"></i>
						{{ $t('FormItem.widget-longlat.186372-0') }}
					</el-button>
					<div v-if="Array.isArray(element?.value) && element.value.length" class="result-box">
						<ul
							class="lng-lat-yet"
							:class="{
								'lng-lat-yet-disabled': disabled
							}"
						>
							<li  class="item">
								<span class="label">{{ $t('FormItem.widget-longlat.186372-1') }}</span>
								<span class="value">{{ addressStr || $t('FormItem.widget-longlat.186372-2') }}</span>
							</li>
							<li class="item">
								<span class="label">{{$t('bindLatLng.index.774051-6')}}：</span>
								<span class="value">{{ lng || '' }}</span>
							</li>
							<li class="item">
								<span class="label">{{$t('bindLatLng.index.774051-7')}}：</span>
								<span class="value">{{ lat || '' }}</span>
							</li>
						</ul>
						<div class="remove" v-if="!disabled">
							<i class="el-icon-delete" @click="onRemove"></i>
						</div>
					</div>
				</div>
				<!-- :type="element.value && element.value != null ? 'warning' : 'primary'"
          v-html="element.value && element.value != null ? $t('FormItem.widget-longlat.186372-0') : $t('FormItem.widget-longlat.186372-6')" -->
				<template v-else>
					<span v-if="disabled">{{ $t('FormItem.widget-longlat.186372-5') }}</span>

					<el-button v-else  @click.stop="openModelDrawer('chooseLngLat')" size="medium" type="">
						<i class="el-icon-s-flag el-icon--left"></i>
						{{ $t('FormItem.widget-longlat.186372-6') }}
					</el-button>
				</template>
			</template>
		</BindLatLng>
	</div>
</template>

<script>
import BindLatLng from '@/custom-component/bindLatLng/ButtonType.vue';
import {formRequest} from "@/apis/home";

export default {
	name: 'WidgetLonglat',
	components: {
		BindLatLng
	},
	props: ['element', 'disabled', 'displayData'],
	data() {
		return {
			addressStr: '',
			lng:'',
			lat:'',
			bindInfo:[],
			show:true,
			config:null
		};
	},
	created() {
		this.config = this.element.config.__config__;
		this.bindInfo = this.element.value || []
		// 默认有值则获取器坐标点信息
		if (Object.prototype.toString.call(this.element?.value) === '[object Object]') {
			this.lat = this.element.value.lat
			this.lng = this.element.value.lng
		} else if(Object.prototype.toString.call(this.element?.value) === '[object Array]' && this.element?.value.length){
			this.lat = this.element.value[0].location_gis.lat
			this.lng = this.element.value[0].location_gis.lng
		}
		if(!this.lng || !this.lat){
			return
		}
		formRequest('post', 'api/language/baiduMap' ,{location:`${this.lat},${this.lng}`,language:this.$i18n.locale}).then(res=>{
			if(res.data.code === 200 && res.data?.data?.result?.formatted_address){
				this.addressStr = res.data.data.result.formatted_address;
			}
		})
	},
	methods: {
		setData(data) {
			this.element.value = data || [];
			this.$emit('input', data || []);
			if(data?.length){
				if(data[0].location_name && data[0].location_name.includes('|') ){
					this.addressStr = data[0].location_name.split('|')[1]
				} else {
					this.addressStr = ''
				}
				this.lat = data[0].location_gis.lat
				this.lng = data[0].location_gis.lng
				this.show = false
				this.$nextTick(()=>{
					this.bindInfo = this.element.value
					this.show = true
				})
			} else if( data === null ){
				this.show = false
				this.$nextTick(()=>{
					this.bindInfo = []
					this.show = true
				})
				this.addressStr = '';
				this.lat = ''
				this.lng = ''
			}
		},
		openModelDrawer(name) {
			this.$nextTick(() => {
				this.$refs[name].show = true;
			});
		},
		/**
		 * @desc: 移除
		 */
		onRemove() {
			this.$confirm(this.$t('FormItem.widget-longlat.186372-8'), this.$t('FormItem.widget-longlat.186372-9'))
				.then(() => {
					this.setData(null);
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="less" scoped>
.content-box {
	// display: flex;
	flex-direction: column;
	overflow: hidden;
	.result-box {
		display: flex;
		.lng-lat-yet {
			flex: 1;
			position: relative;
			box-sizing: border-box;
			background: #f5f7fa;
			border-radius: 6px;
			padding: 6px 12px;
			border: 1px solid #f5f7fa;
			overflow: hidden;
			width: 90%;
			&.lng-lat-yet-disabled {
				color: #c0c4cc;
			}
			.item {
				display: flex;
				span {
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.2px;
					&.label {
						// width: 72px;
					}
					&.value {
						flex: 1;
					}
				}
			}
		}
		.remove {
			display: flex;
			align-items: center;
			margin-left: 8px;
			i {
				font-size: 18px;
				font-weight: 600;
				color: @dangerColor;
				z-index: 10;
				box-sizing: border-box;
				padding: 0 4px;
			}
		}
	}
}
</style>
