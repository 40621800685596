<!--
  * @Description: iconBtnMenu组件
  * @Author: hw
  * @Date: 2021-10-08 17:11:12
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-12-23 16:06:45
 -->
 <template>
	<div class="console-btn-container">
			<div v-if="!onlyFilter" class="icon-btn-menu-wrap" >
				<div
					:class="['icon-btn-item', { isHidden: !item.isShow }]"
					v-for="item in iconBtnMenuConfig"
					:key="item.type"
					:style="{padding:`${element?.iconBtnMenuConfig?.textConfig?.padding || 0}px`,margin:`${element?.iconBtnMenuConfig?.textConfig?.margin || 0}px`}"
				>
          <i @click.stop="onShowFilter(item)" v-if="focused[item.type] && item.params.length" class="iconfont filter-icon iconshaixuan1"></i>
					<img
						v-if="valueType === 'img'"
						:id="'console-img-' + item.type"
						v-buttonHoverEffect="{item}"
						:src="item.defaultIcon || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png'"
						:style="imgSize"
						@click.stop="onClick(item)"
					/>
					<svg
						v-if="valueType === 'svg'"
						class="icon svg-icon"
						aria-hidden="true"
						:style="imgSize"
						@click.stop="onClick(item)"
					>
						<use
							v-buttonHoverEffect="{item}"
							:id="'console-svg-' + item.type"
							:style="imgSize"
							:xlink:href="item.defaultIcon || '#iconshoucang1'"
						></use>
					</svg>
					<span
						v-if="!textHide"
						class="icon-text"
						:id="'console-text-' + item.type"
						:style="textConfig"
						@click.stop="onClick(item)"
						>{{ item.label }}</span
					>
				</div>
			</div>
			<div v-if="filterShow" 
			:style="{
				left:onlyFilter? 0 :`${element.style.width + 20}px`,
				position:onlyFilter? 'inherit' : 'absolute',
				width:onlyFilter?`${element.style.width - 20}px`: '280px',
				padding:onlyFilter?'10px':'10px 10px 20px 10px',
				color:textConfig.color,
				background:onlyFilter?'transparent': 'inherit',
				borderRadius:`${element.style.borderRadius}px`
			}"
			class="filter-cont">
				<!-- <p class="header">筛选 <i class="iconfont iconc-close" @click="filterShow=false"></i></p> -->
				<div class="inner-cont">
					<div class="inner-item" v-for="(item,index) in filterData" :key="index">
						<p>{{ item.label || '-' }}</p>
						<div >
							<span class="filter-item" :style="{background:filterObj[item.value].includes(val.value)?'#337EFF':'rgba(255,255,255,0.1)'}" v-for="(val,i) in item.status" @click.stop="onCheckFilterData(item,val)" :key="i+'status'">
								<i v-if="val.icon" :class="val.icon" class="iconfont"></i>	{{ val.label }}
							</span>
						</div>	
					</div>
					<div class="btn-cont">
						<el-button type="primary" @click="onResetData()" plain>重置</el-button>
						<el-button type="primary" @click="onFilterData()">确定</el-button>
					</div>	
				</div>	
				
			</div>	
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { removeEventBus, getStatusVal, getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import { initParams } from '@/utils/tools';
import { judgingEventLogic } from '@/utils/tools';
import screenComs from '@/custom-component/component-config/newScreen.js'
export default {
	name: 'ConsoleIconBtn',
	props: {
		element: {
			type: Object,
			required: false,
			default: () => {}
		},
		componentList: {
			default: null
		},
		// 是否为分组
		isGroup: {
			type: Boolean
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			current: 0,
			// 单击的菜单项
			activeName: '',
			// 单击/和激活状态
			clickCount: null,
			isActive: false,
			focused: {},
			dblFocused: {},
			filterShow: false,
			filterItem:null,
			filterData:[],
			filterObj:{}
		};
	},
	directives: {
		/**
		 * @description: 菜单项的hover指令
		 * @return {*}
		 */
		buttonHoverEffect: {
			// 初始化执行一次
			bind(el, binding) {
				const defaultImg =
					'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png';
				const defaultSvg = '#iconshoucang1';

				el.src = binding.value.item.defaultIcon || defaultImg;
				el.setAttribute('xlink:href', binding.value.item.defaultIcon || defaultSvg);
			}
		}
	},
	computed: {
		...mapState(['componentData', 'subsidiaryComponentData']),
		/**
		 * @description: 嵌入页面的参数获取
		 * @return {*}
		 */
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return (
					this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
					this.componentList ||
					this.componentData ||
					[]
				);
			}
			return this.componentList || this.componentData || [];
		},
		
		/**
		 * @description: img尺寸
		 * @return {*}
		 */
		imgSize() {
			return `width: ${this.statusConfig?.iconSize}px; height: auto;`;
		},
		/**
		 * @description: iconBtn 配置
		 * @return {*}
		 */
		iconBtnMenuConfig() {
			const result = (this.element?.iconBtnMenuConfig.configList || []).map((el, index) => {
				let obj = {};
				obj = {
					style: {
						flex: 1 / this.size
					},
					index: index
				};
				return Object.assign(obj, el);
			});
			return result;
		},
		/**
		 * @description: 文本属性配置
		 * @return {*}
		 */
		textConfig() {
			const e = this.element?.iconBtnMenuConfig.textConfig || {};
			const textObj = {
				color: e.defaultColor || '#fff',
				fontSize: e.size + 'px' || '14px',
				fontWeight: e.fontWeight || 'normal',
				lineHeight: e.lineHeight + 'px' || '20px'
			};
			return textObj;
		},
		/**
		 * @description: 图片/icon类型
		 * @return {*}
		 */
		valueType() {
			return this.element?.iconBtnMenuConfig.valueType || 'icon';
		},
		/**
		 * @description: 显示条目数
		 * @return {*}
		 */
		size() {
			return this.statusConfig?.size || 4;
		},
		/**
		 * @description: 组件配置
		 * @return {*}
		 */
		statusConfig() {
			return this.element ? this.element.statusConfig || {} : {};
		},
		//仅筛选，不显示左侧
		onlyFilter(){
			return this.element.iconBtnMenuConfig?.onlyFilter || false;
		},
		/**
		 * @description: 隐藏文本
		 * @return {*}
		 */
		
		textHide() {
			return this.element.iconBtnMenuConfig.textHiding || false;
		},
	},
	mounted() {
		if(this.onlyFilter){
			let componentValue = this.getComponentValue()
			if(componentValue && this.iconBtnMenuConfig?.length){
				const findData = this.iconBtnMenuConfig.filter(v=>v.type === componentValue)
				if(findData?.length){
					this.onShowFilter(findData[0])
					this.setEleResolveData(findData[0])
					this.checkAllStatus(this.filterItem.params)
					this.filterShow = true
				}
			}
		}
		//监听行为
		eventBus.$on('doComponentBehavior', config => {
			const { component, list = [] } = config;
			if (component !== this.element.id) return
			list.forEach(ele => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(params, false, this.subComponentData, []);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							window.$EditorDebug.startBehavior(
								this.element,
								screenComs.componentList.find(ele => ele.component === 'ConsoleIconBtn'),
								funName,
								param
							);
							eval(this[funName])(param);
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		});
		// 默认选中
		this.iconBtnMenuConfig.map(item => {
			if (item.isDefaultSelected) {
				this.onTabClick(item)
			}
		});
	},
	unmounted() {
		eventBus.$off('doComponentBehavior');
	},
	methods: {
		checkAllStatus(data = []){
			if(!data.length) return
			let searchArr = []
			for(let i=0;i<data.length;i++){
				let arr = []
				const field_uuid = data[i].value
				if(data[i].status?.length){
					for(let j=0;j<data[i].status.length;j++){
						arr.push(data[i].status[j].value)
						arr = Array.from(new Set(arr))
					}
					searchArr.push({
						field_uuid: field_uuid,
						ruleType: 'in',
						value: arr
					})
					this.filterObj[field_uuid] = arr
				}
				return searchArr
			}
		},
  /**
   * @description: 重置过滤条件
   * @return {*}
   */		
		async onResetData(){
			let resolveData = await this.checkAllStatus(this.filterItem.params)
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					statusConfig:{
						...this.statusConfig,
						displayShow: this.onlyFilter? false : true,
					},
					resolveData:resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			})
			//关闭
			if(this.onlyFilter){
				eventBus.$emit('refreshConsoleList')
			} else {
				this.eventsFunction(this.filterItem.eventList)
				this.filterShow = false
			}
		},
		/**
		 * @description：过滤搜索
		 * @return {*}
		 */		
		onFilterData(){
			if(this.onlyFilter){
				eventBus.$emit('refreshConsoleList')
				this.$store.commit('modifyComponent', {
					component: {
						...this.element,
						statusConfig:{
							...this.statusConfig,
							displayShow: false
						}
					},
					containerId: null,
					isModify: true,
					pageUUID: this.EDITOR_pageUUID
				});
			} else {
				this.filterShow = false
				this.eventsFunction(this.filterItem.eventList)
			}
			
		},
  /**
   * @description: 勾选筛选
   * @return {*}
   */		
		onCheckFilterData(item,val){
			const arr = this.filterObj[item.value]
			if(Array.isArray(arr)){
				if(!arr.includes(val.value)){
					this.filterObj[item.value].push(val.value)
				} else {
					let findIndex = arr.findIndex(v=>v === val.value)
					if(findIndex > -1){
						this.filterObj[item.value].splice(findIndex,1)
					}
				}
			}
			this.setEleResolveData(item)
			//触发绑定事件
			// setTimeout(()=>{
			// 	this.eventsFunction(this.filterItem.eventList)
			// },0)
		},
		setEleResolveData(item){
			let searchArr = []
			if(this.filterItem.params?.length){
				for(let i=0;i<this.filterItem.params.length;i++){
					searchArr.push({
						field_uuid: this.filterItem.params[i].value,
						ruleType: 'in',
						value: this.filterObj[item.value]
					})
				}
			}
			//暴露参数
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData:searchArr
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
		},
  /**
   * @description: 显示筛选
   * @return {*}
   */		
		onShowFilter(item){
			this.filterItem = item
			const data = [...item.params]
			if(data?.length){
				for(let i = 0; i < data.length; i++){
					if(data[i].value && !this.filterObj[data[i].value]){
						this.$set(this.filterObj,data[i].value,[])
					}
				}
			}
			this.filterData = []
			this.$nextTick(()=>{
				this.filterData = data
				this.filterShow = true
			})
		},
		/**
		 * @description: 判断图片
		 * @param {*} str
		 * @return {*}
		 */
		isImg(defaultIcon) {
			return /^https:\/\//.test(defaultIcon) || /^http:\/\//.test(defaultIcon);
		},
		/**
		 * @description: 处理双击触发单击事件
		 * @param {Object} tab
		 */
		onClick(tab) {
			this.onTabClick(tab);
		},
		/**
		 * @description: 切换图片/图标
		 * @param {*} tab
		 * @param {*} resolveDataNum
		 * @param {*} dbClick
		 * @return {*}
		 */
		switchIcon(tab, resolveDataNum, dbClick = false) {
			const { defaultIcon, clickActiveIcon, dblclickActiveIcon } = tab;
			const defaultImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png';
			const defaultSvg = '#iconshoucang1';
			// 单击
			if (!dbClick) {
				switch (resolveDataNum) {
					case 0:
						if (this.valueType === 'img') {
							document.getElementById('console-img-' + tab.type).src = clickActiveIcon || defaultImg;
						} else {
							document.getElementById('console-svg-' + tab.type).setAttribute('xlink:href', clickActiveIcon || defaultSvg);
						}
						break;
					case 1:
						if (this.valueType === 'img') {
							document.getElementById('console-img-' + tab.type).src = defaultIcon || defaultImg;
						} else {
							document.getElementById('console-svg-' + tab.type).setAttribute('xlink:href', defaultIcon || defaultSvg);
						}
						break;
				}
			} else {
				// 双击
				switch (resolveDataNum) {
					case 0:
						if (this.valueType === 'img') {
							document.getElementById('console-img-' + tab.type).src = dblclickActiveIcon || defaultIcon || defaultImg;
						} else {
							document
								.getElementById('console-svg-' + tab.type)
								.setAttribute('xlink:href', dblclickActiveIcon || defaultIcon || defaultSvg);
						}
						break;
					case 1:
						if (this.valueType === 'img') {
							document.getElementById('console-img-' + tab.type).src = defaultIcon || defaultImg;
						} else {
							document.getElementById('console-svg-' + tab.type).setAttribute('xlink:href', defaultIcon || defaultSvg);
						}
						break;
					case 2:
						if (this.valueType === 'img') {
							document.getElementById('console-img-' + tab.type).src = dblclickActiveIcon || defaultIcon || defaultImg;
						} else {
							document
								.getElementById('console-svg-' + tab.type)
								.setAttribute('xlink:href', dblclickActiveIcon || defaultIcon || defaultSvg);
						}
						break;
				}
			}
		},
  /**
   * @description: 触发事件方法
   * @return {*}
   */		
		eventsFunction(comEvents){
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					if (comEvents[i].key === 'click') {
						comEvents[i].effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: {
									...comEvents[i],
									...effect
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList;
				result.forEach(ele => {
					if (ele.key === 'click') {
						this.judgingActionType(ele);
					}
				});
			}
		},
		/**
		 * @description: 被单击时触发回调
		 * @param {*} tab
		 * @return {*}
		 */
		onTabClick(tab) {
			this.activeName = tab.type;
			this.clickCount = 1;
			// dom更新后重置，避免每次重复触发buttonHoverEffect指令
			this.$nextTick(() => {
				this.clickCount = 0;
			});
			// 固定
			const index = +tab.index;
			const comEvents = this.iconBtnMenuConfig[index]?.eventList || [];
			this.eventsFunction(comEvents)
			// 暴露出绑定的数据
			let resolveData = null;
			let resolveDataNum = null;
			const { defaultColor } = this.element?.iconBtnMenuConfig.textConfig;
			// 暴露数据：默认激活：0，单击激活：1
			// 3.双击激活状态->未激活
			if (this.dblFocused[tab.type]) {
				resolveData = {
					value: 1
				};
				resolveDataNum = 1;
				this.dblFocused[tab.type] = false;
				if(document.getElementById('console-text-' + tab.type)){
					document.getElementById('console-text-' + tab.type).style.color = defaultColor;
				}
				this.focused = { ...this.focused, [tab.type]: false };
				this.switchIcon(tab, resolveDataNum);
			} else {
				if (this.focused[tab.type]) {
					// 1.激活->未激活
					resolveData = {
						value: 1
					};
					resolveDataNum = 1;
					this.focused = { ...this.focused, [tab.type]: false };
					if(document.getElementById('console-text-' + tab.type)){
						document.getElementById('console-text-' + tab.type).style.color = defaultColor;
					}
					this.switchIcon(tab, resolveDataNum);
					//处理筛选部分逻辑
					if(tab?.type === this.filterItem?.type){
						this.filterShow = false
					}
					if(tab?.params?.length){
						for(let i = 0; i < tab.params.length; i++){
							delete this.filterObj[tab.params[i].value]
						}
					}
					this.$store.commit('modifyComponent', {
						component: {
							...this.element,
							resolveData:null
						},
						containerId: null,
						isModify: true,
						pageUUID: this.EDITOR_pageUUID
					});
				} else {
					// 2.未激活->激活
					resolveData = {
						value: 0
					};
					this.focused = { ...this.focused, [tab.type]: true };
					resolveDataNum = 0;
					this.switchIcon(tab, resolveDataNum);
					if(document.getElementById('console-text-' + tab.type)){
						document.getElementById('console-text-' + tab.type).style.color = defaultColor;
					}
					//取出过滤数据携带的参数
					if(tab.params?.length){
						for(let i = 0; i < tab.params.length; i++){
							let statusVal = []
							for(let j = 0;j<tab.params[i].status.length; j++) {
								statusVal.push(tab.params[i].status[j].value)
							}
							if(this.filterObj[tab.params[i].value] && this.filterObj[tab.params[i].value]?.length === 0){
								this.$set(this.filterObj,tab.params[i].value,statusVal)
							} else {
								this.filterObj[tab.params[i].value] = statusVal
							}
						}
						this.$store.commit('modifyComponent', {
							component: {
								...this.element,
								resolveData:null
							},
							containerId: null,
							isModify: true,
							pageUUID: this.EDITOR_pageUUID
						});
					}

				}
			}

			// 点击互斥
			if (this.element?.iconBtnMenuConfig.clickedValue) {
				this.iconBtnMenuConfig.map(item => {
					if (item?.type !== tab?.type) {
						this.focused[item.type] = false;
						if(document.getElementById('console-text-' + item.type)){
							document.getElementById('console-text-' + item.type).style.color = defaultColor;
						}
						this.switchIcon(item, 1);
						}
					})
			}
			this.toResolveData(resolveData, tab, 'click');
			// event bus  挂载卸载问题
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action: 'any',
					isSearch: true,
					output: resolveData
				});
			}, 0);
		},
		/**
		 * @description: 暴露值
		 * @param {object} resolveData
		 * @param {object} tab
		 * @param {string} clickType
		 * @return {*}
		 */
		toResolveData(resolveData) {
			// 固定
			this.element.resolveData = resolveData;
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData
			});
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData,
					propValue: this.activeName
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
		},
		/**
		 * @description: 触发行为逻辑判断
		 * @return {*}
		 */
		judgingBehaviorLogic(ele) {
			for (let i = 0; i < ele.behaviors.length; i++) {
				const item = ele.behaviors[i];
				item.list.forEach(target => {
					const logics = target.logics;
					let logicBoolean = true;
					let behaviorsItem = {
						component: item.component,
						list: []
					};
					if (logics?.length) {
						logicBoolean = judgingEventLogic(logics, this.subComponentData || []);
					}
					if (logicBoolean) {
						behaviorsItem.list.push(target);
						this.$store.commit('triggerEvents', {
							config: {
								behavior: behaviorsItem,
								isBehavior: true
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					}
				});
			}
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
    updatePageStatus(arr) {
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, this.subComponentData)) return;
				const { value } = getStatusVal(ele, this.subComponentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		},
		/**
		 * @desc: 判断操作类型
		 * @return {*}
		 */
		judgingActionType(ele) {
			const { actionType } = ele;
			switch (actionType) {
				case 'pageStatus':
					// 修改页面状态值
					this.updatePageStatus(ele.stateMachine || []);
					break;
				case 'componentBehavior':
					// 触发行为 setTimeout解决resolveData异步问题
					if (ele.behaviors?.length) {
						setTimeout(() => {
							this.judgingBehaviorLogic(ele);
						}, 0);
					}
					break;
				case 'pageAction':
					// 页面事件
					if (ele.effects?.length) {
						ele.effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: {
									...ele,
									...effect
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
					break;
			}
		},
		/**
		 * @description: 获取常规取值的配置
		 * @return {Number} 当前取值
		 */
		getComponentValue() {
			const valueOrigin= this.element?.valueOrign;
			if (valueOrigin === 'fixed') {
				return this.element.propValue;
			}
			if (valueOrigin === 'component') {
				return this.getValueFromComponent(this.element.componentValueConfig)
			}
			if (valueOrigin === 'database') {
				if (!this.dataObj || !this.bindUUID) return 0;
				if (this.databaseType === 'value') {
					// 绑定值
					return this.dataObj[this.bindUUID] || 0;
				}
				if (this.databaseType === 'wordbook' && this.metaData) {
					// 数据字典(描述文字)
					return this.metaData.name || 0;
				}
				return 0;
			}
			return 0;
		},
		/**
		 * @description: 来源于组件的值
		 * @param {Object} config
		 * @return {any}
		 */
		getValueFromComponent(config) {
			if (!config || !config.component || !config.field || (!this.subComponentData && !this.componentList)) return '';
			let component = getComponentById(this.componentList || this.subComponentData, config.component);
			if(!component && !this.isGroup && this.signComponentData.length){
				//标记的取值
				component = getComponentById(this.signComponentData, config.component)
			}
			if(!component && this.isGroup && this.groupComponents.length) {
				component = getComponentById(this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		},
	},
	beforeDestroy() {
		if (!this.EDITOR_pageUUID) {
			removeEventBus('databaseTrigger', this.element.id);
		}
	}
};
</script>

<style lang="less" scoped>
.console-btn-container {
	width: 100%;
	height: 100%;
	z-index: 10;
	.icon-btn-menu-wrap {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
		overflow: hidden;
		.icon-btn-item {
			position: relative;
			color: #000;
			text-align: center;
			display: flex;
			flex-direction: column;
			.filter-icon{
				width: 16px;
				height: 16px;
				line-height: 16px;
				background: rgba(0, 0, 0, 0.5);
				padding: 2px;
				border-radius: 50%;
				position: absolute;
				right: -5px;
				top: -5px;
				color: #fff;
				display: none;
				z-index: 10;
			}
			img {
				transition: all 0.3s ease;
				margin:auto;
				cursor: pointer;
				&:hover {
					transform: scale(1.2);
				}
			}
			svg {
				margin:auto;
				&:hover {
					transform: scale(1.2);
				}
				transition: all 0.3s ease;
			}
			.icon-text {
				color: #fff;
			}
		}
		.icon-btn-item:hover{
			.filter-icon{
				display: block;
			}
		}
		.isHidden {
			display: none;
		}
	}
	.filter-cont{
		position: absolute;
		height: auto;
		top:0;
		background-color: inherit;
		text-align: left;
		font-size: 14px;
		.header{
			height: 40px;
			line-height: 40px;
			padding-left: 10px;
			border-radius:8px 8px 0 0;
			position: relative;
			background-image: linear-gradient(rgba(0,183,255, 0), rgba(0,183,255, 0.1));
			i{
				position: absolute;
				right: 10px;
			}
		}
		.inner-cont{
			display: flex;
			flex-direction: row;
      flex-wrap: wrap;
			.inner-item{
				min-height: 25px;
				line-height: 25px;
				margin-bottom: 10px;
				width: 100%;
				p{
					margin: 10px 0;
					font-size: 16px;
				}
				div{
					height: auto;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					flex-wrap: wrap;
				}
			}
			.filter-item{
				margin:0 25px 10px 0;
				padding:3px 15px;
				border-radius: 25px;
				background: rgba(255, 255, 255, 0.1);
				i{
					font-size: 16px;
					margin-right: 3px;
				}
				.iconfuxuan-quanxuan-zhengchangxuanting{
					color:var(--el-color-primary)
				}
			}
			.btn-cont{
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				.el-button{
					width: 45%;
					color:#fff;
				}
			}
		}
	}
}
</style>
