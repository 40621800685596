/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-06-06 12:14:27
 * @FilePath: \dataview-viewer-test\src\custom-component\functionCom\lang\zh.js
 */

export default {
  "functionCom.WeatherComNew.411871-0": "湿度:",
  "functionCom.WeatherComNew.411871-1": "风速:",
  "functionCom.LiveCom.207042-0": "暂无内容",
  "functionCom.LiveCom.207042-1": "视频加载中",
  "functionCom.LiveCom.207042-2": "视频链接缺失",
  "functionCom.LiveCom.207042-3": "参数必填校验未通过",
  "functionCom.LiveCom.207042-4": "非当前组件或非更新",
  "functionCom.LiveCom.207042-5": "配置错误!",
  "functionCom.LiveCom.207042-6": "请求配置错误",
  "functionCom.LiveCom.207042-7": "暂无封面"
}


