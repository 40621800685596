/*
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2024-03-21 13:45:02
 * @LastEditors: zqy
 * @LastEditTime: 2024-07-04 16:13:13
 * @FilePath: \dataview-viewer-test\src\views\login\jida\lang\index.js
 * @Description: 吉达登录 国际化
 */
export default {
  zh: {
    'login.slogan': '沙特吉达市中心综合开发基础',
    'login.slogan1': '设施工程项目信息管理系统',
    'login.account': '账号 ',
    'login.account.placeholder': '用户名/邮箱',
    'login.account.errmsg': '请输入',
    'login.password': '密码',
    'login.password.placeholder': '输入密码',
    'login.password.errmsg': '请输入',
    'login.forgotPassword': '忘记密码?',
    'login.logintext': '登 录',
    'login.registertip': '没有账号?',
    'login.registertip1': '现在去注册',
    'login.agreementTip': '登录即同意平台',
    'login.agreementTip1': '和',
    'login.privacyAgreement': '《隐私协议》',
    'login.userAgreement': '《用户协议》',
    'login.errortip': '登录失败，请联系管理员！',
    'register.slogan': '注册账号',
    'register.account': '邮箱',
    'register.account.placeholder': '请输入',
    'register.account.errmsg': '请输入',
    'register.account.formatError': '格式错误',
    'register.fullname': '全名',
    'register.fullname.placeholder': '请输入全名',
    'register.gender': '性别',
    'register.gender.placeholder': '请选择',
    'register.chineseName': '中文名',
    'register.chineseName.placeholder' : '请输入中文名',
    'register.job': '职位',
    'register.job.placeholder': '请选择职位',
    'register.porject': '申请加入的项目',
    'register.porject.placeholder': '请选择项目',
    'register.company': '所属公司',
    'register.company.placeholder': '请选择公司',
    'register.section': '所属部门',
    'register.section.placeholder': '请选择部门',  
    'register.registertext': '注册',
    'register.logintip': '已有账户？',
    'register.logintip1': '去登录',
    'reset.slogan': '忘记密码?',
    'reset.slogan1': '输入您加入时使用的电子邮件地址，我们将向您发送验证码，如果没有收到，请务必检查您的垃圾邮件文件夹。',
    'reset.slogan2': '重置密码  ',
    'reset.emall': '邮箱',
    'reset.emall.placeholder': '输入邮箱',
    'reset.code': '验证码',
    'reset.code.placeholder': '输入验证码',
    'reset.emptymsg': '请输入',
    'reset.acquire': '获取',
    'reset.reset': '重置密码',
    'reset.resetpassword': '重置密码',
    'reset.password': '重置密码',
    'reset.password.placeholder': '输入密码',
    'reset.password.error': '密码由数字、字母、特殊字符三者组成,长度8-16位！',
    'reset.confimpassword': '确认密码',
    'reset.confimpassword.placeholder': '确认密码',
    'reset.confimpassword.error': '密码不一致',
    'reset.verify': '确定',
    'reset.backlogin': '返回登录',
    'reset.backlogin1': '点击此处', 
    'login.privacyInfo': '请勾选“知晓并同意系统《用户隐私声明》”', 
    'login.privacyTitle': `用户隐私声明`, 
  },
  en: {
    'login.slogan': 'Jeddah Central  Development',
    'login.slogan1': 'Infrastructure Project Portal',
    'login.account': 'Account number',
    'login.account.placeholder': 'User name/Email',
    'login.account.errmsg': 'Please enter',
    'login.password': 'password',
    'login.password.placeholder': 'Enter password',
    'login.password.errmsg': 'password ',
    'login.forgotPassword': 'Forgot password?',
    'login.logintext': 'Log in',
    'login.registertip': 'Dont have an account?',
    'login.registertip1': 'Sign up now',
    'login.agreementTip': 'Login to agree to the Platform',
    'login.agreementTip1': 'And',
    'login.privacyAgreement': '"Privacy Agreement"',
    'login.userAgreement': '"User Agreement"',
    'login.errortip': 'Login failed, please contact the administrator!',
    'register.slogan': 'Sign up',
    'register.account': 'Email ', 
    'register.account.placeholder': 'Please enter',
    'register.account.errmsg': 'Please enter',
    'register.account.formatError': 'Format error',
    'register.fullname': 'Full name',
    'register.fullname.placeholder': 'Enter full name',
    'register.gender': 'Gender',
    'register.gender.placeholder': 'Please select',
    'register.chineseName': 'Chinese name',
    'register.chineseName.placeholder' : 'Enter Chinese name',
    'register.job': 'Job title',
    'register.job.placeholder': 'Entry job',
    'register.porject': 'Projects applied to join ',
    'register.porject.placeholder': 'Select item',
    'register.company': 'Company name',
    'register.company.placeholder': 'Select a company',
    'register.section': 'Section name',
    'register.section.placeholder': 'Select department', 
    'register.registertext': 'Sign up',
    'register.logintip': 'Already have an account?',
    'register.logintip1': 'Go and log in',
    'reset.slogan': 'Forgot password?',
    'reset.slogan1': 'Enter the email address you used to join and we will send you a verification code, if you do not receive it, be sure to check your spam folder.',
    'reset.slogan2': 'Reset password',
    'reset.emall': 'Email',
    'reset.emall.placeholder': 'Enter email',
    'reset.code': 'verification code',
    'reset.code.placeholder': 'Enter verification code',
    'reset.emptymsg': 'Please enter',
    'reset.acquire': 'Acquire',
    'reset.reset': 'Reset password',
    'reset.resetpassword': 'Reset password',
    'reset.password': 'Password',
    'reset.password.placeholder': 'Enter password',
    'reset.password.error': 'The password consists of numbers、letter and special characters, with a length of 8-16 digits!',
    'reset.confimpassword': 'confirm password',
    'reset.confimpassword.placeholder': 'Enter confirm password',
    'reset.confimpassword.error': 'Password inconsistency',
    'reset.verify': 'Verify',
    'reset.backlogin': 'Back to login',
    'reset.backlogin1': 'Click here',
    'login.privacyInfo': `Please check "Know and agree to the system's User Privacy Statement"`, 
    'login.privacyTitle': `User Privacy Statement`, 
  }
}