<template>
    <div class="wrapPanel">
        <div class="title-wrap">
            <span>模型树</span>
            <a :class="['iconfont iconjijia_liebiao2', {'showUnload-btn_active': isShowUnload}]" @click="onShowUnload"></a>
        </div>

        <div v-show="isShowUnload" class="unload-wrap">
            <el-tree
                    class="unloadModelTree-wrap"
                    ref="unloadModelTree"
                    empty-text="暂无数据"
                    :data="unLoadModelList"
                    :props="treeProps"
                    :default-expand-all="true"
                    node-key="id"
                    highlight-current
                    :check-on-click-node="false"
                    :check-strictly="false"
                    :show-checkbox="true"
                    @check="onCheckNode"
                    >
                <template #default="{ node, data }">
                    <div :class="['horizontal-flex tree-node-unload']">
                        <!--<el-popover placement="bottom" trigger="click">
                            <template #reference>
                                <div class="horizontal-flex">
                                    <a :class="['tree-node-icon iconfont ', getIconByType(data.type)]"></a>
                                    <div v-if="data.type==='model'" class="tree-node-info"></div>
                                </div>
                            </template>
                            <div class="vertical-flex">
                                <span style="line-height: 25px">模型大小：{{data.size}}</span>
                                <span style="line-height: 25px">上传日期：{{data.uploadDate}}</span>
                            </div>
                        </el-popover>-->
                        <a :class="['tree-node-icon iconfont ', getIconByType(data.type)]"></a>
                        <div class="tree-node-content">{{ data.name + getModelLength(data)}}</div>
                        <el-popconfirm title="是否卸载模型?"
                                        confirm-button-text="是"
                                        cancel-button-text="否"
                                        @confirm="onDelete(data)">
                            <template #reference>
                                <a v-show="data.isLoaded" class="iconfont iconbimgis_guanbi tree-node-delete"></a>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </el-tree>

            <div :class="['unload-btn', {'unload-btn_active': checkModelList.length > 0}]" @click="onAppend">确定加载{{checkModelList.length}}个模型</div>
        </div>

        <el-tree
                ref="modelTree"
                class="modelTree-wrap"
                empty-text="暂无数据"
                :data="modelList"
                :props="treeProps"
                :expand-on-click-node="false"
                :default-expanded-keys="modelExpanded"
                node-key="id"
                :lazy="true"
                :load="onLoadModelNode"
                highlight-current
                :check-on-click-node="false"
                :check-strictly="false"
                :show-checkbox="true"
                @check="onChangeModelTreeChecked"
        >
            <template #default="{ node, data }">
                <div :class="['horizontal-flex tree-node']">
                    <a v-if="data.type==='file'||data.type==='model'" :class="['tree-node-icon iconfont ', getIconByType(data.type, data.modelType)]"></a>
                    <span class="tree-node-content">{{ data.name}}</span>
                    <a v-if="data.type==='model'&&!data.isLoaded" class="iconfont iconshuaxin tree-node-location loading" style="margin-right: 5px"></a>
                    <a v-if="data.type==='model'" class="iconfont icongis_dingwei tree-node-location" @click="onFitToModel( data )"></a>
                </div>
            </template>
        </el-tree>
    </div>
</template>

<script>
    import { Tree, Popconfirm } from 'element-ui';
    import clickOutside from "./ClickOutside.js";
    import { dataInterface } from '@/apis/data/index';
    import eventBus from '@/plugins/eventBus';

    export default {
        name: "viewerBIMModel",
        components: {
            'el-tree': Tree,
            'el-popconfirm': Popconfirm
        },
        directives: {clickOutside},
        data(){
            return {
                isShowUnload: false,
                checkModelList: [],
                modelTreeMap : {},
                loadModelMap : {},
                unLoadModelList: [],
                modelList: [],
                modelExpanded: [],
                treeProps: {
                    label: 'name',
                    children: 'children',
                    isLeaf : "isLeaf",
                },
                isViewerInited : false,
                viewerInitedFunc : [],
            }
        },
        props : {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {
                    }
                }
            },
            bindViewerId :{
                type : String,
            },
            bindDataMainId:{
                type : String,
            },
            // 是否在组合内
            isGroup: {
                type: Boolean,
            },
            // 组合内组件列表
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            getModelLength(data){
                if(data.type !== 'file') return '';

                let leaf = [];
                this.getLeafNodes(data, leaf);

                return `(${leaf.length})`;
            },
            getLeafNodes(node, leaf = []){
                if(node.type === 'model') leaf.push(node);
                if(node.children && node.children.length > 0){
                    for(let item of node.children){
                        if(item.type === 'model') leaf.push(item);

                        if(item.children && item.children.length > 0){
                            this.getLeafNodes(item, leaf);
                        }
                    }
                }
                return leaf;
            },
            getIconByType(type, modelType = undefined){
                switch (type) {
                    case 'file':return 'iconjijia_liebiao2';
                    case 'model':
                        if(!modelType) return 'iconBIM';
                        switch (modelType) {
                            case 'bim':return 'iconBIM1';
                            case 'gltf':return 'icongltf';
                            case 'osgb':return 'iconosgb';
                            default: return 'iconBIM';
                        }
                    default: return '';
                }
            },
            //添加选中
            setUnloadModelNodesCheck(keys){
                const el = this.$refs.unloadModelTree;
                for (let item of keys){
                    let node = el.getNode(item);
                    if(!node) continue;

                    el.setChecked(item, true);
                    this.checkModelList.push(node.data);
                }
                this.onAppend();
            },
            async loadModelNodeCallback(node){
                const { data , model_id } = await this.getNodeChildren(node);
                const list = [];

                for(let {  name , dbid ,count  } of data){
                    list.push({
                        name,
                        model_id,
                        id : `${model_id}_${dbid}`,
                        isPart : true,
                        dbid,
                        isLeaf : count == 0,
                    })
                }

                return { leafs : list}
            },
            appendModelList(nodes, checked = []){
                const el = this.$refs.modelTree;
                let expand = [];

                for(let item of nodes){
                    let node = el.getNode(item.id);

                    if(!node) {
                        el.append(item, item.pId === -1 ? null : item.pId);

                        if (item.level <= 2 && item.type !== 'model') expand.push(item.id);

                        if (item.children && item.children.length > 0){
                            item.children.forEach(x => expand.push(x.id));
                        }
                    }
                }


                this.$nextTick(()=>{
                    if(checked.length > 0) this.setModelNodesCheck(checked, true);

                    this.modelExpanded = expand;
                });
            },
            setModelNodesCheck(nodes, checked){
                const el = this.$refs.modelTree;
                for (let key in nodes){
                    el.setChecked(nodes[key] instanceof Object ? nodes[key].id : nodes[key], checked, true);
                }
            },
            onShowUnload(){
                this.isShowUnload = !this.isShowUnload;
            },
            onHideUnload(){
                this.isShowUnload = false;
            },
            onCheckNode(data, checked){
                let leaf =[];
                let checkedNodes = checked.checkedNodes;
                for(let item of checkedNodes){
                    if(item.type === 'model' && !item.isLoaded) leaf.push(item);
                }

                this.checkModelList = leaf;
            },
            onAppend(){
                if( !this.checkBind() )return this.$message.error('未绑定BIM模型组件!');

                if(this.checkModelList.length === 0) {
                    // this.$message.error('请勾选模型加载!');
                    return;
                }

                const el = this.$refs.unloadModelTree;
                let loadArray = [];
                let defaultChecked = [];
                let halfCheckedNodes = el.getHalfCheckedNodes();
                for(let item of halfCheckedNodes){
                    let temp = el.getNode(item.id);
                    let pId = -1;
                    if(temp.level > 1) pId = temp.parent.data.id;
                    loadArray.push({
                        id: item.id,
                        name: item.name,
                        pId: pId,
                        level: temp.level,
                        type: item.type,
                        isLeaf: false,
                        isLoaded : item.type !== 'model',
                    })
                }

                let checkedNodes = el.getCheckedNodes();
                for(let item of checkedNodes){
                    if(!item.isLoaded){
                        let temp = el.getNode(item.id);
                        let pId = -1;
                        if(temp.level > 1) pId = temp.parent.data.id;
                        loadArray.push({
                            id: item.id,
                            name: item.name,
                            pId: pId,
                            level: temp.level,
                            type: item.type,
                            isLeaf: false,
                            isLoaded : item.type !== 'model',
                        });
                        if(item.type === 'model') defaultChecked.push(item.id);
                    }

                    item.isLoaded = true;
                    item.disabled = true;
                    el.setCurrentKey(item.id);
                }

                this.loadModels( defaultChecked );
                this.checkModelList = [];
                this.appendModelList(loadArray, defaultChecked);
                this.onHideUnload();
            },
            onDelete( data ){
                console.log(data);
            },
            onLoadModelNode(node, resolve){
                if(node.level === 0) return resolve(node.data);

                if(node.data.children) {
                    node.isLeaf = true;
                    return resolve(node.data.children);
                }

                if(!node.isLeaf && this.loadModelNodeCallback){
                    this.loadModelNodeCallback(node.data).then(({leafs = [], checkedList = [], checked = true})=>{
                        if(leafs.length > 0){
                            node.data.children = leafs;
                            node.isLeaf = false;

                            this.$nextTick(()=>{
                                this.setModelNodesCheck(checkedList, checked);
                            });

                            return resolve(node.data.children);
                        }else{
                            node.isLeaf = true;
                            return resolve([]);
                        }
                    })
                }else{
                    node.isLeaf = true;
                    return resolve([]);
                }
            },
            getModelList(){
                const handleData = ( data , list , map)=>{
                    const node = {
                        name : data.name,
                        id : data.id,
                        type: "file",
                        isLoaded : false,
                    };
                    const children = [];

                    if(data.models && data.models.length > 0){

                        for(let { allversions , used_version , name , modelSize } of data.models){
                            let model_id = null;

                            if( !used_version ) continue;

                            for(let ver of allversions){
                                if(ver.id  === used_version && ver.model_id) {
                                    model_id = ver.model_id;
                                    break;
                                }
                            }

                            if(!model_id) continue;

                            children.push({
                                id : model_id,
                                type : "model",
                                size : modelSize,
                                isLeaf: true,
                                isLoaded : false,
                                name,
                            });

                            map[model_id] = {
                                id : model_id,
                                name ,
                            }
                        }
                    }

                    node.children = children;
                    list.push(node);

                    if(data.children && data.children.length > 0 ){
                        for(let child of data.children){
                            handleData(child , children , this.modelTreeMap , map);
                        }
                    }
                }

                const db = {
                    object_uuid:'object6176693334cff',
                    view_uuid:'view6176699c082b5',
                }

                dataInterface({
                    __method_name__ : "dataList",
                    object_uuid: db.object_uuid,
                    view_uuid: db.view_uuid,
                    transcode: 0,
                }).then( async ( res )=>{
                    if (res && res.status === 200) {
                        const data = res.data.data;

                        const treeData = [];

                        for(let obj of data){
                            handleData( obj , treeData , this.modelTreeMap );
                        }

                        this.unLoadModelList = treeData;
                    }
                });

            },
            async getLoadedInfo(){
                return new Promise(( rol )=>{
                    eventBus.$emit("ViewerBIM.getLoadedModelInfo" , this.bindViewerId ,(loadedInfo) => {
                        rol(loadedInfo);
                    });
                });
            },
            loadModels( ids ){
                if(!this.checkBind()) return;

                const list = [];

                for(let mid of ids){
                    if(! this.loadModelMap[mid] ){
                        list.push(mid);
                        this.loadModelMap[mid] = {
                            model_id : mid,
                            isLoaded : false,
                        }
                    }
                }

                if(this.isViewerInited){
                    for(let mid of list){
                        eventBus.$emit('ViewerBIM.loadModel', this.bindViewerId , mid);
                    }
                }else{
                    eventBus.$emit('ViewerBIM.getViewerInitFlag' ,  this.bindViewerId , ( initFlag )=>{
                        //获取初始化标记
                        if(!initFlag){
                            eventBus.$emit('ViewerBIM.initViewer', this.bindViewerId , list.splice(0,1)[0]);

                            this.viewerInitedFunc.push(()=>{
                                for(let mid of list){
                                    eventBus.$emit('ViewerBIM.loadModel', this.bindViewerId , mid);
                                }
                            });
                        }
                    });
                }
            },
            checkBind () {
                return this.bindViewerId && this.$parent.checkBind();
            },
            getLoadedModelIds(){
                eventBus.$emit("ViewerBIM.getModelIds" , this.bindViewerId , ( ids )=>{
                    this.setUnloadModelNodesCheck(ids);
                });
            },
            initEventListener(){
                this._EventBus = {
                    "Viewer.onViewerInited"  : async ( eid )=>{
                        if(eid !== this.bindViewerId) return;

                        // this.getLoadedModelIds();

                        const loadedInfo = await this.getLoadedInfo();

                        let ids = [];
                        for(let mid in loadedInfo){
                            const info = loadedInfo[mid];

                            if(!this.modelTreeMap[mid]){
                                this.unLoadModelList.push({
                                    id : mid,
                                    type : "model",
                                    size : info.size,
                                    isLeaf: true,
                                    isLoaded : false,
                                    name : info.name,
                                });
                                ids.push(mid);
                            }else{
                                this.modelTreeMap[mid] = {
                                    id : mid,
                                    name  : info.name,
                                }
                            }

                            this.loadModelMap[mid] = {
                                model_id : mid,
                                isLoaded : false,
                            }
                        }

                        this.$nextTick(()=>{
                            this.setUnloadModelNodesCheck( ids );

                            this.isViewerInited = true;

                            for(let func of this.viewerInitedFunc){
                                func();
                            }
                        })
                    },
                    "Viewer.onLoadedModel" : ( eid , model_id) =>{
                        if(eid !== this.bindViewerId) return;

                        this.loadModelMap[model_id ].isLoaded = true;

                        const el = this.$refs.modelTree;
                                            if(!el) return
                        let node = el.getNode(model_id);
                        if(!node) return;

                        if(typeof node.data.isLoaded === "undefined"){
                            this.$set(node.data, 'isLoaded', true)
                        }else{
                            node.data.isLoaded = true;
                        }
                    },
                }

                for( let id in this._EventBus){
                    eventBus.$on(id , this._EventBus[id]);
                }
            },
            getNodeChildren( node ){
                return new Promise(( rol )=>{

                    let model_id = null;
                    let dbid = null;

                    if( node.type === "model"){
                        model_id = node.id;
                        dbid = 1;

                    }else if( node.isPart){
                        model_id = node.model_id;
                        dbid = node.dbid;
                    }else{
                        return;
                    }

                    eventBus.$emit("ViewerBIM.getNodeChildren" , this.bindViewerId , model_id , dbid , (data)=>{
                        rol({
                            model_id ,
                            data,
                        });
                    });
                }) ;

            },
            onFitToModel( node) {
                eventBus.$emit( "ViewerBIM.fitToView" , this.bindViewerId , [{
                    model_id : node.id ,
                    dbids : [1] ,
                }]);
            },
            onChangeModelTreeChecked( data ){
                const el = this.$refs.modelTree;
                const node = el.getNode(data.id);
                const checked = node.checked

                if( !data.type ){
                    const model_id = data.model_id;
                    const dbid = data.dbid;

                    if(checked){
                        eventBus.$emit("ViewerBIM.show" , this.bindViewerId , [ dbid ] , model_id);
                    }else{
                        eventBus.$emit("ViewerBIM.hide" , this.bindViewerId , [{model_id , dbids : [dbid]}]);
                    }

                }else if(data.type === "model"){
                    const model_id = data.id;

                    if(checked){
                        eventBus.$emit("ViewerBIM.show" , this.bindViewerId , [ 1 ] , model_id);
                    }else{
                        eventBus.$emit("ViewerBIM.hide" , this.bindViewerId , [{model_id , dbids : [1]}]);
                    }
                }else{
                    if( data.children ){
                        const findModel = ( list , result)=>{
                            for(let info of list){
                                if(info.type === "model"){
                                    result.push(info.id);
                                }

                                if(info.type === "file" && info.children){
                                    findModel(info.children , result);
                                }
                            }
                        }

                        const model_ids = [];
                        findModel(data.children , model_ids);
                        if(checked){
                            for(let mid of model_ids){
                                eventBus.$emit("ViewerBIM.show" , this.bindViewerId , [ 1 ] , mid);
                            }
                        }else{
                            const selection = []
                            for(let mid of model_ids){
                                selection.push({
                                    model_id : mid,
                                    dbids : [1],
                                })
                            }
                            eventBus.$emit("ViewerBIM.hide" , this.bindViewerId , selection);
                        }

                    }
                }
            }
        },
        mounted(){
            this.getModelList();
            this.initEventListener();
        },
        destroyed(){
            for( let id in this._EventBus){
                eventBus.$off(id , this._EventBus[id]);
            }
        },
    }
</script>

<style lang="less" scoped>
    .wrapPanel{
        float: left;
        padding: 0 !important;
        overflow-y: hidden;
        background-color: inherit;
        color: inherit;

        .title-wrap{
            width: 100%;
            height: 38px;
            line-height: 38px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255,255,255,0.3);
        }

        .title-wrap span{
            float: left;
            padding-left: 10px;
            font-size: 14px;
            color: inherit;
        }

        .title-wrap a{
            float: right;
            padding-right: 10px;
            font-size: 18px;
            color: inherit;
        }

        .showUnload-btn_active{
            color: var(--themeColor) !important;
        }

        .unload-wrap{
            z-index: 9999;
            position: absolute;
            top: 90px;
            right: 55px;
            width: 400px;
            height: 500px;
            overflow: hidden;
            border: 1px solid rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(3px);
            background-color: inherit;
            color: inherit;

            .unload-btn{
                float: right;
                margin-right: 15px;
                width: 120px;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
                background-color: rgba(131, 131, 131, 0.5);
                color: inherit;
            }

            .unload-btn_active{
                background-color: var(--themeColor);
            }
        }
    }

    .unloadModelTree-wrap{
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 50px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }
    }

    .modelTree-wrap{
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }
        :deep(.el-tree-node__content>label.el-checkbox) {
            position: absolute !important;
            right: 0;
            margin-right: 13px !important;
            color: inherit;
        }

        :deep(.el-checkbox__inner) {
            background-color: transparent;
            border: none;
            height: 35px;
            color: inherit;
        }

        :deep(.el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e739";
            color: var(--themeColor);
            font-size: 16px;
            background-color: transparent !important;
            transform: none !important;
            /*transition: none !important;*/
        }

        :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner::before) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner){
            background-color: transparent !important;
        }
    }

    .tree-node{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 80%;
        height: 35px;
        line-height: 35px;
        padding-right: 33px;
    }

    .tree-node-unload{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 100%;
        height: 35px;
        line-height: 35px;
        padding-right: 10px;
    }

    .tree-node-icon{
        margin-right: 3px;
        margin-left: -5px;
        margin-top: 2px;
        width: 20px;
    }

    .tree-node-info{
        position: relative;
        left: -11px;
        top: 2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: red;
    }

    .tree-node-content{
        text-align: left;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tree-node-loading{
        width: 20px;
        margin-top: 2px;
        animation: rotating 2s linear infinite;
    }

    .tree-node-location{
        width: 20px;
        margin-top: 2px;
    }

    .tree-node-location:hover{
        color: var(--themeColor) !important;
    }

    .tree-node-delete{
        width: 20px;
    }

    .tree-node-delete:hover{
        color: var(--themeColor) !important;
    }
</style>

