/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-18 09:41:08
 * @FilePath: \dataview-next\src\custom-component\weather\lang\zh.js
 */

export default {
  "weather.WeatherDay.740282-0": "天气分析",
  "weather.WeatherDay.740282-1": "湿度",
  "weather.WeatherDay.740282-2": "风速",
  "weather.WeatherDay.740282-3": "气压",
  "weather.WeatherDay.740282-4": "温度",
  "weather.WeatherDay.740282-5": "优",
  "weather.WeatherDay.740282-6": "良",
  "weather.WeatherDay.740282-7": "轻度污染",
  "weather.WeatherDay.740282-8": "重度污染",
  "weather.WeatherDay.740282-9": "严重污染",
  "weather.WeatherWeek.961601-0": "天气情况",
  "weather.WeatherWeek.961601-1": "湿度：",
  "weather.WeatherWeek.961601-2": "昨天",
  "weather.WeatherWeek.961601-3": "今天",
  "weather.WeatherWeek.961601-4": "明天",
  "weather.WeatherWeek.961601-5": "优",
  "weather.WeatherWeek.961601-6": "良",
  "weather.WeatherWeek.961601-7": "轻度污染",
  "weather.WeatherWeek.961601-8": "重度污染",
  "weather.WeatherWeek.961601-9": "严重污染"
}


