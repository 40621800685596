export default {
  'leftContent.LeftContent.146055-0': 'To-Do',
  'leftContent.LeftContent.146055-1': 'Term',
  'leftContent.LeftContent.146055-2': 'Search Terms',
  'leftContent.LeftContent.146055-3': 'Read',
  'leftContent.LeftContent.146055-4': 'Unread',
  'leftContent.LeftContent.146055-5': 'No Pending Tasks',
  'leftContent.LeftContent.146055-6': 'Total ',
  'leftContent.LeftContent.146055-7': '',
  'leftContent.LeftContent.146055-8': 'List',
  'leftContent.LeftContent.146055-9': 'Day',
  'leftContent.LeftContent.146055-10': 'Week',
  'leftContent.LeftContent.146055-11': 'Month',
  'leftContent.LeftContent.146055-12': 'Num',
  'leftContent.LeftContent.146055-13': 'Number',
  'leftContent.LeftContent.146055-14': 'Project',
  'leftContent.LeftContent.146055-15': 'Feature Name',
  'leftContent.LeftContent.146055-16': 'Notification Content',
  'leftContent.LeftContent.146055-17': 'Previous Approver',
  'leftContent.LeftContent.146055-18': 'Cc To',
  'leftContent.LeftContent.146055-19': 'Process Approver',
  'leftContent.LeftContent.146055-20': 'Process Status',
  'leftContent.LeftContent.146055-21': 'Deadline',
  'leftContent.LeftContent.146055-22': 'Overdue Status',
  'leftContent.LeftContent.146055-23': 'CC Date',
  'leftContent.LeftContent.146055-24': 'Reading Status',
  'leftContent.LeftContent.146055-25': 'Process Date',
  'leftContent.LeftContent.146055-26': 'You have completed all the audits!',
  'leftContent.LeftContent.146055-27': 'Today{0}',
  'leftContent.LeftContent.146055-28': 'Yesterday{0}',
  'leftContent.LeftContent.146055-29': 'Tomorrow{0}',
  'leftContent.LeftContent.146055-30': '{0} days overdue',
  'leftContent.LeftContent.146055-31': '天',
  'leftContent.LeftContent.146055-32': 'Pending',
  'leftContent.LeftContent.146055-33': 'I Initiated',
  'leftContent.LeftContent.146055-34': 'CC Me',
  'leftContent.LeftContent.146055-35': 'Processed',
  'leftContent.FlowFinishPop.107297-0': 'The {object} process of {name}',
  'leftContent.FlowFinishPop.107297-1': '流程',
  'leftContent.FlowFinishPop.107297-2': 'Submission successful',
  'leftContent.FlowFinishPop.107297-3':
    'seconds later, the pop-up window closes automatically, and it can also be closed by clicking on the blank area.',
  'leftContent.FlowFinishPop.107297-4': 'Close the pop-up Window',
  'leftContent.FlowFinishPop.107297-5': 'Process the Next Data',
  'leftContent.FilterModal.857271-0': 'Approval Number',
  'leftContent.FilterModal.857271-1': 'Belonging Project',
  'leftContent.FilterModal.857271-2': 'Feature Name',
  'leftContent.FilterModal.857271-3': 'Notification Content',
  'leftContent.FilterModal.857271-4': 'Approver',
  'leftContent.FilterModal.857271-5': 'Process Status',
  'leftContent.FilterModal.857271-6': 'Start Date',
  'leftContent.FilterModal.857271-7': 'Select Start Date',
  'leftContent.FilterModal.857271-8': 'End Date',
  'leftContent.FilterModal.857271-9': 'Select End Date',
  'leftContent.FilterModal.857271-10': 'Search',
  'leftContent.FilterModal.857271-11': 'Reset',
  'leftContent.FilterModal.857271-12': 'Returned',
  'leftContent.FilterModal.857271-13': 'Initiate',
  'leftContent.FilterModal.857271-14': 'Under Review',
  'leftContent.FilterModal.857271-15': 'Completed',
  'leftContent.FilterModal.857271-16': 'Rejected',
  'leftContent.FilterModal.857271-17': 'Void',
  'components.alertItem.007190-0': '[System]',
  'components.alertItem.007190-1': 'Project：',
  'components.alertItem.007190-2': 'Announcement',
  'components.alertItem.007190-3': 'Bulletin',
  'components.alertItem.007190-4': 'Resolution',
  'components.alertItem.007190-5': 'Decision',
  'alertNotify.alertDetailPop.951902-0': 'Warning Notification Details',
  'alertNotify.alertDetailPop.951902-1': 'Operation successful!',
  'alertNotify.alertDetailPop.951902-2': 'Warning Resolution',
  'alertNotify.alertNotifications.762766-0': 'Warning Notification',
  'alertNotify.alertNotifications.762766-1': 'No Warning at the Moment',
  'alertNotify.alertNotifyPop.295431-0': 'Warning Notification',
  'alertNotify.alertNotifyPop.295431-1': 'Search',
  'alertNotify.alertNotifyPop.295431-2': 'All read',
  'alertNotify.alertNotifyPop.295431-3': 'Warning Resolution',
  'alertNotify.alertNotifyPop.295431-4': 'No unread data available',
  'shortCutMenu.shortcutMenu.823110-0': 'Shortcut Menu',
  'shortCutMenu.shortcutMenu.823110-1': 'Not Yet Configured',
  'shortCutMenu.ShortcutMenuPop.233974-0': 'Configuration',
  'shortCutMenu.ShortcutMenuPop.233974-1': 'Search Menu Name',
  'shortCutMenu.ShortcutMenuPop.233974-2': 'No Data Available at the Moment',
  'shortCutMenu.ShortcutMenuPop.233974-3': 'Shortcut Menu',
  'shortCutMenu.ShortcutMenuPop.233974-4': 'Long Press',
  'shortCutMenu.ShortcutMenuPop.233974-5': 'and Drag to Adjust the Order',
  'shortCutMenu.ShortcutMenuPop.233974-6': 'Cancel',
  'shortCutMenu.ShortcutMenuPop.233974-7': 'Confirm',
  'systemNotice.systemDetailNotifyPop.053512-0': 'System Announcement',
  'systemNotice.systemDetailNotifyPop.053512-1': 'Reply to Official Documents',
  'systemNotice.systemDetailNotifyPop.053512-2': 'Reply to the recipient',
  'systemNotice.systemDetailNotifyPop.053512-3': 'Content',
  'systemNotice.systemDetailNotifyPop.053512-4': 'Please enter',
  'systemNotice.systemDetailNotifyPop.053512-5': 'Picture',
  'systemNotice.systemDetailNotifyPop.053512-6': 'Attachment',
  'systemNotice.systemDetailNotifyPop.053512-7': 'Cancel',
  'systemNotice.systemDetailNotifyPop.053512-8': 'Confirm',
  'systemNotice.systemDetailNotifyPop.053512-9': 'Operation successful!',
  'systemNotice.systemDetailNotifyPop.053512-10': 'Please enter',
  'systemNotice.systemDetailNotifyPop.053512-11': 'Please select personnel',
  'systemNotice.systemNotice.666307-0': 'System Announcement',
  'systemNotice.systemNotice.666307-1':
    'No Announcements Available at the Moment',
  'systemNotice.systemNoticePop.286370-0': 'System Announcement',
  'systemNotice.systemNoticePop.286370-1': 'Notice Announcement',
  'systemNotice.systemNoticePop.286370-2': 'Search',
  'systemNotice.systemNoticePop.286370-3': 'No Data Available at the Moment',
  'systemNotice.systemNoticePop.286370-4': 'Notifications',
  'systemNotice.systemNoticePop.286370-5': 'System',
  'systemNotice.systemNoticePop.286370-6': 'All read',
  'systemNotice.systemNoticePop.286370-7': 'Reply to Official Documents',
  'systemNotice.systemNoticePop.286370-8': 'Reply to the recipient',
  'systemNotice.systemNoticePop.286370-9': 'Content',
  'systemNotice.systemNoticePop.286370-10': 'Please enter',
  'systemNotice.systemNoticePop.286370-11': 'Picture',
  'systemNotice.systemNoticePop.286370-12': 'Attachment',
  'systemNotice.systemNoticePop.286370-13': 'Cancel',
  'systemNotice.systemNoticePop.286370-14': 'Confirm',
  'systemNotice.systemNoticePop.286370-15': 'Please enter',
  'systemNotice.systemNoticePop.286370-16': 'Please select personnel',
  'systemNotice.systemNoticePop.286370-17':
    'Set all notices and announcements as read',
  'systemNotice.systemNoticePop.286370-18':
    'Set all system announcements as read',
  'systemNotice.systemNoticePop.286370-19': 'No unread data available',
  'systemNotice.systemNoticePop.286370-20': 'Operation successful!',
  'systemNotice.systemNoticePop.286370-21': 'Notice',
  'systemNotice.systemNoticePop.286370-22': 'Bulletin',
  'systemNotice.systemNoticePop.286370-23': 'Opinions',
  'systemNotice.systemNoticePop.286370-24': 'Command',
  'systemNotice.systemNoticePop.286370-25': 'Decision',
  'systemNotice.systemNoticePop.286370-26': 'Resolution',
  'components.popContentList.202539-5': 'Project：',
  'components.popContentList.202539-6': 'No Data Available at the Moment',
  'components.popContentList.202539-7': 'Operation successful!',
  'components.popContentMain.005741-0': 'Project：',
  'components.popContentMain.005741-1': 'Publisher：',
  'components.popContentMain.005741-2': 'Publication Time：',
  'components.popContentMain.005741-3': 'No Data Available at the Moment',
  'components.popContentSider.441255-0': 'Set as Read',
  'components.popContentSider.441255-1': 'I Have Read',
  'components.popContentSider.441255-2': 'Click to Process',
  'components.popContentSider.441255-3': 'I Have Processed it',
  'components.popContentSider.441255-4': 'Click to Reply ',
  'components.popContentSider.441255-5': 'I Have Replied',
  'calendar.CalendarComponent.189440-0': '',
  'calendar.CalendarComponent.189440-1': '月',
  'calendar.CalendarComponent.189440-2': 'Back to Today',
  'calendar.CalendarComponent.189440-3': 'Today',
  'calendar.CalendarComponent.189440-4': '{0}',
  'calendar.CalendarComponent.189440-5': 'Back to Week',
  'calendar.CalendarComponent.189440-6': 'Week {0}',
  'calendar.CalendarComponent.189440-7': 'Back to Month',
  'calendar.CalendarComponent.189440-8': '{0}月',
  'calendar.CalendarComponent.189440-9': 'T',
  'calendar.CalendarComponent.189440-10': '本',
  'calendar.CalendarComponent.189440-11': '周',
  'calendar.CalendarComponent.189440-12': 'Today',
  'calendar.CalendarComponent.189440-13': 'Mon.',
  'calendar.CalendarComponent.189440-14': 'Tue.',
  'calendar.CalendarComponent.189440-15': 'Wed.',
  'calendar.CalendarComponent.189440-16': 'Thu.',
  'calendar.CalendarComponent.189440-17': 'Fri.',
  'calendar.CalendarComponent.189440-18': 'Sat.',
  'calendar.CalendarComponent.189440-19': 'Sun.',
  'calendar.CalendarComponent.189440-20': 'Monday',
  'calendar.CalendarComponent.189440-21': 'Tuesday',
  'calendar.CalendarComponent.189440-22': 'Wednesday',
  'calendar.CalendarComponent.189440-23': 'Thursday',
  'calendar.CalendarComponent.189440-24': 'Friday',
  'calendar.CalendarComponent.189440-25': 'Saturday',
  'calendar.CalendarComponent.189440-26': 'Sunday',
};
