<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 11:51:08
 * @Description:
-->
<template>
  <div style="height:100%;width:100%;">
    <div v-if="!inpage">
      <el-select popper-class="pover" class="new-select-style" :value="seatValue" multiple>
        <template #prefix>
          <div v-if="showValue" class="show-value">
            {{ showValue }}
          </div>
          <div v-else class="show-value" style="color: #999">
            {{ $t('components.cascader.878119-0') }}
          </div>
        </template>
        <template #empty>
          <div
            v-loading="showTree"
            class="pover-content"
            :style="'height:' + maxHeight + 'px'"
          >
            <div class="content-left">
              <div class="content-left-search">
                <el-input v-model="searchValue" :placeholder="$t('components.cascader.878119-1')" />
              </div>
              <div class="content-left-tree scroller">
                <el-tree
                  ref="treeRef"
                  :data="dataList"
                  :render-after-expand="isJgsc"
                  :default-checked-keys="showTreeKeys"
                  :default-expanded-keys="isJgsc ? expandKeys : []"
                  :default-expand-all="!isJgsc ? defaultExpand : false"
                  :filter-node-method="filterNode"
                  node-key="idKey"
                  :lazy="isJgsc"
                  :load="loadNode"
                  :props="{
                    label: 'name',
                    children: 'children',
                    isLeaf: 'leaf'
                  }"
                  :show-checkbox="isCheckType === 'cascaderCheckbox' ? true : false"
                  @check="handleCheckChange"
                  @node-click="handleNodeClick"
                >
                  <template #default="{ node }">
                    <span class="custom-tree-node">
                      <el-tooltip
                        class="item"
                        :content="node.label"
                        effect="dark"
                        placement="top-start"
                        :open-delay="1000"
                      >
                        <div style="display: flex;">
                          <div :style="{color: getTypeInfo(node) ? getTypeInfo(node).color : '',fontWeight: 600,display: 'flex', alignItems: 'center', marginRight: '4px' }">
                            <span v-if="getTypeInfo(node) && getTypeInfo(node).name !== '文' && getTypeInfo(node).name !== '人'">
                              {{ getTypeInfo(node) ? getTypeInfo(node).name : '' }}
                            </span>
                            <i v-if="getTypeInfo(node) && getTypeInfo(node).name === '文'" class="iconfont iconwenjianjia" />
                          </div>
                          {{ node.label }}
                        </div>
                      </el-tooltip>
                    </span>
                  </template>
                </el-tree>
              </div>
            </div>
            <div class="content-right">
              <div class="content-right-top">
                <span>
                  {{ $t('components.cascader.878119-2') }}
                  <span class="content-right-top-number">
                    {{ selectUserList.length }}
                  </span>
                  {{ $t('components.cascader.878119-3') }}
                </span>
                <span
                  style="float: right; color: #0076ff; cursor: pointer"
                  @click="clearChoose(1)"
                >
                  {{ $t('components.cascader.878119-4') }}
                </span>
              </div>
              <div class="content-right-choose">
                <div
                  v-for="(item) in selectUserList"
                  :key="item.idKey"
                  class="content-show"
                >
                  <div class="content-show-name">
                    <el-tooltip
                      class="item"
                      :content="item.name + getShowName(item)"
                      effect="dark"
                      placement="top-start"
                      :open-delay="1000"
                    >
                      <p>{{ item.name }}{{ getShowName(item) }}</p>
                    </el-tooltip>
                    <p>{{ item.companyName ? item.companyName : item.projectName }}</p>
                  </div>
                  <div class="content-show-delete">
                    <i
                      class="iconfont iconbimgis_guanbi"
                      @click.stop="deleteClick(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-select>
    </div>
    <div v-if="inpage" class="pover" style="width: 100%; height: 100%">
      <!-- <div class="show-value" style="height: 30px; line-height: 30px">
        <span>{{ showValue }}</span>
      </div> -->
      <div v-loading="showTree" class="pover-content">
        <div class="content-left">
          <div class="content-left-search">
            <el-input v-model="searchValue" :placeholder="$t('components.cascader.878119-1')" />
          </div>
          <div class="content-left-tree scroller">
            <el-tree
              ref="treeRef"
              :data="dataInfo"
              :default-checked-keys="showTreeKeys"
              :default-expand-all="defaultExpand"
              :filter-node-method="filterNode"
              node-key="idKey"
              :this="{
                label: 'name',
                children: 'children',
              }"
              :show-checkbox="isCheckType === 'cascaderCheckbox' ? true : false"
              @check-change="handleCheckChange"
              @node-click="handleNodeClick"
            />
          </div>
        </div>
        <div class="content-right">
          <div class="content-right-top">
            <span>
              {{ $t('components.cascader.878119-2') }}
              <span class="content-right-top-number">
                {{ selectUserList.length }}
              </span>
              {{ $t('components.cascader.878119-3') }}
            </span>
            <span
              style="float: right; color: #0076ff; cursor: pointer"
              @click="clearChoose(1)"
            >
              {{ $t('components.cascader.878119-4') }}
            </span>
          </div>
          <div class="content-right-choose">
            <div
              v-for="(item, index) in selectUserList"
              :key="index"
              class="content-show"
            >
              <div class="content-show-name">
                <el-tooltip
                  class="item"
                  :content="item.name + '/' + getShowName(item)"
                  effect="dark"
                  placement="top-start"
                  :open-delay="1000"
                >
                  <p>{{ item.name }}{{ getShowName(item) }}</p>
                </el-tooltip>
                <p>{{ item.companyName ? item.companyName : item.projectName }}</p>
              </div>
              <div class="content-show-delete">
                <i
                  class="iconfont iconbimgis_guanbi"
                  @click.stop="deleteClick(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Select,Tooltip,Tree,Input} from 'element-ui'
export default{
  name: '',
  components: {'el-select':Select,
		'el-tooltip':Tooltip,
		'el-tree':Tree,
		'el-input':Input
	},
  props: {
    dataInfo: {
      type: Array,
      default: () => []
    },
    showList: {
      type: Array,
      default: () => []
    },
    inpage: {
      type: Boolean,
      default: false
    },
    // cascaderCheck 级联单选
    // cascaderCheckbox 级联多选
    isCheckType: {
      type: String,
      default: 'cascaderCheckbox'
    },
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true
    },
    /* 展示内容 */
    showTree: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return{
      treeRef: null,
      showTreeKeys: [],
      selectUserList: [],
      searchValue: '',
      firstIn: false,
      seatValue: '',
      isJgsc: false,
      dataList: [],
      expandKeys: []
    }
  },
  computed:{
    /* 计算当前选择的人员回显 */
    showValue(){
      let str = ''
      this.selectUserList.forEach((item) => {
        if (!str) {
          str += item.name
        } else {
          str += ',' + item.name
        }
      })
      return str
    }
  },
  watch:{
    searchValue:{
      handler(val){
        if (!this.isJgsc) {
          this.$refs.treeRef.filter(val)
        } else {
          /* 搜索数据结构方法 */
          const mapTree = (value, arr) => {
              let newarr = [];
              arr.forEach(element => {
                  if (element.name.indexOf(value) > -1) { // 判断条件
                      element.open=true
                      newarr.push(element);
                  } else {
                  if (element.children && element.children.length > 0) {
                      let redata = mapTree(value, element.children);
                      if (redata && redata.length > 0) {
                          let obj = {
                              ...element,
                              children: redata
                          };
                              newarr.push(obj);
                          }
                      }
                  }
              });
              return newarr;
          }
          if (val) {
            const treeData = mapTree(val, this.dataInfo)
            const arr = []
            if (treeData?.length) {
              treeData.forEach((element) => {
                if (element?.company || element?.company?.length === 0) {
                  delete element.company
                }
                if (element?.project || element?.project?.length === 0) {
                  delete element.project
                }
                if (element?.sections || element?.sections?.length === 0) {
                  delete element.sections
                }
                arr.push(element)
              })
            }
            this.dataList = arr
          } else {
            const arr = []
            if (this.dataInfo?.length) {
              this.dataInfo.forEach((element) => {
                if (element?.company || element?.company?.length === 0) {
                  delete element.company
                }
                if (element?.project || element?.project?.length === 0) {
                  delete element.project
                }
                if (element?.sections || element?.sections?.length === 0) {
                  delete element.sections
                }
                arr.push(element)
              })
            }
            this.dataList = arr
            const showKeys = []
            if (this.selectUserList?.length) {
              this.selectUserList.forEach(element => {
                showKeys.push(element.idKey)
              });
            }
            this.showTreeKeys = showKeys
            this.$refs.treeRef.setCheckedKeys(showKeys)
            this.returnShowTreeInfo(this.showTreeKeys)
          }
        }
      },
      deep:true
    },
    dataInfo:{
      handler(val){
        if (this.isJgsc) {
          this.dataList = []
          if (this.dataInfo?.length) {
            this.dataInfo.forEach(element => {
              const obj = JSON.parse(JSON.stringify(element))
              obj.children = []
              this.dataList.push(obj)
            })
          }
        } else {
          this.dataList = this.dataInfo
        }
        this.showTreeInfo(this.showList, val)
      },
      deep:true
    },
    showList:{
      handler(val){
        this.showTreeInfo(val)
      },
      deep:true
    },
  },
  methods: {
    /* 当前选择的idKey数组 */
    handleCheckChange(){
      /* 级联单选 */
      if (this.isCheckType === 'cascaderCheck') {
        this.returnShowTreeInfo(this.showTreeKeys)
        return
      }
      // const treeRef = ref(this.treeRef)
      const keys = this.$refs.treeRef.getCheckedKeys()
      let arr = []
      if( keys && keys.length) {
        const loopTree = (array) => {
          array.forEach(element => {
            if (keys.indexOf(element.idKey) !== -1 && (element.users && element.users.length >= 0)) {
              arr.push(+element.idKey)
            }
            if (element.children && element.children.length) {
              loopTree(element.children)
            }
          });
        }
        loopTree(this.dataInfo)
        this.showTreeKeys = arr
      }else{
        this.showTreeKeys = []
      }
      this.returnShowTreeInfo(arr)
    },
    /* 级联单选 */
    handleNodeClick(data){
      if (this.isCheckType === 'cascaderCheckbox') {
        return
      }
      if (data.section_id) {
        this.showTreeKeys = []
        this.showTreeKeys = [data.idKey]
      }
      this.returnShowTreeInfo(this.showTreeKeys)
    },
    /* 当前选择的人员数组数据（item包含所有信息） */
    returnShowTreeInfo(list){
      const selectArr = []
      list.forEach((element) => {
        const getKey = (array) => {
          array.forEach((itemy) => {
            if (itemy.users && itemy.users.length >= 0) {
              if (itemy.idKey === element) {
                selectArr.push(itemy)
              }
            } else if (itemy.children && itemy.children.length) {
              getKey(itemy.children)
            }
          })
        }
        getKey(this.dataInfo)
      })
      if (this.isJgsc && this.searchValue) {
        const unique = (arr, attrName) => {
          const res = new Map()
          return arr.filter(
            (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
          )
        }
        let newArr = this.selectUserList.concat(selectArr)
        newArr = unique(newArr, 'id')
        this.selectUserList = newArr
      } else {
        this.selectUserList = selectArr
      }
      this.$emit('on-select-change', this.selectUserList)
    },
    /* 传入回显的showList 获取勾选数组  进行默认回显 */
    showTreeInfo(list){
      if (!list || !this.dataInfo || this.firstIn) {
        return
      }
      this.firstIn = true
      let showArr = []
      let expandKeys = []
      list.forEach((element) => {
        let singleKeys = []
        const showTree = (arr, id) => {
          for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (item.id === id && item.users && item.users.length >= 0) {
              showArr.push(item.idKey)
              singleKeys = item.fatherKeys
              break
            } else if (item.children && item.children.length) {
              item.children.forEach(ite => {
                if (!ite.fatherKeys || ite.fatherKeys.length === 0) {
                  ite.fatherKeys = [item.idKey]
                } else {
                  let array = JSON.parse(JSON.stringify(ite.fatherKeys))
                  array.push(item.idKey)
                  ite.fatherKeys = array
                }
              });
              showTree(item.children, id)
            }
          }
        }
        showTree(this.dataInfo, element.id)
        expandKeys = [...new Set([...expandKeys,...new Set(singleKeys)])]
      })
      this.expandKeys = [...new Set(expandKeys)]
      showArr = showArr.filter((item) => !!item) 
      this.showTreeKeys = showArr
      this.returnShowTreeInfo(this.showTreeKeys)
    },
    /* 删除单个勾选 */
    deleteClick(data){
      for (let index = 0; index < this.selectUserList.length; index++) {
        if (this.selectUserList[index].idKey === data.idKey) {
          this.selectUserList.splice(index, 1)
        }
      }
      const arr = []
      if (this.selectUserList?.length) {
        this.selectUserList.forEach(element => {
          arr.push(element.idKey)
        });
      }
      this.showTreeKeys = arr
      this.returnShowTreeInfo(this.showTreeKeys)
      this.$refs.treeRef.setCheckedKeys(this.showTreeKeys)
    },
    /* 清空所有勾选 */
    clearChoose(){
      if (this.isCheckType === 'cascaderCheck') {
        // const treeRef = ref(this.treeRef)
        this.$refs.treeRef.setCurrentKey(null)
        this.showTreeKeys = []
        this.selectUserList = []
        this.returnShowTreeInfo(this.showTreeKeys)
        this.$emit('on-select-change', this.selectUserList)
        return
      }
      this.selectUserList = []
      this.showTreeKeys = []
      this.$refs.treeRef.setCheckedKeys(this.showTreeKeys)
      this.$emit('on-select-change', this.selectUserList)
    },
    /* tree组件的过滤规则 */
    filterNode(value, data){
      if (!value) return true
      return !!(data.name && data.name.indexOf(value) !== -1)
    },
    getShowName(item){
      if (item.departmentName || item.externalName) {
        return item.departmentName
          ? '(' + item.departmentName + ')'
          : '(' + item.externalName + ')'
            ? '(' + item.externalName + ')'
            : ''
      } else {
        return ''
      }
    },
    /* 懒加载子节点 */
    loadNode(node, resolve) {
      let arr = []
      const loop = (array) => {
        array.forEach(element => {
          if (element.idKey === node?.data?.idKey) {
            const arrayList = []
            if (element.children?.length) {
              element.children.forEach(item => {
                const obj = JSON.parse(JSON.stringify(item))
                if (obj?.company || obj?.company?.length === 0) {
                  delete obj.company
                }
                if (obj?.project || obj?.project?.length === 0) {
                  delete obj.project
                }
                if (obj?.sections || obj?.sections?.length === 0) {
                  delete obj.sections
                }
                if (obj.users && obj.users.length >= 0) {
                  obj.leaf = true
                }
                obj.children = []
                arrayList.push(obj)
              })
            }
            arr = arrayList
          }
          if (element.children?.length) {
            loop(element.children)
          }
        })
      }
      if (this.dataInfo?.length && node?.data?.idKey) {
        loop(this.dataInfo)
        const unique = (arr, attrName) => {
          const res = new Map()
          return arr.filter(
            (a) => !res.has(a[attrName]) && res.set(a[attrName], 1)
          )
        }
        arr = node?.data?.children?.length ? node?.data?.children.concat(arr) : arr
        arr = unique(arr, 'idKey')
        arr.forEach(element => {
          if (element.users && element.users.length >= 0) {
            element.leaf = true
          }
        });
        resolve(arr)
        this.$refs.treeRef.setCheckedKeys(this.showTreeKeys)
      } else {
        resolve([])
      }
    },
    /* 根据架构级别返回显示不同的颜色和类型文字 */
    getTypeInfo (data) {
      const info = data.data
      if ((info.table_name && info.table_name.indexOf('companys') > -1) || (info.parent_id && !info.company_id) || info.type) {
        if (info.type) {
          return {
            name: '文',
            color: '#FFCB00'
          }
        }
        return {
          name: '公',
          color: '#A56EFF'
        }
      } else if ((info.table_name && info.table_name.indexOf('companys') === -1) || info.type) {
        return {
          name: '项',
          color: '#2BA5F5'
        }
      } else if (info.section_type || (info.company_id && info.children && info.children.length && info.children[0].user_id)) {
        return {
          name: '部',
          color: '#26A049'
        }
      } else if (info.user_id) {
        return {
          name: '人',
          color: '#26A049'
        }
      }
    },
  },
  created() {
    if (+process.env.VUE_APP_IS_JGSC === 1) {
      this.isJgsc = true
    }
    if (this.isJgsc) {
      const arr = []
      if (this.dataInfo?.length) {
        arr.forEach((element) => {
          if (element?.company || element?.company?.length === 0) {
            delete element.company
          }
          if (element?.project || element?.project?.length === 0) {
            delete element.project
          }
          if (element?.sections || element?.sections?.length === 0) {
            delete element.sections
          }
          arr.push(element)
        })
      }
      this.dataList = this.dataInfo
    } else {
      this.dataList = this.dataInfo
    }
  },
  mounted () {
    this.showTreeInfo(this.showList)
  }
}
</script>
<style lang="less" scoped>
.new-select-style {
  width: 100%;
  :deep(.el-input__suffix)  {
    display: none !important;
  }
  :deep(.el-input__inner)  {
    display: none !important;
  }
  :deep(.el-input__prefix)  {
    width: 100% !important;
    display: inherit !important;
    height: auto !important;
    position: inherit !important;
    padding: 5px !important;
    box-sizing: border-box !important;
  }
  :deep(.el-input--suffix) {
    width: 100% !important;
    display: inherit !important;
    height: auto !important;
    position: inherit !important;
    box-sizing: border-box !important;
  }
}
  .show-value {
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    line-height: 32px;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    padding: 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .pover {
    width: 428px;
    height: 100%;
    .pover-content {
      width: 428px;
      height: 100%;
      display: flex;
      overflow-y: auto;
      .content-left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        .content-left-search {
          height: 50px;
          padding: 5px 10px;
        }
        .content-left-tree {
          padding: 0 10px;
          flex: 1;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      .content-right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .content-right-top {
          height: 50px;
          line-height: 40px;
          padding: 0 10px;
          .content-right-top-number {
            color: var(--themeColor);
          }
        }
        .content-right-choose {
          flex: 1;
          padding: 0 10px;
          overflow-y: auto;
          .content-show {
            width: 100%;
            height: 50px;
            display: flex;
            .content-show-name {
              width: 88%;
              height: 50px;
              p {
                height: 25px;
                line-height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              p:nth-child(2) {
                font-size: 12px;
                color: #999;
              }
            }
            .content-show-delete {
              width: 10%;
              height: 50px;
              line-height: 50px;
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
