<!--
    @name: widget-select
    @description：widget-select
    @author: ZengWei
    @date: 2022-03-25 09:30
-->
<template>
  <div>
    <div class="select-show-content" v-if="disabled || element.config.disabled">
      <div class="input-show">{{ selectLabel }}</div>
      <el-button
        type="primary"
        v-if="element.config.__config__.outerViewer"
        :disabled="selectLabel === '-'"
        @click="openOuterViewer"
      >查看
      </el-button>
    </div>
    <div v-else style="position: relative">
      <div :class="{'outer-viewer':element.config.__config__.outerViewer}">
        <el-select
          ref="selectRef"
          v-model="element.value"
          :disabled="disabled || element.config.disabled"
          :placeholder="getPlaceholder(element.config)"
          :clearable="element.config.clearable"
          :multiple="element.config.multiple"
          :filterable="element.config.filterable"
          collapse-tags
          @focus="dynamicFilter"
          :loading="loading"
        >
          <template v-for="(val, key) in element.config.__slot__.options">
            <template v-if="getLabel(val).length > optionTextNum">
              <el-tooltip :key="key" effect="dark" :content="getLabel(val)" placement="top">
                <el-option
                  :value="optionValue(val.value)"
                  :label="getLabel(val)"
                  :key="key"
                >
                  {{ getLabel(val).substr(0, optionTextNum) + '...' }}
                </el-option>
              </el-tooltip>
            </template>
            <template v-else>
              <el-option
                :value="optionValue(val.value)"
                :label="getLabel(val)"
                :key="key"
              >
                {{ getLabel(val) }}
              </el-option>
            </template>
          </template>
        </el-select>
      </div>
      <el-button
        type="primary"
        v-if="element.config.__config__.outerViewer"
        style="position: absolute;top:0;right: 0;"
        @click="openOuterViewer"
      >查看
      </el-button>
    </div>
    <el-dialog
      top="5vh"
      :custom-class="'common-dialog'"
      title="外部内容查看"
      :visible.sync="outerViewerVisible"
      :width="outerViewerWidth"
      append-to-body
    >
      <div v-loading="outerViewerLoading" style="height: calc(100vh - 200px)">
        <iframe
          ref="iframe" :src="outerRouter"
          style="width: 100%; height: 100%;border: none"></iframe>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {Select,Dialog,Tooltip} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {getBodyParams, getSearchData, optionData} from "@/custom-component/form/newParser/scripts/tools";
import {formRequest} from "@/apis/data/form";

export default {
  name: "WidgetSelect",
  components: {
    'el-select': Select,
    'el-dialog': Dialog,
    'el-tooltip': Tooltip
  },
  props: ['element', 'disabled', 'displayData'],
  mixins: [formItemMinix],
  data() {
    return {
      outerViewerVisible: false,
      outerViewerLoading: false,
      outerViewerWidth: '100%',
      outerViewerParam: {},
      outerRouter: '',
      optionTextNum: 20
    }
  },
  watch:{
    'element.value': {
      handler(val) {
        this.triggerInteractive(val)
      }
    }
  },
  computed: {
    selectLabel() {
      let value = this.element.value;
      const multiple = this.element.config.multiple;
      const data = this.element.config.__slot__.options
      if (value && Array.isArray(data) && data?.length) {
        // 多选
        if (multiple && Array.isArray(value) && value?.length) {
          value = value.map(el => {return el.toString()})
          let labelArr = data.filter((item) => value.includes(item.value.toString()))
          if (labelArr.length) {
            labelArr = labelArr.map(el => { return this.$getLocalizedLabel({'zhLabel': el.label, 'enLabel': el.enLabel}) })
            return labelArr.join(' ; ')
          } else {
            return '-'
          }
        } else {
          const obj = data.find((item) => item?.value?.toString() === value.toString());
          let label = this.$getLocalizedLabel({'zhLabel': obj?.label, 'enLabel': obj?.enLabel})
          if (label) {
            return label
          } else {
            return '-'
          }
        }
      } else {
        return '-'
      }
    }
  },
  created() {
    const dynamic = this.element.config.__config__.dataType
    if(dynamic === 'static'){
      this.triggerInteractive(this.element.value)
    } else {
      this.initDynamicData();
    }
  },
  mounted() {
    if(this.$refs?.selectRef?.$el){
      const selectWidth = this.$refs.selectRef.$el.clientWidth
      this.optionTextNum = parseInt(selectWidth / 15)
    }
  },
  methods: {
    /**
     * @desc: 数据类型统一
     * @param {*} value
     */
    optionValue(value) {
      const multiple = this.element.config.multiple;
      if (!multiple) {
        this.element.value = this.element.value.toString();
      }
      return '' + value
    },
    initDynamicData() {
      const filterRule = this.element.config.__config__.filterRule ?? []
      const objectUuid = this.element.config.__config__.objectUuid
      const viewUuid = this.element.config.__config__.viewUuid
      const dynamic = this.element.config.__config__.dataType
      if(this.element.type === 'select' && dynamic === 'static'){
        return false; //select 静态数据
      }
      let url= '/api/mapi', params;
      let searchData = getSearchData(filterRule,this.parser.getFormIdValue);
      params = {
        __method_name__: 'dataList', object_uuid: objectUuid,
        view_uuid: viewUuid, search: searchData, size: 1000,
        __now_archi_type: this.parser.nowArchiType,
      }
      let bodyParams = getBodyParams(filterRule,this.parser.getFormIdValue);
      if(Object.keys(bodyParams).length) {
        params = Object.assign(params, bodyParams)
      }
      if(searchData.length) params.search = searchData
      if(objectUuid && viewUuid){
        formRequest('post', url, params).then(res => {
          let respData;
          respData = res.data.data;
          if (res.data.data.data) {
            respData = res.data.data.data;
          }
          const tag = this.element.config.__config__.tagIcon;
          const propLabel = this.element.config.props.props.label;
          const propEnLabel = this.element.config.props.props.enLabel;
          const propValue = this.element.config.props.props.value;
          const options = optionData(respData,tag,propLabel,propValue)
          // 字典翻译
          if (this.$i18n.locale === 'en') {
            options.map(item => {
              this.$set(item, 'enLabel', item?.allField[propEnLabel]);
            })
          }
          if(options){
            this.element.config.__slot__.options = options
          }
          this.triggerInteractive(this.element.value,'auto')
          this.loading = false
        });
      }
    },
    dynamicFilter(){
      const childFilter = this.element.config.__config__?.childFilter || false
      const filterRule = this.element.config.__config__?.filterRule || []
      if(filterRule.length && !childFilter){
        this.loading = true
        this.initDynamicData();
      }
    },
    openOuterViewer() {
      this.outerViewerVisible = true
      let route = this.element.config.__config__.outerRouter
      const width = this.element.config.__config__.windowWidth
      if (width) this.outerViewerWidth = width
      const obj = this.outerViewerParam
      const keys = Object.keys(obj)
      const regex = /\{(.+?)\}/g
      const arr = route.match(regex)
      if (arr !== null) {
        for (let item of arr) {
          let param = item.replace('{', '').replace('}', '')
          if (keys.includes(param)) {
            route = route.replace(item, obj[param])
          }
        }
      }
      this.outerRouter = route
      this.outerViewerLoading = true
      this.$nextTick(() => {
        console.log(this.$refs.iframe)
        this.$refs.iframe.onload = () => {
          this.outerViewerLoading = false
        }
      })
    },
    getLabel(val) {
      return this.$getLocalizedLabel({'zhLabel': val.label, 'enLabel': val.enLabel}).toString();
    },
    getPlaceholder(item) {
      return this.$i18n.locale === 'en' ? item.enPlaceholder : item.zhPlaceholder
    }
  },
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.select-show-content {
  display: flex;
  .select-show {
    width: 100%;
    padding: 5px 12px;
    box-sizing: border-box;
    background: #F2F4F7;
    border-radius: 4px;
    font-size: 14px;
    font-family: var(--systemFontFamily);
    color: #2A2F3D;
    line-height: 22px;
    cursor: default;
  }
}
</style>
