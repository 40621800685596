/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-05-13 13:49:03
 * @FilePath: \dataview-viewer-test\src\custom-component\form\lang\en.js
 */
export default {
  "form.CommonForm.618069-0": "Please enter",
  "form.CommonForm.618069-1": "Please select",
  "form.CommonForm.618069-2": "To",
  "form.CommonForm.618069-3": "Start Time",
  "form.CommonForm.618069-4": "End Time",
  "form.CommonForm.618069-5": "All",
  "form.CommonForm.618069-6": "Please configure the event correctly!",
  "form.CommonForm.618069-7": "Operation successful!",
  "form.CommonForm.618069-8": "00000 Backend Event Error",
  "form.CommonDateFilter.730870-0": "Last 24 hours",
  "form.CommonDateFilter.730870-1": "Last 3 days",
  "form.CommonDateFilter.730870-2": "Last week",
  "form.CommonDateFilter.730870-3": "Last month",
  "form.CommonDateFilter.730870-4": "Last year",
  "form.CommonDateFilter.730870-5": "Start Date",
  "form.CommonDateFilter.730870-6": "End Date",
  "form.CommonBaseDate.199253-0": "Please select",
  "form.CommonBaseDate.199253-1": "To",
  "form.CommonBaseDate.199253-2": "Start Time",
  "form.CommonBaseDate.199253-3": "End Time",
  "form.CommonBaseDate.199253-4": "Today",
  "form.CommonBaseDate.199253-5": "Last 3 days",
  "form.CommonBaseDate.199253-6": "Last week",
  "form.CommonBaseDate.199253-7": "Year to Date",
  "form.CommonBaseDate.199253-8": "Last 3 months",
  "form.CommonBaseDate.199253-9": "Last 6 months",
  "form.CommonBaseDate.199253-10": "Last year",
  "form.CommonBaseDate.199253-11": "Last month",
  "form.CommonBaseDate.199253-12": "This month",
  "form.CommonBaseDate.199253-13": "This week",
  "form.CommonBaseDate.199253-14": "This quarter",
  "form.CommonBaseDate.199253-15": "This year",
  "form.CommonBaseDate.199253-16": "Month to Date",
  "form.CommonBaseDate.199253-17": "Year to Date",
  "FormItem.widget-form.245043-0": "Num",
  "FormItem.widget-form.245043-1": "Action",
  "FormItem.widget-form.245043-2": "File Preview",
  "FormItem.widget-form.245043-3": "Details",
  "FormItem.widget-form.245043-4": "Add",
  "FormItem.widget-form.245043-5": "Select Related Data",
  "FormItem.widget-form.245043-6": "Cancel",
  "FormItem.widget-form.245043-7": "Confirm",
  "FormItem.widget-form.245043-8": "Switch Display to Fix Leveling Issues",
  "FormItem.widget-form.245043-9": "Data Validation Failed",
  "FormItem.widget-form.245043-10": "Popup Parameters",
  "FormItem.widget-form.245043-11": "Formula Execution Error Message",
  "FormItem.widget-form.245043-12": "Calculation Formula Configuration Error",
  "newParser.PcParserNew.417838-0": "Cancel",
  "newParser.PcParserNew.417838-1": "Submit",
  "newParser.PcParserNew.417838-2": "Process Approval",
  "newParser.PcParserNew.417838-3": "Close Process Diagram",
  "newParser.PcParserNew.417838-4": "Process Diagram",
  "newParser.PcParserNew.417838-5": "No Process Data Available",
  "newParser.PcParserNew.417838-6": "External Form",
  "newParser.PcParserNew.417838-7": "Basic Information",
  "scripts.parserMethods.537613-0": "Data validation failed",
  "scripts.parserMethods.537613-1": "Error executing formula",
  "scripts.parserMethods.537613-2": "Calculation formula configuration error",
  "scripts.parserMethods.537613-3": "Data validation failed. Please check the required fields carefully!",
  "scripts.parserMethods.537613-4": "Incomplete approvers selected. Please select all approvers!",
  "scripts.parserMethods.537613-5": "--Form Data--",
  "components.cascader.396254-0": "Please select personnel",
  "components.cascader.396254-1": "Add personnel",
  "components.cascader.396254-2": "Enter personnel name",
  "components.cascader.396254-3": "Wen",
  "components.cascader.396254-4": "Ren",
  "components.cascader.396254-5": "Selected personnel (",
  "components.cascader.396254-6": "Clear",
  "components.cascader.396254-7": "Selected",
  "components.cascader.396254-8": "Public",
  "components.cascader.396254-9": "Items",
  "components.cascader.396254-10": "Dept",
  "components.cascader.878119-0": "Please select",
  "components.cascader.878119-1": "Enter department name",
  "components.cascader.878119-2": "Selected",
  "components.cascader.878119-3": "People",
  "components.cascader.878119-4": "Clear",

	"FormItem.widget-longlat.186372-0": "Re-select",
	"FormItem.widget-longlat.186372-1": "Detailed Address:",
	"FormItem.widget-longlat.186372-2": "Failed to retrieve",
	"FormItem.widget-longlat.186372-3": "Longitude:",
	"FormItem.widget-longlat.186372-4": "Latitude:",
	"FormItem.widget-longlat.186372-5": "None",
	"FormItem.widget-longlat.186372-6": "Please select",
	"FormItem.widget-longlat.186372-7": "China",
	"FormItem.widget-longlat.186372-8": "Are you sure you want to delete the selected point?",
	"FormItem.widget-longlat.186372-9": "Prompt",

  "FormItem.widget-database.865990-0": "Operation",
  "FormItem.widget-database.865990-1": "Details",
  "FormItem.widget-database.865990-2": "Select Associated Data",
  "FormItem.widget-database.865990-3": "Cancel",
  "FormItem.widget-database.865990-4": "Confirm",

  "FormItem.widget-date-range.737753-0": "The date range can only select a maximum of [{0}] days, and the deadline has been automatically changed",

  "components.FlowRecover.197417-0": "Flow Approval Records",
  "components.FlowRecover.197417-1": "Close",
  "components.FlowRecover.197417-2": "View",
  "components.FlowRecover.197417-3": "Flowchart",
  "components.FlowRecover.197417-4": "Month $",
  "components.FlowRecover.197417-5": "Day $",
  "components.FlowRecover.197417-6": "Approver:"
}