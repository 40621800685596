/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-05-20 17:34:40
 * @FilePath: \dataview-viewer-test\src\views\newSecond\lang\zh.js
 */

export default {
  'username': '用户名',
  'email': '邮箱',
  'mobile': '手机',
  'placeholder': '请输入',
  'editHomePage': '编辑主页',
  'saveHomePage': '保存主页',
  'addDrawingBoard': '添加画板',
  "enterprise.Index.766519-0": "公司",
  "recent.Index.934494-0": "企业",
  "recent.Index.934494-1": "项目",
  "index.SelectArchi.934493-0": "最近查看",
  "index.SelectArchi.934493-1": "企业",
  "index.SelectArchi.934493-2": "项目",
  "secondEdition.SearchBox.934494-0": "搜索",
  "index.GridDrawer.934494-0": "取消",
  "index.GridDrawer.934494-1": "确定",
  "index.GridDrawer.934494-2": "选择项目成功!",
  "index.HeaderWeather.274545-1": "天气",
  "secondEdition.ArchiInfo.934494-0": "企业",
  "secondEdition.ArchiInfo.934494-1": "项目",
  "secondEdition.ArchiInfo.934494-2": "看板",
  "secondEdition.ArchiInfo.934494-3": "开发中",
  "secondEdition.ArchiInfo.934494-4": "可视化大屏",
  "secondEdition.ArchiInfo.934494-5": "选择",
  "secondEdition.ArchiInfo.934494-6": "】架构下,无【$",
  "secondEdition.ArchiInfo.934494-7": "【{0}】架构下,无【{1}】权限!",
  "secondEdition.ArchiInfo.934494-8": "功能开发中，敬请期待...",
  "index.GridComponent.540553-0": "编辑",
  "index.GridComponent.540553-1": "您确定要删除吗？",
  "index.GridComponent.540553-2": "删除",
  "index.GridComponent.540553-3": "选择项目",
  "index.GridComponent.540553-4": "待办事项",
  "index.GridComponent.540553-5": "我的应用",
  "index.GridComponent.540553-6": "通知公告",
  "index.GridComponent.540553-7": "建设看板",
  "index.GridComponent.540553-8": "新闻动态",
  "index.GridComponent.540553-9": "质安统计",
  "index.GridComponent.540553-10": "进度统计",
  "index.GridComponent.540553-11": "考勤统计",
  "index.GridComponent.540553-12": "获取组件失败！",
  "index.GridComponent.540553-13": "放置到画布根节点上!",
  "index.GridComponent.540553-14": "出错了!",
  "index.GridComponent.540553-15": "保存成功!",
  "index.GridComponent.540553-16": "建设统计",
  "index.GridComponent.540553-17": "平台动态",
  "news.Index.223775-0": "项目风采",
  "news.Index.223775-1": "新闻图片",
  "news.Index.223775-2": "暂无图片",
  "news.Index.223775-3": "详情",
  "news.Index.223775-4": "详情",
  "news.Index.223775-5": "来源：",
  "safety.index.730335-0": "质安统计",
  "safety.index.730335-1": "安全发布",
  "safety.index.730335-2": "质量发布",
  "schedule.index.091865-0": "进度统计",
  "schedule.index.091865-1": "填方完成量",
  "schedule.index.091865-2": "汇总",
  "schedule.index.091865-3": "场道部分（万m³）",
  "schedule.index.091865-4": "桩基完成量",
  "schedule.index.091865-5": "T3航站区部分（根）",
  "people.index.282976-0": "考勤统计",
  "todoCom.index.735398-0": "待办事项",
  "todoCom.index.735398-1": "预警信息",
  "todoCom.index.735398-2": "抄送",
  "todoCom.index.735398-3": "没有更多数据了",
  "todoCom.TodoList.785059-0": "项目：",
  "todoCom.TodoList.785059-1": "暂无数据",
  "todoCom.TodoList.785059-2": "安",
  "todoCom.TodoList.785059-3": "质",
  "todoCom.TodoList.785059-4": "进",
  "todoCom.TodoList.785059-5": "退回",
  "todoCom.TodoList.785059-6": "发起",
  "todoCom.TodoList.785059-7": "完成",
  "todoCom.TodoList.785059-8": "审核中",
  "todoCom.TodoList.785059-16": "拒绝",
  "todoCom.TodoList.785059-17": "作废",
  "todoCom.TodoList.785059-9": "逾期{0}天",
  "todoCom.TodoList.785059-10": "已滞后",
  "todoCom.TodoList.785059-11": "今天{0}截止",
  "todoCom.TodoList.785059-12": "待处理",
  "todoCom.TodoList.785059-13": "{0}截止",
  "todoCom.TodoList.785059-14": "{0}截止",
  "todoCom.TodoList.785059-15": "{0}截止",
  "todoCom.TodoList.785059-NO": "编号:{0}",
  "todoCom.WarringDialog.788995-0": "预警信息",
  "todoCom.WarringDialog.788995-1": "警告等级：",
  "todoCom.WarringDialog.788995-2": "警告类型：",
  "todoCom.WarringDialog.788995-3": "其他",
  "todoCom.WarringDialog.788995-4": "预警】",
  "todoCom.WarringDialog.788995-5": "警告内容：",
  "todoCom.WarringDialog.788995-6": "取 消",
  "todoCom.WarringDialog.788995-7": "已 读",
  "todoCom.WarringDialog.788995-8": "确认关闭？",
  "todoCom.WarringDialog.788995-9": "黄",
  "todoCom.WarringDialog.788995-10": "橙",
  "todoCom.WarringDialog.788995-11": "蓝",
  "todoCom.WarringDialog.788995-12": "红",
  "todoCom.WarringDialog.788995-13": "{0}色预警",
  "todoCom.WarringDialog.788995-14": "紫色预警",
  "todoCom.WarringDialog.788995-15": "其他预警",
  "todoCom.WarringList.792663-0": "其他",
  "todoCom.WarringList.792663-1": "预警】",
  "todoCom.WarringList.792663-2": "项目：",
  "todoCom.WarringList.792663-3": "暂无数据",
  "todoCom.WarringList.792663-4": "黄",
  "todoCom.WarringList.792663-5": "橙",
  "todoCom.WarringList.792663-6": "蓝",
  "todoCom.WarringList.792663-7": "红",
  "todoCom.WarringList.792663-8": "{0}色预警",
  "todoCom.WarringList.792663-9": "紫色预警",
  "todoCom.WarringList.792663-10": "其他预警",
  "components.UserActions.590825-0": "清除缓存",
  "components.UserActions.590825-1": "个人中心",
  "components.UserActions.590825-2": "退出登录",
  "components.UserActions.590825-3": "清除中...",
  "components.UserActions.590825-4": "清除成功!",
  "project.Index.426442-1": "全部项目",
  "appQrcode.Index.601905-0": "扫码下载APP",
  "appQrcode.Index.601905-1": "请使用系统自带扫码功能",
  "appQrcode.Index.601905-2": "扫码下载水印相机",
  "notice.index.787958-0": "通知公告",
  "notice.index.787958-1": "公告",
  "notice.index.787958-2": "公报",
  "notice.index.787958-3": "决议",
  "notice.index.787958-4": "决定",
  "notice.index.787958-5": "详情",
  "notice.index.787958-6": "没有更多数据了",
  "statistic.index.036726-0": "建设看板",
  "statistic.index.036726-1": "平台动态",
  "statistic.index.036726-2": "工程图册",
  "statistic.index.036726-3": "没有更多数据了",
  "statistic.index.036726-4": "分",
  "statistic.index.036726-5": "暂未配置目标页面!",
  "index.Header.055237-0": "搜索",
  "apps.Index.568824-0": "暂无数据",
  "apps.Index.568824-1": "系统错误【未查询到关联应用】！",
  "apps.Index.568824-2": "无效快捷菜单！",
  "apps.Index.568824-3": "应用暂未配置有效首页!",
  "apps.Index.568824-4": "暂未配置目标页面!",
  "apps.Index.568824-5": "跳转附加参数错误!",
  "plalformDynamics.dynamicList.018760-0": "了【",
  "plalformDynamics.dynamicList.018760-1": "没有更多数据了",
  "setLanguage":'语言设置',
  "index.Header.697681-0": "无匹配结果",
  "index.Header.697681-1": "系统错误【未查询到关联应用】！",
  "index.Header.697681-2": "无效快捷菜单！",
  "index.Header.697681-3": "应用暂未配置有效首页!",
  "index.Header.697681-4": "暂未配置目标页面!",
  "index.Header.697681-5": "跳转附加参数错误!",
  'todoCom.TodoList.me':'我',
}


