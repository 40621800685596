<!--
 * @Author: your name
 * @Date: 2021-11-03 17:46:44
 * @LastEditTime: 2024-04-08 19:09:15
 * @LastEditors: hw 315249041@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \dataview-viewer-test\src\custom-component\netDisk\components\FileUpload.vue
-->
<template>
	<div class="file-upload">
		<el-upload
			v-if="!disabled && files.length<limit"
			ref="upload"
			:action="uploadURL"
			:before-upload="beforeUpload"
			class="my-upload-style"
			:data="uploadData"
			:drag="drag"
			:headers="{
        'metadatatoken': metadataToken,
      }"
			:limit="limit"
			:multiple="multiple"
			:on-progress="onProgress"
			:on-success="onSuccess"
			:show-file-list="uploading"
		>
			<el-button
				v-if="!drag"
				:icon="icon"
				type="default"
			>
				<i
					class="iconfont iconjijia_shangchuan"
					style="font-size: 16px"
				/>
				{{ btnName }}
			</el-button>
			<div
				v-if="drag"
				class="drag-text"
			>
				<i
					class="iconfont iconjijia_shangchuan"
					style="font-size: 24px; display: block;color: #5da6fa"
				/>
				<p style="font-size: 14px; font-weight: 400">
					<span style="color: #202126">{{ t('components.FileUpload.193460-0') }}</span>
					<span style="color: #8a8f99; margin: 0 8px">{{ t('components.FileUpload.193460-1') }}</span>
					<span style="color: #5da6fa">{{ t('components.FileUpload.193460-2') }}</span>
				</p>
			</div>
		</el-upload>

		<div
			v-if="showUploadList"
			class="upload-files-area"
		>
			<i
				class="iconfont iconc-close close-icon"
				@click="()=>{showUploadList = false}"
			/>
			<p class="title">
				{{ t('components.FileUpload.193460-3') }}
			</p>
			<div class="file-show">
				<div
					v-for="(item, index) in uploadListOfShow"
					:key="index"
				>
					<div class="file-item">
						<div class="file-item-left">
							<svg
								aria-hidden="true"
								class="iconfont-svg"
							>
								<use xlink:href="#iconfujian1" />
							</svg>
							<div class="name">
								{{ getFileName(item.filename).name }}
							</div>
							<div class="type">{{ getFileName(item.filename).type }}</div>
						</div>
						<div
							class="file-item-size"
							style="
            margin-left:87px
          "
						>
							{{ getFileSize(item.size) }}
						</div>
					</div>
					<div>
						<el-progress
							color="green"
							:duration="3"
							:format="(percent) => (percent == 100 ? t('components.FileUpload.193460-4') : `${percent}%`)"
							:percentage="item.percent"
							:stroke-width="3"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { parseTime } from '@/utils/tools';
import { Progress, Upload } from 'element-ui';
import i18n  from '@/locale/index';

export default {
	name: '',
	components: {
		'el-upload': Upload,
		'el-progress': Progress
	},
	props: {
		// 文件
		files: {
			type: Array,
			default() {
				return [];
			}
		},
		// 禁用
		disabled: {
			type: Boolean,
			default: false
		},
		// 按钮名
		btnName: {
			type: String,
			default: i18n.t('components.FileUpload.193460-5')
		},
		// icon
		icon: {
			type: String,
			default: ''
		},
		// 是否多选
		multiple: {
			type: Boolean,
			default: false
		},
		// 允许上传的文件格式
		fileTypeList: {
			type: Array,
			default: () => []
		},
		// 文件名超出最长长度后中间显示省略号
		nameMaxLength: {
			type: Number,
			default: 20
		},
		// 拖拽上传
		drag: {
			type: Boolean,
			default: false
		},
		// 文件上传数量
		limit: {
			type: Number,
			default: 100
		},
		// 仅图片地址数组(全路径)
		onlyImgUrl: {
			type: Boolean,
			default: false
		},
		// 上传不压缩图
		noThumb: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			uploadData: { token: this.$GetToken() },
			extra_file: [],
			uploading: false,
			// uploadURL: process.env.VUE_APP_BASE_URL + 'api/repository/uploadFile', // 上传地址 网盘地址
			uploadURL: process.env.VUE_APP_BASE_URL + 'api/mapi?__method_name__=file', // 上传地址
			token: this.$GetToken(), // token
			metadataToken: process.env.VUE_APP_METATOKEN,
			imageType: ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'], // 图片类型
			uploadList: [],
			showUploadList: false,
			uploadListOfShow: []
		};
	},
	mounted() {
		if (this.noThumb) {
			this.uploadURL = this.uploadURL + '&scaleScaling=0&isThumb=0';
			this.uploadData.scaleScaling = 0;
			this.uploadData.isThumb = 0;
		}
	},
	watch: {
		extra_file: {
			handler() {
				if (this.extra_file && this.extra_file.length) {
					this.uploadList = this.extra_file[this.extra_file.length - 1];
					this.$emit('extra-files', this.uploadList);
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		beforeUpload(file) {
			this.showUploadList = true;
			file.percent = 0;
			this.uploadListOfShow.push(file);
		},
		/**
		 * @description 上传成功回调
		 * @param {*} response
		 * @param {*} file
		 * @param {*} fileList
		 */
		onSuccess(res, file) {
			try{
				if (!Array.isArray(this.extra_file)) {
					this.extra_file = [];
				}

				if (this.extra_file == null) {
					this.extra_file = [];
				}
				// 判断上传文件是否为图片
				res.data.upTime = parseTime(new Date());
				res.data.name = res.data.filename;
				res.data.sourceID = file.raw.sourceID;
				if (this.imageType.indexOf(res.data.fileext.toLowerCase()) > -1) {
					res.data.path = res.data.thumb_path;
				} else {
					res.data.path = res.data.filepath;
				}
				this.extra_file.push(res.data);
				// this.$refs.upload.clearFiles() // TIPS 会导致后续读取file 失败, 先注释掉
			} catch (err) {
				console.log(err);
				this.$message.error(i18n.t('components.FileUpload.193460-6'));
			}
		},
		// 进度
		onProgress(event, file) {
			// const len = this.uploadListOfShow.length;
			file.percent = parseInt(event.percent);
			const index = this.uploadListOfShow.findIndex(ele => ele.uid === file.uid)
			this.uploadListOfShow.splice(index, 1, {
				...file
			});
		},
		/**
		 * @description 截取名称拼接省略号
		 * @param {String} name
		 */
		getFileName(name) {
			let type = '.' + name.split('.')[name.split('.')?.length - 1]
			const title = name.substring(0, name.length - type.length - 1);
			type = name[title.length] + type;
			return {
				type,
				name: title
			};
		},
		/**
		 * @description 截取名称拼接省略号
		 * @param {String} name
		 */
		getFileSize(fileSize) {
			// eslint-disable-next-line use-isnan
			if (fileSize && fileSize < 1024) {
				return fileSize + 'B';
			} else if (fileSize < 1024 * 1024) {
				let temp = fileSize / 1024;
				temp = temp.toFixed(2);
				return temp + 'KB';
			} else if (fileSize < 1024 * 1024 * 1024) {
				let temp = fileSize / (1024 * 1024);
				temp = temp.toFixed(2);
				return temp + 'MB';
			} else {
				let temp = fileSize / (1024 * 1024 * 1024);
				if (temp) {
					temp = temp.toFixed(2);
					return temp + 'GB';
				} else {
					return 0 + 'B';
				}
			}
		}
	}
};
</script>

<style lang="less" scoped>
.file-upload {
	width: 100%;
	height: auto;
	// padding: 0 10px 10px 0;
	box-sizing: border-box;
	position: relative;
	.my-upload-style {
		height: auto;
		.drag-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.upload-files-area {
		background: #fff;
    width: 420px;
    box-sizing: border-box;
		margin-right: 20px;
		position: fixed;
		right: 0;
		bottom: 10px;
		padding: 20px;
		box-shadow: 0 0 10px 1px rgb(228, 224, 219);
		border-radius: 4px;
		z-index: 999;
		clear: both;
		.close-icon {
			float: right;
			cursor: pointer;
		}
		.title {
			font-size: 14px;
			font-weight: 600;
			color: #25282e;
			line-height: 14px;
			padding-left: 4px;
			padding-bottom: 10px;
			border-bottom: 1px solid #d5d5d5;
		}
		.file-name {
			width: 100%;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: calc(100% - 23px);
			vertical-align: middle;
		}
		.file-show {
			max-height: 60vh;
			overflow-y: auto;
			font-size: 12px;
			.file-item {
				display: flex;
				height: 30px;
				line-height: 30px;
				justify-content: space-between;
				.file-item-left {
					flex: 1;
					display: flex;
					align-items: center;
					overflow: hidden;
					.iconfont-svg {
						width: 16px;
						height: 16px;
						background-color: transparent;
						margin-right: 5px;
					}
					.name {
						width: auto;
						max-width: calc(100% - 80px);
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.type {
						min-width: 60px;
					}
				}
			}
		}
    :deep(.el-progress){
      display: flex;
      .el-progress-bar{
        margin-top: 5px;
        padding-right: 30px;
      }
      .el-progress__text{
        width: 100px;
        text-align: right;
      }
    }
	}
}
</style>
