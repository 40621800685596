
/**
 * @description 功能组件相关配置
 */

// 功能组件小项配置
const functionComponentsList = [
  {
    name: '路由容器',
    component: 'RouterCom',
    type: 'functionCom',
    icon: 'icon-luyou',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
    }
  },
  {
    name: '内嵌页面',
    component: 'IframeCom',
    type: 'functionCom',
    icon: 'icon-neiqianyemian',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      isPreview: false,
      src: 'http://www.baidu.com'
    }
  },
  {
    name: '表格列表',
    component: 'TableCom',
    type: 'container',
    icon: 'icon-liebiao',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showHeader: true,
      showIndex: false,
      pagination: true,
      headerHieght: '30px',
      headerBackGround: '#fff',
      headerTransparent: true,
      headerColor: '#666',
      headerFontSize: '16px',
      bodyHieght: '30px',
      bodyBackGround: '#fff',
      bodyTransparent: true,
      bodyColor: '#666',
      bodyFontSize: '16px',
      transparentPagination: false,
      useSort: false,
      userInnerBorder: false,
      borderColor: '',
      borderWidth: 0
    }
  },
  {
    name: '滚动表格列表',
    component: 'ScrollTableCom',
    type: 'container',
    icon: 'icon-liebiao',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showHeader: true,
      showIndex: false,
      loopScroll: true,
      rowNumber: 3,
      duration: 3,
      headerHieght: '30px',
      headerBackGround: '#fff',
      headerTransparent: true,
      headerColor: '#666',
      headerFontSize: '16px',
      bodyHieght: '30px',
      bodyBackGround: '#fff',
      bodyTransparent: true,
      bodyColor: '#666',
      bodyFontSize: '16px'
    },
    style: {
      height: 200
    }
  },
  {
    name: '全屏按钮',
    component: 'FullScreenCom',
    type: 'functionCom',
    icon: 'icondaping-quanping',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '32px',
    },
    style: {
      height: 32
    }
  },
  {
    name: '全景对比',
    component: 'PanoCom',
    type: 'functionCom',
    icon: 'icon-quanjing',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
    }
  },
  {
    name: '全景组件',
    component: 'Pano',
    type: 'container',
    icon: 'icon-quanjing',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
    }
  },
  {
    name: '视频组件',
    component: 'LiveCom',
    type: 'container',
    icon: 'icon-a-luzhishipin',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      src: '',
      videoType: 'Other',
      screen_sequence: 1,
      showControl: false
    },
    style: {
      mixBlendMode: 'normal'
    }
  },
  {
    name: '视频组件',
    component: 'CommonVideo',
    type: 'functionCom',
    icon: 'icon-a-luzhishipin',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showControl: false,
      PtzControlSize: 150
    },
    mapConfig: [
      {
        label: '平台信息',
        objectUUID: '',
        bindField: '',
        key: 'app_info'
      },
      {
        label: '设备名称',
        objectUUID: '',
        bindField: '',
        key: 'monitor_name'
      },
      {
        label: '设备号',
        objectUUID: '',
        bindField: '',
        key: 'device_serial'
      },
      {
        label: '封面',
        objectUUID: '',
        bindField: '',
        key: 'monitor_img'
      },
      {
        label: '监控路径',
        objectUUID: '',
        bindField: '',
        key: 'monitor_path'
      },
      {
        label: '通道id',
        objectUUID: '',
        bindField: '',
        key: 'channel_id'
      },
      {
        label: '云台状态',
        objectUUID: '',
        bindField: '',
        key: 'yun_tai_status'
      },
      {
        label: '在线状态',
        objectUUID: '',
        bindField: '',
        key: 'onLine'
      },
      {
        label: '平台token',
        objectUUID: '',
        bindField: '',
        from: 'app_info',
        key: 'accessToken'
      },
      {
        label: '标识',
        objectUUID: '',
        bindField: '',
        from: 'app_info',
        key: 'app_name'
      },
      {
        label: '应用id',
        objectUUID: '',
        bindField: '',
        from: 'app_info',
        key: 'appId'
      }
    ]
  },
  {
    name: '里程碑',
    component: 'MilestoneCom',
    type: 'functionCom',
    icon: 'icon-a-qizhilichengbei',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showTitle: true, // B标题
    }
  },
  {
    name: '天气',
    component: 'WeatherCom',
    type: 'functionCom',
    icon: 'icon-tianqi',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showWeather: true,
      showAirpm: true,
      weatherMargin: '30px',
      showIcon: true
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '新版天气',
    component: 'WeatherComNew',
    type: 'functionCom',
    icon: 'icon-tianqi',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showWeather: true,
      showAirpm: true,
      showHumidity: true,
      showWindSpeed: true,
      mainColor: '#fff',
      secondaryColor: '#5CF691'
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '天气-日',
    component: 'WeatherDay',
    type: 'functionCom',
    icon: 'icon-tianqi',
    statusConfig: {
      displayShow: true,
      isShow: true // 是否显示
    },
    style: {
      borderRadius: '4px',
      padding: '6px'
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '天气-周',
    component: 'WeatherWeek',
    type: 'functionCom',
    icon: 'icon-tianqi',
    statusConfig: {
      displayShow: true,
      isShow: true // 是否显示
    },
    style: {
      borderRadius: '4px',
      padding: '12px'
    },
    paramsConfig: [], // 筛选参数
  },
  {
    name: '问题状态组件',
    component: 'StatusCom',
    type: 'container',
    icon: 'iconyeqian',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showNum: true,
      title: '-',
      showTitle: true,
      fontSize: '16px',
      numColor: ''
    },
    style: {
      height: 150,
      width: 100
    },
  },
  {
    name: '人员打卡',
    component: 'PunchIn',
    type: 'functionCom',
    icon: 'icon-renyuan',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      showDateScreen: false
    },
    style: {
      height: 300,
      width: 400
    },
  },
  {
    name: '登录页',
    component: 'CommonLogin',
    type: 'functionCom',
    icon: 'icon-jinrudengru',
    statusConfig: {
      isShow: true, // 是否显示
      styleKey: 1
    },
    style: {
    }
  },
  {
    name: '新登录页',
    component: 'CommonLoginNew',
    type: 'functionCom',
    icon: 'icon-jinrudengru',
    statusConfig: {
      isShow: true, // 是否显示
      usePasswordLogin: true,
      useCodeLogin: false,
      useResetPassword: false,
      userQRCodelogin: false,
      tabsMargin: 8,
      tabsFontSize: 16,
      tabsFontWeight: 'normal',
      formItemMargin: 22,
      activeColor: '#409eff',
      btnColor: '#fff',
      inputStyle: 'border'
    },
    style: {
      height: 400
    }
  },
  {
    name: '考勤统计',
    component: 'PunchinStatistics',
    type: 'container',
    icon: 'icon-kaoqin',
    statusConfig: {
      isShow: true, // 是否显示
      displayShow: true,
      isDark: true,
      label: '在场监理'
    },
    style: {
    }
  },
	{
    name: '轴列表',
    component: 'AxisList',
    type: 'container',
    icon: 'iconzirenwu',
		fieldConfig:[],
		listConfig:{
			axisDirection:'x',
				itemPosition:'right',
				title:'轴列表',
				enTitle:'Axis List',
				itemWidth:200,
				itemHeight:100,
        isBaseBg:true,//手动背景色
        centerDot:false,//点居中
        isDashed:false,//虚线轴
				itemBg:'#fff',
        textAlign:'left',
        itemCol:'#000000',
        topFields:[],//置顶参数
		},
    statusConfig: {
      isShow: true, // 是否显示
      displayShow: true,
    },
    style: {
    }
  },
  {
    name: '轮播行',
    component: 'ScrollBlock',
    type: 'container',
    icon: 'icon-lunbo',
    statusConfig: {
      displayShow: true,
      isShow: true, // 是否显示
      loopScroll: true,
      rowNumber: 3,
      duration: 3,
      titleColor: '#666',
      titleFontSize: '16px',
      bodyBackGround: '#fff',
      bodyTransparent: true,
      bodyColor: '#666',
      backgroundImage: '',
      objectFit: 'cover'
    },
    style: {
      height: 200
    }
  },
]
// 初始化组件列表
const initComponentsList = (list) => {
  // 公共配置
  const publicConfig = {
    type: '',
    icon: 'iconyeqian',
    isLock: false, // 是否锁定
    needCommonStyle: true,
    propValue: '',
    span: -1,
    style: {
      height: 400,
      width: '',
      rotate: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    },
    modelConfig: {
      appId: '',
      appSecret: '',
      modelId: {
        type: '',
        value: ''
      }
    }
  }
  // 返回融合后的配置
  return list.map(el => {
    return Object.assign({}, publicConfig, el)
  })
}
const functionCom = {
  type: 'functionCom',
  title: '功能组件',
  show: true,
	order: 6,
  componentList: initComponentsList(functionComponentsList)
}
export default functionCom