import { render, staticRenderFns } from "./ActionMenu.vue?vue&type=template&id=3c5d55cf&scoped=true"
import script from "./ActionMenu.vue?vue&type=script&lang=js"
export * from "./ActionMenu.vue?vue&type=script&lang=js"
import style0 from "./ActionMenu.vue?vue&type=style&index=0&id=3c5d55cf&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5d55cf",
  null
  
)

export default component.exports