<!--
 * @Author: damarkday damarkday@163.com
 * @Date: 2022-06-08 09:27:16
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-04-04 09:38:47
 * @Description: 生产督察安全问题排列分析echarts图表
-->

<template>
	<div class="common-statistics-analyze">
		<div
			class="eject-icon"
			@click.stop="dialogVisible = !dialogVisible"
		>
			<i class="iconfont icondaping-quanping" />
		</div>
		<!-- 图表 -->
		<div class="select">
			<div class="filter-select">
				<!-- 请选择月份范围 -->
				<div
					class="picker-container"
					@mouseover="changeIconStatus(false)"
					@mouseout="changeIconStatus(true)"
				>
					<el-date-picker
						ref="date"
						prefix-icon="icon"
						align="left"
						v-model="month"
						type="monthrange"
						range-separator=""
						start-placeholder="请选中"
						end-placeholder="月份范围"
						@focus="focus"
						@blur="blur"
						popperClass="resetPopPosition"
						format=" yyyy-MM 至"
						:unlink-panels="true"
						@change="selectChanged"
						value-format="yyyy-MM-dd"
					>
					</el-date-picker>
					<i
						class="el-icon-arrow-up icon"
						ref="icon"
					></i>
				</div>

				<!-- 请选择规模大小 -->
				<el-select
					v-model="size"
					@change="selectChanged"
					v-if="!statusConfig.projectLevelFilterHidden"
					placeholder="请选择规模大小"
					clearable
				>
					<el-option
						v-for="item in filterSizeList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>

				<!-- 请选择时期范围 -->
				<el-select
					v-model="period"
					@change="selectChanged"
					placeholder="请选择时期范围"
					clearable
				>
					<el-option
						v-for="item in filterPeriodList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<ChartContainer
			id="common-statistics-analyze"
			v-if="option"
			:option="option"
			ref="chartInstance"
			class="chart-bar"
		>
		</ChartContainer>
		<el-dialog
			:visible.sync="dialogVisible"
			title=""
			:modal="false"
			:append-to-body="true"
			custom-class="chart-container-dialog"
			width="60%"
		>
			<div
				class="common-statistics-analyze"
				style="height: 600px"
			>
				<!-- 图表 -->
				<div class="select">
					<div class="filter-select">
						<!-- 请选择月份范围 -->
						<div
							class="picker-container"
							@mouseover="changeIconStatus(false)"
							@mouseout="changeIconStatus(true)"
						>
							<el-date-picker
								ref="date"
								prefix-icon="icon"
								align="left"
								v-model="month"
								type="monthrange"
								range-separator=""
								start-placeholder="请选中"
								end-placeholder="月份范围"
								@focus="focus"
								@blur="blur"
								popperClass="resetPopPosition"
								format=" yyyy-MM 至"
								:unlink-panels="true"
								@change="selectChanged"
								value-format="yyyy-MM-dd"
							>
							</el-date-picker>
							<i
								class="el-icon-arrow-up icon"
								ref="icon"
							></i>
						</div>

						<!-- 请选择规模大小 -->
						<el-select
							v-model="size"
							@change="selectChanged"
							v-if="!statusConfig.projectLevelFilterHidden"
							placeholder="请选择规模大小"
							clearable
						>
							<el-option
								v-for="item in filterSizeList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>

						<!-- 请选择时期范围 -->
						<el-select
							v-model="period"
							@change="selectChanged"
							placeholder="请选择时期范围"
							clearable
						>
							<el-option
								v-for="item in filterPeriodList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</div>
				</div>
				<ChartContainer
					id="common-statistics-analyze"
					v-if="option"
					:option="option"
					ref="chartInstance"
					class="chart-bar"
				>
				</ChartContainer>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import * as echarts from 'echarts';
import ChartContainer from '@/chart-components/components/common/ChartContainer';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { Dialog } from 'element-ui';
import databaseTriggerMixin from '@/custom-component/databaseTriggerMixin';

const filterSizeList = [
	{ label: '所有', value: 5 },
	{ label: '小型', value: 1 },
	{ label: '中型', value: 2 },
	{ label: '大型', value: 3 },
	{ label: '特大型', value: 4 }
];
const filterPeriodList = [
	{ label: '前期', value: 1 },
	{ label: '中期', value: 2 },
	{ label: '后期', value: 3 },
	{ label: '全期', value: 4 }
];

export default {
	name: 'CommonStatisticsAnalyze',
	mixins: [CustomComponentMixin, databaseTriggerMixin],
	components: {
		ChartContainer,
		'el-dialog': Dialog
	},
	data() {
		return {
			// 图表配置
			option: null,
			// 尺寸
			filterSizeList,
			// 时期
			filterPeriodList,
			month: null, // 所选择的月份
			size: 5, // 所选择的尺寸
			period: null, // 所选择的时期
			dialogVisible: false
		};
	},
	computed: {
		// 参数配置
		paramsConfig() {
			return this.element?.paramsConfig || [];
		},

		// 外部设置参数
		statusConfig() {
			return this.element?.statusConfig || [];
		}
	},
	mounted() {
		this.getEChartsDataHttps();
		this.$nextTick(() => {
			// 监听请求
			// 配置关联参数的容器才需要监听
			const databaseTrigger = {
				[this.$elementId]: data => {
					if (data.parentId && data.parentId !== this.element.id) return false;
					if (!this.paramsConfig) return;
					this.getEChartsDataHttps();
				}
			};
			eventBus.$on('databaseTrigger', databaseTrigger[this.$elementId]);
		});
	},
	watch: {
		'statusConfig.valueStatus': {
			handler() {
				this.getEChartsDataHttps();
			}
		}
	},
	methods: {
		/**
		 * @description:值的变化
		 * @param {*} val
		 */
		selectChanged() {
			if (!this.month) {
				this.$nextTick(() => {
					let el = this.$refs.icon;
					el.style.visibility = 'visible';
				});
			}
			this.getEChartsDataHttps();
		},
		/**
		 * @description:datePicker聚焦
		 */
		focus() {
			let el = this.$refs.icon;
			el.classList.add('icon-reverse');
		},
		/**
		 * @description:datePicker失焦
		 */
		blur() {
			let el = this.$refs.icon;
			el.classList.remove('icon-reverse');
		},
		/**
		 * @description:控制显示隐藏状态
		 */
		changeIconStatus(bool) {
			let val = this.month;
			this.$nextTick(() => {
				let el = this.$refs.icon;
				if (!val) return;
				el.style.visibility = bool ? 'visible' : 'hidden';
			});
		},

		/**
		 * 获取表格请求
		 */
		getEChartsDataHttps() {
			let canvasParams = this.packagingEchartsOptions(); // 组装图表参数
			let params = {
				object_uuid: 'object620de09897457',
				view_uuid: 'view622ae760d03d6',
				__method_name__: 'dataList',
				transcode: 0,
				project_id: [1],
				item_type: this.statusConfig.valueStatus
			};
			const { param, canPost, search } = this.initParams(this.paramsConfig);
			if (!canPost) return;
			params = {
				...params,
				search,
				...param
			};
			if (!params.project_id) {
				params.project_id = [1];
			} else {
				params.project_id = Array.isArray(params.project_id) ? params.project_id : [params.project_id];
			}
			let level = this.size;
			let stage = this.period;
			let month = this.month;
			console.log(`level is `, level);
			if (level) {
				let tmp = [level]; // project_level：项目等级（值：1（小型）、2（中型）、3（大型）、4（特大型））
				if (level === 5) {
					tmp = [1, 2, 3, 4];
				}
				params['project_level'] = tmp;
			}
			if (stage) {
				params['project_stage'] = stage; // project_stage：项目状态（值：1（前期、2（中期）、3（专项检查）、4（全期））
			}
			if (month) {
				params['start_time'] = `${month[0]} 00:00:00`;
				params['end_time'] = `${month[1]} 23:59:59`;
			}
			return dataInterface(params)
				.then(res => {
					if (res && res.status === 200) {
						let info = res.data;
						let typeArray = [];
						let scoreArray = [];
						let rateArray = [];
						if (Array.isArray(info.data)) {
							// 计算总量
							let totalScore = (function sum(arr) {
								var s = 0;
								arr.forEach(function (item) {
									s += item['total_score'];
								}, 0);

								return s;
							})(info.data);
							// 对象排序 从大到小排序
							info.data.sort(function (a, b) {
								return b.total_score - a.total_score;
							});

							let tmp = 0;

							// 计算折线图占比
							info.data.forEach(item => {
								if (item['type_name']) {
									typeArray.push(item['type_name']);
									scoreArray.push(item['total_score']);
									if (totalScore > 0) {
										tmp = item['total_score'] + tmp;
										let rate = ((tmp / totalScore) * 100).toFixed(1);
										rateArray.push(rate);
									} else {
										let rate = 0;
										rateArray.push(rate);
									}
								}
							});

							canvasParams.xAxis[0].data = typeArray; // x轴label
							canvasParams.series[0].data = scoreArray; // 问题次数
							canvasParams.series[1].data = rateArray; // 累计频率
							this.option = canvasParams;
						}
					}
				})
				.catch(err => {
					console.error(err);
				});
		},
		/**
		 * 组装echarts图表参数
		 */
		packagingEchartsOptions() {
			let canvasParams = {
				grid: {
					top: 30,
					left: 45,
					right: 45,
					bottom: 30
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999'
						}
					}
				},
				legend: {
					data: ['问题发生次数', '次数累计频率'],
					itemWidth: 15,
					itemHeight: 15,
					right: '10%',
					textStyle: {
						fontSize: 12,
						color: '#FFFFFF',
						fontWeight: 400,
						lineHeight: 15
					},
					itemStyle: {}
				},
				xAxis: [
					{
						type: 'category',
						data: [],
						axisPointer: {
							type: 'shadow'
						},
						axisLabel: {
							interval: 0,
							color: '#FFFFFF',
							fontWeight: 700,
							fontSize: 12,
							lineHeight: 15,
							hideOverlap: true
						},
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#959393'
							}
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						min: 0,
						max: value => {
							if (isNaN(value.max) || (value.max >= 0 && value.max <= 10)) {
								return 10;
							} else {
								return null;
							}
						},
						maxInterval: 10,
						minInterval: 1,
						splitNumber: 10,
						axisLabel: {
							formatter: '{value}',
							color: '#FFFFFF',
							fontWeight: 400,
							fontSize: 12,
							lineHeight: 15,
							hideOverlap: true
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#959393'
							}
						},
						axisTick: {
							show: false,
							inside: true
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: 'rgba(22, 43, 120, 1)',
								width: 2,
								type: 'dashed',
								dashOffset: 5,
								cap: 'butt'
							}
						}
					},
					{
						type: 'value',
						min: 0,
						max: 100,
						maxInterval: 10,
						minInterval: 1,
						splitNumber: 10,
						axisLabel: {
							formatter: '{value} %',
							color: '#FFFFFF',
							fontWeight: 400,
							fontSize: 12,
							lineHeight: 15
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#959393'
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: 'rgba(22, 43, 120, 1)',
								width: 2,
								type: 'dashed',
								dashOffset: 5,
								cap: 'butt'
							}
						}
					}
				],
				series: [
					{
						name: '问题发生次数',
						type: 'bar',
						tooltip: {
							valueFormatter: function (value) {
								return value;
							}
						},
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{ offset: 0, color: 'rgba(85, 207, 254, 1)' },
								{ offset: 0.99, color: 'rgba(85, 207, 254, 0.2)' }
							])
						},
						data: []
					},
					{
						name: '次数累计频率',
						type: 'line',
						yAxisIndex: 1,
						symbolSize: 12,
						tooltip: {
							valueFormatter: function (value) {
								return value;
							}
						},
						itemStyle: {
							color: {
								type: 'radial', // 径向渐变
								x: 0.5, // 圆心点x轴，0.5为柱状图宽度中心
								y: 0.5, // 圆心点y轴，0.5为柱状图高度中心
								r: 0.5, // 半径
								colorStops: [
									{
										offset: 0,
										color: 'rgba(188, 245, 120, 100%)' // 0%处的颜色为红色
									},
									{
										offset: 1,
										color: 'rgba(188, 245, 120, 60%)' // 100%处的颜色为蓝
									}
								]
							}
						},
						lineStyle: {
							width: 3
						},
						data: []
					}
				]
			};

			return canvasParams;
		}
	},
	beforeDestroy() {
		eventBus.$off('databaseTrigger');
	}
};
</script>
<style lang="less">
// icon旋转
.resetPopPosition {
	& > .popper__arrow {
		left: 109px !important;
	}
}
</style>

<style lang="less" scoped>
// 所旋转的图标
.common-statistics-analyze {
	width: 100%;
	height: 100%;
	position: relative;
	position: relative;
	&:hover {
		.eject-icon {
			visibility: visible;
		}
	}
	.eject-icon {
		visibility: hidden;
		position: absolute;
		top: 5px;
		right: 5px;
		width: 16px;
		height: 16px;
		z-index: 1000;
		color: #eee;
		&:hover {
			color: var(--el-color-primary);
		}
	}
	.icon {
		color: #fff;
		font-size: 14px;
		transform: rotateZ(180deg);
		top: 8px;
		position: relative;
		left: -6px;
		transition: transform 0.3s;

		&-reverse {
			transform: rotateZ(0);
		}
	}

	.select {
		position: absolute;
		top: -5px;
		width: 25%;
		z-index: 3000;
	}

	:deep(.filter-select .el-select .el-input) {
		width: auto;
		font-size: 10px;
	}

	.filter-select {
		display: flex;
		height: 15px;
		font-size: 10px;

		.picker-container {
			display: flex;
		}

		:deep(.el-select) {
			width: auto;

			.el-input {
				width: 140px;
				display: inline-block;
			}

			.el-input__inner {
				background: transparent;
				border: none;
				color: #fff;
				text-align: right;
			}

			i {
				color: #fff;
			}
		}

		& > div > :deep(.el-date-editor.el-range-editor) {
			background: transparent;
			border: none;

			& > input.el-range-input {
				background: transparent;
				color: #fff;
				font-size: 10px;

				&:nth-child(2) {
					width: auto;
					cursor: pointer;
					text-align: right;
				}

				&:nth-child(4) {
					width: 52px;
					cursor: pointer;
				}

				&:not(:nth-child(2)):not(:nth-child(4)) {
					width: 0px;
				}
			}

			& > span.el-range-separator {
				width: 0px;
				padding: 0px;
			}

			& > .el-input__icon:not(.el-range__close-icon) {
				width: 0px;
				height: 0px;
			}

			& > .el-range__close-icon {
				padding-top: 4px;
				position: absolute;
				color: #fff;
				right: -13px;
			}
		}

		.el-date-editor--monthrange.el-input,
		.el-date-editor--monthrange.el-input__inner {
			width: auto;
		}
	}
}
</style>
