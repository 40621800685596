/*
 * @Author: hw
 * @Date: 2024-04-16 17:14:45
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-26 14:05:43
 * @FilePath: \dataview-viewer-test\src\locale\lang\zh.js
 */

export default {
  "appEntry.Index.905788-0": "资源加载中，请稍后...",
  "appEntry.Index.905788-1": "暂无有效页面数据"
}


