<!--
    @name: SubFlowProp
    @description：SubFlowProp
    @author: ZengWei
    @date: 2022-08-27 11:27
-->
<template>
  <div>
    <el-form
      ref="form"
      :model="subFlow"
      :label-width="{ en: '150px' }[$i18n.locale] || '90px'"
      label-position="left"
      size="small">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="basic">
          <template #title>
            <span class="collapse-title">{{ $t('cell-panel.SubFlowProp.143764-0') }}</span>
          </template>
          <el-form-item :label="$t('cell-panel.SubFlowProp.143764-1')">
            <el-input v-model="subFlow.name" @change="onChange"></el-input>
          </el-form-item>
          <el-form-item :label="$t('cell-panel.SubFlowProp.143764-2')" label-width="220px">
            <el-switch class="switch-fr" v-model="subFlow.skipSubFlow" @change="onChange"></el-switch>
          </el-form-item>
          <el-form-item :label="$t('cell-panel.SubFlowProp.143764-3')">
            <el-select
              filterable
              style="width: 100%;"
              v-model="subFlow.subFlowId"
              @change="onFlowChange">
              <el-option
                v-for="item in subFlowList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <div class="sub-flow-graph" v-if="flowData && dialogVisible">
            <flow-designer
              :formUuid="flowData.module"
              :editable="false"
              :flowData="flowData"
              :formItem="formItem"
              :userData="userData">
            </flow-designer>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import {Collapse, CollapseItem} from "element-ui";

export default {
  name: 'SubFlowProp',
  components: {
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
    'flow-designer': ()=> import("../../../index")
  },
  props: {
    curCellProp: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formItem: {
      type: Array,
      default: () => [],
    },
    userData: {
      type: Array,
      default: () => [],
    },
    subFlowList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeNames: 'basic',
      subFlow: {
        name: this.$t('cell-panel.SubFlowProp.143764-4'),
        canArch: [],
        skipSubFlow: false,
        subFlowId: '',
      },
      loading: false,
      dialogVisible: false,
      flowData: null
    };
  },
  mounted() {
    this.initProp();
  },
  methods: {
    initProp() {
      this.subFlow = this.curCellProp;
      if(this.curCellProp.subFlowId){
        this.onFlowChange(this.curCellProp.subFlowId)
      }
    },
    onFlowChange(val){
      this.dialogVisible = false
      this.$nextTick(()=>{
        const data = this.subFlowList.find(item=>item.id === val)
        this.flowData = data;
        this.dialogVisible = true;
      })
      this.onChange()
    },
    onChange() {
      this.$emit('on-change', this.subFlow);
    },
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

.collapse-title {
  text-indent: 15px;
}

.sub-flow-graph {
  width: 100%;
  height: 450px;
}

:deep(.el-radio) {
  margin-top: 8px;
  margin-bottom: 5px;
}

:deep(.el-collapse-item__content) {
  padding-bottom: 0;
}

:deep(.el-collapse-item__header) {
  position: relative;

  &:before {
    position: absolute;
    top: 16px;
    width: 3px;
    height: 14px;
    margin-right: 10px;
    background-color: var(--themeColor)!important;
    content: "";
  }
}

.switch-fr{
  float: right;
  margin-top: 6px;
}
</style>
