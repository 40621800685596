<!--
 * @Author: wsp
 * @Date: 2021-10-22 14:29:14
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-12-26 15:06:13
 * @Description:
-->

<template>
	<div class="file-upload">
		<div
			v-if="Array.isArray(files) && files.length"
			class="upload-files-area"
		>
      <div class="file-item" v-for="(item, index) in files" :key="index">
        <div class="info">
          <img
            alt=""
            :src="fileType(item.filename)"
            style="width: 20px; vertical-align: middle"
          />
          <span
            class="file-name"
          >
            {{ item.filename }}
          </span>
        </div>
        <div class="action">
          <span
            class="operation-primary"
            @click="openFile(item)"
          >
            预览
          </span>
          <a
            class="operation-down"
            :download="item.name"
            target="_blank"
            :href="item.filepath"
          >
            下载
          </a>
        </div>
      </div>
		</div>
    <p v-else style="text-align: center">暂无数据</p>
		<el-dialog
			:visible.sync="dialogVisible"
			append-to-body
			title="查看大图"
		>
			<div style="width: 100%; height: 100%; display: flex; justify-content: center;">
				<el-image
					fit="fill"
					:src="dialogImageUrl"
				/>
			</div>
		</el-dialog>
		<div v-if="drawingShow" class="drawing-cont">
			<div  :id="'viewerDom'+viewerId" style="width: 100%; height: 100%" />
			<i @click="closeDrawing" class="iconfont iconc-close"></i>
		</div>
	</div>
</template>
<script>
import { getToken, openUrl } from '@/utils/tools';
import { baseUrl, axios } from '@/apis/http/request';
import { Dialog, Image } from 'element-ui';
let viewer
// 文件图标
const pdf = '//static.bimcc.com/images/disk/pdf.png';
const ppt = '//static.bimcc.com/images/disk/ppt.png';
const word = '//static.bimcc.com/images/disk/word.png';
const excel = '//static.bimcc.com/images/disk/excel.png';
const none = '//static.bimcc.com/images/disk/none.png';

export default {
	props: {
		// 文件
		files: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	components: {
		'el-dialog': Dialog,
		'el-image': Image,
	},
	// emits: ['extra-files'],
	data() {
		return {
			url: baseUrl,
			dialogVisible: false,
			dialogImageUrl: '',
			token: getToken(),
			imageType: ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd','webp'],
			viewerId:'',
			drawingShow:false,
		};
	},
	created() {
    // 如果没得 path 参数，那么使用filepath 作为path，可以一起存走
    if(this.files && this.files.length){
      this.files.forEach((item) => {
        if(item.filepath && (item.path == undefined || !item.path)){
          item.path = item.filepath
        }
      })
    }
    // 兼容结束
		// console.log(this.uploadURL, 'baseUrlbaseUrlbaseUrl');
	},
	methods: {
		/**
		 * @description 查看文件预览
		 * @param {Object} row
		 */
		openFile(row) {
			if (
				['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd','webp'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				// this.$store.commit('setImageUrls', { data: [row.path] })
				this.dialogImageUrl = row.filepath;
				this.dialogVisible = true;
			} else if (
				['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
				let url = 'https://weboffice.bimcc.net?furl='
				if (row.path.includes('https://')) {
					url = 'https://weboffice.bimcc.net?ssl=1&furl='
				}
				// window.open(
				// 	url + row.path
				// )
        openUrl(url + row.path,url + row.path)
			} else if (
				['mp4'].indexOf(
					row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
				) >= 0
			) {
        openUrl(row.path,row.path)
				// window.open(row.path);
			} else if(
				['dwg'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
			) {
				axios({
            method: 'post',
            url: 'https://models.bimcc.net/api/v3/auth/token',
            data: { appId: 'bdafce12a2d9465d89821ec14214a382', appSecret: '3EYcdAjbnWxP' }
          }).then((res) => {
            if (res.data?.data?.token) {
              axios({
                method: 'get',
                url: 'https://models.bimcc.net/api/v3/model/tranlate?file_id=' + row.model_file_id,
                headers: {
                  authorization: `Bearer ${res.data.data.token}`
                }
              }).then((re) => {
                if (re.data.data?.progress === 'Finish') {
                  let modelId = ''
                  if (re.data?.data?.data?.f2d && re.data.data.data.f2d.length !== 0) {
										this.viewerId = row.id
                    modelId = re.data.data.data.f2d[0].model_id
										this.drawingShow = true
										setTimeout(() => {
											const appId = 'bdafce12a2d9465d89821ec14214a382'
											const appSecret = '3EYcdAjbnWxP'
											viewer = new window.BIMCC.Viewer('viewerDom' + row.id, {})
											viewer.clearUI()
											viewer.init(modelId, { appId, appSecret, UIType: 'UI.Base' })
										}, 100)
                  }
                } else {
									this.$message.info('dwg文件转换中,请稍后再试！')
                }
              })
            } else {
              this.$message.info('请稍后再试！')
            }
          })
        
      }else{
				console.log(row,'row')
				// this.$Message.info('无法打开此格式的文件！')
			}
    },
		/**
		 * @description 关闭图纸预览
		 */
		closeDrawing(){
			this.drawingShow = false;
			viewer = null
		},
		/**
		 * @description 返回文件类型
		 * @param {String} url
		 */
		fileType(url) {
			if (url.indexOf('pdf') > -1) return pdf;
			else if (url.indexOf('ppt') > -1) return ppt;
			else if (url.indexOf('doc') > -1) return word;
			else if (url.indexOf('xls') > -1) return excel;
			else return none;
		}
	}
};
</script>

<style lang="less" scoped>
.file-upload {
	width: 100%;
	height: auto;
	.upload-files-area {
    .file-item{
      display: flex;
      align-items: center;
      height: 36px;
      .info{
        flex-grow: 1;
        width: 10px;
        display: flex;
        .icon{
          flex-shrink: 0;
        }
        .file-name {
          margin-left: 8px;
          flex-grow: 1;
          width: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
        }
      }
      .action{
        flex-shrink: 0;
        padding: 0 10px;
      }
    }
	}
	.drawing-cont{
		position:fixed;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background: #fff;
		z-index: 1000;
		i{
			position:absolute;
			top: 0px;
			right:5px;
			font-size: 25px;
		}
	}
}
</style>
