/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-05-17 11:49:20
 * @FilePath: \dataview-viewer-test\src\custom-component\dialog\lang\zh.js
 */

export default {
  "components.dialog.342359-1": "保存",
}


