<!--
    @name: widget-time
    @description：widget-time
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ element.value ? element.value : '-' }}</div>
    <el-time-picker
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :format="element.config.format"
      :value-format="element.config['value-format']"
      :placeholder="$getLocalizedLabel({'zhLabel': element.config.zhPlaceholder, 'enLabel': element?.config.enPlaceholder})"
      style="width: 100%"
    >
    </el-time-picker>
  </div>
</template>

<script>
import {TimePicker} from "element-ui"
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetTime",
  components: {
    'el-time-picker': TimePicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.time-details {
  width: 100%;
  height: 32px;
  padding: 0 12px;
  box-sizing: border-box;
  background: #F2F4F7;
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--systemFontFamily);
  font-weight: 400;
  color: #2A2F3D;
  cursor: default;
}
</style>
