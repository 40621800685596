<!--
    @name: widget-row
    @description：widget-row
    @author: ZengWei
    @date: 2022-03-25 09:40
-->
<template>
  <el-form
    ref="childForm"
    size="medium"
    :model="element"
    :class="{'form-parser':formData.tableStyle,'normal-parser': !formData.tableStyle}"
    :show-message="!formData.tableStyle"
    :label-width="formData.labelWidth + 'px'"
    :label-position="formData.labelPosition"
  >
    <div class="type-label" v-if="element.config.__config__.componentName">
      {{ $getLocalizedLabel({'zhLabel': element.config.__config__.componentName, 'enLabel': element.config.__config__.enLabel}) }}
    </div>
    <el-row
      :gutter="10"
      :type="element.config.type === 'flex' ? element.config.type : ''"
      :align="element.config.align ? element.config.align : 'middle'"
      :justify="element.config.justify ? element.config.justify : 'start'"
      style="position: relative"
    >
      <el-col
        v-for="(item, ii) in element.value"
        :key="item.config.__config__.formId"
        :span="item.config.__config__.span"
      >
        <el-form-item
          v-if="showHideField(item.config.__config__)"
          :prop="'value.' + ii + '.value'"
          :rules="item.validate"
          :label="showLabel(item)"
          :label-width="labelWidth(item)"
          class="no-padding"
        >
          <InputItem
            :element="item"
            :disabled="canEditField(item.config.__config__.formId)"
            @trigger-active="triggerActive(arguments,ii)"
          >
          </InputItem>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "WidgetRow",
  components: {},
  props: ['element', 'disabled', 'displayData', 'formData', 'editFields'],
  data() {
    return {}
  },
  computed: {
    labelWidth(){
      return function(element){
        if(element.config.__ques__){
          return '0px'
        }
        const width = element.config.__config__.labelWidth
          ? element.config.__config__.labelWidth + 'px'
          : 120 + 'px'
        return width
      }
    },
    showLabel() {
      return function (item) {
        const config = item.config.__config__
        if (item.config.__ques__ || config.showLabel === undefined || config.showLabel === false)
          return "";
        return this.$getLocalizedLabel({'zhLabel': config.label, 'enLabel': config?.enLabel});
      };
    },
    canEditField() {
      return function (formId) {
        const editFormId = Object.keys(this.editFields);
        if (editFormId.length > 0 && editFormId.includes(formId))
          return this.editFields[formId];
        return this.disabled;
      };
    },
    showHideField() {
      return function (config) {
        const formId = config.formId;
        const editFormId = Object.keys(this.editFields);
        if (editFormId.includes(formId)) return true;
        return config.addShow;
      };
    }
  },
  methods: {
    validateFormInput(){
      this.$refs.childForm.validate((valid)=>{
        if(!valid){
          throw new Error('数据验证失败');
        }
      })
    },
    triggerActive(params, index) {
      const compShowHide = params[0]; // 受控控件
      const ctrlType = params[1] ?? '' // 交互类型 - 默认显示隐藏
      const optionData = params[2] ?? ''// 级联数据
      if (ctrlType === 'cascader') {
        // this.$emit('trigger-active',compShowHide,ctrlType,optionData)
        const childFormItem = this.element.value
        if (childFormItem instanceof Array) {
          for (let eleItem of childFormItem[index]) {
            if (eleItem.config) { //关联子表展示数据无配置
              let childFormId = eleItem.config.__config__.formId;
              for (let val of compShowHide){
                if(val === childFormId && eleItem.type ==='position'){
                  // 修改部位级联参数值
                  setTimeout(()=>{
                    // 加载顺序时间问题导致部位不能回显
                    eleItem.config.__config__.cascade = parseInt(optionData)
                  },200)
                }
                if(val === childFormId){
                  eleItem.config.__slot__.options = optionData;
                }
              }
            }
          }
        }
      } else if (ctrlType === 'selectFill') {
        // test
      } else if(ctrlType === 'positionModel'){
        this.positionModel(optionData,index)
      } else {
        // 显示隐藏交互
        const ctrlFormId = Object.keys(compShowHide);
        const childFormItem = this.element.value;
        for (const item of childFormItem) {
          const formId = item.config.__config__.formId
          if(ctrlFormId.includes(formId)){
            item.config.__config__.addShow = compShowHide[formId]
          }
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
