/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-12 10:45:28
 * @FilePath: \dataview-viewer-test\src\views\userCenter\lang\zh.js
 */

export default {
  "http.axios.837231-0": "服务器内部错误",
  "http.axios.837231-1": "跳转登录1",
  "http.axios.837231-2": "未登录或登录失效，请登录！",
  "http.axios.837231-3": "数据校验失败！",
  "http.axios.837231-4": "跳转登录2",
  "http.axios.837231-5": "未登录或登录失效，请登录!",
  "http.axios.837231-6": "出错了，请联系管理员解决!"
}


