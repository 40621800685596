<!--
    @name: widget-money
    @description：widget-money
    @author: ZengWei
    @date: 2022-03-25 09:28
-->
<template>
  <div>
    <div v-if="disabled || element.config.__config__.openRule || element.config.disabled" class="input-show">
      <span v-if="element.config.__slot__.prepend">{{ element.config.__slot__.prepend }}</span>
      {{ fillMoney }}
      <span v-if="element.config.__slot__.append">{{ element.config.__slot__.append }}</span>
    </div>
    <template v-else>
      <el-input
        :style="element.config.style"
        v-if="!element.config.disabled"
        v-model="element.formatValue"
        :disabled="disabled || element.config.__config__.openRule || element.config.disabled"
        :placeholder="$getLocalizedLabel({'zhLabel': element.config.zhPlaceholder, 'enLabel': element?.config.enPlaceholder})"
        :min="element.config.min"
        :max="element.config.max"
        @change="formatPrecision"
      >
        <template slot="prepend" v-if="element.config.__slot__.prepend">
          {{ element.config.__slot__.prepend }}
        </template>
        <template slot="append" v-if="element.config.__slot__.append">
          {{ element.config.__slot__.append }}
        </template>
      </el-input>
    </template>
    <span style="margin-left: 8px">
      <b style="color: red;">RMB：</b>
      <el-tag size="small">{{ element.value ? moneyToCny(element.value) : '零' }}</el-tag>
    </span>
  </div>
</template>

<script>
import {Input,Tag} from "element-ui";
import {formatThousandsMoney, numToCny} from "@/custom-component/form/newParser/scripts/tools";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {toFixed} from "@/utils/tools";

export default {
  name: "WidgetMoney",
  components: {
    'el-input': Input,
    'el-tag': Tag
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    moneyToCny() {
      return function (money) {
        let unit = this.element.config.__slot__.append
        if (unit === '万' || unit === '万元') {
          money = parseFloat(money) * 10000
        }
        return numToCny(money)
      }
    },
    fillMoney() {
      this.formatPrecision(this.element.value)
      return this.element.formatValue
    },
  },
  methods: {
    formatPrecision(val) {
      this.$emit('change-calc')
      let value = Number(val.replaceAll(',', '')) // 格式化后中间插入问题
      if(value < this.element.config.min) {
        value = this.element.config.min
      } else if(value > this.element.config.max){
        value = this.element.config.max
      }
      if(this.element.config.__config__?.numberType) {
        let type = this.element.config.__config__.numberType;
        let prec = 1;
        let valuePos = value.toString().split('.')
        if(valuePos.length > 1) {
          for (let i = 0; i < valuePos[1].length; i++) {
            prec = prec * 10; // 小数精度处理
          }
        }
        value = Number(value)
        if(type === 1) value = Math.floor((value * prec).toPrecision(12)) / prec  // 向下取整
        if(type === 2) value = Math.ceil((value * prec).toPrecision(12)) / prec // 向上取整
        if(type === 3) value = Math.round((value * prec).toPrecision(12)) / prec // 四舍五入
      }
      if (this.element.config.precision) {
        let precision = this.element.config.precision;
        value = toFixed(value,precision); // toFixed 银行家算法 四舍五入存在问题
        if(value.indexOf('NaN') > -1){
          value = Number('0').toFixed(precision);
        }
      }
      this.element.value = value
      value = formatThousandsMoney(value);
      this.element.formatValue = value;
    },
  },
}
</script>

<style lang="less" scoped>
</style>
