/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-22 11:13:43
 * @FilePath: \dataview-viewer-test\src\custom-component\charts\lang\zh.js
 */

export default {
  "charts.CommonChart.550717-0": "配置错误"
}


