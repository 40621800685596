<!--
    @name: FlowRecover
    @description：FlowRecover
    @author: ZengWei
    @date: 2021-10-28 11:23
-->
<template>
  <div class="flow-recover">
    <div class="type-label">
      {{ $t('operation.FlowRecover.668174-0') }}
      <span style="float: right; cursor: pointer;user-select: none" @click="displayFlow">
        <i class="iconfont icona-jiedianliucheng" />
      </span>
    </div>
    <div v-if="flowShow" style="height: 300px;margin-bottom: 15px">
      <FlowDesigner
        v-if="recoverData"
        form-uuid="formUuidStr"
        :flow-data="{canvas_data: recoverData}">
      </FlowDesigner>
    </div>
    <div v-if="Array.isArray(flowLogs) && flowLogs.length" class="logs-warp">
      <!-- 分组 -->
      <div v-for="(item, index) in flowLogs" :key="index" class="log-group-item">
        <div class="log-item">
          <div class="node-header">
            <div class="node-icon">
              <svg height="22" width="24">
                <use xlink:href="#icondianziqianming" />
              </svg>
            </div>
            <span class="node-name">
              {{ flowNodeName(item?.node) }}
            </span>
            <span
              v-if="!['Start','End'].includes(item?.node?.nodeType) && ![6,7,9].includes(item?.node?.node_status)"
              class="node-status"
              :class="flowTypeClass(item)"
            >
              <span v-if="item?.node?.nodeType === 'SubFlow'">{{ $t('operation.FlowRecover.668174-4') }}</span>
              <span v-if="item?.node?.nodeType === 'Send'">{{ $t('operation.FlowRecover.668174-5') }}</span>
              <span v-else-if="item?.node?.userType === 9">{{ $t('operation.FlowRecover.668174-6') }}</span>
              <span v-else>
                {{ flowType(item?.node?.sign_type || item?.node?.signType) }} ：{{ flowNodeStatus(item) }}
              </span>
            </span>
            <span v-else-if="item?.node?.nodeType === 'Start'" class="node-status green-status">
              {{item.node?.is_approve ? $t('operation.FlowRecover.668174-7') : $t('operation.FlowRecover.668174-8')}}
              <span v-if="[6,7,9].includes(item?.node?.node_status)">：{{ flowNodeStatus(item) }}</span>
            </span>
            <span v-else-if="item?.node?.nodeType === 'End'" class="node-status green-status">{{ $t('operation.FlowRecover.668174-9') }}</span>
            <span v-if="item?.node?.userType === 9" class="node-status green-status">
              {{ $t('operation.FlowRecover.668174-10') }}
            </span>
          </div>
          <div class="log-item-info">
            <div v-for="(infoItem, ssindex) in item.flow_log.filter(t=>t?.log_type === 1)" :key="ssindex" class="log-item-info-item">
              <div v-if="item.flow_log.find(t=>t?.log_type === 5)" style="margin-bottom: 15px">
                <div class="info-content">
                  <div class="user-info">
                    <div class="user-avatar">
                      <el-avatar shape="square" :size="30" class="el-button--primary">
                        {{ item.flow_log.find(t=>t?.log_type === 5)?.user_name[0] }}
                      </el-avatar>
                    </div>
                    <span class="user-name">{{ item.flow_log.find(t=>t?.log_type === 5)?.user_name }}</span>
                  </div>
                  <div class="other-info">
                    <span class="time">
                      {{ getDate(item.flow_log.find(t=>t?.log_type === 5)?.created_at) }}
                    </span>
                    <span class="status">{{ $t('operation.FlowRecover.668174-11') }}</span>
                  </div>
                </div>
              </div>
              <div v-for="(userData, sssindex) in infoItem.user_wait" :key="sssindex" style="margin-bottom: 15px">
                <div class="info-content">
                  <div class="user-info">
                    <div class="user-avatar">
                      <el-avatar shape="square" :size="30" class="el-button--primary">
                        {{ userData.user_name[0] }}
                      </el-avatar>
                    </div>
                    <span class="user-name">{{ userData?.user_name }}</span>
                  </div>
                  <div class="other-info">
                    <span class="time">
                      {{ getDate(userData.updated_at) }}
                    </span>
                    <span class="status">
                      <span v-if="item?.node?.nodeType == 'Start'">
                        <span v-if="userData?.is_pass === 6">{{ $t('operation.FlowRecover.668174-12') }}</span>
                        <span v-else-if="userData?.is_pass === 7">{{ $t('operation.FlowRecover.668174-13') }}</span>
                        <span v-else>{{ userData?.is_deal === 1 ? $t('operation.FlowRecover.668174-14') : $t('operation.FlowRecover.668174-15') }}</span>
                      </span>
                      <span v-else-if="item?.node?.nodeType == 'End'">{{ $t('operation.FlowRecover.668174-9') }}</span>
                      <span v-else-if="!userData?.is_deal">{{ $t('operation.FlowRecover.668174-16') }}</span>
                      <span v-else-if="userData?.is_pass === 6">{{ $t('operation.FlowRecover.668174-12') }}</span>
                      <span v-else-if="userData?.is_pass === 7">{{ $t('operation.FlowRecover.668174-13') }}</span>
                      <span v-else>{{ userData.is_pass ? $t('operation.FlowRecover.668174-10') : $t('operation.FlowRecover.668174-17') }}</span>
                    </span>
                  </div>
                </div>
                <div v-if="userData.remarks || (Array.isArray(userData.appendix) && userData.appendix.length)" class="primary-info-warp">
                  <div v-if="userData.remarks" class="remarks-warp">
                    {{ userData.remarks }}
                  </div>
                  <FileUpload v-if="Array.isArray(userData.appendix) && userData.appendix.length" :disabled="true" :files="userData.appendix" />
                </div>
              </div>
            </div>
            <div v-for="(infoItem, ssindex) in item.flow_log.filter(t => t?.log_type === 2 && t?.user_wait?.length)" :key="ssindex" class="log-item-info-item">
              <div v-for="(userData, sssindex) in infoItem.user_wait" :key="sssindex">
                <div class="info-content">
                  <div class="user-info">
                    <div class="user-avatar">
                      <el-avatar shape="square" :size="30" class="el-button--primary">
                        {{ userData.user_name[0] }}
                      </el-avatar>
                    </div>
                    <span class="user-name">{{ userData?.user_name }}</span>
                  </div>
                  <div class="other-info">
                    <span class="time">
                      {{ getDate(userData.updated_at) }}
                    </span>
                    <span class="status">
                      <span v-if="userData?.is_read === 0">{{ $t('operation.FlowRecover.668174-18') }}</span>
                      <span v-else-if="userData?.is_read === 1">{{ $t('operation.FlowRecover.668174-19') }}</span>
                      <span v-else>
                        -
                      </span>
                    </span>
                  </div>
                </div>
                <div v-if="userData.remarks || (Array.isArray(userData.appendix) && userData.appendix.length)" class="primary-info-warp">
                  <div v-if="userData.remarks" class="remarks-warp">
                    {{ userData.remarks }}
                  </div>
                  <FileUpload v-if="Array.isArray(userData.appendix) && userData.appendix.length" :disabled="true" :files="userData.appendix" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/fileUpload/newFiles/index'
import FlowDesigner from "@/custom-component/flow/flow-designer";
import { Avatar } from 'element-ui'
import {deepCopy} from "@/utils/tools";

export default{
  name: 'FlowRecover',
  components: {
    FileUpload,
    FlowDesigner,
    'el-avatar': Avatar
  },
  props: {
    logs: {
      type: Array,
      default: () => {
        return []
      },
    },
    nextStep: {
      type: String,
      default: '',
    },
    currentStep: {
      type: Object,
      default: ()=>{},
    },
    canvasData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data(){
    return {
      flowShow: false,
      flowLogs: null,
      recoverData: null,
      stepId: []
    }
  },
  computed:{
    turnStatusText(){
      return (item) => {
        const data = item.flow_log.find(t=>t?.log_type === 5)
        return data.user_name + this.$t('operation.FlowRecover.668174-11')
      }
    },
    flowType() {
      return function(type) {
        switch(type) {
          case 1: {
            return this.$t('operation.FlowRecover.668174-20')
          }
          case 2: {
            return this.$t('operation.FlowRecover.668174-21')
          }
          default: {
            return ''
          }
        }
      }
    },
    flowTypeClass() {
      return function (info) {
        if(['Start','End','Send'].includes(info?.node?.nodeType)){
          return 'green-status'
        }
        if(info?.node?.nodeType === 'SubFlow') {
          return 'orange-status'
        }
        switch (info?.node?.node_status) {
          case 0:
          case 6:
          case 7: {
            return 'red-status'
          }
          case -1: {
            return 'orange-status'
          }
          case 1: {
            return 'green-status'
          }
        }
      }
    },
    flowNodeStatus() {
      return function (info) {
        switch (info?.node?.node_status) {
          case 0: {
            return this.$t('operation.FlowRecover.668174-17')
          }
          case 6: {
            return this.$t('operation.FlowRecover.668174-12')
          }
          case 7: {
            return this.$t('operation.FlowRecover.668174-13')
          }
          case -1: {
            return this.$t('operation.FlowRecover.668174-16')
          }
          case 1: {
            return this.$t('operation.FlowRecover.668174-10')
          }
        }
      }
    },
    flowNodeName() {
      return function (node) {
        this.getLocalizedData(node, 'name')
        switch (true) {
          case node?.node_status === 6: {
            return this.$t('operation.FlowRecover.668174-12')
          }
          case node?.node_status === 7: {
            return this.$t('operation.FlowRecover.668174-13')
          }
          case !!node?.t_name: {
            return node.t_name
          }
          case node?.nodeType === 'end' || node?.nodeType === 'End': {
            return this.$t('operation.FlowRecover.668174-9')
          }
          case node?.nodeType === 'start' || node?.nodeType === 'Start': {
            return this.$t('operation.FlowRecover.668174-22')
          }
          default: {
            return '-'
          }
        }
      }
    }
  },
  methods:{
    // 处理时间
    getDate(value) {
      if (!value) return '';
      let dateObj = null;
      if (value instanceof Date) {
        dateObj = value;
      } else if (isNaN(value)) {
        dateObj = new Date(value);
      } else {
        dateObj = new Date(value * 1000);
      }
      // @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
      if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
      const year = dateObj.getFullYear();
      // 无效的时间
      if (year === 1970) return '';
      let month = this.addZero(dateObj.getMonth() + 1);
      const day = this.addZero(dateObj.getDate());
      const hour = this.addZero(dateObj.getHours());
      const minute = this.addZero(dateObj.getMinutes());
      return this.$i18n.locale === 'en' ? `${year}-${month}-${day} ${hour}:${minute}` : `${year}年${month}月${day}日 ${hour}:${minute}`
    },
    /**
     * @desc: 补零
     * @param {Number} val 需要补零的值
     */
    addZero(val) {
      if (isNaN(val) || val < 0 || val >= 10) return val;
      return `0${val}`;
    },
    circleFind(edges, canvasData, doneStepId, curNodeId){
      let count = 0, first = 0
      let cycleCtrl = true;
      const key = doneStepId.findIndex(t=>t === curNodeId)
      while (cycleCtrl) {
        let preEdges = null
        if(key !== -1 && first === 1) {
          first = 0 // 第一次循环执行，当前步骤有多个来源连线时
          let lastStepId = doneStepId[key+1] // 获取当前步骤的上一步骤
          preEdges = edges.filter(t=>t.target.cell === curNodeId && t.source.cell === lastStepId)
        } else {
          // t.source.cell === t.target.cell 排除死循环
          preEdges = edges.filter(t=>t.target.cell === curNodeId && t.source.cell !== t.target.cell)
        }

        if(preEdges.length === 1){
          const preEdge = preEdges[0]
          // 边 节点
          const lastStep = canvasData.find(t=>t.id === preEdge.source.cell)
          if(lastStep){
            curNodeId = preEdge.source.cell
            this.stepId.push(preEdge.id)
            this.stepId.push(preEdge.source.cell)
            if(lastStep.shape === 'Start'){
              //  || (flag && lastStep.shape === 'Gateway')
              cycleCtrl = false
            }
          }
        } else if(preEdges.length > 1){
          const steps = Array.from(preEdges, t=>t.source.cell)
          const nodes = canvasData.filter(t=>steps.includes(t.id))
          const edgeIds = Array.from(preEdges, t=>t.id)
          for (let node of nodes) {
            const edgeNodes = edges.filter(t=>t.source.cell === node.id)
            if(edgeNodes.length > 1) {
              let i = 0, eId = Array.from(edgeNodes, t=>t.id);
              for (const edgeNode of edgeNodes) {
                if(doneStepId.includes(edgeNode.target.cell)){
                  this.stepId.push(edgeNode.id)
                  i++;
                  break;
                }
              }
              if(i === 0) {
                const gId = eId.find(t=>edgeIds.includes(t))
                this.stepId.push(gId)
              }
            } else {
              if(doneStepId.includes(edgeNodes[0].source.cell)){
                this.stepId.push(edgeNodes[0].id)
              }
            }
          }
          for (let edge of preEdges){
            curNodeId = edge.source.cell
            if(doneStepId.includes(curNodeId)){
              this.stepId.push(edge.source.cell)
              this.circleFind(edges, canvasData, doneStepId, curNodeId)
            }
          }
          cycleCtrl = false // !important 没有递归需要手动终止本次循环
        } else {
          cycleCtrl = false
        }
        if(count > 1000){
          cycleCtrl = false
        }
        count++
      }
    },
    handleView() {
      const canvasData = deepCopy(this.canvasData.cells)
      const edges = canvasData.filter(t=>t.shape === 'edge')
      let curNodeId = this.flowLogs[0]?.node?.nodeId // 子流程情况
      const doneStepId = [] // 已执行的步骤
      for (const log of this.logs) {
        doneStepId.push(log.node.nodeId)
      }
      this.circleFind(edges, canvasData, doneStepId, curNodeId)
      for (let item of canvasData){
        if(item.shape === 'edge' && this.stepId.includes(item.id)){
          item.attrs.line.stroke = '#40B67C'
        } else if(this.stepId.includes(item.id)) {
          item.attrs = {
            ...item.attrs,
            body: {
              stroke: '#40B67C',
              strokeWidth: 2,
            }
          }
        }
      }
      this.recoverData = {cells: canvasData}
    },
    displayFlow() {
      this.flowShow = !this.flowShow
    },
  },
  created () {
    this.flowLogs = this.logs;
    this.handleView();
  },
}
</script>

<style lang="less" scoped>
.flow-recover {
  .type-label {
    border-left: 3px solid var(--themeColor)!important;
    padding: 0 10px;
    line-height: 1;
    font-size: 14px;
    margin: 0 0 16px;
    font-weight: 600;
  }
  .topology {
    width: 100%;
    height: 400px;
  }
  .flow-logs {
    margin-bottom: 20px;
    .log-item {
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      border-bottom: 1px solid #F2F4F8;
      padding-bottom: 10px;
      padding-left: 14px;
      box-sizing: border-box;
      .log-item-left {
        width: 20px;
        height: 20px;
        margin: 0 10px;
      }
      .log-item-right {
        flex: 1;
        width: 100%;
        .log-item-name {
          display: flex;
          justify-content: space-between;
          .log-item-type {
            max-width: calc(100% - 80px);
            height: 22px;
            font-size: 14px;
            font-family: var(--systemFontFamily);
            font-weight: 550;
            color: #121619;
            line-height: 22px;
            display: flex;
            div:nth-child(1) {
              display: inline-block;
              width: auto;
              margin-right: 6px;
            }
            div:nth-child(2) {
              flex: 1;
              display: inline-block;
              height: 20px;
              background: #D9F5E0;
              border-radius: 10px;
              padding: 8px;
              font-size: 12px;
              font-family: var(--systemFontFamily);
              font-weight: 400;
              box-sizing: border-box;
              color: #24A148;
              line-height: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .right-date {
            width: auto;
            height: 20px;
            font-size: 12px;
            font-family: var(--systemFontFamily);
            font-weight: 400;
            color: #8A8F99;
            line-height: 20px;
          }
        }
        .log-item-remaks {
          font-size: 12px;
          font-family: var(--systemFontFamily);
          font-weight: 400;
          color: #121619;
          line-height: 20px;
        }
      }
    }
  }

  .node-turn-status {
    text-align: center;
    margin: 10px 15px 0 25px;
    padding: 5px 8px;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
    background: #a8e7d7;
    color: #656464;
  }

  .logs-warp{
    .log-group-item {
      .log-item{
        .node-header{
          width: 100%;
          height: 22px;
          display: flex;
          .node-icon{
            display: flex;
            align-items: center;
          }
          .node-name{
            width: 100px;
            margin-left: 12px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.5px;
            color: #161C1F;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
          }
          .node-status{
            height: 20px;
            line-height: 20px;
            margin-left: 16px;
            padding: 0px 8px;
            box-sizing: border-box;
            border-radius: 10px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0.4px;
          }
          .orange-status{
            background: #FFEEE2;
            color: #F16607;
          }
          .green-status{
            background: #E0F6E8;
            color: #00A650;
          }
          .red-status{
            background: #FFECEC;
            color: #FF525A;
          }
        }
        .log-item-info{
          border-left: 1px dashed #D0D5DA;
          margin-left: 8px;
          width: calc(100% - 8px);
          padding: 12px;
          box-sizing: border-box;
          .log-item-info-item{
            width: 100%;
            padding: 6px;
            box-sizing: border-box;
            .info-content{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .user-info{
                display: flex;
                align-items: center;
                .user-avatar{
                  flex-shrink: 0;
                }
                .user-name{
                  margin-left: 12px;
                  font-family: 'PingFang SC';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: 0.5px;
                  color: #161C1F;
                }
              }
              .other-info{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: right;
                letter-spacing: 0.4px;
                color: #71787F;
                .time{
                  color: #71787F;
                }
                .status{
                  margin-left: 12px;
                }
              }
            }
            .primary-info-warp{
              margin-left: 16px;
              border-left: 1px solid #E7F0FE;
              width: calc(100% - 16px);
              padding: 5px 0 5px 20px;
              box-sizing: border-box;
              .remarks-warp{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: justify;
                letter-spacing: 0.5px;
                color: #41474C;
              }
            }
          }
        }
        &:last-child{
          .log-item-info{
            border: none;
          }
        }
      }
    }
  }
}
</style>
