/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-20 15:19:41
 * @FilePath: \dataview-viewer-test\src\views\appEntry\lang\zh.js
 */

export default {
  "components.Cooperation.059629-0": "未指定",
  "components.Cooperation.059629-1": "建设单位",
  "components.Cooperation.059629-2": "勘察设计单位",
  "components.Cooperation.059629-3": "施工总承包部",
  "components.Cooperation.059629-4": "全咨/监理单位",
  "components.Cooperation.059629-5": "BIM咨询单位",
  "components.NavHistory.022115-0": "关闭其他",
  "components.NavHistory.022115-1": "关闭左侧",
  "components.NavHistory.022115-2": "关闭右侧",
  "components.NavHistory.022115-3": "关闭所有"
}


