<!--
    @name: index
    @description：index
    @author: ZengWei
    @date: 2022-05-13 09:19
-->
<template>
  <div class="task-flow-container" v-loading="loading">
    <div class="main-container">
      <div class="left-sidebar" >
        <task-sidebar
          v-if="taskEngineId > 0"
          ref="sidebar"
          :key="renderKey"
          :taskMode="taskMode"
          @insert-cell="insertCellTemplate">
        </task-sidebar>
      </div>
      <div class="right-container">
        <div v-if="taskMode === 'designer'" class="contextmenu" :style="contextmenuXY">
          <div class="menu-item" @click="toTop">置顶</div>
          <div class="menu-item" @click="toBom">置底</div>
          <div class="menu-item" @click="toPool">入库</div>
        </div>
        <div class="action-bar">
          <el-button-group v-if="taskEngineId > 0">
            <el-button plain size="small" @click="fitViewer">居中</el-button>
<!--            <el-button plain size="small" @click="changeMode">{{taskMode === 'designer' ? '预览' : '编辑'}}</el-button>-->
            <el-button plain size="small" @click="saveTaskFlow">全局配置</el-button>
            <el-button plain size="small" @click="submitTaskFlow">保存</el-button>
          </el-button-group>
        </div>
        <div class="graph-container" v-if="taskMode === 'designer'">
          <div
            v-for="item in nodeList"
            :key="item.text"
            :class="{'node-item': true, 'end-bottom': item.type === 'swim'}"
            @mousedown="drag($event,item)"
          >
            <div class="node-item-icon" :class="item.class"></div>
            <!--        <span class="node-label">{{ item.text }}</span>-->
          </div>
        </div>
        <div ref="x6Container" class="task-block"></div>
        <div v-show="nodeGraph" ref="x6ContainerCopy" class="node-graph"></div>
      </div>
    </div>
    <task-props
      :key="key"
      v-if="propOpen && nodeType === 'task'"
      :disabled="taskMode === 'designer' ? false : true"
      :current-item="currentItem"
      :currentNodeId="currentNodeId"
      :task-recover-id="taskRecoverId"
      :node-type="nodeType"
      :form-node="formNode"
      :element="element"
      :activeTab="activeTab"
      :task-mode="taskMode"
      @on-close="close"
      @on-change="updateProps"
    ></task-props>
    <edge-props
      v-if="propOpen && (nodeType === 'edge' || nodeType === 'gateway')"
      :current-item="currentItem"
      :currentNodeId="currentNodeId"
      :node-type="nodeType"
      :form-node="formNode"
      @on-close="close"
      @on-change="updateProps">
    </edge-props>
    <swim-props
      v-if="propOpen && nodeType === 'swim'"
      :current-item="currentItem"
      :currentNodeId="currentNodeId"
      @on-close="close"
      @on-change="updateProps">
    </swim-props>
    <div class="task-config-wrapper" v-if="configOpen">
      <p class="conf-title">
        全局配置
        <i class="iconfont iconc-close" @click="closeConfig"></i>
      </p>
      <div class="content">
        <task-config
          ref="taskConfig"
          :config-prop="configData"
          :disabled="taskMode === 'recover' ? true : false"
          @on-change="configChange"
        ></task-config>
      </div>
      <div class="footer" v-if="taskMode === 'recover' ? false : true">
        <el-button class="btn cancel" @click="configOpen = false">取消</el-button>
        <el-button class="btn determine" type="primary" @click="submitTaskFlow">保存</el-button>
      </div>
    </div>
    <el-dialog
      :title="modalTitle"
      :visible.sync="dialogVisible"
      width="60%"
      custom-class="common-dialog"
      append-to-body
      top="5vh"
      :close-on-click-modal="false"
    >
      <div v-if="skeleton" class="skeleton">
        <skeleton></skeleton>
      </div>
      <div class="dialog-main" @click="closePicker">
        <template v-if="parserMode === 'form'">
          <PcParserNew
            v-if="dialogVisible && formDesignData"
            ref="dialogForm"
            :form-data="formDesignData"
            :displayData="displayData"
            :editFields="editFields"
            :disabled="formDisabled"
            :needFlow="needFlow"
            :moduleUuid="moduleUuid"
            :formMode="formMode"
            :fillData="fillData"
            :otherParam="otherParam"
            @submit="onFormSubmit"
            @on-close="onEndFlow"
            @changeSkeleton="changeSkeleton"
          ></PcParserNew>
        </template>
        <template v-else>
          <PageParser
            style="padding: 15px"
            v-if="pageUUID"
            :pageUUID="pageUUID">
          </PageParser>
        </template>
      </div>
      <div slot="footer" v-if="formMode === 'add' || (formMode === 'detail' && checkTargetUser)">
        <div class="task-publish">
          <div>
            <el-button @click="dialogVisible = false" style="margin-right: 10px;">取消</el-button>
            <el-button type="primary" @click="triggerSubmit">{{ formMode === 'add' ?  '提交' : '编辑保存'}}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-image ref="previewImg" class="no-show" :src="imgPath" :preview-src-list="srcList"></el-image>
  </div>
</template>

<script>
import TaskGraph from "@/custom-component/task-flow/components/TaskInitializer";
import TaskSidebar from "@/custom-component/task-flow/property/TaskSidebar.vue";
import {ButtonGroup, Button, Image } from "element-ui";
import TaskProps from "@/custom-component/task-flow/property/TaskProps.vue";
import TaskConfig from "@/custom-component/task-flow/property/TaskConfig.vue";
import {dataInterface, transFieldsUUid} from "@/apis/data";
import {preTaskNode} from "@/plugins/util";
import SwimProps from "@/custom-component/task-flow/property/SwimProps.vue";
import EdgeProps from "@/custom-component/task-flow/property/EdgeProps.vue";
import {formDesign} from "@/apis/data/workflow";
import eventBus from "@/plugins/eventBus";
import Skeleton from "@/custom-component/form/newParser/skeleton.vue";
import {initParams, judgingArchiAPermission, openUrl} from "@/utils/tools";
import {Graph} from "@antv/x6";
import {uniqid} from "@/utils/tools";

export default {
  name: "index",
  components: {
    Skeleton,
    'el-button-group':ButtonGroup,
    'el-button':Button,
    'el-image':Image,
    EdgeProps,
    SwimProps,
    TaskProps,
    TaskConfig,
    TaskSidebar,
    PcParserNew: () => import("@/custom-component/form/newParser/PcParserNew.vue"),
    PageParser: () => import('@/components/parser/Index')
  },
  props: {
    element: {
      type: Object,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    componentList: {
      default: null
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    },
    taskId:{
      type: [Number,String],
      default: 0
    },
  },
  data() {
    return {
      imgPath: '',
      srcList: [],
      activeTab: 'info',
      renderKey: '',
      loading: true,
      skeleton: true,
      graph: null,
      dnd: null,
      canvasData: null,
      taskEngineId: this.taskId,
      propOpen: false,
      configOpen: false,
      configData: {
        name: '',
        desc: '',
        status_config: [],
        interval_open: 0,
        interval:{}
      },
      globalStatus: [
        {
          identifying: '1',
          status: '未开始',
          color: 'rgba(191, 199, 216, 1)',
          index: 0,
          fieldValue: '0',
          isEdit: false
        },
        {
          identifying: '2',
          status: '待处理',
          color: 'rgba(87, 155, 252, 1)',
          index: 1,
          fieldValue: '1',
          isEdit: false
        },
        {
          identifying: '3',
          status: '进行中',
          color: 'rgba(85, 89, 223, 1)',
          index: 2,
          fieldValue: '2',
          isEdit: false
        },
        {
          identifying: '4',
          status: '预警中',
          color: 'rgba(253, 171, 61, 1)',
          index: 3,
          fieldValue: '3',
          isEdit: false
        },
        {
          identifying: '5',
          status: '已逾期',
          color: 'rgba(226, 68, 92, 1)',
          index: 4,
          fieldValue: '4',
          isEdit: false
        },
        {
          identifying: '6',
          status: '已完成',
          color: 'rgba(0, 200, 117, 1)',
          index: 5,
          fieldValue: '5',
          isEdit: false
        }
      ],
      nodeList:[
        {text: '开始', type: 'start', class: 'node-start',},
        {text: '任务', type: 'task', class: 'node-task',},
        {text: '网关', type: 'gateway', class: 'node-gateway',},
        {text: '结束', type: 'end', class: 'node-end'},
        {text: '泳道', type: 'swim', class: 'node-swim'},
        {text: '虚线框', type: 'dashed', class: 'node-dashed'},
        {text: '文本', type: 'text', class: 'node-text'},
        {text: '矩形', type: 'rect', class: 'node-rect'},
        {text: '直线', type: 'line', class: 'node-line'},
      ],
      selectedNode: null,
      currentItem: null,
      currentNodeId: '',
      nodeType: null,
      formNode: [],
      editFieldsValue:{},

      objectDataId: 0,
      objectDataTable: '',
      taskRecoverId: 0,
      taskGraphData: null,
      taskNodeData: {},
      taskTodoId: 0,
      completeTodo:[],
      taskTodo:[],
      stepNode:[],
      stepNodeComp:[],
      taskMode: 'designer',
      nodeGraph: false,
      curStepNode: '',
      curCell: null,

      modalTitle: '',
      dialogVisible: false,
      formDesignData: null,
      formDisabled: false,
      displayData: {},
      editFields: {},
      needFlow: false,
      moduleUuid: '',
      formMode: 'add',
      fillData: {},
      otherParam: {},
      parserMode: 'form',
      // 编辑器页面渲染
      pageUUID: null,
      contextmenuXY: {
        top: '0px',
        left: '0px',
        display: ''
      },
      outerParam: null,
      key: 'propRefresh',
      dataId: 0  // 表单数据详情回显 -- 有多条只展示第一条数据
    }
  },
  computed: {
    checkTargetUser () {
      const targetUser = this.taskNodeData?.target_users || []
      const userInfo = this.$GetUserInfo()
      const permission = judgingArchiAPermission([], ['QuestOperation.all'])
      const index = targetUser.findIndex(ele => ele.user_id === userInfo?.id)
      const bool = (index !== -1) ? true : false
      return bool || permission
    },
  },
  created() {
    this.taskMode = this.element?.taskConfig?.customConfig?.taskMode || 'designer'
    eventBus.$on('open-task-form',(data)=>{
      this.openFormParser(data)
    })
    eventBus.$on('open-task-attach',(data)=>{
      this.openTaskAttach(data)
    })
    eventBus.$on('editor-prop-change',(taskConfig)=>{
      this.taskMode = taskConfig?.customConfig?.taskMode || 'designer'
      this.initCanvas()
    })
    eventBus.$on('pos-task-attach',(tab,node)=>{
      this.configOpen = false
      this.propOpen = true
      this.currentItem = node.getData()
      this.currentNodeId = node.id
      this.nodeType = node.shape
      if(this.taskMode === 'recover') {
        this.key = uniqid()
        this.activeTab = tab
      }
    })
    eventBus.$on('prop-change',(node)=>{
      this.selectedNode = node
      this.currentItem = node.getData()
      const step_node = {nodeId: node.id, ...this.currentItem}
      if(this.taskMode === 'recover') {
        const graphData = this.graph.toJSON()
        const {node_data} = this.formatData(graphData.cells)
        const data = {
          task_graph_id: this.taskRecoverId,
          json: graphData,
          node_data: node_data,
          step_node: step_node
        }
        dataInterface(data,'/api/taskengine/cellchange').then(res=>{
          if(res.data.code === 200) {
            this.initCanvas()
          }
        })
      }
      this.key = uniqid()
    })
  },
  mounted() {
    this.initCanvas()
    if(this.element?.id){
      const databaseTrigger = {
        [this.element.id]: () => {
          this.initCanvas(); // 外部参数驱动 - 树形点击事件处理
        },
      };
      eventBus.$on("databaseTrigger", databaseTrigger[this.element.id]);
    }
  },
  methods: {
    initCanvas(){
      this.loading = true
      this.configOpen = false
      this.propOpen = false
      this.canvasData = null
      const {param = {}} = initParams(
        this.element?.operationConfig?.params || [],
        this.isGroup,
        this.componentList || this.componentData,
        this.groupComponents
      );
      this.outerParam = param; // 外部参数
      if (this.outerParam?.data_id) {
        this.taskEngineId = this.outerParam?.data_id
      } else {
        this.taskEngineId = null
      }
      if (this.outerParam?.object_data_id) {
        this.objectDataId = this.outerParam?.object_data_id
      }
      if (this.outerParam?.data_table) {
        this.objectDataTable = this.outerParam?.data_table
      }
      if (this.outerParam?.task_recover_id) {
        this.taskRecoverId = this.outerParam?.task_recover_id
      }
      if (this.outerParam?.task_graph_data) {
        this.taskGraphData = this.outerParam?.task_graph_data
      }
      this.$nextTick(()=>{
        if(this.graph){
          // 属性改变，多个画布问题，初始化销毁画布
          this.graph.dispose()
        }
        if(this.$refs.x6Container){
          const graphInitializer = new TaskGraph(this)
          this.graph = graphInitializer.graph
          if(this.taskGraphData) {
            this.graph.fromJSON(this.taskGraphData.cells)
            this.loading = false
          }
          if(this.taskEngineId){
            this.getTaskEngine()
          } else {
            this.loading = false
          }
        }
      })
    },
    openTaskAttach(data){
      if(['png','jpg','jpeg','gif'].includes(data.fileext)){
        this.imgPath = data.url
        this.srcList = [data.url]
        this.$refs.previewImg.clickHandler();
      } else if (['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].includes(data.fileext)){
        let url = 'https://weboffice.bimcc.net?furl='
        if (data.path.includes('https://')) {
          url = 'https://weboffice.bimcc.net?ssl=1&furl='
        }
        // window.open(url + data.path)
        openUrl(url + data.path,url + data.path)
      } else if (data.fileext === 'mp4'){
        // window.open(data.path)
        openUrl(data.path,data.path)
      }
    },
    changeSkeleton(value){
      this.skeleton = value;
    },
    open(){
      this.configOpen = true
    },
    close(){
      this.propOpen = false
    },
    closeConfig(){
      this.configOpen = false
    },
    configChange(data){
      const {name,desc,status_config,interval_open,interval} = data
      this.configData = {name,desc,status_config,interval_open,interval}
    },
    getTaskEngine(){
      const param = {
        __method_name__: 'dataInfo',
        object_uuid: 'object628619c716579',
        data_id: this.taskEngineId,
        transcode: 0,
      }
      dataInterface(param).then(res=>{
        if(res.data.code === 200){
          let {name,desc,status_config,interval_open,interval,json} = res.data.data
          if(!status_config) status_config = JSON.parse(JSON.stringify(this.globalStatus))
          this.configData = {name,desc,status_config,interval_open,interval}
          this.$store.commit('setStatusList', status_config)
          this.$store.commit('setGraphData', json)
          if(json === null || json === '') {
            this.graph.clearCells() // 清空数据
            this.loading = false
          }
          if(this.taskMode === 'designer' && json?.cells){
            this.canvasData = json;
            this.graph.fromJSON(json)
            this.graph.centerContent()
            this.loading = false
          } else if(this.taskEngineId && this.objectDataId && this.objectDataTable){
            this.getTaskDetail()
          }
        }
      })
    },
    getTaskDetail(){
      const param = {
        task_engine_id: this.taskEngineId,
        object_data_id: this.objectDataId,
        data_table: this.objectDataTable
      }
      dataInterface(param,'api/taskengine/tasktodo','GET').then(res=>{
        if(res.data.code === 200){
          if(res.data.metadata?.task_recover_id){
            this.taskRecoverId = res.data.metadata.task_recover_id
          }
          if(res.data.metadata?.task_recover_json) {
            const canvasData = res.data.metadata.task_recover_json
            const statusConfig = res.data.metadata.status_config
            if(statusConfig) this.$store.commit('setStatusList', statusConfig)
            this.$store.commit('setGraphData', canvasData)
            this.renderKey = uniqid()
            this.canvasData = canvasData
          }
          const data = res.data.data;
          this.taskTodo = data.filter(item => item.status === 0)
          this.completeTodo = data.filter(item => item.status === 1)
          const taskToDoNodeId = Array.from(this.taskTodo,item => item.step_node)
          const completeNodeId = Array.from(this.completeTodo,item => item.step_node)
          this.stepNode = taskToDoNodeId
          this.stepNodeComp = completeNodeId
          this.recoverTaskTodo()
        }
      })
    },
    recoverTaskTodo(){
      const start = this.canvasData.cells.find(item => item.shape === 'start')
      this.stepNodeComp.unshift(start.id)
      for (let item of this.canvasData.cells){
        if(item.shape === 'swim') delete item.component
        if(item.shape === 'task') {
          // 进行中
          const statusList = item.data.statusList
          const statusType = item.data.statusType
          const statusConfigType = item.data.statusConfigType
          if(statusType === 'auto' && statusConfigType === 'custom') {
            // 自动判定 - 自定义
            let curStatus = statusList.find(t=>t.fieldValue == item.data.status)
            item.data.color = curStatus?.color || '#BFC7D8'
            item.data.status = curStatus?.index
          } else  { // if(statusType === 'auto' && statusConfigType === 'default')
            // 自动判定 - 默认
            if(this.stepNodeComp.includes(item.id)) {
              let curStatus = statusList.find(t=>['完成','已完成'].includes(t.status))
              item.data.color = curStatus?.color || '#00C875'
              item.data.status = curStatus?.index
            } else if(this.stepNode.includes(item.id)) {
              let curStatus = statusList.find(t=>['处理中','待处理','待开始'].includes(t.status))
              item.data.color = curStatus?.color || '#579BFC'
              item.data.status = curStatus?.index
            }
          }
          if(item.data.isPreWarn) {
            let preWarnField = item.data.preWarnField
            let goalWarn = item.data.baseInfo.find(t=>t.field == preWarnField)
            let preDay = item.data?.dayCount || 0
            let currentDate = new Date()
            let deadline = new Date(goalWarn.value)
            let warnData = new Date((deadline/1000 - preDay * 86400)*1000)
            if(
              !this.stepNodeComp.includes(item.id) &&
              currentDate > deadline && item.shape === 'task'
            ){
              // 已逾期
              let curStatus = statusList.find(t=>t.status === '已逾期')
              item.data.color = curStatus?.color || '#E2445C'
              item.data.status = curStatus?.index || '已逾期'
            } else if(currentDate > warnData) {
              // 预警中
              let curStatus = statusList.find(t=>t.status === '预警中')
              item.data.color = curStatus?.color || '#FDAB3D'
              item.data.status = curStatus?.index || '预警中'
            }
          }
        } else if(item.shape === 'edge') {
          if(
            this.stepNodeComp.includes(item.source.cell) ||
            this.stepNodeComp.includes(item.target.cell) ||
            this.stepNode.includes(item.target.cell)
          ) {
            item.attrs.line.stroke = '#00C875'
          }
        } else if(item.shape === 'start') {
          const attrs =  {
            body: {strokeWidth: 2, stroke: '#00C875', fill: '#FFF'},
            label: {text: '开始', fontSize: 16, fill: '#7E8595'},
          }
          item.attrs = attrs
        }
      }
      this.graph.fromJSON(this.canvasData.cells)
      // this.graph.zoomToFit({padding: 20})
      this.graph.centerContent()
      this.loading = false
    },
    validTaskEngine(graphData){
      let startNode = false,endNode = false
      let source = [],target = []

      for (let cell of graphData){
        if(cell.shape === 'start') startNode = true
        if(cell.shape === 'end') endNode = true
        if(cell.shape === 'edge'){
          source = source.concat(cell.source.cell)
          target = source.concat(cell.target.cell)
        }
      }
      return {startNode,endNode,source,target}
    },
    changeMode(){
      this.activeTab = 'info'
      this.taskMode = this.taskMode === 'designer' ? 'recover' : 'designer'
    },
    fitViewer(){
      this.graph.zoomToFit({padding: 20})
      this.graph.centerContent()
    },
    toTop(){
      this.curCell.toFront()
      this.contextmenuXY.display = 'none'
    },
    toBom(){
      this.curCell.toBack()
      this.contextmenuXY.display = 'none'
    },
    toPool(){
      this.contextmenuXY.display = 'none'
      const _this = this
      this.nodeGraph = true
      const loadingIns = this.$loading()
      const cellData = this.curCell.toJSON()
      delete cellData.component
      const newCellData = JSON.parse(JSON.stringify(cellData))
      const cellGraph = new Graph({container: this.$refs.x6ContainerCopy})
      cellData.shape = 'task-png'
      cellGraph.fromJSON({cells: [cellData]})
      // const cellTempleteId = cellData.data?.cell_template_id
      setTimeout(()=>{
        cellGraph.toPNG((dataUri) => {
          const data = {
            __method_name__: 'createData',
            object_uuid:'object64e08189ea543',
            view_uuid:'view64e082a427716',
            transcode: 0,
            cell_id: cellData.id,
            cell_name: cellData.data.name,
            graph_name: _this.configData.name,
            task_graph_id: _this.taskEngineId,
            cell_img: dataUri,
            cell_data: newCellData
          }
          // if(cellTempleteId) {
          //   // 存在块模板id，则更新
          //   data.__method_name__ = 'updateData';
          //   data.data_id = cellTempleteId;
          // }
          dataInterface(data).then(res=>{
            if(res.data.code === 200) {
              _this.$message.success('保存任务块模板成功')
              _this.$refs.sidebar.refresh()
              _this.nodeGraph = false
              this.curCell.setData({
                cell_template_id: res.data.data.id
              })
              loadingIns.close()
            }
          })
        },{
          padding: {
            top: 50,
            right: 30,
            bottom: 50,
            left: 30,
          },
        })
      },100)
    },
    saveTaskFlow(){
      this.propOpen = false
      this.configOpen = true
    },
    submitTaskFlow() {
      if(this.graph){
        const graphData = this.graph.toJSON()
        const nodeData = this.formatData(graphData.cells)
        if(this.taskMode === 'designer'){
          for (let node of nodeData.node_data.nodeList){
            if(node.user_type === 1 && node.nodeType === 'task' && node.target_users.length === 0){
              this.$message.error('任务：[ '+node.name+' ] 责任人不能为空，请选择责任人')
              return
            } else if(node.user_type === 5 && node.nodeType === 'task' && node.user_depart.length === 0){
              this.$message.error('任务：[ '+node.name+' ] 部门不能为空，请选择部门')
              return
            } else if(node.user_type === 6 && node.nodeType === 'task' && node.user_role.length === 0){
              this.$message.error('任务：[ '+node.name+' ] 岗位不能为空，请选择岗位')
              return
            }
          }
        }
        const valid = this.validTaskEngine(graphData.cells)
        if(typeof this.$parent.triggerValidate === 'function'){
          try {
            this.$parent.triggerValidate()
          } catch (e) {
            this.$message.error('数据校验失败，请核对必填字段！')
            return
          }
        }
        if(!this.configData.name){
          this.$refs.taskConfig.validForm()
          this.$message.error('任务引擎名称缺失，请检查后保存！')
          return
        }
        if(!valid.startNode || !valid.endNode){
          this.$message.error('开始或结束节点缺失，请检查后保存！')
          return
        }
        for (let item of graphData.cells){
          if(
            item.shape === 'task' &&
            (!valid.source.includes(item.id) || !valid.target.includes(item.id))
          ){
            this.$message.error('任务连线未连接完整，请检查后保存！')
            return
          }
        }
        const param = {
          __method_name__: 'createData',
          object_uuid: 'object628619c716579',
          transcode: 0
        }
        if(this.taskEngineId){
          param.__method_name__ = 'updateData'
          param.data_id = this.taskEngineId
        }
        let saveData = {
          ...this.configData,
          json: graphData,
          ...nodeData,
          ...param
        }
        if(this.taskRecoverId) {
          saveData = {
            __method_name__: 'updateData',
            object_uuid: 'object628ae2a79d46c',
            data_id: this.taskRecoverId,
            transcode: 0,
            json: graphData,
            ...nodeData,
            status_config: this.configData.status_config
          }
        }
        dataInterface(saveData).then(res=>{
          if(res.data.code === 200){
            this.$message.success(res.data.msg)
            this.configOpen = false
            this.renderKey = uniqid()
          }
        })
        // this.$emit('on-save',saveData)
      }
    },
    formatData(graphData){
      const nodeList = [], lineList = []
      let start_node='', end_node=''
      for (let item of graphData){
        if(['edge','gateway'].includes(item.shape)){
          const newData = {
            nodeId: item.id,
            nodeType: item.shape,
            name: item.data?.name || item.shape,
            run_logic: item.data?.run_logic || [],
          }
          if(item.shape === 'edge'){
            newData.from = item.source.cell
            newData.to = item.target.cell
            lineList.push(newData)
          } else {
            nodeList.push(newData)
          }
        } else {
          if(item.shape === 'start') start_node = item.id
          if(item.shape === 'end') end_node = item.id
          if(item.shape === 'task' || item.shape === 'swim') delete item.component
          const newData = {
            nodeId: item.id,
            nodeType: item.shape,
            name: item.data?.name || item.shape,
            user_type: item.data?.user_type || 7,
            task_type: item.data?.task_type || 1,
            target_users: item.data?.target_users || [],
            user_from: item.data?.user_from || 1,
            user_depart: item.data?.user_depart || [],
            user_role: item.data?.user_role || [],
            assign: item.data?.assign || 1,
            form_type: item.data?.form_type || 1,
            object_uuid: item.data?.object_uuid || '',
            func_form: item.data?.func_form || '',
            run_flow: item.data?.run_flow || 1,
            run_logic: item.data?.run_logic || [],
          }
          nodeList.push(newData)
        }
      }
      const node_data = {nodeList,lineList}
      return {node_data,start_node,end_node}
    },
    updateProps(data){
      if(this.selectedNode){
        if(this.nodeType === 'edge'){
          if(data.name){
            const label = {
              markup: [
                {tagName: 'rect', selector: 'body',},
                {tagName: 'text', selector: 'label',},
              ],
              attrs: {
                text: {
                  text: data.name,
                },
                label: {
                  fill: '#000',
                  fontSize: 14,
                  textAnchor: 'middle',
                  textVerticalAnchor: 'middle',
                  pointerEvents: 'none',
                },
                body: {
                  ref: 'label',
                  fill: '#ffd591',
                  stroke: '#ffa940',
                  strokeWidth: 2,
                  rx: 4,
                  ry: 4,
                  refWidth: '140%',
                  refHeight: '140%',
                  refX: '-20%',
                  refY: '-20%',
                },
              },
              zIndex:10
            }
            this.selectedNode.setLabels([label])
          } else {
            this.selectedNode.setLabels([])
          }
        }
        this.selectedNode.updateData(data)
      }
    },
    insertCellTemplate(e, cell){
      const node = this.graph.createNode(cell)
      this.dnd.start(node, e)
    },
    drag(e,nodeItem){
      let nodeData;
      switch (nodeItem.type){
        case 'start':
          nodeData = {
            shape: "start", width: 70, height: 70,
            data:{}
          }
          break
        case 'task':
          nodeData = {
            shape: "task", width: 248, height: 318,
            data: {
              name: '任务标题',
              status: '',
              statusType: 'auto',
              statusList: this.configData.status_config,
              baseInfo: [
                {
                  name: '任务责任人', field: 'targetUser', value_type: null,
                  value: '', cell_block: '', cell_field: '', formItem: [],
                  show: true, show_setting: true
                },
                {
                  name: '任务抄送人', field: 'sendUser', value_type: null,
                  value: '', cell_block: '', cell_field: '', formItem: [],
                  show: true, show_setting: true
                },
                {
                  name: '计划开始', field: 'planStartDate', value_type: 1,
                  value: '', cell_block: '', cell_field: '', formItem: [],
                  show: true, show_setting: true
                },
                {
                  name: '计划完成', field: 'planEndDate', value_type: 1,
                  value: '', cell_block: '', cell_field: '', formItem: [],
                  show: true, show_setting: true
                },
                {
                  name: '实际开始', field: 'actualStartDate', value_type: 1,
                  value: '', cell_block: '', cell_field: '', formItem: [],
                  show: true, show_setting: true
                },
                {
                  name: '实际完成', field: 'actualEndDate', value_type: 1,
                  value: '', cell_block: '', cell_field: '', formItem: [],
                  show: true, show_setting: true
                }
              ],
              content: []
            }
          }
          break
        case 'gateway':
          nodeData = {shape: "gateway", width: 86, height: 86, data:{}}
          break
        case 'end':
          nodeData = {shape: "end", width: 70, height: 70, data:{}}
          break
        case 'swim':
          nodeData = {
            shape: "swim", width: 450, height: 200,
            data:{
              content: [{name: '泳道',ratio: 1}]
            }
          }
          break
        case 'dashed':
          nodeData = {
            width: 280,
            height: 150,
            attrs: {
              body: {
                stroke: '#cccccc',
                strokeDasharray: '5 3',
                fill: 'transparent',
                rx: 10,
                ry: 10,
              },
            },
          }
          break
        case 'text':
          nodeData = {
            width: 120,
            height: 30,
            shape: 'html',
            html: () => {
              const wrap = document.createElement('div')
              wrap.style.width = '100%'
              wrap.style.height = '100%'
              wrap.style.display = 'flex'
              wrap.style.justifyContent = 'center'
              wrap.style.alignItems = 'center'
              const textarea = document.createElement('textarea')
              textarea.innerText = '文本文字'
              textarea.style.fontSize = '14px'
              textarea.style.width = '100%'
              textarea.style.height = '100%'
              textarea.style.textAlign = 'center'
              textarea.style.backgroundColor = 'transparent'
              textarea.style.lineHeight = '25px'
              textarea.style.overflow = 'hidden'
              wrap.append(textarea)
              return wrap
            }
          }
          break
        case 'rect':
          nodeData = {
            width: 160,
            height: 80,
            attrs: {
              body: {
                rx: 10,
                ry: 10,
              },
            },
          }
          break
        case 'line':
          nodeData = {
            shape: 'polyline',
            width: 150,
            height: 10,
            attrs: {
              body: {
                stroke: '#000000',
                refPoints: '0,0  100,0',
                refY: 5,
              },
            },
          }
          break
      }
      const node = this.graph.createNode(nodeData)
      this.dnd.start(node, e)
    },
    // 获取前置任务配置Data
    getPreTask(nodeId,type){
      const graphData = this.graph.toJSON()
      const preNodeId = preTaskNode(graphData.cells,nodeId,type,[nodeId])
      const preNode = graphData.cells.filter(item => preNodeId.includes(item.id) && item.shape === 'task')
      const nodeData = Array.from(preNode,item=>item.data)
      return nodeData
    },
    openFormParser(node){
      this.skeleton = true
      this.otherParam.f_task_engine_id = this.taskEngineId
      this.otherParam.f_task_node_id = node.id
      this.otherParam.f_task_recover_id = this.taskRecoverId
      if (this.outerParam) {
        this.otherParam = Object.assign(this.otherParam, this.outerParam)
        this.fillData = Object.assign(this.fillData, this.outerParam)
      }
      if(this.taskMode !== 'recover'){
        return false
      }
      if([1,2].includes(node.form_type)) {
        this.parserMode = 'form'; // 表单
      } else {
        this.parserMode = 'page'; // page页面
      }

      let tipMsg = '当前任务尚未开始暂时无法填报'
      const userInfo = this.$GetUserInfo()
      for (const item of this.taskTodo) {
        const userId = Array.from(item.user_task, ele => ele.user_id)
        if(item.step_node === node.id && !userId.includes(userInfo.id)) {
          tipMsg = '您暂无相关填报权限，暂时无法填报'
          break
        }
      }
      let data = this.taskTodo.find(item=> {
        const userId = Array.from(item.user_task, ele => ele.user_id)
        return item.step_node === node.id && userId.includes(userInfo.id)
      })
      if(!data) {
        data = this.completeTodo.find(item=> item.step_node === node.id)
      }
      if(data === undefined) {
        this.$message.error(tipMsg)
        return false
      }
      this.modalTitle = node.name;
      this.dialogVisible = true;
      this.displayData = {};
      this.formDesignData = null;
      let formDataId = 0  // 表单数据详情回显 -- 有多条只展示第一条数据
      for (let item of data?.user_task) {
        if(item.task_form_id > 0) {
          formDataId = item.task_form_id
          break
        }
      }
      this.dataId = formDataId
      if(formDataId) {
        this.formMode = 'detail'
      } else {
        this.formMode = 'add'
      }
      this.curStepNode = data.step_node;
      this.taskNodeData = data.nodeData;
      this.taskTodoId = data.task_log_id;
      if(node.form_type === 1){
        formDesign.formShow(node.func_form).then(res=>{
          if(res.data.code === 200){
            const formData = res.data.data
            this.formDesignData = JSON.parse(formData.json)
            this.needFlow = !!formData.need_flow
            this.moduleUuid = formData.module_uuid
          }
        });
      } else if(node.form_type === 2) {
        const objectUUID = node.object_uuid
        const formUUID = node.func_form
        transFieldsUUid(objectUUID, formUUID, {}).then((res) => {
          if (res.data.code == 200) {
            const formData = res.data.data
            let ruuids = []; // 详情子表需传入关联UUID
            for (let i = 0; i < formData.fields.length; i++) {
              let item = formData.fields[i];
              if (item.__config__.tagIcon == 'form') {
                ruuids.push({ relationship_uuid: item.__vModel__ });
              }
            }
            if(formDataId) {
              dataInterface({
                object_uuid: objectUUID,
                __method_name__: 'dataInfo',
                data_id: formDataId,
                ruuids
              }).then((res1) => {
                this.displayData = res1.data.data
                this.formDesignData = formData
                this.needFlow = !!formData.need_flow
                this.moduleUuid = formData.uuid
              })
            } else {
              this.formDesignData = formData
              this.needFlow = !!formData.need_flow
              this.moduleUuid = formData.uuid
            }
          }
        })
      } else if(node.form_type === 3) {
        this.pageUUID = node.func_form
        this.skeleton = false
        let key = `dialogRelationParams_${this.pageUUID}` || `${this.pageUUID}`
        sessionStorage.setItem(key, JSON.stringify(this.fillData))
      }
    },
    getSelectStep(){},
    // 任务责任人
    dealWithPicker(selected, total) {
      this.selectedUsers = selected
      this.checkPickerNum = total
    },
    triggerSubmit () {
      if(this.taskNodeData.form_type === 3){
        let formData = {field649c0d25e8097: this.pageUUID}
        this.onFormSubmit(formData)
      } else {
        this.$refs.dialogForm.handleSubmit()
      }
    },
    onFormSubmit (formData) {
      formData.task_log_id = this.taskTodoId
      /*if (
        this.checkPickerNum !== 0 &&
        this.checkPickerNum !== this.selectedUsers.target_users.length
      ) {
        this.$message.error('下一任务节点责任人未选择！')
        return false
      }
      if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
        formData.target_users = this.selectedUsers.target_users
      }*/
      if(this.taskNodeData.form_type === 1){
        if (['edit', 'detail'].includes(this.formMode)) {
          // 编辑 详情
          formDesign.formDataUpdate(formData,this.dataId).then((res) => {
            if (res.data.code === 200) {
              this.dialogVisible = false
              this.$message.success(res.data.msg);
              this.initCanvas()
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          // 新增
          formData.module_uuid = this.moduleUuid
          formDesign.formDataStore(formData).then((res) => {
            if (res.data.code === 200) {
              this.dialogVisible = false
              this.$message.success(res.data.msg);
              this.initCanvas()
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      } else if(this.taskNodeData.form_type === 2){
        const objectUuid = this.taskNodeData.object_uuid
        const params = {
          object_uuid: objectUuid,
          ...formData
        }
        if (['edit', 'detail'].includes(this.formMode)) {
          // 编辑 详情
          params.__method_name__ = 'updateData'
          params.data_id = this.dataId
        } else {
          // 新增
          params.__method_name__ = 'createData'
        }
        dataInterface(params).then((res) => {
          if (res.data.code=== 200) {
            this.dialogVisible = false
            this.$message.success(res.data.msg);
            this.initCanvas()
          }
        }).catch((err) => {
            console.log(err);
        }).finally(()=>{
          this.$loading().close();
        });
      } else if(this.taskNodeData.form_type === 3){
        // 模拟formModel,便于页面类型任务流转
        const objectUuid = 'object649c0d00271b8'
        const params = {
          object_uuid: objectUuid,
          ...formData
        }
        if (['edit', 'detail'].includes(this.formMode)) {
          // 编辑 详情
          params.__method_name__ = 'updateData'
        } else {
          // 新增
          params.__method_name__ = 'createData'
        }
        dataInterface(params).then((res) => {
          if (res.data.code=== 200) {
            this.dialogVisible = false
            this.$message.success(res.data.msg);
            this.initCanvas()
          }
        }).catch((err) => {
          console.log(err);
        }).finally(()=>{
          this.$loading().close();
        });
      }
    },
    closePicker(){
      if(this.$refs.taskPicker){
        this.$refs.taskPicker.isExpand = false
      }
    },
    onEndFlow(){
      this.dialogVisible = false
    },
  },
  destroyed() {
    this.graph.dispose()
    this.graph = null
    eventBus.$off('open-task-form')
  }
}
</script>
<style lang="less">
  .snapline{
    .x6-widget-snapline-horizontal{
      border-bottom:1px dashed #F57F17 !important;
    }
    .x6-widget-snapline-vertical{
      border-right:1px dashed #F57F17 !important;
    }
  }
  .task-block {
    .x6-widget-selection-box {
      pointer-events: none !important;
    }
  }
</style>
<style lang="less" scoped>
.no-show {
  display: none;
}
.indictor{
  position: absolute;
  top: 25px;
  left: 85px;
  line-height: 20px;
  z-index: 10;
  display: flex;
}

.indictor-todo{
  padding-left: 15px;

  b{
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-color: #539FE6;
    border-radius: 3px;
  }
}

.indictor-complete{
  padding-left: 15px;
  b{
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-color: #40B67C;
    border-radius: 3px;
  }
}

.indictor-danger {
  padding-left: 15px;
  b{
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-color: #F94520;
    border-radius: 3px;
  }
}

:deep(.el-tabs) {
  height: 100%;
  box-shadow: none;
  .el-tabs__content{
    padding: 0;
    height: calc(100% - 40px);
    .el-tab-pane{
      height: 100%;
    }
  }
}

:deep(.common-dialog)  {
  .el-dialog__header{
    border-bottom: 1px #f2f3f5 solid;
  }
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__footer{
    padding: 10px 0;
    border-top: 1px #f2f3f5 solid;
  }
}

.dialog-main{
  padding: 0;
  max-height: calc(100vh - 230px);
  overflow-y: auto;
}

.scrollbar-bar{
  overflow-y: auto;
  padding: 0 15px;
  height: calc(100% - 60px);
}

.iconc-close{
  font-size: 18px;
  cursor: pointer;
}

.task-config-wrapper{
  text-align: initial;
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;

  .conf-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px;
    border-bottom: 1px solid #eaebed;
    font-family: var(--systemFontFamily);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  .content {
    height: calc(100% - 105px);
  }
}

.task-flow-container{
  text-align: initial;
  width: 100%;
  height: calc(100% - 5px);
  border: 1px solid #eaebed;

  .main-container {
    width: 100%;
    height: 100%;
    display: flex;

    .left-sidebar {
      width: 224px;
      height: 100%;
      z-index: 99;
    }
    .right-container {
      position: relative;
      flex: 1;
      height: 100%;

      .contextmenu{
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        z-index: 10;
        border-radius: 5px;
        box-shadow: 3px 3px 5px #e0e0e0;
        width: 100px;
        background: #FFFFFF;
        .menu-item {
          line-height: 25px;
          text-align: center;

          &:hover {
            background: #E3E4E3;
          }
        }
      }
      .action-bar{
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
      }
      .graph-container{
        position: absolute;
        top: 100px;
        left: 20px;
        z-index: 101;
        padding: 20px 10px;
        text-align: center;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0 0 10px 1px rgb(228, 224, 219);

        .node-item {
          margin-bottom: 15px;
        }

        .end-bottom {
          padding-bottom: 15px;
          border-bottom: 1px solid #e0e0e0;
        }

        .node-item-icon {
          display: block;
          width: 40px;
          height: 40px;
          background-size: cover;
        }

        .node-label {
          font-size: 12px;
          line-height: 30px;
          user-select: none;
        }

        .node-start {
          background: url('./background/start.png') no-repeat;
          background-size: cover;
        }

        .node-task {
          background: url('./background/task.png') no-repeat;
          background-size: cover;
        }

        .node-gateway {
          background: url('./background/gateway.png') no-repeat;
          background-size: cover;
        }

        .node-end {
          background: url('./background/end.png') no-repeat;
          background-size: cover;
        }
        .node-swim {
          background: url('./background/swim.png') no-repeat;
          background-size: cover;
        }

        .node-dashed {
          background: url('./background/dashed.png') no-repeat;
          background-size: cover;
        }

        .node-text {
          background: url('./background/text.png') no-repeat;
          background-size: cover;
        }

        .node-rect {
          background: url('./background/rect.png') no-repeat;
          background-size: cover;
        }

        .node-line {
          background: url('./background/line.png') no-repeat;
          background-size: cover;
        }
      }
      .task-block{
        width: 100%;
        height: 100%;
        background-color: #F7F9FF;
      }

      .node-graph {
        //position: absolute;
        //top: 0;
        //left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }

      .node-graph-pos {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 88;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 16px;
    right: 10px;
    .btn {
      width: 106px;
      height: 32px;
      padding: 5px 20px 5px 20px;
      border-radius: 6px;
      gap: 8px;
      border: none;
      font-family: var(--systemFontFamily);
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-align: center;
    }
    .cancel {
      background-color: rgba(242, 245, 250, 1);
      color: rgba(66, 71, 81, 1);
    }
    .determine {
      background-color: rgba(56, 127, 252, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
}

.task-publish {
  text-align: right;
  padding-right: 15px;
}
.skeleton {
  padding: 15px;
}
</style>
