/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-26 18:44:21
 * @FilePath: \dataview-viewer-test\src\views\userCenter\lang\en.js
 */
export default {
  "components.AccountInfo.233336-0": "Username",
  "components.AccountInfo.233336-1": "Please enter your username",
  "components.AccountInfo.233336-2": "Cancel",
  "components.AccountInfo.233336-3": "Save",
  "components.AccountInfo.233336-4": "Edit",
  "components.AccountInfo.233336-5": "Password",
  "components.AccountInfo.233336-6": "Forget Password",
  "components.AccountInfo.233336-7": "Modify",
  "components.AccountInfo.233336-8": "Phone Number",
  "components.AccountInfo.233336-9": "Enterprise WeChat",
  "components.AccountInfo.233336-10": "Bound",
  "components.AccountInfo.233336-11": "Unbound",
  "components.AccountInfo.233336-12": "Unbind",
  "components.AccountInfo.233336-13": "Bind",
  "components.AccountInfo.233336-14": "Change Password",
  "components.AccountInfo.233336-15": "Password must consist of digits, letters, and special characters, with a length of 8-16 characters!",
  "components.AccountInfo.233336-16": "Current Password",
  "components.AccountInfo.233336-17": "Please enter your current password",
  "components.AccountInfo.233336-18": "New Password",
  "components.AccountInfo.233336-19": "Please enter your new password",
  "components.AccountInfo.233336-20": "Confirm New Password",
  "components.AccountInfo.233336-21": "Please confirm your new password",
  "components.AccountInfo.233336-22": "Cancel",
  "components.AccountInfo.233336-23": "Back to Login",
  "components.AccountInfo.233336-24": "Confirm",
  "components.AccountInfo.233336-25": "Please enter new password!",
  "components.AccountInfo.233336-26": "Please confirm new password!",
  "components.AccountInfo.233336-27": "Passwords entered do not match!",
  "components.AccountInfo.233336-28": "Are you sure you want to modify the avatar?",
  "components.AccountInfo.233336-29": "Modified successfully!",
  "components.AccountInfo.233336-30": "Password has been changed, would you like to log in now?",
  "components.AccountInfo.233336-31": "Prompt",
  "components.AccountInfo.233336-32": "Go to Login",
  "components.AccountInfo.233336-33": "Operation failed!",
  "components.AccountInfo.233336-34": "Remove the route file and try again",
  "components.AccountInfo.233336-35": "Please enter a valid phone number!",
  "components.AccountInfo.233336-36": "Are you sure you want to unbind Enterprise WeChat?",
  "components.AccountInfo.233336-37": "Operation failed",
  "components.AccountInfo.233336-38": "Enterprise WeChat has been unbound!",
  "components.AccountInfo.233336-39": "Bound successfully!",
  "components.AccountInfo.233336-40": "Operation successful!",
  "components.UserInfo.300893-0": "Avatar",
  "components.UserInfo.300893-1": "Modify",
  "components.forgetPass.325653-0": "Please enter your phone number",
  "components.forgetPass.325653-1": "Please enter the code",
  "components.forgetPass.325653-2": "Get Code",
  "components.forgetPass.325653-3": "Please enter new password",
  "components.forgetPass.325653-4": "Please enter new password again",
  "components.forgetPass.325653-5": "Reset Password",
  "components.forgetPass.325653-6": "Please enter new password!",
  "components.forgetPass.325653-7": "Password must consist of digits, letters, and special characters, with a length of 8-16 characters!",
  "components.forgetPass.325653-8": "Please confirm new password!",
  "components.forgetPass.325653-9": "Passwords entered do not match!",
  "components.forgetPass.325653-10": "Verification cannot be empty!",
  "components.forgetPass.325653-11": "Incorrect verification code format",
  "components.forgetPass.325653-12": "Please repeat new password",
  "components.forgetPass.325653-13": "Submitted successfully! Redirecting to login page",
  "components.forgetPass.325653-14": "Sent successfully",
  "userCenter.Index.360471-0": "Account Information",
  "components.AccountInfo.761762-0": "Email",
  "components.AccountInfo.761762-1": "Please enter your email",
  "components.AccountInfo.761762-2": "Cancel",
  "components.AccountInfo.761762-3": "Save",
  "components.AccountInfo.761762-4": "Modify"
}