/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-18 09:26:04
 * @FilePath: \dataview-viewer-test\src\custom-component\login\lang\en.js
 */
export default {
  "components.LoginByInput.642272-0": "Scan for a more secure login",
  "components.LoginByInput.642272-1": "Login with Password",
  "components.LoginByInput.642272-2": "Login with SMS",
  "components.LoginByInput.642272-3": "Username/Email/Phone Number",
  "components.LoginByInput.642272-4": "Enter your password",
  "components.LoginByInput.642272-5": "Login",
  "components.LoginByInput.642272-6": "Forgot Password",
  "components.LoginByInput.642272-7": "Enter your phone number",
  "components.LoginByInput.642272-8": "Enter verification code",
  "components.LoginByInput.642272-9": "Get Verification Code",
  "components.LoginByInput.642272-10": "Retry after seconds",
  "components.LoginByInput.642272-11": "Please complete the form",
  "components.LoginByInput.642272-12": "Logging in...",
  "components.LoginByInput.642272-13": "Login failed!",
  "components.LoginByInput.642272-14": "Login successful!",
  "components.LoginByInput.642272-15": "No further actions will be taken in the editor environment, please proceed to dev or live environment for testing!",
  "components.LoginByInput.642272-16": "Failed to retrieve system basic information!",
  "components.LoginByInput.642272-17": "Please enter a valid phone number",
  "components.LoginByInput.642272-18": "SMS verification code sent, there may be delays, please be patient",
  "components.LoginByInput.642272-19": "Failed to get verification code!",
  "components.LoginByInput.642272-20": "Phone information error",
  "components.LoginByCode.634977-0": "Secure login by scanning with your phone",
  "components.LoginByCode.634977-1": "Open WeChat | Enterprise WeChat, scan to quickly login/register",
  "components.LoginByCode.634977-2": "After successful scan, tap 'Agree' in WeChat to complete login",
  "components.LoginByCode.634977-3": "You have canceled this login attempt",
  "components.ResetPassword.785871-0": "Forgot Password",
  "components.ResetPassword.785871-1": "Phone/email used during registration",
  "components.ResetPassword.785871-2": "Enter verification code",
  "components.ResetPassword.785871-3": "Get Verification Code",
  "components.ResetPassword.785871-4": "Retry after seconds",
  "components.ResetPassword.785871-5": "Next",
  "components.ResetPassword.785871-6": "New Password",
  "components.ResetPassword.785871-7": "Repeat Password",
  "components.ResetPassword.785871-8": "Reset Password",
  "components.ResetPassword.785871-9": "Back to Previous Step",
  "components.ResetPassword.785871-10": "Back to Login",
  "components.ResetPassword.785871-11": "Password must be at least 8 characters long and include a combination of numbers, letters, and special characters.",
  "components.ResetPassword.785871-12": "Passwords do not match",
  "components.ResetPassword.785871-13": "Please complete the form",
  "components.ResetPassword.785871-14": "Failed to reset password",
  "components.ResetPassword.785871-15": "Please enter a valid phone number",
  "components.ResetPassword.785871-16": "SMS verification code sent, there may be delays, please be patient",
  "components.ResetPassword.785871-17": "Failed to send verification code",
  "components.ResetPassword.785871-18": "Phone information error",
  "login.mixin.362796-0": "No further actions in the editor environment. Please proceed to the dev or live environment to test the effects!",
  "login.mixin.362796-1": "Failed to retrieve system basic information!",
  "login.mixin.362796-2": "Login successful!",
  "login.mixin.362796-3": "Login failed!",
  "login.mixin.362796-4": "Failed to retrieve system configuration!",
  "login.mixin.362796-5": "Invalid path",
  "login.mixin.362796-6": "Failed to retrieve organizational structure!"
}