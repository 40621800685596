/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-26 14:08:28
 * @FilePath: \dataview-viewer-test\src\locale\lang\en.js
 */
export default {
  "appEntry.Index.905788-0": "Resources loading, please wait...",
  "appEntry.Index.905788-1": "No Data"
}