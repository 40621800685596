<!--
 * @Description: 页面解析器
 * @Author: luocheng
 * @Date: 2021-09-13 15:48:38
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-26 14:07:00
-->
<template>
  <div class="parser" v-loading="!initEnd">
    <template v-if="initEnd">
      <!-- 普通页 -->
      <RegularPage v-if="pageEditorType === 'page' || pageEditorType === 'template' || pageEditorType === 'second'"
        :insidePageData="insidePageData"
        :defaultTableSelectData="defaultTableSelectData"
        :componentList="insidePageData && insidePageData.componentData"
        :canvasStyle="canvasStyle"
      ></RegularPage>
      <!-- 大屏页面 -->
      <ScreenPage v-else-if="pageEditorType === 'dataview' || pageEditorType === 'console'"
        :insidePageData="insidePageData"
        :defaultTableSelectData="defaultTableSelectData"
        :componentList="insidePageData && insidePageData.componentData"
        :canvasStyle="canvasStyle"
      ></ScreenPage>
    </template>
    <el-empty :description="$t('appEntry.Index.905788-1')" v-else></el-empty>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data';
import RegularPage from './page/Index';
import ScreenPage from './screen/Index';
import { Empty } from 'element-ui';
import { deepClone } from '@topology/core';
import eventBus from '@/plugins/eventBus';
import { isJSONStr, doEEActionHandle, getComponentById, doFrontOperation, judgingEventLogic, getStatusVal, removeEventBus, getLinkByTemplate, generateQuery,openUrl } from '@/utils/tools';

export default {
  name: 'Parser',
  components: {
    'el-empty': Empty,
    RegularPage,
    ScreenPage
  },
  props: {
		// 表格默认选中数据
		defaultTableSelectData: {
			type: Array,
			default: () => []
		},
    // 附加页面的UUID
    pageUUID: {
      type: String,
      default: ''
    },
    canvasStyleOut: {
      default: () => {}
    },
    // 表单参数
    pageParams: {
      default: null
    }
  },
  provide() {
    return {
      EDITOR_pageUUID: this.pageUUID
    }
  },
  data() {
    return {
      initEnd: false,
      insidePageData: null,
      pageEditorType: '',
      canvasStyle: {}
    }
  },
  computed: {
    ...mapState([
      'editorType' // 类型
    ])
  },
  created() {
    sessionStorage.setItem('editorType', this.editorType);
    this.$store.commit('outFormInlineParams', {
      pageUUID: this.pageUUID,
      pageParams: this.pageParams
    });
    if (this.pageUUID) {
      // 内嵌渲染器
      this.getInsideData();
    } else {
      this.pageEditorType = this.editorType;
      this.canvasStyle = this.canvasStyleOut || {};
      try {
        const statusConfig = JSON.parse(sessionStorage.getItem('statusConfig'));
        if (statusConfig && Array.isArray(statusConfig) && statusConfig.length) {
          this.$store.commit('initPageCustomStatus', statusConfig);
        }
        this.initEnd = true;
      } catch(err) {
        console.log(err);
      }
    }
  },
  mounted() {
    // 配置时候状态监听
     const events = {
      [this.pageUUID]: async configList => {
        if (!Array.isArray(configList)) return;
        // 只取第一个（只存在一种状态改变事件）
        if (!configList[0]?.eventList && !configList[0]?.specialEventList) return;
        const eventPattern = configList?.[0]?.pattern;
        if (eventPattern === 'special') {
          configList[0]?.specialEventList.forEach(ele => {
            this.doEventItem({
              ...ele,
              specialEventList: !ele.specialEventList ? [{
                ...ele
              }] : ele.specialEventList
            });
          })
          return;
        }
        // 常规事件
        const event = configList[0]?.eventList?.[0] || {};
        this.doEventItem(event);
      }
    };
    eventBus.$on('EDITOR_statusEvent', events[this.pageUUID]);
  },
  methods: {

    /**
     * @description: 触发单个事件
     * @param {Object} event 事件配置             
     */
     async doEventItem(event) {
      window.$EditorDebug.responsePageStatusEvent();
      const {
        actionType,
        effects = [],
        behaviors,
        specialEventList = [],
        pattern,
        linkType,
        queryList = [],
        linkUrl = '',
        stateMachine,
        pageId,
        frontOperation = [],
        linkTemplate = ''
      } = event;
      let canNext = true;
      if (frontOperation && frontOperation.length) {
        // 触发预处理事件、行为
        window.$EditorDebug.startFrontOperation();
        canNext = await doFrontOperation(frontOperation, {
          isGroup: this.isGroup,
          componentList: this.componentList,
          componentData: this.componentData,
          groupComponents: this.groupComponents
        });
        window.$EditorDebug.resultFrontOperation(canNext, null);
      }
      if (!canNext) {
        console.log('操作失败!');
        this.$message.error('操作失败！');
        return false;
      }
      if (actionType === 'eeAction') {
        // 触发后端事件
        window.$EditorDebug.startEEAction();
        const { objectUUID, viewUUID, eventName } = event;
        if (!objectUUID || !viewUUID || !eventName) {
          this.$message.error('事件配置错误！');
          window.$EditorDebug.failEEAction();
          return false;
        }
        // const data_id = this.getBindParams(event)?.data_id;
        const data_id = undefined;
        dataInterface({
          __method_name__: 'customEventCall',
          object_uuid: objectUUID,
          view_uuid: viewUUID,
          data_id, // 参数配置
          event: eventName
        })
          .then(res => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success('操作成功！');
              doEEActionHandle(res.data?.__adds__);
              window.$EditorDebug.successEEAction(null, eventName);
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            window.$EditorDebug.successEEAction(null, eventName);
          });
        return false;
      } else if (actionType === 'jumpPage') {
        const query = {};
        queryList.forEach(queryItem => {
          let component = getComponentById(this.componentData, queryItem.componentId);
          this.$set(query, queryItem.key, component?.resolveData[queryItem.feild]);
        });
        const queryStr = generateQuery(query);
        if (linkType === 'projectPage') {
          this.$router.push({
            path: pageId,
            query
          });
          return;
        } else if (linkType === 'outPage') {
          openUrl(linkUrl,linkUrl)
          // window.open(linkUrl);
        } else if (linkType === 'custom') {
          const customLink = getLinkByTemplate(linkTemplate, {})
          openUrl(`${customLink}${queryStr}`,customLink)
          // window.open(`${customLink}${queryStr}`);
        }
      } else if (actionType === 'export') {
        // 导出
        // this.onExport(item?.events?.click);
      } else if (actionType === 'pageStatus') {
        // 修改页面状态
        this.updatePageStatus(stateMachine || []);
      } else {
        // 普通事件
        if (!pattern) {
          effects.forEach(ele => {
            this.$store.commit('triggerEvents', {
              config: ele,
              element: null
            });
          });
        } else if (pattern === 'special') {
          specialEventList.forEach(ele => {
            ele.effects.forEach(effect => {
              this.$store.commit('triggerEvents', {
                config: effect,
                element: null
              });
            });
            if (ele.behaviors?.length) {
              for (let i = 0; i < ele.behaviors.length; i++) {
                this.$store.commit('triggerEvents', {
                  config: {
                    behavior: ele.behaviors[i],
                    isBehavior: true
                  }
                });
              }
            }
          });
        }
      }
      // 行为
      if (behaviors && Array.isArray(behaviors)) {
        if (behaviors.length === 1) {
          const { component, list } = behaviors[0];
          if (!component) {
            return;
          }
          if (list.length === 1) {
            const behaviorsList = list[0].behaviors;
            if (!behaviorsList.length) {
              return;
            }
          }
        }
        behaviors.forEach(behavior => {
          this.$store.commit('triggerEvents', {
            config: {
              behavior,
              isBehavior: true
            },
            element: null
          });
        });
      }
    },
    /**
     * @desc: 获取内嵌渲染器
     */
    getInsideData() {
      this.loading = true;
			dataInterface({
				__method_name__: 'dataList',
				page_uuid: this.pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			}).then((res) => {
        this.loading = false;
        if (!res?.data?.data?.length) {
          this.$message.error('暂无有效渲染配置！');
          return;
        }
        const { page_data } = res?.data?.data?.[0] || {};
        this.insidePageData = isJSONStr(page_data ) ? deepClone(JSON.parse(page_data)) : deepClone(page_data);
        this.pageEditorType = this.insidePageData?.editorType;
        const { componentData, editorConfig, editorType, statusConfig = [] } = this.insidePageData;
        if (statusConfig && Array.isArray(statusConfig) && statusConfig.length) {
          this.$store.commit('initPageCustomStatus', statusConfig);
        }
        this.canvasStyle = editorConfig || {};
        this.$store.commit('setSubsidiaryComponentData', {
          pageUUID: this.pageUUID,
          pageConfig: {
            componentData,
            editorConfig,
            editorType
          }
        })
        this.$nextTick(() => {
          this.initEnd = true;
        });
      }).catch((err) => {
        console.log(err);
        this.loading = false;
        this.$message.error('获取渲染配置失败！')
      });
    },
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.sContainer ? this.originComponentData : this.componentData;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		}
  },
  beforeDestroy() {
    removeEventBus('EDITOR_statusEvent', this.pageUUID)
  }
}
</script>

<style lang="less" scoped>
.parser{
  height: 100%;
  width: 100%;
  background: var(--systemBackground);
}
</style>