export const colorList = [
  "rgba(99, 157, 255, 1)",
  "rgba(3, 127, 76, 1)",
  "rgba(156, 211, 38, 1)",
  "rgba(202, 182, 65, 1)",
  "rgba(0, 200, 117, 1)",
  "rgba(255, 203, 0, 1)",
  "rgba(253, 171, 61, 1)",
  "rgba(255, 100, 46, 1)",
  "rgba(255, 173, 173, 1)",
  "rgba(226, 68, 92, 1)",
  "rgba(250, 161, 241, 1)",
  "rgba(255, 90, 196, 1)",
  "rgba(120, 75, 209, 1)",
  "rgba(162, 93, 220, 1)",
  "rgba(85, 89, 223, 1)",
  "rgba(34, 80, 145, 1)",
  "rgba(87, 155, 252, 1)",
  "rgba(102, 204, 255, 1)",
  "rgba(0, 134, 192, 1)",
  "rgba(78, 204, 198, 1)",
  "rgba(43, 118, 229, 1)",
  "rgba(173, 150, 122, 1)",
  "rgba(189, 168, 249, 1)",
  "rgba(169, 190, 232, 1)",
  "rgba(127, 83, 71, 1)",
];
//颜色转换为rgba
export const hex2Rgb = (hexValue, alpha = 1) => {
  const regex = /^#([0-9A-Fa-f]{6}),(?:\d+(?:\.\d*)?|\.\d+)$/;
  const match = regex.exec(hexValue);
  // console.log(match);
  if (match) (hexValue = `#${match[1]}`) && (alpha = match[0].split(",")[1]);

  const rgx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexValue.replace(rgx, (m, r, g, b) => r + r + g + g + b + b);
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!rgb) return hexValue;
  const r = parseInt(rgb[1], 16),
    g = parseInt(rgb[2], 16),
    b = parseInt(rgb[3], 16);
  return `rgba(${r},${g},${b},${alpha})`;
};
