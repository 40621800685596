<!--
 * @Description: 公共表单容器 用于引入外部表单
 * @Author: luocheng
 * @Date: 2021-10-15 15:01:42
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-11-14 17:43:58
-->
<template>
	<div class="w-full h-full" style="backdrop-filter: blur(4px)">
		<div class="w-full h-full" style="overflow: hidden;">
			<div class="console-form-header">
				<div>{{ typeName }}</div>
				<i @click="onCloseSelf" class="iconfont iconbiaotiguanbi"></i>		
			</div>
			<div class="form-bottom-cont">
				<div v-show="showToggle" v-if="outParam" ref="consoleFormCont" class="console-from-index">
					<div class="console-form-cont">	
						<!-- 基本信息 -->
						<div class="label-cont relative">
							<img class="arrow-img" src="//static.bimcc.net/images/arrow.png">	<span>基本信息</span>		
							<el-button v-if="requestType!=='detail'" size="mini" @click="onFormSubmit()" class="save-btn"  type="primary">提交</el-button>
						</div>
						<div class="w-full h-full overflow-y-auto inner-form-cont relative">
							<FormCont
							:element="element"
							ref="formCont"
							:outParam = "outParam"
							:chooseFilesInfo="files"
							:chooseBimInfo="chooseBimInfo"
							:chooseGisInfo="chooseGisInfo"
							:chooseViewInfo= "chooseViewInfo"
							:groupComponents="groupComponents"
							:isGroup="isGroup"
							:requestType="requestType"
							@set-special-info="setSpecialInfo"
							@reset-perent-data = "resetData"
							@postSuccess = "postSuccess($event)"
							/>
							<i class="iconfont" :class="toggle?'icona-Packupshouqi':'icona-unfoldzhankai'" style="position: absolute; top:48%;right:0" @click="toggle = !toggle" />	
						</div>	
						<!-- 关联构件 -->
						<div class="components-cont" :style="{display:showComponentCont}">
							<div class="label-cont">
								<img class="arrow-img" src="//static.bimcc.net/images/arrow.png">	<span>关联位置</span>		
							</div>	
							<div style="margin-top: 10px;" class="flex-row w-full">
								<div v-if="isBim"  class="flex1 overflow-hidden" >
									<div class="w-full" style="display:flex;flex-direction: row;">
										<div class="w-full flex1">
											<!-- 详情 -->
											<div class="w-full" v-if="requestType === 'detail'">
												<el-button style="padding:9px 7px" :disabled="bindBimLen===0" @click="location($event)" type="primary" size="small" class="w-full" >
													<span>查看模型<span>({{bindBimLen}})</span></span>	
												</el-button>
											</div>
											<!-- 编辑 -->
											<div class="w-full" v-else-if="requestType === 'edit'">
												<el-button :disabled="chooseBimInfo === null" v-if="bindBimLen === 0" @click="saveComponents()"  type="primary" size="small" class="w-full" >
													<span>绑定模型<span v-if="chooseBimInfo !== null" > ({{chooseNum}})</span></span>		  
												</el-button>
												<el-button style="padding:9px 7px" v-else @click="location($event)" type="primary" size="small" class="w-full" >
													<span>查看模型<span>({{bindBimLen}})</span></span>	
												</el-button>
											</div>
											<!-- 新增 -->
											<div class="w-full" v-else>
												<el-button :disabled="chooseBimInfo === null"  @click="saveComponents(true)"  type="primary" size="small" class="w-full" >
													<span>{{chooseBimInfo === null?'未选择':'已选择'}}</span><span v-if="chooseBimInfo !== null"> ({{chooseNum}})</span>	  
												</el-button>
											</div>
										</div>
										<div v-if="requestType!=='detail'&& bindBimLen!==0" class="icon-btn">
											<el-tooltip  effect="light" :content="isRebind?'绑定模型':'开启重绑'" placement="bottom">
												<i class="iconfont " @click="clearComponents(1)" :class="isRebind?'icongis_bangding':'iconbimgis_biaoji'"/>
											</el-tooltip>
											<el-tooltip  effect="light" content="清除全部" placement="bottom">
												<i @click="clearComponents(2)" class="iconfont iconsaidi-neirong-jiebang" />
											</el-tooltip>
											<el-tooltip  effect="light" content="添加已选择" placement="bottom">
												<i @click="saveComponents(true)" class="iconfont iconzhankai1" />
											</el-tooltip>
											<el-tooltip  effect="light"  content="删除已选择" placement="bottom">
												<i  @click="saveComponents(false)" class="iconfont iconshouqi" />
											</el-tooltip>
										</div>
									</div>	
								</div>
								<div v-if="isGis"  class="flex1 overflow-hidden" > 
									<div class="w-full" style="display:flex;flex-direction:row">
										<div v-if="chooseGisInfo !== null && chooseGisInfo"  class="flex w-full">
											<el-button   @click="location($event)" type="primary" size="small" class="w-full" >
												<span>查看位置</span>	
											</el-button>
										</div>
										<div v-if="requestType!=='detail'" class="icon-btn">
											<el-tooltip  effect="light" :content="isRebind?'绑定位置':'开启重绑'" placement="top">
												<i @click="clearComponents(1)" class="iconfont" :class="isRebind?'icongis_bangding':'iconbimgis_biaoji'" />
											</el-tooltip>
											<el-tooltip  effect="light" content="清除全部" placement="top">
												<i v-if="chooseGisInfo !== null && chooseGisInfo"  @click="clearComponents(2)" class="iconfont iconqingchu1" />
											</el-tooltip>
											</div>
									</div>
								</div>
							</div>
						</div>	
						
					</div>	
					<!-- 右侧容器 -->
					<div class="right-info-cont" v-show="toggle" >
						<div class="file-tab">
							<span v-for="(item,index) in tabData"  class="tab-item" @click="onChangeTab(item)" :key="index" :class="getFileClass(item.tab)" >
								<i class="iconfont" :class="item.icon"></i>	{{item.name}}
							</span>
						</div>
						<div class="w-full h-full right-inner-cont">
							<!-- 附件 -->
							<div  class="file-cont">
								<!-- 详情附件 -->
								<div class="w-full h-full overflow-hidden" v-if="tabIndex === 1"> 
									<div class="w-full h-full" v-if="imgList.length">
										<el-carousel :autoplay="false" :arrow="requestType === 'detail' && imgList.length>1 ?'hover':'never'" ref="carouselRef" @change="changeListActive"  trigger="click" height="100%">
											<el-carousel-item v-for="(item,index) in imgList" :key="index">
												<el-image
													class="w-full h-full"
													:src="item.url"
													fit="fit"></el-image>
											</el-carousel-item>
										</el-carousel>
									</div>
									<div v-else class="w-full h-full">
										<el-empty description="暂无数据" :image-size="80"></el-empty>
									</div>
								</div>
								<div class="w-full h-full overflow-hidden" v-if="tabIndex === 2"> 
									<div class="w-full h-full" v-if="fileList.length">
										<el-carousel :autoplay="false" :arrow="requestType === 'detail' && fileList.length>1 ?'hover':'never'" ref="carouselRef" @change="changeListActive"  trigger="click" height="100%">
											<el-carousel-item style="height: 100%;" v-for="(item,index) in fileList" :key="index">
												<iframe style="background: #fff;" :src="item.onlineUrl" frameborder="0" height="100%" width="100%"></iframe>
											</el-carousel-item>
										</el-carousel>
									</div>
									<div v-else class="w-full h-full">
										<el-empty description="暂无数据" :image-size="80"></el-empty>
									</div>
								</div>	
								<!-- 视点 -->
								<div class="w-full h-full" v-if="tabIndex === 3">
									<div class="w-full h-full" v-if="bimViewList.length">
										<el-carousel :autoplay="false" :arrow="requestType === 'detail' && bimViewList.length>1 ?'hover':'never'" ref="carouselRef" @change="changeListActive" trigger="click" height="100%">
											<el-carousel-item v-for="(item,index) in bimViewList" :key="index">
												<el-image
													@click="tabView(item)"
													class="w-full h-full"
													:src="item.picture"
													fit="fit"></el-image>
											</el-carousel-item>
										</el-carousel>
									</div>
									<div v-else class="w-full h-full">
										<el-empty description="暂无数据" :image-size="80"></el-empty>
									</div>
								</div>
							</div>
							<div class="file-list" v-if="requestType !== 'detail'">
								<ul class="w-full h-full" >
									<li :class="requestType !== 'detail'?'edit-able-list':''" v-for="(item,index) in curTabList" :key="index">
										<div :class="listActive === index?'active':''" @click.stop="clickList(index,item)" class="list-name">{{ item.name || '暂无命名' }}</div>	
										<div @click.stop="delFileList(index)" class="edit-icons">
											<i class="iconfont iconqingchu2"></i>	
										</div>
									</li>
								</ul>	
								<div v-if="requestType!=='detail'">
									<el-button @click="getModelView" v-if="tabIndex === 3" style="width:220px;border-radius: 4px;" size="small" type="primary">新增视点</el-button>
									<el-upload
										v-else
										class="upload-demo w-full"
										ref="upload"
										:action="uploadURL"
										:on-success="uploadSuccess"
										:show-file-list="false"
										>
										<el-button style="width:220px;border-radius: 4px;" size="small" type="primary">新增附件</el-button>
									</el-upload>
								</div>	
							</div>	
						</div>	
					</div>
				</div>	
			</div>
		</div>
	</div>
</template>

<script>
import FormCont from '@/console-components/consoleFormNew/components/formCont.vue';
import eventBus from '@/plugins/eventBus';
import { findModelComponentId, getNowTime, getComponentById, initParams, getToken } from '@/utils/tools.js'
import { dataInterface } from '@/apis/data/index';
import { baseUrl } from '@/apis/http/request';
import { mapState } from 'vuex';
import {Image, Tooltip, Button, Carousel, CarouselItem, Empty, Upload } from 'element-ui'; 

export default {
	name: 'ConsoleFormNew',
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	components: {
		FormCont,
		'el-upload':Upload,
		'el-empty': Empty,
		'el-image': Image,
		'el-tooltip': Tooltip,
		'el-button': Button,
		'el-carousel': Carousel,
		'el-carousel-item': CarouselItem,
  },
	data() {
		return {
			typeName:'',
			tabData:[],
			tabIndex:0,
			bimList:null,//bim模型字段
			gisList:null,//gis模型字段
			
			chooseGisInfo:null,//gis选中的位置以及模型
			chooseBimInfo:null,//bim选中的模型构件（里面是"field6268d8869a7a2": []格式）
			chooseViewInfo:null,//添加视点附件所选
			bindedBimInfo: null, //已绑定的模型构件信息
			bindedGisInfo: null, //已绑定的模型构件信息
			bindFilesInfo: null,//已上传的文件

			bindBimLen:0,//已绑定的Bim构件数量
			isRebind:false,
			timer:null, //解决触发多次的计时器
			bimComponentId:'',//模型的组件id
			gisComponentId:'',//gis组件id
			showToggle: true,
			clickViewLoading: false,
			
			modelView:null,//视图字段
			bimViewList: [],//已绑定的视图
			fileList:[],//附件
			imgList:[],//图片
			loadedBimIds:[], //加载的模型列表
			defaultView: null,
			outParam:null,
			requestType:'add', // 请求类型
			curTabList:[],//当前tab列表
			uploadURL: `${baseUrl}api/mapi?__method_name__=file&token=${getToken()}`,
			listActive:0,
			files: {},
			modelLoadFun:{functionName:'',data:null},//模型加载完毕后的需要执行的事件
			toggle: true
			
		};
	},
	computed: {
		...mapState(['componentData', 'curComponent']),
		//数据来源
		isBim () {
			const bimComponent = getComponentById(this.componentData, this.bimComponentId)
			if(bimComponent && bimComponent.statusConfig?.displayShow && bimComponent.statusConfig?.isShow){
				return true
			}else{
				return false
			}
		},
		//是否是gis页面显示
		isGis () {
			const gisComponent = getComponentById(this.componentData, this.gisComponentId)
			if(gisComponent && gisComponent.statusConfig?.displayShow && gisComponent.statusConfig?.isShow){
				return true
			} else {
				return false
			}
		},
		sourceConfig(){
			return this.element && this.element.sourceConfig;
		},
		//选中的构件个数
		chooseNum (){
			let num = 0
			if(this.chooseBimInfo && this.chooseBimInfo!==null && this.bimList.field && this.chooseBimInfo[this.bimList.field]?.length){
				this.chooseBimInfo[this.bimList.field].forEach((item)=>{
					if(item.dbids?.length){ 
						num += item.dbids.length
					}
				})
				return num
			}
			return num 
		},
		showComponentCont(){
			if(this.isBim){
				if(this.bindBimLen === 0){
					if(this.requestType === 'detail'){
						return 'none'
					}else{
						return 'block'
					}
				}else{
					return 'block'
				}
			}else{
				if(this.requestType === 'detail'){
					if(this.chooseGisInfo !== null && this.chooseGisInfo){
						return 'block'
					}else{
						return 'none'
					}
				} else {
					return 'block'
				}
			}
		},
		includesModel(){
			return function(data){
				if(data.state?.model_ids?.length){
					for(var i = 0; i < data.state.model_ids.length; i++){
						if(!this.loadedBimIds.includes(data.state.model_ids[i])){
							return true
						}
					}
					return false
				}else{
					return false
				}
			};
		},
		getFileClass(){
			return function(type){
				let str = ''
				if(this.tabIndex === type){
					str = 'active'
				}
				return str
			}
		}
	},
	watch:{
		requestType: {
      handler(val) {
				console.log(val,'val')
				if(val === 'detail'){
					this.typeName = '详情'
				} else if(val === 'add'){
					this.typeName = '新增'
				} else {
					this.typeName = '编辑'
				}
      },
      immediate: true,
    }
	},
	created(){
		//获取模型的组件id
		this.bimComponentId = findModelComponentId(this.componentData,'ViewerBIM') || '';
		this.gisComponentId = findModelComponentId(this.componentData,'ViewerGIS') || '';
		const doComponentBehavior = {
			[this.element.id]: config => {
				const { component, list = [] } = config;
				if (this.element.id !== component) return false;
				list.forEach(ele => {
					const { behaviors, params } = ele;
					const { param = {}, canPost } = initParams(params, false, this.subComponentData, []);
					if (canPost) {
						// 调用行为方法
						behaviors.forEach(funName => {
							try {
								eval(this[funName])(param)
							} catch (err) {
								console.log(err);
							}
						});
					}
				});
			}
		}
		eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id]);
	},
	unmounted() {
		eventBus.$off('doComponentBehavior');
	},
	mounted() {
		this.outParam = this.element.contentConfig
		if(this.sourceConfig?.length && this.sourceConfig[0].type){
			const types = {
				createData: 'add',
				dataInfo: 'detail',
				updateData: 'edit'
			};
			this.requestType = types[this.element?.sourceConfig?.[0].type || 'add'];
			this.outParam.requestType = this.requestType
		}
		//有页面状态取值为formStatusBysign时关闭页面
		let statusConfig = JSON.parse(sessionStorage.getItem('statusConfig'))
		if(statusConfig && statusConfig.length){
			let filterData = statusConfig.filter(v => v.code === 'formStatusBysign')
			if(filterData?.length && filterData[0].defaultValue === 1){
				this.showToggle = false
			}
		}
		this.getLoadModeIds()
		//监听获取加载的模型
		eventBus.$on('Viewer.onLoadedModel',() => {
			this.getLoadModeIds()
			if(this.modelLoadFun.functionName){
				setTimeout(()=>{
					this[this.modelLoadFun.functionName](this.modelLoadFun.data)
					this.modelLoadFun = {functionName:'',data:null}
				},2000)
				
			}
		})
		//bim模型选中构件
		eventBus.$on('Viewer.onViewerSelect',(componentId,selection) => {
			//获取加载完的模型
			if(selection){
				if(this.isBim){
					if(this.bimList === null || this.requestType === 'detail') return
					this.chooseBimInfo = null
					clearTimeout(this.timer)
					this.timer = setTimeout(() => {
						if(selection?.length){
							let arr = []
							selection.forEach((item)=>{
								let obj = {model_id:item.model_id, dbids:item.dbids}
								arr.push(obj)
							})
							this.$nextTick(()=>{
								this.setChooseBimData(arr)
							})
						} else {
							this.chooseBimInfo = null
						}
					}, 200)
				}
			}
		})
		//点击标记显示表单
		eventBus.$on('showForm',() => {
			//获取加载完的模型
			if(!this.showToggle){
				this.showToggle = true
			}
		})

	},
	methods: {
  /**
   * @description: 关闭
   * @return {*}
   */		
		onCloseSelf(){
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					statusConfig:{
						...this.element.statusConfig,
						isShow: false,
					}
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			})
		},
  /**
   * @description: 获取加载的模型id
   * @return {*}
   */	
		getLoadModeIds(){
			eventBus.$emit('ViewerBIM.getModelIds', this.bimComponentId , (list)=>{
				if(list){
					this.loadedBimIds = [...list]
				}
			})
		},
		/**
		 * @desc: 操作视图
		 */
		async getModelView () {
			this.clickViewLoading = true
			let graffiti = null
			const picture = await this.getScreenShotBuffer();
			const state = await this.getState()
			if(await this.getGraffitiActive()) {
				graffiti = await this.getGraffitiData()
			}
			this.clickViewLoading = false
			dataInterface({
				__method_name__ : "createData",
				object_uuid:'object63a01147947d3',
				view_uuid:'view63aea9a433c0c',
				name:'视点/'+ getNowTime(2),
				state : JSON.stringify(state),
				picture : picture,
				graffiti: graffiti
				
			}).then((res)=>{
				if (res.code || (res.status && res.status === 200)) {
					this.curTabList.push(res.data.data)
				}
			});
		},
		changeListActive(index){
			this.$nextTick(()=>{
				this.listActive = index
			})
		},
		clickList(index){
			this.$refs.carouselRef.setActiveItem(index)
		},
  /**
   * @description: 附件上传成功
   * @return {*}
   */		
		uploadSuccess(res) {
			if(res.code === 200){
				this.$nextTick(()=>{
					this.curTabList.push({...res.data,name:res.data.filename})
				})
			}
		},
  /**
   * @description: 删除附件列表
   * @param {*} index
   * @return {*}
   */		
		delFileList(index){
			this.curTabList.splice(index,1)
			if(this.tabIndex === 1){
				this.imgList.splice(index,1)
			} else if(this.tabIndex === 2){
				this.fileList.splice(index,1)
			} else if(this.tabIndex === 3){
				this.bimViewList.splice(index,1)
			}
		},
		/**
		 * @description: 初始化表单
		 * @return {*}
		 */		
		initForm(data){
			this.outParam = null
			this.resetData()
			if(data.requestType){
				this.requestType = data.requestType
			}
			this.outParam = data
		},
		goToFullScreen (element) {// 定义名为 "goToFullScreen" 的函数，并接受一个参数 "element"。
			element = element || document.body;// 如果传入 "element" 参数则以该元素为目标，否则为整个文档体 "document.body"
			if (element.requestFullscreen) {// 判断是否支持 W3C 标准的请求全屏接口
				element.requestFullscreen();// 通过调用 requestFullscreen() 方法来请求进入全屏模式
			} else if (element.mozRequestFullScreen) {// 判断是否支持 moz 的非标准客户端 API 请求接口
				element.mozRequestFullScreen();// 通过调用 mozRequestFullScreen() 方法来请求进入全屏模式
			} else if (element.msRequestFullscreen) {// 判断是否支持 Microsoft 提供的 PC Edge 浏览器和 IE11 浏览器专属 Api 接口
				element.msRequestFullscreen();// 通过调用 msRequestFullscreen() 方法来请求进入全屏模式
			} else if (element.webkitRequestFullscreen) {// 判断是否支持 Webkit 内核标准请求接口
				element.webkitRequestFullScreen();// 通过调用 webkitRequestFullScreen() 方法来请求进入全屏模式
			}
		},
		fullScreen(){
			this.goToFullScreen(this.$refs.consoleFormCont)
		},
  /**
   * @description: 切换顶部菜单
   * @return {*}
   */		
		onChangeTab(item){
			this.tabIndex = item.tab
			this.curTabList = []
			this.$nextTick(()=>{
				if(item.tab === 1){
					this.curTabList = this.imgList
				} else if(item.tab === 2){
					this.curTabList = this.fileList
				} else if(item.tab === 3){
					this.curTabList = this.bimViewList
				}
			})
		},
		/**
   * @description: 获取涂鸦数据
   * @return {*}
   */		
    getGraffitiData(){
			if(!this.bimComponentId) return Promise.resolve(null);
			return new Promise( (rol )=>{
					eventBus.$emit("ViewerBIM.Graffiti.getData" , this.bimComponentId , (data)=>{
							rol(data);
					})
			});
		},
		/**
   * @description: 获取涂鸦状态
   * @return {*}
   */		
    getGraffitiActive(){
			if(!this.bimComponentId) return Promise.resolve(null);
			return new Promise( (rol )=>{
					eventBus.$emit("ViewerBIM.Graffiti.isActive" , this.bimComponentId , (data)=>{
							rol(data);
					})
			});
		},
  /**
   * @description: 获取视点状态
   * @return {*}
   */		
		getState(){
			if(!this.bimComponentId) return Promise.resolve(null);
			return new Promise( (rol )=>{
					eventBus.$emit("ViewerBIM.getState" , this.bimComponentId , (data)=>{
							rol(data);
					})
			});
		},
   /**
    * @description: 获取截图
    * @return {*}
    */
		getScreenShotBuffer(){
			if(!this.bimComponentId) return Promise.resolve(null);
			return new Promise( (rol )=>{
					eventBus.$emit("ViewerBIM.getScreenShotBuffer" , this.bimComponentId , (data)=>{
							rol(data);
					})
			});
		},
		/**
		 * @desc: 重置特殊数据
		 * @param {Object} 
		 */
		resetData(){
				this.isRebind = false
				this.toggle = true
				this.tabIndex = 0
				this.chooseGisInfo = null//gis选中的位置以及模型
				this.chooseBimInfo = null//bim选中的模型构件
				this.chooseViewInfo = null//选中的视点构件
				this.bindedBimInfo = null //已绑定的bim模型构件
				this.bindedGisInfo = null //已绑定的gis模型构件
				this.bindFilesInfo = null
				this.files = {}
				this.bindBimLen = 0
				this.listActive = 0
				this.bimViewList = []
				this.imgList = []
				this.bimList = null
				this.gisList = null
				this.modelView = null
				this.fileList = []
				this.tabData = []
				this.curTabList = []
		},
		/**
		 * @desc: 获取bim,gis以及附件
		 * @param {Object} 
		 */
		setSpecialInfo(field, data){
			console.log(field, data,'field, data')
				let value = JSON.parse(JSON.stringify(data.value))
				this[field] = data
				if(field === 'bindFilesInfo' ){
					if(Array.isArray(value) && value.length){
						for(let i = 0; i < value.length; i++){
							if(['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].includes(value[i].fileext)){
								this.imgList.push(value[i])
								this.tabData.push({name:'图片',tab:1,icon:'icontupian'})
							} else if (['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].includes(value[i].fileext)) {
								this.tabData.push({name:'附件',tab:2,icon:'iconlianjie'})
								let url = 'https://weboffice.bimcc.net?furl='
								if (value[i].url.includes('https://')) {
									url = 'https://weboffice.bimcc.net?ssl=1&furl='
								}
								this.fileList.push({
									...value[i],
									onlineUrl: url + value[i].url
								})
							}
						}
					} else {
						this.tabData.push({name:'图片',tab:1,icon:'icontupian'})
						this.tabData.push({name:'附件',tab:2,icon:'iconlianjie'})
					}
				} else {
					if(field === 'gisList'){
						// console.log(value,'gis',field)
						if(value && JSON.stringify(value)!=='[]' && value!=='null'){
							this.bindedGisInfo = value
							this.setChooseGisData(value)
						}
					}
					if(field === 'bimList'){
						// console.log(value,'bim',field)
						if(Array.isArray(value) && value.length){
							this.bindBimLen = 0
							let num = 0
							this.bindedBimInfo = value
							if(value!=null  && value?.length){
								value.forEach((item)=>{
									if(item.dbids?.length){ 
										num += item.dbids.length
									}
								})
								this.bindBimLen = num
							}
						}
					}
					if(field === 'modelView'){
						this.tabData.push({name:'视点',tab:3,icon:'iconbim_shidianliebiao'})
						if(Array.isArray(value)){
							this.bimViewList = value
						}
					}
				}
				if(this.tabData.length){
					let map = new Map();
					for (let item of this.tabData) {
							map.set(item.tab, item);
					}
					this.tabData = [...map.values()];
					this.tabData.sort((a, b) => a.tab - b.tab)
					this.tabIndex = this.tabData[0].tab
					this.onChangeTab(this.tabData[0])
				}
		},
		/**
		 * @desc: 定位模型
		 * @param {Object} output 输出的数据
		 */
		location(e){
			this.toggle = false
			if(this.isBim){
				if(this.bindedBimInfo?.length){
					eventBus.$emit('ViewerBIM.setSelect',this.bimComponentId,this.bindedBimInfo);
					//缩放到
					eventBus.$emit('ViewerBIM.fitToView',this.bimComponentId,this.bindedBimInfo)
					eventBus.$emit('ViewerBIM.getModelIds', this.bimComponentId , async(list)=>{
						if(list?.length){
							let loadedBimIds = [...list]
							let unloadModel = await this.findUnloadModel(loadedBimIds,this.bindedBimInfo)
							if(unloadModel?.length){
								this.$confirm(loadedBimIds.length === 0?'是否加载模型?':'是否加载其他模型?', '提示', {
									confirmButtonText: loadedBimIds.length === 0 ? '确定' : '叠加加载',
									cancelButtonText: loadedBimIds.length === 0 ? '取消' : '重新加载',
									type: 'warning',
									closeOnClickModal: false,
									distinguishCancelAndClose: true,
									center: false,
									beforeClose (action, instance, done) {
											done()
									}
								}).then(() => {
									unloadModel.forEach((item)=>{
										eventBus.$emit('ViewerBIM.loadModel', this.bimComponentId, item.model_id)
									})
								}).catch((error) => {
									if(error === 'close'){
										return
									} else if(this.loadedBimIds.length){
										this.loadedBimIds.forEach((item)=>{
											eventBus.$emit('ViewerBIM.unloadModel', this.bimComponentId, item)
										})
										unloadModel.forEach((item)=>{
											eventBus.$emit('ViewerBIM.loadModel', this.bimComponentId, item.model_id)
										}) 
									}
								});
							} else {
								this.onAction('location',e)
							}
						}else{
								this.onAction('location',e)
						}
					});
				}
			}
			if(this.isGis){
				if(this.bindedGisInfo !== null){
					if(Object.prototype.toString.call(this.bindedGisInfo) === '[object Object]'){
						eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, this.bindedGisInfo.position, 1000, 45, 2);
						if(this.bindedGisInfo.models?.length){
							for(let i = 0; i < this.bindedGisInfo.models.length;i++){
								const item = this.bindedGisInfo.models[i]
								eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
							}
						}
					} else if(Object.prototype.toString.call(this.bindedGisInfo) === '[object Array]' && this.bindedGisInfo.length){
						eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, this.bindedGisInfo[0].location_gis, 1000, 45, 2);
						if(this.bindedGisInfo[0].models?.length){
							for(let i = 0; i < this.bindedGisInfo[0].models.length;i++){
								const item = this.bindedGisInfo[0].models[i]
								eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
							}
							
						}
					}
					this.onAction('location',e)
				}
			}
		},
		//提交成功后的回调
		postSuccess(e){
			this.resetData()
			this.onAction('onFormSubmit',e)
			eventBus.$emit('refreshConsoleList')
		},
		//查找未加载的模型id,数组
		findUnloadModel(loadedArr,arr){
			let newArr = [];
			if(loadedArr?.length && arr?.length){
				arr.forEach((item)=>{
					if(!loadedArr.includes(item.model_id)){
						newArr.push(item);
					}
				})
				return newArr
			}
			return newArr;
		},
		/**
		 * @desc: 操作
		 * @param {String} action 操作
		 * @param {Object} output 输出的数据
		 */
		onAction(action, e) {
			if(e){
				e.preventDefault();
				e.stopPropagation();
			}
			const eventList = this.element?.actionConfig[action]?.eventList;
			if (eventList?.length) {
				eventList.forEach((el) => {
					el.eventList.forEach(ele => {
						if (ele.key === 'click') {
							if (ele.actionType !== 'jumpPage') {
								// 触发组件行为
								const { behaviors, effects} = ele;
								if(behaviors?.length){
									behaviors.forEach(behavior => {
										this.$store.commit('triggerEvents', {
											config: {
												behavior,
												isBehavior: true
											},
											element: this.element
										});
									});
								}
								if(effects?.length){
									effects.forEach((effect) => {
										this.$store.commit('triggerEvents', {
											config: {
												...ele,
											...effect
											},
											element: this.element
										});
									});
								}
							}
						}
					})
				});
			}
		},
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		async onFormSubmit() {
			if(this.bindFilesInfo?.field){
				this.$set(this.files, this.bindFilesInfo.field, this.imgList.concat(this.fileList))
			}
			//新增默认截取视点
			if(this.requestType === 'add' && !this.bimViewList.length){
				if(this.modelView?.field){
					let obj = {
						__method_name__ : "createData",
						object_uuid:'object63a01147947d3',
						view_uuid:'view63aea9a433c0c',
					}
					if(this.isBim){
						obj.picture = await this.getScreenShotBuffer();
						const state = await this.getState()
						obj.state = JSON.stringify(state)
						if(await this.getGraffitiActive()) {
							obj.graffiti = await this.getGraffitiData()
						}
						obj.name = '视点/'+ getNowTime(2)
					}
					dataInterface(obj).then((res)=>{
						if (res.code || (res.status && res.status === 200)) {
							let newArr = this.bimViewList.concat(res.data.data)
							this.chooseViewInfo = {}
							this.$set(this.chooseViewInfo, this.modelView.field, newArr)
							this.onConfirm()
						}
					});
				} else {
					this.onConfirm()
				}
				
			} else {
				this.onConfirm()
			}
			
		},
  /**
   * @description: 提交请求
   * @return {*}
   */		
		onConfirm () {
			setTimeout(() => {
				this.$refs.formCont.onConfirm()
			}, 100);
		},
		/**
		 * @desc: 上传文件
		 */
		onSuccess(files,item){
			item.value = files
		},
		/**
		 * @desc: 赋值this.chooseBimInfo
		 */
		setChooseBimData(data = []){
			if(this.chooseBimInfo === null){
				this.chooseBimInfo = {}
				this.$set( this.chooseBimInfo,this.bimList.field, data)
			}else{
				this.chooseBimInfo[this.bimList.field] = data
			}
		},
		/**
		 * @desc: 赋值this.chooseGisInfo
		 */
		setChooseGisData(data = null){
			if(data === null){
				if(this.gisList.field){
					this.chooseGisInfo[this.gisList.field] = null
				} else {
					this.chooseGisInfo = null
				}
			} else {
				this.chooseGisInfo = {}
				this.$set(this.chooseGisInfo,this.gisList.field,data)
			}
		},
		/**
		 * @desc: 保存构件信息
		 * @param {Boolean} bol true算上选中的模型以及构件 false删除已选中构件
		 */
		async saveComponents (bol) {
			if(bol!==undefined){
				let comp = await this.contrastFn(bol)
				this.chooseBimInfo = comp
			} else {
				this.bindBimLen = this.chooseNum
			}
			this.onConfirm()
			
		},
		/**
		 * @desc: 清除所有绑定的构件信息
		 * @param {Number} type 2 清理全部 1 开启重绑
		 */
		async clearComponents (type) {
			this.toggle = false
			if(this.isBim){
				if(type === 2){
					//清除全部
					await this.setChooseBimData([])
					this.bindBimLen = 0
					// this.onConfirm()
				}else{
					if(this.isRebind){
						this.isRebind = false
						if(this.chooseBimInfo !== null && this.chooseBimInfo[this.bimList.field].length !== 0){
							this.onConfirm()
						}else{
							this.$message.error("请先选择构件！");
							return
						}
					}else{
						// this.bindBimLen = 0
						this.setChooseBimData([])
						//清除选中的模型构件
						eventBus.$emit('ViewerBIM.setSelect',this.bimComponentId,[])
						this.isRebind = true
					}
				}
			}else{
				if(type === 2){
					await this.setChooseGisData()
					this.onConfirm()
				}else{
					if(this.isRebind){
						// 关闭绘制点坐标
						eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.gisComponentId);
						this.isRebind = false
						this.onConfirm()
					}else{
						// 开启绘制点坐标
						eventBus.$emit('ViewerGIS.drawPointByMouseCustom', this.gisComponentId, async(position,selectFeature) => {
							if(position){
								let obj = [{location_gis:position}]
								if(Object.keys(selectFeature).length){
									let models = []
									for (let key in selectFeature) {
										models.push({ model_id: key, dbids:selectFeature[key] });
									}
									obj = [{location_gis:position, models:models}]
								}
								this.setChooseGisData(obj)
							}
						});
						this.isRebind = true
					}
				}
			}
		},
		/**
		 * @desc: 对比模型数据
		 */
		contrastFn (bol) {
        this.bindBimLen = 0
				let binded=JSON.parse(JSON.stringify(this.bindedBimInfo))
				let choosed=JSON.parse(JSON.stringify(this.chooseBimInfo))
				let newArr=[]
				let componentInfo = {}
				if(binded === null || binded?.length === 0){
					return choosed
				}else{
					let choosedValue = choosed[this.bimList.field]
					if(bol){
						let mergeArr=binded.concat(choosedValue)
						newArr=[mergeArr[0]]
						if(mergeArr?.length){
								mergeArr.forEach((item,i)=>{
								if(i != 0){
									let filterArr=newArr.filter((v)=>v.model_id==item.model_id)
									if(filterArr?.length===0){ 
										newArr.push(item)
									}else{
										newArr.forEach((val,j)=>{
											if(val.model_id === item.model_id){
												newArr[j].dbids = Array.from(new Set(newArr[j].dbids.concat(item.dbids)))
											}
										})
									}
								}
							})
						}
					}else{
						for (var i = 0; i < binded.length; i++) {
							for (var j = 0; j < choosedValue.length; j++) {
								if (binded[i].model_id == choosedValue[j].model_id) {
									choosedValue[j].dbids.forEach((val)=>{
										binded[i].dbids=binded[i].dbids.filter(function(cur){
											if(cur === val){
													return false
											}
											return true
										})
									})	
								}
							}
						}
						newArr = binded
					}
					if(newArr.length){
						for(let i = 0; i < newArr.length; i++){
							this.bindBimLen += newArr[i].dbids.length
						}
					}
					
					this.$set( componentInfo,this.bimList.field,newArr )
					return componentInfo
				}
		},
			/**
	 * @desc: 切换视点
	 */
		tabView (data){
			this.toggle = false
			let state = data.state
			if(Object.prototype.toString.call(data.state) === '[object String]'){
				state = JSON.parse(data.state)
			}
			const isEqual = this.loadedBimIds.length === state.model_ids.length && this.loadedBimIds.includes(...state.model_ids) && state.model_ids.every((value, index) => value === this.loadedBimIds[index]);
			if(!isEqual){
				let models = [...state.model_ids]
				//先卸载其他的
				for(let i=0;i<this.loadedBimIds.length;i++){
					if(!models.includes(this.loadedBimIds[i])){
						eventBus.$emit('ViewerBIM.unloadModel', this.bimComponentId, this.loadedBimIds[i])
						this.loadedBimIds.splice(i,1)
					} else {
						models.splice(i,1)
					}
				}
				for(let i = 0;i<models.length;i++){
					//再加载视点内模型
					eventBus.$emit('ViewerBIM.loadModel', this.bimComponentId, models[i])
				}
				this.modelLoadFun = {functionName:'tabView',data:data}
			} else {
				//回显视点
				if(state){
					eventBus.$emit('ViewerBIM.ViewState.RestoreState', this.bimComponentId, state)
				}
				//回显涂鸦
				if(data.graffiti){
					eventBus.$emit('ViewerBIM.Graffiti.open', this.bimComponentId)
					eventBus.$emit('ViewerBIM.Graffiti.setData', this.bimComponentId,data.graffiti)
				}
			}
		},

	},
	beforeDestroy() {
		//多个组件时关闭了另一个组件也监听不了，暂且先注释
		// eventBus.$off('Viewer.onViewerSelect');
		// eventBus.$off('showForm');
	}
};
</script>
<style>
	.relative{
		position: relative;
	}
	.right-info-cont .el-carousel{
		height:100%!important;
	}
	.console-from-index .input-show ,
	.console-from-index .select-show,
	.console-from-index .textarea-show,
	.console-from-index .radio-show,
	.console-from-index .date-details,
	.console-from-index .show-value{
		background:rgba(255,255,255,0.1)!important;
		color:#fff!important;
	}
	.console-from-index .user-show .show-value{
		position: relative;
		border: none!important;
		left: 4px;
	}
	.console-from-index .el-input__inner,.console-from-index  .el-textarea__inner{
		border:none!important;
		background:rgba(255,255,255,0.1)!important;
		color:#fff!important;
	}
	.console-from-index .el-button--primary{
		background: var(--el-color-primary)!important;
		border-color: var(--el-color-primary)!important;
		border-radius: 4px!important;
		color:inherit;
	}
	.console-from-index .el-radio-button__orig-radio:checked+.el-radio-button__inner{
		background: var(--el-color-primary)!important;
		border-color: var(--el-color-primary)!important;
	}
	.right-info-cont .el-empty{
		height: 100%;
	}
</style>	

<style lang="less" scoped>
	.console-form-header{
		text-align: left;
		display: flex;
		flex-direction: row;
		height: 40px;
		line-height:40px;
		color:inherit;
		justify-content:space-between;
		border-bottom:1px solid #f7f7f7;
		font-size:14px;
		margin-bottom:5px;
	}
	.form-bottom-cont{
		height: calc(100% - 46px);
	}
	.console-from-index{
		display: flex;
		flex-direction: row;
		z-index:10;
		height: 100%;
		.console-form-cont{
			height: 100%;
			width: 260px;
			text-align: left;
			font-size:14px;
			display: flex;
			flex-direction:column;
			.label-cont{
				height: 25px;
				line-height: 25px;
				.arrow-img{
					width: 12px;
					height: 12px;
					transform: rotate(-90deg);
					margin-right:10px;
				}
				.save-btn{
					border-radius: 2px!important;
					position:absolute;
					right:0;
					padding:4px 6px;
				}
			}
			.inner-form-cont{
				flex:1;
				overflow:hidden;
			}
			.refresh-view-icon{
				position: absolute;
				top: 20px;
				right: 10px;
				font-size: 18px;
			}
			.components-cont{
				padding: 12px 0 0 0;
				height: auto;
				img{
					width: 72px;
					height: 75px;
					object-fit: fill;
					margin-right:10px;
				}
				.icon-btn{
					line-height:32px;
					i{
						font-size:20px;
						color: #fff;
						border:1px solid #fff;
						padding: 4px;
						border-radius: 50%;
						margin-left:10px;
					}
				}
			}
		}
		.right-info-cont{
			height: 100%;
			flex:1;
			position: relative;
			margin-left: 10px;
			min-width: 900px;
			.right-inner-cont{
				display: flex;
				flex-direction: row;
			}
			.file-cont{
				height: 100%;
				flex:1;
			}
			.file-list{
				width: 220px;
				display: flex;
				flex-direction:column;
				overflow: hidden;
				margin-left: 10px;
				ul{
					overflow-y: auto;
					flex: 1;
				}
				li{
					height: 30px;
					color: inherit;
					font-size: 14px;
					line-height: 30px;
					text-align: left;
					display: flex;
					flex-direction: row;
					.list-name{
						flex: 1;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.list-name.active{
						color: var(--el-color-primary) !important;
					}
					.edit-icons{
						width: auto;
						display: none;
					}
				}
				li:hover{
					color: var(--el-color-primary) !important;
				}
				.edit-able-list:hover{
					.edit-icons{
						display: block;
					}
				}
			}
			.file-tab{
				position: absolute;
				width: auto;
				top:0px;
				left:10px;
				height: 27px;
				line-height: 27px;
				text-align: left;
				z-index: 10;
				.tab-item{
					width: auto;
					margin-right:10px;
					font-size: 14px;
					font-family: var(--systemFontFamily);
					font-weight: 400;
					background: rgba(0,0,0,0.6);
					padding:4px 16px;
					border-radius: 4px;
					vertical-align: middle;
					i{
						margin-right: 5px;
						position: relative;
						top:1px
					}
				}
				.tab-none{
					display: none;
				}
				.active{
					background: var(--el-color-primary)!important;
				}
				.commit-btn{
					padding:0px 8px;
					background-color:@theme;
					border-radius: 3px;
					font-size: 12px;
					color:#fff;
					position:absolute;
					right:0px;
					top:1px;
					height: 22px;
					line-height: 20px;
				}
			}
		}
	}
</style>
