import IBehaviors from './IBehaviors';

var situationData = [];
var krigingSurround = [
    {lng: 108.19026713, lat: 22.60832815},
    {lng: 108.18988121, lat: 22.60710135},
    {lng: 108.17700350, lat: 22.59588368},
    {lng: 108.17934920, lat: 22.59356219},
    {lng: 108.17850523, lat: 22.59282692},
    {lng: 108.18104693, lat: 22.59031135},
    {lng: 108.19558714, lat: 22.60297683},
    {lng: 108.19558999, lat: 22.60305961},
    {lng: 108.19041055, lat: 22.60818620},
    {lng: 108.19026713, lat: 22.60832815},
];
var krigingSurroundAB = [
    [3955.524,5689.774],
    [3883.587,5567.888],
    [3883.565,3752.284],
    [3531.048,3752.288],
    [3531.047,3633.287],
    [3149.062,3633.292],
    [3149.087,5683.298],
    [3155.572,5689.783],
    [3933.97,5689.774],
    [3955.524,5689.774]
];


export default class SituationBehaviors extends IBehaviors {
    constructor(viewer, comp, dataInterface) {
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;
        this.dataInterface = dataInterface;

        this.init();
    }

    init() {
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors() {
        const viewer = this.viewer;
        const dataInterface = this.dataInterface;
        viewer.on(viewer.EventType.plotClicked, (id) => {
            if(id.indexOf('situation_area') !== -1){
                let arr = id.split('_');
                this.comp.setResolveData("gis_situationPolygon_id", arr[arr.length - 1]);
                this.comp.setResolveData("gis_situationPolygon_childIndex", Number(arr[arr.length - 2]));
                this.comp.onAction('onSituationPolygonClick');
            }
        });
        viewer.on(viewer.EventType.plotRightClicked, (id) => {
            if(id.indexOf('situation_area') !== -1){
                let arr = id.split('_');
                this.comp.setResolveData("gis_situationPolygon_id", arr[arr.length - 1]);
                this.comp.setResolveData("gis_situationPolygon_childIndex", Number(arr[arr.length - 2]));
                this.comp.onAction('onSituationPolygonRightClick');
            }
        });
        viewer.on(viewer.EventType.markerClicked, (id) => {
            if(id.indexOf('situation_area') !== -1){
                let arr = id.split('_');
                this.comp.setResolveData("gis_situationPolygon_id", arr[arr.length - 1]);
                this.comp.setResolveData("gis_situationPolygon_childIndex", Number(arr[arr.length - 2]));
                this.comp.onAction('onSituationPolygonClick');
            }
        });

        function multiArr(arr){
            let a = 1;
            for (let i = 0;i < arr.length; i++){
                if(arr[i] instanceof Array){
                    a++;
                    multiArr(arr[i]);
                }
            }
            return a;
        }

        function getNodeType(type) {
            switch (type) {
                case 1:  //道槽区填方
                    return {fillColor: '#FF0000', alpha: 45, outline: true, outlineColor: '#ffffff', isClampToGround: false, height: 0.1};
                case 2:  //土面区填方
                    return {fillType: 'checkerboard', fillColor: '#FF0000', checkerboardColor: '#FFFFFF', checkerboardRepeat: [20, 40], alpha: 45, outline: true, outlineColor: '#ffffff', isClampToGround: false, height: 0.1};
                case 3:  //工作区填方
                    return {fillColor: '#FF0000', alpha: 45, outline: true, outlineColor: '#ffffff', isClampToGround: false, height: 0.1};
                case 4:  //一般区挖方
                    return {fillColor: '#FFC700', alpha: 45, outline: true, outlineColor: '#ffffff', isClampToGround: false, height: 0.1};
                case 5:  //特殊区挖方
                    // return {fillType: 'stripe', fillColor: '#FFC700', stripeColor: '#545403', stripeCount: 20, alpha: 45, outline: false, outlineColor: '#ffffff', isClampToGround: true};
                    return {fillColor: '#FFFFFF', alpha: 10, outline: false, outlineColor: '#ffffff', isClampToGround: true};
                case 6:  //外借土源
                    return {fillColor: '#387FFC', alpha: 45, outline: true, outlineColor: '#ffffff', isClampToGround: false, height: 0.1};
                case 7:  //机场改扩建工程填方
                    return {fillColor: '#48B26F', alpha: 45, outline: true, outlineColor: '#ffffff', isClampToGround: false, height: 0.1};
                default:
                    return {fillColor: '#FFC700', alpha: 45, outline: true, outlineColor: '#ffffff', isClampToGround: false, height: 0.1};
            }
        }

        function getAreaType(data, id) {
            let index = data.findIndex(x => x.id.toString() === id && x.shape === 'area');
            if(index === -1) return '填方';

            let type = data[index].data.type;
            switch (type) {
                case 1:
                    return '填方';
                case 2:
                    return '填方';
                case 3:
                    return '填方';
                case 4:
                    return '挖方';
                case 5:
                    return '挖方';
                case 6:
                    return '挖方';
                case 7:
                    return '填方';
                default:
                    return '填方';
            }
        }

        function getLabelFormat(text, type) {
            if(type === 6 || type === 7){
                return text;
            }else{
                if(text.indexOf('特殊') !== -1 && text.indexOf('挖方') !== -1 ){
                    let arr = text.split("");
                    arr.splice(arr.findIndex(x => x === '特'), 0, '\n');
                    return arr.join("");
                }else {
                    return text.match(/.{1,7}/g).join('\n');
                }
            }
        }

        function createSituationPolygon(data) {
            viewer.Scene.setCameraView({
                lng : 108.20188018,
                lat : 22.58546032,
                height : 3830.48,
                heading: -43.17099662608335,
                pitch : -60,
                roll : 0
            });

            let edgeList = [];
            let areaList = {};
            let hideAreaList = [];
            for (let item of data){
                if(item.shape === 'area'){
                    //创建分区
                    let {name, type, coordinate} = item.data;
                    if(!coordinate) continue;
                    if(typeof coordinate === 'string') coordinate = JSON.parse(coordinate);

                    let coordinates = [];
                    if(multiArr(coordinate) === 1){
                        //一维数组，手动绘制分区
                        coordinates.push(coordinate);
                    }else{
                        //二维数组，kml传入分区(多个)
                        coordinates = coordinate;
                    }

                    let options = getNodeType(type);

                    let labelShow = true;
                    if(type === 5){
                        hideAreaList.push(item.id);
                        labelShow = false;
                    }

                    coordinates.map((coord, index) => {
                        let center = viewer.Turf.getPolygonCentroid(coord);
                        let areaNum = viewer.Coordinate.getPolygonArea(coord);
                        let maxValueObject = coord.reduce((max, current) => {
                            return current.lng > max.lng ? current : max;
                        }, { lng: -Infinity });

                        viewer.Plot.createPlot(`situation_area_${index}_${item.id}`, {
                            plotType: 'polygon',
                            positions: coord,
                            options: options,
                            isClick: true
                        });
                        viewer.Marker.createMarker(`situation_areaLabel_${index}_${item.id}`, {
                            type: 'Label',
                            position: type === 5 ? maxValueObject : center,
                            options: {
                                text: getLabelFormat(name, type),
                                font: type === 6 || type === 7 ? 'normal 16px MicroSoft YaHei' : 'normal 14px MicroSoft YaHei',
                                color: type === 5 ? '#00ffff': '#FFFFFF',
                                outlineWidth: 0,
                                outlineColor: '#cccccc',
                                showBackground: false,
                                backgroundColor: 'rgba(255,255,255,1.0)',
                                horizontalOrigin: type === 5 ? -1 : 0,
                                verticalOrigin: type === 5 ? 1 : 0,
                                pixelOffset: type === 5 ? [-10, -10] : 0,
                                distanceDisplayCondition: [0, 6000],
                                disableDepthTestDistance: Number.POSITIVE_INFINITY
                            },
                            isClick: type !== 5
                        }, labelShow);

                        if(!areaList[item.id]) areaList[item.id] = [];
                        areaList[item.id].push({
                            index: index,
                            type: type,
                            area: areaNum,
                            center: center,  //lnglat对象
                            positions: coord //Latlng格式
                        });
                    });

                    //设置其他分区的名称隐藏，只显示面积最大分区的名称
                    if(areaList[item.id].length > 1){
                        let maxArea = areaList[item.id].reduce((max, current) => {
                            return current.area > max.area ? current : max;
                        }, { area: -Infinity });
                        for (let i = 0; i < areaList[item.id].length; i++) {
                            let {index} = areaList[item.id][i];
                            if(index !== maxArea.index){
                                viewer.Marker.showMarker(`situation_areaLabel_${index}_${item.id}`, false);
                            }
                        }
                    }
                }else{
                    //连线集合
                    edgeList.push(item);
                }
            }

            return {edgeList, areaList, hideAreaList};
        }

        function createSituationEdge(edgeList, areaList, options = {}) {
            let {labelCallback = undefined, billboardCallback = undefined, customMarkerCallback = undefined, sourceIndex = 0, targetIndex = 0, hideAreaList = []} = options;

            for (let {id, type, source, target, data} of edgeList){
                if(!areaList[source] || !areaList[target]) continue;
                let color = '#00FF94';
                if(hideAreaList.findIndex(x => x === source) !== -1 ||
                    hideAreaList.findIndex(x => x === target) !== -1) {
                    color = '#387FFC';
                }
                let pointOptions = {color: color, size: 10, disableDepthTestDistance: 1500};

                let targetCenter = areaList[target][targetIndex].center;
                let sourceCenter = areaList[source][sourceIndex].center;
                let sourceCoordinate = viewer.Coordinate.getGeoJSON(areaList[source][sourceIndex].positions);
                let sourceBound = viewer.Turf.getBoundByPoints(sourceCoordinate);
                let sourceXDelta = Number((sourceBound.east - sourceCenter.lng).toFixed(4));
                let sourceYDelta = Number((sourceBound.north - sourceCenter.lat).toFixed(4));
                let sourceD = sourceXDelta < sourceYDelta ? sourceXDelta : sourceYDelta;
                let sourcePoints = viewer.Turf.getPolygonRandomPoint(sourceCoordinate, [[targetCenter.lng, targetCenter.lat]], [1], sourceD / 4, sourceD / 4);
                let startPoint = sourcePoints[0][0];
                if(source === 77){
                    startPoint = [108.19983038, 22.61488704];
                }

                let targetCoordinate = viewer.Coordinate.getGeoJSON(areaList[target][targetIndex].positions);
                let targetBound = viewer.Turf.getBoundByPoints(targetCoordinate);
                let targetXDelta = Number((targetBound.east - targetCenter.lng).toFixed(4));
                let targetYDelta = Number((targetBound.north - targetCenter.lat).toFixed(4));
                let targetD = targetXDelta < targetYDelta ? targetXDelta : targetYDelta;
                let targetPoints = viewer.Turf.getPolygonRandomPoint(targetCoordinate, [startPoint], [1], targetD / 4, targetD / 4);
                let endPoint = targetPoints[0][0];

                let coords = [
                    {lng: startPoint[0], lat: startPoint[1]},
                    {lng: endPoint[0], lat: endPoint[1]}
                ];

                viewer.Marker.createMarker(`situation_map_startPoint_${id}`, {type: 'Point', position: coords[0], options: pointOptions});
                viewer.Marker.createMarker(`situation_map_endPoint_${id}`, {type: 'Point', position: coords[1], options: pointOptions});
                viewer.Plot.createODArcLine(`situation_map_arcLine${id}`, {
                    plotType: 'odArcLine',
                    positions: coords,
                    options: {
                        ratio: 0.1,
                        fillColor: color,
                        animationColor: '#FFFF00',
                        animationSpeed: 2,
                        alpha: 100,
                        width: 5,
                        isLabel: !!labelCallback,
                        labelList: labelCallback && labelCallback(type, data),
                        isBillboard: !!billboardCallback,
                        billboard: billboardCallback && billboardCallback(type, data, coords),
                        isCustomMarker: !!customMarkerCallback,
                        customMarkerFunc: customMarkerCallback
                    }
                });

                customMarkerCallback && customMarkerCallback(`situation_map_arcLine${id}`, {
                    type,
                    data
                })
            }
        }

        function createSituationInfoMarker(dataList, options = {}) {
            let {billboardCallback} = options;
            let total = {};
            let features = [];
            for (let key in dataList) {
                let {position, type, data} = dataList[key];
                let billboard = billboardCallback(type, data);
                viewer.Marker.createMarker(`${key}_infoMarker`, {
                    type: 'Billboard',
                    position: position,
                    options: billboard
                }, false);

                total[`${key}_infoMarker`] = {
                    position,
                    billboard
                };
                features.push({
                    id: `${key}_infoMarker`,
                    position
                });
            }

            let index = 1;
            viewer.Cluster.createPointCluster('situation_map_allCluster', {
                points: features,
                options: {
                    color: 'rgba(0,255,255,0)',
                    size: 1,
                    conditionType: 'Custom',
                    conditionOptions: {
                        pixelRange: 120
                    },
                    conditions: (position, clusterList, isRestart)=> {
                        if(isRestart) {
                            for (let key in total){
                                viewer.Marker.showMarker(key, true);
                            }
                            viewer.Marker.removeMarkerByKeyWords('situation_map_cluster');
                            index = 1;
                        }

                        if(!position && clusterList.length === 0) return;

                        for (let key of clusterList){
                            viewer.Marker.showMarker(key, false);
                        }

                        viewer.Marker.createMarker(`situation_map_cluster_${index}`, {
                            type: 'Billboard',
                            position: total[clusterList[0]].position,
                            options: total[clusterList[0]].billboard
                        });
                        index++;
                    }
                }
            });
        }

        return [
            {
                label: '[调配方案]创建分区多边形',
                desc: `
                    参数[ remove ]。 
                    根据分区数据创建分区多边形
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createSituationPolygon',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    let {
                        remove = 'false'
                    } = params;

                    if(JSON.parse(remove)){
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                    }

                    dataInterface({}, `https://nnjc-api.bimcc.com/api/nnjc/earthwork`, 'GET').then((res) => {
                        if (res.data.code === 200) {
                            let data = res.data.data;

                            createSituationPolygon(data);
                        }
                    });
                }
            },
            {
                label: '[调配方案]创建设计调配方案图',
                desc: `
                    参数[ remove ]。 
                    绘制设计调配方案态势图
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createDesignSituation',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    let {remove = 'false'} = params;
                    if (JSON.parse(remove)) {
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                        situationData = [];
                    }

                    function getLabelList(type, data) {
                        let same = JSON.stringify({
                            font: 'normal 16px MicroSoft YaHei',
                            color: '#ffffff',
                            outlineWidth: 0,
                            showBackground: true,
                            horizontalOrigin: 0,
                            distanceDisplayCondition: [0, 6000]
                        });

                        return [
                            Object.assign(JSON.parse(same), {
                                text: Number(Number(data.design_total).toFixed(3)) + 'm³',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                verticalOrigin: 1,
                                pixelOffset: [0, -2],
                                disableDepthTestDistance: Number.POSITIVE_INFINITY
                            }),
                            Object.assign(JSON.parse(same), {
                                text: Number(Number(data.distance).toFixed(2)) + '千米',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                verticalOrigin: -1,
                                pixelOffset: [0, 2],
                                disableDepthTestDistance: Number.POSITIVE_INFINITY
                            })
                        ];
                    }

                    dataInterface({}, `https://nnjc-api.bimcc.com/api/nnjc/earthwork/design`, 'GET').then((res) => {
                        if (res.data.code === 200) {
                            let data = res.data.data;
                            situationData = data;

                            let {edgeList, areaList, hideAreaList} = createSituationPolygon(data);
                            createSituationEdge(edgeList, areaList, {
                                labelCallback: getLabelList,
                                hideAreaList
                            });
                        }
                    });
                }
            },
            {
                label: '[调配方案]创建实时已完成土方调配情况图',
                desc: `
                    参数[ remove ]。 
                    绘制实时已完成土方调配情况态势图
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createFinishSituation',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    let {remove = 'false'} = params;
                    if(JSON.parse(remove)){
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                        situationData = [];
                    }

                    function getLabelList(type, data){
                        let same = JSON.stringify({
                            font: 'normal 16px MicroSoft YaHei',
                            color: '#ffffff',
                            outlineWidth: 0,
                            showBackground: true,
                            horizontalOrigin: 0,
                            distanceDisplayCondition: [0, 6000]
                        });

                        let design = Object.assign(JSON.parse(same), {
                            text: Number(Number(data.design_total).toFixed(3)) + 'm³',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            verticalOrigin: 1,
                            pixelOffset: [0, -2],
                            disableDepthTestDistance: Number.POSITIVE_INFINITY
                        });
                        let finish = Object.assign(JSON.parse(same), {
                            text: Number(Number(data.finish_total).toFixed(3)) + 'm³',
                            backgroundColor: 'rgba(4,112,233,1.0)',
                            verticalOrigin: -1,
                            pixelOffset: [0, 2],
                            disableDepthTestDistance: Number.POSITIVE_INFINITY
                        });
                        let distance = Object.assign(JSON.parse(same), {
                            text: Number(Number(data.distance).toFixed(2) ) + '千米',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            verticalOrigin: -1,
                            pixelOffset: [0, type === 1 ? 2: 28],
                            disableDepthTestDistance: Number.POSITIVE_INFINITY
                        });

                        if(type === 1){
                            return [design, distance];
                        }else{
                            return [design, finish, distance];
                        }
                    }

                    dataInterface({}, `https://nnjc-api.bimcc.com/api/nnjc/earthwork/finish`, 'GET').then((res) => {
                        if (res.data.code === 200) {
                            let data = res.data.data;
                            situationData = data;

                            let {edgeList, areaList, hideAreaList} = createSituationPolygon(data);
                            createSituationEdge(edgeList, areaList, {
                                labelCallback: getLabelList,
                                hideAreaList
                            });
                        }
                    });
                }
            },
            {
                label: '[调配方案]创建建议调配方案图',
                desc: `
                    参数[ remove ]。 
                    绘制实时建议调配方案态势图
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createAdviseSituation',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    let {remove = 'false'} = params;
                    if(JSON.parse(remove)){
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                        situationData = [];
                    }

                    function getLabelList(type, data){
                        let same = JSON.stringify({
                            font: 'normal 16px MicroSoft YaHei',
                            color: '#ffffff',
                            outlineWidth: 0,
                            showBackground: true,
                            horizontalOrigin: 0,
                            distanceDisplayCondition: [0, 6000]
                        });

                        let design = Object.assign(JSON.parse(same), {
                            text: Number(Number(data.design_total).toFixed(3)) + 'm³',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            verticalOrigin: 1,
                            pixelOffset: [0, -2],
                            disableDepthTestDistance: Number.POSITIVE_INFINITY
                        });
                        let proposal = Object.assign(JSON.parse(same), {
                            text: Number(Number(data.proposal_total).toFixed(3)) + 'm³',
                            backgroundColor: 'rgba(0,148,24,1.0)',
                            verticalOrigin: -1,
                            pixelOffset: [0, 2],
                            disableDepthTestDistance: Number.POSITIVE_INFINITY
                        });
                        let distance = Object.assign(JSON.parse(same), {
                            text: Number(Number(data.distance).toFixed(2)) + '千米',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            verticalOrigin: -1,
                            pixelOffset: [0, type === 1 ? 2: 28],
                            disableDepthTestDistance: Number.POSITIVE_INFINITY
                        });

                        if(type === 1){
                            return [design, distance];
                        }else{
                            return [design, proposal, distance];
                        }
                    }

                    dataInterface({}, `https://nnjc-api.bimcc.com/api/nnjc/earthwork`, 'GET').then((res) => {
                        if (res.data.code === 200) {
                            let data = res.data.data;
                            situationData = data;

                            let {edgeList, areaList, hideAreaList} = createSituationPolygon(data);
                            createSituationEdge(edgeList, areaList, {
                                labelCallback: getLabelList,
                                hideAreaList
                            });
                        }
                    });
                }
            },
            {
                label: '[调配方案]创建指定分区调配情况',
                desc: `
                    参数[ id, childIndex, remove ]。 
                    绘制指定分区调配情况态势图
                    id => 分区id
                    childIndex => 分区子对象索引值
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createSingleSituation',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.id) return console.warn("[参数出错]");

                    let {remove = 'false'} = params;
                    if(JSON.parse(remove)){
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                    }

                    function getBillboard(type, data){
                        let img = viewer.Image.getDivBuilderImage({
                            div: `
                            <style>
                                .div{
                                    width: 250px;
                                    height: 120px;
                                    line-height: 32px;
                                    background-color: rgba(11, 47, 90, 0.8);
                                    box-sizing: border-box;
                                    border: 2px solid rgb(0, 114, 255);
                                    border-radius: 5px;
                                    text-align: center;
                                }
                                .title{
                                    padding-top: 5px;
                                    padding-left: 10px;
                                    float: left;
                                    text-align: left;
                                    width: 100px;
                                    color: rgba(255,255,255,0.8);
                                    font-size: 14px;
                                }
                                .content{
                                    padding-top: 5px;
                                    padding-left: 10px;
                                    float: left;
                                    text-align: left;
                                    white-space: nowrap;
                                    width: 80px;
                                    color: rgba(255,255,255,1.0);
                                    font-size: 20px;
                                    font-weight: 400;
                                }
                                .unit{
                                    padding-top: 5px;
                                    padding-right: 10px;
                                    float: right;
                                    text-align: right;
                                    color: rgba(255,255,255,0.8);
                                    font-size: 14px;
                                }
                                .point{
                                    margin-left: 120px;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 5px;
                                    background-color: rgb(0, 114, 255);
                                }
                            </style>
                            <div class="div">
                                <div><span class="title">距离：</span><span class="content">${Number(Number(data.distance).toFixed(2))}</span><span class="unit">千米</span></div>
                                <div><span class="title">设计填方量：</span><span class="content">${Number(Number(data.design_total).toFixed(3))}</span><span class="unit">m³</span></div>
                                <div><span class="title">已完成填方量：</span><span class="content">${Number(Number(data.finish_total).toFixed(3))}</span><span class="unit">m³</span></div>
                            </div>
                            <div class="point"></div>`
                        });

                        return {
                            image: img,
                            pixelOffset: [25, 20],
                            disableDepthTestDistance: Number.POSITIVE_INFINITY
                        };
                    }

                    let pointList = {};
                    function addPointList(key, value) {
                        if(!pointList[key]) pointList[key] = {};

                        pointList[key] = Object.assign(pointList[key], value);
                    }

                    if(situationData.length === 0) return;

                    let {edgeList, areaList, hideAreaList} = createSituationPolygon(situationData);

                    let type = getAreaType(situationData, params.id);

                    let childIndex = params.childIndex !== undefined ? Number(params.childIndex) : 0;
                    let newEdgeList = [];
                    edgeList.forEach((x) => {
                        if (type === '填方' && x.target.toString() === params.id) {
                            newEdgeList.push(x);
                            if(hideAreaList.findIndex(m => m === x.source) !== -1){
                                areaList[x.source].map((item) => {
                                    viewer.Marker.showMarker(`situation_areaLabel_${item.index}_${x.source}`, true);
                                })
                            }
                        }else if (type === '挖方' && x.source.toString() === params.id) {
                            newEdgeList.push(x);
                        }
                    });

                    createSituationEdge(newEdgeList, areaList, {
                        // billboardCallback: getBillboard,
                        customMarkerCallback: addPointList,
                        sourceIndex: type === '填方' ? 0 : childIndex,
                        targetIndex: type === '填方' ? childIndex : 0,
                        hideAreaList
                    });

                    createSituationInfoMarker(pointList, {
                        billboardCallback: getBillboard,
                    });

                    //显示分区全部的范围线
                    for (let i = 0; i < areaList[params.id].length; i++) {
                        let {positions} = areaList[params.id][i];

                        if(positions[0].lng !== positions[positions.length - 1].lng){
                            positions = positions.concat(positions[0]);
                        }
                        viewer.Plot.createPlot(`situation_map_polyline_${i}_${params.id}`, {
                            plotType: 'polyline',
                            positions: positions,
                            options: {
                                fillColor: '#0166FF',
                                width: 4,
                                isClampToGround: false,
                                height: 0.2
                            }
                        });
                    }

                }
            },
            {
                label: '[调配方案]查看指定分区调配进度情况',
                desc: `
                    参数[ id, childIndex, remove ]。 
                    查看指定分区调配进度情况
                    id => 分区id
                    childIndex => 分区子对象索引值
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createSingleSituationInfo',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.id) return console.warn("[参数出错]");

                    let {remove = 'false'} = params;
                    if(JSON.parse(remove)){
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                    }

                    if(situationData.length === 0) return;

                    function getSpecialArea(key){
                        let specialId = -1;
                        if(key === '60' || key === '61' || key === '62'){
                            specialId = 63;
                        }else if(key === '70' || key === '71'){
                            specialId = 72;
                        }else if(key === '73' || key === '74'){
                            specialId = 75;
                        }else if(key === '76' || key === '77'){
                            specialId = 78;
                        }else if(key === '82' || key === '83'){
                            specialId = 81;
                        }else {
                            return false;
                        }

                        let i = situationData.findIndex(x => x.id === specialId && x.shape === 'area');
                        if(i === -1) return false;

                        return situationData[i];
                    }

                    let index = situationData.findIndex(x => x.id.toString() === params.id && x.shape === 'area');
                    if(index === -1) return;

                    let specialArea = getSpecialArea(params.id);

                    let {areaList} = createSituationPolygon(situationData);
                    let childIndex = params.childIndex !== undefined ? Number(params.childIndex) : 0;
                    let {center} = areaList[params.id][childIndex];


                    let div = `
                              <style>
                                .maker {
                                position: relative;
                                width: 356px;
                                }
                                .container {
                                display: flex;
                                width: 320px;
                                padding: 16px;
                                flex-direction: column;
                                align-items: center;
                                gap: 8px;
                                border-radius: 4px;
                                border: 2px solid blue;
                                background: linear-gradient(
                                    77deg,
                                    rgba(48, 150, 224, 0.3) 21.84%,
                                    rgba(0, 114, 255, 0.3) 75.6%
                                    ),
                                    rgba(0, 23, 32, 0.9);
                                }
                                .title {
                                color: white;
                                text-align: center;
                                font-family: cursive;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 25px;
                                }
                                .desc {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                height: 20px;
                                text-align: left;
                                }
                                .label {
                                width: 60%;
                                color: rgba(255, 255, 255, 0.8);
                                font-family: Source Han Sans CN;
                                font-size: 14px;
                                font-weight: 400;
                                }
                                .num {
                                width: 28%;
                                text-align: center;
                                color: white;
                                font-family: Barlow Condensed;
                                font-size: 16px;
                                font-weight: 500;
                                }
                                .m {
                                color: rgba(255, 255, 255, 0.8);
                                font-family: Source Han Sans CN;
                                font-size: 14px;
                                font-weight: 400;
                                }
                                .footer {
                                position: absolute;
                                width: 12px;
                                bottom: -80px;
                                left: 45%;
                                transform: translateX(6px);
                                }
                                .circle {
                                position: relative;
                                top: 80px;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                border: 2px solid rgba(0, 102, 255, 1);
                                background-color: white;
                                }
                                .line {
                                width: 3px;
                                margin: 0 auto;
                                height: 80px;
                                background: linear-gradient(
                                    to top,
                                    rgba(0, 102, 255, 1),
                                    rgba(0, 102, 255, 0.3)
                                );
                                }
                            </style>
                            <div class="maker">
                                <div class="container">
                                    <div class="title">${situationData[index].name}</div>
                                    <div class="desc">
                                    <div class="label">设计填方量：</div>
                                    <div class="num">${situationData[index].data.design_fill_total}</div>
                                    <div class="m">m³</div>
                                    </div>
                                    <div class="desc">
                                    <div class="label">设计挖方量${specialArea ? '（特殊挖方）' : ''}：</div>
                                    <div class="num">${specialArea ? specialArea.data.design_excavate_total : situationData[index].data.design_excavate_total}</div>
                                    <div class="m">m³</div>
                                    </div>
                                    <div class="desc">
                                    <div class="label">已完成填方量：</div>
                                    <div class="num">${situationData[index].data.finish_fill_total}</div>
                                    <div class="m">m³</div>
                                    </div>
                                     <div class="desc">
                                    <div class="label">已完成挖方量${specialArea ? '（特殊挖方）' : ''}：</div>
                                    <div class="num">${specialArea ? specialArea.data.finish_excavate_total : situationData[index].data.finish_excavate_total}</div>
                                    <div class="m">m³</div>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class="circle"></div>
                                    <div class="line"></div>
                                </div>
                            </div>
                                    `;
                    let image = viewer.Image.getDivBuilderImage({ div, width: 356, height: 255});
                    viewer.Marker.createMarker(`situation_map_areaInfo_${params.id}`, {
                        type: 'Billboard',
                        position: center,
                        options: {
                            image: image
                        }
                    });


                    //显示分区全部的范围线
                    for (let i = 0; i < areaList[params.id].length; i++) {
                        let {positions} = areaList[params.id][i];

                        if(positions[0].lng !== positions[positions.length - 1].lng){
                            positions = positions.concat(positions[0]);
                        }
                        viewer.Plot.createPlot(`situation_map_polyline_${i}_${params.id}`, {
                            plotType: 'polyline',
                            positions: positions,
                            options: {
                                fillColor: '#0166FF',
                                width: 4,
                                isClampToGround: false,
                                height: 0.2
                            }
                        });
                    }

                }
            },
            {
                label: '[调配方案]创建设计调配进度等值图',
                desc: `
                    参数[ remove ]。 
                    创建设计调配进度等值图
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createDesignKrigingPolygon',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    let {remove = 'false'} = params;
                    if(JSON.parse(remove)){
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                    }

                    viewer.Scene.setCameraView({
                        lng: 108.19947581,
                        lat: 22.58642212,
                        height: 3830.73,
                        heading: 316.82900877113485,
                        pitch: -60,
                        roll: 0
                    });

                    viewer.Plot.createPlot('situation_kriging_boundary', {
                        plotType: 'polyline',
                        positions: krigingSurround,
                        options: {
                            fillColor: '#ff0000',
                            width: 3
                        }
                    });

                    dataInterface({}, `https://nnjc-api.bimcc.com/api/nnjc/earthwork/equalHeightLineData`, 'GET').then((res) => {
                        if (res.data.code === 200) {
                            let data = res.data.data;

                            let list = data.filter(x => x.type === 0);
                            if(list.length === 0) return;

                            let image = viewer.Turf.getTurfKrigingImage(list[0].data.geometrys, krigingSurroundAB);
                            viewer.Plot.createTexturePolygon('situation_kriging_texturePolygon', {
                                plotType: 'texturePolygon',
                                positions: krigingSurround,
                                options: {
                                    image: image,
                                    stRotation: -43.3
                                }
                            });

                        }
                    });
                }
            },
            {
                label: '[调配方案]创建剩余调配进度等值图',
                desc: `
                    参数[ remove ]。 
                    创建剩余调配进度等值图
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createRemainderKrigingPolygon',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    let {remove = 'false'} = params;
                    if(JSON.parse(remove)){
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                    }

                    viewer.Scene.setCameraView({
                        lng: 108.19947581,
                        lat: 22.58642212,
                        height: 3830.73,
                        heading: 316.82900877113485,
                        pitch: -60,
                        roll: 0
                    });

                    viewer.Plot.createPlot('situation_kriging_boundary', {
                        plotType: 'polyline',
                        positions: krigingSurround,
                        options: {
                            fillColor: '#ff0000',
                            width: 3
                        }
                    });

                    dataInterface({}, `https://nnjc-api.bimcc.com/api/nnjc/earthwork/equalHeightLineData`, 'GET').then((res) => {
                        if (res.data.code === 200) {
                            let data = res.data.data;

                            let list = data.filter(x => x.type === 2);
                            if(list.length === 0) return;

                            let image = viewer.Turf.getTurfKrigingImage(list[0].data.geometrys, krigingSurroundAB);
                            viewer.Plot.createTexturePolygon('situation_kriging_texturePolygon', {
                                plotType: 'texturePolygon',
                                positions: krigingSurround,
                                options: {
                                    image: image,
                                    stRotation: -43.3
                                }
                            });

                        }
                    });
                }
            },
            {
                label: '[调配方案]创建已完成调配进度等值图',
                desc: `
                    参数[ remove ]。 
                    创建已完成调配进度等值图
                    remove => 是否清空上一次添加的对象
                    `,
                value: 'situation_createFinishKrigingPolygon',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    let {remove = 'false'} = params;
                    if(JSON.parse(remove)){
                        viewer.Plot.removePlotByKeyWords('situation');
                        viewer.Marker.removeMarkerByKeyWords('situation');
                        viewer.Cluster.removeClusterByKeyWords('situation');
                    }

                    viewer.Scene.setCameraView({
                        lng: 108.19947581,
                        lat: 22.58642212,
                        height: 3830.73,
                        heading: 316.82900877113485,
                        pitch: -60,
                        roll: 0
                    });

                    viewer.Plot.createPlot('situation_kriging_boundary', {
                        plotType: 'polyline',
                        positions: krigingSurround,
                        options: {
                            fillColor: '#ff0000',
                            width: 3
                        }
                    });

                    dataInterface({}, `https://nnjc-api.bimcc.com/api/nnjc/earthwork/equalHeightLineData`, 'GET').then((res) => {
                        if (res.data.code === 200) {
                            let data = res.data.data;

                            let list = data.filter(x => x.type === 1);
                            if(list.length === 0) return;

                            let image = viewer.Turf.getTurfKrigingImage(list[0].data.geometrys, krigingSurroundAB);
                            viewer.Plot.createTexturePolygon('situation_kriging_texturePolygon', {
                                plotType: 'texturePolygon',
                                positions: krigingSurround,
                                options: {
                                    image: image,
                                    stRotation: -43.3
                                }
                            });

                        }
                    });
                }
            },
            {
                label: '[调配方案]删除全部调配方案对象',
                desc: `
                    参数[ 无 ]。 
                    `,
                value: 'primitive_removeAllSituation',
                func: () => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    viewer.Plot.removePlotByKeyWords('situation');
                    viewer.Marker.removeMarkerByKeyWords('situation');
                    viewer.Cluster.removeClusterByKeyWords('situation');
                    situationData = [];
                }
            },
        ];
    }
}
