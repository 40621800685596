/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-12-23 14:53:01
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2024-02-02 14:50:42
 * @FilePath: \console\src\views\gantt\js\canvasTable.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/*
    fixed          normal
 ------------------------------
 | head_fixed |    head        |
 |------------|----------------|
 |            |                |
 | body_fixed |    task_body   |
 |            |                |
 ------------------------------
 */
import { generateBezier } from './bezier'
import Scrollbar from './scrollbar'
import { Rect } from './common'
import theme from './theme'
import { parseTime } from '@/utils/util'

const DIR_X = 1
const DIR_Y = 2
const SORT_NONE = 1 // 未排序
const SORT_ASC = 2 // 升序
const SORT_DESC = 3 // 降序
const TABLE_HEAD_FIXED = 1
const TABLE_HEAD = 2
const TABLE_BODY_FIXED = 3
const TABLE_BODY = 4
const FORWARD = 5 // 前锋线
const IN_SCROLL_V = 1
const IN_SCROLL_H = 2
const IN_BAR_V = 3
const IN_BAR_H = 4
const Bezier = generateBezier(0.14, 0.77, 0, 1)
let timer = null
// let editItem = null
let forwardLinePosition = [10000, 10000]// 前锋线坐标
let todayLinePosition = [10000, 10000] // 今天线坐标
let dashedLinePosition = [10000, 10000] // 虚线
let flagPosition = [10000, 10000] // 时间轴坐标
let filterImgPosition = [10000, 10000] // 筛选分部分项的图片坐标
let treeImgPosition = [10000, 10000] // 展开按钮
let InputPosition = [10000, 10000]
let forwardLineDateNum = '' // 前锋线日期
let todayDataNum = '' // 今天日期
let flagDateNum = '' // 旗子所在位置日期
let firstDateNum = '' // header初始时间
let dashedLineDateNum = '' // 虚线日期
let dragType = 'forWardLine'
let isPlaying = false
let ShiftEnter = false // Shift按下
let rightEnter = false // 鼠标右键是否按下
let isDragMode = false // 拖拽中
let baseItemWidth = 0
let hoverId = 0
let translateX = 0 // table和gantt拖拽的偏移量
let baseFixedWidth = 0
let tableContentWidth = 0 // 右侧滚动数据的总宽度
let maxPosX = 0 // 最大的拖拽距离

function getNextSortType (type) {
  switch (type) {
    case SORT_NONE:
      return SORT_ASC
    case SORT_ASC:
      return SORT_DESC
    case SORT_DESC:
      return SORT_NONE
    default:
      return SORT_NONE
  }
}

function getOrDefault (v, defaultV) {
  return v === undefined ? defaultV : v
}
function isFunction (f) {
  return typeof f === 'function'
}
function isObject (o) {
  return o !== null && typeof o === 'object'
}
function makeDelta (cur, last) {
  if (!cur || !last) {
    return { x: 0, y: 0 }
  }
  return {
    x: -(cur.clientX - last.clientX),
    y: -(cur.clientY - last.clientY)
  }
}

function attenuationCoefficient (initV, msFromBegin) {
  const MAX_V = 100
  if (initV > MAX_V) {
    initV = MAX_V
  }
  const d = msFromBegin

  const percent = d / 5000 / (initV / MAX_V)
  if (percent >= 1) {
    return 0
  }
  const v = Bezier(percent, 1, 0)
  return v
}
function valueBetween (v, min, max) {
  if (v < min) return min
  if (v > max) return max
  return v
}
/**
 * @description: 绘制树形展开收起图标
 * @param {*} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} type
 * @param {*} style
 * @return {*}
 */
function drawExpandIcon (ctx, x, y, bol = false, style) {
  ctx.save()
  ctx.strokeStyle = style.color
  ctx.lineWidth = 1.5
  ctx.lineJoin = 'round'
  ctx.beginPath()
  if (bol) {
    ctx.moveTo(x + 10, y + 3)
    ctx.lineTo(x + 14, y + 7)
    ctx.lineTo(x + 10, y + 11)
  } else {
    ctx.moveTo(x + 5, y + 6)
    ctx.lineTo(x + 9, y + 10)
    ctx.lineTo(x + 13, y + 6)
  }
  ctx.stroke()
  ctx.restore()
}
function drawTask (ctx, x, y, w, h, r, style) {
  let radius = r
  if (w < 20) {
    radius = 0
  }
  ctx.save()
  ctx.strokeStyle = 'transparent'
  ctx.lineWidth = style.pixelRatio || 1
  ctx.lineJoin = 'round'
  ctx.beginPath()
  ctx.moveTo(x, y)
  ctx.arcTo(x, y, x + w, y, radius)
  ctx.arcTo(x + w, y, x + w, y + h, radius)
  ctx.arcTo(x + w, y + h, x, y + h, radius)
  ctx.arcTo(x, y + h, x, y, radius)
  ctx.arcTo(x, y, x + w, y, radius)
  ctx.closePath()
  ctx.stroke()
  ctx.restore()
  ctx.fillStyle = style.backgroundColor
  ctx.fill()
}
/**
 * @description: 绘制预计完成的虚线部分
 * @param {*} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} r
 * @param {*} style
 * @return {*}
 */
function drawFutureTask (ctx, x, y, w, h) {
  ctx.lineWidth = 6
  ctx.strokeStyle = '#E6E6E6'
  ctx.save()
  ctx.rect(x, y, w, h)
  ctx.clip()
  for (let i = 0; i <= w / 12; i++) {
    ctx.save()
    ctx.beginPath()
    ctx.moveTo(x + 12 * i, y + h + 1)
    ctx.lineTo(x + (i + 1) * 12, y - 1)
    ctx.stroke()
    ctx.restore()
  }
  ctx.restore()
}

/**
 * @description: 绘制百分比
 * @param {*} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} r
 * @param {*} style
 * @return {*}
 */
function drawPercent (ctx, x, y, w, h, text) {
  ctx.lineWidth = 1
  ctx.strokeStyle = 'transparent'
  ctx.save()
  ctx.rect(x, y, w, h)
  ctx.beginPath()
  ctx.arcTo(x, y, x + w, y, 4)
  ctx.arcTo(x + w, y, x + w, y + h, 4)
  ctx.arcTo(x + w, y + h, x, y + h, 4)
  ctx.arcTo(x, y + h, x, y, 4)
  ctx.arcTo(x, y, x + w, y, 4)
  ctx.fillStyle = 'rgba(0,179,178,0.4)'
  ctx.fill()
  ctx.font = '13px 微软雅黑'
  ctx.fillStyle = '#fff'
  ctx.fillText(text, x + 5, y + 15)
  ctx.stroke()
  ctx.restore()
}

/**
 * @description: 绘制快递定位
 * @param {*} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} r
 * @param {*} style
 * @return {*}
 */
function drawQuickLoction (ctx, text, x, y1, rowHeight, borderCol, color, name, type, w = 20, h = 20) {
  const y = y1 + (rowHeight - 20) / 2
  ctx.lineWidth = 1
  ctx.strokeStyle = borderCol
  ctx.save()
  ctx.rect(x, y, w, h)
  ctx.beginPath()
  // ctx.arcTo(x, y, x + w, y, 4)
  // ctx.arcTo(x + w, y, x + w, y + h, 4)
  // ctx.arcTo(x + w, y + h, x, y + h, 4)
  // ctx.arcTo(x, y + h, x, y, 4)
  // ctx.arcTo(x, y, x + w, y, 4)
	ctx.roundRect(x, y, w, h, 4)
  ctx.fill()
  ctx.font = '12px 微软雅黑'
  ctx.fillStyle = color
  ctx.fillText(text, x + 3, y + 15)
  // if (onlyGantt) {
  const textWidth = ctx.measureText(name).width + 20
  if (type === 'left') {
    ctx.fillText(name, x + 40, y + 15)
  } else {
    ctx.fillText(name, x - 40 - textWidth, y + 15)
  }
  // }
  ctx.stroke()
  ctx.restore()
}
/**
 * @description: 绘制排序图标
 * @param {*} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} sortType
 * @param {*} style
 * @return {*}
 */
function drawSortIcon (ctx, x, y, w, h, sortType, style) {
  ctx.save()
  let color
  if (sortType === SORT_NONE) {
    color = style.sortArrowColor
  } else {
    color = style.sortArrowActiveColor
  }
  ctx.fillStyle = color
  ctx.strokeStyle = color
  ctx.lineWidth = style.pixelRatio || 1
  ctx.lineJoin = 'round'
  const r = (style.pixelRatio || 1) * 1
  const arrowUp = [
    { x: x + w / 2, y: y + h * 0.1 },
    { x: x + w * 0.8, y: y + h * 0.42 },
    { x: x + w * 0.2, y: y + h * 0.42 }
  ]
  ctx.beginPath()
  const startX = (arrowUp[0].x + arrowUp[1].x) / 2
  const startY = (arrowUp[0].y + arrowUp[1].y) / 2
  ctx.moveTo(startX, startY)
  ctx.arcTo(arrowUp[1].x, arrowUp[1].y, arrowUp[2].x, arrowUp[2].y, r)
  ctx.arcTo(arrowUp[2].x, arrowUp[2].y, arrowUp[0].x, arrowUp[0].y, r)
  ctx.arcTo(arrowUp[0].x, arrowUp[0].y, arrowUp[1].x, arrowUp[1].y, r)
  ctx.closePath()
  ctx.stroke()
  if (sortType === SORT_ASC) {
    ctx.fill()
  }
  const arrowDown = [
    { x: x + w / 2, y: y + h * 0.9 },
    { x: x + w * 0.8, y: y + h * 0.58 },
    { x: x + w * 0.2, y: y + h * 0.58 }
  ]
  ctx.beginPath()
  const startX1 = (arrowDown[0].x + arrowDown[1].x) / 2
  const startY1 = (arrowDown[0].y + arrowDown[1].y) / 2
  ctx.moveTo(startX1, startY1)
  ctx.arcTo(arrowDown[1].x, arrowDown[1].y, arrowDown[2].x, arrowDown[2].y, r)
  ctx.arcTo(arrowDown[2].x, arrowDown[2].y, arrowDown[0].x, arrowDown[0].y, r)
  ctx.arcTo(arrowDown[0].x, arrowDown[0].y, arrowDown[1].x, arrowDown[1].y, r)
  ctx.closePath()
  ctx.stroke()
  // 升序，亮起向下箭头
  if (sortType === SORT_DESC) {
    ctx.fill()
  }
  ctx.restore()
}
/**
 *
 * @param {*} ctx
 * @param {*} text
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} style => {color, fontSize, align, fontFamily}
 */
function drawTextInTask (ctx, text, x, y, w, h, style, row, onlyGantt) {
  const { fontSize, color, align } = style
  let bol = true
  let textX = x
  let textAlign = align
  const textCol = color
  let width = w
  // 字长于任务条的长度就放到任务条后
  if (text && text.length * 16 + 30 > width) {
    bol = false
    textX += width + 20
    textAlign = 'left'
    // textCol = color
    width = text.length * 15 + 40
  }
  ctx.save()
  ctx.rect(textX, y, width, h)
  ctx.clip()
  ctx.font = `${fontSize}px 微软雅黑`
  ctx.fillStyle = textCol
  const yOffset = y + h - (h - fontSize) / 2
  const textWidth = ctx.measureText(text).width
  let xOffset = textX
  if (bol) {
    if (textAlign === 'left') {
      xOffset = textX + 30
    } else if (textAlign === 'right') {
      xOffset = textX + (width - textWidth)
    } else {
      xOffset = textX + (width - textWidth) / 2
    }
  }
  ctx.fillText(text, xOffset, yOffset)
  ctx.restore()
  if (onlyGantt && row.children?.length) {
    // 绘制树形图标
    drawExpandIcon(ctx, xOffset - 20, yOffset - 12, row.expanded, { color: textCol })
  }
}
function drawTypeInTask (ctx, text, x, y, h, style) {
  const textWidth = ctx.measureText(text).width
  const { fontSize, color } = style
  ctx.save()
  ctx.rect(x - textWidth, y, textWidth + 20, h)
  ctx.clip()
  ctx.font = `${fontSize}px 微软雅黑`
  ctx.fillStyle = color
  const yOffset = y + h - (h - fontSize) / 2
  ctx.fillText(text, x - textWidth, yOffset)
  ctx.restore()
}
/**
 *
 * @param {*} ctx
 * @param {*} text
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} style => {color, fontSize, align, fontFamily}
 */
function drawTextInTable (ctx, text, x, y, w, h, style, row, col) {
  const { fontSize, color, align, borderColor, borderWidth, backgroundColor } = style;
  let xOffsetIcon = 0;
  ctx.save();
  ctx.rect(x, y, w, h);
  ctx.clip();
  if (backgroundColor) {
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(x, y, w, h);
  }
  ctx.font = `${fontSize}px 微软雅黑`;
  ctx.fillStyle = color || theme.COLOR;
  let xOffset = x;
  const yOffset = y + h - (h - fontSize) / 2;
  const textWidth = ctx.measureText(text).width;
  if (align === 'left') {
    xOffset = x;
    // 树形结构空格递增显示层次
    if (col?.treeIcon && row?.wbs) {
      const wbsArr = row.wbs.split('.');
      if (row?.children?.length) {
        xOffset += wbsArr.length * 20;
      } else {
        xOffset += (wbsArr.length - 1) * 20 + 5;
      }
    }
  } else if (align === 'right') {
    xOffset = x + (w - textWidth);
  } else {
    // default: center
    xOffset = x + (w - textWidth) / 2;
  }
  xOffsetIcon = xOffset - 20;
  if (col.format && col.format === 'String' && text?.length) {
    const text1 = text.replaceAll('.', '');
    if (col.treeIcon) {
      if (textWidth + xOffset > x + w) {
        const spliceN = Math.floor((x + w - xOffset) / fontSize);
        text = text.substring(0, spliceN) + '...';
      }
    } else if (text1.length * fontSize > w) {
			for(let i = text1.length - 1; i >= 0; i--) {
				if(ctx.measureText(text1.substring(0,i)).width < w){
					text = text1.substring(0,i) + '...'
					break;
				}
			}
    }
  }
  ctx.fillText(text, xOffset, yOffset - 2);
  // 绘制树形展开收起图标
  if (row?.children?.length && col?.treeIcon) {
    drawExpandIcon(ctx, xOffsetIcon, y + (h - fontSize) / 2 - 2, row.expanded, style);
  }
  ctx.strokeStyle = borderColor;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'miter';
  ctx.beginPath();
  ctx.lineWidth = borderWidth;
  ctx.moveTo(x + w, y);
  ctx.lineTo(x + w, y + h);
  ctx.lineTo(x, y + h);
  ctx.stroke();
  ctx.restore();
}
/**
 * @description: 绘制checkbox
 * @param {*} ctx
 * @param {*} type
 * @param {*} x
 * @param {*} y
 * @param {*} boxWidth
 * @param {*} boxHeight
 * @param {*} isHeader
 * @param {*} style
 * @param {*} w
 * @param {*} h
 * @return {*}
 */
function drawCheckbox (ctx, type, x, y, boxWidth, boxHeight, isHeader, style, w = 16, h = 16) {
  const { align, borderColor, color, backgroundColor } = style
  ctx.save()
  ctx.rect(x, y, boxWidth, boxHeight)
  if (backgroundColor) {
    ctx.fillStyle = backgroundColor
    ctx.fillRect(x, y, boxWidth, boxHeight)
  }
  let xOffset = x
  ctx.strokeStyle = borderColor
  ctx.lineWidth = 1
  ctx.font = '12px 微软雅黑'
  const yOffset = y + boxHeight / 2 - h / 2
  if (align === 'left') {
    xOffset = x
  } else if (align === 'right') {
    xOffset = boxWidth - w
  } else {
    xOffset = x + (boxWidth - w) / 2
  }
  if (isHeader) {
    xOffset -= 15
    ctx.fillStyle = color || theme.COLOR
    ctx.fillText('全选', xOffset + 20, yOffset + 12)
  }
  // 绘制选中状态
  if (type !== 0) {
    if (type === 1) {
      ctx.strokeStyle = '#448aff'
      ctx.beginPath()
      ctx.moveTo(xOffset + 4.5, yOffset + 7)
      ctx.lineTo(xOffset + 7.5, yOffset + 12)
      ctx.lineTo(xOffset + 12.5, yOffset + 5)
      ctx.stroke()
    } else {
      ctx.strokeStyle = '#448aff'
      ctx.beginPath()
      ctx.moveTo(xOffset + 4, yOffset + 8)
      ctx.lineTo(xOffset + 12, yOffset + 8)
      ctx.stroke()
    }
  }
  // 绘制外框
  ctx.beginPath()
	ctx.lineWidth = 0.5
	if(!isHeader){
		ctx.moveTo(xOffset + 0.5, yOffset)
		ctx.lineTo(xOffset + w + 0.5, yOffset)
		ctx.lineTo(xOffset + w + 0.5, yOffset + h)
		ctx.lineTo(xOffset + 0.5, yOffset + h)
	} else {
		ctx.moveTo(xOffset + 0.5, yOffset + 0.5)
		ctx.lineTo(xOffset + w + 0.5, yOffset + 0.5)
		ctx.lineTo(xOffset + w + 0.5, yOffset + h + 0.5)
		ctx.lineTo(xOffset + 0.5, yOffset + h + 0.5)
	}
  ctx.closePath()
  ctx.stroke()
  // 绘制表格边框
  ctx.beginPath()
  ctx.lineWidth = 0.5
  ctx.strokeStyle = borderColor
  ctx.moveTo(x + boxWidth, y)
  ctx.lineTo(x + boxWidth, y + boxHeight)
  ctx.lineTo(x, y + boxHeight)
  ctx.stroke()
  ctx.restore()
}

/**
 * @description: 绘制隐藏显示的眼睛图标
 * @param {*} ctx
 * @param {*} type
 * @param {*} x
 * @param {*} y
 * @param {*} boxWidth
 * @param {*} boxHeight
 * @param {*} isHeader
 * @param {*} style
 * @param {*} w
 * @param {*} h
 * @return {*}
 */
function drawEyeIcon (ctx, x, y, boxWidth, boxHeight, isHeader, style, w = 24, h = 24) {
  const { align, backgroundColor, borderColor, isHideTask } = style
  const openEyeImg = document.getElementById('openEye')
  const closeEyeImg = document.getElementById('closeEye')
  ctx.save()
  ctx.rect(x, y, boxWidth, boxHeight)
  if (backgroundColor) {
    ctx.fillStyle = backgroundColor
    ctx.fillRect(x, y, boxWidth, boxHeight)
  }
  let xOffset = x
  const yOffset = y + boxHeight / 2 - h / 2
  if (align === 'left') {
    xOffset = x
  } else if (align === 'right') {
    xOffset = boxWidth - w
  } else {
    xOffset = x + (boxWidth - w) / 2
  }
  if (isHeader) {
    if (isHideTask) {
      ctx.drawImage(closeEyeImg, xOffset + 3, yOffset + 3, 0.6 * w, 0.6 * h)
    } else {
      ctx.beginPath()
      ctx.strokeStyle = borderColor
      ctx.moveTo(xOffset + 5, yOffset + h / 2)
      ctx.lineTo(xOffset + 15, yOffset + h / 2)
      ctx.stroke()
    }
  } else {
    ctx.drawImage(openEyeImg, xOffset + 3, yOffset + 3, 0.6 * w, 0.6 * h)
  }
  // 绘制表格边框
  ctx.beginPath()
  ctx.strokeStyle = borderColor
  ctx.lineWidth = 0.5
  ctx.moveTo(x + boxWidth, y)
  ctx.lineTo(x + boxWidth, y + boxHeight)
  ctx.lineTo(x, y + boxHeight)
  ctx.stroke()
  ctx.restore()
}
/**
 * @description: 绘制header
 * @param {*} ctx
 * @param {*} text
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} style
 * @param {*} isEndItem 最后一个
 */
function drawTextInHeader (ctx, text, x, y, w, h, style, type, isTopItem = false, isTreeData = false, editMode = false, addAble) {
  const { fontSize, color, align, borderColor, sort, sortType, projectType } = style
  let sortIconWidth = 0
  let xOffsetIcon = 0
  ctx.save()
  ctx.rect(x, y, w, h)
  ctx.clip()
  if (style.backgroundColor) {
    ctx.fillStyle = style.backgroundColor
    ctx.fillRect(x, y, w, h)
  }
  ctx.font = `${fontSize}px 微软雅黑`
  ctx.fillStyle = color || theme.COLOR
  ctx.textAlign = 'left' // 水平对齐设置
  ctx.textBaseline = 'middle' // 垂直居中
  let xOffset = x
  const yOffset = y + h / 2
  if (sort) {
    sortIconWidth = fontSize
  }
  const textWidth = ctx.measureText(text).width
  const titleWidth = textWidth + sortIconWidth
  if (align === 'left') {
    xOffset = x
  } else if (align === 'right') {
    xOffset = x + (w - titleWidth)
  } else {
    // default: center
    xOffset = x + (w - titleWidth) / 2
  }
  xOffsetIcon = xOffset + textWidth
  // 排序
  if (sort) {
    drawSortIcon(ctx, xOffsetIcon, y + (h - fontSize) / 2, fontSize, fontSize, sortType, style)
  }
  // 操作内新增图标绘制
  if (type && type === 'handle' && addAble) {
    xOffset = xOffset + 10
    drawAddIcon(ctx, xOffset - fontSize, y + h / 2 + 2, fontSize, style)
  }
  // 筛选
  if (type && type === 'name' && projectType?.length) {
    xOffset = xOffset + 10
    drawFilterImg(ctx, xOffset + textWidth, y + h / 2 - 7)
  }
  // 收起和展开树形
  if (type && type === 'name' && isTreeData && !editMode) {
    xOffset = xOffset + 10
    drawTreeImg(ctx, x + 5, y + h / 2 - 7)
  }
  ctx.fillText(text, xOffset, yOffset + 3)
  ctx.strokeStyle = borderColor
  if (isTopItem) {
    ctx.beginPath()
    ctx.lineWidth = 0.5
    ctx.moveTo(x + w - 0.5, y)
    ctx.lineTo(x + w - 0.5, y + h - 0.5)
    ctx.lineTo(x , y + h - 0.5)
    ctx.stroke()
  } else {
    ctx.beginPath()
    ctx.lineWidth = 0.5
    ctx.moveTo(x + w, y)
    ctx.lineTo(x + w, y + h)
    ctx.lineTo(x, y + h)
    ctx.stroke()
  }
  ctx.restore()
}

/**
 * @description: 绘制任务条纹背景
 * @param {*} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} w
 * @param {*} h
 * @param {*} backgroundColor
 */
function drawBgInTaskBg (ctx, x, y, w, h, backgroundColor, borderColor) {
  ctx.save()
  ctx.rect(x, y, w, h)
  ctx.clip()
  if (backgroundColor) {
    ctx.fillStyle = backgroundColor
    ctx.fillRect(x, y, w, h)
  }
  ctx.strokeStyle = borderColor
  ctx.lineWidth = 0.5
  ctx.beginPath()
  ctx.moveTo(x + w, y)
  ctx.lineTo(x + w, y + h)
  ctx.stroke()
  ctx.restore()
}

/**
 * @description: 绘制新增图标
 * @return {*}
 */
function drawAddIcon (ctx, x, y, w, style) {
  ctx.save()
  ctx.strokeStyle = style.color
  ctx.lineWidth = 1.5
  ctx.lineJoin = 'round'
  ctx.beginPath()
  ctx.arc(x, y, w * 0.6, 0, 3 * Math.PI)
  ctx.stroke()
  ctx.beginPath()
  ctx.moveTo(x - 5, y)
  ctx.lineTo(x + w * 0.4, y)
  ctx.stroke()
  ctx.beginPath()
  ctx.moveTo(x, y - 4)
  ctx.lineTo(x, y + 5)
  ctx.stroke()
  ctx.restore()
}
/**
 * @description: 绘制过滤的图片
 * @return {*}
 */
function drawFilterImg (ctx, x, y) {
  filterImgPosition = [x + 10, y]
  const openEyeImg = document.getElementById('filterIcon')
  ctx.save()
  ctx.drawImage(openEyeImg, x + 10, y, 18, 18)
  ctx.restore()
}
/**
 * @description: 收起和展开按钮，展开第一级和全部
 * @return {*}
 */
function drawTreeImg (ctx, x, y) {
  treeImgPosition = [x, y]
  const treeImg = document.getElementById('treeIcon')
  ctx.save()
  ctx.drawImage(treeImg, x, y, 18, 18)
  ctx.restore()
}
/**
 * @description: 获取点击或移入的row，col
 * @param {*} x
 * @param {*} y
 * @param {*} cols
 * @param {*} rows
 * @param {*} option
 * @return {*}
 */
function getCell (x, y, cols, rows, option) {
  let rowIndex = 0
  let colIndex = null
  let width = 0
  let row = null
  let col = null
  rowIndex = Math.floor(y / option.rowHeight)
  if (rows && rows.length > 0 && rows[rowIndex] !== undefined) {
    row = rows[rowIndex]
  }
  for (let i = 0; i < cols.length; i++) {
    width += cols[i].widthItem
    if (width >= x) {
      // 处理操作点击
      if ((cols[i].field === 'handle' || cols[i].field === 'location') && cols[i].children?.length && row !== null) {
        width -= cols[i].widthItem
        for (var j = 0; j < cols[i].children.length; j++) {
          width += cols[i].children[j].widthItem
          if (width - x <= cols[i].children[j].widthItem && width - x > 0) {
            col = cols[i].children[j]
          }
        }
      } else {
        colIndex = i
      }
      break
    }
  }

  if (colIndex !== null) {
    col = cols[colIndex]
  }
  return { row, col }
}
/**
 * @description: 获取相差天数
 * @param {*} startDate
 * @param {*} enDate
 * @return {*}
 */
function getDaysBetween (startDate, enDate) {
  const sDate = Date.parse(startDate)
  const eDate = Date.parse(enDate)
  if (sDate > eDate) {
    return -1
  }
  // 这个判断可以根据需求来确定是否需要加上
  if (sDate === eDate) {
    return 0
  }
  const days = (eDate - sDate) / (1 * 24 * 60 * 60 * 1000)
  return days
}
/**
 * @description: 前后推几天
 * @param {*} date
 * @param {*} num
 * @return {*}
 */
function getDiffDate (date, num) {
  const milliseconds = new Date(date).getTime() - parseInt(num) * 1000 * 60 * 60 * 24
  const myDate = new Date(milliseconds)
  const Y = myDate.getFullYear()
  let M = myDate.getMonth() + 1
  let D = myDate.getDate()
  if (M < 10) {
    M = '0' + M
  }
  if (D < 10) {
    D = '0' + D
  }
  return Y + '-' + M + '-' + D
}
export class CanvasTable {
  constructor (rootElm,id) {
    if (!rootElm) return
    // 不做太多初始化，仅创建canvas元素
    this.rootElm = rootElm
    this.canvas = document.createElement('canvas')
    this.canvas.id = `canvasBox_${id}`
    this.ctx = this.canvas.getContext('2d')
    rootElm.appendChild(this.canvas)
    this.bindEvent()
    this.posX = 0
    this.posY = 0 // 当前显示位置坐标 (CSS坐标)
    this.fixedWidth = 0 // 左侧固定总列宽
    this.touchDir = null
    this.startPoint = null
    this.lastPoint = null
    this.requestFlag = false
    this.touchMoveVelocity = 0
    this.hasScrollbarV = false
    this.hasScrollbarH = false
    this.scrollbarH = null
    this.scrollbarV = null
    this.waitingRender = false
    this.sortObject = {
      field: null,
      type: SORT_NONE
    }
  }

  get top () {
    return this.posY * this.pixelRatio
  }

  set top (value) {
    const y = value / this.pixelRatio
    this.posY = valueBetween(y, 0, this.C_MAX_POS_Y)
    this.requestRefresh()
  }

  get left () {
    return this.posX * this.pixelRatio
  }

  set left (value) {
    const x = value / this.pixelRatio
    this.posX = valueBetween(x, 0, this.C_MAX_POS_X)
    this.requestRefresh()
    if (this.option.isPlayAble) {
      // 拖动滚动条将旗子所在的日期暴露出去
      clearTimeout(timer)
      timer = setTimeout(() => {
        if (flagPosition[0] !== 10000) {
          const lineNum = Math.round((flagPosition[0] + this.posX - this.fixedWidth) / baseItemWidth)
          flagDateNum = getDiffDate(firstDateNum, -lineNum)
          if (isFunction(this.getFlagDate)) {
            this.getFlagDate(flagDateNum, false, isPlaying)
          }
        }
      }, 200)
    }
  }

  get nextSortObject () {
    const sortObject = this.sortObject
    if (!sortObject) return null
    return {
      field: sortObject.field,
      type: getNextSortType(sortObject.type)
    }
  }

  // 这里有问题，需要改进，分两种转换，一种是 设计尺寸到canvas像素， 一种是css像素到canvas像素
  designToCanvasPx (x) {
    if (x === undefined || x === null) return x
    return x * this.designScale
  }

  canvasToCssPx (x) {
    if (x === undefined || x === null) return x
    return x / this.pixelRatio
  }

  cssToCanvasPx (x) {
    if (x === undefined || x === null) return x
    return x * this.pixelRatio
  }

  initOption (option) {
    const { designWidth, sortObject, top, left, forwardLine } = option
    const { ctx } = this
    const boundingRect = this.rootElm?.getBoundingClientRect()
    if (!boundingRect) {
      return
    }
    // pixelRatio: 像素比例，canvas像素比css像素的值，就是1个css像素对应几个canvas像素
    // designScale: canvas宽度与设计稿宽度的比例，不指定设计稿宽度则为1，此值用来计算实际绘制尺寸，比如线宽、文字大小等。 drawValue = valueSpecified * designScale;
    // this.pixelRatio = window.devicePixelRatio
    this.pixelRatio = 1
    this.cssWidth = boundingRect.width
    // 将baseItemWidth有补齐宽度的情况
    baseItemWidth = option.dayWidth
    this.canvasWidth = boundingRect.width * this.pixelRatio
    this.designWidth = designWidth || boundingRect.width * this.pixelRatio
    if (this.canvasWidth < this.designWidth) {
      this.canvasWidth = this.designWidth
      this.pixelRatio = this.canvasWidth / boundingRect.width
    }
    this.designScale = this.canvasWidth / this.designWidth // 在不指定designWidth的情况下，为1，值域大于等于1
    this.fillDefaultOption(option)
    let cssHeight = Math.max(boundingRect.height, option.maxHeight)
    const tableCssHeight = Math.ceil((option.list.length * option.rowHeight + option.headHeight + option.scrollbarWidth) / this.pixelRatio)
    cssHeight = Math.min(cssHeight, tableCssHeight)
    this.canvasHeight = cssHeight * this.pixelRatio
    this.cssHeight = cssHeight
    this.canvas.style.display = 'block'
    // this.canvas.style.borderTop = `1px solid ${this.option.borderColor}`
    this.canvas.style.border = `1px solid ${this.option.borderColor}`
    // viewWidth, viewHeight 主canvas可视区的宽高，排除滚动条的,减去上右边框
    this.canvas.width = this.canvasWidth - 2
    this.canvas.height = this.canvasHeight - 2
    this.forwardLine = forwardLine
    // reduce遍历相加
    tableContentWidth = option.normalCols.reduce((acc, i) => {
      return acc + getOrDefault(i.widthItem, option.colWidth)
    }, 0)
    /**
   * @description: 清空canvas
   * @return {*}
   */
    this.clearCanvas = () => {
      // translateX = 0 // table和gantt拖拽的偏移量
      // baseFixedWidth = 0
      // tableContentWidth = 0 // 右侧滚动数据的总宽度
      // maxPosX = 0
      clearInterval(timer)
      isPlaying = false
      const ctx = this.ctx
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      ctx.save()
    }
    /**
   * @description: 设置时间
   * @return {*}
   */
    this.setFlagDate = (date) => {
      if (!this.option.onlyTable && !isPlaying && firstDateNum) {
        let todayDate = parseTime(date, '{y}-{m}-{d}')
        if (todayDate < this.option.startDate || todayDate > this.option.endDate) {
          todayDate = this.option.startDate
        }
        if (todayDate >= firstDateNum && todayDate < this.option.endDate) {
          const distance = getDaysBetween(firstDateNum, todayDate)
          this.posX = distance * baseItemWidth
          flagDateNum = todayDate
          flagPosition = [0, 0]
          this.getFlagDate(todayDate, false, false)
          this.requestRefresh()
        }
      }
    }
    /**
   * @description: 隐藏任务
   * @return {*}
   */
    this.hideTasks = (arr) => {
      if (!arr.length && this.option.list?.length) {
        for (let i = 0; i < this.option.list.length; i++) {
          if (this.option.list[i].canvas_checked === 1) {
            this.option.list.splice(i, 1)
            this.changeScrollerHeight()
            i--
          } else {
            this.option.list[i].canvas_checked = 0
          }
        }
        this.requestRefresh()
      } else if (arr.length) {
        for (let i = 0; i < this.option.list.length; i++) {
          if (arr.filter((v) => v.id === this.option.list[i].id).length) {
            this.option.list.splice(i, 1)
            this.changeScrollerHeight()
            i--
          } else {
            this.option.list[i].canvas_checked = 0
          }
        }
        this.requestRefresh()
      }
    }
    /**
   * @description: 暴露出刷新供实例调用
   * @return {*}
   */
    this.requestRefresh = () => {
      if (!this.requestFlag) {
        this.requestFlag = true
        window.requestAnimationFrame(this.draw.bind(this))
      }
    }
    /**
   * @description: 暴露出播放方法供实例调用
   * @return {*}
   */
    this.playGantt = (type) => {
      if (type) {
        isPlaying = true
        let isEnd = false
        timer = setInterval(() => {
          if (this.option.dateType === 'monthAndDay') {
            this.posX += 0.8
          } else if (this.option.dateType === 'onlyYear') {
            this.posX += 0.2
          } else {
            this.posX += 0.5
          }
          if (this.posX >= tableContentWidth - (flagPosition[0] - this.fixedWidth)) {
            isPlaying = false
            clearInterval(timer)
            this.posX = 0
            isEnd = true
          }
          this.requestRefresh()
          if (this.posX % baseItemWidth === 0) {
            const lineNum = Math.round((flagPosition[0] + this.posX - this.fixedWidth) / baseItemWidth)
            flagDateNum = getDiffDate(firstDateNum, -lineNum)
            if (isFunction(this.getFlagDate)) {
              this.getFlagDate(flagDateNum, isEnd, isPlaying)
            }
          }
        }, 25)
      } else {
        isPlaying = false
        clearInterval(timer)
      }
    }
    // headHeight 和 fixedWidth 不受滚动条影响。
    // 受滚动条影响的是 viewWidth 和 viewHeight
    const { headHeight } = option
    const tableContentHeight = option.list.length * option.rowHeight
    const tableHeight = headHeight + tableContentHeight
    const fixedWidth = option.fixedCols.reduce((acc, i) => {
      // 仅表格时fixedWidth为可视宽度
      if (this.option.onlyTable && !this.option.tableFixed) {
        if (tableHeight > this.canvasHeight) {
          return this.canvasWidth - this.option.scrollbarWidth
        } else {
          return this.canvasWidth
        }
      } else if (this.option.onlyGantt) {
        return 0
      } else {
        return acc + getOrDefault(i.widthItem, option.colWidth)
      }
    }, 0)

    let tableWidth = fixedWidth + tableContentWidth
    // 补齐后面宽度
    if (tableWidth <= this.canvasWidth) {
      tableWidth = this.canvasWidth
    }
    // 仅表格
    if (this.option.onlyTable && !this.option.tableFixed) {
      tableWidth = fixedWidth
    }
    // 仅gantt
    if (this.option.onlyGantt) {
      tableWidth = tableContentWidth
    }

    // 默认没有滚动条，有type
    this.viewWidth = Number(this.canvasWidth)
    this.viewHeight = this.canvasHeight
    this.hasScrollbarV = false
    this.hasScrollbarH = false
    // 计算scrollbar， 修正viewWidth 和 viewHeight
    if (!this.hasScrollbarV && tableHeight > this.viewHeight) {
      // 内容高度超出可显示区域，需要垂直滚动条
      this.hasScrollbarV = true
      this.viewWidth = this.canvasWidth - option.scrollbarWidth
    }
    // 水平滚动条显示，需要注意是否有垂直滚动条，需要判断
    if (!this.hasScrollbarH && this.hasScrollbarV ? Number(tableWidth).toFixed(2) > this.viewWidth + option.scrollbarWidth : Number(tableWidth).toFixed(2) > this.viewWidth) {
      // 内容宽度超出可显示区域，需要水平滚动条
      this.hasScrollbarH = true
      this.viewHeight = this.canvasHeight - option.scrollbarWidth
    }
    // 增加水平滚动条会导致viewHeight变小，可能出现垂直滚动条
    if (!this.hasScrollbarV && tableHeight > this.viewHeight) {
      // 内容高度超出可显示区域，需要垂直滚动条
      this.hasScrollbarV = true
      this.viewWidth = this.canvasHeight - option.scrollbarWidth
    }

    this.tableWidth = tableWidth
    this.tableHeight = tableHeight
    if (translateX && !this.option.onlyTable) {
      if (this.option.onlyGantt) {
        this.fixedWidth = 0
      } else {
        this.fixedWidth = translateX
        if (isFunction(this.getFixWidth)) {
          this.getFixWidth(translateX)
        }
      }
    } else {
      this.fixedWidth = fixedWidth
      if (isFunction(this.getFixWidth)) {
        this.getFixWidth(fixedWidth)
      }
    }
    baseFixedWidth = fixedWidth

    this.headHeight = headHeight
    if (this.hasScrollbarV) {
      this.scrollbarV = new Scrollbar({
        ctx,
        type: Scrollbar.VER,
        scrollHeight: tableContentHeight,
        clientHeight: this.viewHeight - this.headHeight,
        rect: new Rect(
          this.viewWidth,
          this.headHeight,
          option.scrollbarWidth,
          this.viewHeight - this.headHeight
        ),
        style: {
          foregroundColor: option.scrollbarForegroundColor,
          backgroundColor: option.scrollbarBackgroundColor,
          borderColor: option.borderColor
        }
      })
    }
    if (this.hasScrollbarH) {
      this.scrollbarH = new Scrollbar({
        ctx,
        type: Scrollbar.HOR,
        scrollWidth: tableContentWidth,
        clientWidth: this.viewWidth - this.fixedWidth,
        rect: new Rect(this.fixedWidth, this.viewHeight, this.viewWidth - this.fixedWidth, option.scrollbarWidth),
        style: {
          foregroundColor: option.scrollbarForegroundColor,
          backgroundColor: option.scrollbarBackgroundColor,
          borderColor: option.borderColor
        }
      })
    }
    if (this.option.onlyGantt) {
      this.C_MAX_POS_X = this.canvasToCssPx(Math.max(tableWidth - this.viewWidth, 0))
    } else {
      this.C_MAX_POS_X = maxPosX || this.canvasToCssPx(Math.max(tableWidth - this.viewWidth, 0))
    }
    this.C_MAX_POS_Y = this.canvasToCssPx(Math.max(tableHeight - this.viewHeight, 0))

    this.waitingRender = false
    this.top = top || 0
    this.left = left || 0
    if (sortObject) {
      this.sortObject = sortObject
    }
    // 回显滚动条
    if (this.option.defaultScrollerPosition) {
      let bol = false
      // if (this.option.defaultScrollerPosition.x && this.hasScrollbarH) {
      //   bol = true
      //   this.posX = this.option.defaultScrollerPosition.x
      //   this.option.defaultScrollerPosition.x = 0
      // }
      if (this.option.defaultScrollerPosition.y && this.hasScrollbarV) {
        bol = true
        this.posY = this.option.defaultScrollerPosition.y
        this.option.defaultScrollerPosition.y = 0
      }
      if (bol) {
        this.requestRefresh()
      }
    }
  }

  fillDefaultOption (option) {
    const initValue = (v, dv) => {
      if (v === undefined || v === null) {
        return this.designToCanvasPx(dv)
      } else {
        return this.designToCanvasPx(v)
      }
    }
    option.color = getOrDefault(option.color, theme.COLOR)
    option.backgroundColor = getOrDefault(option.backgroundColor, theme.BACKGROUND_COLOR)
    option.hoverBackgroundColor = getOrDefault(option.hoverBackgroundColor, theme.HOVER_BACKGROUND_COLOR)
    option.borderColor = getOrDefault(option.borderColor, theme.BORDER_COLOR)
    option.borderWidth = initValue(option.borderWidth, 1)
    option.headColor = getOrDefault(option.headColor, option.color)
    option.headBackgroundColor = getOrDefault(option.headBackgroundColor, option.backgroundColor)
    option.rowHeight = initValue(option.rowHeight, 100)
    option.headHeight = getOrDefault(this.designToCanvasPx(option.headHeight), option.rowHeight)
    option.colWidth = initValue(option.colWidth, 200)
    option.fontSize = initValue(option.fontSize, 28)
    option.scrollbarWidth = initValue(option.scrollbarWidth, 16)
    option.maxHeight = getOrDefault(option.maxHeight, 0) // css value
    option.sortArrowColor = getOrDefault(option.sortArrowColor, theme.ARROW_COLOR)
    option.sortArrowActiveColor = getOrDefault(
      option.sortArrowActiveColor,
      theme.ARROW_ACTIVE_COLOR
    )
    option.fixedCols = []
    option.normalCols = []
    if (option.cols) {
      let totalDateWidth = 0
      let totalDays = 0
      let fixedWidth = 0
      option.cols.forEach((col) => {
        col.align = getOrDefault(col.align, 'center')
        col.titleAlign = getOrDefault(col.titleAlign, col.align)
        col.sort = getOrDefault(col.sort, false)
        col.fixed = getOrDefault(col.fixed, false)
        // this.option.itemWidth宽度
        if (!col.width && col.field === 'itemDate') {
          // 定义顶部的宽度
          if (col.children?.length) {
            let n = 0
            for (let i = 0; i < col.children.length; i++) {
              if (col.children[i].itemDays) {
                n += col.children[i].itemDays
                totalDays += col.children[i].itemDays
              }
            }
            col.widthItem = n * this.option.dayWidth
            col.itemDays = n
          } else if (this.option.dateType === 'onlyYear') {
            col.widthItem = col.itemDays * this.option.dayWidth
            totalDays += col.itemDays
          }
          totalDateWidth += col.widthItem
        } else {
          col.widthItem = this.designToCanvasPx(col.width) || option.colWidth // 每列的canvas像素宽度
        }
        if (col.fixed) {
          option.fixedCols.push(col)
          fixedWidth += Number(col.width)
        } else {
          if (option.tableFixed) {
            if (col.field !== 'itemDate') {
              option.normalCols.push(col)
            }
          } else {
            option.normalCols.push(col)
          }
        }
      })
      if ((this.option.onlyGantt && this.canvasWidth > totalDateWidth) || (this.canvasWidth > totalDateWidth + fixedWidth)) {
        // 补齐剩余宽度
        if (this.option.onlyGantt) {
          baseItemWidth = this.canvasWidth / totalDays
        } else {
          baseItemWidth = (this.canvasWidth - fixedWidth) / totalDays
        }
        option.cols.forEach((col) => {
          if (col.field === 'itemDate') {
            // 定义顶部的宽度
            if (col.children?.length) {
              col.widthItem = col.itemDays * baseItemWidth
              for (let i = 0; i < col.children.length; i++) {
                col.children[i].widthItem = baseItemWidth * col.children[i].itemDays
              }
            } else if (this.option.dateType === 'onlyYear') {
              col.widthItem = col.itemDays * baseItemWidth
            }
          }
        })
      }
    }
  }

  bindEvent () {
    this.canvas.addEventListener('wheel', (e) => {
      this.stopInertiaScroll()
      const deltaY = Math.round(e.deltaY)
      // const deltaX = Math.round(e.deltaX)
      if (ShiftEnter) {
        this.addPosX(deltaY)
        // 行内编辑的inputX轴滚动变化
        if (this.option.editMode && InputPosition[0] !== 10000 && this.option.tableFixed) {
          const inputCont = document.getElementById('InputCont')
          const inputLeft = this.fixedWidth + InputPosition[0] - this.posX
          if (inputLeft > this.fixedWidth + 20 && inputLeft < this.canvasWidth - 20) {
            inputCont.style.display = 'block'
            inputCont.style.left = inputLeft + 'px'
          } else {
            inputCont.style.display = 'none'
          }
        }
      } else {
        this.addPosY(deltaY)
        if (this.option.editMode && InputPosition[0] !== 10000) {
          const inputCont = document.getElementById('InputCont')
          const inputTop = InputPosition[1] - this.posY
          if (inputTop > this.headHeight + 20 && inputTop < this.canvasHeight - this.option.rowHeight - 20) {
            inputCont.style.display = 'block'
            inputCont.style.top = inputTop + 'px'
          } else {
            inputCont.style.display = 'none'
          }
        }
      }

      // if (ok1 && ok2) {
      //   e.preventDefault()
      //   e.stopPropagation()
      // }
    })
    this.touchHandler = this.touchHandler.bind(this)
    this.canvas.addEventListener('touchstart', this.touchHandler)
    this.canvas.addEventListener('touchmove', this.touchHandler)
    this.canvas.addEventListener('touchend', this.touchHandler)
    this.canvas.addEventListener('touchcancel', this.touchHandler)
    this.canvas.addEventListener('contextmenu', (e) => {
      e.preventDefault()
      e.stopPropagation()
    })
    this.mouseHandler = this.mouseHandler.bind(this)
    this.canvas.addEventListener('mousedown', this.mouseHandler)
    window.addEventListener('mousemove', this.mouseHandler)
    window.addEventListener('mouseup', this.mouseHandler)

    this.canvas.addEventListener('click', this.clickHandler.bind(this))
  }

  removeEventHandler () {
    window.removeEventListener('mousemove', this.mouseHandler)
    window.removeEventListener('mouseup', this.mouseHandler)
  }

  clickHandler (e) {
    const x = e.clientX
    const y = e.clientY
    const projectTypeList = document.getElementById('projectTypeList')
    const inputCont = document.getElementById('InputCont')
    if (
      this.mousedownPoint &&
      this.mousedownPoint.clientX === x &&
      this.mousedownPoint.clientY === y
    ) {
      const rect = this.canvas.getBoundingClientRect()
      const cx = Math.floor(x - rect.left)
      const cy = Math.floor(y - rect.top)
      const res = this.testInScrollbar(cx, cy)
      if (res > 0) return // 滚动条区域，忽略
      const vx = this.cssToCanvasPx(cx)
      const vy = this.cssToCanvasPx(cy)
      let rx = vx + this.left
      let ry = vy + this.top

      const { option } = this
      const { normalCols, fixedCols, list } = option
      let cell = null
      let area = null
      // 四个区域
      if (vx >= this.fixedWidth && vy >= this.headHeight) {
        rx -= this.fixedWidth
        ry -= this.headHeight
        area = TABLE_BODY
        cell = getCell(rx, ry, normalCols, list, option)
      } else if (vx >= this.fixedWidth) {
        rx -= this.fixedWidth
        area = TABLE_HEAD
        cell = getCell(rx, ry, normalCols, [], option)
      } else if (vy >= this.headHeight) {
        ry -= this.headHeight
        area = TABLE_BODY_FIXED
        cell = getCell(rx - this.posX, ry, fixedCols, list, option)
      } else {
        area = TABLE_HEAD
        cell = getCell(rx - this.posX, ry, fixedCols, [], option)
      }
      if (cell.col) {
        // 行内编辑输入框
        if (cell.row && this.option.editMode && cell.col.editAble) {
          // editItem = cell
          InputPosition = [cell.col.positionX + 18, cell.row.positionY + this.headHeight + 18]
          inputCont.value = cell.row[cell.col.field] || ''
          inputCont.style.display = 'block'
          inputCont.style.width = cell.col.width - 12 + 'px'
          if (area === 4) {
            inputCont.style.left = cell.col.positionX + this.fixedWidth - this.posX + 18 + 'px'
          } else {
            inputCont.style.left = cell.col.positionX + 18 + 'px'
          }
          inputCont.style.top = cell.row.positionY + this.headHeight - this.posY + 18 + 'px'
        }
        // 快速定位
        let quickClick = false
        if (cell.row?.isQuick !== undefined && cell.row.isQuick !== 0) {
          if (cell.row.isQuick === 2) {
            // 左侧
            if (rx >= this.posX && rx <= this.posX + 20) {
              quickClick = true
            }
          } else {
            // 右侧
            const edX = this.canvasWidth - this.fixedWidth + this.posX
            if (rx >= edX - 30 && rx <= edX) {
              quickClick = true
            }
          }
        }
        if (cell.row?.beginX !== undefined && cell.row.beginX && quickClick) {
          // // 有水平滚动时点击后X轴滚动到相应位置 && !isPlayAble
          if (this.hasScrollbarH && cell.col?.field) {
            let x = (cell.row.beginX - 30) / this.pixelRatio
            if (cell.row.actualBeginX && cell.row.actualBeginX < cell.row.beginX) {
              x = cell.row.actualBeginX - 30 / this.pixelRatio
            }
            const scrollerToX = valueBetween(x, 0, this.C_MAX_POS_X)
            if (this.posX !== scrollerToX) {
              this.posX = scrollerToX
              this.requestRefresh()
            }
          }
          if (this.option.isPlayAble) {
            // 拖动滚动条将旗子所在的日期暴露出去
            clearTimeout(timer)
            timer = setTimeout(() => {
              const lineNum = Math.round((flagPosition[0] + this.posX - this.fixedWidth) / baseItemWidth)
              flagDateNum = getDiffDate(firstDateNum, -lineNum)
              if (isFunction(this.getFlagDate)) {
                this.getFlagDate(flagDateNum, false, isPlaying)
              }
            }, 200)
          }
        }
        if ((cell.col.treeIcon && !this.option.editMode) || this.option.onlyGantt) {
          // 点击展开树形
          if (cell.row?.children?.length) {
            this.changeTreeExpand(cell.row)
          }
        } else {
          // 点击事件
          this.clickCell({ ...cell, area })
        }
      }
      if (area !== TABLE_HEAD && projectTypeList) {
        projectTypeList.style.display = 'none'
      }
      if (area === TABLE_HEAD && cell.col) {
        // 分部分项筛选
        if (cell.col.field === 'name' && filterImgPosition[0] <= cx && cx <= filterImgPosition[0] + 18) {
					if(projectTypeList){
						projectTypeList.style.display = 'block'
						projectTypeList.style.left = cx - 40 + 'px'
						projectTypeList.style.top = cy + 50 + 'px'
					}
        } else {
					if(projectTypeList){
            projectTypeList.style.display = 'none'
					}
        }
        if (cell.col.field === 'name' && treeImgPosition[0] <= cx && cx <= treeImgPosition[0] + 18 && !this.option.editMode) {
          if (isFunction(this.showTreeLevel)) {
            this.showTreeLevel()
            return
          }
        }
        const col = cell.col
        let sortType = SORT_ASC
        if (col.field === this.sortObject.field) {
          sortType = getNextSortType(this.sortObject.type)
        }
        if (col.sort) {
          if (isFunction(this.onsort)) {
            this.onsort(cell, {
              field: col.field,
              type: sortType
            })
          }
        }
      }
    }
  }

  /**
 * @description:树形结构展开和收起
 * @return {*}
 */
  changeTreeExpand (row) {
    console.time()
    const rowWbsArr = row.wbs.split('.')
    const len = this.option.list.length
    row.expanded = !row.expanded
    for (let i = len - 1; i >= 0; i--) {
      if (row.expanded) {
        if (this.option.list[i].id === row.id) {
          // 插入
          for (let j = 0; j < row.children.length; j++) {
            row.children[j].expanded = false
            if (row.canvas_checked !== -1) {
              row.children[j].canvas_checked = row.canvas_checked
            }
            this.option.list.splice(i + j + 1, 0, row.children[j])
          }
          break
        }
      } else {
        // 删除
        if (this.option.list[i]?.wbs) {
          const itemWbsArr = this.option.list[i].wbs.split('.')
          if (itemWbsArr[0] === rowWbsArr[0]) {
            if (itemWbsArr[rowWbsArr.length - 1] === rowWbsArr[rowWbsArr.length - 1] && rowWbsArr.length < itemWbsArr.length) {
              this.option.list.splice(i, 1)
              i++
            }
          }
        }
      }
    }
    this.changeScrollerHeight()
    console.timeEnd()
    this.requestRefresh()
  }

  /**
  * @description: 改变滚动条高度
  * @return {*}
  */
  changeScrollerHeight () {
    this.init({
      ...this.option,
      top: this.top,
      left: this.left
    })
    if (this.hasScrollbarV) {
      const tableContentHeight = this.option.list.length * this.option.rowHeight
      // 修改滚动最大值
      this.C_MAX_POS_Y = this.canvasToCssPx(Math.max(this.option.headHeight + tableContentHeight - this.viewHeight, 0))
      const { ctx } = this
      this.scrollbarV = new Scrollbar({
        ctx,
        type: Scrollbar.VER,
        scrollHeight: tableContentHeight,
        clientHeight: this.viewHeight - this.headHeight,
        rect: new Rect(
          this.viewWidth,
          this.headHeight,
          this.option.scrollbarWidth,
          this.viewHeight - this.headHeight
        ),
        style: {
          foregroundColor: this.option.scrollbarForegroundColor,
          backgroundColor: this.option.scrollbarBackgroundColor
        }
      })
    }
  }

  /**
   * @description: 点击表格
   * @param {*} cell
   * @return {*}
   */
  clickCell (cell) {
    if (isFunction(this.onclick)) {
      this.onclick(cell)
    }
  }

  async mouseHandler (e) {
    const dragLine = document.getElementById('dragLine')
    const rect = this.canvas.getBoundingClientRect()
    const { option, viewWidth, viewHeight, headHeight, fixedWidth, ctx } = this
    let res
    const cx = Math.floor(e.clientX - rect.left)
    const cy = Math.floor(e.clientY - rect.top)
    switch (e.type) {
      case 'mousedown':
        this.mousedownPoint = e
        this.stopInertiaScroll()
        res = this.testInScrollbar(cx, cy)
        if (e.button === 2) {
          rightEnter = true
        }
        if (res > 0 || e.button === 2) { // 右键长按拖动滚动左右
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'pointer'
          this.mouseScrolling = true
          if (res === IN_SCROLL_V) {
            this.setScrollbarPosition(cy, DIR_Y)
          } else if (res === IN_SCROLL_H) {
            this.setScrollbarPosition(cx, DIR_X)
          }
          this.firstDraggingPoint = e
          this.lastDraggingPoint = e
          this.lastDraggingArea = res
          this.draggingStartTop = this.top
          this.draggingStartLeft = this.left
        } else {
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'default'
        }
        // 触发拖拽前锋线
        if (forwardLinePosition[0] + 4 >= cx && forwardLinePosition[0] - 4 <= cx && forwardLinePosition[1] + 10 >= cy && forwardLinePosition[1] - 10 <= cy) {
          dragType = 'forWardLine'
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'col-resize'
          isDragMode = true
        }
        // 触发今天拖拽线
        // if (!dashedLineDateNum) {
        //   if (todayLinePosition[0] + 4 >= cx && todayLinePosition[0] - 4 <= cx && todayLinePosition[1] + 10 >= cy && todayLinePosition[1] - 10 <= cy) {
        //     dragType = 'halvingLine'
        //     document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'col-resize'
        //   }
        // } else {
        //   if (dashedLinePosition[0] + 4 >= cx && dashedLinePosition[0] - 4 <= cx && dashedLinePosition[1] + 10 >= cy && dashedLinePosition[1] - 10 <= cy) {
        //     dragType = 'halvingLine'
        //     document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'col-resize'
        //   }
        // }
        // 触发拖拽时间轴旗子
        if (flagPosition[0] + 25 >= cx && flagPosition[0] <= cx && flagPosition[1] + this.headHeight >= cy && flagPosition[1] <= cy) {
          dragType = 'flag'
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'col-resize'
          this.playGantt(false)
          isDragMode = true
        }
        // 触发table和gantt中线
        if (!this.option.onlyGantt && !this.option.onlyTable && this.fixedWidth + 5 >= cx && this.fixedWidth - 5 <= cx) {
          dragType = 'tableGanttLine'
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'col-resize'
          isDragMode = true
          this.playGantt(false)
        }
        break
      case 'mousemove':
        if (this.mouseScrolling) {
          const d = makeDelta(e, this.firstDraggingPoint)
          if (this.lastDraggingArea === IN_SCROLL_V || this.lastDraggingArea === IN_BAR_V) {
            const dy = d.y
            const scale = this.scrollbarV.verticalPixelRatio
            this.top = this.draggingStartTop - scale * dy * this.pixelRatio
            if (this.option.editMode && InputPosition[0] !== 10000) {
              const inputCont = document.getElementById('InputCont')
              const inputTop = InputPosition[1] - this.posY
              if (inputTop > this.headHeight + 20 && inputTop < this.canvasHeight - this.option.rowHeight - 20) {
                inputCont.style.display = 'block'
                inputCont.style.top = inputTop + 'px'
              } else {
                inputCont.style.display = 'none'
              }
            }
          } else {
            const dx = d.x
            const scale = this.scrollbarH?.horizontalPixelRatio || 0
            if (!scale) {
              return
            }
            if (rightEnter) {
              this.left = this.draggingStartLeft + scale * (dx * this.pixelRatio)
            } else {
              this.left = this.draggingStartLeft - scale * (dx * this.pixelRatio)
            }
            // 行内编辑的inputX轴滚动变化
            if (this.option.editMode && InputPosition[0] !== 10000 && this.option.tableFixed) {
              const inputCont = document.getElementById('InputCont')
              const inputLeft = InputPosition[0] - this.posX
              if (inputLeft > 20 && inputLeft < this.canvasWidth - 20) {
                inputCont.style.display = 'block'
                inputCont.style.left = inputLeft + this.fixedWidth + 'px'
              } else {
                inputCont.style.display = 'none'
              }
            }
          }
          this.lastDraggingPoint = e
        }
        if (isDragMode && dragLine) {
          dragLine.style.display = 'block'
          dragLine.style.height = viewHeight + 'px'
          dragLine.style.left = cx + 17 + 'px'
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'col-resize'
        }
        // hover效果
        var { normalCols, fixedCols, list, tableFixed } = option
        var vy = this.cssToCanvasPx(cy) + this.posY
        var toolTipCont = document.getElementById('toolTipCont')
        if (cx > 0 && cx < viewWidth && vy > this.option.headHeight + this.posY && vy < viewHeight + this.posY && !isPlaying && e.target.nodeName === 'CANVAS') {
          const cell = getCell(cx, vy - this.option.rowHeight, normalCols, list, option)
          if (cell?.row && hoverId !== cell.row.id) {
            ctx.clearRect(0, 0, viewWidth, viewHeight)
            if (tableFixed) {
              const rectFixedTable = { x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }
              this.renderTableBody(rectFixedTable, normalCols, TABLE_BODY)
              this.renderHead({ x: fixedWidth, y: 0, w: this.canvasWidth - fixedWidth, h: headHeight }, normalCols, TABLE_HEAD)
            } else {
              // 绘制任务条条纹背景
              this.renderTaskBg({ x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }, normalCols, TABLE_BODY)
              // 绘制任务条
              this.renderTaskBody({ x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }, normalCols, TABLE_BODY)
            }
            // 渲染今天
            if (!this.option.onlyTable && todayDataNum) {
              this.rendTodayLine({ x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }, normalCols, FORWARD, todayDataNum)
            }
            // 渲染拖拽的虚线
            if (dashedLineDateNum) {
              this.rendDashedLine({ x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }, normalCols, FORWARD, dashedLineDateNum)
            }
            // 渲染前锋线
            if (this.option.forwardLine && !this.option.onlyTable) {
              this.rendForwardLine({ x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }, normalCols, forwardLineDateNum)
            }
            if (!this.option.onlyTable) {
              if (this.option.onlyGantt) {
                this.renderHead({ x: 0, y: 0, w: viewWidth, h: headHeight }, normalCols, TABLE_HEAD)
              } else {
                this.renderHead({ x: fixedWidth, y: 0, w: this.canvasWidth - fixedWidth, h: headHeight }, normalCols, TABLE_HEAD)
              }
              // 渲染时间轴的旗子
              if (this.option.isPlayAble) {
                this.renderFlag()
              }
            }
            // 渲染header和table
            if (!this.option.onlyGantt) {
              this.renderTableBody({ x: 0, y: headHeight, w: fixedWidth, h: viewHeight - headHeight }, fixedCols, TABLE_BODY_FIXED)
              this.renderHead({ x: 0, y: 0, w: fixedWidth, h: headHeight }, fixedCols, TABLE_HEAD_FIXED)
              if (!this.option.onlyTable) {
                this.renderSplitPanes()
              }
            }
            // 任务移入hover效果,位置放到后面
            this.renderTaskHoverBg({ x: 0, y: headHeight, w: this.canvasWidth, h: viewHeight - headHeight }, cell.row.id)
            this.renderScrollbar()
            hoverId = cell.row.id
          }
          // tooltip
          if (this.option.tooltip && cell?.row && toolTipCont) {
            if (!cell.row.start_date || !cell.row.end_date) {
              return
            }
            toolTipCont.innerHTML = '<p>计划开工：' + cell.row.start_date.substring(0, 10) + '</p><p>计划完工：' + cell.row.end_date.substring(0, 10) + '</p>'
            toolTipCont.style.top = cy + 'px'
            toolTipCont.style.left = cx + 100 + 'px'
            toolTipCont.style.display = 'block'
          }
        } else {
          if (toolTipCont) {
            toolTipCont.style.display = 'none'
          }
        }
        break
      case 'mouseup':
        rightEnter = false
				if (dragLine) {
					dragLine.style.display = 'none'
				}
        // 前锋线
        if (document.getElementById(`canvasBox_${this.option.id}`)?.style?.cursor === 'col-resize' && dragType === 'forWardLine') {
          const moveNum = Math.round((forwardLinePosition[0] - cx) / baseItemWidth)
          forwardLineDateNum = await getDiffDate(forwardLineDateNum, moveNum)
          this.option.forwardLine = true
          this.requestRefresh()
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'default'
          isDragMode = false
        }
        if (document.getElementById(`canvasBox_${this.option.id}`)?.style.cursor === 'pointer') {
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'default'
        }
        // 时间分割虚线
        if (document.getElementById(`canvasBox_${this.option.id}`)?.style.cursor === 'col-resize' && dragType === 'halvingLine') {
          let moveNum = 0
          if (dashedLineDateNum) {
            moveNum = Math.round((dashedLinePosition[0] - cx) / baseItemWidth)
            dashedLineDateNum = await getDiffDate(dashedLineDateNum, moveNum)
          } else {
            moveNum = Math.round((todayLinePosition[0] - cx) / baseItemWidth)
            dashedLineDateNum = await getDiffDate(todayDataNum, moveNum)
          }
          if (dashedLineDateNum === todayDataNum) {
            dashedLineDateNum = ''
            dashedLinePosition = [10000, 10000]
          }
          this.requestRefresh()
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'default'
          isDragMode = false
        }
        // 时间轴旗子
        if (document.getElementById(`canvasBox_${this.option.id}`)?.style?.cursor === 'col-resize' && dragType === 'flag') {
          const lineNum = Math.round((cx + this.posX - this.fixedWidth) / baseItemWidth)
          flagPosition[0] = cx
          flagDateNum = getDiffDate(firstDateNum, -lineNum)
          if (isFunction(this.getFlagDate)) {
            this.getFlagDate(flagDateNum, false, isPlaying)
          }
          this.requestRefresh()
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'default'
          isDragMode = false
        }
        // table和gantt拖拽
        if (document.getElementById(`canvasBox_${this.option.id}`)?.style?.cursor === 'col-resize' && dragType === 'tableGanttLine') {
          translateX = cx
          if (this.hasScrollbarH) {
            this.C_MAX_POS_X = this.C_MAX_POS_X - (this.fixedWidth - cx)
            maxPosX = this.C_MAX_POS_X
            this.scrollbarH = new Scrollbar({
              ctx,
              type: Scrollbar.HOR,
              scrollWidth: tableContentWidth,
              clientWidth: this.viewWidth - cx,
              rect: new Rect(cx, this.viewHeight, this.viewWidth - cx, this.option.scrollbarWidth),
              style: {
                foregroundColor: this.option.scrollbarForegroundColor,
                backgroundColor: this.option.scrollbarBackgroundColor,
                borderColor: this.option.borderColor
              }
            })
          }
          this.fixedWidth = cx
          if (this.option.isPlayAble) {
            // 从新计算日期
            let lineNum = 0
            if (baseFixedWidth < this.fixedWidth) {
              lineNum = Math.round((cx + this.posX - this.fixedWidth) / baseItemWidth)
            } else {
              lineNum = Math.round((flagPosition[0] + this.posX - this.fixedWidth) / baseItemWidth)
            }
            flagDateNum = getDiffDate(firstDateNum, -lineNum)
            if (isFunction(this.getFlagDate)) {
              this.getFlagDate(flagDateNum, false, isPlaying)
            }
          }
          // 将值传递给伸展和收起图标
          if (isFunction(this.getFixWidth)) {
            this.getFixWidth(cx)
          }
          this.requestRefresh()
          document.getElementById(`canvasBox_${this.option.id}`).style.cursor = 'default'
        }
        this.mouseScrolling = false
        isDragMode = false
        break
    }
  }

  touchHandler (e) {
    // clientXY - 当前视口
    // pageXY - 当前页面
    // screen - 显示屏幕坐标
    let point, delta
    let preventDefault = true
    let rect, cx, cy, res
    switch (e.type) {
      case 'touchstart':
        this.stopInertiaScroll()
        point = e.touches[0]
        // 在滚动条区域，不处理
        rect = this.canvas.getBoundingClientRect()
        cx = Math.floor(point.clientX - rect.left)
        cy = Math.floor(point.clientY - rect.top)
        res = this.testInScrollbar(cx, cy)
        if (res > 0) {
          return
        }
        this.lastTouchEventTimpStamp = Date.now()
        this.touchDir = null
        this.startPoint = point
        this.lastPoint = point
        this.touchMoveVelocity = 0
        this.touching = true
        break
      case 'touchmove':
        if (!this.touching) return
        point = e.touches[0]
        delta = makeDelta(point, this.lastPoint)
        if (!this.touchDir) {
          const dx = Math.abs(delta.x)
          const dy = Math.abs(delta.y)
          if (dx > dy && dx > 4) {
            this.touchDir = DIR_X
          } else if (dy > dx && dy > 4) {
            this.touchDir = DIR_Y
          }
        }
        if (this.touchDir) {
          this.lastPoint = point
        }
        if (this.touchDir === DIR_Y) {
          preventDefault = this.addPosY(delta.y)
        } else if (this.touchDir === DIR_X) {
          preventDefault = this.addPosX(delta.x)
        }
        // 在这里求速度，根据point 和 lastPoint所发生的时间和像素距离  x px / 100 ms
        if (this.touchDir) {
          const d =
            ((this.touchDir === DIR_X ? delta.x : delta.y) * 100) /
            (Date.now() - this.lastTouchEventTimpStamp)
          this.touchMoveVelocity = Math.floor(this.touchMoveVelocity * 0.3 + d * 0.7)
        }
        break
      case 'touchend':
        point = e.touches[0]
        delta = makeDelta(point, this.lastPoint)
        if (this.touchDir === DIR_Y) {
          this.addPosY(delta.y)
        } else if (this.touchDir === DIR_X) {
          this.addPosX(delta.x)
        }
        if (isFinite(this.touchMoveVelocity)) {
          this.startInertiaScroll(this.touchMoveVelocity, this.touchDir)
        }
        this.touchDir = null
        this.touching = false
        this.lastPoint = null
        this.touchMoveVelocity = null
        break
      case 'touchcancel':
        this.touching = false
        this.touchDir = null
        break
    }
    // 如果不阻止默认行为，则滑动列表时，整个页面也滑动。
    if (e.type === 'touchmove' && preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  testInScrollbar (cx, cy) {
    if (!this.hasScrollbarV && !this.hasScrollbarH) {
      return 0
    }
    const x = cx * this.pixelRatio
    const y = cy * this.pixelRatio
    if (this.scrollbarV) {
      const res = this.scrollbarV.testIn(x, y)
      if (+res === 1) {
        return IN_SCROLL_V // 垂直滚动条区域
      } else if (+res === 2) {
        return IN_BAR_V // 垂直滚动条滑块
      }
    }
    if (this.scrollbarH) {
      const res = this.scrollbarH.testIn(x, y)
      if (+res === 1) {
        return IN_SCROLL_H
      } else if (+res === 2) {
        return IN_BAR_H
      }
    }
    return 0
  }

  // 带惯性的滚动
  inertiaScroll (v, dir) {
    // v = px / 5ms
    window.requestAnimationFrame((highT) => {
      // t ms
      const t = Math.floor(highT)
      if (!this.inertiaBeginTimeStamp) {
        this.inertiaBeginTimeStamp = t
      }
      if (!this.lastInertiaTimeStamp) {
        this.lastInertiaTimeStamp = t
      }
      const deltaT = (t - this.lastInertiaTimeStamp) / 100 // 转为100ms单位
      const c = attenuationCoefficient(Math.abs(v), t - this.inertiaBeginTimeStamp)
      const s = Math.floor(deltaT * v * c * 8) // 乘以一个以时间和初始速度相关的衰减值, *5
      if (Math.abs(s) >= 1) {
        this.lastInertiaTimeStamp = t
        const ok = dir === DIR_X ? this.addPosX(s) : this.addPosY(s)
        if (!ok) {
          this.inertia = false
        }
      } else if (deltaT !== 0) {
        this.inertia = false
      }
      if (this.inertia) {
        this.inertiaScroll(v, dir)
      } else {
        this.inertiaBeginTimeStamp = null
        this.lastInertiaTimeStamp = null
        this.touchDir = null
      }
    })
  }

  startInertiaScroll (delta, dir) {
    this.inertia = true
    this.inertiaScroll(delta, dir)
  }

  stopInertiaScroll () {
    this.inertia = false
  }

  // dir = DIR_X / DIR_Y
  setScrollbarPosition (px, dir) {
    const d = px * this.pixelRatio
    let offsetPx
    if (dir === DIR_Y) {
      if (this.scrollbarV) {
        const scrollTop = this.scrollbarV.setPosition(0, d)
        offsetPx = Math.floor(scrollTop / this.pixelRatio)
        this.addPosY(offsetPx - this.posY)
      }
    } else if (dir === DIR_X) {
      if (this.scrollbarH) {
        const scrollLeft = this.scrollbarH.setPosition(d, 0)
        offsetPx = Math.floor(scrollLeft / this.pixelRatio)
        this.addPosX(offsetPx - this.posX)
      }
    }
  }

  addPosX (delta) {
    let ok = true
    this.posX += delta
    if (this.posX < 0) {
      this.posX = 0
      ok = false
    }
    if (this.posX > this.C_MAX_POS_X) {
      this.posX = this.C_MAX_POS_X
      ok = false
    }
    this.requestRefresh()
    return ok
  }

  addPosY (delta) {
    let ok = true
    this.posY += delta
    if (this.posY < 0) {
      this.posY = 0
      ok = false
    }
    if (this.posY > this.C_MAX_POS_Y) {
      this.posY = this.C_MAX_POS_Y
      ok = false
    }
    this.requestRefresh()
    return ok
  }

  /**
   * @description: 渲染滚动条
   * @return {*}
   */
  renderScrollbar () {
    // 画交界区域
    if (this.hasScrollbarV && this.hasScrollbarH) {
      const barWidth = this.option.scrollbarWidth
      const ctx = this.ctx
      // ctx.fillStyle = this.option.scrollbarBackgroundColor || theme.SCROLL_BACKGROUND_COLOR
      ctx.fillStyle = '#fff'
      ctx.fillRect(this.viewWidth - 2, this.viewHeight, barWidth, barWidth)
    }
    // 垂直滚动条
    if (this.hasScrollbarV) {
      this.scrollbarV.scrollTop = this.top
    }
    // 水平滚动条
    if (this.hasScrollbarH) {
      this.scrollbarH.scrollLeft = this.left
    }
  }

  translateContext (ctx, id) {
    const { top, left, fixedWidth, headHeight } = this
    ctx.moveTo(0, 0)
    switch (id) {
      case TABLE_BODY_FIXED:
        ctx.translate(0, -top + headHeight)
        return
      case TABLE_BODY || FORWARD:
        ctx.translate(-left + fixedWidth, -top + headHeight)
        return
      case TABLE_HEAD_FIXED:
        ctx.translate(0, 0)
        return
      case TABLE_HEAD:
        ctx.translate(-left + fixedWidth, 0)
        return
      default:
        return {}
    }
  }

  /**
   * @description: 绘制表格内容部分，不含任务部分
   * @return {*}
   */
  renderTableBody (rect, cols, canvasId) {
    const { list, color, rowHeight, borderColor, borderWidth, backgroundColor } = this.option
    const { ctx } = this
    let x = 0
    let y = 0
    ctx.save()
    // 盖一层底色
    ctx.fillStyle = '#fff'
    if (translateX && !this.option.onlyTable) {
      ctx.fillRect(rect.x, rect.y, translateX, rect.h + this.option.scrollbarWidth)
      ctx.rect(rect.x, rect.y, translateX, rect.h)
    } else {
      ctx.fillRect(rect.x, rect.y, rect.w, rect.h + this.option.scrollbarWidth)
      ctx.rect(rect.x, rect.y, rect.w, rect.h)
    }

    ctx.clip()
    this.translateContext(ctx, canvasId)
    const bodyTop = this.top
    const bodyBottom = this.top + this.viewHeight - this.headHeight
    const maxShowLen = Math.floor((this.viewHeight - this.headHeight) / rowHeight) + 1
    const minShowLen = Math.floor(this.posY / rowHeight)
    list.forEach((row, index) => {
      x = 0
      if (y + rowHeight < bodyTop || y > bodyBottom + rowHeight) {
        y += rowHeight
        return
      }
      cols.forEach((col) => {
        let fontSize = this.option.fontSize
        let textColor = color
        let text
        let width = col.widthItem
        // onlyTable模式指定的col宽度为auto 或者拖拽超过原来的宽度
        if ((this.option.onlyTable && col.widthAuto && !this.option.tableFixed) || (!this.option.onlyTable && col.widthAuto && baseFixedWidth < this.fixedWidth)) {
          let surplusWidth = 0
          cols.forEach((dd, j) => {
            if (!dd.widthAuto) {
              surplusWidth += dd.widthItem
            }
            if (j === cols.length - 1) {
              if (baseFixedWidth < this.fixedWidth) {
                col.width = col.widthItem = width = this.fixedWidth - surplusWidth
              } else {
                col.width = col.widthItem = width = this.canvasWidth - surplusWidth
              }
            }
          })
        }
        if (isFunction(col.draw)) {
          const ok = col.draw(row, ctx, new Rect(x, y, width, rowHeight), this.designScale)
          if (!ok) {
            this.waitingRender = true
          }
        } else {
          if (isFunction(col.formatter)) {
            text = col.formatter(row)
          } else {
            text = row[col.field]
          }
          // 时间格式YYYY-MM-DD
          if (col.format) {
            if (col.format === 'Model') {
              if (row[col.field] && row[col.field].length) {
                textColor = '#409eff'
                text = '已绑定(' + row.componentNum + ')'
              } else {
                text = '未绑定'
              }
            } else {
              if (text) {
                if (col.format === 'Date') {
                  if (text === '0000-00-00 00:00:00') {
                    text = '-'
                  } else if (col.field === 'daily_end_date' || col.field === 'actual_end_date') {
                    if (Number(row.daily_over) >= 1 || col.field === 'actual_end_date') {
                      text = parseTime(text, '{y}-{m}-{d}')
                    } else {
                      text = '-'
                    }
                  } else {
                    text = parseTime(text, '{y}-{m}-{d}')
                  }
                } else if (col.format === 'Number') {
                  text = Number(text).toFixed(2)
                } else if (col.format === 'Percent') {
                  // 百分比
                  text = (text * 100).toFixed(2) + '%'
                }
              } else {
                text = '-'
              }
            }
          }
          if (isObject(text)) {
            const o = text
            text = o.text
            textColor = o.color || textColor
            fontSize = o.fontSize || fontSize
          }
          // 只渲染可视范围内得数据
          if (index <= maxShowLen + minShowLen && index >= minShowLen) {
            if (col.type && col.type === 'Icon') {
              drawEyeIcon(ctx, x - 0.5, y - 0.5, width, rowHeight, false, {
                fontSize,
                align: col.align,
                borderColor,
                color: textColor,
                backgroundColor: backgroundColor
              })
            } else if (col.type && col.type === 'selection') {
              // 绘制全选框
              drawCheckbox(ctx, row.canvas_checked, x - 0.5, y - 0.5, width, rowHeight, false, {
                fontSize,
                align: col.align,
                borderColor: borderColor,
                color: textColor,
                backgroundColor: backgroundColor
              })
            } else if (col.field === 'handle' && col.children?.length) {
              // 绘制操作
              for (let i = 0; i < col.children.length; i++) {
                drawTextInTable(ctx, col.children[i].title, x + (i * width / col.children.length), y, width / col.children.length, rowHeight, {
                  fontSize,
                  color: col.children[i].color ? col.children[i].color : '#409eff',
                  align: col.treeIcon ? 'left' : col.align,
                  borderColor,
                  borderWidth,
                  backgroundColor: backgroundColor
                }, row, col)
              }
            } else if (col.field === 'location' && col.children?.length) {
              // 绘制绑定解绑
              if (row.componentNum === 0) {
                drawTextInTable(ctx, col.children[0].title, x, y, width, rowHeight, {
                  fontSize,
                  color: '#409eff',
                  align: col.treeIcon ? 'left' : col.align,
                  borderColor,
                  borderWidth,
                  backgroundColor: backgroundColor
                }, row, col)
              } else {
                for (let i = 0; i < col.children.length; i++) {
                  drawTextInTable(ctx, col.children[i].title, x + (i * width / col.children.length), y, width / col.children.length, rowHeight, {
                    fontSize,
                    color: col.children[i].color ? col.children[i].color : '#409eff',
                    align: col.treeIcon ? 'left' : col.align,
                    borderColor,
                    borderWidth,
                    backgroundColor: backgroundColor
                  }, row, col)
                }
              }
            } else {
              drawTextInTable(ctx, text || '-', x, y, width, rowHeight, {
                fontSize,
                color: textColor,
                align: col.treeIcon ? 'left' : col.align,
                borderColor,
                borderWidth,
                backgroundColor: backgroundColor
              }, row, col)
            }
            // 这个格子的xy坐标存入，方便行内编辑显示输入框
            col.positionX = x
            row.positionY = y
          }
        }
        x += width
      })
      y += rowHeight
    })
    ctx.restore()
  }

  // 表格和gantt分割线
  renderSplitPanes () {
		const { ctx } = this
    ctx.save()
    ctx.clearRect(this.fixedWidth, 0, 0.5, this.viewHeight)
    ctx.rect(this.fixedWidth, 0, 0.5, this.viewHeight)
    ctx.strokeStyle = this.option.borderColor
    ctx.lineWidth = 0.5
    ctx.shadowOffsetX = 3
    ctx.shadowOffsetY = 0
    ctx.shadowBlur = 5
    ctx.shadowColor = '#808080'
    // 拖拽线绘制
    ctx.beginPath()
    ctx.moveTo(this.fixedWidth - 0.5, 0)
    ctx.lineTo(this.fixedWidth - 0.5, this.viewHeight)
    ctx.stroke()
    // ctx.lineWidth = 1
    // ctx.moveTo(this.fixedWidth - 5.5, this.viewHeight / 2 - 30)
    // ctx.lineTo(this.fixedWidth - 5.5, this.viewHeight / 2)
    // ctx.stroke()
  }

  /**
   * @description: 绘制任务部分
   * @return {*}
   */
  renderTaskBody (rect, cols, canvasId) {
    const { list, color, rowHeight, fontSize } = this.option
    const { ctx } = this
    let y = 0
    ctx.save()
    ctx.rect(rect.x, rect.y, rect.w, rect.h)
    this.translateContext(ctx, canvasId)
    const bodyTop = this.top
    const bodyBottom = this.top + this.viewHeight - this.headHeight
    const maxShowLen = Math.floor((this.viewHeight - this.headHeight) / rowHeight)
    const minShowLen = Math.floor(this.posY / rowHeight)
    const halvingLine = dashedLineDateNum || todayDataNum // 分割线，有虚线就取虚线，没有就取今天的日期
    list.forEach((row, index) => {
      const rowStartDate = row.start_date?.substring(0, 10) || ''
      const rowEndDate = row.end_date?.substring(0, 10) || ''
      const taskBetween = getDaysBetween(rowStartDate, rowEndDate)
      let firstDate = this.option.startDate
      let taskBgColor = '#CBE3FE'
      let actualBgColor = '#337EFF'
      let priority = ''
      if (cols?.length) {
        if (cols[0].children?.length) {
          firstDate = cols[0].children[0].fullDate
        } else {
          firstDate = cols[0].fullDate
        }
      }
      const startBetween = getDaysBetween(firstDate, rowStartDate)
      const taskWidth = (taskBetween + 1) * baseItemWidth || 0
      const taskX = (startBetween * baseItemWidth) || 0
      if (y + rowHeight < bodyTop || y > bodyBottom) {
        y += rowHeight
        return
      }
      // 只渲染可视范围内得数据
      if (index <= maxShowLen + minShowLen && index >= minShowLen) {
        row.beginX = taskX
        row.endX = taskX + taskWidth
        row.rowY = y
        row.isQuick = 0
        // 绘制实际进度
        row.actualBeginX = 0
        row.actualLong = 0
        row.isFutureTask = false
        if (row.lagging) {
          if (Number(row.lagging) > 0) priority = '滞后'
          if (Number(row.lagging) < 0) priority = '提前'
        }
        if (rowEndDate < halvingLine || (rowStartDate <= halvingLine && rowEndDate >= halvingLine)) {
          if (row[this.option.actualStartField] && row[this.option.actualStartField] !== '0000-00-00 00:00:00' && row[this.option.actualEndField] && row[this.option.actualEndField] !== '0000-00-00 00:00:00') {
            // row.daily_end_date不是实际完成时间，要根据row.daily_over完成百分比是否大于1判断
            if (row[this.option.actualEndField].substring(0, 10) <= halvingLine) {
              if (row.daily_over && Number(row.daily_over) >= 1) {
                if (row[this.option.actualEndField].substring(0, 10) > rowEndDate) {
                  if (!priority) {
                    // 滞后
                    actualBgColor = '#DD2121'
                    taskBgColor = '#FFDDDD'
                    const betweenDays = getDaysBetween(rowEndDate, row[this.option.actualEndField].substring(0, 10))
                    priority = `滞后${betweenDays}天完成`
                  } else {
                    if (priority === '提前') {
                      actualBgColor = '#2EBF76'
                      taskBgColor = '#B4EEB4'
                    }
                  }
                } else if (row[this.option.actualEndField].substring(0, 10) < rowEndDate) {
                  if (!priority) {
                    const betweenDays = getDaysBetween(row[this.option.actualEndField].substring(0, 10), rowEndDate)
                    priority = `提前${betweenDays}天完成`
                    actualBgColor = '#2EBF76'
                    taskBgColor = '#B4EEB4'
                  } else {
                    if (priority === '滞后') {
                      actualBgColor = '#DD2121'
                      taskBgColor = '#FFDDDD'
                    }
                  }
                }
              } else {
                if (priority === '提前') {
                  actualBgColor = '#2EBF76'
                  taskBgColor = '#B4EEB4'
                } else if (priority === '滞后') {
                  actualBgColor = '#DD2121'
                  taskBgColor = '#FFDDDD'
                }
              }
            }
          } else {
            // 无实际进度
            if (rowEndDate < halvingLine) {
              // 滞后
              if (!priority) {
                actualBgColor = '#DD2121'
                // taskBgColor = '#FFDDDD'
                priority = '无实际开工、完工'
              } else if (priority === '滞后') {
                taskBgColor = '#FFDDDD'
              } else if (priority === '提前') {
                taskBgColor = '#B4EEB4'
              }
            } else {
              if (!priority) {
                priority = '无实际开工、完工'
              } else if (priority === '滞后') {
                taskBgColor = '#FFDDDD'
              } else if (priority === '提前') {
                taskBgColor = '#B4EEB4'
              }
            }
          }
        }
        drawTask(ctx, taskX, y + 6, taskWidth, rowHeight - 12, 0, {
          borderWidth: 1,
          color: color,
          borderColor: 'transparent',
          backgroundColor: taskBgColor
        })
        // 绘制实际进度
        if (row[this.option.actualStartField] && row[this.option.actualStartField] !== '0000-00-00 00:00:00' && row[this.option.actualEndField] && row[this.option.actualEndField] !== '0000-00-00 00:00:00' && row.daily_over && row.daily_over !== null && row[this.option.actualStartField].substring(0, 10) <= halvingLine) {
          const actualStartBetween = getDaysBetween(firstDate, row[this.option.actualStartField].substring(0, 10))
          let actualBetweenDays = 0
          const dailyOver = Number(row.daily_over)
          if (dailyOver >= 1) {
            actualBetweenDays = getDaysBetween(row[this.option.actualStartField].substring(0, 10), row[this.option.actualEndField].substring(0, 10)) + 1
            row.actualBeginX = actualStartBetween * baseItemWidth
            row.actualLong = actualBetweenDays * baseItemWidth
            drawTask(ctx, row.actualBeginX, row.rowY + rowHeight / 3 - 2, row.actualLong, rowHeight / 6 * 2 + 4, 0, {
              borderWidth: 1,
              color: color,
              borderColor: 'transparent',
              backgroundColor: actualBgColor
            })
          } else {
            // 有实际开始，但完成率不足100%,绘制今天滞后的预计任务（虚线）
            actualBetweenDays = getDaysBetween(row[this.option.actualStartField].substring(0, 10), halvingLine)
            row.actualBeginX = actualStartBetween * baseItemWidth
            drawTask(ctx, row.actualBeginX, row.rowY + rowHeight / 3 - 2, actualBetweenDays * baseItemWidth, rowHeight / 6 * 2 + 4, 0, {
              borderWidth: 1,
              color: color,
              borderColor: 'transparent',
              backgroundColor: actualBgColor
            })
            // const futrueActualDays = Math.ceil(dailyOver * 100 / actualBetweenDays * (1 - dailyOver) * 100) //根据实际进度和完成百分比算未来天数
            // const futrueActualDays = Math.ceil(row.lagging) //根据滞后工期算未来天数
            const futrueActualDays = Math.ceil(row.residue_day || 0) // 根据后端计算返回的数据
            // 预计结束日期
            const futrueEndDate = parseTime(new Date(halvingLine).getTime() + parseInt(futrueActualDays) * 1000 * 60 * 60 * 24, '{y}-{m}-{d}')
            const futureStartX = getDaysBetween(firstDate, halvingLine) * baseItemWidth
            row.actualLong = actualBetweenDays * baseItemWidth + futrueActualDays * baseItemWidth
            row.isFutureTask = true // 绘制未来的虚线任务
            // if (rowStartDate <= halvingLine && rowEndDate >= halvingLine) {
            //   // 计划完成和今天交叉的任务的
            //   const innerDays = getDaysBetween(halvingLine, rowEndDate)
            //   console.log(innerDays, 'innerdays')
            //   drawFutureTask(ctx, futureStartX, row.rowY + rowHeight / 3 - 2, (futrueActualDays + innerDays) * baseItemWidth, rowHeight / 6 * 2 + 4, row.name)
            //   if (priority && priority.includes('滞后')) {
            //     priority = `预计滞后${Math.ceil(row.lagging)}天完成`
            //   }
            // } else {
            // 计划完成在今天之前完成的
            const planEndToFutrueEndDays = getDaysBetween(rowEndDate, futrueEndDate)
            const residueRatio = ((1 - dailyOver) * 100).toFixed(2)
            drawFutureTask(ctx, futureStartX + 3, row.rowY + rowHeight / 3 - 2, futrueActualDays * baseItemWidth, rowHeight / 6 * 2 + 4, residueRatio + '%')
            // 绘制百分比
            drawPercent(ctx, futureStartX + futrueActualDays * baseItemWidth, row.rowY + 9, 55, rowHeight - 18, residueRatio + '%')
            if (priority && priority.includes('滞后')) {
              priority = `预计滞后${planEndToFutrueEndDays}天完成`
            }
            // }
          }
        }
        // 绘制快速定位箭头
        let taskLeftX = row.beginX
        let taskRightX = row.endX
        let itemActualEndX = 0
        if (row.actualBeginX) {
          itemActualEndX = row.actualBeginX + row.actualLong
          if (row.actualBeginX < taskLeftX) {
            taskLeftX = row.actualBeginX
          }
          if (itemActualEndX > taskRightX) {
            taskRightX = itemActualEndX
          }
        }
        if (taskLeftX + this.fixedWidth - this.posX > this.canvasWidth) {
          row.isQuick = 1
          // 绘制右侧快速定位
          drawQuickLoction(ctx, '→', this.canvasWidth - this.fixedWidth + this.posX - 30, y, rowHeight, this.option.borderColor, color, row.name, 'right')
          y += rowHeight
          return
        }
        if (taskRightX && taskRightX - this.posX < 0) {
          row.isQuick = 2
          // 绘制左侧侧快速定位
          drawQuickLoction(ctx, '←', this.posX + 4, y, rowHeight, this.option.borderColor, color, row.name, 'left')
          y += rowHeight
          return
        }

        // 绘制任务名称
        if (taskWidth) {
          drawTextInTask(ctx, row.name, taskX, y + 6, taskWidth, rowHeight - 15, {
            fontSize: fontSize,
            align: this.option.ganttAlign || 'center',
            color: color
          }, row, this.option.onlyGantt)
        }

        if (rowEndDate < halvingLine || (rowStartDate <= halvingLine && rowEndDate >= halvingLine)) {
          let actualTextBeginX = taskX - 30
          if (row.actualBeginX && row.actualBeginX < taskX) {
            actualTextBeginX = row.actualBeginX - 30
          }
          // 绘制任务状态，滞后正常提前
          drawTypeInTask(ctx, !priority ? '正常' : priority, actualTextBeginX, y + 5, rowHeight - 15, {
            fontSize: fontSize,
            align: this.option.ganttAlign || 'center',
            color: color
          })
        }
      }
      y += rowHeight
    })
    // 外底部边线
    ctx.lineCap = 'round'
    ctx.lineJoin = 'miter'
    ctx.strokeStyle = this.option.borderColor

    ctx.beginPath()
    ctx.lineWidth = 0.5
    // 0.5是解决绘制线条出现2px的问题
    ctx.moveTo(0, y - 0.5)
    ctx.lineTo(this.tableWidth, y - 0.5)
    ctx.stroke()

    // ctx.beginPath()
    // ctx.lineWidth = 1
    // ctx.moveTo(this.canvasWidth - this.fixedWidth + this.posX, 0)
    // ctx.lineTo(this.canvasWidth - this.fixedWidth + this.posX, y)
    // ctx.stroke()
    ctx.restore()
  }

  /**
   * @description: 绘制任务部分的条纹背景
   * @return {*}
   */
  renderTaskBg (rect, cols, canvasId) {
    const {headBackgroundColor, borderColor } = this.option
    const { ctx } = this
    const y = 0 + this.posY
    const h = this.viewHeight - this.headHeight
    let color = '#fff'
    let x = 0
    let w = 0
    let n = 0
    ctx.save()
    ctx.rect(rect.x, rect.y, rect.w, rect.h)
    ctx.clip()
    this.translateContext(ctx, canvasId)
    cols.forEach((col) => {
      const width = col.itemDays * baseItemWidth
      // 只显示可视范围内的header数据
      if (col.field === 'itemDate') {
        col.childRender = true
        if (x < this.canvasWidth - this.fixedWidth + this.posX) {
          if (this.posX === 0) {
            col.childRender = true
          } else {
            if (x - this.posX + width >= 0 && x - this.posX <= this.canvasWidth - this.fixedWidth) {
              col.childRender = true
            } else {
              col.childRender = false
            }
          }
        } else {
          col.childRender = false
        }
      }
      if (this.option.dateType === 'onlyYear') {
        n++
        if (n % 2 === 0) {
          color = headBackgroundColor
        } else {
          color = '#fff'
        }
        w = col.itemDays * baseItemWidth
        if (col.childRender) {
          drawBgInTaskBg(ctx, x, y, w, h, color, borderColor)
        }
        x += col.itemDays * baseItemWidth
      } else {
        if (col.children?.length) {
          col.children.forEach((item) => {
            n++
            if (this.option.dateType === 'monthAndDay') {
              if (item.weekend) {
                color = '#fff'
              } else {
                color = headBackgroundColor
              }
            } else {
              if (n % 3 === 0) {
                color = headBackgroundColor
              } else {
                color = '#fff'
              }
            }
            w = item.itemDays * baseItemWidth
            if (col.childRender) {
              drawBgInTaskBg(ctx, x, y, w, h, color, borderColor)
            }
            x += item.itemDays * baseItemWidth
          })
        }
      }
    })
    ctx.restore()
  }

  /**
   * @description: 渲染header
   * @return {*}
   */
  renderHead (rect, cols, canvasId) {
    const { headColor, headHeight, borderWidth, headBackgroundColor, hoverBackgroundColor, isTreeData, editMode } = this.option
    const { ctx } = this
    let x = 0
    const y = 0
    let childX = 0
    ctx.clearRect(rect.x, rect.y, rect.w, rect.h)
    ctx.save()
    ctx.rect(rect.x, rect.y, rect.w, rect.h)
    ctx.clip()
    ctx.fillStyle = headBackgroundColor
    ctx.fillRect(rect.x, rect.y, rect.w, rect.h)
    this.translateContext(ctx, canvasId)
    cols.forEach((col) => {
      let fontSize = this.option.fontSize
      let textColor = headColor
      let text = col.title
      let width = col.widthItem
      let height = headHeight
      // 仅table滞后有widthAuto的宽度变为auto
      if (this.option.onlyTable && col.widthAuto && !this.option.tableFixed) {
        let surplusWidth = 0
        cols.forEach((dd, j) => {
          if (!dd.widthAuto) {
            surplusWidth += dd.widthItem
          }
          if (j === cols.length - 1) {
            col.width = col.widthItem = width = this.canvasWidth - surplusWidth
          }
        })
      }
      // handlehe 是操作得标识，有children，但header不分成上下,location是解绑和绑定操作
      if (col.children?.length && col.field !== 'handle' && col.field !== 'location') {
        height = headHeight / 2
      }
      if (isObject(text)) {
        const o = text
        text = o.text
        textColor = o.color || textColor
        fontSize = o.fontSize || fontSize
      }
      const sortType = this.sortObject.field === col.field ? this.sortObject.type : SORT_NONE
      // 绘制眼睛图标
      if (col.type && col.type === 'Icon') {
        drawEyeIcon(ctx, x - 0.5, y - 0.5, width, height, true, {
          fontSize,
          align: col.titleAlign || col.align,
          borderColor: this.option.borderColor,
          color: textColor,
          backgroundColor: headBackgroundColor,
          isHideTask: col.isHideTask
        })
      } else if (col.type && col.type === 'selection') {
        // 绘制checkbox
        drawCheckbox(ctx, col.canvas_checked, x - 0.5, y, width, height, true, {
          fontSize,
          align: col.titleAlign || col.align,
          borderColor: this.option.borderColor,
          color: textColor,
          backgroundColor: headBackgroundColor
        })
      } else {
        let bol = false
        // 只显示可视范围内的header数据
        if (col.field === 'itemDate') {
          col.childRender = true
          if (x < this.canvasWidth - this.fixedWidth + this.posX) {
            if (this.posX === 0) {
              bol = true
              col.childRender = true
            } else {
              if (x - this.posX + width >= 0 && x - this.posX <= this.canvasWidth - this.fixedWidth) {
                bol = true
                col.childRender = true
              } else {
                bol = false
                col.childRender = false
              }
            }
          } else {
            bol = false
            col.childRender = false
          }
        }

        if (col.field !== 'itemDate' || bol) {
          drawTextInHeader(ctx, text, x, y, width, height, {
            ...this.option,
            fontSize,
            color: textColor,
            align: col.titleAlign || col.align,
            borderColor: this.option.borderColor,
            borderWidth,
            backgroundColor: headBackgroundColor,
            sort: col.sort,
            sortType,
            pixelRatio: this.pixelRatio
          }, col.field, bol, isTreeData, editMode, this.option.addAble)
        }
      }
      x += width
      // 渲染head第二行的数据
      if (col.children?.length && col.childRender && col.field !== 'handle' && cols.field !== 'location') {
        if (childX === 0) {
          // 计算出第一个日期的起始X位置
          childX = x - col.widthItem
        }
        col.children.forEach((child) => {
          const itemWidth = child.itemDays * baseItemWidth
          drawTextInHeader(ctx, child.title, childX, y + (headHeight / 2) - 0.5, itemWidth, headHeight / 2, {
            ...child,
            fontSize,
            color: textColor,
            align: 'center',
            borderColor: this.option.borderColor,
            borderWidth,
            backgroundColor: child.weekend ? hoverBackgroundColor : headBackgroundColor,
            pixelRatio: this.pixelRatio
          }, '', false, false, editMode, this.option.addAble)
          childX += itemWidth
        })
      }
    })
    ctx.restore()
  }

  /**
* @description: 移入效果
* @return {*}
*/
  renderTaskHoverBg (rect, id) {
    const { list, rowHeight, headHeight } = this.option
    const { ctx } = this
    ctx.restore()
    const index = list.findIndex(v => v.id === id)
    const hoverY = index * rowHeight - this.posY + headHeight
    if (hoverY >= headHeight) {
      ctx.fillStyle = 'rgba(128,128,128,0.2)'
      ctx.fillRect(rect.x, hoverY, rect.w, rowHeight)
    }
  }

  /**
  * @description: 渲染时间轴旗子
  * @return {*}
  */
  renderFlag () {
    const { ctx, headHeight, fixedWidth } = this
    let x = flagPosition[0]
    if (x < fixedWidth) {
      x = fixedWidth
    }
    if (x > this.canvasWidth) {
      x = this.canvasWidth
    }
    ctx.fillStyle = 'green'
    ctx.beginPath()
    ctx.arcTo(x, flagPosition[1], x + 28, flagPosition[1], 4)
    ctx.arcTo(x + 28, flagPosition[1], x + 28, flagPosition[1] + 23, 4)
    ctx.arcTo(x + 28, flagPosition[1] + 23, x + 3, flagPosition[1] + 23, 4)
    ctx.arcTo(x, flagPosition[1] + 23, x + 3, flagPosition[1], 0)
    ctx.arcTo(x, flagPosition[1], x + 28, flagPosition[1], 0)
    ctx.closePath()
    ctx.fill()

    ctx.lineWidth = 2
    ctx.strokeStyle = 'green'
    ctx.beginPath()
    ctx.moveTo(x + 1, flagPosition[1])
    ctx.lineTo(x + 1, headHeight)
    ctx.stroke()

    ctx.lineWidth = 1.5
    ctx.strokeStyle = 'white'
    ctx.beginPath()
    ctx.lineCap = 'round'
    ctx.lineJoin = 'miter'
    ctx.moveTo(x + 7, flagPosition[1] + 8.5)
    ctx.lineTo(x + 22, flagPosition[1] + 8.5)
    ctx.lineTo(x + 19, flagPosition[1] + 5.5)
    ctx.stroke()

    ctx.beginPath()
    ctx.lineCap = 'round'
    ctx.lineJoin = 'miter'
    ctx.moveTo(x + 23, flagPosition[1] + 12.5)
    ctx.lineTo(x + 8, flagPosition[1] + 12.5)
    ctx.lineTo(x + 11, flagPosition[1] + 15.5)
    ctx.stroke()
  }

  /**
  * @description: 绘制前锋线
  * @return {*}
  */
  rendForwardLine (rect, cols, lineDate, canvasId) {
    const { list, rowHeight } = this.option
    const { headHeight, viewHeight, fixedWidth, ctx } = this
    const intersectData = [] // 前锋线相交数据
    let n = 0
    let y = 0
    let firstDate = this.option.startDate
    if (cols?.length) {
      if (cols[0].children?.length) {
        firstDate = cols[0].children[0].fullDate
      } else {
        firstDate = cols[0].fullDate
      }
    }
    const lineStartBetween = getDaysBetween(firstDate, lineDate)
    let lineX = Math.ceil(fixedWidth + (lineStartBetween * baseItemWidth) - (this.posX * this.pixelRatio))
    if (!lineX) {
      lineX = 10000
    }
    forwardLinePosition = [lineX, 50]
    ctx.save()
    ctx.fillStyle = 'transparent'
    ctx.fillRect(rect.x, rect.y, rect.w, rect.h)
    this.translateContext(ctx, canvasId)
    // 渲染绿色的竖线
    if (lineX > fixedWidth) {
      const y1 = this.option.headHeight
      ctx.strokeStyle = 'green'
      ctx.fillStyle = 'green'
      ctx.beginPath()
      ctx.arcTo(lineX, y1, lineX + 50, y1, 4)
      ctx.arcTo(lineX + 50, y1, lineX + 50, y1 + 23, 4)
      ctx.arcTo(lineX + 50, y1 + 23, lineX, y1 + 23, 4)
      ctx.arcTo(lineX, y1 + 23, lineX, y1, 0)
      ctx.arcTo(lineX, y1, lineX + 50, y1, 0)
      ctx.closePath()
      ctx.fill()
      ctx.beginPath()
      ctx.font = '13px 微软雅黑'
      ctx.fillStyle = '#fff'
      ctx.fillText('前锋线', lineX + 6, 56)
      ctx.stroke()
      ctx.beginPath()
      ctx.moveTo(lineX, headHeight)
      ctx.lineTo(lineX, viewHeight)
      ctx.stroke()
      // 拖拽线绘制
      ctx.beginPath()
      ctx.moveTo(lineX - 4, this.option.headHeight)
      ctx.lineTo(lineX - 4, this.option.headHeight + 23)
      ctx.stroke()
    }
    list.forEach((row) => {
      n++
      const rowStartDate = row.start_date.substring(0, 10)
      const rowEndDate = row.end_date.substring(0, 10)
      // 绘制前锋线
      // 有实际进度
      if (row.actualLong && row.actualBeginX) {
        let nextPoint = [row.actualLong + row.actualBeginX + fixedWidth - this.posX, y + this.headHeight + 20 - this.posY]
        // 已完成的前锋线是贴着竖线的
        if (rowStartDate >= lineDate) {
          nextPoint = [lineX, y + this.headHeight + 20 - this.posY]
        } else {
          if (+row.daily_over > 1) {
            // 提前完成
            const rowActualEndDate = row[this.option.actualEndField].substring(0, 10)
            if (rowEndDate > lineDate && rowActualEndDate < lineDate) {
              const days = getDaysBetween(rowActualEndDate, rowEndDate)
              nextPoint = [lineX + (days * baseItemWidth), y + this.headHeight + 20 - this.posY]
            } else {
              nextPoint = [lineX, y + this.headHeight + 20 - this.posY]
            }
          } else if (row.isFutureTask) {
            // 前锋线减去滞后工期
            nextPoint = [lineX - (row.lagging * baseItemWidth), y + this.headHeight + 20 - this.posY]
          }
        }
        if (!intersectData.length) {
          intersectData.push(nextPoint)
        } else {
          const beforePoint = intersectData[intersectData.length - 1]
          // 将竖线交点单独写入，方便前锋线根据滞后工期着色
          if ((beforePoint[0] < lineX && lineX < nextPoint[0]) || (beforePoint[0] > lineX && lineX > nextPoint[0])) {
            // 求交点的Y坐标
            const crossingPointY = (beforePoint[1] - nextPoint[1]) / (nextPoint[0] - beforePoint[0]) * (beforePoint[0] - lineX) + beforePoint[1]
            // 插入交点坐标
            intersectData.push([lineX, crossingPointY])
            intersectData.push(nextPoint)
          } else {
            intersectData.push(nextPoint)
          }
        }
      } else {
        // 前锋线之前的任务
        if (rowEndDate < lineDate) {
          const nextPoint = [row.beginX + fixedWidth - this.posX, y + this.headHeight + 20 - this.posY]
          if (!intersectData.length) {
            intersectData.push(nextPoint)
          } else {
            const beforePoint = intersectData[intersectData.length - 1]
            // 将竖线交点单独写入，方便前锋线根据滞后工期着色
            if ((beforePoint[0] < lineX && lineX < nextPoint[0]) || (beforePoint[0] > lineX && lineX > nextPoint[0])) {
              // 求交点的Y坐标
              const crossingPointY = (beforePoint[1] - nextPoint[1]) / (nextPoint[0] - beforePoint[0]) * (beforePoint[0] - lineX) + beforePoint[1]
              // Math.pow(3,2) + Math.pow(4,2) ),'勾股定理js'
              // 插入交点坐标
              intersectData.push([lineX, crossingPointY])
              intersectData.push(nextPoint)
            } else {
              intersectData.push(nextPoint)
            }
          }
        } else if (rowStartDate >= lineDate) {
          // 前锋线后的任务
          intersectData.push([lineX, y + this.headHeight + 20 - this.posY])
        } else if (rowStartDate <= lineDate && lineDate <= rowEndDate) {
          let nextPoint = [row.beginX + fixedWidth - this.posX, y + this.headHeight + 20 - this.posY]
          if (row.lagging < 0) {
            // 提前完成的情况
            nextPoint = [row.beginX + fixedWidth - this.posX - (row.lagging * baseItemWidth), y + this.headHeight + 20 - this.posY]
          }
          if (!intersectData.length) {
            intersectData.push(nextPoint)
          } else {
            const beforePoint = intersectData[intersectData.length - 1]
            // 将竖线交点单独写入，方便前锋线根据滞后工期着色
            if ((beforePoint[0] < lineX && lineX < nextPoint[0]) || (beforePoint[0] > lineX && lineX > nextPoint[0])) {
              // 求交点的Y坐标
              const crossingPointY = (beforePoint[1] - nextPoint[1]) / (nextPoint[0] - beforePoint[0]) * (beforePoint[0] - lineX) + beforePoint[1]
              // Math.pow(3,2) + Math.pow(4,2) ),'勾股定理js'
              // 插入交点坐标
              intersectData.push([lineX, crossingPointY])
              intersectData.push(nextPoint)
            } else {
              intersectData.push(nextPoint)
            }
          }
        }
      }
      if (intersectData?.length && n === list.length) {
        // const pointArr = intersectData.sort((a, b) => a[1] - b[1])
        const pointArr = intersectData
        ctx.lineWidth = 1.5
        pointArr.forEach((item, index) => {
          if (index > 0) {
            // 分段绘制颜色
            if (item[0] >= lineX && pointArr[index - 1][0] >= lineX) {
              ctx.beginPath()
              ctx.lineCap = 'round'
              ctx.lineJoin = 'miter'
              ctx.moveTo(...pointArr[index - 1])
              ctx.lineTo(item[0], item[1])
              ctx.strokeStyle = '#000099'
              ctx.stroke()
            } else {
              ctx.beginPath()
              ctx.lineCap = 'round'
              ctx.lineJoin = 'miter'
              ctx.moveTo(...pointArr[index - 1])
              ctx.lineTo(item[0], item[1])
              ctx.strokeStyle = 'red'
              ctx.stroke()
            }
          }
        })
      }
      y += rowHeight
    })
  }

  /**
  * @description: 绘制今天
  * @return {*}
  */
  rendTodayLine (rect, cols, canvasId, lineDate) {
    const y = this.option.headHeight
    const { headHeight, viewHeight, fixedWidth, ctx } = this
    let firstDate = this.option.startDate
    if (cols?.length) {
      if (cols[0].children?.length) {
        firstDate = cols[0].children[0].fullDate
      } else {
        firstDate = cols[0].fullDate
      }
    }
    const lineStartBetween = getDaysBetween(firstDate, lineDate)
    const lineX = Math.ceil(fixedWidth + (lineStartBetween * baseItemWidth) - (this.posX * this.pixelRatio))
    todayLinePosition = [lineX, 50]
    ctx.save()
    ctx.fillStyle = 'transparent'
    ctx.fillRect(rect.x, rect.y, rect.w, rect.h)
    this.translateContext(ctx, canvasId)
    // 渲染绿色的竖线
    if (lineX > fixedWidth) {
      ctx.strokeStyle = '#1C86EE'
      ctx.beginPath()
      ctx.moveTo(lineX, headHeight)
      ctx.lineTo(lineX, viewHeight)
      ctx.stroke()
      ctx.beginPath()
      ctx.fillStyle = '#1C86EE'
      ctx.beginPath()
      ctx.arcTo(lineX, y, lineX + 40, y, 4)
      ctx.arcTo(lineX + 40, y, lineX + 40, y + 23, 4)
      ctx.arcTo(lineX + 40, y + 23, lineX, y + 23, 4)
      ctx.arcTo(lineX, y + 23, lineX, y, 0)
      ctx.arcTo(lineX, y, lineX + 40, y, 0)
      ctx.closePath()
      ctx.fill()
      ctx.stroke()
      ctx.beginPath()
      ctx.font = '13px 微软雅黑'
      ctx.fillStyle = '#fff'
      ctx.fillText('今天', lineX + 6, y + 17)
      // if (!dashedLineDateNum) {
      //   // 拖拽线绘制
      //   ctx.beginPath()
      //   ctx.moveTo(lineX - 4, this.option.headHeight)
      //   ctx.lineTo(lineX - 4, this.option.headHeight + 23)
      //   ctx.stroke()
      // }
    }
  }

  /**
  * @description: 绘制分割虚线
  * @return {*}
  */
  rendDashedLine (rect, cols, canvasId, lineDate) {
    const y = this.option.headHeight
    const { headHeight, viewHeight, fixedWidth, ctx, viewWidth } = this
    let firstDate = this.option.startDate
    if (cols?.length) {
      if (cols[0].children?.length) {
        firstDate = cols[0].children[0].fullDate
      } else {
        firstDate = cols[0].fullDate
      }
    }
    const lineStartBetween = getDaysBetween(firstDate, lineDate)
    const lineX = Math.ceil(fixedWidth + (lineStartBetween * baseItemWidth) - (this.posX * this.pixelRatio))
    // 前锋线需要展示在可视的位置
    if (dashedLinePosition[0] === 10000 && (lineX > viewWidth || lineX < fixedWidth)) {
      if (lineX < fixedWidth) {
        this.posX = viewWidth - lineX
      } else {
				const posX = lineX - viewWidth + (viewWidth - fixedWidth) / 2
				this.posX  = Math.min(this.C_MAX_POS_X, posX)
      }
    }
    dashedLinePosition = [lineX, 50]
    ctx.save()
    ctx.fillStyle = 'transparent'
    ctx.fillRect(rect.x, rect.y, rect.w, rect.h)
    this.translateContext(ctx, canvasId)
    // 渲染绿色的竖线
    if (lineX > fixedWidth) {
      ctx.strokeStyle = 'rgba(0,179,178,0.4)'
      ctx.beginPath()
      ctx.setLineDash([4])
      ctx.moveTo(lineX, headHeight)
      ctx.lineTo(lineX, viewHeight)
      ctx.stroke()
      ctx.beginPath()
      ctx.setLineDash([])
      ctx.fillStyle = 'rgba(0,179,178,0.4)'
      ctx.lineWidth = 0
      ctx.beginPath()
      ctx.arcTo(lineX, y, lineX + 85, y, 4)
      ctx.arcTo(lineX + 85, y, lineX + 90, y + 23, 4)
      ctx.arcTo(lineX + 85, y + 23, lineX, y + 23, 4)
      ctx.arcTo(lineX, y + 23, lineX, y, 0)
      ctx.arcTo(lineX, y, lineX + 85, y, 0)
      ctx.closePath()
      ctx.fill()
      ctx.stroke()
      ctx.beginPath()
      ctx.font = '13px 微软雅黑'
      ctx.fillStyle = '#fff'
      ctx.fillText(lineDate, lineX + 6, 56)
      // 拖拽线绘制
      if (dashedLineDateNum) {
        ctx.beginPath()
        ctx.moveTo(lineX - 4, this.option.headHeight)
        ctx.lineTo(lineX - 4, this.option.headHeight + 23)
        ctx.stroke()
      }
    }
  }

  /**
   * @description: 绘制各个部分的内容
   * @return {*}
   */
  renderSubView () {
    // canvas没有层级，只有先后绘制，这里的绘制顺序很重要
    let rect
    const { normalCols, fixedCols, tableFixed } = this.option
    const { headHeight, fixedWidth, viewWidth, viewHeight, ctx } = this
    ctx.clearRect(0, 0, viewWidth, viewHeight)
    // 今天的线滚动到可视区域
    if (!this.option.onlyTable && todayDataNum) {
      // 取消前锋线还是回到今天这根线
      if ((!this.option.forwardLine && forwardLinePosition[0] !== 10000) || (this.option.forwardLine && forwardLinePosition[0] === 10000)) {
        todayLinePosition = [10000, 10000]
      }
      const lineStartBetween = getDaysBetween(this.option.startDate, todayDataNum)
      const lineX = Math.ceil(fixedWidth + (lineStartBetween * baseItemWidth) - (this.posX * this.pixelRatio))
      // 今天需要展示在可视的位置
      if (todayLinePosition[0] === 10000 && (lineX > viewWidth || lineX < fixedWidth)) {
        if (lineX < fixedWidth) {
          this.posX = viewWidth - lineX
        } else {
          const posX = lineX - viewWidth + (viewWidth - fixedWidth) / 2
					this.posX  = Math.min(this.C_MAX_POS_X, posX)
        }
      }
    }
    if (!this.option.onlyTable) {
      // 绘制任务条条纹背景
      const taskBgRect = { x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }
      this.renderTaskBg(taskBgRect, normalCols, TABLE_BODY)
      // 渲染任务条
      if (this.option.onlyGantt) {
        rect = { x: 0, y: headHeight, w: viewWidth, h: viewHeight - headHeight }
        this.renderTaskBody(rect, normalCols, TABLE_BODY)
      } else {
        rect = { x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }
        this.renderTaskBody(rect, normalCols, TABLE_BODY)
      }
    }
    // 渲染今天
    if (!this.option.onlyTable && todayDataNum) {
      rect = { x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }
      this.rendTodayLine(rect, normalCols, FORWARD, todayDataNum)
    }
    // 渲染拖拽的虚线
    if (dashedLineDateNum) {
      rect = { x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }
      this.rendDashedLine(rect, normalCols, FORWARD, dashedLineDateNum)
    }
    // 渲染前锋线
    if (this.option.forwardLine && !this.option.onlyTable) {
      dashedLineDateNum = ''
      dashedLinePosition = [10000, 10000]
      rect = { x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }
      this.rendForwardLine(rect, normalCols, forwardLineDateNum, FORWARD)
    }
    // 关闭前锋线将坐标复位
    if (!this.option.forwardLine) {
      forwardLinePosition = [10000, 10000]
      forwardLineDateNum = parseTime(new Date(), '{y}-{m}-{d}')
    }
    // 渲染左侧的表格数据
    if (!this.option.onlyGantt) {
      // 全表格宽度低于1500才渲染右侧是滚动的状态
      if (tableFixed) {
        const tableFixedData = normalCols.filter(v => v.field !== 'itemDate')
        if (tableFixedData?.length) {
          const rectFixedTable = { x: fixedWidth, y: headHeight, w: viewWidth - fixedWidth, h: viewHeight - headHeight }
          const rectFixedHead = { x: fixedWidth, y: 0, w: this.canvasWidth - fixedWidth, h: headHeight }
          this.renderTableBody(rectFixedTable, tableFixedData, TABLE_BODY)
          this.renderHead(rectFixedHead, tableFixedData, TABLE_HEAD)
        }
      }
      rect = { x: 0, y: headHeight, w: fixedWidth, h: viewHeight - headHeight }
      this.renderTableBody(rect, fixedCols, TABLE_BODY_FIXED)
    }
    if (!this.option.onlyTable) {
      if (this.option.onlyGantt) {
        rect = { x: 0, y: 0, w: viewWidth, h: headHeight }
        this.renderHead(rect, normalCols, TABLE_HEAD)
      } else {
        rect = { x: fixedWidth, y: 0, w: this.canvasWidth - fixedWidth, h: headHeight }
        this.renderHead(rect, normalCols, TABLE_HEAD)
      }
      // 渲染时间轴的旗子
      if (this.option.isPlayAble) {
        if (flagPosition[0] === 0 && flagPosition[1] === 0) {
          flagPosition = [rect.x, rect.y]
        }
        this.renderFlag()
      }
    }
    // 渲染header
    if (!this.option.onlyGantt) {
      rect = { x: 0, y: 0, w: fixedWidth, h: headHeight }
      this.renderHead(rect, fixedCols, TABLE_HEAD_FIXED)
      if (!this.option.onlyTable) {
        this.renderSplitPanes()
      }
    }
    // hover效果
    if (hoverId) {
      this.renderTaskHoverBg({ x: 0, y: headHeight, w: this.canvasWidth, h: viewHeight - headHeight }, hoverId)
    }
  }

  async init (option) {
    if (!option || typeof option !== 'object') {
      console.log(`CanvasTable: option Invalid: ${option}`)
    }
    if (!(option.cols instanceof Array)) {
      console.log(`CanvasTable: option.cols Invalid: ${option.cols}`)
    }
    if (!(option.list instanceof Array)) {
      console.log(`CanvasTable: option.list Invalid: ${option.list}`)
    }
    // console.log(option, 'option')
    this.option = option
    this.initOption(option)
    // 设置前锋线时间
    if (!todayDataNum) {
      const todayDate = parseTime(new Date(), '{y}-{m}-{d}')
      if (todayDate > option.startDate && todayDate < option.endDate) {
        todayDataNum = todayDate
        forwardLineDateNum = todayDate
      }
    }
    // 获取拖拽和播放的初始时间
    if (option.isPlayAble && option?.normalCols?.length) {
      if (option.normalCols[0].children?.length) {
        flagDateNum = option.normalCols[0].children[0].fullDate
        firstDateNum = option.normalCols[0].children[0].fullDate
      } else {
        flagDateNum = option.normalCols[0].fullDate
        firstDateNum = option.normalCols[0].fullDate
      }
    }
    // 监听shift按键
    document.onkeydown = (e) => {
      if (e.key === 'Shift' && !ShiftEnter) {
        ShiftEnter = true
      }
    }
    document.onkeyup = () => {
      if (ShiftEnter) {
        ShiftEnter = false
      }
    }
    // 监听行内编辑
    // document.getElementById('InputCont').onblur = (e) => {
    //   if (editItem.row[editItem.col.field] !== e.target.value) {
    //     if (isFunction(this.editItemFn)) {
    //       this.editItemFn(editItem, e.target.value)
    //     }
    //   }
    // }
    window.requestAnimationFrame(this.draw.bind(this))
  }

  draw () {
    if (!this.requestFlag) {
      return
    }
    const ctx = this.ctx
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.save()
    this.renderSubView()
    this.renderScrollbar()
    ctx.restore()
    this.requestFlag = false
    if (this.waitingRender) {
      this.requestRefresh()
      this.waitingRender = false
    }
  }

  /**
   * @description: 导出数据
   * @param {*} titleText
   * @return {*}
   */
  exportHtml (titleText) {
    const { list, cols } = this.option
    const html = document.createElement('html')
    const head = document.createElement('head')
    const meta = document.createElement('meta')
    meta.setAttribute('charset', 'utf-8')
    const title = document.createElement('title')
    const style = document.createElement('style')
    style.innerText = 'table {border-collapse: collapse;}th, td {border: 1px solid #999;text-align: center;padding: 0 4px;}'
    title.innerHTML = titleText
    head.appendChild(meta)
    head.appendChild(title)
    head.appendChild(style)
    const body = document.createElement('body')
    html.appendChild(head)
    html.appendChild(body)
    const table = document.createElement('table')
    const thead = document.createElement('thead')
    const tbody = document.createElement('tbody')
    table.appendChild(thead)
    table.appendChild(tbody)
    const headTr = document.createElement('tr')
    cols.forEach((col) => {
      const th = document.createElement('th')
      let text = ''
      if (isObject(col.title)) {
        text = col.title.text
      } else {
        text = col.title
      }
      th.innerText = text
      headTr.appendChild(th)
    })
    thead.appendChild(headTr)
    list.forEach((row) => {
      const tr = document.createElement('tr')
      cols.forEach((col) => {
        const td = document.createElement('td')
        let text = ''
        if (isFunction(col.formatter)) {
          text = col.formatter(row)
        } else {
          text = row[col.field]
        }
        if (isObject(text)) {
          const o = text
          text = o.text
        }
        td.innerText = text
        tr.appendChild(td)
      })
      tbody.appendChild(tr)
    })
    body.appendChild(table)
    return '<!DOCTYPE html>' + html.outerHTML
  }
}

CanvasTable.SORT_NONE = SORT_NONE
CanvasTable.SORT_ASC = SORT_ASC
CanvasTable.SORT_DESC = SORT_DESC
