<!--
 * @Description: 平台动态组件
 * @Author: luocheng
 * @Date: 2022-08-31 11:35:22
 * @LastEditors: zyf
 * @LastEditTime: 2023-07-07 09:45:10
-->
<template>
  <div
    class="common-platform-dynamics"
    v-loading="loading"
  >
    <div
      class="list-box"
      :style="rankList && rankList.length ? 'width: 80%;' : 'width: 100%;'"
      @scroll="scrollEvent"
    >
      <div
        class="list-item"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <div class="item-box" >
          <div class="avatar-box" :class="!!item.defaultIcon ? 'defaultIcon' : ''">
            <img
              :src="item.avatar"
              class="head-icon"
            />
          </div>
          <div class="item-user-name">
            {{ item.user_name }}
          </div>
        </div>
        <div class="item-content">
          <div class="item-content-left" ref="contentLeft">
            {{ item.type_name }}了【{{ getObjectName(item.object_name) }}】
          </div>
          <div class="item-content-right">
            {{ item.created_at | switchTime }}
          </div>
        </div>
      </div>
      <Empty
        v-if="!dataList.length"
        description="暂无数据"
        :image-size="100"
      ></Empty>
    </div>
    <div
      v-if="rankList && rankList.length"
      class="rank-box"
    >
      <div
        v-for="(item, index) in rankList"
        :key="item.id"
        class="icon-box"
      >
        <img
          :src="rankIconList[index]"
          class="rank-icon"
        />
        <el-tooltip
          class="item"
          :content="item.user_name"
          placement="bottom"
        >
          <span
            class="avatar-box"
            :class="!!item.defaultIcon ? 'defaultIcon' : ''"
          >
            <img
              :src="item.avatar"
              class="head-icon"
            />
          </span>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty, Tooltip } from 'element-ui';
import { dataInterface } from '@/apis/data/index';

export default {
  name: 'CommonPlatformDynamics',
  components: {
    Empty,
    'el-tooltip': Tooltip
  },
  data() {
    return {
      // 排名图标
      rankIconList: [
        'https://static.bimcc.com/openim/no25438055C20D6CF08766BCFED563DCE8.1%402x',
        'https://static.bimcc.com/openim/no83D13479934F782C1440469AEC145688.2%402x',
        'https://static.bimcc.com/openim/noCF2CBAC05DA363C269DB90F1BB3C8581.3%402x',
        'https://static.bimcc.com/openim/noDBB90593FB13BAA61181376B90EF5130.4%402x',
        'https://static.bimcc.com/openim/no7CA4641037E4B26D737F9D400D322CEC.5%402x'
      ],
      // 总数据列表
      dataList: [],
      // 排名列表
      rankList: [],
      loading: false,
      // 分页数据
      page: 1,
      size: 20,
      total: 0,
      isUpdate: true, // 是否到底
      defaultAvatar: 'https://static.bimcc.com/openim/default-avatar78A259E1D5409BC2F409311229CB469D.png',
      initTnd: false
    };
  },
  created() {
    this.getList();
  },
  filters: {
    switchTime(time) {
      const m = time.slice(5, 7);
      const d = time.slice(8, 11);
      const h = time.slice(11, 13);
      const mm = time.slice(14, 16);
      return `${m}/${d} ${h}:${mm}`;
    }
  },
  computed: {
    getObjectName () {
      return function (val) { 
        if (val.length <= 4) {
          return val
        } else { 
          return val.slice(0,4) + '...'
        }
      }
		}
  },
  methods: {
    /**
     * @desc: 获取列表
     */
    async getList() {
      this.loading = true;
      await this.getDataList();
      if (!this.initTnd) {
        await this.getBuildBoardData();
      }
      this.loading = false;
    },
    /**
     * @desc: 获取当前页数据类表
     */
    getDataList() {
      return new Promise((resolve, reject) => {
        dataInterface({
          object_uuid: 'object62cbe374ef233',
          view_uuid: 'view62cd1cf39e2f3',
          __method_name__: 'dataList',
          transcode: 0,
          size: this.size,
          page: this.page
        })
          .then(res => {
            if (res?.data?.code === 200) {
              let dataList = this.dataList;
              let list = res.data.data.data || [];
              if (list && list.length) {
                list.forEach(item => {
                  if (!item.avatar) {
                    item.avatar = this.defaultAvatar;
                  }
                  dataList.push(item);
                });
                this.dataList = dataList;
                this.total = dataList.length;
              } else {
                this.page -= 1;
                if (list.length === 0) {
                  this.isUpdate = false;
                  this.$message.warning('没有更多数据了');
                } else {
                  this.$message.warning('没有更多数据了');
                }
              }
            } else {
              this.$message('获取数据失败！');
            }
            resolve();
          })
          .catch(err => {
            console.log(err);
            this.$message('获取数据失败！');
            reject();
          });
      });
    },
    /**
     * @desc: 获取建设看板（利率和名次）
     */
    getBuildBoardData() {
      return new Promise((resolve, reject) => {
        dataInterface({
          object_uuid: 'object62cbe374ef233',
          view_uuid: 'view62ce9543b87f7',
          __method_name__: 'dataList',
          transcode: 0
        })
          .then(res => {
            if (res?.data?.code === 200) {
              let data = res.data.data || [];
              data.sort((a, b) => parseInt(b.nums) - parseInt(a.nums));
              data.forEach(item => {
                if (!item.avatar) {
                  item.avatar = this.defaultAvatar;
                }
              });
              if (data.length > 5) {
                this.rankList = data.slice(0, 5);
              } else {
                this.rankList = data;
              }
              this.initTnd = true;
            }
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      });
    },
    /**
     * @desc: 滚动加载
     * @param {Object} e
     */
    scrollEvent(e) {
      if (e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight === 0) {
        if (this.isUpdate) {
          this.page += 1;
          this.getList();
        } else {
          this.$message.warning('没有更多数据了');
        }
      }
    }
  }
};
</script>

<style lang="less">
.common-platform-dynamics {
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }
}
</style>

<style lang="less" scoped>
.common-platform-dynamics {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px;
  .list-box {
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    .list-item {
      width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 8px 12px;
        background: #f5f6f7;
        margin-bottom: 12px;
        border-radius: 6px;
        box-sizing: border-box;
        overflow: hidden;
        .item-box{
          display: flex;
          align-items: center;
          max-width: 240px;
          .item-user-name{
            font-size: 14px;
            font-family: var(--systemFontFamily);
            font-weight: 400;
            color: #2a2f3d;
            line-height: 22px;
            padding-left: 8px;
          }
        }
        .item-content{
          flex: 1;
          display: flex;
          overflow: hidden;
          box-sizing: border-box;
          font-size: 14px;
          font-family: var(--systemFontFamily);
          font-weight: 400;
          color: #2a2f3d;
          line-height: 22px;
          padding-left: 8px;
          flex-wrap: wrap;
          .item-content-left{
            flex: 1;
            min-width: fit-content;
            white-space: nowrap;
          }
          .item-content-right{
            padding-left: 12px;
            font-size: 13px;
            font-family: var(--systemFontFamily);
            font-weight: 400;
            color: #787E8F;
            display: inline-block;
            flex-grow: 1;
            text-align: right;
          }
        }
    }
  }

  .rank-box {
    box-sizing: border-box;
    overflow: hidden;
    .icon-box {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      .rank-icon {
        display: block;
        width: 20px;
        height: 20px;
        padding-right: 12px;
      }
    }
  }
  .avatar-box {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    
    .head-icon {
      width: 20px;
      height: 20px;
      display: block;
      line-height: 20px;
      border-radius: 4px;
    }
  }
  .defaultIcon {
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
