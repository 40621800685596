<!--
 * @Description: 3.0消息弹窗
 * @Author: luocheng
 * @Date: 2021-12-20 19:09:02
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-03-21 15:56:49
 * @FilePath: /dataview-viewer-test/src/custom-component/dialog/CommonEmailShow.vue
-->
<template>
  <div class="global-dialog global-dialog-one global-dialog-overscroll-box" v-loading="loading">
    <header class="global-dialog-title" slot="title">
      <h3>{{ title || '--' }}</h3>
      <!-- <i class="iconfont icondaping-quanping fullscreen" @click="onToggleScreen"></i> -->
    </header>
    <article class="email-dialog-box">
      <div v-if="!prepared" v-loading="true" style="height:100%"></div>
      <PcParserNew
        v-else-if="newFormFlow"
        ref="dialogForm"
        :form-data="formDesignData"
        :displayData="displayData"
        :editFields="editFields"
        :disabled="disabled"
        :needFlow="!!needFlow"
        :moduleUuid="formUUID"
        :needParentFlow="!!needParentFlow"
        :formMode="requestType"
        :nowArchiType="archi_type"
        @submit="onFormSubmit"
        @on-close="onEndFlow"
        @edit-change="formEditChange"
      >
      </PcParserNew>
      <PcParser
        v-else
        ref="dialogForm"
        :class="{'p20': !newFormFlow}"
        :form-data="formDesignData"
        :displayData="displayData"
        :disabled="disabled"
        :editFields="editFields"
        :needFlow="!!needFlow"
        :moduleUuid="formUUID"
        :needParentFlow="!!needParentFlow"
        :formMode="requestType"
        :nowArchiType="archi_type"
        @on-close="onEndFlow"
        @submit="onFormSubmit"
      >
      </PcParser>
    </article>
    <div class="footer" v-if="newFormFlow && Object.keys(displayData).length && displayData.btn && displayData.btn.length">
      <!-- v-if="Object.keys(displayData).length && displayData.btn && displayData.btn.length"  -->
      <FlowOperate
        :edit-data="displayData"
        :flow-edit="editFieldsValue"
        @change-end="onEndFlow"
      />
    </div>
  </div>
</template>

<script>
import PcParser from "@/custom-component/form/newParser/PcParser";
import PcParserNew from '@/custom-component/form/newParser/PcParserNew.vue';
import { transFieldsUUid, dataInterface } from "@/apis/data/index";
// import eventBus from '@/plugins/eventBus';
import FlowOperate from "@/custom-component/flow/flow-designer/src/operation/FlowOperate";
import { getQueryValue, getComponentById } from '@/utils/tools';
import { mapState } from "vuex";

export default {
  name: "EmailShow",
  components: {
    PcParser,
    PcParserNew,
    FlowOperate
  },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
  },
  data() {
    return {
      displayData: null,
      editFields: {},
      formDisabled: "dataInfo",
      formDesignData: {},
      // 流程
      needFlow: true,
      needParentFlow: false,
      formUUID: "",
      requestType: "detail",
      loading: true,
      prepared:false,
      // 全屏
      isFullScreen: false,
      newFormFlow: false, // 新流程表单
      editFieldsValue: {},
      // 必要参数
      form_uuid: '', // 表单ID
      disabled: '', // 是否禁用
      data_id: '', // 数据ID
      title: '', // 标题
      tag: 'copy', // 类型 - 固定为copy 
      archi_type: '', // 架构, 无需 @廖伟
      ruuids: []
    };
  },
  inject: ['EDITOR_pageUUID'],
  computed: {
    ...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus',
			'subsidiaryComponentData'
    ]),
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
		},
  },
  mounted() {
    const param = this.getParams();
    console.log(param, '---param----');
    if (!param || Object.prototype.toString.call(param) !== '[object Object]'){
      this.$message.error('参数错误！');
      return;
    }
    const { form_uuid, data_id, title, archi_type = '' } = param;
    this.title = title;
    if (!form_uuid || !data_id) {
      this.$message.error('参数错误！');
      return;
    }
    /* eslint-disable */
    this.formUUID = form_uuid;
    this.data_id = data_id;
    this.archi_type = archi_type;
    this.getData();
  },
  methods: {
    formEditChange(val){
      this.editFieldsValue = val
    },
    /**
     * @desc: 表单提交
     */
    onFormSubmit() {
      this.loading = false;
      this.$emit('close')
    },
    /**
     * @desc: 操作完毕关闭弹窗
     */
    onEndFlow() {
      // 更新状态列表
      // eventBus.$emit('updateTodoList');
      this.getData();
    },
    /**
     * @description: 获取数据
     * @return {*}
     */
    getData() {
      this.loading = true;
      this.prepared = false;
      this.newFormFlow = false;
      this.formDesignData = {};
      this.formDesignData = null;
      this.editFields = {};
      // TIPS 请求表单携带原始数据id @凌志华
      transFieldsUUid("", this.formUUID,{ tag: this.tag, org_data_id: this.data_id }).then((res) => {
        if (res.data.code == 200) {
          this.needFlow = res.data.data.need_flow;
          // this.needParentFlow = !!res.data.data.need_parent_flow;
          // 编辑
          // 获取已经有的数据进行绑定
          let config = res.data.data;
          let ruuids = [];
          for (let i = 0; i < config.fields.length; i++) {
            let item = config.fields[i];
            if (item.__config__.tagIcon == "form") {
              ruuids.push({ relationship_uuid: item.__vModel__ });
            }
          }
          this.ruuids = ruuids;
          dataInterface({
            data_id: this.data_id,
            object_uuid: this.formUUID,
            view_uuid: "",
            ruuids: this.ruuids,
            __method_name__: "dataInfo",
            __now_archi_type: this.archi_type,
            tag: this.tag
          }).then((res1) => {
            let data = res1.data.data;
            this.formDesignData = res.data.data;
            // 新版流程表单
            this.newFormFlow = !!(data?.recover?.cells)
            this.displayData = data;
            this.editFields = data?.editFields || {};
            this.loading = false;
            this.prepared = true
          });
        }
      }).catch((err) => {
        console.error(err);
        this.loading = false;
      });
    },
    /**
     * @desc: 获取绑定参数
     */
    getParams() {
      const sourceConfig = this.element.sourceConfig || [];
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = '',
          urlParamKey = '',
          statusCode = '',
          fixedValue = '',
          systemKey = '',
          systemCode = ''
        } = sourceConfig[i];
        if (originType === 'pageStatus' && statusCode) {
          const result = this._PageCustomStatus[statusCode] === undefined ? (this._APPCustomStatus[statusCode] || null) : this._PageCustomStatus[statusCode]
          this.$set(sourceParams, key, result);
        } else if (originType === 'url' && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (componentId && field && key) {
          // 普通从组件获取
          let sourceComponent = getComponentById(this.subComponentData, componentId);
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(this.groupComponents, componentId);
          }
          const componentName = componentId.toString().split('-')[0];
          if (field === 'DATAVIEWSELECT' && ['CommonTree', 'CommonTableContainer', 'CommonLoopContainer'].includes(componentName)) {
            this.$set(sourceParams, key, sourceComponent?.resolveData || []);
          } else {
            const result = sourceComponent?.resolveData ? sourceComponent.resolveData[field] : '';
            this.$set(sourceParams, key, result);
          }
        } else if (originType === 'fixed') {
          // 固定参数
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === 'system') {
          // 系统参数
          let value = '';
          if (systemKey === 'userInfo') {
            value = this.$GetUserInfo(systemCode);
          } else if (systemKey === 'targetArchi') {
            value = this.$GetTargetArchi(systemCode);
          } else {
            let systemObject = JSON.parse(localStorage.getItem(systemKey));
            if (Object.prototype.toString.call(systemObject) !== '[object Object]') {
              systemObject = {};
            }
            value = systemObject?.[systemCode] || '';
          }
          this.$set(sourceParams, key, value);
        }
      }
      return sourceParams;
    },
  },
};
</script>

<style lang="less" scoped>
.global-dialog-overscroll-box{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 6px!important;
  box-sizing: border-box;
  padding: 5px;
  transition: all .2s;
  .global-dialog-title {
    display: flex;
    height: 44px;
    box-sizing: border-box;
    border-bottom: 1px solid #f2f3f5;
    padding: 11px 0;
    h3 {
      flex: 1;
      line-height: 22px;
      font-size: 18px;
      color: #303133;
      box-sizing: border-box;
      padding-right: 20px;
      text-align: left;
    }
    .fullscreen {
      margin-right: 30px;
      // color: #909399;
      color: #4D535E;
      line-height: 22px;
    }
  }
  :deep(.email-dialog-box) {
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding: 0;
    .base-type-label {
      text-align: left;
    }
    .el-form-item__label {
      text-align: left;
      margin-left: 0;
    }
    .form-parser-warp {
      text-align: left!important;
    }
    .form-parser-container {
      min-height: 100%;
    }
  }
  .footer{
    padding: 10px 0;
  }
  .float-operate-wrap {
    z-index: 50000;
  }
  .p20 {
    padding: 20px;
  }
}
</style>
