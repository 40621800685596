/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-18 09:23:36
 * @FilePath: \dataview-viewer-test\src\custom-component\login\lang\zh.js
 */

export default {
  "components.LoginByInput.642272-0": "扫码登录更安全",
  "components.LoginByInput.642272-1": "密码登录",
  "components.LoginByInput.642272-2": "短信登录",
  "components.LoginByInput.642272-3": "账号名/邮箱/手机号",
  "components.LoginByInput.642272-4": "请输入登录密码",
  "components.LoginByInput.642272-5": "登录",
  "components.LoginByInput.642272-6": "忘记密码",
  "components.LoginByInput.642272-7": "请输入手机号",
  "components.LoginByInput.642272-8": "请输入验证码",
  "components.LoginByInput.642272-9": "获取验证码",
  "components.LoginByInput.642272-10": "秒后重试",
  "components.LoginByInput.642272-11": "请完整填写表单",
  "components.LoginByInput.642272-12": "登录中...",
  "components.LoginByInput.642272-13": "登录失败!",
  "components.LoginByInput.642272-14": "登录成功!",
  "components.LoginByInput.642272-15": "编辑器环境不做后续操作，请前往dev或线上环境测试效果!",
  "components.LoginByInput.642272-16": "获取系统基本信息失败！",
  "components.LoginByInput.642272-17": "请输入正确的手机号",
  "components.LoginByInput.642272-18": "短信验证码已发送，可能会有延后，请耐心等待",
  "components.LoginByInput.642272-19": "验证码获取失败！",
  "components.LoginByInput.642272-20": "手机信息错误",
  "components.LoginByCode.634977-0": "手机扫码，安全登录",
  "components.LoginByCode.634977-1": "打开微信 | 企业微信扫一扫  快速登录/注册",
  "components.LoginByCode.634977-2": "扫码成功请在微信中轻触同意即可登录",
  "components.LoginByCode.634977-3": "你已取消此次登录",
  "components.ResetPassword.785871-0": "忘记密码",
  "components.ResetPassword.785871-1": "您注册时使用的手机号或邮箱",
  "components.ResetPassword.785871-2": "请输入验证码",
  "components.ResetPassword.785871-3": "获取验证码",
  "components.ResetPassword.785871-4": "秒后重试",
  "components.ResetPassword.785871-5": "下一步",
  "components.ResetPassword.785871-6": "新密码",
  "components.ResetPassword.785871-7": "重复密码",
  "components.ResetPassword.785871-8": "重置密码",
  "components.ResetPassword.785871-9": "返回上一步",
  "components.ResetPassword.785871-10": "返回登陆",
  "components.ResetPassword.785871-11": "密码至少8位，由数字、字母、特殊字符组成。",
  "components.ResetPassword.785871-12": "密码不一致",
  "components.ResetPassword.785871-13": "请完整填写表单",
  "components.ResetPassword.785871-14": "重置密码失败",
  "components.ResetPassword.785871-15": "请输入正确的手机号",
  "components.ResetPassword.785871-16": "短信验证码已发送，可能会有延后，请耐心等待",
  "components.ResetPassword.785871-17": "发送验证码失败",
  "components.ResetPassword.785871-18": "手机信息错误",
  "login.mixin.362796-0": "编辑器环境不做后续操作，请前往dev或线上环境测试效果!",
  "login.mixin.362796-1": "获取系统基本信息失败！",
  "login.mixin.362796-2": "登录成功!",
  "login.mixin.362796-3": "登录失败!",
  "login.mixin.362796-4": "获取系统配置失败！",
  "login.mixin.362796-5": "无效路径",
  "login.mixin.362796-6": "获取组织架构失败！"
}


