/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-05-11 18:04:15
 * @FilePath: \dataview-viewer-test\src\components\fileUpload\lang\en.js
 */
export default {
  "fileUpload.Index.383596-0": "Supported formats only",
  "fileUpload.Index.383596-1": "format files upload",
  "fileUpload.Index.383596-2": "Drop file here",
  "fileUpload.Index.383596-3": "or",
  "fileUpload.Index.383596-4": "Click to upload",
  "fileUpload.Index.383596-5": "Drag and drop images",
  "fileUpload.Index.383596-6": "or click to upload",
  "fileUpload.Index.383596-7": "Click here",
  "fileUpload.Index.383596-8": "",
  "fileUpload.Index.383596-9": "Screenshot",
  "fileUpload.Index.383596-10": "Preview",
  "fileUpload.Index.383596-11": "Edit",
  "fileUpload.Index.383596-12": "Download",
  "fileUpload.Index.383596-13": "Delete",
  "fileUpload.Index.383596-14": "Not uploaded",
  "fileUpload.Index.383596-15": "View larger image",
  "fileUpload.Index.383596-16": "Upload File",
  "fileUpload.Index.383596-17": "Unknown",
  "fileUpload.Index.383596-18": "Please upload",
  "fileUpload.Index.383596-19": "format files!",
  "fileUpload.Index.383596-20": "dwg file conversion in progress, please try again later!",
  "fileUpload.Index.383596-21": "Please try again later!",
  "fileUpload.Index.383596-22": "Unable to open files of this format!",
  "fileUpload.Index.383596-23": "Pasted content is not an image",
  "fileUpload.Index.383596-24": "Upload of this type of image is restricted!",
  "fileUpload.Index.383596-25": "Failed to upload screenshot!",
  "newFiles.index.508307-0": "Drop file here",
  "newFiles.index.508307-1": "or",
  "newFiles.index.508307-2": "Click to upload",
  "newFiles.index.508307-3": "Preview",
  "newFiles.index.508307-4": "Edit",
  "newFiles.index.508307-5": "Download",
  "newFiles.index.508307-6": "Delete",
  "newFiles.index.508307-7": "None",
  "newFiles.index.508307-8": "View larger image",
  "newFiles.index.508307-13": "Unable to open files of this format!",
  "newFiles.index.034401-0": "Upload File",
  "newFiles.index.034401-1": "Unknown",
  "newFiles.index.034401-2": "Please upload",
  "newFiles.index.034401-3": "format files!"
}