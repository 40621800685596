/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-24 10:35:42
 * @FilePath: \dataview-viewer-test\src\components\fileUpload\lang\zh.js
 */

export default {
  "fileUpload.Index.383596-0": "仅支持",
  "fileUpload.Index.383596-1": "格式文件上传",
  "fileUpload.Index.383596-2": "拖拽上传",
  "fileUpload.Index.383596-3": "或",
  "fileUpload.Index.383596-4": "点击上传",
  "fileUpload.Index.383596-5": "拖拽图片",
  "fileUpload.Index.383596-6": "或点击上传",
  "fileUpload.Index.383596-7": "点击此处",
  "fileUpload.Index.383596-8": "粘贴",
  "fileUpload.Index.383596-9": "截图",
  "fileUpload.Index.383596-10": "预览",
  "fileUpload.Index.383596-11": "编辑",
  "fileUpload.Index.383596-12": "下载",
  "fileUpload.Index.383596-13": "删除",
  "fileUpload.Index.383596-14": "未上传",
  "fileUpload.Index.383596-15": "查看大图",
  "fileUpload.Index.383596-16": "上传文件",
  "fileUpload.Index.383596-17": "未知",
  "fileUpload.Index.383596-18": "请上传",
  "fileUpload.Index.383596-19": "格式的文件！",
  "fileUpload.Index.383596-20": "dwg文件转换中,请稍后再试！",
  "fileUpload.Index.383596-21": "请稍后再试！",
  "fileUpload.Index.383596-22": "无法打开此格式的文件！",
  "fileUpload.Index.383596-23": "粘贴内容不是图片",
  "fileUpload.Index.383596-24": "限制此类型图片上传！",
  "fileUpload.Index.383596-25": "上传截图失败！",
  "newFiles.index.508307-0": "拖拽上传",
  "newFiles.index.508307-1": "或",
  "newFiles.index.508307-2": "点击上传",
  "newFiles.index.508307-3": "预览",
  "newFiles.index.508307-4": "编辑",
  "newFiles.index.508307-5": "下载",
  "newFiles.index.508307-6": "删除",
  "newFiles.index.508307-7": "无",
  "newFiles.index.508307-8": "查看大图",
  "newFiles.index.508307-13": "无法打开此格式的文件！",
  "newFiles.index.034401-0": "上传文件",
  "newFiles.index.034401-1": "未知",
  "newFiles.index.034401-2": "请上传",
  "newFiles.index.034401-3": "格式的文件！"
}


