/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-23 17:10:37
 * @FilePath: \dataview-viewer-test\src\custom-component\special\lang\en.js
 */
export default {  
  "special.CommonConfigPage.371916-0": "Edit",
  "special.CommonConfigPage.371916-1": "Submit",
  "special.CommonConfigPage.371916-2": "Invalid data, please try again later",
  "special.CommonConfigPage.371916-3": "Failed to request data, please try again later",
  "special.CommonConfigPage.371916-4": "Failed to add data",
  "special.CommonConfigPage.371916-5": "Saved successfully"
}