/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-22 00:41:33
 * @FilePath: \dataview-viewer-test\src\custom-component\container\lang\zh.js
 */

export default {
  "container.CommonTableContainer.351873-0": "图片",
  "container.CommonTableContainer.351873-1": "链接",
  "container.CommonTableContainer.351873-2": "操作",
  "container.CommonTableContainer.351873-3": "序号",
  "container.CommonTableContainer.351873-4": "周天",
  "container.CommonTableContainer.351873-5": "周一",
  "container.CommonTableContainer.351873-6": "周二",
  "container.CommonTableContainer.351873-7": "周三",
  "container.CommonTableContainer.351873-8": "周四",
  "container.CommonTableContainer.351873-9": "周五",
  "container.CommonTableContainer.351873-10": "周六",
  "container.CommonTableContainer.351873-11": "参数必填校验未通过",
  "container.CommonTableContainer.351873-12": "请求配置错误",
  "container.CommonTableContainer.351873-13": "前端排序故障---表格1",
  "container.CommonTableContainer.351873-14": "操作失败！",
  "container.CommonTableContainer.351873-15": "事件触发， --------",
  "container.CommonTableContainer.351873-16": "操作失败!",
  "container.CommonTableContainer.351873-17": "请正确配置事件!",
  "container.CommonTableContainer.351873-18": "操作成功！",
  "container.CommonTableContainer.351873-19": "事件未配置触发对象!",
  "container.CommonTableContainer.351873-20": "00000后端事件错误",
  "container.CommonTableContainer.351873-21": "是否确认删除?",
  "container.CommonTableContainer.351873-22": "提示",
  "container.CommonTableContainer.351873-23": "确定",
  "container.CommonTableContainer.351873-24": "取消",
  "container.CommonTableContainer.351873-25": "年",
  "container.CommonTableContainer.351873-26": "月",
  "container.CommonTableContainer.351873-27": "日",
  "container.CommonTableContainer.351873-28": "时",
  "container.CommonTableContainer.351873-29": "分",
  "container.CommonTableContainer.351873-30": "秒",
  "container.CommonTableContainer.351873-31": "第{0}周",
  "container.CommonTableContainer.351873-32": "导出中....",
  "container.CommonTableContainer.351873-33": "导出失败！数据验证错误。",
  "container.CommonTableContainer.351873-34": "暂无数据可供导出！",
  "container.CommonTableContainer.351873-35": "请选择需要导出的数据！",
  "container.CommonTableContainer.351873-36": "导出配置错误！",
  "container.CommonTableContainer.351873-37": "导出配置错误！不存在导出数据",
  "container.CommonTableContainer.351873-38": "导出成功！",
  "container.CommonTableContainer.351873-39": "导出失败！",
  "container.CommonTableContainer.351873-40": "组件【{0}】数据接口无【id】字段返回，请检查接口配置！",
  "container.CommonTableContainer.351873-41": "组件【{0}】数据接口存在【{1}】个【id】字段返回，请检查接口配置！以保证准确性！",
  "container.CommonTableContainer.351873-42": "{0}_{1}-{2}米",
  "container.CommonTableContainer.351873-43": "{0}_{1}米",
  "container.CommonTableContainer.351873-44": "暂无数据"
}


