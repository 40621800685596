import { render, staticRenderFns } from "./ChartRing.vue?vue&type=template&id=35a1e924&scoped=true"
import script from "./ChartRing.vue?vue&type=script&lang=js"
export * from "./ChartRing.vue?vue&type=script&lang=js"
import style0 from "./ChartRing.vue?vue&type=style&index=0&id=35a1e924&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a1e924",
  null
  
)

export default component.exports