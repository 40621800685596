<template>
  <div>
    <ul class="vertical-flex moduleUl">
      <li
        v-for="(item, index) in moduleList"
        :key="index"
        :class="[
          'vertical-flex moduleLi',
          { moduleLi_active: activeModule === item.id }
        ]"
        @click="onSelectModule(item)"
      >
        <a :class="['iconfont', item.icon]"></a>
        <span>{{ item.name }}</span>
      </li>
    </ul>

    <div class="modulePanel">
      <ul class="tabUl">
        <li
          v-for="(item, index) in tabList"
          :key="index"
          :class="[
            'vertical-flex tabLi',
            { tabLi_active: activeTab === item.id }
          ]"
        >
          <a :class="['iconfont', item.icon]"></a>
          <span>{{ item.name }}</span>
        </li>
      </ul>

      <ViewerGISModel
        ref="modelList"
        v-show="activeModule === 'model' && activeTab === 'modelTree'"
        class="moduleWrap"
        :element.sync="element"
        :bindViewerId="bindViewerId"
      ></ViewerGISModel>

      <ViewerGISScene
        ref="sceneList"
        v-show="activeModule === 'scene' && activeTab === 'viewPoint'"
        class="moduleWrap"
        :element.sync="element"
        :bindViewerId="bindViewerId"
      ></ViewerGISScene>
    </div>
  </div>
</template>

<script>
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import ViewerGISModel from './components/ViewerGISModel';
import ViewerGISScene from './components/ViewerGISScene';

export default {
  name: 'ViewerGISModule',
  components: {
    ViewerGISModel,
    ViewerGISScene
  },
  data() {
    return {
      moduleList: [
        { id: 'model', name: '模型', icon: 'icongis_moxingshu' },
        { id: 'scene', name: '场景', icon: 'icongis_changjing' }
      ],
      activeModule: 'model',
      tabList: [{ id: 'modelTree', name: '模型', icon: 'icongis_tuceng' }],
      activeTab: 'modelTree',
      bindViewerId: null
    };
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    isGroup: {
      type: Boolean
    },
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  mixins: [CustomComponentMixin],
  computed: {
    ...mapState(['componentData', 'originComponentData'])
  },
  mounted() {
    this.initEventListener();

    if (this.element.viewerBindId) {
      if (this.checkBind(this.element.viewerBindId)) {
        this.bindViewerId = this.element.viewerBindId;
      }
    }
  },
  methods: {
    initEventListener() {
      eventBus.$on('ViewerGISModule.GetSource', (eid, cb) => {
        if (eid !== this.element.id) return;

        const loop = (array = [], resList = []) => {
          for (let item of array) {
            if (item.component !== 'ViewerGIS') {
              if (item.children && item.children.length > 0)
                loop(item.children, resList);
              continue;
            }

            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
            resList.push({
              id: item.id,
              name: item.name
            });
          }

          return resList;
        };

        const comps = [];
        loop(this.subComponentData, comps);
        // loop(this.subComponentData, comps);

        cb({
          list: comps,
          bindId: this.bindViewerId
        });
      });

      eventBus.$on('ViewerGISModule.BindViewer', (eid, bindId) => {
        if (eid !== this.element.id) return;
        if (!this.checkBind(bindId)) return;

        this.element.viewerBindId = bindId;
        this.bindViewerId = bindId;
        this.$message.success(`[${this.element.name}] 绑定成功`);
      });
    },
    checkBind(bindId = this.bindViewerId) {
      if(!bindId) return false;

      const checkFunc = (bid, list) => {
        let hasComp = false;
        if(!list) return hasComp;

        for (let comp of list) {
          if (comp.children) {
            const flag = checkFunc(bid, comp.children);

            if (flag) {
              hasComp = true;
              break;
            }
          }
          if (comp.id !== bid) continue;

          hasComp = true;
          break;
        }

        return hasComp;
      };

      const hasComp = checkFunc(bindId, this.subComponentData);
      // const hasOriginComp = checkFunc(bindId, this.subComponentData);

      if (!hasComp)
        return this.$message.error(
          `[${this.element.name}] 绑定失效，组件可能已经被删除`
        );

      return hasComp;
    },
    onSelectModule(item) {
      this.activeModule = item.id;
      switch (this.activeModule) {
        case 'model':
          this.tabList = [
            { id: 'modelTree', name: '模型', icon: 'icongis_tuceng' }
          ];
          this.activeTab = 'modelTree';
          break;
        case 'scene':
          this.tabList = [
            { id: 'viewPoint', name: '视点', icon: 'iconbim_shidianliebiao' }
          ];
          this.activeTab = 'viewPoint';
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.vertical-flex {
  display: inline-flex;
  flex-direction: column;
  line-height: initial;
  vertical-align: middle;
}

.moduleUl {
  float: right;
  width: 48px;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  background-color: inherit;
  color: inherit;

  .moduleLi {
    width: 48px;
    height: 56px;
  }

  .moduleLi:hover {
    color: var(--themeColor);
  }

  .moduleLi_active {
    color: var(--themeColor);
  }

  .moduleLi a {
    padding-top: 10px;
    font-size: 18px;
    color: inherit;
  }

  .moduleLi span {
    padding-bottom: 10px;
    font-size: 14px;
    color: inherit;
  }
}

.modulePanel {
  float: left;
  padding: 0 !important;
  overflow: hidden;
  width: calc(100% - 48px);
  height: 100%;
  background-color: inherit;
  color: inherit;

  .tabUl {
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    .tabLi {
      float: left;
      width: 70px;
      height: 56px;
    }

    .tabLi_active {
      background-color: #8d8d8d;
    }

    .tabLi a {
      padding-top: 10px;
      font-size: 18px;
      color: inherit;
    }

    .tabLi span {
      padding-bottom: 10px;
      font-size: 14px;
      color: inherit;
    }
  }

  .moduleWrap {
    width: 100%;
    height: calc(100% - 56px);
  }
}
</style>
