<!--
 * @Description: 进度图
 * @Author: luocheng
 * @Date: 2022-05-07 09:52:27
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-06-28 15:43:32
-->
<template>
  <div class="chart-completion-ratio"
		:class="{ 'self-ddaption': selfDdaption}"
		v-loading="getting"
		:element-loading-background="loadingBackColor"
    :style="{
      height: (height - 32) + 'px'
    }">
		<el-scrollbar v-autoScroll="autoScrollConfig" style="height:100%">
			<section class="complete-item"
				v-for="(item, index) in renderList"
				:key="index"
				:style="{
					marginTop: selfDdaption ? '30px' : (scaleSize(attributes.barMarginTop) + 'px'),
					paddingRight: scaleSize(60 * attributes.percentFontSize / 16) + 'px'
				}"
			>
				<p class="name" v-if="attributes.showTitle"
					:style="{
						color: attributes.titleColor,
						fontSize: scaleSize(attributes.titleFontSize) + 'px',
						top: scaleSize(-1 * (attributes.titleFontSize + 10)) + 'px'
					}"
				>{{ item.name }}</p>
				<p class="count" v-if="attributes.showCompleteCount"
					:style="{
						color: attributes.completeColor,
						fontSize: scaleSize(attributes.completeFontSize) + 'px',
						top: -1 * (attributes.completeFontSize + 10) + 'px',
						right: 5 * attributes.percentFontSize + 'px'
					}"
				>{{ item.completeData || 0 }}</p>
				<p class="percent" v-if="attributes.showPercent"
					:style="{
						color: attributes.percentColor,
						fontSize: scaleSize(attributes.percentFontSize) + 'px',
						top: (attributes.barHeight - attributes.percentFontSize) / 2 + 'px',
					}"
				>{{ item.percent }}</p>
				<div class="total-box"
					:style="{
						background: attributes.transparentBg ? 'transparent' : attributes.background,
						height: attributes.barHeight + 'px',
						width: isNaN(attributes.barWidth) ? attributes.barWidth : attributes.barWidth + 'px',
						borderRadius: attributes.isRadius ? attributes.barHeight / 2 + 'px' : 'none',
						border: attributes.showBorder ? '1px solid ' + attributes.borderColor : 'none'
					}"
				>
					<div class="complete-box"
						:style="{
							width: item.percent,
							background: attributes.completeBg,
							borderRadius: attributes.isRadius ? attributes.barHeight / 2 + 'px' : 'none',
							'background-image': item.gradientStyle ? item.gradientStyle : ''
						}"
					></div>
				</div>
			</section>
		</el-scrollbar>
  </div>
</template>

<script>
import { Scrollbar } from 'element-ui'
/* eslint-disable */
import mixin from './mixin';

export default {
  name: 'ChartCompletionRatio',
  mixins: [mixin],
	components: {
		'el-scrollbar': Scrollbar
	},
	data() {
		return {
			option: null,
			renderList: []
		};
	},
	created() {
		this.getOptions();
	},
	directives: {
		autoScroll: {
			bind(el, binding) {
				if(binding.value.autoScroll) {
					const wrap = el.querySelector('.el-scrollbar__wrap');
					el.dataset.autoScroll = true;
					el.dataset.autoScrollSuspend = false;
					el.dataset.autoScrollSpeed = binding.value.speed;
					let start = null;
					const scrollAnimationFrameFn = (timeStamp) => {
						let timeTemp = timeStamp;
						// 如果非第一次 且开启滚动 且没有暂停
						if(start && el.dataset.autoScroll === 'true' && el.dataset.autoScrollSuspend !== 'true') {
							const speedBase = Number(el.dataset.autoScrollSpeed);
							let speed = 0;
							if(speedBase) {
								// 时间戳差异 / 帧 * 速度
								speed = (timeStamp - start) / (1000 / 60) * speedBase;
							}
							const reSpeed = Math.round(speed);
							// 如果取整大于了 0. 3 那就停顿一下 主要用于高刷
							if(Math.abs(reSpeed - speed) < 0.3) {
								wrap.scrollTop += Math.round(speed);
								if(wrap.scrollTop + wrap.clientHeight >= wrap.scrollHeight) {
									wrap.scrollTop = 0;
								}
							} else {
								timeTemp = start
							}
						}
						start = timeTemp;
						el.dataset.requestAnimationFrameId = requestAnimationFrame(scrollAnimationFrameFn)
					}
					requestAnimationFrame(scrollAnimationFrameFn);
				}
				el.onmouseenter = () => {
					el.dataset.autoScrollSuspend = true
				}
				el.onmouseleave  = () => {
					el.dataset.autoScrollSuspend = false
				}
			},
			update(el, binding) {
				// 更新 主要用于编辑器实时的响应编辑
				if(binding.value.autoScroll !== binding.oldValue.autoScroll) {
					if(binding.value.autoScroll === true) {
						const wrap = el.querySelector('.el-scrollbar__wrap')
						el.dataset.autoScroll = true
						el.dataset.autoScrollSuspend = false
						el.dataset.autoScrollSpeed = binding.value.speed
						let start = null
						const scrollAnimationFrameFn = (timeStamp) => {
							let timeTemp = timeStamp;
							if(start && el.dataset.autoScroll === 'true' && el.dataset.autoScrollSuspend !== 'true') {
								const speedBase = Number(el.dataset.autoScrollSpeed);
								let speed = 0;
								if(speedBase) {
									speed = (timeStamp - start) / (1000 / 60) * speedBase;
								}
								const reSpeed = Math.round(speed);
								if(Math.abs(reSpeed - speed) < 0.3) {
									wrap.scrollTop += Math.round(speed);
									if(wrap.scrollTop + wrap.clientHeight >= wrap.scrollHeight) {
										wrap.scrollTop = 0;
									}
								} else {
									timeTemp = start
								}
							}
							start = timeTemp;
							el.dataset.requestAnimationFrameId = requestAnimationFrame(scrollAnimationFrameFn)
						}
						requestAnimationFrame(scrollAnimationFrameFn);
					} else {
						if(el.dataset.requestAnimationFrameId) {
							cancelAnimationFrame(Number(el.dataset.requestAnimationFrameId))
						}
					}
				}
				if(binding.value.speed !== binding.oldValue.autoScroll) {
					el.dataset.autoScrollSpeed = binding.value.speed
					if(binding.value.speed === '0') {
						if(el.dataset.requestAnimationFrameId) {
							console.log('停止滚动事件')
							cancelAnimationFrame(Number(el.dataset.requestAnimationFrameId))
						}
					}
				}
			},
			unbind(el, binding) {
				if(el.dataset.requestAnimationFrameId) {
					cancelAnimationFrame(Number(el.dataset.requestAnimationFrameId))
				}
			}
		}
	},
	computed: {
		// transparentBg: false,
    // isRadius: true,
    // showBorder: true,
    // borderColor: '#fff'
		attributes() {
			return this.chartData.attributes || {}
		},
		// 是否需要自适应占满整个盒子
		selfDdaption() {
			return !!this.attributes.selfDdaption
		},
		useSort() {
			return this.chartData.useSort;
		},
		isASC() {
			return this.chartData.isASC;
		},
		// 滚动配置
		autoScrollConfig() {
			return {
				autoScroll: !!this.attributes.autoScroll, // 是否自动滚动
				speed: this.attributes.autoScrollSpeed || 0 // 滚动速度 按每秒60帧算 一帧多少px
			}
		}
	},
	watch: {
		'$i18n.locale': {
			handler(n, o) {
				if(n && n !== o) {
					this.getOptions()
				}
			}
		}
	},
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {Object} data 数据对象
		 */
		async setOptions(data, freeLoop) {
			this.renderList = [];
			// TIPS 非填充数据
			if(Array.isArray(this.chartData.staticConfig) && this.chartData.staticConfig.length) {
				const { completionRatio = [] } = this.chartData.staticConfig[0]
				const { useGradient = false, gradientRange = [], fixedCount = 2} = this.attributes;
				if (freeLoop && Array.isArray(data)) {
					/**
					 * 国际化 英文翻译
					 */
					if(this?.$i18n?.locale === 'en') {
						// 转换内容
						const tasks = [];

						if(completionRatio?.[0]) {
							tasks.push({ target: completionRatio?.[0], key: 'completeText' });
						}
						const { completeFiled } = completionRatio?.[0];
						data.forEach((el) => {
							tasks.push({ target: el, key: completeFiled });
						})
						await this.translate2English(tasks);
					}
					// 自循环
					const { complete, completeText, completeFiled, total } = completionRatio?.[0];
					data.forEach((item, i) => {
						const completeData = item?.[complete] || 0;
						const totalData = item?.[total] || 0;
						const result = {
							name: completeText || item?.[completeFiled] || '-',
							completeData,
							totalData,
							percent: `${!totalData ? `${(0).toFixed(fixedCount)}` : ((completeData / totalData) * 100).toFixed(fixedCount)}%`
						}
						if(useGradient) {
							result.gradientStyle = `linear-gradient(to right, ${gradientRange[i % gradientRange.length].join(',')})`
						} else {
							result.gradientStyle = ''
						}
						this.renderList.push(result)
					});
				} else {
					/**
					 * 国际化 英文翻译
					 */
					if(this?.$i18n?.locale === 'en') {
						const tasks = [];
						
						completionRatio.forEach((el) => {
							const { completeFiled } = el;
							tasks.push({ target: el, key: 'completeText' });
							tasks.push({ target: data, key: completeFiled });
						})
						await this.translate2English(tasks);
					}
					// 逐一
					completionRatio.forEach((ele, i) => {
						const { complete, completeText, completeFiled, total } = ele;
						const completeData = data?.[complete] || 0;
						const totalData = data?.[total] || 0;
						const item = {
							name: completeText || data?.[completeFiled] || '-',
							completeData,
							totalData,
							percent: `${!totalData ? `${(0).toFixed(fixedCount)}` : ((completeData / totalData) * 100).toFixed(fixedCount)}%`
						}
						if(useGradient) {
							item.gradientStyle = `linear-gradient(to right, ${gradientRange[i % gradientRange.length].join(',')})`
						} else {
							item.gradientStyle = ''
						}
						this.renderList.push(item)
					});
				}
				if(this.useSort) {
					this.renderList.sort((a, b) => this.isASC ? Number(a.completeData) - Number(b.completeData) : Number(b.completeData) - Number(a.completeData));
				}
			} else if (Array.isArray(this.chartData.interfaceConfig) && this.chartData.interfaceConfig.length) {
				const { completionRatio = [] } = this.chartData.interfaceConfig[0]
				const { useGradient = false, gradientRange = [], fixedCount = 2} = this.attributes;
				if (freeLoop && Array.isArray(data)) {
					/**
					 * 国际化 英文翻译
					 */
					if(this?.$i18n?.locale === 'en') {
						const tasks = [];
						if(completionRatio?.[0]) {
							tasks.push({ target: completionRatio?.[0], key: 'completeText' });
						}
						const { completeFiled } = completionRatio?.[0];
						data.forEach((el) => {
							tasks.push({ target: el?.[0], key: completeFiled });
						})
						await this.translate2English(tasks);
					}
					// 自循环
					const { complete, completeText, completeFiled, total } = completionRatio?.[0];
					data.forEach((item, i) => {
						const completeData = item?.[complete] || 0;
						const totalData = item?.[total] || 0;
						const result = {
							name: completeText || item?.[completeFiled] || '-',
							completeData,
							totalData,
							percent: `${!totalData ? `${(0).toFixed(fixedCount)}` : ((completeData / totalData) * 100).toFixed(fixedCount)}%`
						}
						if(useGradient) {
							result.gradientStyle = `linear-gradient(to right, ${gradientRange[i % gradientRange.length].join(',')})`
						} else {
							result.gradientStyle = ''
						}
						this.renderList.push(result)
					});
				} else {
					/**
					 * 国际化 英文翻译
					 */
					if(this?.$i18n?.locale === 'en') {
						const tasks = [];
						completionRatio.forEach((el) => {
							const { completeFiled } = el;
							tasks.push({ target: el, key: 'completeText' });
							tasks.push({ target: data, key: completeFiled });
						})
						await this.translate2English(tasks);
					}
					// 逐一
					completionRatio.forEach((ele, i) => {
						const { complete, completeText, completeFiled, total } = ele;
						const completeData = data?.[complete] || 0;
						const totalData = data?.[total] || 0;
						const item = {
							name: completeText || data?.[completeFiled] || '-',
							completeData,
							totalData,
							percent: `${!totalData ? `${(0).toFixed(fixedCount)}` : ((completeData / totalData) * 100).toFixed(fixedCount)}%`
						}
						if(useGradient) {
							item.gradientStyle = `linear-gradient(to right, ${gradientRange[i % gradientRange.length].join(',')})`
						} else {
							item.gradientStyle = ''
						}
						this.renderList.push(item)
					});
				}
				if(this.useSort) {
					this.renderList.sort((a, b) => this.isASC ? Number(a.completeData) - Number(b.completeData) : Number(b.completeData) - Number(a.completeData));
				}
			} else {
				// TIPS 否则安装填充数据进行初始化
				const { useGradient = false, gradientRange = []} = this.attributes
				if(Array.isArray(data) && data.length) {
					data.forEach((item, i) => {
						if(useGradient) {
							item.gradientStyle = `linear-gradient(to right, ${gradientRange[i % gradientRange.length].join(',')})`
						} else {
							item.gradientStyle = ''
						}
						this.renderList.push(item)
					})
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.chart-completion-ratio{
  height: 100%;
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
	// overflow-y: auto;
	.complete-item{
		box-sizing: border;
		position: relative;
		padding: 0px 10px;
		padding-right: 60px;
		margin-top: 30px;
		p{
			color: #fff;
			position: absolute;
			font-size: PingFang SC;
			&.name{
				top: -24px;
				line-height: 1;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				width: calc(100% - 120px); // 暂时取120px 的间隔
				text-align: left;
			}
			&.count{
				line-height: 1;
				right: 70px;
				top: -24px;
				font-weight: bold;
			}
			&.percent{
				right: 0;
			}
		}
		.total-box{
			height: 40px;
			width: 100%;
			box-sizing: border-box;
			background: #f2f3f5;
			border-radius: 2px;
			.complete-box{
				background: @theme;
				height: 100%;
			}
		}
	}
}
.self-ddaption{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.complete-item{
		min-height: 20px;
	}
}
</style>