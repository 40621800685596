/*
 * @Description: 基础容器
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:06
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-30 15:06:07
 * @LastEditTime: 2022-04-28 10:09:40
 */

export const CommonText = {
	component: 'CommonText',
	name: '文本框',
	icon: 'icon-wenbenbiaoqian',
	isLock: false, // 是否锁定
	needCommonStyle: true,
	span: -1,
	statusConfig: {
		displayShow: true,
		isShow: true, // 是否显示
		ellipsis: false, // 换行问题
		clamp: 1,
		nullText: '',
		bold: false,
		italic: false,
		useToFixed: false,
		toFixedCount: 2
	},
	style: {
		width: 'auto',
		minWidth: ['dataview', 'console'].includes(sessionStorage.getItem('editorType')) ? 0 : 200,
		height: 32,
		fontSize: 14,
		fontWeight: 500,
		lineHeight: 32,
		letterSpacing: 0,
		textAlign: 'center',
		color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
		overflowX: 'hidden',
		overflowY: 'hidden',
		rotate: 0
	},
	propValue: '文本框'
};
// 基础组件
const baseComponent = {
	type: 'baseComponent',
	title: '基础组件',
	show: true,
	order: 1,
	componentList: [
		{
			component: 'CommonButton',
			type: 'button',
			name: '按钮',
			icon: 'icon-anniu',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '按钮',
			style: {
				height: '',
				width: '',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				fontSize: 12
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'primary', // primary / success / warning / danger / info / text
				size: '', // medium / small / mini
				plain: false,
				round: false,
				circle: false,
				loading: false,
				disabled: false,
				icon: '',
				autofocus: false,
				outId: ''
			},
			behaviors: [
				{
					label: '同步进度计划模型',
					value: 'onSyncPlanModel',
					desc: '参数配置名为schedule_id,即进度计划id'
				},
			]
		},
		CommonText,
		{
			component: 'CommonImage',
			name: '图片',
			icon: 'icon-tupian',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			statusConfig: {
				displayShow: true,
				isShow: true,
				objectFit: 'cover',
				hoverEffect: ''
			},
			style: {
				width: 200,
				height: 180,
				rotate: 0,
				mixBlendMode: 'normal'
			},
			propValue: ''
		},
		{
			component: 'CommonDialog',
			type: 'dialog',
			name: '弹窗',
			icon: 'icon-a-dulibiaodandanchuang',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			style: {
				minHeight: '',
				width: '',
				rotate: 0
			},
			propValue: '',
			statusConfig: {
				displayShow: true,
				isShow: true,
				firstType: 'dialog',
				showConfirm: true,
				confirmText: '确认',
				enConfirmText: 'Confirm',
				confirmType: 'primary',
				showCancel: true,
				cancelText: '取消',
				enCancelText: 'Cancel',
				cancelType: '',
				title: '这是标题',
				width: '50%',
				fullscreen: false,
				top: '5vh',
				drawerWidth: 400,
				modal: true,
				closeOnClickModal: false,
				closeOnPressEscape: true,
				showClose: true,
				center: false,
				showExcel: false,
				preferredType: 'form',
				useCache: false,
				createWithDetails: false,
				closeConfigCache: false,
				showShareBtn: true
			},
			contentConfig: {
				contentType: '', // 内容类型
				configType: '', // 配置类型 '' 普通类型， dynamic 动态配置
				dynamicComponent: '', // 动态组件
				objectUUID: '', // 数据对象
				viewUUID: '', // 视图ID
				pageId: '', // 页面id
				formUUID: '', // 表单对象
				newFlow: false, // 启用新流程
				developPageRoute: '', // 首页页面路由
			},
			actionConfig: [
				{
					key: 'cancel',
					effect: false, // 是否有附加作用
					effectType: '', // 附加操作类型 请求类型
					canCustom: false, // 是否可以删除
					buttonType: '',
					text: '取消',
					postType: '', // 请求类型
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'confirm',
					effect: false,
					effectType: '',
					canCustom: false,
					postType: '', // 请求类型
					buttonType: 'primary',
					text: '确认',
					objectUUID: '', // 对象UUID
					viewUUID: '', // 视图UUID
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			],
			updateRulesConfig: {
				pattern: '',
				rules: [
					// {
					//   componentId: '' // 需要更新的组件
					// }
				]
			},
			formFillConfig: []
		},
		{
			component: 'CommonEmailShow',
			type: 'form',
			name: '邮件显示',
			icon: 'iconbiaodan-tc',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			style: {
				height: '100%',
				width: '100%'
			},
			propValue: '',
			statusConfig: {
				displayShow: true,
				isShow: true,
			}
		},
		{
			component: 'CommonTree',
			type: 'tree',
			name: '树形图',
			icon: 'icon-shuxingliebiao',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			style: {
				width: 200,
				height: 180,
				rotate: 0
			},
			propValue: '',
			statusConfig: {
				isShow: true,
				displayShow: true,
				showSearch: true,
				placeholder: '请输入名称',
				label: '组织名称',
				nodeKey: 'id',
				children: '子集架构',
				idUUID: '',
				disabled: false,
				isLeaf: false,
				lazy: false,
				emptyText: '暂无数据',
				hideActions: false,
				appendChild: false,
				defaultExpandAll: true,
				showCheckBox: false,
				showCheckAll: false,
				defaultSelectAll: false,
				checkAllText: '全选',
				defaultSelectFirst: true,
				rowExpend: true,
				checkOnClickNode: true,
				nodeSelectSingle: false,
				resolveFullNode: false,
				resolveCodes: '',
				defaultHideAction: true,
				checkStrictly: false,
				useTooltip: false
			},
			// 组件定义的自定义属性
			customStatus: {},
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column' // flex 主轴方向
			},
			actionLimit: {
				permission: [], // 权限
				archiLimit: [], // 架构限制
			},
			// 删除权限
			deleteLimit: {
				permission: [], // 权限
				archiLimit: [], // 架构限制
			},
			actionConfig: [
				{
					key: 'appendChild',
					type: 'appendChild',
					name: '新增子集(特殊)',
					color: '#409EFF',
					outSide: false,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'modify',
					type: 'modify',
					name: '编辑',
					color: '#409EFF',
					outSide: false,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'add',
					type: 'add',
					name: '新增',
					color: '#409EFF',
					outSide: false,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'rowClick',
					type: 'rowClick',
					name: '点击行',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			],
			// 图标配置
			iconConfig: {
				type: '',
				fixedConfig: {
					preIcon: '',
					actionIcon: ''
				},
				customConfig: {
					preList: [],
					actionList: []
				}
			},
			behaviors: [
				{
					label: '选中指定行',
					value: 'onSelectItem',
					desc: '参数名为【key】，参数值为【唯一标识】/【id的UUID】对应的UUID的取值'
				},
				{
					label: '改变数据状态',
					value: 'changeDataValue',
					desc: '必须的参数项：1.参数名【 rowKey 】，参数值为需要改变行的【唯一标识】/【id的UUID】对应的UUID的取值；2.参数名【 field 】, 参数值为需要改变的字段名（自定义）或UUID；3.参数名【value】, 参数值为新的值'
				}
			]
		},
		{
			component: 'CommonSwiper',
			type: 'swiper',
			name: '轮播图',
			icon: 'icon-lunbo',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			style: {
				width: '',
				rotate: 0,
				background: sessionStorage.getItem('editorType') === 'dataview' ? 'transparent' : '#fff',
			},
			propValue: '',
			statusConfig: {
				displayShow: true,
				isShow: true,
				height: '',
				initialIndex: 0,
				autoplay: true,
				interval: 3000,
				trigger: 'click',
				indicatorPosition: '',
				arrow: 'hover',
				type: '',
				loop: true,
				direction: 'horizontal',
				limit: 1,
				preview: false,
				showText: true,
				clickLink: false
			},
			swiperConfig: {
				valueType: 'fixed', // 值类型  fixed 固定值，database 数仓
				fixedConfig: [
					// {
					// 	img: '', // 图片链接
					// 	text: '', // 描述文本
					// 	href: '' // 跳转链接
					//  hrefType: '' // 跳转方式
					// }
				],
				databaseConfig: {
					objectUUID: '',
					viewUUID: '',
					imgUUID: '', // 图片链接
					textUUID: '', // 描述文本
					hrefUUID: '', // 跳转链接
					hrefTypeUUID: '' // 跳转方式
				}
			},
			paramsConfig: [], // 筛选参数
		},
		{
			component: 'CommonTabs',
			type: 'tabs',
			name: 'TAB容器',
			icon: 'icon-tabxuanxiangka',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: '', // 风格
				tabPosition: 'top', // 位置
				useAll: false,
				useSolt: false,
				defaultColor: '',
				activeColor: ''
			},
			propValue: '',
			style: {
				height: 44
			},
			// 用于直接渲染的数据
			containerData: null,
			tabOrigin: 'fixed', // 来源 fixed 固定 dictionary 字典(全局) dynamic 数据仓库
			tabConfig: [], // 固定参数
			dictionaryConfig: '', // 使用的字典
			dynamicConfig: {
				objectUUID: '',
				viewUUID: '',
				labelKey: '', // label字段UUID
				valueKey: '', // 值字段UUID
				fieldList: []
			}, // 使用的字典
		},
		{
			component: 'ScreenTabs',
			type: 'screenTabs',
			name: '大屏TAB',
			icon: 'icon-tabxuanxiangka',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				isActive: false,
				defaultText: 'tabs',
				activeText: 'tabs',
				defaultColor: '',
				activeColor: '#409eff',
				defaultImage: '',
				activeImage: '',
				objectFit: 'fill',
				fontSize: '16'
			},
			tabConfig: {
				showList: [],
				displayList: []
			},
			propValue: '',
			style: {
				height: 44
			},
		},
		{
			component: 'TabsMenu',
			type: 'tabsMenu',
			name: 'TAB菜单',
			icon: 'icon-tabxuanxiangka',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'horizontal',
				size: 4,
				scrollSize: 4,
				preIconDefault: '',
				preIconActive: '',
				nextIconDefault: '',
				nextIconActive: '',
				iconSize: 32
			},
			propValue: '',
			style: {
				height: 44
			},
			// 用于直接渲染的数据
			containerData: null,
			tabConfig: [], // 固定参数
		},
		{
			component: 'IconBtnMenu',
			type: 'iconBtnMenu',
			name: 'ICON按钮菜单',
			icon: 'icon-tubiao',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'horizontal',
				size: 4,
				align: 'left',
				gap: 0,
				iconSize: 32
			},
			propValue: '',
			style: {
				height: 44
			},
			metadata: {
				'value': 'value'
			},
			// 用于直接渲染的数据
			containerData: null,
			iconBtnMenuConfig: {
				configList: [],
				textConfig: {
					defaultColor: '#fff',
					clickColor: '#fff',
					dblColor: '#fff',
					size: '14',
					fontWeight: '400',
					lineHeight: '20'
				},
				// 图片/icon类型
				valueType: 'img'
			}, // 固定参数
		},
		{
			component: 'BtnGroup',
			type: 'btnGroup',
			name: '按钮组',
			icon: 'icon-tubiao',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'horizontal',
				size: 4,
				align: 'left',
				gap: 0,
				iconSize: 32
			},
			propValue: '',
			style: {
				height: 44
			},
			metadata: {
				'value': 'value'
			},
			// 用于直接渲染的数据
			containerData: null,
			btnGroupConfig: {
				configList: [],
				textConfig: {
					defaultColor: '#fff',
					clickColor: '#fff',
					dblColor: '#fff',
					size: '14',
					fontWeight: '400',
					lineHeight: '20'
				},
				// 图片/icon类型
				valueType: 'img'
			}, // 固定参数
		},
		// tag标签
		{
			component: 'TagMenu',
			type: 'tagMenu',
			name: '标签',
			icon: 'iconbiaoqian',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '标签一',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'horizontal',
				type: '',
				size: '',
				effect: 'light',
				// size: 4,
				align: 'left',
				gap: 0,
				iconSize: 32,
				closable: false, // 是否可关闭
				disabletransitions: false, // 是否禁用渐变动画
				hit: false // 是否有边框描边
			},
			style: {
				width: 200,
				height: 25,
				rotate: 0,
				mixBlendMode: 'normal'
			},
			metadata: {
				'value': 'value'
			},
			// 用于直接渲染的数据
			containerData: null,
			tagMenuConfig: {
				configList: [],
				textConfig: {
					defaultColor: '#fff',
					clickColor: '#fff',
					dblColor: '#fff',
					size: '14',
					fontWeight: '400',
					lineHeight: '20'
				},
				// 图片/icon类型
				valueType: 'img'
			}, // 固定参数
		},
		// 抽屉弹窗合并(隐藏抽屉)
		// {
		// 	component: 'CommonDrawer',
		// 	type: 'dialog',
		// 	name: '抽屉',
		// 	icon: 'iconyeqian',
		// 	isLock: false, // 是否锁定
		// 	needCommonStyle: true,
		// 	style: {
		// 		rotate: 0
		// 	},
		// 	propValue: '',
		// 	statusConfig: {
		// 		isShow: true,
		// 		direction: 'rtl',
		// 		showConfirm: true,
		// 		confirmText: '确认',
		// 		confirmType: 'primary',
		// 		showCancel: true,
		// 		cancelText: '取消',
		// 		cancelType: '',
		// 		modal: true,
		// 		size: 400,
		// 		withHeader: true,
		// 		title: '抽屉标题',
		// 		showClose: true,
		// 		appendToBody: false,
		// 		closeOnPressEscape: true,
		// 		wrapperClosable: true,
		// 		destroyOnClose: true,
		// 		modalAppendToBody: true
		// 	},
		// 	contentConfig: {
		// 		contentType: '', // 内容类型
		// 		objectUUID: '', // 数据对象
		// 		viewUUID: '', // 视图ID
		// 		pageId: '', // 页面id
		// 		formUUID: '' // 表单对象
		// 	},
		// 	actionConfig: [
		// 		{
		// 			key: 'cancel',
		// 			effect: false, // 是否有附加作用
		// 			effectType: '', // 附加操作类型 请求类型
		// 			canCustom: false, // 是否可以删除
		// 			buttonType: '',
		// 			text: '取消',
		// 			postType: '', // 请求类型
		// 			objectUUID: '', // 对象UUID
		// 			viewUUID: '', // 视图UUID
		// 		},
		// 		{
		// 			key: 'confirm',
		// 			effect: false,
		// 			effectType: '',
		// 			canCustom: false,
		// 			postType: '', // 请求类型
		// 			buttonType: 'primary',
		// 			text: '确认',
		// 			objectUUID: '', // 对象UUID
		// 			viewUUID: '', // 视图UUID
		// 		}
		// 	],
		//  updateRulesConfig: {
		//    pattern: '',
		//    rules: [
		//      {
		//        componentId: '' // 需要更新的组件
		//      }
		//    ]
		//  }
		// },
		{
			component: 'BaseIcon',
			name: 'ICON图标',
			icon: 'icon-tubiao',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'auto'
			},
			style: {
				width: 'auto',
				minWidth: ['dataview', 'console'].includes(sessionStorage.getItem('editorType')) ? 0 : 100,
				height: 32,
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 32,
				letterSpacing: 0,
				textAlign: 'center',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: 'el-plus'
		},
		{
			component: 'CommonTitle',
			name: '标题',
			icon: 'icon-biaoti',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				level: 3, // 标题级别 1 - 6
				ellipsis: false, // 换行问题
				clamp: 1,
				nullText: ''
			},
			style: {
				width: 300,
				height: 50,
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 50,
				letterSpacing: 0,
				textAlign: 'left',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: '标题'
		},
		{
			component: 'CommonAnnex',
			name: '附件',
			icon: 'icon-fujian',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			style: {
				width: 300,
				height: 'auot',
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 50,
				letterSpacing: 0,
				textAlign: 'left',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: '/'
		},
		{
			component: 'DropDownButton',
			name: '下拉按钮',
			icon: 'icon-anniu',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			style: {
				width: 120,
				height: 'auot',
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 50,
				letterSpacing: 0,
				textAlign: 'left',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: '/',
			actionConfig: [
				{
					key: 'modify',
					type: 'modify',
					name: '编辑',
					color: '#409EFF',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'add',
					type: 'add',
					name: '新增',
					color: '#409EFF',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				{
					key: 'rowClick',
					type: 'rowClick',
					name: '点击行',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			]
		},
		{
			component: 'CommonPagination',
			name: '分页组件',
			icon: 'icon-shaixuan',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'prev, pager, next, sizes, total',
				size: 15,
			},
			style: {
				width: '100%',
				height: 'auot',
				fontSize: 14,
				fontWeight: 500,
				lineHeight: 50,
				letterSpacing: 0,
				textAlign: 'left',
				color: sessionStorage.getItem('editorType') === 'dataview' ? '#fff' : '#333',
				overflowX: 'hidden',
				overflowY: 'hidden',
				rotate: 0
			},
			propValue: '/',
			actionConfig: []
		},
		{
			component: 'CalendarFilter',
			type: 'text',
			name: '日历筛选',
			icon: 'iconrilishaixuan',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '50',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'year', // year / month / week 
			},
			style: {
				height: 32,
				width: 160,
				rotate: 0,
				color: 'rgba(232, 209, 34, 1)',
				fontSize: 12,
				fontWeight: 400,
				lineHeight: 32,
			}
		}
	]
};

export default baseComponent;
