/*
` * @Description: 入口文件
 * @Author: luocheng
 * @Date: 2021-10-20 15:44:04
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-26 16:04:05
 */
import Vue from "vue";

import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/plugins/element";
import "@/assets/css/reset.css";
import ComponentBox from "@/components/parser/page/ComponentBox";
import ShapeBox from "@/components/parser/screen/ShapeBox";
import PageParser from "@/components/parser/Index";
import VueCodeMirror from "vue-codemirror";
import "codemirror/lib/codemirror.css";

import i18n from "@/locale/index";
import getLocalizedArrayMixin from "@/custom-component/mixins/getLocalizedArrayMixin";

window.BIMCCRouteList = [];
import { validToken, getInfoByToken } from "@/plugins/validToken";

Vue.use(VueCodeMirror);

Vue.component("ComponentBox", ComponentBox);
Vue.component("ShapeBox", ShapeBox);
Vue.component("PageParser", PageParser);

// 动态字段语言切换
Vue.prototype.$getLocalizedLabel = function (config) {
  return i18n.locale === "en"
    ? config.enLabel || config.zhLabel
    : config.zhLabel;
};

Vue.mixin(getLocalizedArrayMixin);

import InputItem from "@/custom-component/form/newParser/InputItem";
import PcParser from "@/custom-component/form/newParser/PcParser";
Vue.component("InputItem", InputItem);
Vue.component("PcParser", PcParser);
import actions from "./libs/action";
window.BIMCCRouterObj = router;
// 全局方法
import { getTargetArchi, getToken, getUserInfo, setToken } from "@/utils/tools";
Vue.prototype.$GetTargetArchi = getTargetArchi;
Vue.prototype.$GetToken = getToken;
Vue.prototype.$GetUserInfo = getUserInfo;
Vue.prototype.$QIANKUNActions = actions;
// 全局element配置
Vue.prototype.$ELEMENT = { size: "small", zIndex: 3000 };

if (window.location.origin.includes("bimcc.net")) {
  document.domain = "bimcc.net"; //仅在本地测试时解决跨域问题 线上全部同域
} else if (window.location.origin.includes("bimcc.com")) {
  document.domain = "bimcc.com"; //仅在本地测试时解决跨域问题 线上全部同域
} else if (window.location.origin.includes("ltsd-bim.com")) {
  document.domain = "ltsd-bim.com"; //仅在本地测试时解决跨域问题 线上全部同域
}
/* 编辑器debug日志 */
import { BIMCCEditorDebug, getLogId } from "@/utils/debugLog.js";
window.$EditorDebug = new BIMCCEditorDebug();
Vue.prototype.$getLogId = getLogId;
// 微前端配置
import { registerMicroApps } from "qiankun";
import eventBus from "@/plugins/eventBus";
import { getRenderData } from "@/apis/data/index";
import { routes } from "@/router/baseConfig.js";
// 重写router的push和replace方法 路由守卫的跳转bug
import Router from "vue-router";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
/**
 * @description: 设置配置的路由列表
 */
const setConfigRoutes = (arr) => {
  let result = [];
  if (!arr || !Array.isArray(arr)) return result;
  arr.forEach((ele) => {
    const { menu_path, menu_type, name, permissions, is_screen } = ele;
    // 权限
    const routeName = `${menu_path}${new Date().getTime()}`;
    // 类型 0 配置页面，1 跳转外链，2 功能组 ，3(微应用暂时弃用)， 4 跳转路由
    if (+menu_type !== 1) {
      const query = {};
      if (menu_path && menu_path.toString().includes("?")) {
        const str = menu_path.split("?")?.[1];
        const queryList = str.toString().split("&");
        queryList.forEach((ele) => {
          const eleArr = ele.toString()?.split("=");
          Vue.set(query, eleArr?.[0] || "", eleArr?.[1] || "");
        });
      }
      // if (menu_path.indexOf('work-todesk') > -1) {
      //   console.log(menu_path.toString().split('?')[0], '----11111111-----');
      // }
      const item = {
        ...ele,
        path: menu_path,
        // path: menu_path.toString().split('?')[0],
        name: routeName, // 暂时将path作为name
        meta: {
          title: name,
          isScreen: is_screen,
          auth: permissions,
        },
        query,
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/Index"),
      };
      result.push(item);
    }
    // 子路由
    if (
      +menu_type === 2 &&
      ele.children_list &&
      Array.isArray(ele.children_list) &&
      ele.children_list.length
    ) {
      const children = setConfigRoutes(ele.children_list);
      result = result.concat(children);
    }
  });
  return result;
};
const getApps = async () => {
  const res = await getRenderData({
    __method_name__: "dataList",
    object_uuid: "object62331adb0640c",
    view_uuid: "view6233e631e57c2",
    search: [],
    transcode: 0,
    project_id: +process.env.VUE_APP_BASE_ID, // 项目ID
  });
  if (res && res.status === 200 && res.data && res.data.data) {
    let appList = res.data.data || [];
    appList = appList.map((ele) => {
      return {
        ...ele,
        auth_key: ele?.app_orign_data?.[0]?.auth_key || "",
      };
    });
    store.commit("setAppList", appList);
    if (res.data.data) {
      sessionStorage.setItem("appList", JSON.stringify(res.data.data));
    }
    console.log("请求了应用列表");
    let routeList = [];
    for (let i = 0; i < appList.length; i++) {
      const appOriginData = appList[i]?.app_orign_data || [];
      const pathList = appOriginData[0]?.children_list || [];
      if (appOriginData.length) {
        const item = JSON.parse(JSON.stringify(appOriginData[0]));
        delete item.children_list;
        pathList.push({
          ...item,
        });
        const result = setConfigRoutes(pathList, true, "MyHome");
        routeList = routeList.concat(result);
      }
    }
    const parent = routes.find((ele) => ele.name === "MyHome");
    routes.splice(1, 1, {
      ...parent,
      children: parent.children.concat(routeList),
    });
    // 过滤routers
    const handle = (data) => {
      return data.filter((el) => {
        if (Array.isArray(el.children) && el.path) {
          el.children = handle(el.children);
        }
        return el.path;
      });
    };

    const resultRouter = handle(routes);
    console.log(resultRouter, "-----routes---");
    window.testRoutes = resultRouter;

    router.addRoutes(resultRouter);
  }
};

Vue.config.productionTip = false;
if (window.location.href?.includes("_key_=")) {
  sessionStorage.setItem("isLimit", 1);
}
(async () => {
  console.log("开始正式渲染", new Date());
  // 测试token
  // setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVSUQiOiIxMzg4MzA5NDQzMiIsIlBsYXRmb3JtIjoiV2ViIiwiZXhwIjoyMDAzNjIxMzAzLCJuYmYiOjE2ODgwMDIxMDMsImlhdCI6MTY4ODAwMjEwM30.5YD_vYKHUdETmumL46r9OvB-jEmK18SsxetczPALXM8');
  // 测试完毕
  // 校验token
  const token = getToken();
  if (token && +sessionStorage.getItem("isLimit") !== 1) {
    const tokenValid = await validToken(token);
    if (!tokenValid) {
      sessionStorage.setItem("needReLogin", 1);
    } else {
      setToken(tokenValid);
      await getInfoByToken();
    }
  }
  const appList = JSON.parse(localStorage.getItem("appList") || "[]") || [];
  if (!appList || !Array.isArray(appList) || appList.length === 0) {
    await getApps();
  }
  // 实例化
  const vue = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
  vue.$nextTick(() => {
    // 传入的是一个所有子应用的列表
    registerMicroApps([
      // 3.0系统功能
      // {
      //   name: 'iview-admin', // 已经注册的app名称
      //   // entry: 'https://nh.bimcc.net/app-vue/', // 子应用获取资源的域名
      //   entry: process.env.NODE_ENV === 'development' ? 'https://localhost:8083/' : 'https://nh.bimcc.net/app-vue/', // 子应用获取资源的域名
      //   container: '#container', // 子应用挂载的元素
      //   activeRule: '/app-vue', // 当到达此路由时候加载应用
      //   sandbox: true
      // },
      // vue3新功能
      {
        name: "micro-zy", // 已经注册的app名称
        // entry: 'https://nh.bimcc.net/nhmicro/', // 子应用获取资源的域名 || process.env.VUE_APP_V3_HOST
        entry:
          process.env.NODE_ENV === "development"
            ? "//localhost:3000/"
            : `${window.location.origin}/nhmicro/`, // 子应用获取资源的域名
        container: "#container", // 子应用挂载的元素
        activeRule: "/child-app", // 当到达此路由时候加载应用
        sandbox: true,
        props: {
          BIMCCRouterObj: router,
          BIMCCStoreObj: store,
          actions,
        }, //向子应用传递创建的全局状态
        loader: (loading) => {
          if (loading) {
            eventBus.$emit("MicroAppLoading", true);
          } else {
            eventBus.$emit("MicroAppLoading", false);
          }
        },
      },

      // bim相关
      {
        name: "micro-bim-console", // 已经注册的app名称 //localhost:3002/  //192.168.111.70:3000/
        // entry: 'https://nh.bimcc.net/nhmicro/', // 子应用获取资源的域名
        entry:
          process.env.NODE_ENV === "development"
            ? "//localhost:3000/"
            : `${window.location.origin}/aboutModel/`, // 子应用获取资源的域名
        container: "#container", // 子应用挂载的元素
        activeRule: "/child-app-bim", // 当到达此路由时候加载应用
        sandbox: true,
        // 通过props实现通信传递值
        props: {
          actions,
          msg: "w",
          BIMCCRouterObj: router,
          BIMCCParent: (() => {
            // console.log(vue.$route.matched, '---this.$route.matched----', vue.$route);
            return window;
          })(),
        }, //向子应用传递创建的全局状态
        loader: (loading) => {
          if (loading) {
            eventBus.$emit("MicroAppLoading", true);
          } else {
            eventBus.$emit("MicroAppLoading", false);
          }
        },
      },
      // TS微应用
      {
        name: "digital-micro",
        entry:
          process.env.NODE_ENV === "development"
            ? "//localhost:3002/"
            : `${window.location.origin}/digitalmicro/`, // 子应用获取资源的域名
        container: "#container", // 子应用挂载的元素
        activeRule: "/child-app-ts", // 当到达此路由时候加载应用
        sandbox: true,
        loader: (loading) => {
          if (loading) {
            eventBus.$emit("MicroAppLoading", true);
          } else {
            eventBus.$emit("MicroAppLoading", false);
          }
        },
      },
    ]);
    // start({
    // singular: false,
    // sandbox: true,
    // sandbox: {
    //   // strictStyleIsolation: true,
    //   // experimentalStyleIsolation: true
    // },
    // prefetch: false
    // })
  });
})();
