/*
 * @Description: 样式相关
 * @Author: luocheng
 * @Date: 2021-08-09 14:28:29
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-11-27 15:59:30
 */
// import { sin, cos } from '@/utils/translate'

/**
 * @desc: 将配置的样式对象转换为可渲染对象
 * @param {Object} style 配置的样式对象
 * @param {Array} excludes 排除
 * @return {Object} 可渲染样式对象
 */
export function getStyle(style, excludes = []) {
	// 需要添加单位
	const needUnit = [
		'fontSize',
		'width',
		'height',
		'lineHeight',
		'top',
		'left',
		// border
		'borderWidth',
		'letterSpacing',
		'borderRadius',
		// 宽高范围
		'minHeight',
		'minWidth',
		'maxHeight',
		'maxWidth',
		// 外边距
		'marginTop',
		'marginBottom',
		'marginLeft',
		'marginRight',
		// 内边距
		'paddingTop',
		'paddingBottom',
		'paddingLeft',
		'paddingRight'
	];
	const result = {};
	Object.keys(style).forEach((key) => {
		if (!excludes.includes(key)) {
			if(key === 'rotate') {
				result.transform = key + '(' + style[key] + 'deg)';
			} else if(key === 'backdropFilter'){
				result[key] = style[key] && style[key] !== '0' ? 'blur('+ style[key] +'px)' : ''
			}else{
				result[key] = style[key];
				if (needUnit.includes(key) && !isNaN(+result[key])) {
					result[key] += 'px';
				}
			}
			// if (key != 'rotate') {
			// 	result[key] = style[key];
			// 	if (needUnit.includes(key) && !isNaN(+result[key])) {
			// 		result[key] += 'px';
			// 	}
			// } else if(key === 'backdropFilter'){
			// 	result[key] = style[key] && style[key] !== '0' ? 'blur('+ style[key] +'px)' : ''
			// } else {
			// 	result.transform = key + '(' + style[key] + 'deg)';
			// }
		}
	});
  if(style.overflowY !== 'hidden' || style.overflowX !=='hidden') {
    // 兼容火狐浏览器滚动条样式
    result.scrollbarWidth = 'none';
  }
	return result;
}

/**
 * @desc: 获取一个组件旋转 rotate 后的样式
 * @param {Object} style 原样式
 * @return {Object} 被处理的样式
 */
// export function getComponentRotatedStyle(style) {
// 	style = { ...style };
// 	if (style.rotate != 0) {
// 		const newWidth = style.width * cos(style.rotate) + style.height * sin(style.rotate);
// 		const diffX = (style.width - newWidth) / 2; // 旋转后范围变小是正值，变大是负值
// 		style.left += diffX;
// 		style.right = style.left + newWidth;

// 		const newHeight = style.height * cos(style.rotate) + style.width * sin(style.rotate);
// 		const diffY = (newHeight - style.height) / 2; // 始终是正
// 		style.top -= diffY;
// 		style.bottom = style.top + newHeight;

// 		style.width = newWidth;
// 		style.height = newHeight;
// 	} else {
// 		style.bottom = style.top + style.height;
// 		style.right = style.left + style.width;
// 	}

// 	return style;
// }

/**
 * @desc: 混合颜色
 * @param {String} color1 16进制色值
 * @param {String} color2  16进制色值
 * @param {Number} percent 小数点 0 - 1
 * @return {String}
 */
export  function approximateColor1ToColor2ByPercent(color1, color2, percent) {
	// 不减去1是反的
	const usePercent = 1 - percent;
  var red1 = parseInt(color1[1] + color1[2], 16);
  var green1 = parseInt(color1[3] + color1[4], 16);
  var blue1 = parseInt(color1[5] + color1[6], 16);

  var red2 = parseInt(color2[1] + color2[2], 16);
  var green2 = parseInt(color2[3] + color2[4], 16);
  var blue2 = parseInt(color2[5] + color2[6], 16);

  var red = Math.round(mix(red1, red2, usePercent));
  var green = Math.round(mix(green1, green2, usePercent));
  var blue = Math.round(mix(blue1, blue2, usePercent));

  return generateHex(red, green, blue);
}

/**
 * @desc: 生成hex
 * @param {*} r
 * @param {*} g
 * @param {*} b
 * @return {*}
 */
function generateHex(r, g, b) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  // to address problem mentioned by Alexis Wilke:
  while (r.length < 2) { r = "0" + r; }
  while (g.length < 2) { g = "0" + g; }
  while (b.length < 2) { b = "0" + b; }

  return "#" + r + g + b;
}

/**
 * @desc: 混合
 * @param {*} start
 * @param {*} end
 * @param {*} percent
 * @return {*}
 */
function mix(start, end, percent) {
    return start + ((percent) * (end - start));
}
