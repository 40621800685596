/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-05-09 18:23:26
 * @FilePath: \dataview-viewer-test\src\custom-component\flow\lang\en.js
 */
export default {
  "src.FlowDesigner.822470-0": "Global Configuration",
  "src.FlowDesigner.822470-1": "Save Design",
  "src.FlowDesigner.822470-2": "Template Library",
  "src.FlowDesigner.822470-3": "Process Component Properties Configuration",
  "src.FlowDesigner.822470-4": "Standard Process Template Library",
  "src.FlowDesigner.822470-5": "Process Template Snapshot",
  "src.FlowDesigner.822470-6": "Save Template",
  "src.FlowDesigner.822470-7": "Use Template",
  "src.FlowDesigner.822470-8": "Process Basic Configuration",
  "src.FlowDesigner.822470-9": "Process Name",
  "src.FlowDesigner.822470-10": "Process Description",
  "src.FlowDesigner.822470-11": "Data Object",
  "src.FlowDesigner.822470-12": "Form Object",
  "src.FlowDesigner.822470-13": "Default",
  "src.FlowDesigner.822470-14": "Cancel",
  "src.FlowDesigner.822470-15": "Confirm",
  "src.FlowDesigner.822470-16": "Basic Components",
  "src.FlowDesigner.822470-17": "Start Node",
  "src.FlowDesigner.822470-18": "Approval Node",
  "src.FlowDesigner.822470-19": "CC node",
  "src.FlowDesigner.822470-20": "Parallel Gateway",
  "src.FlowDesigner.822470-21": "End Node",
  "src.FlowDesigner.822470-22": "Advanced Components",
  "src.FlowDesigner.822470-23": "Subprocess",
  "src.FlowDesigner.822470-24": "System Task",
  "src.FlowDesigner.822470-25": "Please enter the process name",
  "src.FlowDesigner.822470-26": "Process template does not exist",
  "src.FlowDesigner.822470-27": "Please enter the template name",
  "src.FlowDesigner.822470-28": "Saving process template",
  "src.FlowDesigner.822470-29": "OK",
  "src.FlowDesigner.822470-30": "Cancel",
  "src.FlowDesigner.822470-31": "Process template name is required",
  "src.FlowDesigner.822470-32": "【Parallel Gateway】must appear in pairs",
  "src.FlowDesigner.822470-33": "【Parallel Gateway】must have single input and multiple outputs or multiple inputs and single output",
  "src.FlowDesigner.822470-34": "Approval node: [{0}] Approver cannot be empty. Please select an approver",
  "src.FlowDesigner.822470-35": "Approval node: [{0}] Department cannot be empty. Please select a department",
  "src.FlowDesigner.822470-36": "Approval node: [{0}] Position cannot be empty. Please select a position",
  "src.FlowDesigner.822470-37": "Start or end node is missing. Please check before saving!",
  "src.FlowDesigner.822470-38": "Process node connection is incomplete. Please check before saving!",
  "src.FlowDesigner.822470-39": "Successfully saved the process!",
  "src.FlowDesigner.822470-40": "Loading...",
  "src.FlowDesigner.822470-41": "Custom approval content for process nodes",
  "src.FlowDesigner.822470-42": "Start node link missing， Please check before saving!",
  "src.FlowDesigner.822470-43": "End node link missing， Please check before saving!",
  "src.FlowDesigner.822470-44": "Nodes do not allow themselves to connect to themselves， Please check before saving!",
  "src.FlowDesigner.822470-45": "TodoMsg Template List",
  "src.FlowDesigner.822470-46": "SendMsg Template List",
  "scripts.X6GraphInit.733994-0": "Start",
  "scripts.X6GraphInit.733994-1": "Approval Node",
  "scripts.X6GraphInit.733994-2": "CC node",
  "scripts.X6GraphInit.733994-3": "End",
  "scripts.X6GraphInit.733994-4": "System Task",
  "scripts.X6GraphInit.733994-5": "Subprocess",
  "scripts.X6GraphInit.733994-6": "Returned",
  "scripts.X6GraphInit.733994-7": "Pass",
  "scripts.X6GraphInit.733994-8": "Forward",
  "scripts.X6GraphInit.733994-9": "Reinitiate",
  "scripts.X6GraphInit.733994-10": "Submit to Returner",
  "cell-panel.StepProp.033670-0": "Basic Settings",
  "cell-panel.StepProp.033670-1": "Step Name",
  "cell-panel.StepProp.033670-2": "Branch Selection",
  "cell-panel.StepProp.033670-3": "Enable Forwarding",
  "cell-panel.StepProp.033670-4": "Approvers",
  "cell-panel.StepProp.033670-5": "Designated Personnel",
  "cell-panel.StepProp.033670-6": "Initiator Selection",
  "cell-panel.StepProp.033670-7": "Department Personnel",
  "cell-panel.StepProp.033670-8": "Position Personnel",
  "cell-panel.StepProp.033670-9": "Specified in Workflow",
  "cell-panel.StepProp.033670-10": "Initiator Review",
  "cell-panel.StepProp.033670-11": "Empty Step",
  "cell-panel.StepProp.033670-12": "Selection Range",
  "cell-panel.StepProp.033670-13": "This Level Architecture",
  "cell-panel.StepProp.033670-14": "Higher-level Organization",
  "cell-panel.StepProp.033670-15": "Personnel Selection",
  "cell-panel.StepProp.033670-16": "Department Selection",
  "cell-panel.StepProp.033670-17": "Position Selection",
  "cell-panel.StepProp.033670-18": "Please Select",
  "cell-panel.StepProp.033670-19": "Personnel Range",
  "cell-panel.StepProp.033670-20": "Enabled: Selected Personnel as Selection Range",
  "cell-panel.StepProp.033670-21": "Form Control",
  "cell-panel.StepProp.033670-22": "Editable",
  "cell-panel.StepProp.033670-23": "Visible Only",
  "cell-panel.StepProp.033670-24": "Hidden",
  "cell-panel.StepProp.033670-25": "Countersign or Sign",
  "cell-panel.StepProp.033670-26": "Sign (Any one passes)",
  "cell-panel.StepProp.033670-27": "Countersign (All personnel pass)",
  "cell-panel.StepProp.033670-28": "Countersign Ratio",
  "cell-panel.StepProp.033670-29": "All Pass",
  "cell-panel.StepProp.033670-30": "Over Half Pass",
  "cell-panel.StepProp.033670-31": "Button Settings",
  "cell-panel.StepProp.033670-32": "Button Operation",
  "cell-panel.StepProp.033670-33": "Returned",
  "cell-panel.StepProp.033670-34": "Pass",
  "cell-panel.StepProp.033670-35": "Forward",
  "cell-panel.StepProp.033670-36": "Rejected",
  "cell-panel.StepProp.033670-37": "Void",
  "cell-panel.StepProp.033670-38": "Expedite",
  "cell-panel.StepProp.033670-39": "Reinitiate",
  "cell-panel.StepProp.033670-40": "Submit to Returner",
  "cell-panel.StepProp.033670-41": "Button Text",
  "cell-panel.StepProp.033670-42": "Show/Hide",
  "cell-panel.StepProp.033670-43": "Show",
  "cell-panel.StepProp.033670-44": "Add Button",
  "cell-panel.StepProp.033670-45": "Approval Comments",
  "cell-panel.StepProp.033670-46": "Attachment Display",
  "cell-panel.StepProp.033670-47": "Attachment Required",
  "cell-panel.StepProp.033670-48": "Comment Display",
  "cell-panel.StepProp.033670-49": "Comment Required",
  "cell-panel.StepProp.033670-50": "Control Name",
  "cell-panel.StepProp.033670-51": "Control Type",
  "cell-panel.StepProp.033670-52": "Approval Comments Extended Form Control Configuration",
  "cell-panel.StepProp.033670-53": "Approval Node",
  "cell-panel.SendProp.975649-0": "Basic Settings",
  "cell-panel.SendProp.975649-1": "Step Name",
  "cell-panel.SendProp.975649-2": "CC Recipients",
  "cell-panel.SendProp.975649-3": "Assigned Personnel",
  "cell-panel.SendProp.975649-4": "Initiator's Choice",
  "cell-panel.SendProp.975649-5": "Departmental Personnel",
  "cell-panel.SendProp.975649-6": "Position-based Personnel",
  "cell-panel.SendProp.975649-7": "Designated in Process",
  "cell-panel.SendProp.975649-8": "CC Initiator",
  "cell-panel.SendProp.975649-9": "Selection Scope",
  "cell-panel.SendProp.975649-10": "Current Level Architecture",
  "cell-panel.SendProp.975649-11": "Superior Company",
  "cell-panel.SendProp.975649-12": "Personnel Selection",
  "cell-panel.SendProp.975649-13": "Department Selection",
  "cell-panel.SendProp.975649-14": "Position Selection",
  "cell-panel.SendProp.975649-15": "Please Select",
  "cell-panel.SendProp.975649-16": "CC Node",
  "cell-panel.SubFlowProp.143764-0": "Basic Settings",
  "cell-panel.SubFlowProp.143764-1": "Subprocess Name",
  "cell-panel.SubFlowProp.143764-2": "Skip Execution of Subprocess by Process Initiating Organization",
  "cell-panel.SubFlowProp.143764-3": "Subprocess Settings",
  "cell-panel.SubFlowProp.143764-4": "Subprocess",
  "cell-panel.EdgeProp.382481-0": "Basic Settings",
  "cell-panel.EdgeProp.382481-1": "Branch Name",
  "cell-panel.EdgeProp.382481-2": "Priority",
  "cell-panel.EdgeProp.382481-3": "Low (P1)",
  "cell-panel.EdgeProp.382481-4": "Medium (P2)",
  "cell-panel.EdgeProp.382481-5": "High (P3)",
  "cell-panel.EdgeProp.382481-6": "Condition Settings",
  "cell-panel.EdgeProp.382481-7": "Logic",
  "cell-panel.EdgeProp.382481-8": "And",
  "cell-panel.EdgeProp.382481-9": "Or",
  "cell-panel.EdgeProp.382481-10": "Rule Type",
  "cell-panel.EdgeProp.382481-11": "Personnel Condition",
  "cell-panel.EdgeProp.382481-12": "Form Condition",
  "cell-panel.EdgeProp.382481-13": "Personnel Selection",
  "cell-panel.EdgeProp.382481-14": "Form Control",
  "cell-panel.EdgeProp.382481-15": "Please Select",
  "cell-panel.EdgeProp.382481-16": "Operator",
  "cell-panel.EdgeProp.382481-17": "Value",
  "cell-panel.EdgeProp.382481-18": "Add New Condition",
  "cell-panel.EdgeProp.382481-19": "Greater Than",
  "cell-panel.EdgeProp.382481-20": "Less Than",
  "cell-panel.EdgeProp.382481-21": "Equal To",
  "cell-panel.EdgeProp.382481-22": "Exactly Equal To",
  "cell-panel.EdgeProp.382481-23": "Not Equal To",
  "cell-panel.EdgeProp.382481-24": "Greater Than or Equal To",
  "cell-panel.EdgeProp.382481-25": "Less Than or Equal To",
  "cell-panel.EdgeProp.382481-26": "Contains Any",
  "cell-panel.EdgeProp.382481-27": "Does Not Contain",
  "cell-panel.EdgeProp.382481-28": "Between",
  "operation.FlowOperate.468394-0": "Collapse Approval",
  "operation.FlowOperate.468394-1": "Approve",
  "operation.FlowOperate.468394-2": "Submit",
  "operation.FlowOperate.468394-3": "Approval Comment",
  "operation.FlowOperate.468394-4": "Please enter approval comments",
  "operation.FlowOperate.468394-5": "Approval Attachment",
  "operation.FlowOperate.468394-6": "Select Return Type",
  "operation.FlowOperate.468394-7": "Return to Initiator",
  "operation.FlowOperate.468394-8": "Return to Previous Step",
  "operation.FlowOperate.468394-9": "Return to Specified Step",
  "operation.FlowOperate.468394-10": "Step Selection",
  "operation.FlowOperate.468394-11": "Please select the step to return to",
  "operation.FlowOperate.468394-12": "Confirm {0}",
  "operation.FlowOperate.468394-13": "Approval comments cannot be empty",
  "operation.FlowOperate.468394-14": "Form validation failed",
  "operation.FlowOperate.468394-15": "Approval comments are required but not fully provided",
  "operation.FlowOperate.468394-16": "Incomplete selection of approvers!",
  "operation.FlowOperate.468394-17": "Data validation failed, please verify the required field data",
  "operation.FlowPicker.605015-0": "Loading...",
  "operation.FlowPicker.605015-1": "Select Transition Branch",
  "operation.FlowPicker.605015-2": "Transition Branch",
  "operation.FlowPicker.605015-3": "Please select a transition branch",
  "operation.FlowPicker.605015-4": "End",
  "operation.FlowPicker.605015-5": "Select Delegatee",
  "operation.FlowPicker.605015-6": "Please select a delegatee",
  "operation.FlowPicker.605015-7": "Select Approver",
  "operation.FlowPicker.605015-8": "Select CC Recipient",
  "operation.FlowPicker.605015-9": "No personnel selection required for the process",
  "operation.FlowRecover.668174-0": "Flow Approval Records",
  "operation.FlowRecover.668174-1": "Close",
  "operation.FlowRecover.668174-2": "View",
  "operation.FlowRecover.668174-3": "Process Diagram",
  "operation.FlowRecover.668174-4": "Subprocess Trigger",
  "operation.FlowRecover.668174-5": "CC Successful",
  "operation.FlowRecover.668174-6": "Empty Step",
  "operation.FlowRecover.668174-7": "Restart",
  "operation.FlowRecover.668174-8": "Initiate",
  "operation.FlowRecover.668174-9": "End",
  "operation.FlowRecover.668174-10": "Approve",
  "operation.FlowRecover.668174-11": "Transferred",
  "operation.FlowRecover.668174-12": "Rejected",
  "operation.FlowRecover.668174-13": "Void",
  "operation.FlowRecover.668174-14": "Processed",
  "operation.FlowRecover.668174-15": "Pending",
  "operation.FlowRecover.668174-16": "Pending",
  "operation.FlowRecover.668174-17": "Returned",
  "operation.FlowRecover.668174-18": "Unread",
  "operation.FlowRecover.668174-19": "Read",
  "operation.FlowRecover.668174-20": "Optional Signature",
  "operation.FlowRecover.668174-21": "Joint Signature",
  "operation.FlowRecover.668174-22": "Start",
  "operation.FlowRecover.668174-23": "$ Month",
  "operation.FlowRecover.668174-24": "$ Day"


}