/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-26 18:12:57
 * @FilePath: \dataview-viewer-test\src\apis\lang\en.js
 */
export default {
  "http.axios.837231-0": "Internal Server Error",
  "http.axios.837231-1": "Redirect to Login 1",
  "http.axios.837231-2": "Not logged in or session expired. Please log in!",
  "http.axios.837231-3": "Data validation failed!",
  "http.axios.837231-4": "Redirect to Login 2",
  "http.axios.837231-5": "Not logged in or session expired. Please log in!",
  "http.axios.837231-6": "Oops! An error occurred. Please contact the administrator to resolve!"
}