/*
 * @Author: hw
 * @Date: 2024-04-19 10:25:46
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-30 15:06:48
 * @Description: 动态字段语言切换
 * @FilePath: \dataview-viewer-test\src\custom-component\mixins\getLocalizedArrayMixin.js
 */
import i18n from '@/locale/index';
import { dataInterface } from '@/apis/data';
/**
 * @description: 接口批量翻译
 * @param {*} cnText
 * @param {*} callback
 * @return {*}
 */
let timer = null;
let unTranslateList = [];
let callbacks = [];
const chineseSwitchEnglish = (cnText, callback) => {
  if (timer) {
    clearTimeout(timer)
  }
  unTranslateList.push(cnText)
  callbacks.push(callback)

  timer = setTimeout(() => {
    dataInterface({ chinese: [...unTranslateList] }, '/api/language/chineseSwitchEnglish').then((res) => {
      let data = res.data.data;
      callbacks.forEach((cb, index) => {
        cb(data[index].english)
      })

      unTranslateList.length = 0;
      callbacks.length = 0;
      timer = null;
    })
  }, 100);
}
export default {
  data() {
    return {
      langMapList: window.langMapList
    };
  },
  methods: {
    /**
     * @description: 翻译数组对象
     * @param {*} dataList 需要翻译的数组对象
     * @param {*} keys 需要翻译的字段 ['key1', 'key2']或'key1'
     * @return {*} 使用t_开头的字段，如't_key1'
     */
    getLocalizedData(data, keys, childKey = '') {
      if (Array.isArray(data) && !data.length) return;
      const isEnglish = i18n.locale === 'en';

      keys = typeof keys === 'string' ? [keys] : keys;
      const handleData = (item) => {
        if (isEnglish) {
          // 处理英语情况
          this.handleEnglish(item, keys, childKey);
        } else {
          // 处理非英语情况
          this.handleNonEnglish(item, keys, childKey);
        }
        return item;
      };
      if (Array.isArray(data)) {
        return data.map(handleData);
      } else {
        return handleData(data);
      }
    },
    /**
     * @description: 处理英语情况
     */
    handleEnglish(item, keys, childKey) {
      if (keys) {
        keys.forEach((key) => {
          this._getTranslation(item, key, newValue => {
            this.$set(item, `t_${key}`, newValue);
          });
        });
      }

      // 处理子数据
      this.handleChildData(item, keys, childKey);
    },
    /**
     * @description: 处理非英语情况
     */
    handleNonEnglish(item, keys, childKey) {
      if (keys) {
        keys.forEach((key) => {
          item[`t_${key}`] = item[key];
        });

        // 处理子数据
        this.handleChildData(item, keys, childKey);
      }
    },
    /**
     * @description: 获取翻译后的数据
     */
    _getTranslation(item, key, callback) {
      try {
        const result = this.langMapList?.find((value) => value.key === item[key]);
        if (result && result.data && result.data.english) {
          callback(result.data.english);
        } else {
          // 走翻译接口
          chineseSwitchEnglish(item[key], callback)
        }
      } catch (error) {
        console.log(error);
        // 直接返回原始值
        callback(item[key]);
      }
    },
    /**
     * @description: 处理子数据
     */
    handleChildData(item, keys, childKey) {
      if (item[childKey] && item[childKey].length > 0) {
        item[childKey] = this.getLocalizedData(item[childKey], keys, childKey);
      }
    }
  }
};


