<!--
    @name: widget-task
    @description：widget-task
    @author: ZengWei
    @date: 2022-06-09 10:42
-->
<template>
  <div>
    <div style="position: relative">
      <div :class="{'outer-viewer':element.config.__config__.display}">
        <el-select
          v-model="element.value"
          :disabled="disabled || element.config.disabled"
          :placeholder="$getLocalizedLabel({'zhLabel': element.config.zhPlaceholder, 'enLabel': element?.config.enPlaceholder})"
          :clearable="element.config.clearable"
          :multiple="element.config.multiple"
          :filterable="element.config.filterable"
          @change="onChange"
        >
          <el-option
            v-for="item in taskList"
            :key="item.id"
            :label="item.name"
            :value="''+item.id">
          </el-option>
        </el-select>
      </div>
      <el-button
        type="primary"
        v-if="element.config.__config__.display"
        style="position: absolute;bottom:0;right: 0;"
        @click="openTaskViewer"
      >{{ btnName }}
      </el-button>
    </div>

    <div class="task-picker" v-if="!disabled">
      <TaskPicker
        v-if="taskEngineId"
        :key="key"
        type="self"
        :taskEngineId="taskEngineId"
        @checked-users="dealWithPicker"
        @selected-step="getSelectStep">
      </TaskPicker>
    </div>

    <el-dialog
      title="任务引擎逻辑预览"
      :visible.sync="taskVisible"
      top="5vh"
      width="80%"
      :custom-class="'common-dialog'"
      append-to-body
    >
      <article class="dialog-main">
        <TaskDesigner
          ref="taskEngineGraph"
          v-if="taskVisible"
          :task-id="taskEngineId"
          :disabled="disabled || element.config.disabled"
          @on-save="callbackSubmit">
        </TaskDesigner>
      </article>
      <div slot="footer">
        <el-button type="default" @click="closeModal" style="margin-right: 10px;">取消</el-button>
        <el-button type="primary" v-if="!disabled && !element.config.disabled" @click="confirmSubmit()">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TaskDesigner from "@/views/workOrder/task-flow/TaskDesigner";
import {dataInterface} from "@/apis/data";
import TaskPicker from "@/views/workOrder/task-flow/components/TaskPicker";
import {uniqid} from "@/plugins/util";
import eventBus from "@/plugins/eventBus";
import {mapGetters} from "vuex";
export default {
  name: "WidgetTask",
  components: { TaskDesigner,TaskPicker },
  props: ['element', 'disabled'],
  data() {
    return {
      taskList: [],
      btnName: '新增任务逻辑',
      loading: false,
      taskVisible: false,
      taskEngineId: 0,
      key: uniqid()
    }
  },
  computed:{
    ...mapGetters(["getWorkSpace"])
  },
  mounted() {
    if(this.element.value){
      this.btnName = '查看任务逻辑'
      this.taskEngineId = this.element.value
      this.initList()
    } else {
      const spaceType = this.getWorkSpace?.work_space_type || 1
      const taskEngineId = this.getWorkSpace?.task_engine_id || 0
      if(spaceType === 1){
        this.getChildTaskBind(this.getWorkSpace)
      } else {
        this.element.config.disabled = true
        if(taskEngineId){
          this.btnName = '查看任务逻辑'
          this.taskEngineId = taskEngineId
          this.element.value = ''+taskEngineId
        }
        this.initList()
      }
    }
  },
  methods: {
    getChildTaskBind(data){
      this.element.config.disabled = false
      const param = {task_space_id: data.work_space_id}
      dataInterface(param,'/api/taskengine/taskbind','GET').then(res=>{
        if(res.data.code === 200){
          this.taskList = res.data.data
        }
      })
    },
    getSelectStep(){},
    dealWithPicker(selected, total){
      eventBus.$emit('selfpicker',selected,total)
    },
    initList(){
      const param = {
        __method_name__: 'dataList',
        object_uuid: 'object628619c716579',
        view_uuid: 'view628635336d974',
        transcode: 0,
        size: 1000,
      }
      dataInterface(param).then(res=>{
        if(res.data.code === 200){
          this.taskList = res.data.data.data
        }
      })
    },
    onChange(val){
      this.taskEngineId = val
      this.key = uniqid()
      if(val){
        this.btnName = '查看任务逻辑'
      } else {
        this.btnName = '新增任务逻辑'
      }
    },
    openTaskViewer(){
      this.taskVisible = true
    },
    confirmSubmit(){
      this.$refs.taskEngineGraph.saveTaskFlow()
    },
    callbackSubmit(saveData){
      dataInterface(saveData).then(res=>{
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
          this.taskVisible = false
          if(res.data.data.id){
            this.taskEngineId = '' + res.data.data.id
            this.element.value = '' + this.taskEngineId
            this.btnName = '查看任务逻辑'
            this.initList()
          }
        }
      })
    },
    closeModal(){
      this.taskVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.outer-viewer{
  width: calc(100% - 140px);
}
</style>
