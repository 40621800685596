/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-23 17:34:14
 * @FilePath: \dataview-viewer-test\src\custom-component\bindLatLng\lang\en.js
 */
export default {  
  "bindLatLng.index.774051-0": "Select Point",
  "bindLatLng.index.774051-1": "Draw Area",
  "bindLatLng.index.774051-2": "Clear",
  "bindLatLng.index.774051-3": "Confirm",
  "bindLatLng.index.774051-4": "Marker",
  "bindLatLng.index.774051-5": "Marker Information Description",
  "bindLatLng.index.774051-6": "Longitude",
  "bindLatLng.index.774051-7": "Latitude",
  "bindLatLng.index.774051-8": "Coordinate A",
  "bindLatLng.index.774051-9": "Coordinate B",
  "bindLatLng.index.774051-10": "Please enter a keyword",
  "bindLatLng.index.774051-11": "Loading...",
  "bindLatLng.index.774051-12": "Rectangle",
  "bindLatLng.index.774051-13": "Circle",
  "bindLatLng.index.774051-14": "Polygon",
  "bindLatLng.index.774051-15": "Bind Latitude and Longitude",
  "bindLatLng.index.774051-16": "Bind GIS",
  "bindLatLng.index.774051-17": "China"
}