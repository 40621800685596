<template>
  <div class="num-conts" @mouseenter="enter()" @mouseleave="leave()">

    <div
      class="out-circle"
      :class="state == 'detail' ? 'mini-size' : ''"
      :style="{zoom: size }"
     
    >
      <div class="both" v-if="zoom < 6" :style="{ background: color, zoom: size }"  @click="locationProject(1180000)">
        <p class="province-name">
          {{ provinceName?.slice(0,3) }}
        </p>
        <p class="has-num">
          {{ item?.children?.length }}个项目
        </p>
      </div>
      <div v-else-if="this.zoom >= 6 && this.zoom < 8 && item?.children?.length" class="alone" @click="locationProject(930960)">
        <div class="icon-div" :style="iconcolor(item.build_status_x,1)">
          <i class="iconfont" :class="getIcon(item)"></i>
        </div>
        <div class="title">
          <div><span>{{ item.children.length }}</span>个</div>
          <div>{{ getBuildStatus(item.build_status_x) }}项目</div>
        </div>
      </div>

      <div v-else-if="this.zoom >= 8" class="project-infos">
        <div v-if="!isSearch">
          <div class="project-infos-top" v-show="showmini">
            <span class="project-infos-title">
             {{ getProjectName(item)}} {{ getBuildStatus(item.data.build_status_x) }}
            </span>
            <i class="iconfont iconjiaobiao" :style="iconcolor(item.data.build_status_x)" 
            />
          </div>
          <div class="round" :style="iconcolor(item.data.build_status_x,1)" @click="showmini = !showmini"></div>
        </div>
        <div v-else @click="getInfo(item)">
          <div class="project-infos-top" style="top: -90px;">
            <span class="project-infos-title">
              {{ getProjectName(item)}}
              <div style="display:flex">
                <el-badge :value="it.num" v-for="(it,index) in infoType" :key="index" >
                  <i class="iconfont" :class="it.icon"/>
                </el-badge>
              </div>

            </span>
            <i class="iconfont iconjiaobiao" :style="iconcolor(item.data.build_status_x)" 
            />
          </div>
          <div class="round" :style="iconcolor(item.data.build_status_x,1)" @click="showmini = !showmini"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Badge } from 'element-ui'
/*eslint-disable*/
  export default {
    name: 'NumCont',
    components:{
      'el-badge':Badge
    },
    props: {
      color: {
        type: String,
        default: 'var(--el-color-primary)',
      },
      item: {
        type: Object,
        default: null,
      },
      num: {
        type: Number,
        default: 0,
      },
      zoom: {
        type: Number,
        default: 4,
      },
			onlyProject:{
				type:Boolean,
				default: false,
			},
			projectTypeData: {
				type:Array,
				default: () => []
			},
      isSearch:{
        type:Boolean,
        default: false
      }
    },
    data() {
      return {
        infoType:[],
        size: 1,
        showmini:false, // 最小层级的项目名称是否展示
      }
    },
    computed: {
			getProjectName(){
				return function(item){
          if(!item.data) return
					let name = item.data.short_name != null ? item.data.short_name : item.data.name
					if(name){
						if(name.length > 11){
							return name.substring(0,8) + '...'
						} else {
							return name
						} 
					} else {
						return '-'
					}
				}
			},
      /**
       * @return 返回当前建筑状态
       * @param {data} item 
       */
      getBuildStatus(){
        return function(item){
          let build_status_x = +item
          
          switch(build_status_x){
            case 1 : 
              return '筹备'
            case 2: 
              return '在建'
            case 3 : 
              return '停工'
            case 4 : 
              return '竣工'
              
          }
        }
      },
      iconcolor(){
        return function(item,type=0){
          let build_status_x = +item
          if(type ===0){
            switch(build_status_x){
              case 2: 
                return 'color:#EFC11A'
              case 3 : 
                return 'color:#6D768F'
              case 1 : 
                return 'color:#337EFF'
              case 4 : 
                return 'color:#00A650'
            }
          }else if(type===1){
            switch(build_status_x){
              case 2 : 
                return 'background:#EFC11A'
              case 3 : 
                return 'background:#6D768F'
              case 1 : 
                return 'background:#337EFF'
              case 4 : 
                return 'background:#00A650'
            }
          }

        }
      },
      getIcon(){
        return function(item){
          let build_status_x = +item.build_status_x
          switch(build_status_x){
              case 2 : 
                return 'icona-shigongfill'
              case 3 : 
                return 'icontinggong'
              case 1 : 
                return 'iconchoujian'
              case 4 : 
                return 'iconqizhi'
            }
        }
      },
      provinceName() {
        let firstData = this.item.data.build_address
				if(firstData){
        if (this.zoom == 4) {
            return firstData.split('/')[0]
          } else {
            if (this.zoom < 8) {
              return firstData.split('/')[1]
            }
            if (this.zoom == 8 || this.zoom < 12) {
              return firstData.split('/')[1]
            }
          }
        } else {
          return ''
        }
      },
      state() {
				if(this.onlyProject){//项目概况
					if (this.zoom < 12) {
						return 'alone'
					} else {
						this.size = 0.9
						return 'detail'
						
					}
					return 'alone'
				}else{//公司分布
					if (this.zoom < 12) {
						this.size = 1
						if (this.item?.children?.length == 1) {
							if (this.zoom < 8) {
								return 'both'
							} else {
								return 'alone'
							}
						} else {
							return 'both'
						}
					} else {
						if (this.item?.children?.length == 1) {
							this.size = 0.9
							return 'detail'
						} else {
							this.size = 1
							return 'detail'
						}
					}
				}
      },
    },
    watch: {},
    mounted() {
      window.addEventListener(
        'mousewheel',
        this.debounce(this.handleScroll, 300),
        true
      ) ||
      window.addEventListener(
        'DOMMouseScroll',
        this.debounce(this.handleScroll, 300),
        false
      )

    },
    methods: {
      updateInfo(){
        if(this.item.warn_type_info){
          this.infoType= []
          let obj = this.item.warn_type_info[0]
          for (let key in obj) {
            if(key!=='object_id' && obj[key] != 0){
              if(this.getTypeIcon(key)){
                this.infoType.push({num:obj[key],value:key,icon:this.getTypeIcon(key)})
              }
            }
          }
        }   
      },
      getInfo(item){
        this.$emit('getInfo',item)
      },
      getTypeIcon(data){
        let arr = ['safecount','quliatycount','informcount','complaintscount','degressioncount','investmentcount']
        // if(!arr.includes(data)) return false
        switch(data){
            case 'safecount' : 
              return 'iconanquanmao-copy'
            case 'quliatycount' : 
              return 'iconzhiliang'
            case 'informcount': 
              return 'iconjubao'
            case 'complaintscount' : 
              return 'icona-xinxipinglunxiaoxi'
            case 'degressioncount' : 
              return 'iconchengbenqian'
            case 'investmentcount' : 
              return 'icontouzi'
          }
      },
      locationProject(n) {
        console.log( this.item)
        this.$emit('locationProject', this.item,n)
      },
      enter() {
        this.$emit('getmouseenter', this.item)
      },
      leave() {
        this.$emit('getmouseout', this.item)
      },
      //函数防抖
      debounce(func, wait) {
        let timeout
        return function () {
          let context = this
          let args = arguments
          if (timeout) clearTimeout(timeout)
          timeout = setTimeout(() => {
            func.apply(context, args)
          }, wait)
        }
      },
      //判断滚动方向
      handleScroll(e) {
        let direction = e.deltaY > 0 ? 'down' : 'up'
        this.$emit('scrollWheel', direction)
      },
      openInfoFn() {
        this.$emit('open-info', this.item)
      },
    },
  }
</script>

<style lang="less" scoped>
.num-conts{
  z-index:9
}
:deep(.el-badge){
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #E7F0FE;
  margin-top: 14px;
  padding: 4px;
  box-sizing: border-box;
  margin-left: 8px;
  i{
    font-size: 24px;
    color: #337EFF;
  }
}
.both{
  width: 88px;
  height: 88px;
  background-color: var(--el-color-primary);
  border-radius:50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .province-name {
      height: 12px;
      line-height: 16px;
      font-size: 16px;
      margin-top: 24px;
      margin-bottom: 10px;
      color: #fff;
      text-align: center;
      height: auto;
    }
    .has-num {
      height: 12px;
      line-height: 12px;
      font-size: 12px;
      font-family: IBMPlexSans, IBMPlexSans-Regular;
      text-align: center;
      color: #d0e2ff;
    }
}
.alone{
  display: flex;
  padding: 10px 16px;
  align-items: flex-start;
  gap: 16px;
  width: 152px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 7px 0px rgba(16, 28, 43, 0.20);
  .icon-div{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    i{
      font-size: 32px;
      color: #FFF;
    }
  }
  .title{
    color: #5F5959;
    font-family: Source Han Sans CN;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    span{
      color: #000;
      font-family: Source Han Sans CN;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 120% */
    }
  }
}
.round{
  margin-top: 3px;
  width: 22px; 
  height: 22px;
  border-radius: 50%;
  box-shadow: 0px -4px 8px 0px rgba(255, 255, 255, 0.60) inset;
  filter: drop-shadow(0px 4px 5px rgba(112, 80, 0, 0.25));
}
.project-infos{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 186px;
  text-align: left;
  .project-infos-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: -81px;
    top: -52px;
    width: 184px;
    i{
      margin-top: 6px;
    }
  .project-infos-title{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 184px;
    padding: 8px;
    box-sizing: border-box;
    white-space: normal;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0px 0px 8px 0px rgba(5, 16, 43, 0.20);
    backdrop-filter: blur(4px);
    :deep(.el-badge){
      margin-top: 6px;
    }
  }
}
}


</style>
