/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-05-20 17:34:48
 * @FilePath: \dataview-viewer-test\src\views\newSecond\lang\en.js
 */
export default {
  'username': 'Username',
  'email': 'Email',
  'mobile': 'Mobile',
  'placeholder': 'Please enter',
  'editHomePage': 'Edit home page',
  'saveHomePage': 'Save Home Page',
  'addDrawingBoard': 'Add a drawing board',
  "enterprise.Index.766519-0": "Company",
  "index.HeaderWeather.274545-1": "Weather",
  "recent.Index.934494-0": "Enterprise",
  "recent.Index.934494-1": "Project",
  "index.SelectArchi.934493-0": "Recently Viewed",
  "index.SelectArchi.934493-1": "Enterprise",
  "index.SelectArchi.934493-2": "Project",
  "secondEdition.SearchBox.934494-0": "Search",
  "index.GridDrawer.934494-0": "Cancel",
  "index.GridDrawer.934494-1": "OK",
  "index.GridDrawer.934494-2": "Project selection successful!",
  "secondEdition.ArchiInfo.934494-0": "Enterprise",
  "secondEdition.ArchiInfo.934494-1": "Project",
  "secondEdition.ArchiInfo.934494-2": "Dashboard",
  "secondEdition.ArchiInfo.934494-3": "Under Development",
  "secondEdition.ArchiInfo.934494-4": "Visualization Screen",
  "secondEdition.ArchiInfo.934494-5": "Select",
  "secondEdition.ArchiInfo.934494-6": "】architecture, no 【$",
  "secondEdition.ArchiInfo.934494-7": "No 【{1}】 permission under 【{0}】 architecture!",
  "secondEdition.ArchiInfo.934494-8": "Functionality under development, please stay tuned...",
  "index.GridComponent.540553-0": "Edit",
  "index.GridComponent.540553-1": "Are you sure you want to delete?",
  "index.GridComponent.540553-2": "Delete",
  "index.GridComponent.540553-3": "Select Project",
  "index.GridComponent.540553-4": "To-Do",
  "index.GridComponent.540553-5": "My Applications",
  "index.GridComponent.540553-6": "Notices",
  "index.GridComponent.540553-7": "Construction Dashboard",
  "index.GridComponent.540553-8": "News",
  "index.GridComponent.540553-9": "Quality & Safety Stats",
  "index.GridComponent.540553-10": "Progress Stats",
  "index.GridComponent.540553-11": "Attendance Statistics",
  "index.GridComponent.540553-12": "Failed to fetch component!",
  "index.GridComponent.540553-13": "Place on the canvas root node!",
  "index.GridComponent.540553-14": "Error occurred!",
  "index.GridComponent.540553-15": "Save successful!",
  "index.GridComponent.540553-16": "Construction Dashboard",
  "index.GridComponent.540553-17": "Platform Dynamics",
  "news.Index.223775-0": "Project Excellence",
  "news.Index.223775-1": "News Images",
  "news.Index.223775-2": "No Images Available",
  "news.Index.223775-3": "Details",
  "news.Index.223775-4": "Details",
  "news.Index.223775-5": "Source:",
  "safety.index.730335-0": "Quality & Safety Stats",
  "safety.index.730335-1": "Safety Notices",
  "safety.index.730335-2": "Quality Releases",
  "schedule.index.091865-0": "Progress Stats",
  "schedule.index.091865-1": "Fill Volume",
  "schedule.index.091865-2": "Summary",
  "schedule.index.091865-3": "Pavement Section (10,000m³)",
  "schedule.index.091865-4": "Pile Foundation Progress",
  "schedule.index.091865-5": "T3 Terminal Section (units)",
  "people.index.282976-0": "Attendance Statistics",
  "todoCom.index.735398-0": "To-Do",
  "todoCom.index.735398-1": "Alerts",
  "todoCom.index.735398-2": "CC",
  "todoCom.index.735398-3": "No more data",
  "todoCom.TodoList.785059-0": "Project:",
  "todoCom.TodoList.785059-1": "No Data",
  "todoCom.TodoList.785059-2": "Safety",
  "todoCom.TodoList.785059-3": "Quality",
  "todoCom.TodoList.785059-4": "Progress",
  "todoCom.TodoList.785059-5": "Returned",
  "todoCom.TodoList.785059-6": "Initiate",
  "todoCom.TodoList.785059-7": "Completed",
  "todoCom.TodoList.785059-8": "Under Review",
  "todoCom.TodoList.785059-16": "Rejected",
  "todoCom.TodoList.785059-17": "Void",
  "todoCom.TodoList.785059-9": "{0} days overdue",
  "todoCom.TodoList.785059-10": "Behind Schedule",
  "todoCom.TodoList.785059-11": "As of {0} today",
  "todoCom.TodoList.785059-12": "Pending",
  "todoCom.TodoList.785059-13": "{0} deadline",
  "todoCom.TodoList.785059-14": "Due {0}",
  "todoCom.TodoList.785059-NO": "NO:{0}",
  "todoCom.TodoList.785059-15": "Due {0}",
  "todoCom.WarringDialog.788995-0": "Warning Information",
  "todoCom.WarringDialog.788995-1": "Warning Level:",
  "todoCom.WarringDialog.788995-2": "Warning Type:",
  "todoCom.WarringDialog.788995-3": "Other",
  "todoCom.WarringDialog.788995-4": "Warning】",
  "todoCom.WarringDialog.788995-5": "Warning Content:",
  "todoCom.WarringDialog.788995-6": "Cancel",
  "todoCom.WarringDialog.788995-7": "Mark as Read",
  "todoCom.WarringDialog.788995-8": "Confirm Close?",
  "todoCom.WarringDialog.788995-9": "Yellow",
  "todoCom.WarringDialog.788995-10": "Orange",
  "todoCom.WarringDialog.788995-11": "Blue",
  "todoCom.WarringDialog.788995-12": "Red",
  "todoCom.WarringDialog.788995-13": "{0} Warning",
  "todoCom.WarringDialog.788995-14": "Purple Warning",
  "todoCom.WarringDialog.788995-15": "Other Warnings",
  "todoCom.WarringList.792663-0": "Other",
  "todoCom.WarringList.792663-1": "Warning】",
  "todoCom.WarringList.792663-2": "Project:",
  "todoCom.WarringList.792663-3": "No data available",
  "todoCom.WarringList.792663-4": "Yellow",
  "todoCom.WarringList.792663-5": "Orange",
  "todoCom.WarringList.792663-6": "Blue",
  "todoCom.WarringList.792663-7": "Red",
  "todoCom.WarringList.792663-8": "{0} Warning",
  "todoCom.WarringList.792663-9": "Purple Warning",
  "todoCom.WarringList.792663-10": "Other Warnings",
  "components.UserActions.590825-0": "Clear Cache",
  "components.UserActions.590825-1": "Profile",
  "components.UserActions.590825-2": "Logout",
  "components.UserActions.590825-3": "Clearing...",
  "components.UserActions.590825-4": "Successfully Cleared!",
  "project.Index.426442-1": "All projects",
  "appQrcode.Index.601905-0": "Scan QR Code to Download the App",
  "appQrcode.Index.601905-1": "Please use the built-in QR code scanning function of your device",
  "appQrcode.Index.601905-2": "Scan QR Code to Download Watermark Camera",
  "notice.index.787958-0": "Notices",
  "notice.index.787958-1": "Notice",
  "notice.index.787958-2": "Bulletin",
  "notice.index.787958-3": "Resolution",
  "notice.index.787958-4": "Decision",
  "notice.index.787958-5": "Details",
  "notice.index.787958-6": "No more data",
  "statistic.index.036726-0": "Construction Dashboard",
  "statistic.index.036726-1": "Platform Dynamics",
  "statistic.index.036726-2": "Engineering Albums",
  "statistic.index.036726-3": "No more data",
  "statistic.index.036726-4": "min",
  "statistic.index.036726-5": "Target page not configured yet!",
  "index.Header.055237-0": "search",
  "apps.Index.568824-0": "No Data",
  "apps.Index.568824-1": "System error [No associated applications found]!",
  "apps.Index.568824-2": "Invalid shortcut menu!",
  "apps.Index.568824-3": "Application does not have a valid homepage configured!",
  "apps.Index.568824-4": "Target page not configured yet!",
  "apps.Index.568824-5": "Error in redirect additional parameters!",
  "plalformDynamics.dynamicList.018760-0": "End [",
  "plalformDynamics.dynamicList.018760-1": "No more data available",
  "setLanguage":'Language',
  "index.Header.697681-0": "No matching results",
  "index.Header.697681-1": "System error [No associated applications found]!",
  "index.Header.697681-2": "Invalid shortcut menu!",
  "index.Header.697681-3": "The application is currently not configured with a valid homepage!",
  "index.Header.697681-4": "The target page is not configured yet!",
  "index.Header.697681-5": "Error in redirecting additional parameters!",
  'todoCom.TodoList.me':'me',
}