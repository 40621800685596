<!--
    @name: TaskProps
    @description：TaskProps
    @author: ZengWei
    @date: 2022-05-13 10:11
-->
<template>
  <div class="prop-container">
    <i class="iconfont iconc-close" @click="close"></i>
    <el-tabs type="border-card" v-model="activedTab">
      <el-tab-pane label="基本信息" name="info">
        <div class="scrollbar">
          <div class="constom-label-title" style="padding-top: 0px;">任务标题</div>
          <el-input :disabled="disabled" v-model="task.name" @change="onChange" placeholder="输入任务标题"></el-input>
          <div class="constom-label-title">任务概述</div>
          <el-input
            :disabled="disabled" v-model="task.overview" :rows="2" type="textarea" @change="onChange"
            placeholder="输入任务概述"></el-input>
          <div class="item-box">
            <div class="task-status-box">
              <div class="task-status-title">当前任务状态</div>
              <div class="view-type" v-if="taskMode === 'designer'">
                <div
                  class="view-type-item"
                  :class="{ 'active': task.statusType === 'auto' }"
                  @click="changeView('auto')">
                  <span>自动判定</span>
                </div>
                <div
                  class="view-type-item"
                  :class="{ 'active': task.statusType === 'hand' }"
                  @click="changeView('hand')">
                  <span>手动选择</span>
                </div>
              </div>
            </div>
            <div class="content-box">
              <div class="content-label">状态</div>
              <div class="content status-content" @click="onShowSelectStatus">
                <div class="color-box">
                  <span class="color" :style="`background: ${getStatusColor};`"></span>
                  <span class="label">{{ getStatusName }}</span>
                </div>
                <i
                  v-show="task.statusType === 'hand' && taskMode === 'designer'"
                  class="iconfont iconditushaixuanjiantou_1 color-icon"
                   :style="showStatusOptions ? 'transform: rotate(-180deg);' : 'transform: rotate(0deg);'"></i>
                <ul
                  v-show="task.statusType === 'hand' && showStatusOptions && taskMode === 'designer'"
                  class="status-option">
                  <li
                    v-for="item in task.statusList"
                    :key="item.identifier"
                    class="status-option-item color-box"
                    @click.stop="onSelectStatus(item)">
                    <span class="color" :style="`background: ${item.color};`"></span>
                    <span class="label">{{ item.status }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="item-box" v-if="taskMode === 'designer'">
            <div class="task-status-box">
              <div class="task-status-title">任务状态配置</div>
              <div class="view-type" v-show="task.statusType === 'auto'">
                <div class="view-type-item" :class="{ 'active': task.statusConfigType === 'default' }"
                     @click="changeConfigView('default')">
                  <span>默认</span>
                </div>
                <div class="view-type-item" :class="{ 'active': task.statusConfigType === 'custom' }"
                     @click="changeConfigView('custom')">
                  <span>自定义</span>
                </div>
              </div>
            </div>
            <div v-if="task.statusType === 'auto' && task.statusConfigType === 'custom'" class="content-box">
              <div class="content-label">图块</div>
              <div class="content">
                <el-select
                  v-model="task.statusCell"
                  clearable
                  @change="changeCellValue($event,'statusFormItem')"
                  style="width: 100%;" size="mini">
                  <el-option
                    v-for="(item,index) in blockCells"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div v-if="task.statusType === 'auto' && task.statusConfigType === 'custom'" class="content-box pt6">
              <div class="content-label">字段</div>
              <div class="content">
                <el-select v-model="task.statusField" clearable style="width: 100%;" size="mini" @change="onChange">
                  <el-option
                    v-for="(item,index) in task.statusFormItem"
                    :key="index"
                    :label="item.label"
                    :value="item.field">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 任务状态配置表格 -->
            <TaskStatus
              :key="task.statusConfigType"
              class="pt6"
              :hasCheckbox="(task.statusType === 'auto' && task.statusConfigType === 'default') || task.statusType === 'hand'"
              :hasFieldValue="task.statusType === 'auto' && task.statusConfigType === 'custom'"
              :statusList="task.statusList"
              :isColorEdit="false"
              @check="onCheckTaskStatus"
              @edit="onEditTaskStatus">
            </TaskStatus>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="内容配置" name="content" v-if="taskMode === 'designer'">
        <div class="scrollbar-content-config">
          <div class="content-config" v-for="(item,index) in contentConfigData" :key="index" style="width: 100%; ">
            <div class="constom-label-title content-config-title" style="padding-top: 0px;" :class="{'bus-config-title':item.type === 2}">{{ item.title }}</div>
            <div class="task-config-box" v-show="item.children.length">
              <div v-for="(el,i) in item.children" :key="i" class="task-config-item">
                <div :style="`background: ${el.show_setting ? '#F2F7FF' : '#fff'};`" class="show-box"
                     @click="el.show_setting = !el.show_setting">
                  <div class="name-box">
                    <p style="width: 20px;">
                      <i class="iconfont tree-icon" :class="el.show_setting ? 'iconzhankai2' : 'iconshouqi1'"></i>
                    </p>
                    <i class="iconfont iconjihuawanchengriqi task-icon"></i>
                    <span class="name">{{ el.name }}</span>
                  </div>
                  <div class="oprate-box">
                    <el-checkbox class="checkbox" v-model="el.show" size="large" @change="onChange"></el-checkbox>
                    <p class="show-draggable-box" style="width: 20px;">
                      <i
                        v-if="+item.type === 2"
                        :class="{'show-draggable':!el.show_setting}"
                        class="iconfont icona-guanbiqingchu"
                        :data-index="i"
                        @click.s.stop="handleDel(i)"></i>
                    </p>
                  </div>
                </div>
                <div v-show="el.show_setting" class="setting-box">
                  <div class="content-box">
                    <div class="content-label">名称</div>
                    <div class="content">
                      <el-input style="width: 100%;" v-model="el.name" @change="onChange" placeholder="请输入" size="mini"/>
                    </div>
                  </div>
                  <div v-if="el.value_type" class="content-box pt6">
                    <div class="content-label">取值</div>
                    <div class="content">
                      <el-select style="width: 100%;" v-model="el.value_type" size="mini" @change="onChange">
                        <el-option :value="1" label="手动输入"></el-option>
                        <el-option :value="2" label="引用数据"></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="content-box pt6" v-if="+item.type === 2 && +el.value_type === 1">
                    <div class="content-label">类型</div>
                    <div class="content">
                      <el-select style="width: 100%;" v-model="el.type" size="mini" @change="onChange">
                        <el-option
                          v-for="(item,index) in fieldTypeList"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div v-if="el.value_type === 2" class="content-box pt6">
                    <div class="content-label">图块</div>
                    <div class="content">
                      <el-select
                        style="width: 100%;"
                        v-model="el.cell_block"
                        clearable
                        size="mini"
                        @change="changeCellValue($event, el)">
                        <el-option
                          v-for="(item,index) in blockCells"
                          :key="index"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div v-if="el.value_type === 2" class="content-box pt6">
                    <div class="content-label">字段</div>
                    <div class="content">
                      <el-select
                        style="width: 100%;"
                        v-model="el.cell_field"
                        size="mini"
                        clearable
                        @change="onChange">
                        <el-option
                          v-for="(item,index) in el.formItem"
                          :key="index"
                          :label="item.label"
                          :value="item.field">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div
                    v-if="+item.type === 2 && el.type === 'select' && +el.value_type === 1" class="content-box pt6"
                    :style="el.type === 'select' ? 'align-items: flex-start;' : ''">
                    <div class="content-label">选项</div>
                    <div class="content">
                      <ul class="select-box" style="width: 100%;">
                        <li v-for="(ele,n) in el.options" :key="n" class="select-item">
                          <div class="select-name">
                            <i class="iconfont iconqingchu3" @click="el.options.splice(n, 1)"></i>
                            <span><el-input size="mini" v-model="ele.label"></el-input></span>
                          </div>
                          <i class="iconfont icona-tuozhuaituodong" draggable :data-index="n"
                              @dragstart="handleSelectDrag($event)"></i>
                        </li>
                        <li class="select-item" @click="addSelectItem(el.options)">
                          <div class="select-name add-select-item" style="user-select: none">
                            <i class="iconfont icona-xinzengyuanjiao"></i>
                            <span>添加选项</span>
                          </div>
                        </li>
                        <el-checkbox class="multiple-checkbox" v-model="el.isMultiple" size="large" @change="onChange">多选</el-checkbox>
                      </ul>
                    </div>
                  </div>
                  <div
                    v-if="+item.type === 2" class="content-box pt6"
                    :style="el.type === 'select' ? 'align-items: flex-start;' : ''">
                    <div class="content-label">内容</div>
                    <div class="content uploader-constom-style">
                      <template v-if="el.type === 'input' || +el.value_type === 2">
                        <el-input
                          :disabled="disabled || el.value_type === 2" type="textarea" placeholder="表单填报后自动填充"
                          v-model="el.value" @change="onChange"></el-input>
                      </template>
                      <template v-if="el.type === 'upload' && +el.value_type === 1">
                        <uploader
                          :disabled="disabled"
                          :files="el.attach"
                          @extra-files="onSuccess($event,el)">
                        </uploader>
                      </template>
                      <template v-if="el.type === 'date' && +el.value_type === 1">
                        <el-date-picker
                          :disabled="disabled"
                          style="width: 100%;"
                          v-model="el.value"
                          size="mini"
                          type="date"
                          placeholder="选择日期"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          @change="onChange"
                        >
                        </el-date-picker>
                      </template>
                      <template v-if="el.type === 'select' && +el.value_type === 1">
                        <el-select style="width: 100%;" v-model="el.value" size="mini" @change="onChange">
                          <el-option
                            v-for="(item,index) in el.options"
                            :key="index"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </div>
                  </div>

<!--                  <div v-if="el.value_type === 2 && +item.type === 1" class="content-box pt6">
                    <div class="content-label">间隔(天)</div>
                    <div class="content">
                      <el-input-number
                        v-model="el.dayCount"
                        :min="0"
                        size="mini"
                        controls-position="right"
                        @change="onChange"
                      />
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="add-config-box">
            <div class="add-config" @click="addContent">
              <i class="iconfont icona-xinzengyuanjiao"></i>
              <span>添加新字段</span>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="执行配置" name="conf" v-if="taskMode === 'designer'">
        <div class="scrollbar">
          <div class="constom-label-title" style="padding-top: 0px;">任务引擎模式</div>
          <el-radio-group
            :disabled="disabled"
            @change="onChange"
            v-model="task.task_type"
            size="small">
            <el-radio :label="1">流程模式</el-radio>
            <el-radio :label="2">自由模式</el-radio>
          </el-radio-group>
          <div class="constom-label-title">任务表单绑定</div>
          <div class="item-box">
            <div class="content-box" style="align-items: flex-start;">
              <div class="content-label">类别</div>
              <div class="content">
                <el-select :disabled="disabled" v-model="task.form_type" style="width: 100%;" size="mini">
                  <el-option
                    v-for="(item,index) in taskFormOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-select
                  v-if="+task.form_type === 1"
                  filterable
                  clearable
                  :disabled="disabled"
                  v-model="task.func_form"
                  size="mini"
                  class="select-item pt6"
                  @change="onChange"
                  placeholder="请选择功能表单">
                  <el-option
                    v-for="item in funcFormList"
                    :key="item.id"
                    :label="item.module_name"
                    :value="item.module_uuid">
                  </el-option>
                </el-select>
                <template v-if="+task.form_type === 2">
                  <el-select
                    filterable
                    clearable
                    :disabled="disabled"
                    size="mini"
                    v-model="task.object_uuid"
                    class="select-item pt6"
                    @change="getObjectFormList"
                    placeholder="请选择数据对象">
                    <el-option
                      v-for="item in objectList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.uuid">
                    </el-option>
                  </el-select>
                  <el-select
                    filterable
                    clearable
                    :disabled="disabled"
                    v-model="task.func_form"
                    @change="onChange"
                    size="mini"
                    class="select-item pt6"
                    placeholder="请选择对象表单">
                    <el-option
                      v-for="item in objectFormList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.uuid">
                    </el-option>
                  </el-select>
                </template>

                <el-select
                  v-if="+task.form_type === 3"
                  :disabled="disabled"
                  v-model="task.func_form"
                  @change="onChange"
                  class="select-item pt6"
                  size="mini"
                  clearable
                  filterable
                  remote
                  placeholder="请选择编辑器页面"
                  :loading="loading"
                  :remote-method="remoteMethod"
                >
                  <el-option
                    v-for="item in pageList"
                    :key="item.uuid"
                    :label="item.name"
                    :value="item.uuid">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="constom-label-title">任务责任人</div>
          <div class="item-box">
            <div class="content-box">
              <div class="content-label">选择类别</div>
              <div class="content">
                <el-select
                  v-model="task.user_type"
                  :disabled="disabled"
                  @change="onTypeChange('target')"
                  style="width: 100%;"
                  size="mini">
                  <el-option
                    v-for="(item,index) in taskUserOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div v-if="[1, 2, 6, 7].includes(task.user_type)" class="content-box pt6">
              <div class="content-label">{{ task.user_type === 6 ? '岗位范围' : '人员范围' }}</div>
              <div class="content">
                <el-select
                  :disabled="disabled"
                  v-model="task.user_from"
                  @change="changeUserScope"
                  style="width: 100%;"
                  size="mini">
                  <el-option :value="1" :label="task.user_type === 6 ? '项目岗位' : '项目人员'"></el-option>
                  <el-option :value="3" :label="task.user_type === 6 ? '公司岗位' : '公司人员'"></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="task.user_type === 1" class="content-box pt6">
              <div class="content-label">人员选择</div>
              <div class="content">
                <select-user
                  class="select-depart"
                  style="width: 100%;"
                  :key="'targetuser'+task.user_arch"
                  :company-id="task.user_arch"
                  :disabled="disabled"
                  :show-list="task.target_users"
                  :show-choose-button="false"
                  position-type="left"
                  @on-select-change="onSelectUser($event,'target')"
                />
              </div>
            </div>

            <div v-if="task.user_type === 5" class="content-box pt6">
              <div class="content-label">部门选择</div>
              <div class="content">
                <select-depart
                  class="select-depart"
                  :disabled="disabled"
                  :show-list="task.user_depart"
                  @on-select-change="onSelectDepart"
                />
              </div>
            </div>

            <div v-if="task.user_type === 6" class="content-box pt6">
              <div class="content-label">岗位选择</div>
              <div class="content">
                <el-select
                  :disabled="disabled"
                  v-model="task.user_role"
                  multiple
                  size="mini"
                  placeholder="请选择"
                  class="select-item"
                  @change="onChange">
                  <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="constom-label-title">抄送配置</div>
          <div class="item-box">
            <div class="content-box">
              <div class="content-label">选择类别</div>
              <div class="content">
                <el-select
                  :disabled="disabled"
                  @change="onTypeChange('send')"
                  v-model="task.send_type"
                  style="width: 100%;"
                  size="mini">
                  <el-option
                    v-for="(item,index) in sendUserOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div v-if="[1, 2, 6, 7].includes(task.send_type)" class="content-box pt6">
              <div class="content-label">{{ task.send_type === 6 ? '岗位范围' : '人员范围' }}</div>
              <div class="content">
                <el-select :disabled="disabled" v-model="task.send_from" @change="changeUserScope" style="width: 100%;"
                           size="mini">
                  <el-option :value="1" :label="task.send_type === 6 ? '项目岗位' : '项目人员'"></el-option>
                  <el-option :value="3" :label="task.send_type === 6 ? '公司岗位' : '公司人员'"></el-option>
                </el-select>
              </div>
            </div>
            <div v-if="task.send_type === 1" class="content-box pt6">
              <div class="content-label">人员选择</div>
              <div class="content">
                <select-user
                  class="select-depart"
                  :key="'senduser'+task.send_arch"
                  :companyId="task.send_arch"
                  :disabled="disabled"
                  :show-list="task.send_users"
                  :show-choose-button="false"
                  position-type="left"
                  @on-select-change="onSelectUser($event,'send')"
                />
              </div>
            </div>
            <div v-if="task.send_type === 6" class="content-box pt6">
              <div class="content-label">岗位选择</div>
              <div class="content">
                <el-select
                  :disabled="disabled"
                  v-model="task.send_role"
                  multiple
                  size="mini"
                  placeholder="请选择岗位"
                  class="select-item"
                  @change="onChange">
                  <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="constom-label-title">是否允许分派</div>
          <el-radio-group :disabled="disabled" v-model="task.assign" @change="onChange">
            <el-radio :label="1">允许分派</el-radio>
            <el-radio :label="2">不许分派</el-radio>
          </el-radio-group>

          <div class="constom-label-title">多责任人处理</div>
          <el-radio-group :disabled="disabled" v-model="task.sign" @change="onChange">
            <el-radio :label="1">任意一人处理</el-radio>
            <el-radio :label="2">所有人处理</el-radio>
          </el-radio-group>
<!--          <div class="constom-label-title">任务流转条件</div>
          <el-radio-group :disabled="disabled" class="task-run-radio" v-model="task.run_flow" @change="onChange">
            <el-radio :label="1">发起后流转</el-radio>
            <el-radio :label="2">流程结束后</el-radio>
            <el-radio :label="3">责任人决策</el-radio>
            <el-radio :label="4">自定义条件</el-radio>
          </el-radio-group>
          <task-rules
            v-if="task.run_flow === 4 || ['edge','gateway'].includes(nodeType)"
            :disabled="disabled"
            :allFormFields="allFormFields"
            @on-logic="logicChange"
          ></task-rules>-->

          <div class="constom-label-title">预警配置</div>
          <div class="item-box">
            <el-radio-group v-model="task.isPreWarn" :disabled="disabled" @change="onChange">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
            <div class="content-box pt6" v-if="task.isPreWarn">
              <div class="content-label">依赖日期</div>
              <div class="content">
                <el-select v-model="task.preWarnField" :disabled="disabled" style="width: 100%;" size="mini">
                  <el-option
                    v-for="(item,index) in dependDateOption"
                    :key="index"
                    :label="item.name"
                    :value="item.field">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="content-box pt6" v-if="task.isPreWarn">
              <div class="content-label">提醒天数</div>
              <div class="content">
                <el-input-number
                  v-model="task.dayCount"
                  :min="0"
                  controls-position="right"
                  size="mini"
                />
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="附件资料" name="netdisk" v-if="taskMode === 'recover'">
        <NetDisk v-if="innerNetDiskConfig" :element="innerNetDiskConfig" />
        <el-empty v-else description="网盘暂未配置"></el-empty>
      </el-tab-pane>
      <el-tab-pane label="评论" name="remark" v-if="taskMode === 'recover'">
        <!-- props :param="{ graph_str_id: '节点id'}" :object_uuid="对象 内部已指定任务图对象" :view_uuid="视图 内部已指定任务图视图" -->
        <Remark :param="{graph_str_id: currentNodeId, graph_id: taskRecoverId }" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/* eslint-disable */
import {Tabs, TabPane, RadioGroup, Radio, DatePicker, InputNumber, Checkbox, Empty} from "element-ui";
import uploader from "@/components/fileUpload/newFiles/index";
import selectUser from "@/custom-component/form/formParser/selectUser";
import selectDepart from "@/custom-component/form/formParser/flowEngine/components/selectDepartment";
// import TaskRules from "@/custom-component/task-flow/property/TaskRules";
// import MsgConfig from "@/custom-component/task-flow/components/MsgConfig";
import {dataInterface, transFieldsUUid} from "@/apis/data";
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";
import TaskStatus from '@/custom-component/task-flow/property/TaskStatus.vue'
import NetDisk from '@/custom-component/netDisk/CommonNetDisk.vue'
import Remark from './Remark.vue'

export default {
  name: "TaskProps",
  components: {
    uploader,
    selectUser,
    selectDepart,
    // TaskRules,
    TaskStatus,
    // MsgConfig,
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
    // 'el-radio-button': RadioButton,
    'el-date-picker': DatePicker,
    'el-input-number': InputNumber,
    'el-checkbox': Checkbox,
    'el-empty': Empty,
    Remark,
    NetDisk
  },
  props: [
    'currentItem',
    'currentNodeId',
    'nodeType',
    'formNode',
    'disabled',
    'element',
    'taskMode',
    'activeTab',
    'taskRecoverId'
  ],
  data() {
    return {
      activedTab: this.activeTab,
      archId: 0,
      pageList: [],
      key: '',
      task: {
        name: '任务名称',
        overview: '',
        status: '',
        color: '',
        statusType: 'auto',
        statusConfigType: 'default',
        statusCell: '',
        statusField: '',
        statusFormItem: [],
        statusJudge: '', // 状态判断依据值 4个时间
        statusList: [], // 状态配置
        baseInfo: [], // 基本信息
        content: [], // 自定义内容，业务内容

        task_type: 1, //逻辑任务 自由任务
        user_type: 7,
        target_users: [],
        user_from: 1,
        user_arch: 0,

        send_type: 1,
        send_users: [],
        send_from: 1,
        send_arch: 0,

        user_role: [],
        send_role: [],
        user_depart: [],

        assign: 1,
        sign: 1,
        form_type: 1,
        object_uuid: '',
        object_e_uuid: '',
        object_event: '',
        func_form: '',
        run_flow: 1,
        run_logic: [],
        msg_select: '',

        task_node_field: '',
        task_graph_field: '',

        // 预警配置
        isPreWarn: 0,
        preWarnField: '',
        dayCount: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入任务名称', trigger: 'blur'},
        ],
      },
      departList: [], // 部门数据
      roleList: [], // 岗位数据
      objectList: [],
      objectEventList: [],
      funcFormList: [],
      objectFormList: [],
      blockCells: [],
      cellFormItem: [],
      allFormFields: [],
      loading: false,
      showStatusOptions: false,
      taskUserOptions: [
        {value: 1, label: '指定人员'},
        // {value: 2, label: '发起人分派'},
        {value: 5, label: '指定部门'},
        {value: 6, label: '指定岗位'},
        {value: 7, label: '任务中分派'},
      ],
      sendUserOptions: [
        {value: 1, label: '指定人员'},
        // {value: 2, label: '发起人分派'},
        {value: 6, label: '指定岗位'},
        // {value: 7, label: '任务中分派'},
        // {value: 8, label: '发起人'},
      ],
      taskFormOptions: [
        {value: 1, label: '功能表单'},
        {value: 2, label: '对象表单'},
        {value: 3, label: '页面'},
      ],
      fieldTypeList: [
        {value: 'input', label: '文本'},
        {value: 'upload', label: '上传'},
        {value: 'date', label: '日期'},
        {value: 'select', label: '下拉选择'},
      ],
      contentConfigData: [
        {
          title: '任务内容配置',
          type: 1,
          children: [
            {
              name: '任务责任人',
              field: 'targetUser',
              value_type: null,
              value: '',
              cell_block: '',
              cell_field: '',
              formItem: [],
              show: true,
              show_setting: true
            },
            {
              name: '任务抄送人',
              field: 'sendUser',
              value_type: null,
              value: '',
              cell_block: '',
              cell_field: '',
              formItem: [],
              show: true,
              show_setting: true
            },
            {
              name: '计划开始',
              field: 'planStartDate',
              value_type: 1,
              value: '',
              cell_block: '',
              cell_field: '',
              formItem: [],
              show: true,
              show_setting: true
            },
            {
              name: '计划完成',
              field: 'planEndDate',
              value_type: 1,
              value: '',
              cell_block: '',
              cell_field: '',
              formItem: [],
              show: true,
              show_setting: true
            },
            {
              name: '实际开始',
              field: 'actualStartDate',
              value_type: 1,
              value: '',
              cell_block: '',
              cell_field: '',
              formItem: [],
              show: true,
              show_setting: true
            },
            {
              name: '实际完成',
              field: 'actualEndDate',
              value_type: 1,
              value: '',
              cell_block: '',
              cell_field: '',
              formItem: [],
              show: true,
              show_setting: true
            }
          ]
        },
        {
          title: '业务内容配置',
          type: 2,
          children: [
            /*{
              type: 'input',
              name: '业务内容',
              value_type: 1,
              value: '',
              attach: [],
              cell_block: '',
              cell_field: '',
              show: true,
              formItem: [],
              options: [
                {value: 1, label: '选项1',},
                {value: 1, label: '选项2',},
                {value: 1, label: '选项3',},
              ],
              isMultiple: false,//是否多选
              show_setting: false
            }*/
          ]
        }
      ],
      taskStatusList: []
    }
  },
  computed: {
    // 内置网盘配置
    innerNetDiskConfig() {
      const sourceData = this.element?.innerNetDiskConfig;
      if(!sourceData) {
        return null;
      }

      // 操作需求的参数字段
      const actionConfigParams = [
        {
          code: "graph_cell_id",
          componentId: "",
          componentKey: "",
          fieldUUID: "",
          fixedValue: this.currentNodeId,
          isRequired: true,
          originType: "fixed",
          paramType: "param",
          ruleType: "",
          statusCode: "",
          urlKey: "",
          uuid: ""
        },
        {
          code: "graph_str_id",
          componentId: "",
          componentKey: "",
          fieldUUID: "",
          fixedValue: this.currentNodeId,
          isRequired: true,
          originType: "fixed",
          paramType: "param",
          ruleType: "",
          statusCode: "",
          urlKey: "",
          uuid: ""
        },
        {
          code: "graph_id",
          componentId: "",
          componentKey: "",
          fieldUUID: "",
          fixedValue: this.taskRecoverId,
          isRequired: true,
          originType: "fixed",
          paramType: "param",
          ruleType: "",
          statusCode: "",
          urlKey: "",
          uuid: ""
        },
      ]
      // 填充的配置
      const config = {
        "database": {
          "mapping": "object",
          "requestType": "dataList",
          "objectData": {
            "code": "table1",
            "uuid": "object63eb4f038c03e", // 必填
            "id": "R2558",
            "name": "网盘练习"
          },
          "viewData":{
            "uuid": "view63f30e252db5c", // 必填
            "id": 9402,
            "name": "所有数据_copy"
          },
          "relationData": null,
          "fieldList": [],
          "paramsConfig": [
            // 可选
            {
              "componentId": "",
              "sourceType": "fixed",
              "urlParamsKey": "",
              "isRequired": false,
              "key": "graph_cell_id", // key
              "paramKey": "",
              "statusCode": "",
              "fixedValue": this.currentNodeId // key 值
            },
            {
              "componentId": "",
              "sourceType": "fixed",
              "urlParamsKey": "",
              "isRequired": false,
              "key": "graph_str_id", // key
              "paramKey": "",
              "statusCode": "",
              "fixedValue": this.currentNodeId // key 值
            },
            {
              "componentId": "",
              "sourceType": "fixed",
              "urlParamsKey": "",
              "isRequired": false,
              "key": "graph_id", // key
              "paramKey": "",
              "statusCode": "",
              "fixedValue": this.taskRecoverId // key 值
            }
          ],
          "metadata": [],
          "userOutParams": false,
          "mockData": [],
          "statisticalConfig": []
        },
        "operationConfig": {
          "fileViewUUID": "view63f30e252db5c", // 必填
          "withClass": false,
          "objectUUID": "",
          "viewUUID": "",
          "idUUID": "",
          "labelUUID": "",
          "childrenUUID": "",
          "typeKey": ""
        },
        actionConfig: {
          ...sourceData.actionConfig,
          create: {
            ...sourceData.actionConfig.create,
            paramsConfig: actionConfigParams
          },
          upload: {
            ...sourceData.actionConfig.create,
            paramsConfig: actionConfigParams
          }
        }
      }
      return Object.assign(sourceData, config)
    },
    getStatusColor() {
      const color = this.task.statusList[0]?.color || 'rgb(191, 199, 216)'
      const status = this.task.statusList.find(ele => +ele.index === +this.task.status)
      this.task.status = status?.index
      this.task.color = status?.color || color
      this.onChange()
      return status?.color || color
    },
    getStatusName() {
      const status = this.task.statusList[0]?.status || '未开始'
      return this.task.statusList.find(ele => +ele.index === +this.task.status)?.status || status
    },
    dependDateOption() {
      return this.contentConfigData[0].children.filter(t => !['targetUser', 'sendUser'].includes(t.field))
    },
    // 根据任务内容每一项的类型获取icon图标
    getTaskIcon () {
      return function (val) {
        switch (val) {
          case 1: // 任务负责人
            return 'iconzerenfuzeren';
          case 2: // 任务抄送人
            return 'iconrenwuchaosongren';
          case 3: // 计划开始日期
            return 'iconjihuakaishiriqi';
          case 4: // 计划完成日期
            return 'iconjihuawanchengriqi';
          case 5: // 实际开始日期
            return 'iconshijikaishiriqi';
          case 6: // 实际完成日期
            return 'iconshijiwanchengriqi';
          default: // 业务内容图标
          return 'iconyewuziduan';

        }
      }
    }
  },
  watch: {
    /*currentItem: {
      handler() {
        this.initPropsData()
      },
      immediate: true
    },*/
    activeTab(val){
      this.activedTab = val
    }
  },
  created() {
    this.getFuncDesign();
    this.getObjectList();
    this.remoteMethod();
  },
  mounted() {
    this.getBlockCells()
    this.initPropsData()
  },
  methods: {
    // 任务选择
    onTaskCheck(data) {
      console.log(data, '任务选择')
    },
    // 选择任务状态配置表格
    onCheckTaskStatus(list) {
      this.task.statusList = JSON.parse(JSON.stringify(list))
    },
    // 编辑任务状态配置表格
    onEditTaskStatus(list) {
      this.task.statusList = JSON.parse(JSON.stringify(list))
    },
    // 任务顺序拖拽
    handleDel(index) {
      this.contentConfigData[1].children.splice(index,1)
    },
    // 下拉选项顺序拖拽
    handleSelectDrag(e) {
      console.log(e)
    },
    // 新增下拉选项
    addSelectItem(arr) {
      let newArr = arr || []
      const item = {
        value: newArr.length,
        label: '选项' + newArr.length
      }
      newArr.push(item)
    },
    changeView(val) {
      this.task.color = this.task.statusList[0].color
      this.task.status = this.task.statusList[0].index
      this.task.statusType = val
      this.onChange()
    },
    changeConfigView(val) {
      this.task.statusConfigType = val
      this.onChange()
    },
    // 是否显示状态下拉
    onShowSelectStatus() {
      this.showStatusOptions = !this.showStatusOptions
    },
    // 获取状态以及颜色
    onSelectStatus(data) {
      this.task.color = data.color
      this.task.status = data.index
      this.showStatusOptions = false
    },
    changeCellValue(val, el) {
      // 为什么不用formUuid作为select值，标识图块
      const cell = this.blockCells.find(t => t.id === val)
      if (cell) {
        this.getFormInputItem(cell.formUuid, el)
        // 图块改变字段重置
        if(el === 'statusFormItem'){
          this.task.statusField = ''
        } else {
          el.cell_field = ''
        }
      }
      this.onChange()
    },
    getBlockCells() {
      const cells = this.$parent.graph.toJSON().cells.filter(t => t.shape === 'task')
      this.blockCells = cells.map(item => {
        if (item.data.form_type === 2) {
          return {id: item.id, name: item.data.name, formUuid: item.data.func_form}
        }
      }).filter(t => t);
    },
    remoteMethod(name = '') {
      this.loading = true
      const search = [
        {
          field_uuid: 'd1f7e149-2af7-468e-85be-5ef9bd97f634',
          ruleType: 'like',
          value: name
        },
      ];
      let params = {
        __method_name__: 'dataList',
        object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
        view_uuid: 'view647da1927375d',
        search,
        page: 1,
        size: 20,
        transcode: 0,
        type: 0
      }
      dataInterface(params).then((res) => {
        this.loading = false;
        if (res && res.status === 200 && res.data && res.data.data) {
          this.pageList = res.data.data.data || [];
        }
      })
    },
    getFormHasField() {
      const nodeData = this.$parent.getPreTask(this.currentNodeId, this.nodeType)
      const formNode = nodeData.filter(item => item.form_type)
      dataInterface({formNode: formNode}, '/api/taskengine/taskfield').then(res => {
        if (res.data.code === 200) {
          this.allFormFields = res.data.data
        }
      })
    },
    initPropsData() {
      const name = this.nodeType === 'edge' ? '逻辑条件' : '任务名称';
      this.task.name = this.currentItem?.name || name
      if(this.currentItem?.status === undefined) {
        this.task.status = '' // 未使用|| 存在0情况
      } else {
        this.task.status = this.currentItem?.status
      }
      this.task.statusType = this.currentItem?.statusType || 'auto'
      this.task.statusConfigType = this.currentItem?.statusConfigType || 'default'
      this.task.statusList = this.currentItem?.statusList || []
      this.task.statusCell= this.currentItem?.statusCell || ''
      this.task.statusFormItem= this.currentItem?.statusFormItem || []
      this.task.statusField= this.currentItem?.statusField || ''
      this.task.color = this.currentItem?.color || '#BFC7D8'
      this.task.warning = this.currentItem?.warning || false

      this.task.baseInfo = this.currentItem?.baseInfo || []
      if(this.task.baseInfo.length){
        this.contentConfigData[0].children = this.task.baseInfo
      }
      this.task.content = this.currentItem?.content || []
      if(this.task.content.length){
        this.contentConfigData[1].children = this.task.content
      }

      // 添加show_setting属性，用于判断是否展开编辑
      if (this.task.content.length) {
        this.task.content.forEach(el => {
          el.show_setting = false
        })
      }

      this.task.task_type = this.currentItem?.task_type || 1
      this.task.user_type = this.currentItem?.user_type || 7
      this.task.target_users = this.currentItem?.target_users || []
      this.task.user_from = this.currentItem?.user_from || 1
      this.task.user_arch = this.currentItem?.user_arch || 0
      this.task.user_role = this.currentItem?.user_role || []
      this.task.user_depart = this.currentItem?.user_depart || []

      this.task.send_type = this.currentItem?.send_type || 1
      this.task.send_users = this.currentItem?.send_users || []
      this.task.send_from = this.currentItem?.send_from || 1
      this.task.send_arch = this.currentItem?.send_arch || 0
      this.task.send_role = this.currentItem?.send_role || []

      this.task.assign = this.currentItem?.assign || 1
      this.task.sign = this.currentItem?.sign || 1
      this.task.form_type = this.currentItem?.form_type || 1
      this.task.object_uuid = this.currentItem?.object_uuid || ''
      this.task.object_e_uuid = this.currentItem?.object_e_uuid || ''
      this.task.object_event = this.currentItem?.object_event || ''
      this.task.func_form = this.currentItem?.func_form || ''

      this.task.showField = this.currentItem?.showField || []
      this.task.run_flow = this.currentItem?.run_flow || 1
      this.task.run_logic = this.currentItem?.run_logic || []
      this.task.msg_select = this.currentItem?.msg_select || ''
      this.task.task_node_field = this.currentItem?.task_node_field || ''
      this.task.task_graph_field = this.currentItem?.task_graph_field || ''

      this.task.isPreWarn = this.currentItem?.isPreWarn || 0
      this.task.preWarnField = this.currentItem?.preWarnField || ''
      this.task.dayCount = this.currentItem?.dayCount || ''

      if (this.task.object_uuid) {
        this.getObjectFormList(this.task.object_uuid)
      }
      if (this.task.form_type === 2 && this.task.func_form) {
        this.getFormInputItem(this.task.func_form)
      }
    },
    onSuccess(file, item) {
      item.attach = file
      this.onChange()
    },
    getFuncDesign() {
      const url = `api/form4/design?task=true&page=1&per_page=1000`
      dataInterface({}, url, 'GET').then(res => {
        if (res.data.code === 200) {
          this.funcFormList = res.data.data.data
        }
      })
    },
    getObjectList() {
      const url = `api/metadata/objects?page=1&size=2000`
      dataInterface({}, url, 'GET').then(res => {
        if (res.data.code === 200) {
          this.objectList = res.data.data.data
        }
      })
    },
    getObjectFormList(objectUuid) {
      const url = `api/metadata/getFormDesigns?object_uuid=${objectUuid}`
      dataInterface({}, url, 'GET').then(res => {
        if (res.data.code === 200) {
          this.objectFormList = res.data.data.filter(item => item.uuid !== '')
        }
      })
    },
    getFormInputItem(formUuid, data = '') {
      transFieldsUUid(formUuid).then(res => {
        const jsonFields = res.data.data?.fields || []
        if (data === 'statusFormItem') {
          this.task.statusFormItem = viewGetFormItem(jsonFields)
        } else if (data) {
          data.formItem = viewGetFormItem(jsonFields)
        }
      })
    },
    // 获取项目或公司岗位
    getArchRole() {
      let queryData;
      const archiInfo = this.$GetTargetArchi()
      if (this.task.user_from === 1 || this.task.send_from === 1) {
        queryData = {
          object_uuid: 'object6170c260890d9',
          view_uuid: 'view6172842c7f669',
          project_id: archiInfo.archi_id,
          transcode: 0,
          __method_name__: 'dataList'
        }
      } else {
        queryData = {
          object_uuid: 'object6170c260890d9',
          view_uuid: 'view61728398b0390',
          company_id: archiInfo.company_id,
          transcode: 0,
          __method_name__: 'dataList'
        }
      }
      dataInterface(queryData).then(res => {
        if (res.data.code === 200) {
          this.roleList = res.data.data;
        }
      })
    },
    onTypeChange(flag = 'target') {
      if(flag === 'target'){
        this.task.user_from = 1
        this.task.target_users = []
        this.task.user_depart = []
        this.task.user_arch = 0
        this.task.user_role = []
      } else {
        this.task.send_from = 1
        this.task.sendr_arch = 0
        this.task.send_users = []
        this.task.send_role = []
      }
      if (this.task.user_type === 6) {
        this.getArchRole() //请求岗位接口
      }
      this.onChange()
    },
    changeUserScope() {
      if (this.task.user_type === 6 || this.task.send_type === 6) {
        this.getArchRole() //请求岗位接口
      }
      let targetArch = this.$GetTargetArchi()
      if (this.task.user_from === 3) {
        this.task.user_arch = targetArch.company_id ?
          targetArch.company_id : targetArch.archi_id
      } else {
        this.task.user_arch = 0
      }
      if (this.task.send_from === 3) {
        this.task.send_arch = targetArch.company_id ?
          targetArch.company_id : targetArch.archi_id
      } else {
        this.task.send_arch = 0
      }
    },
    addContent() {
      const newData = {
        type: 'input', name: '业务内容', value_type: 1,
        value: '', attach: [], cell_block: '',
        cell_field: '', show: true, formItem: [],
        show_setting: false, options: []
      }
      this.contentConfigData[1].children.push(newData)
      // this.task.content.push(newData)
      this.onChange()
    },
    delContent(index) {
      this.task.content.splice(index, 1);
      this.onChange()
    },
    delShowField(index) {
      this.task.showField.splice(index, 1);
      this.onChange()
    },
    logicChange(logicRules) {
      this.task.run_logic = logicRules
      this.onChange()
    },
    msgChange(msgId) {
      this.task.msg_select = msgId
      this.onChange()
    },
    onChange() {
      let data = {}
      if (['edge', 'gateway'].includes(this.nodeType)) {
        const {name, run_logic} = this.task
        data = {name, run_logic}
      } else if (['start', 'end'].includes(this.nodeType)) {
        const {
          user_type, target_users, user_from, user_arch, user_role, user_depart,
          send_type, send_users, send_from, send_arch, send_role, assign, sign,
          form_type, object_uuid, object_e_uuid, object_event, func_form, run_flow,
          run_logic, msg_select
        } = this.task
        data = {
          user_type, target_users, user_from, user_arch, user_role, user_depart,
          send_type, send_users, send_from, send_arch, send_role, assign, sign,
          form_type, object_uuid, object_e_uuid, object_event, func_form, run_flow,
          run_logic, msg_select
        }
      } else {
        const baseInfo = this.contentConfigData[0].children
        const content = this.contentConfigData[1].children
        this.task.baseInfo = baseInfo
        this.task.content = content
        data = this.task
      }
      this.getBlockCells()
      this.$emit('on-change', data)
    },
    onSelectUser(userData, flag) {
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          user_id: item.user_id,
          name: item.name,
          avatar: item.avatar,
          user_table: item.user_table,
          section_id: item.section_id
        }
        users.push(user)
      }
      if (flag === 'target') {
        this.task.target_users = users
      } else if (flag === 'send') {
        this.task.send_users = users
      }
      this.onChange()
    },
    onSelectDepart(depart) {
      let departList = []
      for (let item of depart) {
        let users = [] // 部门用户
        for (let user of item.users) {
          let userItem = {
            id: user.user_id,
            user_id: user.user_id,
            name: user.name,
            avatar: user.avatar,
            user_table: user.user_table,
            section_id: user.section_id
          }
          users.push(userItem)
        }
        let itemData = {
          id: item.id,
          name: item.name,
          section_id: item.id,
          users: users
        }
        departList.push(itemData)
      }
      this.task.user_depart = departList
      this.onChange()
    },
    close() {
      this.$emit('on-close')
    },
    upGo(index, flag = 'content') {
      let fieldData = this.task[flag];
      if (index != 0) {
        fieldData[index] = fieldData.splice(index - 1, 1, fieldData[index])[0];
      } else {
        fieldData.push(fieldData.shift());
      }
      this.task[flag] = fieldData;
      this.onChange();
    },
    downGo(index, flag = 'content') {
      let fieldData = this.task[flag];
      if (index != fieldData.length - 1) {
        fieldData[index] = fieldData.splice(index + 1, 1, fieldData[index])[0];
      } else {
        fieldData.unshift(fieldData.splice(index, 1)[0]);
      }
      this.task[flag] = fieldData;
      this.onChange();
    },
  },
}
</script>

<style lang="less" scoped>
.scrollbar {
  overflow-y: auto;
  padding: 15px 15px 0 15px;
  height: calc(100% - 15px);
}

:deep(.el-tabs--border-card) {
  border: 1px solid #DCDFE6;
}

:deep(.form-table) {
  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    /*margin-bottom: -1px !important;*/

    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      /*margin-bottom: 1px;*/

      .el-select,
      .el-cascader {
        width: 100%;
      }

      .self-type {
        .el-radio {
          margin-right: 18px;
        }
      }
    }
  }
}

.label-title {
  border-left: 3px solid #007FE0;
  padding: 0 10px;
  line-height: 1;
  margin: 0 0 16px;
  font-weight: 600;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.select-item {
  width: 100%;
}

.mb-10 {
  margin-bottom: 10px;
}

.float-r {
  float: right;
  padding: 0 1px;
  cursor: pointer;
}

.self-define {
  margin: 8px 0;
  text-indent: 2px;
}

:deep(.user-type-radio) {
  .el-radio {
    width: 74px;
    margin-bottom: 15px;
  }
}

:deep(.task-run-radio) {
  .el-radio {
    width: 125px;
    margin-bottom: 15px;
  }
}


.prop-container {
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;

  :deep(.el-tabs) {
    height: 100%;

    .el-tab-pane {
      height: 100%;
      display: flex;
      flex-direction: column;

      .btn-group {
        text-align: right;
        padding: 16px;
        box-sizing: border-box;

        .btn {
          width: 106px;
          height: 32px;
          padding: 5px 0;
          border-radius: 6px;
          gap: 8px;
          border: none;
          font-family: var(--systemFontFamily);
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.5px;
          text-align: center;
        }

        .cancel {
          background: #F2F5FA;
        }
      }
    }

    .el-tabs__content {
      padding: 0;
      height: calc(100% - 40px);

      .el-tab-pane {
        height: 100%;
      }
    }
  }

  .iconc-close {
    position: absolute;
    font-size: 22px;
    top: 5px;
    right: 8px;
    z-index: 10;
    cursor: pointer;
  }

  .property {
    height: calc(100% - 52px);
  }

  .constom-label-title {
    font-family: var(--systemFontFamily);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    padding-top: 16px;
    padding-bottom: 6px;
  }

  .item-box {
    width: 100%;

    :deep(.task-config-form) {
      .el-form--label-left .el-form-item__label {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.4px;
      }
    }

    .task-status-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 6px;
      box-sizing: border-box;
      overflow: hidden;

      .task-status-title {
        font-family: var(--systemFontFamily);
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
      }

      .view-type {
        width: 126px;
        height: 24px;
        padding: 2px;
        background: #F2F5FA;
        box-sizing: border-box;
        display: flex;
        border-radius: 4px;

        .view-type-item {
          flex: 1;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-family: var(--systemFontFamily);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.4px;
            text-align: left;
            color: #707786;
            display: inline-block;
            padding: 1px 5px;
          }
        }

        .active {
          background: #fff;

          span {
            color: #181B22;
          }
        }
      }
    }

    .pt6 {
      padding-top: 6px;
    }

    .status-content {
      border: 1px solid #F0F1F4;
      border-radius: 2px;
      padding: 5px 6px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .color-box {
        display: flex;
        align-items: center;

        .color {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 2px;
        }

        .label {
          display: inline-block;
          font-family: var(--systemFontFamily);
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.4px;
          text-align: left;
          padding-left: 8px;
        }
      }

      .color-icon {
        display: inline-block;
      }

      .status-option {
        position: absolute;
        top: 32px;
        right: 0;
        width: 115px;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0px 2px 16px 0px #191B231A;
        padding: 4px;
        box-sizing: border-box;
        z-index: 100;

        .status-option-item {
          width: 100%;
          padding: 5px 12px;
          box-sizing: border-box;
          cursor: pointer;

          &:hover {
            background: #F4F6F9;
            border-radius: 2px;
          }
        }
      }
    }
  }

  .scrollbar-content-config {
    padding: 15px 0;
    box-sizing: border-box;
    overflow-y: auto;

    .content-config {
      width: 100%;

      .content-config-title {
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid #F0F1F4;
      }

      .bus-config-title {
        padding-top: 10px !important;
        border-top: 1px solid #F0F1F4;
      }

      .task-config-box {
        width: 100%;
        padding: 6px 0;
        box-sizing: border-box;

        .task-config-item {
          width: 100%;

          .show-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px;
            box-sizing: border-box;
            overflow: hidden;

            .name-box {
              flex: 1;
              display: flex;
              align-items: center;

              .task-icon {
                display: inline-block;
                padding: 0 6px;
              }

              .name {
                width: 100%;
                display: inline-block;
                font-family: var(--systemFontFamily);
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.4px;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                box-sizing: border-box;
              }

              .tree-icon {
                display: none;
                color: #8991A2;
              }

            }

            .oprate-box {
              display: flex;
              align-items: center;
              .checkbox {
                height: 12px;
                width: 12px;
              }
              .show-draggable-box{
                width: 20px;
                height: auto;
                text-align: right;
                padding-left: 4px;
                .iconfont {
                  display: none;
                }

              }
            }

            &:hover {
              background: #F0F1F4 !important;

              .name-box {
                .tree-icon {
                  display: block;
                }
              }

              .oprate-box {
                .show-draggable-box{
                  .show-draggable {
                    display: block;
                  }
                }
              }
            }
          }

          .setting-box {
            width: 100%;
            padding: 16px 28px;
            box-sizing: border-box;
            overflow: hidden;
            :deep(.uploader-constom-style){
              .file-upload{
                display: flex;
                align-items: center;
                .el-button--small{
                  padding: 5px 15px;
                }
              }
            }
            .select-box{
              width: 100%;
              .select-item {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 12px;
                box-sizing: border-box;
                overflow: hidden;
                margin-bottom: 2px;
                background: #F4F6F9;
                border-radius: 8px;
                cursor: pointer;

                .select-name {
                  flex: 1;
                  display: flex;
                  align-items: center;

                  .iconfont {
                    color: #DA383D;
                  }

                  span {
                    font-family: var(--systemFontFamily);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.4px;
                    text-align: left;
                    display: inline-block;
                    padding-left: 8px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                  }

                }

                .add-select-item {

                  .iconfont, span {
                    color: #387FFC;
                  }
                }

                .iconfont {
                  display: inline-block;
                }
              }

              .multiple-checkbox {
                padding-top: 8px;
              }
            }
          }
        }
      }
    }
  }

  .add-config-box {
    width: 100%;
    padding: 8px 15px;
    box-sizing: border-box;

    .add-config {
      width: 100%;
      padding: 5px 0;
      border: 1px solid #387FFC;
      border-radius: 3px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;

      .iconfont, span {
        display: inline-block;
        color: #387FFC;
      }

      span {
        font-family: var(--systemFontFamily);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.4px;
        text-align: center;
        padding-left: 4px;
      }
    }
  }

  .pt6 {
    padding-top: 6px;
  }

  .content-box {
    width: 100%;
    display: flex;
    align-items: center;

    .content-label {
      font-family: var(--systemFontFamily);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.4px;
      text-align: left;
      padding-right: 8px;
      padding-left: 6px;
      box-sizing: border-box;
    }

    .content {
      flex: 1;
      box-sizing: border-box;

      :deep(.select-depart) {
        width: 100%;
        .show-value {
          height: 28px;
          line-height: 28px;
          box-sizing: border-box !important;
        }
        .new-select-style{
          height: auto;
        }
      }
    }
  }
}
</style>
