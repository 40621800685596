/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-18 09:37:46
 * @FilePath: \dataview-next\src\custom-component\weather\lang\en.js
 */
export default {  
  "weather.WeatherDay.740282-0": "Weather Analysis",
  "weather.WeatherDay.740282-1": "Humidity",
  "weather.WeatherDay.740282-2": "Wind Speed",
  "weather.WeatherDay.740282-3": "Air Pressure",
  "weather.WeatherDay.740282-4": "Temperature",
  "weather.WeatherDay.740282-5": "Excellent",
  "weather.WeatherDay.740282-6": "Good",
  "weather.WeatherDay.740282-7": "Mild Pollution",
  "weather.WeatherDay.740282-8": "Severe Pollution",
  "weather.WeatherDay.740282-9": "Serious Pollution",
  "weather.WeatherWeek.961601-0": "Weather Conditions",
  "weather.WeatherWeek.961601-1": "Humidity:",
  "weather.WeatherWeek.961601-2": "Yesterday",
  "weather.WeatherWeek.961601-3": "Today",
  "weather.WeatherWeek.961601-4": "Tomorrow",
  "weather.WeatherWeek.961601-5": "Excellent",
  "weather.WeatherWeek.961601-6": "Good",
  "weather.WeatherWeek.961601-7": "Light Pollution",
  "weather.WeatherWeek.961601-8": "Heavy Pollution",
  "weather.WeatherWeek.961601-9": "Severe Pollution"
}