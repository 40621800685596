/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-14 16:10:01
 * @FilePath: \dataview-next\src\custom-component\tree\lang\zh.js
 */

export default {
  "tree.CommonTree.463131-0": "前",
  "tree.CommonTree.463131-1": "删除",
  "tree.CommonTree.463131-2": "父级id",
  "tree.CommonTree.463131-3": "组件【{0}】数据接口无【id】字段返回，请检查接口配置！",
  "tree.CommonTree.463131-4": "组件【{0}】数据接口存在【{1}】个【id】字段返回，请检查接口配置！以保证准确性！",
  "tree.CommonTree.463131-5": "事件配置错误！",
  "tree.CommonTree.463131-6": "操作成功！",
  "tree.CommonTree.463131-7": "是否确认删除?",
  "tree.CommonTree.463131-8": "提示",
  "tree.CommonTree.463131-9": "确定",
  "tree.CommonTree.463131-10": "取消",
  "tree.CommonTree.463131-11": "导出中....",
  "tree.CommonTree.463131-12": "导出失败！数据验证错误。",
  "tree.CommonTree.463131-13": "暂无数据可供导出！",
  "tree.CommonTree.463131-14": "导出配置错误！",
  "tree.CommonTree.463131-15": "导出成功！",
  "tree.CommonTree.463131-16": "导出失败！"
}


