<template>
    <div class="moduleWrap vertical-flex">
        <div class="title-wrap">
            <img class="title-icon" src="../background/entity.png">
            <span class="title-span">标记标绘</span>
        </div>

        <div class="btn-wrap">
            <div v-for="item in btnList" :key="item.id" @click="onClickBtn(item)"
                 :class="['btn-item vertical-flex', currentBtn === item.id ? 'btnItem-active' : '']">
                <a :class="['btnItem-icon iconfont', item.icon]"></a>
                <span class="btnItem-content">{{item.name}}</span>
            </div>
        </div>

        <div class="content-wrap" v-if="!isEditing">
            <div class="content-wrap vertical-flex" v-if="currentBtn === 'point'">
                <span class="contentWrap-title">系统模板</span>

                <div class="contentWrap-item">
                    <img v-for="item in pointList" :key="item.id" :src="item.img" :title="item.name"
                         :class="['contentItem-img', {'contentItem-img-active': currentTemplet === item.id}]"
                         @click="onClickPoint(item)">
                </div>
            </div>

            <div class="content-wrap vertical-flex" v-if="currentBtn === 'marker'">
                <span class="contentWrap-title">系统模板</span>

                <div class="contentWrap-item">
                    <img v-for="item in markerList" :key="item.id" :src="item.img" :title="item.name"
                         :class="['contentItem-img', {'contentItem-img-active': currentTemplet === item.id}]"
                         @click="onClickMarker(item)">
                </div>
            </div>

            <div class="content-wrap vertical-flex" v-if="currentBtn === 'plot2d'">
                <span class="contentWrap-title">系统模板</span>

                <div class="contentWrap-item">
                    <img v-for="item in plot2dList" :key="item.id" :src="item.img" :title="item.name"
                         :class="['contentItem-img', {'contentItem-img-active': currentTemplet === item.id}]"
                         @click="onClickPlot(item)">
                </div>
            </div>

            <div class="content-wrap vertical-flex" v-if="currentBtn === 'plot3d'">
                <span class="contentWrap-title">系统模板</span>

                <div class="contentWrap-item">
                    <img v-for="item in plot3dList" :key="item.id" :src="item.img" :title="item.name"
                         :class="['contentItem-img', {'contentItem-img-active': currentTemplet === item.id}]"
                         @click="onClickPlot(item)">
                </div>
            </div>
        </div>

        <div class="content-wrap vertical-flex" v-if="isEditing">
            <span class="contentWrap-title">属性编辑</span>

            <EditorList
                    ref="editor"
                    class="editing-wrap"
                    :element.sync="element"
                    :bindViewerId="bindViewerId"
                    :editList="editList"
            ></EditorList>

            <el-button type="success" class="editing-apply" @click="onEditBtn('apply')">应用</el-button>

            <div class="horizontal-flex editing-bottom">
                <el-button type="info" class="editing-btn" @click="onEditBtn('cancel')">取消</el-button>
                <el-button type="primary" class="editing-btn" @click="onEditBtn('save')">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import eventBus from '@/plugins/eventBus';
    import EditorList from "../../viewerComponent/components/EditorList";
    import PlotUtils from "../../viewerComponent/components/PlotUtils";
    import {Button} from 'element-ui';

    let markerUtils;
    export default {
        name: "EntityEditor",
        components: {
            EditorList,
            'el-button': Button
        },
        data(){
            return {
                btnList: [
                    {id: 'point', name: '标点', icon: 'icongis_dingwei'},
                    {id: 'marker', name: '标签', icon: 'iconbimgis_biaoji'},
                    {id: 'plot2d', name: '二维标绘', icon: 'icongis_duobianxing'},
                    {id: 'plot3d', name: '三维标绘', icon: 'icongis_moxingshu'},
                    // {id: 'singleBox', name: '单体化', icon: 'icongis_dantihua'},
                    // {id: 'scanLine', name: '扫描线', icon: 'iconquanping2'},
                ],
                currentBtn: 'point',

                currentTemplet: '',
                currentModule: '',
                pointList: [
                    {id: 'pinIcon', name: '图钉点', img: 'https://models.bimcc.net/gisFiles/images/marker/pinIcon.jpg'},
                    {id: 'pinSolid', name: '实心图顶点', img: 'https://models.bimcc.net/gisFiles/images/marker/pinSolid.jpg'},
                    {id: 'wavePointIcon', name: '波浪点', img: 'https://models.bimcc.net/gisFiles/images/marker/wavePoint.jpg'},
                    {id: 'pointIcon', name: '扩散点', img: 'https://models.bimcc.net/gisFiles/images/marker/pointIcon.jpg'},
                ],
                markerList: [],
                plot2dList: [
                    {id: 'polyline', name: '折线', tag: 'polyline', img: 'https://models.bimcc.net/gisFiles/images/plot/polyline.jpg'},
                    {id: 'polylineGround', name: '折线-贴地', tag: 'polyline', img: 'https://models.bimcc.net/gisFiles/images/plot/polylineGround.jpg'},
                    {id: 'curve', name: '曲线', tag: 'polyline', img: 'https://models.bimcc.net/gisFiles/images/plot/curve.jpg'},
                    {id: 'curveGround', name: '曲线-贴地', tag: 'polyline', img: 'https://models.bimcc.net/gisFiles/images/plot/curveGround.jpg'},
                    {id: 'rectangle', name: '矩形', tag: 'rectangle', img: 'https://models.bimcc.net/gisFiles/images/plot/rectangle.jpg'},
                    {id: 'rectangleGround', name: '矩形-贴地', tag: 'rectangle', img: 'https://models.bimcc.net/gisFiles/images/plot/rectangleGround.jpg'},
                    {id: 'circle', name: '圆', tag: 'circle', img: 'https://models.bimcc.net/gisFiles/images/plot/circle.jpg'},
                    {id: 'circleGround', name: '圆-贴地', tag: 'circle', img: 'https://models.bimcc.net/gisFiles/images/plot/circleGround.jpg'},
                    {id: 'polygon', name: '多边形', tag: 'polygon', img: 'https://models.bimcc.net/gisFiles/images/plot/polygon.jpg'},
                    {id: 'polygonGround', name: '多边形-贴地', tag: 'polygon', img: 'https://models.bimcc.net/gisFiles/images/plot/polygonGround.jpg'}
                ],
                plot3dList: [
                    {id: 'wall', name: "墙体", tag: 'polyline', img: 'https://models.bimcc.net/gisFiles/images/plot/wall.jpg'},
                    {id: 'corridor', name: "走廊", tag: 'polyline', img: 'https://models.bimcc.net/gisFiles/images/plot/corridor.jpg'},
                    {id: 'extrudedPolygon', name: "多边立体", tag: 'polygon', img: 'https://models.bimcc.net/gisFiles/images/plot/extrudedPolygon.jpg'},
                    {id: 'extrudedRectangle', name: "立方体", tag: 'rectangle', img: 'https://models.bimcc.net/gisFiles/images/plot/extrudedRectangle.jpg'},
                    {id: 'box', name: "盒子", tag: 'point', img: 'https://models.bimcc.net/gisFiles/images/plot/box.jpg'},
                    {id: 'cylinder', name: "圆柱", tag: 'circle', img: 'https://models.bimcc.net/gisFiles/images/plot/cylinder.jpg'},
                    {id: 'ellipsoid', name: "球体", tag: 'circle', img: 'https://models.bimcc.net/gisFiles/images/plot/ellipsoid.jpg'}
                ],

                isEditing: false,
                pointOptions: null,
                pointEditList: [
                    {id: 'iconColor', type: 'objSelector', name: '标点颜色选择', options: [
                            {id: 'blue', name: '蓝色'},
                            {id: 'green', name: '绿色'},
                            {id: 'red', name: '红色'}
                        ], value: 'blue'},
                    {id: 'width', type: 'input', name: '标点宽度', value: 35, tag: 'Number'},
                    {id: 'height', type: 'input', name: '标点高度', value: 35, tag: 'Number'},
                    {id: 'horizontalOrigin', type: 'objSelector', name: '标点横轴对齐', options: [
                            {id: 0, name: 'Center'},
                            {id: 1, name: 'Left'},
                            {id: -1, name: 'Right'}
                        ], value: 0},
                    {id: 'verticalOrigin', type: 'objSelector', name: '标点纵轴对齐', options: [
                            {id: 0, name: 'Center'},
                            {id: 1, name: 'Bottom'},
                            {id: 2, name: 'BaseLine'},
                            {id: -1, name: 'Top'},
                        ], value: 1},
                ],
                markerEditList: [
                    {id: 'content', type: 'input', name: '描述（内容）', value: '标记点'},
                    {id: 'iconSetting', type: 'iconSelectorNumber', name: '图标设置', options: [], value: 'location', num: 36},
                    {id: 'fillColor', type: 'colorPicker', name: '填充颜色', value: '#FFFFFF'},
                    {id: 'markerWidth', type: 'input', name: '图标宽度', value: 180, tag: 'Number'},
                    {id: 'markerHeight', type: 'input', name: '图标高度', value: 75, tag: 'Number'},
                    {id: 'backgroundColor', type: 'colorPicker', name: '文本背景颜色', value: '#FFFFFF'},
                    {id: 'startColor', type: 'colorPicker', name: '渐变起始颜色', value: '#FFFFFF'},
                    {id: 'endColor', type: 'colorPicker', name: '渐变终止颜色', value: '#FFFFFF'},
                    {id: 'borderColor', type: 'colorPicker', name: '渐变边框颜色', value: '#FFFFFF'},
                    {id: 'isLeftTurn', type: 'radio', name: '是否左镜像', value: false},

                    {id: 'overflow', type: 'objSelector', name: '文本格式', options: [
                            {id: 'nowrap', name: '单行文本'},
                            {id: 'wrap', name: '多行文本'},
                        ], value: 'wrap'},
                    {id: 'width', type: 'input', name: '宽度设置', value: 150, tag: 'Number'},
                    {id: 'height', type: 'input', name: '宽度设置', value: 150, tag: 'Number'},
                    {id: 'color', type: 'colorPicker', name: '图标颜色', value: '#00a1ff'},
                    {id: 'fontSize', type: 'input', name: '字体大小', value: 12, tag: 'Number'},
                    {id: 'fontColor', type: 'colorPicker', name: '字体颜色', value: '#ffffff'},
                    {id: 'fontAlign', type: 'objSelector', name: '字体排列', options: [
                            {id: 'left', name: '靠左排列'},
                            {id: 'center', name: '居中排列'},
                            {id: 'right', name: '靠右排列'}
                        ], value: 'left'},
                    {id: 'isClampToGround', type: 'radioInfo', name: '是否贴地', value: false}
                ],
                plotEditList: [
                    {id: 'radius', type: 'input', name: '半径(单位：米)', value: '', tag: 'Number'},
                    {id: 'width', type: 'input', name: '宽度(单位：米)', value: '', tag: 'Number'},
                    {id: 'minHeight', type: 'input', name: '最小海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'maxHeight', type: 'input', name: '最大海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'boxLong', type: 'input', name: '盒子长度(单位：米)', value: '', tag: 'Number'},
                    {id: 'boxWidth', type: 'input', name: '盒子宽度(单位：米)', value: '', tag: 'Number'},
                    {id: 'boxHeight', type: 'input', name: '盒子高度(单位：米)', value: '', tag: 'Number'},
                    {id: 'ellipsoidHeight', type: 'input', name: '球心海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'perPositionHeight', type: 'input', name: '固定海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'height', type: 'input', name: '海拔(单位：米)', value: '', tag: 'Number'},
                    {id: 'fill', type: 'radio', name: '是否填充', value: true},
                    {id: 'fillType', type: 'objSelector', name: '填充类型', options: [], value: ''},

                    {id: 'fillColor', type: 'colorPicker', name: '填充颜色', value: '#FFFFFF'},
                    {id: 'fillWidth', type: 'input', name: '填充宽度', value: 3, tag: 'Number'},
                    {id: 'alpha', type: 'slider', name: '不透明度(单位：%)', value: 100, min: 0, max: 100, step: 1},
                    {id: 'outline', type: 'radio', name: '是否边框', value: true},
                    {id: 'outlineColor', type: 'colorPicker', name: '边框颜色', value: '#FFFFFF'},
                    {id: 'outlineAlpha', type: 'slider', name: '边框不透明度(单位：%)', value: 100, min: 0, max: 100, step: 1},
                    {id: 'outlineWidth', type: 'input', name: '边框线宽', value: 1, tag: 'Number'},
                    {id: 'labelColor', type: 'colorPicker', name: '字体颜色', value: '#FFFFFF'},
                    {id: 'labelSize', type: 'input', name: '字体大小', value: 1, tag: 'Number'},
                    {id: 'isClampToGround', type: 'radio', name: '是否贴地', value: true},
                    {id: 'isDepth', type: 'radio', name: '是否遮挡', value: true},
                    {id: 'rotation', type: 'input', name: '旋转角度', value: 1, tag: 'Number'},
                    {id: 'stRotation', type: 'input', name: '材质角度', value: 1, tag: 'Number'},
                ],
                editList: [],
                editObject: null
            }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            bindViewerId: {
                type: String,
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mounted(){
            eventBus.$emit('ViewerGIS.getMarkerOptions', this.bindViewerId, 'Billboard', (res) => {
                this.pointOptions = res;
            });

            eventBus.$emit('ViewerGIS.getTemplateMarkerUtils', this.bindViewerId, (res) => {
                markerUtils = res;
                let collection = markerUtils.getCollection('GIS');
                this.markerList = collection[0].children;
                this.markerEditList[1].options = markerUtils.getIconArray();
            });
        },
        methods: {
            getMarkerOptions(data){
                if(data.imageType === 'canvas'){
                    let parent = markerUtils.getMarkerModule(data.markerType);
                    let canvas = markerUtils.getMarkerUrlByType(parent, data.markerType, data.options);
                    data.canvas = canvas;
                }

                return data;
            },
            getEditOptions(list){
                let res = {};
                for (let item of list){
                    if(item.id === 'iconSetting'){
                        res[item.id] = {
                            icon: item.value,
                            size: Number(item.num)
                        };
                        continue;
                    }

                    if(item.tag === 'Number'){
                        res[item.id] = Number(item.value);
                    }else{
                        res[item.id] = item.value;
                    }
                }

                return res;
            },
            getPointEditList(opt = {}){
                let res = [];

                for (let item of this.pointEditList){
                    if(opt[item.id] === undefined) continue;

                    let temp = Object.assign(item, {
                        value: opt[item.id]
                    });

                    res.push(temp);
                }

                return res;
            },
            getMarkerEditList(opt = {}){
                let res = [];

                for (let item of this.markerEditList){
                    if(opt[item.id] === undefined) continue;

                    let temp;
                    if(item.id === 'iconSetting'){
                        temp = Object.assign(item, {
                            value: opt[item.id].icon,
                            num: opt[item.id].size
                        });
                    }else{
                        temp = Object.assign(item, {
                            value: opt[item.id]
                        });
                    }

                    res.push(temp);
                }

                return res;
            },
            getPlotEditList(type, opt = {}){
                let res = [];

                for (let item of this.plotEditList){
                    if(opt[item.id] === undefined) continue;

                    let temp;
                    if(item.id === 'fillType'){
                        temp = Object.assign(item, {
                            value: opt[item.id],
                            options: PlotUtils.getFillTypeByType(type)
                        });
                    }else{
                        temp = Object.assign(item, {
                            value: opt[item.id]
                        });
                    }

                    res.push(temp);
                }

                return res;
            },

            setActiveEntity(type, data){
                this.isEditing = true;
                this.editList = [];

                switch (type) {
                    case 'point':
                        this.editList = this.getPointEditList(data.options);
                        this.editObject = data;
                        break;
                    case 'marker':
                        this.editList = this.getMarkerEditList(data.options);
                        this.editObject = data;
                        break;
                    case 'plot':
                        this.editList = this.getPlotEditList(data.plotType, data.options);
                        this.editObject = data;
                        break;
                }
            },
            setEmptyEntity(){
                this.isEditing = false;
                this.editList = [];

                this.currentTemplet = '';
                this.currentModule = '';
            },

            onClickBtn(row){
                if(this.isEditing){
                    return this.$message.error(`编辑中，尚未保存数据！`);
                }

                this.currentBtn = row.id;
            },
            onClickPoint(row){
                if(this.currentTemplet === row.id){
                    this.currentTemplet = '';
                    this.currentModule = '';
                    this.$emit('cancelEntity');
                }else{
                    this.currentTemplet = row.id;
                    this.currentModule = 'point';
                    this.$emit('clickPoint', this.currentTemplet, {
                        imageType: 'Billboard',
                        pointType: this.currentTemplet,
                        options: Object.assign(this.pointOptions, {
                            iconType: `${this.currentTemplet}_${this.pointOptions.iconColor}`
                        })
                    });
                }
            },
            onClickMarker(row){
                if(this.currentTemplet === row.id){
                    this.currentTemplet = '';
                    this.currentModule = '';
                    this.$emit('cancelEntity');
                }else{
                    this.currentTemplet = row.id;
                    this.currentModule = 'marker';

                    let parent = markerUtils.getMarkerModule(this.currentTemplet);
                    let imageType = markerUtils.getImageType(this.currentTemplet);
                    let options = markerUtils.getMarkerOptByType(parent, this.currentTemplet);
                    let data = {
                        imageType: imageType,
                        markerType: this.currentTemplet,
                        options: options
                    };
                    if(imageType === 'canvas'){
                        let canvas = markerUtils.getMarkerUrlByType(parent, this.currentTemplet, options);
                        data.canvas = canvas;
                    }

                    this.$emit('clickMarker', this.currentTemplet, data);
                }
            },
            onClickPlot(row){
                if(this.currentTemplet === row.id){
                    this.currentTemplet = '';
                    this.currentModule = '';
                    this.$emit('cancelEntity');
                }else{
                    this.currentTemplet = row.id;
                    this.currentModule = 'plot';
                    this.$emit('clickPlot', this.currentTemplet, row.tag);
                }
            },

            onEditBtn(type){
                if(type === 'cancel'){
                    this.$emit('editCancel', this.currentModule);
                    return;
                }

                let options = this.getEditOptions(this.editList);
                if(this.editObject.imageType === 'canvas'){
                    let parent = markerUtils.getMarkerModule(this.editObject.markerType);
                    let canvas = markerUtils.getMarkerUrlByType(parent, this.editObject.markerType, options);
                    this.editObject.canvas = canvas;
                }
                if(!this.editObject.options) this.editObject.options = {};
                this.editObject.options = Object.assign(this.editObject.options, options);
                if(this.editObject.module === 'point'){
                    this.editObject.options.iconType = `${this.editObject.pointType}_${this.editObject.options.iconColor}`
                }

                switch (type) {
                    case 'apply':
                        this.$emit('editApply', this.currentModule, this.editObject);
                        break;
                    case 'save':
                        this.$emit('editSave', this.currentModule, this.editObject);
                        break;
                }
            }
        }
    }
</script>

<style scoped>
    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .vertical-flex {
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .title-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .title-icon{
        float: left;
        padding-left: 10px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        fill: currentColor;
    }

    .title-span {
        float: left;
        padding-left: 5px;
        font-size: 14px;
        color: inherit;
    }

    .btn-wrap{
        padding-bottom: 10px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        display: flex;
        flex-wrap: wrap;
    }

    .btn-item{
        margin-top: 10px;
        margin-left: 15px;
        padding-top: 15px;
        width: 80px;
        height: 65px;
        line-height: 25px;
        border-radius: 5px;
    }

    .btnItem-active{
        color: var(--el-color-primary);
    }

    .btnItem-icon{
        text-align: center;
        font-size: 20px;
        color: inherit;
    }

    .btnItem-content{
        color: inherit;
        font-size: 14px;
    }

    .content-wrap{
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .contentWrap-title{
        float: left;
        margin-left: 15px;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 12px;
        opacity: 70%;
    }

    .contentWrap-span{
        float: left;
        margin-top: 10px;
        margin-left: 15px;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 12px;
    }

    .contentWrap-item{
        margin-left: 15px;
        display: flex;
        flex-wrap: wrap;
    }

    .contentWrap-select{
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 35px);
    }

    .contentItem-img{
        width: 85px;
        height: 85px;
        margin-right: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    .contentItem-img-active{
        border: 2px solid var(--el-color-primary);
    }

    .contentItem-img:hover{
        border: 2px solid var(--el-color-primary);
    }

    .contentItem-span{
        float: left;
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 14px;
    }

    .editing-wrap{
        width: 100%;
        /*height: calc(100% - 120px);*/
        overflow-y: auto;
    }

    .editing-apply{
        float: left;
        margin-top: 15px;
        margin-left: 15px;
        width: calc(100% - 30px);
    }

    .editing-bottom{
        float: left;
        display: flex;
        margin-top: 10px;
        margin-left: 15px;
        margin-bottom: 10px;
        width: calc(100% - 30px);
    }

    .editing-btn{
        flex: 1;
    }
</style>
