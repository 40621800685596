/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-13 19:13:17
 * @FilePath: \dataview-viewer-test\src\custom-component\flow\lang\zh.js
 */

export default {
  "src.FlowDesigner.822470-0": "全局配置",
  "src.FlowDesigner.822470-1": "保存设计",
  "src.FlowDesigner.822470-2": "模板库",
  "src.FlowDesigner.822470-3": "流程组件属性配置",
  "src.FlowDesigner.822470-4": "标准流程模板库",
  "src.FlowDesigner.822470-5": "流程模板快照",
  "src.FlowDesigner.822470-6": "保存模板",
  "src.FlowDesigner.822470-7": "使用模板",
  "src.FlowDesigner.822470-8": "流程基本配置",
  "src.FlowDesigner.822470-9": "流程名称",
  "src.FlowDesigner.822470-10": "流程描述",
  "src.FlowDesigner.822470-11": "数据对象",
  "src.FlowDesigner.822470-12": "表单对象",
  "src.FlowDesigner.822470-13": "默认",
  "src.FlowDesigner.822470-14": "取 消",
  "src.FlowDesigner.822470-15": "确 定",
  "src.FlowDesigner.822470-16": "基础组件",
  "src.FlowDesigner.822470-17": "开始节点",
  "src.FlowDesigner.822470-18": "审批节点",
  "src.FlowDesigner.822470-19": "抄送节点",
  "src.FlowDesigner.822470-20": "并行网关",
  "src.FlowDesigner.822470-21": "结束节点",
  "src.FlowDesigner.822470-22": "高级组件",
  "src.FlowDesigner.822470-23": "子流程",
  "src.FlowDesigner.822470-24": "系统任务",
  "src.FlowDesigner.822470-25": "请输入流程名称",
  "src.FlowDesigner.822470-26": "流程模板不存在",
  "src.FlowDesigner.822470-27": "请输入模板名称",
  "src.FlowDesigner.822470-28": "正在保存流程模板",
  "src.FlowDesigner.822470-29": "确定",
  "src.FlowDesigner.822470-30": "取消",
  "src.FlowDesigner.822470-31": "流程模板名称必须",
  "src.FlowDesigner.822470-32": "【并行网关】必须成对出现",
  "src.FlowDesigner.822470-33": "【并行网关】必须单入多出或多入单出",
  "src.FlowDesigner.822470-34": "审批节点：[ {0} ] 责任人不能为空，请选择责任人",
  "src.FlowDesigner.822470-35": "审批节点：[ {0} ] 部门不能为空，请选择部门",
  "src.FlowDesigner.822470-36": "审批节点：[ {0} ] 岗位不能为空，请选择岗位",
  "src.FlowDesigner.822470-37": "开始或结束节点缺失，请检查后保存！",
  "src.FlowDesigner.822470-38": "流程节点连线未连接完整，请检查后保存！",
  "src.FlowDesigner.822470-39": "保存流程成功!",
  "src.FlowDesigner.822470-40": "流程配置加载中",
  "src.FlowDesigner.822470-41": "流程节点自定义审批内容",
  "src.FlowDesigner.822470-42": "开始节点连线缺失，请检查后保存！",
  "src.FlowDesigner.822470-43": "结束节点连线缺失，请检查后保存！",
  "src.FlowDesigner.822470-44": "节点不允许自己连自己，请检查后保存！",
  "src.FlowDesigner.822470-45": "待办模板",
  "src.FlowDesigner.822470-46": "抄送模板",
  "scripts.X6GraphInit.733994-0": "开始",
  "scripts.X6GraphInit.733994-1": "审批节点",
  "scripts.X6GraphInit.733994-2": "抄送节点",
  "scripts.X6GraphInit.733994-3": "结束",
  "scripts.X6GraphInit.733994-4": "系统任务",
  "scripts.X6GraphInit.733994-5": "子流程",
  "scripts.X6GraphInit.733994-6": "退回",
  "scripts.X6GraphInit.733994-7": "通过",
  "scripts.X6GraphInit.733994-8": "转交",
  "scripts.X6GraphInit.733994-9": "重新发起",
  "scripts.X6GraphInit.733994-10": "提交至退回人",
  "cell-panel.StepProp.033670-0": "基本设置",
  "cell-panel.StepProp.033670-1": "步骤名称",
  "cell-panel.StepProp.033670-2": "分支选择",
  "cell-panel.StepProp.033670-3": "开启转交",
  "cell-panel.StepProp.033670-4": "审批人员",
  "cell-panel.StepProp.033670-5": "指定人员",
  "cell-panel.StepProp.033670-6": "发起人自选",
  "cell-panel.StepProp.033670-7": "部门人员",
  "cell-panel.StepProp.033670-8": "岗位人员",
  "cell-panel.StepProp.033670-9": "流程中指定",
  "cell-panel.StepProp.033670-10": "发起人审核",
  "cell-panel.StepProp.033670-11": "空步骤",
  "cell-panel.StepProp.033670-12": "选择范围",
  "cell-panel.StepProp.033670-13": "本级架构",
  "cell-panel.StepProp.033670-14": "上级组织",
  "cell-panel.StepProp.033670-15": "人员选择",
  "cell-panel.StepProp.033670-16": "部门选择",
  "cell-panel.StepProp.033670-17": "岗位选择",
  "cell-panel.StepProp.033670-18": "请选择",
  "cell-panel.StepProp.033670-19": "人员范围",
  "cell-panel.StepProp.033670-20": "开启：已选择人员作为选择范围",
  "cell-panel.StepProp.033670-21": "表单控制",
  "cell-panel.StepProp.033670-22": "可编辑",
  "cell-panel.StepProp.033670-23": "仅可见",
  "cell-panel.StepProp.033670-24": "隐藏",
  "cell-panel.StepProp.033670-25": "会签或签",
  "cell-panel.StepProp.033670-26": "或签（任意一人通过）",
  "cell-panel.StepProp.033670-27": "会签（全部人员通过）",
  "cell-panel.StepProp.033670-28": "会签比例",
  "cell-panel.StepProp.033670-29": "全部通过",
  "cell-panel.StepProp.033670-30": "过半通过",
  "cell-panel.StepProp.033670-31": "按钮设置",
  "cell-panel.StepProp.033670-32": "按钮操作",
  "cell-panel.StepProp.033670-33": "退回",
  "cell-panel.StepProp.033670-34": "通过",
  "cell-panel.StepProp.033670-35": "转交",
  "cell-panel.StepProp.033670-36": "拒绝",
  "cell-panel.StepProp.033670-37": "作废",
  "cell-panel.StepProp.033670-38": "催办",
  "cell-panel.StepProp.033670-39": "重新发起",
  "cell-panel.StepProp.033670-40": "提交至退回人",
  "cell-panel.StepProp.033670-41": "按钮文字",
  "cell-panel.StepProp.033670-42": "显示隐藏",
  "cell-panel.StepProp.033670-43": "显示",
  "cell-panel.StepProp.033670-44": "添加按钮",
  "cell-panel.StepProp.033670-45": "审批意见",
  "cell-panel.StepProp.033670-46": "附件显示",
  "cell-panel.StepProp.033670-47": "附件必填",
  "cell-panel.StepProp.033670-48": "意见显示",
  "cell-panel.StepProp.033670-49": "意见必填",
  "cell-panel.StepProp.033670-50": "控件名称",
  "cell-panel.StepProp.033670-51": "控件类型",
  "cell-panel.StepProp.033670-52": "审批意见扩展表单控件配置",
  "cell-panel.StepProp.033670-53": "审批节点",
  "cell-panel.SendProp.975649-0": "基本设置",
  "cell-panel.SendProp.975649-1": "步骤名称",
  "cell-panel.SendProp.975649-2": "抄送人员",
  "cell-panel.SendProp.975649-3": "指定人员",
  "cell-panel.SendProp.975649-4": "发起人自选",
  "cell-panel.SendProp.975649-5": "部门人员",
  "cell-panel.SendProp.975649-6": "岗位人员",
  "cell-panel.SendProp.975649-7": "流程中指定",
  "cell-panel.SendProp.975649-8": "抄送发起人",
  "cell-panel.SendProp.975649-9": "选择范围",
  "cell-panel.SendProp.975649-10": "本级架构",
  "cell-panel.SendProp.975649-11": "上级公司",
  "cell-panel.SendProp.975649-12": "人员选择",
  "cell-panel.SendProp.975649-13": "部门选择",
  "cell-panel.SendProp.975649-14": "岗位选择",
  "cell-panel.SendProp.975649-15": "请选择",
  "cell-panel.SendProp.975649-16": "抄送节点",
  "cell-panel.SubFlowProp.143764-0": "基本设置",
  "cell-panel.SubFlowProp.143764-1": "子流程名称",
  "cell-panel.SubFlowProp.143764-2": "流程发起组织子流程跳过执行",
  "cell-panel.SubFlowProp.143764-3": "子流程设置",
  "cell-panel.SubFlowProp.143764-4": "子流程",
  "cell-panel.EdgeProp.382481-0": "基本设置",
  "cell-panel.EdgeProp.382481-1": "分支名称",
  "cell-panel.EdgeProp.382481-2": "优先级",
  "cell-panel.EdgeProp.382481-3": "低（P1）",
  "cell-panel.EdgeProp.382481-4": "中（P2）",
  "cell-panel.EdgeProp.382481-5": "高（P3）",
  "cell-panel.EdgeProp.382481-6": "条件设置",
  "cell-panel.EdgeProp.382481-7": "逻辑",
  "cell-panel.EdgeProp.382481-8": "与",
  "cell-panel.EdgeProp.382481-9": "或",
  "cell-panel.EdgeProp.382481-10": "规则类型",
  "cell-panel.EdgeProp.382481-11": "人员条件",
  "cell-panel.EdgeProp.382481-12": "表单条件",
  "cell-panel.EdgeProp.382481-13": "人员选择",
  "cell-panel.EdgeProp.382481-14": "表单控件",
  "cell-panel.EdgeProp.382481-15": "请选择",
  "cell-panel.EdgeProp.382481-16": "判断符",
  "cell-panel.EdgeProp.382481-17": "取值",
  "cell-panel.EdgeProp.382481-18": "添加新条件",
  "cell-panel.EdgeProp.382481-19": "大于",
  "cell-panel.EdgeProp.382481-20": "小于",
  "cell-panel.EdgeProp.382481-21": "等于",
  "cell-panel.EdgeProp.382481-22": "完全等于",
  "cell-panel.EdgeProp.382481-23": "不等于",
  "cell-panel.EdgeProp.382481-24": "大于等于",
  "cell-panel.EdgeProp.382481-25": "小于等于",
  "cell-panel.EdgeProp.382481-26": "包含任意",
  "cell-panel.EdgeProp.382481-27": "不包含",
  "cell-panel.EdgeProp.382481-28": "介于之间",
  "operation.FlowOperate.468394-0": "收起审批",
  "operation.FlowOperate.468394-1": "审批",
  "operation.FlowOperate.468394-2": "提交",
  "operation.FlowOperate.468394-3": "审批意见",
  "operation.FlowOperate.468394-4": "请输入审批意见",
  "operation.FlowOperate.468394-5": "审批附件",
  "operation.FlowOperate.468394-6": "选择退回类型",
  "operation.FlowOperate.468394-7": "退回发起人",
  "operation.FlowOperate.468394-8": "退回上一步",
  "operation.FlowOperate.468394-9": "指定步骤退回",
  "operation.FlowOperate.468394-10": "步骤选择",
  "operation.FlowOperate.468394-11": "请选择退回的步骤",
  "operation.FlowOperate.468394-12": "确认{0}",
  "operation.FlowOperate.468394-13": "审批意见不能为空",
  "operation.FlowOperate.468394-14": "表单验证失败",
  "operation.FlowOperate.468394-15": "审批意见必填项未填报完整",
  "operation.FlowOperate.468394-16": "审批人未选择完整！",
  "operation.FlowOperate.468394-17": "数据验证失败，请核对必填字段数据",
  "operation.FlowOperate.468394-18": "审批附件不能为空",
  "operation.FlowPicker.605015-0": "拼命加载中",
  "operation.FlowPicker.605015-1": "选择流转分支",
  "operation.FlowPicker.605015-2": "流转分支",
  "operation.FlowPicker.605015-3": "请选择流转分支",
  "operation.FlowPicker.605015-4": "结束",
  "operation.FlowPicker.605015-5": "选择转交人",
  "operation.FlowPicker.605015-6": "请选择转交人",
  "operation.FlowPicker.605015-7": "选择审批人",
  "operation.FlowPicker.605015-8": "选择抄送人",
  "operation.FlowPicker.605015-9": "流程无需选人",
  "operation.FlowRecover.668174-0": "流程审批记录",
  "operation.FlowRecover.668174-1": "关闭",
  "operation.FlowRecover.668174-2": "查看",
  "operation.FlowRecover.668174-3": "流程图",
  "operation.FlowRecover.668174-4": "子流程触发",
  "operation.FlowRecover.668174-5": "抄送成功",
  "operation.FlowRecover.668174-6": "空步骤",
  "operation.FlowRecover.668174-7": "重新发起",
  "operation.FlowRecover.668174-8": "发起",
  "operation.FlowRecover.668174-9": "结束",
  "operation.FlowRecover.668174-10": "通过",
  "operation.FlowRecover.668174-11": "已转交",
  "operation.FlowRecover.668174-12": "拒绝",
  "operation.FlowRecover.668174-13": "作废",
  "operation.FlowRecover.668174-14": "已处理",
  "operation.FlowRecover.668174-15": "待处理",
  "operation.FlowRecover.668174-16": "待审批",
  "operation.FlowRecover.668174-17": "退回",
  "operation.FlowRecover.668174-18": "未读",
  "operation.FlowRecover.668174-19": "已读",
  "operation.FlowRecover.668174-20": "或签",
  "operation.FlowRecover.668174-21": "会签",
  "operation.FlowRecover.668174-22": "开始",
  "operation.FlowRecover.668174-23": "月$",
  "operation.FlowRecover.668174-24": "日 $"
}


