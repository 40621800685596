<!--
 * @Description: 重命名
 * @Author: luocheng
 * @Date: 2022-03-02 15:50:16
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-05-13 14:21:57
-->
<template>
  <div class="rename" v-if="value">
    <el-dialog
      :title="title"
      :visible.sync="value"
      :show-close="false"
      :before-close="handleClose"
      append-to-body
      width="400px">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item :label="$t('components.LinkModel.193459-0')" prop="name">
          <el-input v-model="form.name" :placeholder="$t('components.LinkModel.193459-1')" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('components.LinkModel.193459-2')" prop="url">
          <el-input v-model="form.url" :placeholder="$t('components.LinkModel.193459-8')" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">{{ $t('components.LinkModel.193459-3') }}</el-button>
        <el-button type="primary" @click="onConfirm">{{ $t('components.LinkModel.193459-4') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Rename',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    inputData: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      required: false,
      default: 'create'
    }
  },
  data() {
    const validateEmpty = (rules, value, callback) => {
      if(!value || !value.trim()) {
        callback(new Error(this.$t('components.LinkModel.193459-5')))
      } else {
        callback();
      }
    }
    return {
      form: {
        name: '',
        url: ''
      },
      rules: {
        name: [
          // { required: true, message: this.$t('components.LinkModel.193459-1'), trigger: 'blur' },
          { required: true, validator: validateEmpty, message: this.$t('components.LinkModel.193459-1'), trigger: 'blur' }
        ],
        url: [
          // { required: true, message: '请输入链接', trigger: 'blur' },
          {  required: true, validator: validateEmpty, message: this.$t('components.LinkModel.193459-1'), trigger: 'blur' }
        ],
      }
    }
  },
  watch: {
    value: {
      handler() {
        this.form.name = this.inputData.name;
        this.form.url = this.inputData.url || this.inputData.path;
      }
    }
  },
  computed: {
    title() {
      return this.type === 'create' ? this.$t('components.LinkModel.193459-6') : this.$t('components.LinkModel.193459-7');
    }
  },
  created() {
    this.form.name = this.inputData.name;
    this.form.url = this.inputData.url || this.inputData.path;
  },
  methods: {
    handleClose(done) {
      this.onClose();
      done();
    },
    onClose() {
      this.name = '';
      this.$emit('input', false)
    },
    onConfirm() {
      this.$refs.form.validate((valid) => {
        if(valid) {
          // 兼容 path 及 url
          this.form.path = this.form.url
          this.$emit('submit', this.form);
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.rename{
  :deep(.el-dialog){
    .el-dialog__header{
      text-align: left;
      border-bottom: 1px solid #f5f5f5;
      .el-dialog__title{
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
</style>