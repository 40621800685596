<template>
    <div class="moduleWrap vertical-flex">
        <div class="title-wrap">
            <img class="title-icon" src="../background/scene.png">
            <span class="title-span">场景效果</span>
        </div>

        <div class="btn-wrap">
            <div v-for="item in btnList" :key="item.id" @click="onClickBtn(item)"
                 :class="['btn-item vertical-flex', currentBtn === item.id ? 'btnItem-active' : '']">
                <a :class="['btnItem-icon iconfont', item.icon]"></a>
                <span class="btnItem-content">{{item.name}}</span>
            </div>
        </div>

        <div class="content-wrap vertical-flex" v-if="currentBtn === 'environment'">
            <span class="contentWrap-title">天气环境</span>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">太阳阴影</span>
                <el-switch v-model="shadows" size="small" class="contentItem-end contentItem-switch" @change="onChangeEnvironment('shadows')"></el-switch>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">太阳强度</span>
                <el-slider v-model="sunIntensity" :min="0" :max="10" :step="0.1" class="contentItem-slider" @change="onChangeEnvironment('sunIntensity')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">月亮</span>
                <el-switch v-model="moon" size="small" class="contentItem-end contentItem-switch" @change="onChangeEnvironment('moon')"></el-switch>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">大气层</span>
                <el-switch v-model="groundAtmosphere" size="small" class="contentItem-end contentItem-switch" @change="onChangeEnvironment('groundAtmosphere')"></el-switch>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">天空</span>
                <el-switch v-model="skyAtmosphere" size="small" class="contentItem-end contentItem-switch" @change="onChangeEnvironment('skyAtmosphere')"></el-switch>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">星空</span>
                <el-switch v-model="skyBox" size="small" class="contentItem-end contentItem-switch" @change="onChangeEnvironment('skyBox')"></el-switch>
            </div>

            <el-button type="primary" class="contentWrap-button" @click="onSaveBtn('environment')">保存</el-button>
        </div>

        <div class="content-wrap vertical-flex" v-if="currentBtn === 'effection'">
            <span class="contentWrap-title">效果调整</span>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">地形深度检测</span>
                <el-switch v-model="depthTest" size="small" class="contentItem-end contentItem-switch" @change="onChangeEffection('depthTest')"></el-switch>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">相机碰撞检测</span>
                <el-switch v-model="collisionDetection" size="small" class="contentItem-end contentItem-switch" @change="onChangeEffection('collisionDetection')"></el-switch>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">抗锯齿FXAA</span>
                <el-switch v-model="fxaa" size="small" class="contentItem-end contentItem-switch" @change="onChangeEffection('fxaa')"></el-switch>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">抗锯齿MSAA</span>
                <el-select class="contentItem-end contentItem-select" v-model="msaa" size="small" @change="onChangeEffection('msaa')">
                    <el-option label="8x" value="8"/>
                    <el-option label="4x" value="4"/>
                    <el-option label="2x" value="2"/>
                    <el-option label="off" value="off"/>
                </el-select>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">环境光遮蔽AO</span>
                <div class="contentItem-end">
                    <a :class="['contentItem-expand iconfont', ambientOcclusionExpand ? 'iconpage-arrow1-16' : 'iconpage-arrow2-16' ]"
                       @click="onClickOptions('ambientOcclusion')"></a>
                    <el-switch v-model="ambientOcclusion" size="small" class="contentItem-switch" @change="onChangeEffection('ambientOcclusion')"></el-switch>
                </div>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="ambientOcclusionExpand">
                <span class="contentItem-content">强度</span>
                <el-slider v-model="ambientOcclusionOptions.intensity" :min="0" :max="10" :step="0.1" class="contentItem-slider" @change="onChangeEffection('ambientOcclusionOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="ambientOcclusionExpand">
                <span class="contentItem-content">偏移量</span>
                <el-slider v-model="ambientOcclusionOptions.bias" :min="0" :max="1" :step="0.01" class="contentItem-slider" @change="onChangeEffection('ambientOcclusionOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="ambientOcclusionExpand">
                <span class="contentItem-content">长度上限</span>
                <el-slider v-model="ambientOcclusionOptions.lengthCap" :min="0" :max="1" :step="0.01" class="contentItem-slider" @change="onChangeEffection('ambientOcclusionOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="ambientOcclusionExpand">
                <span class="contentItem-content">步长</span>
                <el-slider v-model="ambientOcclusionOptions.stepSize" :min="0" :max="5" :step="0.1" class="contentItem-slider" @change="onChangeEffection('ambientOcclusionOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="ambientOcclusionExpand">
                <span class="contentItem-content">模糊步长</span>
                <el-slider v-model="ambientOcclusionOptions.blurStepSize" :min="0" :max="2" :step="0.1" class="contentItem-slider" @change="onChangeEffection('ambientOcclusionOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex">
                <span class="contentItem-content">泛光</span>
                <div class="contentItem-end">
                    <a :class="['contentItem-expand iconfont', bloomExpand ? 'iconpage-arrow1-16' : 'iconpage-arrow2-16' ]"
                       @click="onClickOptions('bloom')"></a>
                    <el-switch v-model="bloom" size="small" class="contentItem-switch" @change="onChangeEffection('bloom')"></el-switch>
                </div>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="bloomExpand">
                <span class="contentItem-content">对比度</span>
                <el-slider v-model="bloomOptions.contrast" :min="0" :max="255" :step="1" class="contentItem-slider" @change="onChangeEffection('bloomOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="bloomExpand">
                <span class="contentItem-content">亮度</span>
                <el-slider v-model="bloomOptions.brightness" :min="-5" :max="5" :step="0.1" class="contentItem-slider" @change="onChangeEffection('bloomOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="bloomExpand">
                <span class="contentItem-content">增量</span>
                <el-slider v-model="bloomOptions.delta" :min="0" :max="5" :step="0.1" class="contentItem-slider" @change="onChangeEffection('bloomOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="bloomExpand">
                <span class="contentItem-content">sigma</span>
                <el-slider v-model="bloomOptions.sigma" :min="0" :max="5" :step="0.1" class="contentItem-slider" @change="onChangeEffection('bloomOptions')"></el-slider>
            </div>

            <div class="contentWrap-item horizontal-flex" v-if="bloomExpand">
                <span class="contentItem-content">步长</span>
                <el-slider v-model="bloomOptions.stepSize" :min="0" :max="10" :step="0.1" class="contentItem-slider" @change="onChangeEffection('bloomOptions')"></el-slider>
            </div>

            <el-button type="primary" class="contentWrap-button" @click="onSaveBtn('effection')">保存</el-button>
        </div>
    </div>
</template>

<script>
    import {Switch, Slider, Select, Option, Button} from 'element-ui';
    import eventBus from '@/plugins/eventBus';

    export default {
        name: "SceneEditor",
        components: {
            'el-switch': Switch,
            'el-slider': Slider,
            'el-select': Select,
            'el-option': Option,
            'el-button': Button
        },
        data(){
            return {
                btnList: [
                    {id: 'environment', name: '天气环境', icon: 'icontianqi-yu'},
                    {id: 'effection', name: '效果调整', icon: 'iconjinduguanli1'},
                    {id: 'templet', name: '场景模板', icon: 'iconpingpu'},
                ],
                currentBtn: 'environment',

                shadows: false,
                sunIntensity: 2,
                moon: false,
                groundAtmosphere: false,
                skyAtmosphere: false,
                skyBox: false,

                depthTest: false,
                collisionDetection: false,
                fxaa: false,
                msaa: '4',
                ambientOcclusion: false,
                ambientOcclusionExpand: false,
                ambientOcclusionOptions: {
                    intensity: 3.0,
                    bias: 0.1,
                    lengthCap: 0.03,
                    stepSize: 1,
                    blurStepSize: 0.86
                },
                bloom: false,
                bloomExpand: false,
                bloomOptions: {
                    contrast: 128,
                    brightness: -0.3,
                    delta: 1,
                    sigma: 3.8,
                    stepSize: 5
                },
            }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            bindViewerId: {
                type: String,
            },
            sceneSetting: {
                type: Object
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mounted(){
            if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && this.bindViewerId){
                eventBus.$emit("ViewerGIS.getViewerInitedFlag", this.bindViewerId, ({viewerInited = false}) => {
                    if(viewerInited){
                        this.initSceneData();
                    }else{
                        this.initViewerInitedEventListener();
                    }
                });
            }else{
                this.initViewerInitedEventListener();
            }
        },
        methods: {
            getEnvironmentOptions(){
                return {
                    shadows: this.shadows,
                    sunIntensity: Number(this.sunIntensity),
                    moon: this.moon,
                    groundAtmosphere: this.groundAtmosphere,
                    skyAtmosphere: this.skyAtmosphere,
                    skyBox: this.skyBox,
                }
            },
            getEffectionOptions(){
                return {
                    depthTest: this.depthTest,
                    collisionDetection: this.collisionDetection,
                    fxaa: this.collisionDetection,
                    msaa: this.msaa,
                    ambientOcclusion: this.ambientOcclusion,
                    ambientOcclusionOptions: {
                        intensity: Number(this.ambientOcclusionOptions.intensity),
                        bias: Number(this.ambientOcclusionOptions.bias),
                        lengthCap: Number(this.ambientOcclusionOptions.lengthCap),
                        stepSize: Number(this.ambientOcclusionOptions.stepSize),
                        blurStepSize: Number(this.ambientOcclusionOptions.blurStepSize),
                    },
                    bloom: this.bloom,
                    bloomOptions: {
                        contrast: Number(this.bloomOptions.contrast),
                        brightness: Number(this.bloomOptions.brightness),
                        delta: Number(this.bloomOptions.delta),
                        sigma: Number(this.bloomOptions.sigma),
                        stepSize: Number(this.bloomOptions.stepSize),
                    },
                }
            },

            initViewerInitedEventListener(){
                eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
                    if (eid !== this.bindViewerId) return;

                    let {viewerInited = false} = opt;
                    if(viewerInited){
                        this.initSceneData();
                    }
                });
            },
            initSceneData(){
                eventBus.$emit("ViewerGIS.getSceneSetting", this.bindViewerId, (setting) => {
                    this.initEnvironmentData(setting);
                    this.initEffectionData(setting);
                });
            },
            initEnvironmentData(setting){
                this.shadows = setting.shadows;
                this.sunIntensity = setting.sunIntensity;
                this.moon = setting.moon;
                this.groundAtmosphere = setting.groundAtmosphere;
                this.skyAtmosphere = setting.skyAtmosphere;
                this.skyBox = setting.skyBox;
            },
            initEffectionData(setting){
                this.depthTest = setting.depthTest;
                this.collisionDetection = setting.collisionDetection;
                this.fxaa = setting.fxaa;
                this.msaa = setting.msaa;
                this.ambientOcclusion = setting.ambientOcclusion;
                this.ambientOcclusionOptions = {
                    intensity: setting.ambientOcclusionOptions.intensity,
                    bias: setting.ambientOcclusionOptions.bias,
                    lengthCap: setting.ambientOcclusionOptions.lengthCap,
                    stepSize: setting.ambientOcclusionOptions.stepSize,
                    blurStepSize: setting.ambientOcclusionOptions.blurStepSize
                };
                this.bloom = setting.bloom;
                this.bloomOptions = {
                    contrast: setting.bloomOptions.contrast,
                    brightness: setting.bloomOptions.brightness,
                    delta: setting.bloomOptions.delta,
                    sigma: setting.bloomOptions.sigma,
                    stepSize: setting.bloomOptions.stepSize
                };
            },

            onClickBtn(row){
                if(row.id === 'templet'){
                    return this.$message.warning('功能开发中');
                }
                this.currentBtn = row.id;
            },
            onClickOptions(type){
                switch (type) {
                    case 'ambientOcclusion':
                        this.ambientOcclusionExpand = !this.ambientOcclusionExpand;
                        break;
                    case 'bloom':
                        this.bloomExpand = !this.bloomExpand;
                        break;
                }
            },
            onChangeEnvironment(type){
                this.$emit('clickEnvironment', type, {value: this[type]});
            },
            onChangeEffection(type){
                if(type === 'ambientOcclusion' || type === 'ambientOcclusionOptions'){
                    this.$emit('clickEffection', 'ambientOcclusion', {
                        isShow: this.ambientOcclusion,
                        value: this.ambientOcclusionOptions
                    });
                }else if(type === 'bloom' || type === 'bloomOptions'){
                    this.$emit('clickEffection', 'bloom', {
                        isShow: this.bloom,
                        value: this.bloomOptions
                    });
                }else{
                    this.$emit('clickEffection', type, {value: this[type]});
                }
            },
            onSaveBtn(type){
                switch (type) {
                    case 'environment':
                        this.$emit('saveEnvironment', this.getEnvironmentOptions());
                        break;
                    case 'effection':
                        this.$emit('saveEffection', this.getEffectionOptions());
                        break;
                }
            }
        }
    }
</script>

<style scoped>
    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .vertical-flex {
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .title-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .title-icon{
        float: left;
        padding-left: 10px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        fill: currentColor;
    }

    .title-span {
        float: left;
        padding-left: 5px;
        font-size: 14px;
        color: inherit;
    }

    .btn-wrap{
        padding-bottom: 10px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        display: flex;
        flex-wrap: wrap;
    }

    .btn-item{
        margin-top: 10px;
        margin-left: 15px;
        padding-top: 15px;
        width: 80px;
        height: 65px;
        line-height: 25px;
        border-radius: 5px;
    }

    .btnItem-active{
        color: var(--el-color-primary);
    }

    .btnItem-icon{
        text-align: center;
        font-size: 20px;
        color: inherit;
    }

    .btnItem-content{
        color: inherit;
        font-size: 14px;
    }

    .content-wrap{
        width: 100%;
        color: inherit;
        overflow: hidden;
    }

    .contentWrap-title{
        margin-left: 15px;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 12px;
        opacity: 70%;
    }

    .contentWrap-item{
        display: flex;
        width: 100%;
        height: 38px;
        line-height: 38px;
    }

    .contentWrap-button{
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .contentItem-content{
        display: flex;
        justify-content: flex-start;
        margin-left: 15px;
        color: inherit;
        font-size: 14px;
    }

    .contentItem-end{
        display: flex;
        flex: 1;
        justify-content: flex-end;
        margin-right: 15px;
    }

    .contentItem-expand{
        margin-right: 5px;
        font-size: 18px;
    }

    .contentItem-expand:hover{
        color: var(--el-color-primary);
    }

    .contentItem-switch{
        height: 38px;
        line-height: 38px;
        vertical-align: middle;
    }

    .contentItem-select{
        margin-left: 15px;
    }

    .contentItem-slider{
        display: flex;
        flex: 1;
        justify-content: flex-end;
        margin-left: 15px;
        margin-right: 15px;
    }
</style>
