<template>
    <div class="moduleWrap">
        <el-tree ref="kmlTree"
                 class="kmlTree-wrap"
                 empty-text="暂无数据"
                 :data="kmlList"
                 :props="treeProps"
                 node-key="id"
                 :expand-on-click-node="false"
                 :default-expand-all="true"
                 :highlight-current="true">
            <template #default="{ node, data }">
                <div :class="['horizontal-flex', 'tree-node']">
                    <a :class="['tree-node-icon iconfont', getTreeNodeIcon(data.type)]"></a>
                    <span class="tree-node-content">{{ data.name }}</span>
                    <a class="tree-node-location iconfont icondingwei1" @click="onLocation(data)"></a>
                    <a :class="['tree-node-btn iconfont', data.isShow ? 'iconbimgis_xianshi tree-node-btn-light' : 'iconbimgis_yincang']"
                       @click="onShow(data)"></a>
                </div>
            </template>
        </el-tree>
    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';
    import { Tree } from 'element-ui';

    const kmlUrlList = [
        {id: 'areaKml', name: '施工区域', url: 'https://models.bimcc.net/demo/褥垫层区.kml'},
        {id: 'lineKml', name: '标段展示', url: 'https://models.bimcc.net/demo/标段展示.kml'}
    ];

    export default {
        name: "ViewerGISKml",
        components: {
            'el-tree': Tree,
        },
        data() {
            return {
                bindViewerId: null,
                kmlList: [],
                treeProps: {
                    label: 'name',
                    children: 'children',
                    isLeaf: 'isLeaf'
                }
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted() {
            this.initEventListener();

            if (this.element.viewerBindId) {
                if (this.checkBind(this.element.viewerBindId)) {
                    this.bindViewerId = this.element.viewerBindId;
                }
            }
        },
        methods: {
            initEventListener() {
                eventBus.$on('ViewerGISKml.GetSource', (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== 'ViewerGIS') {
                                if (item.children && item.children.length > 0)
                                    loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.subComponentData, comps);
                    // loop(this.subComponentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId
                    });
                });

                eventBus.$on('ViewerGISKml.BindViewer', (eid, bindId) => {
                    if (eid !== this.element.id) return;
                    if (!this.checkBind(bindId)) return;

                    this.element.viewerBindId = bindId;
                    this.bindViewerId = bindId;
                    this.$message.success(`[${this.element.name}] 绑定成功`);

                    this.getTreeData();
                });

                eventBus.$on('ViewerGIS.onViewerInited', (eid) => {
                    if (eid !== this.bindViewerId) return;

                    this.getTreeData();
                });
            },
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp)
                    return this.$message.error(
                        `[${this.element.name}] 绑定失效，组件可能已经被删除`
                    );

                return hasComp;
            },
            getTreeData(bindId = this.bindViewerId){
                if(!bindId) return;

                eventBus.$emit('ViewerGIS.createKmlList', this.bindViewerId, kmlUrlList, (res) => {
                    this.kmlList = [];
                    for (let item of res){
                        let children = [];
                        if(item.entities.length > 0){
                            item.entities.map((obj) => {
                                children.push({
                                    id: obj.id,
                                    pId: item.id,
                                    name: obj.name,
                                    type: this.getEntityType(obj),
                                    isShow: true
                                });
                            })
                        }

                        this.kmlList.push({
                            id: item.id,
                            name: item.name,
                            type: 'file',
                            children: children,
                            isShow: true
                        })
                    }
                });
            },
            getEntityType(entity){
                if(entity.polyline) {
                    return 'polyline';
                }else if(entity.polygon){
                    return 'polygon';
                }else if(entity.label){
                    return 'label';
                }
            },
            getTreeNodeIcon(type){
                switch (type) {
                    case 'file':
                        return 'icona-wenjianjiadakai tree-node-icon-file';
                    case 'polyline':
                        return 'iconzhixian tree-node-icon-obj';
                    case 'polygon':
                        return 'iconwubianxing tree-node-icon-obj';
                    case 'label':
                        return 'iconwenbenbiaoqian tree-node-icon-obj';
                }
            },

            onLocation(item){
                if(item.type === 'file'){
                    eventBus.$emit('ViewerGIS.locationKml', this.bindViewerId, item.id);
                }else{
                    eventBus.$emit('ViewerGIS.locationKmlItem', this.bindViewerId, item.pId, [item.id]);
                }
            },
            onShow(item){
                if(item.type === 'file'){
                    eventBus.$emit('ViewerGIS.showKml', this.bindViewerId, item.id, !item.isShow);
                    item.isShow = !item.isShow;
                    item.children.map((obj) => {
                        obj.isShow = item.isShow;
                    });
                }else{
                    eventBus.$emit('ViewerGIS.showKmlItem', this.bindViewerId, item.pId, [item.id], !item.isShow);
                    item.isShow = !item.isShow;
                    let index = this.kmlList.findIndex(x => x.id === item.pId);
                    if(!item.isShow){
                        this.kmlList[index].isShow = false;
                    }else{
                        let temp = this.kmlList[index].children.findIndex(x => !x.isShow);
                        if(temp === -1) this.kmlList[index].isShow = true;
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .kmlTree-wrap {
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 10px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree-node__content>label.el-checkbox) {
            position: absolute !important;
            right: 0;
            margin-right: 13px !important;
            color: inherit;
        }

        :deep(.el-checkbox__inner) {
            background-color: transparent !important;
            border: none;
            height: 35px;
            color: inherit;
        }

        :deep(.el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e739";
            color: var(--themeColor);
            font-size: 16px;
            background-color: transparent !important;
            transform: none !important;
            /*transition: none !important;*/
        }

        :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner::before) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
            background-color: transparent !important;
        }
    }

    .tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 95%;
        height: 35px;
        line-height: 35px;
        padding-right: 10px;
    }

    .tree-node-icon {
        margin-right: 3px;
        margin-left: -5px;
        margin-top: 2px;
        width: 22px;
    }

    .tree-node-icon-file {
        color: #6DD3FD;
    }

    .tree-node-icon-obj {
        color: inherit;
    }

    .tree-node-content {
        text-align: left;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tree-node-location{
        width: 22px;
        margin-top: 2px;
        color: inherit;
    }

    .tree-node-location:hover{
        color: #1E90FF;
    }

    .tree-node-btn{
        width: 22px;
        margin-top: 2px;
        color: inherit;
    }

    .tree-node-btn-light{
        color: #1E90FF;
    }
</style>
