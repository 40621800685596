import { render, staticRenderFns } from "./CommonPlatformDynamics.vue?vue&type=template&id=402b750e&scoped=true"
import script from "./CommonPlatformDynamics.vue?vue&type=script&lang=js"
export * from "./CommonPlatformDynamics.vue?vue&type=script&lang=js"
import style0 from "./CommonPlatformDynamics.vue?vue&type=style&index=0&id=402b750e&prod&lang=less"
import style1 from "./CommonPlatformDynamics.vue?vue&type=style&index=1&id=402b750e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "402b750e",
  null
  
)

export default component.exports