<!--
 * @Description: BIM中控台
 * @Author: luocheng
 * @Date: 2022-02-23 14:17:16
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-24 09:44:52
-->
<template>
  <div class="bim-console" v-loading="loading"  element-loading-spinner="el-icon-loading" :element-loading-text="$t('appEntry.Index.905788-0')"
    :style="contentBoxStyle"
  >
    <iframe :src="baseUrl+'aboutModel/bimConsole'" id="consoleIframe" @load="loaded()" ref="bimConsole" frameborder="0"></iframe>
  </div>
</template>

<script>

import { getToken } from '@/utils/tools';
import { mapState } from 'vuex';
export default {
  name: 'BIMConsole',
	data() {
    return {
			token:this.$GetToken(),
			targetArchi:this.$GetTargetArchi(),
			userInfo:this.$GetUserInfo(),
			baseUrl: '',
      loading:true
    };
  },
	computed: {
		...mapState(['projectEnvironment', 'systemConfig']),
    // 内容样式对象
		contentBoxStyle() {
			const { theme_type = 1 } = this.systemConfig || {};
			if (theme_type === 2) {
				return {
					height: 'calc(100vh - 48px)',
          top: '48px'
				}
			}
			return {
				height: 'calc(100vh - 48px)',
        top: '48px'
			}
		},
	},
  methods: {
		loaded(){
      this.loading = false;
			let info = 	{
					type:'defaultConfig',
					token: getToken(),
					targetArchi:this.targetArchi,
					userInfo:this.userInfo,
					projectEnvironment:this.projectEnvironment
			}
			document.getElementById('consoleIframe').contentWindow.postMessage(info,'*');
			console.log('send',this.projectEnvironment)
		}
	},
  mounted() {
		if(process.env.NODE_ENV === 'development'){
			this.baseUrl = process.env.VUE_APP_V3_HOST
		} else {
			this.baseUrl = location.origin + '/'
		}
  }
}
</script>

<style lang="less" scoped>
.bim-console{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2888;
  iframe{
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}
</style>