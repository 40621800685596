<!--
 * @Description: 年选择器
 * @Author: luocheng
 * @Date: 2022-08-01 10:25:40
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-03-01 10:16:17
-->
<template>
  <div class="common-date calendar-year">
    <div class="content">
    
      <div class='calendar-header'>
         <!-- eslint-disable -->
        <span @click="changeYearList(0)"><<</span>
        <p class="title"> {{currenYearObj.year}} </p>
        <span @click="changeYearList(1)">>></span>
      </div>
    
      <ul class="date-list " v-if="showDateList && showDateList.length">
        <li class="date-year date-item month-item" v-for="(item, index) in showDateList" :key="index"
          :class="{
            'is-today': item.isTarget,
            'is-active': currenYearObj && currenYearObj.year === item.year
          }"
          @click="onYear(item)"
        >
          <!-- 基础类型 -->
          <div class="base-item" v-if="calendarType === 'base' || item.type !== 'target'">
            {{ item.isTarget ? '今年' : item.year }}
          </div>
          <!-- 符合类型 -->
          <div class="with-data-item" v-else-if="calendarType === 'withDatabase' || calendarType === 'justTotal'">
            <p class="date-text">
              {{ item.isTarget ? '今年' : item.year }}
            </p>
            <p class="count-text">
              <span class="finish-count"
                :class="{
                  'just-total': calendarType === 'justTotal',
                  'null-count': !(+item.finishCount)
                }"
              >{{ item.finishCount }}</span>
              <template v-if="calendarType === 'withDatabase'">
                <span class="separate" :class="{
                  'null-count': (!(+item.totalCount)) && (!(+item.finishCount))
                }">/</span>
                <span class="total-count" :class="{
                  'null-count': !(+item.totalCount)
                }">{{ item.totalCount }}</span>
              </template>
            </p>
          </div>
        </li>
      </ul>
      <el-empty v-else description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'CalendarYear',
  mixins: [mixin],
  data() {
    return {
      yearList: [],
      currenYearObj: null,
      lastYear:'',
      nowYear:''
    }
  },
  computed: {
    // startYear() {
    //   return this.statusConfig?.startYear || 2008;
    // },
    yearNum(){
      return this.statusConfig?.yearNum > 5 ? this.statusConfig?.yearNum :5;
    }
  },
  watch:{
    yearNum:{
      handler() {
        this.initDate(false)
      },
      immediate: true
    }
  },
  created() {
    this.initDate(true);
  },
  methods: {
    /**
     * @desc: 初始化
     * @param {Boolean} isInit
     */
    initDate(isInit) {
      this.yearList = [];
      const date = new Date();
      this.nowYear = date.getFullYear();
      if (isInit) {
        this.lastYear = this.nowYear - 3;
        const month = this.addZero(date.getMonth() + 1);
        this.currenYearObj = {
          year:this.nowYear,
          month,
          date: this.addZero(date.getDate()),
          isTarget: true,
          result: [`${this.nowYear}-01-01 00:00:00`, `${this.nowYear}-12-31 23:59:59`]
        };
        this.onYear(this.currenYearObj);
      }
      for (let i = this.lastYear; i < (this.lastYear + (+this.yearNum)); i++) {
        this.yearList.push({
          year: i,
          month: null,
          date: null,
          isTarget: +this.nowYear === i,
          result: [`${i}-01-01 00:00:00`, `${i}-12-31 23:59:59`]
        });
      }
    },
    
    /**
     * @desc:
     * @param {Number} type : 0:上一页 1 下一页
     */
    changeYearList(type){
      if(type){
        this.lastYear += (+this.yearNum)
      }else{
        this.lastYear -= this.yearNum
      }
      this.initDate(false)
    },
    
    /**
     * @desc: 年份
     * @param {Object} year
     */
    onYear(yearObj) {
      this.currenYearObj = yearObj;
      const {year} = yearObj;
      this.$emit('setDate', {date:yearObj.result,dateString:yearObj.year,year});
    }
  }
}
</script>

<style lang="less" scoped>
@import "./common.less";
</style>