import { axios } from "./http/request";

//表单渲染器
export const formRequest = (method, url, data = {}) => {
  if (method === "get" || method === "GET") {
    return axios.request({
      url: url,
      method: method,
      params: data,
    });
  } else {
    return axios.request({
      url: url,
      method: method,
      data: data,
    });
  }
};

// 待办
export const waitToDo = () => {
  return axios.request({
    url: "api/backlog/produce",
    method: "GET",
  });
};

// 提醒
export const remindInfo = () => {
  return axios.request({
    url: "api/backlog/remind",
    method: "GET",
  });
};

// 已读
export const isRead = (data) => {
  return axios.request({
    url: "api/msg/read",
    data,
    method: "POST",
  });
};

// 已完成
export const finish = () => {
  return axios.request({
    url: "api/backlog/finsh",
    method: "GET",
  });
};
// 获取任务详情
export const assignmentsDetailApi = (id) => {
  return axios.request({
    url: "api/assignments/" + id,
    method: "GET",
  });
};

// 新首页列表
export const getNewHomeList = (params) => {
  return axios.request({
    url: "api/home/list",
    method: "get",
    params,
  });
};

//新首页已完成列表
export const getFinishedList = (params) => {
  return axios.request({
    url: "api/home/finish",
    method: "get",
    params,
  });
};
// 新首页标记抄送已读
export const newReadCC = (data) => {
  return axios.request({
    url: "api/msg/read",
    method: "post",
    data,
  });
};

//任务流程操作
export const taskFlowOperat = (data) => {
  return axios.request({
    url: "api/flowlog/operate",
    method: "post",
    data,
  });
};

//新增任务
export const addTaskApi = (data) => {
  return axios.request({
    url: "api/assignments",
    method: "post",
    data,
  });
};

//删除任务
export const deleteTaskApi = (id) => {
  return axios.request({
    url: `api/assignments/${id}`,
    method: "delete",
  });
};

export const editorTaskApi = (id, data) => {
  return axios.request({
    url: `api/assignments/${id}`,
    method: "put",
    data,
  });
};

export const addTaskTempApi = (data) => {
  return axios.request({
    url: "api/assignmentTemplates",
    method: "post",
    data,
  });
};

export const getTaskTempDetailsApi = (id) => {
  return axios.request({
    url: `api/assignmentTemplates/${id}`,
    method: "get",
  });
};

export const modifTaskTemplateApi = (id, data) => {
  return axios.request({
    url: `api/assignmentTemplates/${id}`,
    method: "put",
    data,
  });
};

// 获取模块名
export const getModulNames = () => {
  return axios.request({
    url: "api/home/getModuleList",
    method: "get",
  });
};

export const OverheadApi = (data) => {
  return axios.request({
    url: "/api/home/top",
    method: "post",
    data,
  });
};

export const cancelOverheadApi = (data) => {
  return axios.request({
    url: "/api/home/top",
    method: "delete",
    data,
  });
};
// 新新首页状态总数
export const getTODOStatusNum = () => {
  return axios.request({
    url: "api/home/todo/statistics",
    method: "GET",
  });
};
// 新新首页状态已读
export const getTODORead = (data) => {
  return axios.request({
    url: "api/home/todo/read",
    method: "POST",
    data,
  });
};
// 首页待办改版列表
export const getHomeTODOList = (params) => {
  return axios.request({
    url: "/api/home/todo/list",
    method: "GET",
    params,
  });
};
