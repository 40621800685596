var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"common-config-page scroller"},[(
      (
        _vm.actionConfig.needAction
        && _vm.judgingArchiAPermission(_vm.actionConfig.archiLimit, _vm.actionConfig.permission)
      )
      || _vm.actionConfig.needAction === undefined
    )?_c('el-button',{staticClass:"action-btn",staticStyle:{"margin-right":"20px","width":"80px"},attrs:{"type":"primary"},on:{"click":_vm.btnClick}},[_vm._v(" "+_vm._s(_vm.formDisabled ? _vm.$t('special.CommonConfigPage.371916-0') : _vm.$t('special.CommonConfigPage.371916-1'))+" ")]):_vm._e(),(_vm.company_info_id && _vm.showData)?_c('PcParser',{ref:"dialogForm",staticStyle:{"margin-top":"50px","padding-right":"20px"},attrs:{"displayData":_vm.showData,"form-data":_vm.formDesignData,"disabled":_vm.formDisabled,"moduleUuid":_vm.contentConfig.formUUID || _vm.contentConfig.objectUUID},on:{"submit":_vm.onFormSubmit}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }