/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-23 17:34:08
 * @FilePath: \dataview-viewer-test\src\custom-component\bindLatLng\lang\zh.js
 */

export default {
  "bindLatLng.index.774051-0": "选点",
  "bindLatLng.index.774051-1": "画区域",
  "bindLatLng.index.774051-2": "清除",
  "bindLatLng.index.774051-3": "确定",
  "bindLatLng.index.774051-4": "标记",
  "bindLatLng.index.774051-5": "标记信息描述",
  "bindLatLng.index.774051-6": "经 度",
  "bindLatLng.index.774051-7": "纬 度",
  "bindLatLng.index.774051-8": "A 坐标",
  "bindLatLng.index.774051-9": "B 坐标",
  "bindLatLng.index.774051-10": "请输入关键词",
  "bindLatLng.index.774051-11": "加载中...",
  "bindLatLng.index.774051-12": "矩形",
  "bindLatLng.index.774051-13": "圆形",
  "bindLatLng.index.774051-14": "多边形",
  "bindLatLng.index.774051-15": "绑定经纬度",
  "bindLatLng.index.774051-16": "绑定GIS",
  "bindLatLng.index.774051-17": "中国"
}


