/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-05-13 13:49:17
 * @FilePath: \dataview-viewer-test\src\custom-component\form\lang\zh.js
 */

export default {
  "form.CommonForm.618069-0": "请输入",
  "form.CommonForm.618069-1": "请选择",
  "form.CommonForm.618069-2": "至",
  "form.CommonForm.618069-3": "开始时间",
  "form.CommonForm.618069-4": "结束时间",
  "form.CommonForm.618069-5": "全部",
  "form.CommonForm.618069-6": "请正确配置事件!",
  "form.CommonForm.618069-7": "操作成功！",
  "form.CommonForm.618069-8": "00000后端事件错误",
  "form.CommonDateFilter.730870-0": "近一天",
  "form.CommonDateFilter.730870-1": "近三天",
  "form.CommonDateFilter.730870-2": "近一周",
  "form.CommonDateFilter.730870-3": "近一月",
  "form.CommonDateFilter.730870-4": "近一年",
  "form.CommonDateFilter.730870-5": "开始日期",
  "form.CommonDateFilter.730870-6": "结束日期",
  "form.CommonBaseDate.199253-0": "请选择",
  "form.CommonBaseDate.199253-1": "至",
  "form.CommonBaseDate.199253-2": "开始时间",
  "form.CommonBaseDate.199253-3": "结束时间",
  "form.CommonBaseDate.199253-4": "今天",
  "form.CommonBaseDate.199253-5": "近三天",
  "form.CommonBaseDate.199253-6": "近一周",
  "form.CommonBaseDate.199253-7": "今年至今",
  "form.CommonBaseDate.199253-8": "近三个月",
  "form.CommonBaseDate.199253-9": "近六个月",
  "form.CommonBaseDate.199253-10": "近一年",
  "form.CommonBaseDate.199253-11": "近一个月",
  "form.CommonBaseDate.199253-12": "本月",
  "form.CommonBaseDate.199253-13": "本周",
  "form.CommonBaseDate.199253-14": "本季度",
  "form.CommonBaseDate.199253-15": "本年",
  "form.CommonBaseDate.199253-16": "本月至今",
  "form.CommonBaseDate.199253-17": "本年至今",
  "FormItem.widget-form.245043-0": "序号",
  "FormItem.widget-form.245043-1": "操作",
  "FormItem.widget-form.245043-2": "文件预览",
  "FormItem.widget-form.245043-3": "详情",
  "FormItem.widget-form.245043-4": "新增数据",
  "FormItem.widget-form.245043-5": "选择关联数据",
  "FormItem.widget-form.245043-6": "取 消",
  "FormItem.widget-form.245043-7": "确 定",
  "FormItem.widget-form.245043-8": "切换显示修复层级问题--",
  "FormItem.widget-form.245043-9": "数据验证失败",
  "FormItem.widget-form.245043-10": "弹窗参数",
  "FormItem.widget-form.245043-11": "公式执行错误信息",
  "FormItem.widget-form.245043-12": "计算公式配置错误",
  "newParser.PcParserNew.417838-0": "取消",
  "newParser.PcParserNew.417838-1": "提交",
  "newParser.PcParserNew.417838-2": "流程审批",
  "newParser.PcParserNew.417838-3": "关闭流程图",
  "newParser.PcParserNew.417838-4": "流程图",
  "newParser.PcParserNew.417838-5": "暂无流程数据",
  "newParser.PcParserNew.417838-6": "外部表单",
  "newParser.PcParserNew.417838-7": "基本信息",
  "scripts.parserMethods.537613-0": "数据验证失败",
  "scripts.parserMethods.537613-1": "公式执行错误信息",
  "scripts.parserMethods.537613-2": "计算公式配置错误",
  "scripts.parserMethods.537613-3": "数据校验失败，请仔细核对必填项字段！",
  "scripts.parserMethods.537613-4": "审批人未选择完整，请选择审批人！",
  "scripts.parserMethods.537613-5": "--表单数据--",
  "components.cascader.396254-0": "请选择人员",
  "components.cascader.396254-1": "添加人员",
  "components.cascader.396254-2": "请输入人员名字",
  "components.cascader.396254-3": "文",
  "components.cascader.396254-4": "人",
  "components.cascader.396254-5": "已选人员（",
  "components.cascader.396254-6": "清空",
  "components.cascader.396254-7": "已选中",
  "components.cascader.396254-8": "公",
  "components.cascader.396254-9": "项",
  "components.cascader.396254-10": "部",
  "components.cascader.878119-0": "请选择",
  "components.cascader.878119-1": "请输入部门名字",
  "components.cascader.878119-2": "已选中",
  "components.cascader.878119-3": "人",
  "components.cascader.878119-4": "清空",

	"FormItem.widget-longlat.186372-0": "重新选择",
	"FormItem.widget-longlat.186372-1": "详细地址：",
	"FormItem.widget-longlat.186372-2": "获取失败",
	"FormItem.widget-longlat.186372-3": "经度：",
	"FormItem.widget-longlat.186372-4": "纬度：",
	"FormItem.widget-longlat.186372-5": "无",
	"FormItem.widget-longlat.186372-6": "请选择",
	"FormItem.widget-longlat.186372-7": "中国",
	"FormItem.widget-longlat.186372-8": "是否确认删除已选点?",
	"FormItem.widget-longlat.186372-9": "提示",

  "FormItem.widget-database.865990-0": "操作",
  "FormItem.widget-database.865990-1": "详情",
  "FormItem.widget-database.865990-2": "选择关联数据",
  "FormItem.widget-database.865990-3": "取 消",
  "FormItem.widget-database.865990-4": "确 定",

  "FormItem.widget-date-range.737753-0": "日期范围最多只能选【{0}】天，已自动更改截止时间",

  "components.FlowRecover.197417-0": "流程审批记录",
  "components.FlowRecover.197417-1": "关闭",
  "components.FlowRecover.197417-2": "查看",
  "components.FlowRecover.197417-3": "流程图",
  "components.FlowRecover.197417-4": "月$",
  "components.FlowRecover.197417-5": "日 $",
  "components.FlowRecover.197417-6": "审批人："
}


