<!--
 * @Author: Shiltin
 * @Date: 2022-08-2 11:04:20
 * @LastEditors: 冉桂精 156189868@qq.com
 * @Description: 中控台滚动列表
-->
<template>
		<div class="scroll-list-cont">
				<div class="group-cont">
					<div class="label-cont">
						<i class="iconfont iconproject-xiangmu"></i>
						<input @blur="saveType" class="type-input" v-if="showInput" v-model="inputVal" />
						<span v-if="showInput" class="type-btn" @click="saveType">保存</span>	
						<span v-else>{{ typeName }}</span>	
					</div>	
					<div class="right-handle" v-if="element.bindFieldConfig?.hasType">
						<el-dropdown :hide-on-click="false">
							<i style="margin-right: 5px;" class="el-icon-arrow-down el-icon--right"></i>
							<template slot="dropdown">
									<el-dropdown-menu>
											<el-dropdown-item  
													v-for="(val, i) in typeData"
													:key="i"
													><span :style="{color:val.id === filterTypeId?'#00B7FF':''}" @click.stop="filterType(val)">{{val.name}}</span>
											</el-dropdown-item>
									</el-dropdown-menu>
							</template>
						</el-dropdown>
						<el-dropdown :hide-on-click="false">
							<i class="iconfont icongengduomore"></i>
							<template slot="dropdown">
									<el-dropdown-menu>
											<el-dropdown-item  
													v-for="(val, i) in typeHandleData"
													:key="i"
													><p @click.stop="typeHandle(val)">{{val.name}}</p>
											</el-dropdown-item>
									</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>	
				</div>
				<div id="infiniteCont" class="infinite-cont">
					<div id="infiniteInnerCont" class="infinite-list"  v-infinite-scroll="loadData" :infinite-scroll-immediate="immediate">
							<div v-if="['3rowTextAndImg', '2rowTextAndImg'].includes(layoutConfig.type)">
								<div
										:style="getStyle(item)"
										v-for="(item, index) in listData" 
										:id="index === 0?'firstRow':''"
										class="infinite-list-item"
										:key="index"
										@click.stop="locationFun($event,item,index)"
								>
									<template v-if="layoutConfig.type">
										<!-- 图片 -->
										<div @click.stop="onAction('rowDetail', item, index)"
											class="item-img-warp" v-if="['3rowTextAndImg', '2rowTextAndImg'].includes(layoutConfig.type) && layoutConfig.img">
											<el-image
												v-if="item[layoutConfig.img]"
												:style="{
													height: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.height || 72) + 'px',
													width: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.width || 72) + 'px',
												}"
												:src="item[layoutConfig.img].length?item[layoutConfig.img][0].url:'//static.bimcc.com/images/h5-img/empty_no_content@2x.png'"
												fit="fit" />
											<img v-else
												:style="{
													height: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.height || 72) + 'px',
													width: (fieldConfigObj[layoutConfig.img] && fieldConfigObj[layoutConfig.img].imgConfig.width || 72) + 'px',
												}"
												src="//static.bimcc.com/images/h5-img/empty_no_content@2x.png" alt=""
											>	
										</div>
										<!-- 文本盒子 -->
										<div @click.stop="onAction('rowDetail', item, index)" class="item-text-warp" >
											<template>
												<p 
													class="text-item"
													:class="sitem.uuid === layoutConfig.title? 'title-text' : ''"
													v-for="(sitem, j) in fieldConfig.filter(v=>v.show && v.type !== 2)"
													:style="{color: sitem.color || '#fff'}"
													:key="j"
												>
													<span v-show = "sitem.uuid !== layoutConfig.title && sitem.label">{{sitem.label}} : </span>
													<span class="value-style" :style="{	backgroundColor:getValue(sitem,item,'color'),padding:sitem.type === 3?'3px 10px':'0px'}">{{getValue(sitem,item,'value')}}</span>
												</p>
											</template>
											<!-- 模型绑定与定位图标 -->
											<template>
												<div class="bottom-icons">
													<el-tooltip 
														v-for="(val, i) in modelHandle"
														:key="i" class="item"
														effect="dark" :content="val.name" 
														placement="top">
														<i @click.stop="modelAction(val,$event,item,index)"
															:class="getIconStyle(val,item)" 
															v-if="logicAction(val,item) && checkIconState(val,item)"  
															class="iconfont">
														</i>
													</el-tooltip>
												</div>	
											</template>
										</div>
										<div class="item-handle-warp">
											<el-dropdown  v-if="listHandle.length" :hide-on-click="false">
													<i class="iconfont icongengduomore"></i>
													<template slot="dropdown">
															<el-dropdown-menu>
																	<el-dropdown-item  
																			v-for="(val, i) in listHandle"
																			:key="i"
																			><span @click.stop="onAction(val.type, item, index)">{{val.name}}</span>
																	</el-dropdown-item>
															</el-dropdown-menu>
													</template>
											</el-dropdown>
										</div>	
									</template>
								</div>
							</div>	
							<!-- 图片背景模式 -->
							<div v-else  class="w-full h-full">
								<div
										:style="getStyle(item)"
										v-for="(item, index) in listData" 
										class="img-bg-mode"
										:id="index === 0?'firstRow':''"
										:key="index"
										@click.stop="locationFun($event,item,index)"
								>
									<template v-if="layoutConfig.type">
										<!-- 文本盒子 -->
										<div class="status-filed" v-show="fieldConfig.filter(v=>v.show && v.type === 3).length"  :style="{backgroundColor:getValue(fieldConfig.filter(v=>v.show && v.type === 3)[0],item,'color')}">{{getValue(fieldConfig.filter(v=>v.show && v.type === 3)[0],item,'value')}}</div>
										<div class="item-text-warp" >
											<template>
												<p 
													class="text-item"
													:class="sitem.uuid === layoutConfig.title? 'title-text' : ''"
													v-for="(sitem, j) in fieldConfig.filter(v=>v.show && v.type !== 2 && v.type !== 3)"
													:style="{color: sitem.color || '#fff'}"
													:key="j"
												>
													<span v-show = "sitem.uuid !== layoutConfig.title && sitem.label">{{sitem.label}} :</span>
													<span class="value-style">{{getValue(sitem,item,'value')}}</span>
												</p>
											</template>
											<!-- 模型绑定与定位图标 -->
											<template>
												<div class="bottom-icons">
													<el-tooltip 
														v-for="(val, i) in modelHandle"
														:key="i" class="item"
														effect="dark" :content="val.name" 
														placement="top">
														<i @click.stop="modelAction(val,$event,item,index)"
															:class="getIconStyle(val,item)" 
															v-if="logicAction(val,item) && checkIconState(val,item)"  
															class="iconfont">
														</i>
													</el-tooltip>
												</div>	
											</template>
										</div>
										<div class="item-handle-warp">
											<i @click.stop="onAction('rowDetail', item,  index)" class="iconquanping5 iconfont"></i>	
											<el-dropdown style="margin-top:10px;" v-if="listHandle.length" :hide-on-click="false">
													<i  class="iconfont icongengduomore more-handle"></i>
													<template slot="dropdown">
															<el-dropdown-menu>
																	<el-dropdown-item  
																			v-for="(val, i) in listHandle"
																			:key="i"
																			><span @click.stop="onAction(val.type, item, index)">{{val.name}}</span>
																	</el-dropdown-item>
															</el-dropdown-menu>
													</template>
											</el-dropdown>
										</div>	
									</template>
								</div>
							</div>	
							
							<div v-if="loading && !finished" class="loading-cont">
									<span />
									<p>加载中...</p>
							</div>
							<p v-if="finished" class="no-more">没有更多了!</p>
					</div>
				</div>	
				<el-dialog custom-class="dialog-location"  :width="isGis && !isOpenGisDraw?'290px':'240px'" append-to-body :modal="false" :show-close="false" :visible.sync="dialogTableVisible">
					<div style="padding:8px">
						<i class="iconfont iconinformation"></i>当前暂无定位，
						<span class="bind-btn" v-if="isGis && !isOpenGisDraw" @click="openGisDraw">开启绑定</span> 
						<el-button  size="mini" class="show-detail-btn" @click="onAction('rowDetail', clickItemInfo.data,clickItemInfo.index)" type="primary">查看详情</el-button>
					</div>
					<div style="text-align: center;"> <span>{{ seconds }}</span>秒后自动 <span class="bind-btn" @click="closeDialog()">关闭</span>
					</div>
				</el-dialog>
					
		</div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { getStatusVal } from '@/utils/tools';
import {getComponentById, getQueryValue, judgingArchiAPermission, initParams, findModelComponentId } from '@/utils/tools';
import { mapState } from 'vuex';
import { Image, Dropdown, DropdownMenu, DropdownItem, Tooltip, MessageBox } from 'element-ui';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';

export default {
	name: 'ConsoleList',
	directives: { infiniteScroll },
	mixins: [databaseTriggerDebug],
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		},
		isGroup: {
			type: Boolean,
		},
	},
	components: {
		'el-image': Image,
		'el-dropdown': Dropdown,
		'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem,
		'el-tooltip': Tooltip

	},
	data() {
		return {
			immediate: false,
			// 列表状态
			loading: false,
      finished: false,
			activeIndex:-1,
			page: 0,
			listData: [],
			metadata: [],// 配置数据
			weekDays: ['周天', '周一', '周二', '周三', '周四', '周五', '周六'],
			listHandle: [],	//操作按钮		
			modelHandle: [],//模型操作相关
			itemOutPut:null,//item的取值
			timer: null,
			bimComponentId:'',//模型的组件id
			gisComponentId:'',//gis模型组件id
			loadedBimIds:[],//已加载完的Bim模型Id
			chooseBimInfo:null,//bim选中的模型构件（里面是"field6268d8869a7a2": []格式）
			chooseGisInfo:null,//选中的gis位置
			dialogTableVisible:false,
			seconds: 3,
			isOpenGisDraw:false,//开启gis选点

			typeHandleData:[
				{name:'新增分组', type:'addType'},
				{name:'编辑', type:'editType'}
			],//分类操作内容
			typeData:[],//分类数据
			typeName:'默认分组',
			menuId: 0,
			filterTypeId:0,//选择的分类id
			inputVal:'',
			showInput:false,
			inputType:'addType',
		};
	},
	computed: {
		// 数据仓库配置
		...mapState(['componentData', 'curComponent','_PageStatusList']),
		//是否是bim页面显示
		isBim () {
			let bimComponent = this.componentData.filter(v => v.component === 'ViewerBIM')
			if(bimComponent.length && bimComponent[0].statusConfig?.displayShow && bimComponent[0].statusConfig?.isShow){
				return true
			}else{
				return false
			}
		},
		//是否是gis页面显示
		isGis () {
			let gisComponent = this.componentData.filter(v => v.component === 'ViewerGIS')
			if(gisComponent.length && gisComponent[0].statusConfig?.displayShow && gisComponent[0].statusConfig?.isShow){
				return true
			} else {
				return false
			}
		},
		//下排菜单的显示，主要是bim/gis判断
		checkIconState(){
			return function (item,row) {
				if (item.type === 'bind') {
					if (this.isGis) {
						if(this.isOpenGisDraw){
							return true
						} else {
							return false
						}
						
					} 
				} else {
					if (this.isBim) {
						if(this.bindFieldConfig.bimField &&  row[this.bindFieldConfig.bimField]?.length && row[this.bindFieldConfig.bimField] !== null){
							return true
						} else {
							if(item.type === 'location'){
								return true
							} else {
								return false
							}
						}
					} else if (this.isGis) {
						if(item.type === 'location'){
								return true
						} else {
							if(this.bindFieldConfig.gisField && row[this.bindFieldConfig.gisField] && JSON.stringify(row[this.bindFieldConfig.gisField])!== '[]' && row[this.bindFieldConfig.gisField] !== 'null'){
								return true
							} else {
								return false
							}
						}	
					}
				}
				return true
			}
		},
		database() {
			return this.element && this.element.database;
		},
		// 操作类型
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 字段配置
		fieldConfig() {
			const configs = this.element && this.element.fieldConfig;
			if (!configs) return [];
			if (!configs && !configs.length) return this.metadata || [];
			const result = [];
			configs.forEach((ele) => {
				if (ele) {
					result.push(ele);
				}
			});
			return result;
		},
		//分类表objectuuid
		typeObjectUUID(){
			return this.element.bindFieldConfig?.typeObjectUUID || ''
		},
		//分类表viewuuid
		typeViewUUID(){
			return this.element.bindFieldConfig?.typeViewUUID || ''
		},
		//分类表名称字段
		typeField(){
			return this.element.bindFieldConfig?.typeField || ''
		},
		// hash 字段配置，方便查询
		fieldConfigObj() {
			const hash = {}
			this.fieldConfig.map(el => {
				hash[el.uuid] = el
			})
			return hash
		},
		// layout 配置
		layoutConfig() {
			const configs = this.element && this.element.layoutConfig;
			if (!configs) {
				return {}
			} else {
				return configs
			}
		},
		pageSize(){
			return this.element && this.element.layoutConfig && this.element.layoutConfig.pageSize || 15;
		},
		//模型绑定的字段
		bindFieldConfig (){
			return this.element.bindFieldConfig || {gisField:'',bimField:''}
		},
		//定位、绑定、解绑图标的颜色状态
		getIconStyle() {
      return function (item,data) {
				if(item.type !== 'clearBind'){
					if (item.type === 'location') {
						if (this.isBim) {
							//未加载模型
							if(this.loadedBimIds?.length === 0){
								return item.icon + ' icon-opacity'
							} else {
								if(item.dependLogic?.length && item.dependLogic[0].field){
									let bimInfo = data[item.dependLogic[0].field]
									if(bimInfo && bimInfo.filter((v)=>this.loadedBimIds.includes(v.model_id)).length){
										return item.icon
									} else {
										return item.icon + ' icon-opacity'
									}
								} else {
									if(this.element.bindFieldConfig?.bimField){
										let bimInfo = data[this.element.bindFieldConfig.bimField]
										if(this.element.bindFieldConfig.bimField &&  data[this.element.bindFieldConfig.bimField]?.length && data[this.element.bindFieldConfig.bimField] !== null){
											if(bimInfo && bimInfo.filter((v)=>this.loadedBimIds.includes(v.model_id)).length){
												return item.icon
											} else {
												return item.icon + ' icon-opacity'
											}
										} else {
											return item.icon + ' icon-opacity'
										}
									}
								}
							}
						} else if(this.isGis){
							if(this.bindFieldConfig.gisField && data[this.bindFieldConfig.gisField] && JSON.stringify(data[this.bindFieldConfig.gisField])!== '[]' && data[this.bindFieldConfig.gisField] !== 'null'){
								return item.icon 
							} else {
								return item.icon + ' icon-opacity'
							}
						}
					} else if(item.type === 'bind') {
						if(this.isBim){
							if(this.chooseBimInfo === null ){
								return item.icon + ' icon-opacity'
							}else{
								return item.icon
							}
						} else if(this.isGis){
							if(this.chooseGisInfo === null ){
								return item.icon + ' icon-opacity'
							}else{
								return item.icon
							}
						}
					}
				} else {
					return item.icon
				}
      }
    },
		getValue(){
			return function(data,item,field){
				if(data?.uuid){
					let value = item[data.uuid]
					if (data.type === 3 && data?.statusOptions?.length) {
							if(Array.isArray(value)){
								let str = ''
								value.forEach(val=>{
									let filterData = data.statusOptions.filter(v => +v.value === +val)
									if(filterData.length){
										if(field === 'value'){
											str +=  filterData[0].label + '/';
										}
									}
								})
								if(field === 'value'){
									return str === '' ? '-' : str.substring(0,str.length-1)
								} else {
									return '-'
								}
								
							} else {
								let filterData = data.statusOptions.filter(v => v.value === value || +v.value === +value)
								if(filterData.length){
									if(field === 'value'){
										return filterData[0].label || '-';
									} else if (field === 'color'){
										return filterData[0].color;
									}
								} else {
									if(field === 'value'){
										return '-'
									}else{
										return ''
									}
								}
								
							}
					} else {
						return value || '-'
					}
				} else {
					return ''
				}
			}
		},
		getStyle(){
			return function(item){
				if(this.layoutConfig.type === '3rowText'){
					let backgroundImage = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8d9e1bc5535d191b08af6e7007a98cb0.png'
					if(this.layoutConfig.img && item[this.layoutConfig.img]?.length){
						for(let i = 0;i<item[this.layoutConfig.img].length;i++ ){
							if(['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].includes(item[this.layoutConfig.img][i].fileext)){
								backgroundImage = item[this.layoutConfig.img][0].url
								break
							}
						}
					}
					return {
						backgroundImage:`url(${backgroundImage})`,
						backgroundSize: '100% 100%',
						color:'#fff'
					}
				} else {
					if(this.element.bindFieldConfig?.itemBackgroundColor){
						return {background: this.element.bindFieldConfig?.itemBackgroundColor} 
					} else {
						return {background:'rgba(8,35,48,0.8)'}
					}
					
				}
			}
		},
	},
	watch: {
		page: {
      handler(val) {
				if(+val === 1){
					this.activeIndex = -1
				}
      },
      deep: true,
    },
		isGis:{
			handler() {
				eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.gisComponentId);
				this.isOpenGisDraw = false
      },
      deep: true,
		}
	},
	mounted() {
		window.addEventListener('wheel', this.handleMouseWheel)
		//获取分类数据
		if(this.element.bindFieldConfig?.hasType){
			this.getTypeData()
		}
		//获取模型的组件id
		this.bimComponentId = findModelComponentId(this.componentData,'ViewerBIM') || ''
		this.gisComponentId = findModelComponentId(this.componentData,'ViewerGIS') || ''
		this.finished = false
		this.isOpenGisDraw = false
		//列表操作按钮
		if(this.actionConfig){
			for(let key in this.actionConfig){
				if(key!=='needAction' && this.actionConfig[key].use){
					if(key!=='location' && key!=='bind' && key!=='clearBind'){
						//编辑删除详情等操作
						this.listHandle.push(this.actionConfig[key])
					} else {
						//模型操作相关
						this.modelHandle.push(this.actionConfig[key])
					}
				}
			}
		}
		// 监听请求
		const databaseTrigger = {
			[this.element.id]: (data) => {
				// console.log('中控台下拉列表才需要监听', data, '-----', this.element.name);
				let componentArr = ['ConsoleFilter','ConsoleTypeTree','ConsoleList']
				let dataComponentId = data.componentId
				if(dataComponentId){
					let type = dataComponentId.split('-')
					if(!componentArr.includes(type[0])){
						return
					}
				}
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					this.page = 1
					let { search = [], param = {}, canPost } = this.initParams();
					if (!canPost) return;
					this.listData = []
					this.finished = false
					this.getList(this.element.database, search, param, this.page);
				}, 200)
			}
		}
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
		const refreshConsoleList = {
			[this.element.id]: () => {
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					this.page = 1
					let { search = [], param = {}, canPost } = this.initParams();
					if (!canPost) return;
					this.listData = []
					this.finished = false
					this.getList(this.element.database, search, param, this.page);
				}, 200)
			}
		}
    eventBus.$on('refreshConsoleList', refreshConsoleList[this.element.id]);
		//监听行为
		const doComponentBehavior = {
			[this.element.id]: (config) => {
				const { component, list = [] } = config;
				if (component !== this.element.id) return false;
				list.forEach(ele => {
					const { behaviors, params } = ele;
					const { param = {}, canPost } = initParams(params, this.isGroup, this.componentData, this.groupComponents);
					//着色颜色状态
					if(behaviors[0] === 'onSetColor'){
						if(params.length){
							params.forEach((item)=>{
								if (item.key === 'status') {
									let statusOptions =  this.fieldConfigObj[item.paramKey].statusOptions
									if (statusOptions.length) {
										let findData = statusOptions.filter(v => +v.value === param.status)
										if (findData.length && findData[0].color) {
											param.status = findData[0].color;
										} else {
											param.status = '';
										}
									}
								}
							})
						}
					}
					if (canPost) {
						// 调用行为方法
						behaviors.forEach(funName => {
							try {
								eval(this[funName])(param, params)
							} catch (err) {
								console.log(err);
							}
						});
					}
				});
			}
		}
		eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id]);
		//模型返回的状态值监听
		eventBus.$on('Viewer.callback',(id,data) => {
			console.log(id,data)
		})
		//监听模型初始化是否完毕
		eventBus.$on('Viewer.onViewerInited',() => {
			console.log('模型加载完毕')
		})
		//模型加载返回的model_id
		eventBus.$on('Viewer.onLoadedModel',() => {
			eventBus.$emit('ViewerBIM.getModelIds', this.bimComponentId , (list)=>{
				if(list){
					this.loadedBimIds = [...list]
				}
			})	
		})
		//模型选中构件
		eventBus.$on('Viewer.onViewerSelect',(componentId,selection) => {
			if(selection?.length && this.bindFieldConfig.bimField){
				let arr = []
				selection.forEach((item)=>{
					let obj = {model_id:item.model_id, dbids:item.dbids, select: item.dbids}
					arr.push(obj)
				})
				this.$nextTick(()=>{
					if(this.chooseBimInfo === null){
						this.chooseBimInfo = {}
						this.$set( this.chooseBimInfo,this.bindFieldConfig.bimField, arr)
					}else{
						this.chooseBimInfo[this.bindFieldConfig.bimField] = arr
					}
				})
			} else {
				this.chooseBimInfo = null
			}
		})
	},
	methods: {
  /**
   * @description: 定位
   * @return {*}
   */		
		locationFun(e,item,index){
			const locationObj = this.modelHandle.filter(v=>v.type === 'location')
			if(locationObj?.length){
				this.modelAction({type:'location'},e,item,index, false)
			}
		},
  /**
   * @description: 开启gis标记方法
   * @return {*}
   */		
		openGisDraw(){
			this.dialogTableVisible = false
			eventBus.$emit('ViewerGIS.drawPointByMouseCustom', this.gisComponentId, async(position,selectFeature) => {
				this.isOpenGisDraw = true
				if(position){
					let obj = [{location_gis:position}]
					if(Object.keys(selectFeature).length){
						let models = []
						for (let key in selectFeature) {
							models.push({ model_id: key, dbids:selectFeature[key] });
						}
						obj = [{location_gis:position, models:models}]
					}
					if(this.chooseGisInfo === null){
						this.chooseGisInfo = {}
						this.$set( this.chooseGisInfo,this.bindFieldConfig.gisField, obj)
					}else{
						this.chooseGisInfo[this.bindFieldConfig.gisField] = obj
					}
				}
			})
		},
		
		/**
		 * @description: 保存分类
		 * @return {*}
		 */		
		saveType(){
			this.showInput = false
			let param = {
				object_uuid:this.typeObjectUUID ,
				view_uuid:this.typeViewUUID,
				[this.typeField]:this.inputVal
			}
			if(this.inputVal && this.inputVal !== this.typeName){
				if(this.inputType === 'editType'){
					param.__method_name__ = 'updateData'
					param.data_id = this.menuId
				} else if(this.inputType === 'addType'){
					param.__method_name__= 'createData'
				}
				dataInterface(param).then((res) => {
					if(res.data.code === 200){
						if(this.inputType === 'editType'){
							this.typeName = this.inputVal
						}
						this.getTypeData()
					}
				})
			}
			
		},
  /**
   * @description: 分类操作
   * @return {*}
   */		
		typeHandle(data){
			this.inputType = data.type
			if(data.type === 'editType'){
				this.inputVal = this.typeName
				this.showInput = true
			} else if(data.type === 'addType'){
				this.inputVal = ''
				this.showInput = true
			}

		},
  /**
   * @description: 
   * @return {*}
   */		
		filterType(data){
			if(data.id !== this.filterTypeId){
				this.finished = false
				this.typeName = data.name
				this.filterTypeId = data.id
				this.menuId = data.id
				if(this._PageStatusList.filter((v)=>v.code === 'menuId').length){
					this.$store.commit('updatePageCustomStatus', {
						key: 'menuId',
						value: this.menuId
					});
				} else {
					console.log('请设置页面状态值menuId')
				}
				this.listData = []
				this.page = 1
				let { search = [], param = {}, canPost } = this.initParams();
				search.push({
					field_uuid: this.element.bindFieldConfig?.typeIdField || '',
					ruleType: 'eq',
					value: this.filterTypeId
				})
				if (canPost) {
					this.getList(this.database,search, param || {}, this.page);
				}
			}
		},
  /**
   * @description: 获取分类数据
   * @return {*}
   */		
		getTypeData(){
			if(!this.typeObjectUUID || !this.typeViewUUID) return
			this.typeData = []
			dataInterface({
				object_uuid:this.typeObjectUUID ,
				view_uuid:this.typeViewUUID,
				__method_name__:'dataList',
				// transcode:0
			}).then((res) => {
				if(res.data.code === 200){
					const metadata = res.data.metadata
					let idField = ''
					for(const key in metadata){
						if(metadata[key].includes('id')){
							idField = key
						}
					}
					if(Array.isArray(res.data.data) && res.data.data?.length){
						this.typeData = res.data.data
					} else if(res.data.data?.data && Array.isArray(res.data.data.data) && res.data.data.data?.length){
						this.typeData = res.data.data.data
					}
					if(this.typeData.length){
						this.typeData.map((item)=>{
							item.name = item[this.typeField]
							if(idField){
								item.id = item[idField]
							}
						})
						this.typeData.unshift({
							name:'全部',id:0
						})
					}
				}
			})
		},
  /**
   * @description: 鼠标滚动获取分类
   * @return {*}
   */		
		handleMouseWheel(){
			//获取当前是哪一个元素
			clearTimeout(this.timer)
			const firstRow =  document.getElementById('firstRow')
			const offsetTop = document.getElementById('infiniteInnerCont').offsetTop - 2
			if(firstRow && this.element.bindFieldConfig?.typeNameField){
				let index = 0
				this.timer = setTimeout(()=>{
					index = Math.round((document.getElementById('infiniteCont').scrollTop - offsetTop)/(firstRow.clientHeight + 15))
					if(this.listData[index] && this.element.bindFieldConfig.typeIdField && this.listData[index][this.element.bindFieldConfig.typeIdField]){
						this.menuId = this.listData[index][this.element.bindFieldConfig.typeIdField]
						//将滚动的区域获取的typeid放入全局变量
						if(this._PageStatusList.filter((v)=>v.code === 'menuId').length){
							this.$store.commit('updatePageCustomStatus', {
								key: 'menuId',
								value: this.menuId
							});
						}
						const filterData = this.typeData.filter((v)=> v.id === this.menuId)
						if(filterData?.length){
							this.typeName = filterData[0].name
						} else {
							this.typeName = this.listData[index][this.element.bindFieldConfig.typeNameField]
						}
					}
				},200)
				
			}
		},
		closeDialog(){
			this.dialogTableVisible=false
			clearInterval(this.timer)
		},
		/**
		 * @desc: 下拉加载数据
		 */
		loadData(){
			if(!this.finished){
				this.page++
				let { search = [], param = {}, canPost } = this.initParams();
				if (canPost) {
					if(this.filterTypeId){
						search.push({
							field_uuid: this.element.bindFieldConfig?.typeIdField || '',
							ruleType: 'eq',
							value: this.filterTypeId
						})
					}
					this.getList(this.database,search, param || {}, this.page);
				}
      }
		},
		// 判断架构及权限
		judgingArchiAPermission,
		/**
		 * @desc: 根据配置逻辑判断操作是否显示
		 * @param {Object} actionConfig
		 */
		logicAction(actionConfig, row) {
				if (this.isGis) {
					return true
				}
				if (!actionConfig.useDepend || !actionConfig || !actionConfig.dependLogic ||!actionConfig.dependLogic.length) {
					return true;
				}
				const dependLogic = actionConfig.dependLogic;
				const logicList = [];
				for (let i = 0, len = dependLogic.length; i < len; i++) {
					const { field, fieldAttr, fieldLogic, itemLogic, value, valueType } = dependLogic[i];
					let logicValue = null;
					if (fieldAttr === 'length') {
						logicValue = row[field]?.length || 0;
					} else {
						logicValue = row[field];
					}
					// console.log(logicValue,'logicValue',field)
					// 当前项判断条件
					// 字段关系 大于>  小于 < 等于 === 大于等于>=  小于等于 <= 不等于 !=
					let itemBoolean = true;
					if (fieldLogic === '===') {
						if (valueType === 'string') {
							itemBoolean = logicValue === value;
						} else if (valueType === 'number') {
							itemBoolean = +logicValue === +value;
						} else if (valueType === 'date') {
							itemBoolean = new Date(logicValue).getTime() === value;
						}
					} else if (fieldLogic === '!=') {
						if (valueType === 'string') {
							itemBoolean = logicValue !== value;
						} else if (valueType === 'number') {
							itemBoolean = logicValue !== +value;
						} else if (valueType === 'date') {
							itemBoolean = new Date(logicValue).getTime() !== value;
						}
					} else if (fieldLogic === '>') {
						if (valueType === 'number') {
							itemBoolean = logicValue > +value;
						} else if (valueType === 'date') {
							itemBoolean = new Date(logicValue).getTime() > value;
						}
					} else if (fieldLogic === '<') {
						if (valueType === 'number') {
							itemBoolean = logicValue < +value;
						} else if (valueType === 'date') {
							itemBoolean = new Date(logicValue).getTime() < value;
						}
					} else if (fieldLogic === '<=') {
						if (valueType === 'number') {
							itemBoolean = logicValue <= +value;
						} else if (valueType === 'date') {
							itemBoolean = new Date(logicValue).getTime() <= value;
						}
					} else if (fieldLogic === '>=') {
						if (valueType === 'number') {
							itemBoolean = logicValue >= +value;
						} else if (valueType === 'date') {
							itemBoolean = new Date(logicValue).getTime() >= value;
						}
					}
					logicList.push({ itemLogic, itemBoolean });
				}
				if (logicList.length === 1) {
					return logicList[0].itemBoolean;
				}
				// 循环逻辑关系
				let prevLogicBoolean = null;
				for (let j = 1; j < logicList.length; j++) {
					const prev = logicList[j - 1];
					const prevItemLogic = prev.itemLogic;
					if (prevItemLogic === 'and') {
						if (j === 1) {
							prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
						} else {
							prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
						}
						if (!prevLogicBoolean) {
							return false;
						}
					} else if (prevItemLogic === 'or') {
						if (j === 1) {
							prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
						} else {
							prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
						}
						if (!prevLogicBoolean) {
							return false;
						}
					}
				}
				return true;
		},
		/**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			let comp = getComponentById(this.componentData, componentId);
      if (!comp && this.isGroup && this.groupComponents.length) {
				comp = getComponentById(this.groupComponents, componentId);
			}
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
		/**
		 * @desc: 初始化获取参数
		 */
		initParams() {
			const { paramsConfig } = this.element.database;
			// 是否可以请求
			let canPost = true;
			if (!paramsConfig || !paramsConfig.length) return {
				canPost
			};
			let search = [];
			const param = {};
			for (let i = 0; i < paramsConfig.length;i++) {
				const ele = paramsConfig[i];
				let originComponent = getComponentById(this.componentData, ele.componentId);
				if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, ele.componentId);
				}
				if (ele.key !== 'search') {
					if (ele.sourceType === 'url') {
						// url参数
						const queryVal = getQueryValue(ele.urlParamsKey);
						this.$set(param, ele.key, queryVal);
						if (ele.isRequired && !queryVal) {
							canPost = false;
							break;
						}
					} else if (ele.isRequired && (!originComponent || !originComponent.resolveData)) {
						canPost = false;
						break;
					} else if (originComponent && originComponent.resolveData) {
						const queryVal = originComponent && originComponent.resolveData[ele.paramKey];
						this.$set(param, ele.key, queryVal);
						if (ele.isRequired && !queryVal) {
							canPost = false;
							break;
						}
					}
				} else {
					if (originComponent && originComponent.resolveData && Array.isArray(originComponent.resolveData)) {
						search = search.concat(originComponent.resolveData)
						search = Array.from(new Set(search))  
					}
				}
			}
			return { search, param, canPost};
		},
		/**
		 * @desc: 模型操作
		 * @param {Object} item 当前得item数据
		 */
		async modelAction(item,e,data, index, behavior = true){
			this.clickItemInfo={
				data,e,index
			}
			if(this.isBim){
				if(!this.bindFieldConfig.bimField || !data) return
			} else if (this.isGis) {
				if(!this.bindFieldConfig.gisField || !data) return
			}
			const eventList = this.element.actionConfig[item.type]?.eventList  || [];
			if (eventList && eventList.length && !e.target.className.includes('icon-opacity') && behavior) {
				eventList.forEach((el) => {
					el.eventList.forEach(ele => {
						if (ele.key === 'click') {
							this.$store.commit('modifyComponent', {
								component: {
									...this.element,
									resolveData: data
								},
								containerId: null,
								isModify: true
							});
							// 触发组件行为
							const { behaviors } = ele;
								behaviors.forEach(behavior => {
									this.$store.commit('triggerEvents', {
										config: {
											behavior,
											isBehavior: true
										},
										element: this.element
									});
								});
						}
					})
				});
			}		
			//定位构件
			if(item.type === 'location'){
				let bindInfo = data[this.bindFieldConfig.bimField]
				if(this.isGis){
					bindInfo = data[this.bindFieldConfig.gisField]
				}
				//加载其他模型
				if(e.target.className.includes('icon-opacity')){
					if(this.isBim){
						const unloadModel = await this.findUnloadModel(this.loadedBimIds,bindInfo)
						if(bindInfo && unloadModel?.length){
							this.$confirm(this.loadedBimIds.length === 0?'是否加载模型?':'是否加载其他模型?', '提示', {
								confirmButtonText: this.loadedBimIds.length === 0 ? '确定' : '叠加加载',
								cancelButtonText: this.loadedBimIds.length === 0 ? '取消' : '重新加载',
								type: 'warning',
								closeOnClickModal: false,
								distinguishCancelAndClose: true,
								center: false,
								beforeClose (action, instance, done) {
									done()
								}
							}).then(() => {
								if(bindInfo.length){
									bindInfo.forEach((item)=>{
										if(!this.loadedBimIds.includes(item.model_id)){
											eventBus.$emit('ViewerBIM.loadModel', this.bimComponentId, item.model_id)
										}
									})
								}
							}).catch((error) => {
								if(error === 'close'){
									return
								} else if(this.loadedBimIds.length){
									this.loadedBimIds.forEach((item)=>{
											eventBus.$emit('ViewerBIM.unloadModel', this.bimComponentId, item)
									})
									if(bindInfo.length){
										bindInfo.forEach((item)=>{
												eventBus.$emit('ViewerBIM.loadModel', this.bimComponentId, item.model_id)
										})
									} 
								}
							});
						} else {
							this.openRebindDialog()
						}
					} else {
						this.openRebindDialog()
					}
				} else {
					if(bindInfo){
						if(this.isBim){
							if(bindInfo.length){
								eventBus.$emit('ViewerBIM.setSelect', this.bimComponentId, bindInfo);
								//缩放到
								// eventBus.$emit('ViewerBIM.fitToView',this.bimComponentId, bindInfo)
							}
						} else {
							if(Object.prototype.toString.call(bindInfo) === '[object Object]'){
								eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, bindInfo.position, 1000, 45, 2);
								if(bindInfo.models?.length){
									for(let i = 0; i < bindInfo.models.length;i++){
										const item = bindInfo.models[i]
										eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
									}
								}
							} else if(Array.isArray(bindInfo) && bindInfo.length){
								eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, bindInfo[0].location_gis, 1000, 45, 2);
								if(bindInfo[0].models?.length){
									for(let i = 0; i < bindInfo[0].models.length;i++){
										const item = bindInfo[0].models[i]
										eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
									}
								}
							}
						}
					}
				}
				
      } else { //解绑重绑
				let keyStr = ''
				Object.keys(this.metadata).map((key) => {
					if (this.metadata[key] === 'id') {
						if(data[key]){
							keyStr = key
						}
					}
				})
				let obj = {
					__method_name__:'updateData',
					data_id:data[keyStr], 
					object_uuid: this.element.database.objectData.uuid,
					view_uuid: this.element.database.viewData.uuid,
				}
				if(item.type === 'clearBind'){
					if(this.isBim){
						this.$set(obj,this.bindFieldConfig.bimField,[])
					} else if(this.isGis){
						this.$set(obj,this.bindFieldConfig.gisField,[])
					}
				}else if(item.type === 'bind'){
					if(this.isBim){
						obj = Object.assign(obj,this.chooseBimInfo)
					} else if(this.isGis){
						obj = Object.assign(obj,this.chooseGisInfo)
					}
				}
				dataInterface(obj).then((res) => {
					if(res.data.code === 200){
						let { search = [], param = {}, canPost } = this.initParams();
						if (!canPost) return;
						this.page = 1
						this.listData = []
						this.finished = false
						this.getList(this.element.database, search, param, this.page);
						if(this.isGis){
							this.isOpenGisDraw = false
							eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.gisComponentId);
						}
					}
				})
			}
		},
		//查找未加载的模型id,数组
		findUnloadModel(loadedArr,arr){
			let newArr = [];
			if(loadedArr?.length && arr?.length){
				arr.forEach((item)=>{
					if(!loadedArr.includes(item.model_id)){
						newArr.push(item);
					}
				})
				return newArr
			}
			return newArr;
		},
  /**
   * @description: 打开提示
   * @return {*}
   */		
		openRebindDialog(){
			this.dialogTableVisible = true
			clearInterval(this.timer)
			this.seconds = 3
			this.timer = setInterval(()=>{
				this.$nextTick(()=>{
					if(this.seconds > 0){
						this.seconds--
					} else {
						if(this.seconds === 0){
							this.dialogTableVisible = false
							clearInterval(this.timer)
						}
					}
				})
			},1000)
		},
		/**
		 * @desc: 非模型操作
		 * @param {String} action 操作
		 * @param {Object} output 输出的数据
		 */
		onAction(action, output, index) {
			this.activeIndex = index
			this.itemOutPut = output
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData: output
				},
				containerId: null,
				isModify: true
			});
			const eventConfigs = this.element.actionConfig[action]?.eventList || [];
			for (let i = 0; i < eventConfigs.length; i++) {
					const { eventList = [], pattern = undefined, specialEventList = [] } = eventConfigs[i];
					if (!pattern) {
						eventList.forEach((ele) => {
							if (ele.key === 'click') {
								// 跳转页面
								if (ele.actionType !== 'jumpPage') {
									ele.effects.forEach((effect) => {
										this.$store.commit('triggerEvents', {
											config: {
												...ele,
											...effect
											},
											element: this.element
										});
									});
									
									// 触发组件行为
									const { behaviors } = ele;
									behaviors.forEach(behavior => {
										this.$store.commit('triggerEvents', {
											config: {
												behavior,
												isBehavior: true
											},
											element: this.element
										});
									});
								}
							}
						});
					} else if (pattern === 'special') {
						specialEventList.forEach(ele => {
							const { actionType } = ele;
							if(actionType === 'pageStatus'){
								this.updatePageStatus(ele.stateMachine || [])
							}else	if (actionType === 'pageAction') {
								ele.effects.forEach((effect) => {
									this.$store.commit('triggerEvents', {
										config: {
											...ele,
										...effect
										},
										element: this.element,
										EDITOR_pageUUID: this.EDITOR_pageUUID
									});
								});
							} else if(actionType === 'componentBehavior'){
								//触发组件行为
								setTimeout(()=>{
									if(ele.behaviors?.length){
										ele.behaviors.forEach(behavior => {
											this.$store.commit('triggerEvents', {
												config: {
													behavior,
													isBehavior: true
												},
												ele
											});
										});
									}
								},0)
							}
						});
					}
			}
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			arr.forEach(ele => {
				const { value } = getStatusVal(ele, this.componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		},
		/**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {},page ) {
			this._startDataDebug();
			if (!this.validDatabase(database)) {
				this._failDataDebug('请求配置错误');
				return;
			}
			const paramsObj = {
				...params,
				page: page,
				size: this.pageSize
			};
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj,
					search
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj,
					search
				};
			}
			/* 值绑定设置的获取请求参数 */
			if (database.mobileQeuryInfo && database.mobileQeuryInfo.params && database.mobileQeuryInfo.params.length) {
				const queryObj = this.getQueryStringArgs()
				database.mobileQeuryInfo.params.forEach(element => {
					if (element.component) {
						/* 组件取值 */
						const originComponent = getComponentById(this.componentData, element.component);
						if (originComponent.resolveData) {
							configObj.search.push({
								code: element.key,
								ruleType: element.ruleType,
								value: originComponent.resolveData[element.value]
							})
						}
					} else {
						/* 路由取值 */
						for (const key in queryObj) {
							if (queryObj[element.key] && (element.key === key)) {
								configObj.search.push({
									code: element.key,
									ruleType: element.ruleType,
									value: queryObj[key]
								})
							}
						}
					}
					
				});
			}
      this.loading = true;
			// 获取表格数据
			dataInterface(configObj).then((res) => {
				if (res && res.status === 200) {
					this.metadata = res.data.metadata;
					//编辑器状态下随时更新mateData
					if (!this.isPreview) {
						this.$store.commit('modifyComponent', {
							component: {
								...this.element,
								metadata:res.data.metadata
							},
							containerId: null,
							isModify: true
						});
					}
					let listData = [];
					if (__method_name__ === 'dataList' || __method_name__ === 'relationList') {
						// 列表数据
						listData = this.getListFromRes(res, true) || [];
					}
					for (let i = 0; i < this.fieldConfig.length; i++) {
						const item = this.fieldConfig[i];
						if (item.type === 7) {
							// switch 编辑
							listData = listData.map((ele) => {
								if (item.switchOptions.type === 'number') {
									// 数字
									ele[item.uuid] =
										+ele[item.uuid] === +item.switchOptions.trueValue;
								} else if (item.switchOptions.type === 'boolean') {
									if (ele[item.uuid] === 'true') {
										ele[item.uuid] = true;
									} else if (ele[item.uuid] === 'false') {
										ele[item.uuid] = false;
									}
								} else if (item.switchOptions.type === 'string') {
									ele[item.uuid] =
										ele[item.uuid] === item.switchOptions.trueValue;
								}
								return ele;
							});
						}
					}
					this.listData.push(...listData);
					//首次显示分类名称
					if(configObj.page === 1 && this.element.bindFieldConfig?.typeNameField && this.listData?.length){
						this.typeName = this.listData[0][this.element.bindFieldConfig?.typeNameField]
						this.menuId = this.listData[0][this.element.bindFieldConfig?.typeIdField]
					}
					//获取加载完的模型
					eventBus.$emit('ViewerBIM.getModelIds', this.bimComponentId , (list)=>{
						if(list){
							this.loadedBimIds = [...list]
						}
					});
					// 如果达到了最大条数或者说请求到了空数据将不再请求
					if(res.data.total <= this.listData.length || listData.length === 0) {
						this.finished = true
					}
				}
				this.loading = false;
				this._successDataDebug({
					url: '',
					content: configObj,
					res
				});
			})
			.catch((err) => {
				console.log(err)
				this.loading = false;
				this._errorDataDebug({
					url: '',
					content: configObj,
					err
				});
			});
		},
		/**
		 * @desc: 获取query参数
		 * @param {Object} 
		 */
    getQueryStringArgs() {
      //取得查询字符串并去掉开头的问号
      var qs = location.search.length > 0 ? location.search.substring(1) : "",
      //保存数据的对象
      args = {},
      //取得每一项
      items = qs.length ? qs.split("&") : [],
      item = null,
      name = null,
      value = null,
      //在 for 循环中使用
      i = 0,
      len = items.length;
      //逐个将每一项添加到 args 对象中
      for (i = 0; i < len; i++) {
        item = items[i].split("=");
        name = decodeURIComponent(item[0]);
        value = decodeURIComponent(item[1]);
        if (name.length) {
          args[name] = value;
        }
      }
      return args;
    },
		/**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 * @param {Boolean} withPager 是否需要重置pager
		 */
		getListFromRes(res, withPager = true) {
			if (res.data && Array.isArray(res.data)) {
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data, withPager);
			}
			return [];
		},
		/**
		 * @desc: 获取状态值
		 * @param {any普通类型} value 值
		 * @param {Array} statusOptions 状态选项
		 * @return {Object} 用于状态显示的对象  label value color
		 */
		filterStatus(value, statusOptions = []) {
			if (!statusOptions || !Array.isArray(statusOptions)) {
				return {
					label: value
				};
			}
			if(Array.isArray(value) && value.length && statusOptions.length){
				let str = ''
				for(let i =0;i<value.length; i++){
					for(let j =0;j<statusOptions.length; j++){
						if(+value[i] === +statusOptions[j].value || value[i] === statusOptions[j].value){
							str += '/'+ statusOptions[j]
						}
					}
				}
				return str
				
			}
			for (let i = 0; i < statusOptions.length; i++) {
				if (
					(isNaN(value) && statusOptions[i].value === value) ||
					+statusOptions[i].value === +value
				) {
					return statusOptions[i];
				}
			}
			return {
				label: value
			};
		},
		/**
		 * @description 格式化颜色透明
		 * @param color { String } 颜色
		 * @param op { String } 透明的
		 * @return String 格式化后的颜色
		 */
		formatColorOp(color = '', op = 0.2) {
			if(!color) {
				return ''
			}
			if(color.startsWith('#')) {
				if (color.length === 4) {
					return `RGBA(${Number('0x'+color[1] + color[1])},${Number('0x'+color[2] + color[2])},${Number('0x'+color[3] + color[3])},${op})`
				} else if(color.length === 7) {
					return `RGBA(${Number('0x'+color[1] + color[2])},${Number('0x'+color[3] + color[4])},${Number('0x'+color[5] + color[6])},${op})`
				}
			} else if(color.startsWith(/RGBA/gi)) {
				const colorArr = color.split(',')
				colorArr[colorArr.length - 1] = `${op})`
				return colorArr.join()
			} else if(color.startsWith(/rgb/gi)) {
				return color.substring(0, color.length -1) + `,${op})`
			} else {
				return color
			}
		},
		/**
		 * @desc: 格式化时间
		 * @param {String/Object/Number} value 时间值 兼容时间戳、时间字符串、时间对象
		 * @param {String} timeFormat 格式化
		 * @param {String} connector 连接符
		 */
		filterTime(value, timeFormat, connector = '') {
			if (!value) return '';
			// 时间格式表单无法统一不做校验
			// const reg = /^(\d{4})(-|\/)(\d{2})(-|\/)(\d{2})/;
			// if ((isNaN(+value) && !reg.test(value))) return '';
			let dateObj = null;
			if (value instanceof Date) {
				dateObj = value;
			} else if (isNaN(value)) {
				dateObj = new Date(value);
			} else {
				dateObj = new Date(value * 1000);
			}
			// @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
			if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
			const year = dateObj.getFullYear();
			// 无效的时间
			if (year === 1970) return '';
			let month = this.addZero(dateObj.getMonth() + 1);
			const day = this.addZero(dateObj.getDate());
			const hour = this.addZero(dateObj.getHours());
			const minute = this.addZero(dateObj.getMinutes());
			const second = this.addZero(dateObj.getSeconds());
			let connectorStr = '-';
			if (connector === '/') {
				connectorStr = '/';
			} else if (connector === 'cn') {
				connectorStr = connector;
			}
			const defaultStr = `${year}${
				connectorStr === 'cn' ? '年' : connectorStr
			}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
				connectorStr === 'cn' ? '日' : ''
			} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
				connectorStr === 'cn' ? '分' : ':'
			}${second}${connectorStr === 'cn' ? '秒' : ''}`;
			switch (timeFormat) {
				case 'YMDhms':
					return defaultStr;
				case 'YMDhm':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'YMDh':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ''}`;
				case 'YMD':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					}`;
				case 'YM':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr}${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'MD':
					return `${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'hms':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ':'
					}${second}${connectorStr === 'cn' ? '秒' : ''}`;
				case 'hm':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'yyyy':
					return `${year}${connectorStr === 'cn' ? '年' : ''}`;
				case 'mm':
					return `${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'dd':
					return `${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'weekDay':
					return this.weekDays[dateObj.getDay()];
				case 'week':
					return `第${this.getWeek(dateObj)}周`;
				default:
					return defaultStr;
			}
		},
		/**
		 * @desc: 补零
		 * @param {Number} val 需要补零的值
		 */
		addZero(val) {
			if (isNaN(val) || val < 0 || val >= 10) return val;
			return `0${val}`;
		},
		/**
		 * @desc: 获取当前日期对象为全年第几周
		 * @param {Object<Date>} dateObj 时间对象
		 * @return {Number} 第几周
		 */
		getWeek(dateObj) {
			const firstDay = new Date(`${dateObj.getFullYear()}-1-1`);
			const timeBetween = dateObj - firstDay;
			const between = timeBetween / (7 * 24 * 60 * 60 * 1000);
			return Math.ceil(between);
		},

		/**
		 * @desc: 暴露的删除行为
		 */
		rowDel(){
			MessageBox.confirm('是否确认删除该条数据?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				Object.keys(this.metadata).map((key) => {
					if (this.metadata[key] === 'id') {
						dataInterface({
							__method_name__:'deleteData',
							data_id:this.itemOutPut[key], 
							object_uuid: this.element.database.objectData.uuid,
							view_uuid: this.element.database.viewData.uuid,
						}).then((res) => {
							if(res.data.code === 200){
								let { search = [], param = {}, canPost } = this.initParams();
								if (!canPost) return;
								this.page = 1
								this.listData = []
								this.finished = false
								this.getList(this.element.database, search, param,this.page);
							}
						})
					}
				})
				
			});
		},
  /**
   * @description: 创建标记(行为)
   * @return {*}
   */		
		creatMarker(param,behaviorsParam){
			let str = 'null'
			let toComponent = behaviorsParam.filter(v => v.key === 'toComponent')
			if(toComponent?.length && toComponent[0].componentId){
				str = toComponent[0].componentId
			}
			if(!param || !param.data_id && this.element.database.viewData.uuid ){
				return
			}
			eventBus.$emit("ViewerBIM.getBoundingSphere", this.bimComponentId, param.position[0].dbids[0],param.position[0].model_id, async (res) => {
				let point = await res
				let marker = {id:this.element.database.viewData.uuid+ '/' + str + '/' + param.data_id,content:param.content,position:point.center, visible: true}
				eventBus.$emit("ViewerBIM.Marker.Add",this.bimComponentId,marker)
			})
		},
  /**
   * @description: 定位到缩放到构件
   * @return {*}
   */		
		onFlyTo(param){
			if(param.model_info?.length){
				eventBus.$emit('ViewerBIM.fitToView',this.bimComponentId, param.model_info)
			}
		},
		/**
		 * @desc: 暴露的添加视点行为
		 */
		onAddView(param){
			if(!param || !param.data_id){
				return
			}
			eventBus.$emit('ViewerBIM.ViewState.getState', this.bimComponentId, res => {
				let obj = {
					__method_name__:'updateData',
					data_id: param.data_id, 
					object_uuid: this.element.database.objectData.uuid,
					view_uuid: this.element.database.viewData.uuid,
				}
				let bimInfo = this.chooseBimInfo[this.bindFieldConfig.bimField].map((val)=>{
					return {
						...val,
						viewState: res
					}
				})
				this.chooseBimInfo[this.bindFieldConfig.bimField] = bimInfo
				obj = Object.assign(obj,this.chooseBimInfo)
				dataInterface(obj).then((res) => {
					if(res.data.code === 200){
						let { search = [], param = {}, canPost } = this.initParams();
						if (!canPost) return;
						this.page = 1
						this.listData = []
						this.finished = false
						this.getList(this.element.database, search, param, this.page);
					}
				})
			});
		},
		/**
		 * @desc: 暴露的视点回显行为
		 */
		onShowView(param){
			if(param.model_info?.length && param.model_info[0].viewState){
				eventBus.$emit('ViewerBIM.ViewState.RestoreState', this.bimComponentId, param.model_info[0].viewState);
			}
		},
		/**
		 * @desc: 暴露的模型着色行为
		 */
		onSetColor(param){
			if(param.model_info?.length && param.status){
				//清除颜色
				param.model_info.forEach((item)=>{
					eventBus.$emit('ViewerBIM.Part.SetColor', this.bimComponentId, item.dbids, param.status, item.model_id);
				})
				//清除选中状态
				setTimeout(()=>{
					eventBus.$emit('ViewerBIM.setSelect', this.bimComponentId, []);
				},1)
			}
		},
	},
	beforeDestroy() {
		eventBus.$off('databaseTrigger');
		eventBus.$off('refreshConsoleList');
		eventBus.$off('doComponentBehavior',this.element.id);
		window.removeEventListener('wheel', this.handleMouseWheel)
	}
}
</script>
<style lang="less" scoped>
.scroll-list-cont {
		backdrop-filter: blur(4px);
    padding: 0 !important;
    height: 100%;
		overflow: hidden;
    width: 100%;
		.group-cont{
			height: 38px;
			line-height: 38px;
			border-radius:4px;
			background:rgba(0,183,255,0.3);
			font-size:14px;
			text-align: left;
			margin:12px;
			display: flex;
			flex-direction: row;
			.label-cont{
				position:relative;
				i{
					margin: 8px;
				}
				.type-input{
					background: rgba(255,255,255,0.1);
					padding: 5px 10px;
					border-radius: 2px;
				}
				.type-btn{
					height: 25px;
					border-radius: 2px;
					background: var(--el-color-primary);
					padding: 0 8px;
					margin-left: 5px;
					font-size: 12px;
					position: absolute;
					right: 2px;
					top: 7px;
					line-height: 25px;
					display: inline-block;
				}
			}
			.right-handle{
				flex: 1;
				text-align: right;
				margin-right: 8px;
				display: none;
			}
		}
		.group-cont:hover,.label-cont:hover{
			.right-handle{
				display:block;
			}
		}
		.infinite-cont{
			height: calc(100% - 62px);
			// height:100%;
			overflow-y: auto;
		}
		.infinite-cont::-webkit-scrollbar {
			display: none;
		}
    .infinite-list-item:hover, .img-bg-mode:hover{
				box-shadow: 0px 0px 2px 2px var(--el-color-primary);
				.item-handle-warp{
					display: block;
				}
    }
    .infinite-list-item {
				position: relative;
        height: auto;
        padding: 12px 8px;
        display: flex;
        flex-direction: row;
				margin: 5px 12px 15px 12px;
				border-radius: 6px;
        .item-img-warp{
				margin-right: 5px;
				.el-image{
					margin: 0 5px;
					border-radius: 6px;
				}
			}
			.item-handle-warp{
				display: none;
				width: auto;
				i{
					background:rgba(0, 0, 0, 0.45);
					border-radius: 2px;
					color:inherit;
					margin-left: 10px;
				}
			}
			.item-text-warp{
				flex-grow: 1;
				width: 100px;
				.text-item{
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: left;
					line-height: 22px;
					font-size: 12px;
					opacity:0.6;
				}
				.title-text{
					font-size: 14px;
					opacity:1;
					.value-style{
						padding: 0px!important;
					}
				}
			}
			& + .list-item{
				margin-top: 10px;
			}
    }
		#firstRow{
			margin-top: 2px;
		}
		.img-bg-mode{
			position: relative;
			height: 140px;
			padding: 12px 5px;
			margin: 0px 12px 15px 12px;
			border-radius: 8px;
			.status-filed{
				position: absolute;
				left: 10px;
				font-size: 12px;
				padding: 0px 10px;
				width: auto;
				display: inline-block;
				border-radius: 20px;
				height: 25px;
				line-height: 25px;
			}
			.item-text-warp{
				width: calc(100% - 20px);
				height: auto;
				color:#fff;
				background:	rgba(0, 0, 0, 0.45);
				position:absolute;
				bottom: 6px;
				left: 0;
				padding:5px 10px;
				.text-item{
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: left;
					font-size: 12px;
					opacity:0.6;
					line-height: 25px;
				}
				.title-text{
					font-size: 14px;
					opacity:1;
					.value-style{
						padding: 0px!important;
					}
				}
			}
			.item-handle-warp{
				position: absolute;
				right: 10px;
				top: 10px;
				width: auto;
				display: none;
				i{
					background:rgba(0, 0, 0, 0.45);
					border-radius: 2px;
					color:inherit;
					margin-left: 10px;
				}
			}
		}

}
:deep(.el-dropdown){
	color: inherit;
}
.loading-cont {
    border: none !important;
    text-align: center;
    padding: 10px 16px;
    color: inherit;
}
.loading-cont p {
    font-size: 12px;
    margin: 3px 0 0 0;
}
.loading-cont span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid inherit;
    border-left: transparent;
    animation: zhuan 0.5s linear infinite;
    border-radius: 50%;
}
.no-more {
    width: 100%;
    text-align: center;
    margin: 30px 0;
    font-size: 12px;
    color: inherit;
}
@keyframes zhuan {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.flex1 {
    flex: 1;
    overflow: hidden;
}
.bottom-icons{
	text-align: left;
	height: auto;
	line-height: 18px;
	.item{
		margin-right:10px;
	}
	i{
		font-size:18px;
		color:inherit;
	}
	.icon-opacity{
		opacity: 0.3!important;
	}
}
.value-style{
  border-radius: 15px;
}
.show-detail-btn{
	color: var(--el-color-primary);
	margin-left:10px;
}
</style>