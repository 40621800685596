<!--
    @name: ques-indefinite
    @description：ques-indefinite
    @author: ZengWei
    @date: 2022-04-01 11:18
-->
<template>
  <div class="widget-video">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[评分评价]</span>
        {{ element.config.__config__.label }}
      </div>
      <div
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc"
        class="q-title">
      </div>
    </div>
    <div class="rate-container">
      <p class="rate-inner-container">
        <el-rate v-model="element.value.rate" class="rate-inner-container" />
      </p>
      <p v-if="currentDesc" class="rate-desc">
        “{{ currentDesc }}”
      </p>
      <p class="rate-label">
        <template v-for="(item, sindex) in labels">
          <span :key="sindex" class="label-item" :class="isChecked(item) ? 'label-checked' : ''" @click="handleLabelItem(item)">{{ item }}</span>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";
import { Rate } from 'element-ui'
export default {
  name: "ques-rate",
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  components: {
    'el-rate': Rate
  },
  data() {
    return {}
  },
  computed: {
     currentDesc() {
      return this.element.config.__slot__.rateDesc[this.element.value.rate - 1 < 0
        ? 0 : this.element.value.rate - 1] || ''
    },
    labels() {
      const data = this.element.config.__slot__.rateLabel
      return data.split('\n').filter(el => el !== '')
    },
    currentComment() {
      return this.element.comment || []
    }
  },
  methods: {
    isChecked(item) {
       if (this.element.value.comment && Array.isArray(this.element.value.comment)) {
        const index = this.element.value.comment.indexOf(item)
        if (index > -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    handleLabelItem(item) {
      if (this.element.value.comment && Array.isArray(this.element.value.comment)) {
        const index = this.element.value.comment.indexOf(item)
        if (index > -1) {
          this.element.value.comment.splice(index, 1)
        } else {
          this.element.value.comment.push(item)
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
.widget-video{
  .rate-container{
    margin: 6px 0 0 32px;
    .rate-inner-container{
      height: 36px;
      :deep(.el-rate__icon) {
        font-size: 32px;
      }
    }
    .rate-desc{
      color: rgb(247, 186, 42);
    }
    .rate-label{
      .label-item{
        padding: 5px 10px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        color: #888;
        border-radius: 5px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        & + .label-item{
          margin-left: 10px;
        }
      }
      .label-checked{
        border-color: var(--el-color-primary);
        color: var(--el-color-primary);
      }
    }
  }
}
</style>
