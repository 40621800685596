/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-14 16:13:53
 * @FilePath: \dataview-next\src\custom-component\tree\lang\en.js
 */
export default {
  "tree.CommonTree.463131-0": "Before",
  "tree.CommonTree.463131-1": "Delete",
  "tree.CommonTree.463131-2": "Parent ID",
  "tree.CommonTree.463131-3": "Component [{0}] data interface does not return an [id] field, please check the interface configuration!",
  "tree.CommonTree.463131-4": "Component [{0}] data interface returns [{1}] [id] fields, please check the interface configuration to ensure accuracy!",
  "tree.CommonTree.463131-5": "Event configuration error!",
  "tree.CommonTree.463131-6": "Operation successful!",
  "tree.CommonTree.463131-7": "Are you sure you want to delete?",
  "tree.CommonTree.463131-8": "Tip",
  "tree.CommonTree.463131-9": "OK",
  "tree.CommonTree.463131-10": "Cancel",
  "tree.CommonTree.463131-11": "Exporting...",
  "tree.CommonTree.463131-12": "Export failed! Data validation error.",
  "tree.CommonTree.463131-13": "No data available for export!",
  "tree.CommonTree.463131-14": "Export configuration error!",
  "tree.CommonTree.463131-15": "Export successful!",
  "tree.CommonTree.463131-16": "Export failed!"
}