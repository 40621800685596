<!--
 * @Author: 姚铃 1871883713@qq.com
 * @Date: 2023-08-16 17:21:55
 * @LastEditors: 姚铃 1871883713@qq.com
 * @LastEditTime: 2023-08-17 13:56:19
 * @FilePath: \dataview-next\src\custom-component\task-flow\property\TaskSidebar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="task-sidebar">
		<section class="information-card-box">
			<div class="information-card">
<!--				<div class="information">
					<h1>一工区#A标段</h1>
					<div>罗铁测试项目</div>
				</div>
				<el-button>切换</el-button>-->
			</div>
		</section>
    <div class="single-line"></div>
		<template v-if="taskMode === 'designer'">
			<div class="tag-title">
				<p>任务图筛选</p>
				<div class="add-tag">
<!--					<i class="iconfont iconjiacuxinzeng"></i>-->
				</div>
			</div>
			<div class="search-container">
        <el-select
          style="width: 100%"
          v-model="searchValue"
          filterable
          clearable
          multiple
          @change="changeType"
        >
          <el-option
            v-for="(item, index) in taskList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
			</div>
      <div class="single-line"></div>
      <div class="tag-title">
        <p>图块模板库</p>
      </div>
      <div class="search-container">
        <el-input
          class="search-input"
          placeholder="搜索"
          prefix-icon="el-icon-search"
          v-model="searchCellValue"
          @input="filterTaskCell">
        </el-input>
      </div>
      <div class="task-display" v-loading="loading">
        <div
          class="item"
          v-for="(item, index) in taskImageList"
          :key="index"
        >
          <i class="iconfont iconshanchulajitong action-btn" @click.stop="del(item.id, index)"></i>
          <div @mousedown="insertCell($event, item)">
            <div class="image">
              <img :src="item.cell_img" alt="" />
            </div>
            <div class="title">{{ item.cell_name }}</div>
          </div>
        </div>
      </div>
      <div class="task-image-total">图块总数: {{ taskImageList.length }}</div>
		</template>
    <!-- 任务图分析 -->
    <template v-else>
			<TaskGraphAnalysis />
		</template>
	</div>
</template>

<script>
import TaskGraphAnalysis from '@/custom-component/task-flow/property/TaskGraphAnalysis.vue'
import {dataInterface} from "@/apis/data";
export default {
	name: 'TaskSidebar',
	components: {
    TaskGraphAnalysis
  },
	props: {
    taskMode: {
			type: String
		},
		listTitle: {
			type: String
		}
	},
	data() {
		return {
			searchValue: '',
      searchCellValue: '',
			chooseTagId: null,
			taskImageList: [],
			taskImageListCopy: [],
			taskList: [],
			taskListCopy: [],
      loading: false
		};
	},
	created() {
    this.initList()
    this.initCellTemplate()
  },
	mounted() {},
	methods: {
    refresh(){
      this.searchValue = ''
      this.initCellTemplate()
    },
    insertCell(e, item){
      const cellData = item.cell_data
      delete cellData.id
      if(cellData.data?.id){
        delete cellData.data.id
      }
      this.$emit('insert-cell',e, cellData)
    },
    del(id,index){
      this.$confirm('此操作将删除该图块模板, 是否继续?', '提示', {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          object_uuid:'object64e08189ea543',
          view_uuid:'view64e2bd6c751d7',
          __method_name__: "deleteData",
          transcode: 0,
          data_id: id
        }
        dataInterface(params).then(()=>{
          this.taskImageList.splice(index,1)
        })
      }).catch(() => {
        // nothing todo
      });
    },
    filterTaskCell(val){
      this.taskImageList = this.taskImageListCopy.filter(t=>t.cell_name.indexOf(val) > -1)
    },
    initList(){
      const params = {
        object_uuid: "object628619c716579",
        view_uuid: "view649e84a5b6541",
        __method_name__: "dataList",
        transcode: 0
      }
      dataInterface(params).then(res=>{
        this.taskList = res.data.data
        this.taskListCopy = this.taskList
      })
    },
    initCellTemplate(search = []){
      const params = {
        object_uuid:'object64e08189ea543',
        view_uuid:'view64e2bd6c751d7',
        __method_name__: "dataList",
        transcode: 0,
      }
      if(search.length) {
        params.search = search
      }
      dataInterface(params).then(res=>{
        this.taskImageList = res.data.data
        this.taskImageListCopy = res.data.data
        this.loading = false
      })
    },
    changeType(val){
      this.loading = true
      if(val.length){
        let search = [{code: 'task_graph_id',ruleType: 'in',value: val}]
        this.initCellTemplate(search)
      } else {
        this.initCellTemplate()
      }
    }
  }
};
</script>

<style lang="less" scoped>
.task-sidebar {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 224px;
	height: 100%;
	background: rgba(255, 255, 255, 1);
	padding: 8px 0;
	box-sizing: border-box;
	border-radius: 8px 0px 0px 8px;
	display: flex;
	flex-direction: column;
	.information-card-box {
		display: flex;
		justify-content: center;
		position: relative;
		width: 200px;
		height: 64px;
		margin: 4px 12px 12px 12px;
		background-image: url('https://openim-1309784708.cos.ap-shanghai.myqcloud.com/5b8bba0f243779e7cd9ac5780cb27e97.png');
		.information-card {
			display: flex;
			justify-content: space-between;
			width: 198px;
			height: 58px;
			margin-top: 1px;
			padding: 10px 12px;
			box-sizing: border-box;
			background: rgba(255, 255, 255, 1);
			border-radius: 5px;
			.information {
				h1 {
					color: rgba(24, 27, 34, 1);
					font-family: var(--systemFontFamily);
					font-size: 14px;
					font-weight: 500;
					line-height: 22px;
					letter-spacing: 0.5px;
					text-align: left;
				}
				div {
					font-family: var(--systemFontFamily);
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: 0.4000000059604645px;
					text-align: left;
					color: rgba(112, 119, 134, 1);
				}
			}
			.el-button {
				width: 41px;
				height: 20px;
				padding: 0px 8px 0px 8px;
				border-radius: 4px;
				background-color: rgba(242, 247, 255, 1);
				border: none;
				color: rgba(56, 127, 252, 1);
				font-family: var(--systemFontFamily);
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: 0.4000000059604645px;
				text-align: left;
			}
		}
	}
	.list-content {
		overflow: auto;
    width: 100%;
		height: 312px;
		padding: 0 15px;
		box-sizing: border-box;
	}
	.task-display {
		display: grid;
    overflow: auto;
		grid-auto-flow: row dense;
		grid-template-columns: repeat(2, 100px);
		grid-template-rows: 122px;
		grid-gap: 8px 6px;
		padding: 0 8px;
    padding-bottom: 34px;
		box-sizing: border-box;
		border-radius: 6px;
		.item {
			width: 100%;
			height: 100%;
      position: relative;

      &:hover {
        .action-btn{
          display: block;
        }
      }

      .action-btn {
        display: none;
        position: absolute;
        top: 5px;
        right: 3px;
      }
			.image {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100px;
				border-radius: 6px;
				background-color: rgba(244, 246, 249, 1);
				img {
					width: 48px;
					height: 64px;
				}
			}
			.title {
				padding-top: 4px;
				box-sizing: border-box;
				color: rgba(66, 71, 81, 1);
				font-family: var(--systemFontFamily);
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: 0.4000000059604645px;
				text-align: center;
				white-space: nowrap;
				max-width: 100px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	.task-image-total {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: 8px;
		width: 208px;
		height: 28px;
		padding: 0 8px;
		box-sizing: border-box;
		background-color: rgba(242, 247, 255, 1);
		color: rgba(56, 127, 252, 1);
		font-family: var(--systemFontFamily);
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.4000000059604645px;
		text-align: center;
	}
	p {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 32px;
		line-height: 32px;
		padding: 0 8px;
		box-sizing: border-box;
		background: rgba(255, 255, 255, 0.01);
		box-sizing: border-box;
		font-family: 'PingFang SC';
		font-style: normal;
		font-weight: 550;
		font-size: 12px;
		margin-bottom: 4px;
		box-sizing: border-box;
		padding: 0 12px;
		justify-content: space-between;
	}
	.list-item {
		width: 100%;
		height: 32px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		border-radius: 2px;
		box-sizing: border-box;
		padding: 5px 12px;
		margin-bottom: 4px;
		i {
			width: 14px;
			height: 14px;
			margin-right: 8px;
		}
		span {
			font-family: var(--systemFontFamily);
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0.5px;
			text-align: left;
			color: rgba(24, 27, 34, 1);
			white-space: nowrap;
			max-width: 184px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.list-item:hover {
		background: #f4f6fa;
	}
	.active {
		background: #f4f6fa;
		i {
			color: #337eff;
		}
		span {
			font-weight: 550;
			color: #337eff;
		}
	}
	.tag-title {
		width: 100%;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 4px;
		box-sizing: border-box;
		p {
			padding-left: 4px;
			box-sizing: border-box;
			color: rgba(24, 27, 34, 1);
			font-family: var(--systemFontFamily);
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0.5px;
			text-align: left;
		}
		.add-tag {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			margin-right: 4px;
			i {
				font-size: 16px;
				color: #41474c;
			}
		}
		.add-tag:hover {
			cursor: pointer;
			background: #eff1f5;
			border-radius: 4px;
		}
		.add-tag:active {
			cursor: pointer;
			background: #c1c7cd;
			border-radius: 4px;
		}
	}
	.search-container {
		width: 100%;
		height: auto;
		padding: 8px;
		box-sizing: border-box;
		.search-input {
			height: 32px;
			:deep(.el-input__inner) {
				height: 32px;
				border: none;
			}
			:deep(.el-input__inner, .el-textarea__inner) {
				background-color: rgba(244, 246, 249, 1);
			}
		}
	}
	.single-line {
		width: 100%;
		border-top: 1px solid #eff1f5;
	}
	.tag-container {
		flex: 1;
		width: 100%;
		overflow: auto;
		box-sizing: border-box;
		padding: 0 8px;
		.select-tag {
			width: 100%;
			height: 30px;
			margin: 4px 0;
		}
		:deep(.el-select-dropdown.is-multiple .el-select-dropdown__item) {
			text-align: left;
		}
	}
}
:deep(.el-select-dropdown) {
	.selected {
		color: #337eff;
		span {
			color: #337eff;
		}
	}
}
.edit-menu {
	width: 60px;
	height: auto;
	box-sizing: border-box;
	position: fixed;
	border-radius: 6px;
	background: #ffffff;
	box-shadow: 0px 4px 12px -1px rgba(22, 28, 31, 0.08), 0px 0px 2px rgba(22, 28, 31, 0.04);
	z-index: 1;
	padding: 8px 0;
}
</style>
