<!--
    @name: KnowDetail
    @description：KnowDetail
    @author: ZengWei
    @date: 2022-04-28 15:23
-->
<template>
  <div class="">
    <el-form class="details-form" label-width="80">
      <div class="type-label">
        基本信息
      </div>
      <el-form-item label="页面名称:">
        {{ data.name ? data.name : '-' }}
      </el-form-item>
    </el-form>
    <FlowRecover
      v-if="editData"
      :key="keyID1"
      :canvas-data="editData.recover"
      :logs="editData.logs"
    />
    <FlowOperate
      v-if="editData"
      :key="keyID2"
      :edit-data="editData"
      @change-end="refresh"
    />
  </div>
</template>

<script>
import FlowRecover from "@/custom-component/form/formParser/flowEngine/components/FlowRecover";
import FlowOperate from "@/custom-component/form/formParser/flowEngine/components/FlowOperate";
import {flowEngine} from "@/apis/data/workflow";

export default {
  name: "KnowDetail",
  components: {
    FlowRecover,FlowOperate
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      editData: null,
      keyID1: new Date().getTime() + 'a',
      keyID2: new Date().getTime() + 'b',
    }
  },
  created() {
    this.getModule()
  },
  methods: {
    refresh(){
      this.$emit('refresh')
      this.getModule()
      this.keyID1 = new Date().getTime() + 'a'
      this.keyID2 = new Date().getTime() + 'b'
    },
    getModule(){
      const params = { module: 'repository_page' }
      flowEngine.flowDataDetail(this.data.id, params).then((res) => {
        if (res.data.code === 200) {
          this.editData = res.data.data
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.type-label {
  border-left: 3px solid var(--themeColor)!important;
  padding: 0 10px;
  line-height: 1;
  font-size: 14px;
  margin: 0 0 16px;
  font-weight: 600;
}

:deep(.details-form) {
  .el-form-item{
    border-top: 1px solid #f5f5f5;

    // display: block;
    .el-form-item__label{
      color: #445E96;
    }

  }
}
</style>
