/*
 * @Author: hw
 * @Date: 2024-03-08 15:46:06
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-27 11:03:24
 * @FilePath: \dataview-viewer-test\src\locale\index.js
 */
import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import locale from "element-ui/lib/locale";
import zh from "./lang/zh";
import en from "./lang/en";

import container from "@/custom-component/container/lang/index";
import login from "@/custom-component/login/lang/index";
import button from "@/custom-component/button/lang/index";
import form from "@/custom-component/form/lang/index";
import tree from "@/custom-component/tree/lang/index";
import functionCom from "@/custom-component/functionCom/lang/index";
import weather from "@/custom-component/weather/lang/index";
import flow from "@/custom-component/flow/lang/index";
import netDisk from "@/custom-component/netDisk/lang/index";
import charts from "@/custom-component/charts/lang/index";
import special from "@/custom-component/special/lang/index";
import bindLatLng from "@/custom-component/bindLatLng/lang/index";
import dialog from "@/custom-component/dialog/lang/index";
import todo from "@/views/todo/lang/index";

import newSecond from "@/views/newSecond/lang/index";
import userCenter from "@/views/userCenter/lang/index";
import todoList from "@/components/todoList/lang/index";
import appEntry from "@/views/appEntry/lang/index";
import Login from "../views/login/jida/lang/index";
import fileUpload from "@/components/fileUpload/lang/index";
import apis from "@/apis/lang/index";

Vue.use(VueI18n);

const messages = {
  zh: {
    ...zh,
    ...zhLocale,
    ...container.zh,
    ...newSecond.zh,
    ...userCenter.zh,
    ...login.zh,
    ...button.zh,
    ...form.zh,
    ...tree.zh,
    ...functionCom.zh,
    ...weather.zh,
    ...todoList.zh,
    ...appEntry.zh,
    ...Login.zh,
    ...fileUpload.zh,
    ...apis.zh,
    ...flow.zh,
    ...netDisk.zh,
    ...charts.zh,
    ...special.zh,
    ...bindLatLng.zh,
    ...dialog.zh,
    ...todo.zh,
  },
  en: {
    ...en,
    ...enLocale,
    ...container.en,
    ...newSecond.en,
    ...userCenter.en,
    ...login.en,
    ...button.en,
    ...form.en,
    ...tree.en,
    ...functionCom.en,
    ...weather.en,
    ...todoList.en,
    ...appEntry.en,
    ...Login.en,
    ...fileUpload.en,
    ...apis.en,
    ...flow.en,
    ...netDisk.en,
    ...charts.en,
    ...special.en,
    ...bindLatLng.en,
    ...dialog.en,
    ...todo.en,
  },
};

const preferredLang = localStorage.getItem("preferred_lang");
let lang =
  preferredLang ||
  (navigator.language || navigator.browserLanguage).toLowerCase();

const i18n = new VueI18n({
  messages,
  locale: lang.substr(0, 2),
});

// element语言动态切换
locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
