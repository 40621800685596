<!--
    @name: PcParser
    @description：PcParser
    @author: ZengWei
    @date: 2022-03-29 09:30
-->
<template>
  <div>
    <div v-if="skeleton" class="skeleton">
      <skeleton></skeleton>
    </div>
    <div v-else>
      <el-form
        ref="formParser"
        size="medium"
        :class="{'form-parser':tableStyle,'normal-parser': !tableStyle}"
        :model="formValidate"
        :show-message="!tableStyle"
        :label-width="formData.labelWidth+'px'"
        :label-position="formData.labelPosition"
      >
        <div v-if="formLabel" class="type-label">{{ formLabel }}</div>
        <el-row :gutter="gutter">
          <el-col
            v-for="(item, index) in formValidate.items"
            :span="item.config.__config__.span"
            :key="index"
          >
            <!--兼容隐藏控件的交互事件-->
            <template
              v-if="['radio','switch','select'].indexOf(item.type) >-1 && !showHideField(item.config.__config__,item)">
              {{ initTrigger(item) }}
            </template>

            <template
              v-if="['form','row','table','tab','steps','text'].includes(item.type)
                && showHideField(item.config.__config__,item)">
              <InputItem
                ref="childParser"
                :element="item"
                :disabled="canEditField(item)"
                :editFields="editFields"
                :isConsole="isConsole"
                :formData="formConf"
                :displayData="displayData"
                :contentConfig="contentConfig"
                :dataIds="dataIds"
                :formMode="formMode"
                @trigger-active="triggerActive(arguments)"
                @auto-fill-relate="selectAutoFillRelate"
              >
              </InputItem>
            </template>

            <template v-else-if="showHideField(item.config.__config__,item)">
              <el-form-item
                class="no-padding"
                :prop="'items.' + index + '.value'"
                :label="item.config.__ques__ ? '' : $getLocalizedLabel({'zhLabel': item?.config.__config__.label, 'enLabel': item?.config.__config__.enLabel})"
                :rules="item.validate"
                :label-width="labelWidth(item)"
              >
                <InputItem
                  ref="childParser"
                  :element="item"
                  :isConsole="isConsole"
                  :disabled="canEditField(item)"
                  :editFields="editFields"
                  :formData="formConf"
                  :displayData="displayData"
                  :contentConfig="contentConfig"
                  :dataIds="dataIds"
                  :formMode="formMode"
                  @trigger-active="triggerActive(arguments)"
                  @auto-fill-relate="selectAutoFillRelate"
                >
                </InputItem>
              </el-form-item>
            </template>
          </el-col>
        </el-row>

        <div class="parser-tp">
          <slot name="other"></slot>
        </div>

        <div class="parser-tp">
          <template v-if="needFlow">
            <template v-if="formMode === 'add'">
              <!--添加界面流程-->
              <FlowPicker
                type="self"
                :module="moduleUuid"
                :flow-edit="editFieldsValue"
                @checked-users="dealWithPicker"
                @selected-step="getSelectStep"
              />
            </template>
            <template v-else-if="formMode === 'detail'">
              <!--详情界面流程-->
              <FlowRecover
                v-if="Object.keys(displayData).length"
                :canvas-data="displayData.recover"
                :logs="displayData.logs"
                :next-step="displayData.nextStep"
              />
              <FlowOperate
                v-if="Object.keys(displayData).length && displayData.btn && displayData.btn.length"
                :edit-data="displayData"
                :flow-edit="editFieldsValue"
                @change-end="onRefresh"
              />
            </template>
          </template>
        </div>

        <div class="parser-tp">
          <slot name="button"></slot>
        </div>

        <div v-if="!hideBtn" style="text-align: right;margin-top: 15px;">
          <el-button type="default" @click="handleReset" style="margin-right: 10px;">取消</el-button>
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import parserMethods from "@/custom-component/form/newParser/scripts/parserMethods";
import skeleton from "@/custom-component/form/newParser/skeleton";
import FlowPicker from "@/custom-component/form/formParser/flowEngine/components/FlowPicker";
import FlowOperate from "@/custom-component/form/formParser/flowEngine/components/FlowOperate";
import FlowRecover from "@/custom-component/form/formParser/flowEngine/components/FlowRecover";
import {getNowTime} from "@/utils/tools";

let timer // 多次触发只执行最后一次定时器
export default {
  name: "PcParser",
  components: {
    skeleton, FlowPicker, FlowRecover, FlowOperate,
  },
  props: {
    //可编辑字段
    editFields: {
      type: [Object, Array],
      default: () => {
        return {}
      },
    },
    // 显示验证错误信息
    showMessage: {
      type: Boolean,
      default: false,
    },
    // 隐藏默认按钮
    hideBtn: {
      type: Boolean,
      default: true,
    },
    // 表单禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 表单设计数据
    formData: {
      type: Object,
    },
    // 表单回显数据
    displayData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 表单回显数据
    isConsole: {
      type: Boolean,
      default: false,
    },
    // 架构限制
    nowArchiType: {
      type: [String, Number],
      default: '',
    },
    // 表单模式-新增/编辑
    formMode: {
      type: String,
      default: 'add',
    },
    // 表单填充数据
    fillData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 任务表单填充数据
    otherParam: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否开启流程
    needFlow: {
      type: Boolean,
      default: false,
    },
    // 表单唯一标识--用于流程
    moduleUuid: {
      type: String,
      default: '',
    },
    excelMetaData: {
      type: null,
      default: null,
    },
    contentConfig: {
      type: null,
      default: null,
    },
    dataIds: {
      type: null,
      default: null,
    }
  },
  provide() {
    return {
      parser: this,
    }
  },
  data() {
    return {
      currentInfo: {},
      skeleton: true,
      formConf: {
        tableStyle: this.formData?.tableStyle || false,
        labelWidth: this.formData?.labelWidth,
        labelPosition: this.formData?.labelPosition,
        fields: this.formData?.fields,
      },
      formValidate: {
        items: [],
      },
      tdElement: null,
      validator: false,
      formSubmitData: null,
      relateData: [],
      relateItemInd: '',
      relateVisible: false,
      relateConfig: null,
      relateObjectUUid: null,
      relateViewUUid: null,
      relateFilterRule: [],
      formLabel: '',
      tableStyle: false,
      // 流程数据
      selectedUsers: [],
      checkPickerNum: 0,
      selectStep: '',
      flowEngineId: 0,
      // 外部表单
      outerFormIndex: null,
      outerFormData: null,
      outerNeedFlow: false,
      outerFormUuid: '',
      outerObjectUuid: '',
      outerFormMode: 'add',
      outerFormDisplay: {},
      outerFormType: 1,
      outerFormShowType: 1,
      outerFormVisible: false,
      outerFormTitle: '外部表单',
      editFieldsValue: {}, //流程中编辑表单值
      ctrlEditable: {}
    };
  },
  computed: {
    gutter() {
      if (this.tableStyle) {
        return 0
      } else {
        return this.formData.gutter || 15
      }
    },
    labelWidth() {
      return function (element) {
        if (element.config.__ques__) {
          return '0px'
        }
        if (!element.config.__config__.label) {
          return '0px;'
        }
        const width = element.config.__config__.labelWidth
          ? element.config.__config__.labelWidth + 'px'
          : 120 + 'px'
        return width
      }
    },
    initTrigger() {
      return function (element) {
        let value = element.value;
        if (element.type === 'steps') {
          value = element.config.__config__.activeName;
        }
        this.triggerEventCtrl(element, value)
      };
    },
    showLabel() {
      return function (config) {
        if (config.showLabel === undefined || config.showLabel === false)
          return "";
        return config.label;
      };
    },
    canEditField() {
      return function (item) {
        let formId = item.config.__config__.formId
        let editFormId = Object.keys(this.editFields)
        let ctrlFormId = Object.keys(this.ctrlEditable)
        editFormId = editFormId.concat(ctrlFormId)
        const editFields = {...this.editFields, ...this.ctrlEditable}
        if (editFormId.length > 0 && editFormId.includes(formId)) {
          if(['radio','select','switch'].includes(item.type)) {
            if(item.config.__config__?.interact?.effect === 'showhide'){
              const condition = item.config.__config__.interact.condition
              for (const item of condition) {
                for (const ele of item.comp) {
                  this.ctrlEditable[ele] = editFields[formId]
                }
              }
            }
          }
          return editFields[formId]
        }
        return this.disabled;
      };
    },
    showHideField() {
      return function (config) {
        //中控台表单将bim,gis 附件等数据提出去,不在表单里显示
        if (['upload','bimList','gisList','modelView'].includes(config.tagIcon) && this.isConsole) {
          return false
        }
        //中控台表单将bim,gis 附件等数据提出去
        const formId = config.formId;
        const editFormId = Object.keys(this.editFields);
        if (editFormId.includes(formId)) return true;
        return config.addShow;
      };
    }
  },
  watch: {
    formValidate: {
      handler() {
        const that = this
        clearTimeout(timer);
        timer = setTimeout(() => {
          that.carryCalculate();
          that.editFieldsInput();
        }, 100)
        // if(Object.keys(this.editFields).length){
        //   this.editFieldsInput(); // 改变即获取流程中编辑字段
        // }
        // this.setDynamicData();
      },
      deep: true,
    }
  },
  created() {
    window.calcField = this.getFieldValue.bind(this)

    //附加当前填充数据 -- 当前用户，当前时间，当前项目
    const userInfo = this.$GetUserInfo()
    const archiInfo = this.$GetTargetArchi()
    const nowData = getNowTime(1);
    const nowDataTime = getNowTime(2);
    const nowTime = getNowTime(3);
    this.currentInfo = {
      user_id: userInfo.id,
      user_name: userInfo.name,
      archi_id: archiInfo.archi_id,
      archi_name: archiInfo.name,
      company_id: archiInfo.company_id,
      archi_type: archiInfo.archiType,
      section_id: archiInfo.sectionId,
      section_name: archiInfo.sectionName,
      now_date: nowData,
      now_datetime: nowDataTime,
      now_time: nowTime,
    }

    this.getFormValidate(this.formData.fields);
    if (this.moduleUuid && this.needFlow && this.formMode === 'add') {
      this.flowDesign({module: this.moduleUuid});
    }
    if (this.formData.baseLabel) {
      this.formLabel = this.$getLocalizedLabel({'zhLabel': '基本信息', 'enLabel': 'Basic information'});
    }
    if (this.formData.tableStyle) {
      this.tableStyle = this.formData.tableStyle
    }
  },
  methods: {
    ...parserMethods
  },
};
</script>

<style>
.input-show {
  width: 100%;
  max-height: 100px;
  padding: 5px 12px;
  box-sizing: border-box;
  background: #F2F4F7;
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--systemFontFamily);
  color: #2A2F3D;
  line-height: 26px;
  cursor: default;
  overflow-y: auto;
}

.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #f56c6c !important;
}

.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  max-height: 100%;
}

.vertical-center.el-dialog {
  margin: 0 auto !important;
}

.vertical-center .el-dialog__body {
  padding: 15px 20px !important;
}

.el-dialog__body::-webkit-scrollbar,
.el-dialog__wrapper::-webkit-scrollbar,
.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  margin: 0 3px;
}

.el-dialog::-webkit-scrollbar-track,
.el-dialog__body::-webkit-scrollbar-track,
.el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.el-dialog::-webkit-scrollbar-thumb,
.el-dialog__body::-webkit-scrollbar-thumb,
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(189, 192, 199, 1);
  border-radius: 4px;
}

.el-dialog::-webkit-scrollbar-thumb:hover,
.el-dialog__body::-webkit-scrollbar-thumb:hover,
.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(189, 192, 199, 1);
}

.el-dialog::-webkit-scrollbar-thumb:active,
.el-dialog__body::-webkit-scrollbar-thumb:active,
.el-table__body-wrapper::-webkit-scrollbar-thumb:active {
  background-color: rgba(189, 192, 199, 1);
}

.el-dialog::-webkit-scrollbar-corner,
.el-dialog__body::-webkit-scrollbar-corner,
.el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: #ffffff;
}
</style>

<style lang="less" scoped>
.remove-text-center {
  text-align: left;
}

@colorActive: #007FE0;
.parser-tp {
  margin-top: 20px;
}

// @import "@/main.less";
:deep(.type-label) {
  border-left: 3px solid @colorActive !important;
  padding: 0 10px;
  line-height: 20px;
  font-size: 14px;
  margin: 15px 0;
  font-weight: 600;
  text-align: left;
}

/*
.no-padding {
  height: 36px;
  .el-form-item__content {
    height: 36px;
  }
}
*/

:deep(.form-parser) {
  .table-wrapper, .table-relate {
    /*.el-form-item__content {
      .el-form-item {
        border: none;
      }
    }*/

    .el-table {
      .el-form-item {
        border: none;
      }
    }
  }

  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    /*margin-bottom: -1px !important;*/

    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      /*margin-bottom: 1px;*/

      .el-select,
      .el-cascader {
        width: 100%;
      }

      .el-input.is-disabled .el-input__inner {
        background-color: #ffffff;
        color: #606266;
      }

      .el-textarea.is-disabled .el-textarea__inner {
        color: #606266;
      }

      .el-input-group__prepend, .el-input-group__append {
        border: none;
      }

      .el-input__inner,
      textarea {
        border: 1px solid transparent;
      }
    }
  }

  .table-container {
    .el-form-item {
      border: none;
    }
  }
}

:deep(.normal-parser) {
  .el-col-1 {
    .el-form-item {
      height: 36px;
      overflow: hidden;
    }
  }

  .el-form-item__label {
    padding: 0;
  }

  .el-form-item__content {
    //margin-left: 0!important;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }

  .el-input.is-disabled .el-input__inner {
    color: #606266;
  }

  .el-textarea.is-disabled .el-textarea__inner {
    color: #606266;
  }

  .table-wrapper,
  .table-relate {
    margin-bottom: 18px;
  }
}

:deep(.common-dialog) {
  max-height: calc(100vh - 50px);
  overflow-y: auto;

  .type-label {
    border-left: 3px solid var(--themeColor)!important;
    padding: 0 10px;
    line-height: 1;
    font-size: 14px;
    margin: 0 0 16px;
    font-weight: 600;
  }

  .dialog-main {
    overflow-x: auto;
  }

  .el-dialog__body {
    max-height: calc(100vh - 155px);
    height: calc(100vh - 155px);
    min-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
