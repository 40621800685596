<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-06-06 15:36:53
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-06-06 16:04:10
 * @FilePath: /dataview-next/src/custom-component/container/ShowNationality.vue
 * @Description: 
-->
<template>
  <span class="show-nationality">
    {{ ($i18n.locale === 'en' ? nationality?.administrative_region_english : nationality?.administrative_region_name) || '-'}}
  </span>
</template>

<script>
export default {
  name: 'ShowNationality',
  props: {
    nationlityId: {
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    nationality() {
      return this.list.find(ele => +ele.id === +this.nationlityId)
    }
  }
}
</script>

<style lang="less" scoped>

</style>