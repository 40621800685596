<!--
 * @Description: 添加文件(从功能网盘中添加文件)
 * @Author: luocheng
 * @Date: 2022-03-03 11:41:59
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-08 19:19:17
-->
<template>
  <div v-if="value">
    <el-dialog
      :title="$t('components.PlusFile.648379-0')"
      :visible.sync="value"
      width="800px"
      append-to-body
      :before-close="handleClose">
      <div class="plus-file">
        <!-- 功能模块 -->
        <ul class="module-list" v-show="type === 1">
          <li class="module-item" v-for="item in 100" :key="item" @click="onSelectModule(item)">
            <i class="iconfont iconwenjianjia"></i>
            <p class="title">{{ $t('components.PlusFile.648379-1') }}{{ item }}</p>
          </li>
        </ul>
        <!-- 选择内容 -->
        <article v-if="type === 2" class="select-main">
          <SelectFiles></SelectFiles>
        </article>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">{{ $t('components.PlusFile.648379-2') }}</el-button>
        <el-button type="primary" @click="onConfirm">{{ $t('components.PlusFile.648379-3') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SelectFiles from './SelectFiles';

export default {
  name: 'PlusFile',
  components: {
    SelectFiles
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      type: 1, // 1 选择模块 2 选择文件
    }
  },
  methods: {
    /**
     * @desc: 选择模块
     * @param {Object} item 模块数据
     */
    onSelectModule(item) {
      console.log(item, '00000000');
      this.type = 2;
    },
    onConfirm() {},
    onClose() {
      this.$emit('input', false);
    },
    handleClose(done) {
      this.onClose();
      done();
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-dialog){
  .el-dialog__header{
    box-sizing: border-box;
    border-bottom: 1px solid #f5f5f5;
    text-align: left;
    .el-dialog__title{
      font-size: 14px;
      font-weight: bold;
    }
  }
  .el-dialog__body{
    padding: 16px;
  }
}
.plus-file{
  height: 60vh;
  overflow: hidden;
  .module-list{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .module-item {
      box-sizing: border-box;
      height: 80px;
      width: 80px;
      text-align: center;
      cursor: pointer;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 5px;
      margin: 0 3px 10px 0;
      &:hover{
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
      }
      i{
        display: block;
        font-size: 48px;
        line-height: 50px;
        color: rgb(249, 194, 10)
      }
      .title{
        line-height: 20px;
        text-align: center;
        overflow: hidden;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .select-main{
    height: 100%;
    width: 100%;
  }
}
</style>