
<!--
 * @Description: 标记容器
 * @Author: Shiltin
 * @Date: 2021-08-18 13:48:12
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2024-02-06 11:27:16
-->
<template>
	<div class="console-sign-cont">
		<div
			v-for="(item, index) in signLists.filter(v=>v.statusConfig && v.statusConfig.isShow && (showAreaLevel? viewHeight < 1.5:true))"
			:key="item.id"
			:id="'cont-'+item.id"
			:class="[isSignScale && cameraHeight > gisMinHeight?'sign-item-scale':'sign-item',isPointerNone?'pointer-events-none':'pointer-events-auto']"
			@click="onClickSign(item)"
			:style="{width:item.style.width + 'px',left:item.style.left + 'px',top:item.style.top + 'px'}"
		>
		<!-- transform: `rotate3d(1, 0, 0, ${90 - cameraAngle}deg)` -->
			<CommonSignContainer
				:index="index"
				:element="item"
				class="sign-container"
				:id="item.id"
				:propValue="item.propValue"
				:scaleHeight="+scaleHeight"
				:scaleWidth="+scaleWidth"
				:ratio="+ratio"
				:isPreview="true"
				@click-box="clickBox"
			/>
		</div>
		<!-- 聚合 -->
		<div v-if="showAreaLevel">
			<!-- 带行政区划的聚合 -->
			<div 
				class="aggregation-item"
				:class="isPointerNone?'pointer-events-none':'pointer-events-auto'"
				@click="flyTo(item.id,item)"
				v-show="item.position.x > 0 && item.position.y > 0 && viewHeight < 40 " 
				v-for="(item, index) in aggregationData"
				:key="index"
				:style="{top:item.position.y + 'px', left:item.position.x + 'px'}"
				>
				{{ item.num }} {{ cameraAngle }}
				<p class="aggregation-area-name" v-show="item.areaArr?.length === 2" v-html="viewHeight >= 9 ? item.areaArr[0] : item.areaArr[1]" />
			</div>	
		</div>	
		<div v-else>
			<!-- 不带行政区划的聚合 -->
			<div
				v-for="(value, key) in aggregationData"
				:key="key"
				>
				<div 
					class="aggregation-item"
					:class="isPointerNone?'pointer-events-none':'pointer-events-auto'"
					v-for="(val,j) in value"
					:key="j"
					@click="flyTo(val.id,val)"
					:style="{top:val.position.y + 'px', left:val.position.x + 'px',background:val.color}"
				>
					{{ val.num }}
					<p class="aggregation-name" v-if="val.name">{{val.name}}</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { judgingArchiAPermission, initParams, getComponentById } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import CommonSignContainer  from '@/custom-component/container/CommonSignContainer.vue'
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
import modelGis from '@/custom-component/component-config/console.js';

export default {
name: 'ConsoleSignCont',
components: {
    CommonSignContainer,
},
props: { 
	element: {
		type: Object,
		required: true,
		default: () => {}
	},
	// 是否为预览
	isPreview: {
		type: Boolean,
		required: false,
		default: true
	},
	componentList: {
		default: null
	}
},
watch: {
	isGis: {
		handler(newVal,oldVal){
			if(newVal !== oldVal){
				this.onHideGisSigns(newVal)
			}
		},
		deep: true
	},
	isBim:{
		handler(newVal,oldVal){
			if(newVal !== oldVal){
				this.onHideBimSigns(newVal)
			}
		},
		deep: true
	},
	signLists:{
		handler(newVal){
			if(this.signListsLen === 0){
				this.$store.commit('setSignComponentData',newVal)
				this.signListsLen = 1
			}
		},
		deep:true,
	},
	loadedSignsType:{
		handler(newVal){
			if(newVal){
				for(let key in newVal){
					if(!newVal[key]){
						//关闭计时器
						clearInterval(this.intervalObj[key])
					}
				}
			}
		},
		deep:true,
	}
},
inject: ['EDITOR_pageUUID'],
data() {
	return {
		isPointerNone:false,
		allSignStyle:[],//标记样式合集
		bimComponentId:'',
		gisComponentId:'',
		isAccumulate: true,//标记连续显示
		gisInit:false,
		timer:null,//防止触发多次
		loadedSignsType:null,
		timeOuter: null, //计时器
		firstLevel:1,
		signLists:[],
		scaleHeight: 1,
		scaleWidth: 1,
		ratio: 1,
		isNoPositionInfo:false,//没有返回位置信息
		signListsLen: 0,
		aggregationData:[],//聚合的数据
		plotIdArr:[],//绘制面id
		viewHeight:9,// 地图初始的层级
		gisMinHeight:2000, //gis视角最小高度
		cameraHeight:9000000, //相机高度
		// 定时更新
		currentUpdateSigns: [],
		intervalObj:{},
		//枚举聚合的种类颜色
		aggregationColorData:[
			{label:['视频','监控'],color:'#2771FF'},
			{label:['人员','实名'],color:'#20AB6A'},
			{label:['车辆'],color:'#BF771E'},
			{label:['机械','塔吊'],color:'#FFA41C'},
			{label:['气体'],color:'#E32C35'},
			{label:['龙门'],color:'#835BFC'},
			{label:['升降'],color:'#B8B8B8'},
			{label:['环境'],color:'#20AB6A'},
			{label:['质量'],color:'#45A4F6'},
			{label:['安全'],color:'#728272'},
			{label:['水位'],color:'#45A4F6'},
			{label:['地质','播报','预报'],color:'#996600'},
			{label:['景观','效果'],color:'#3C8DC4'},
			{label:['爆破','预警'],color:'#E32C35'},
			{label:['汇报','报批','拆迁'],color:'#2771FF'},
		],
		cacheBehavior:[],//缓存行为
		socketObj:null,//长连接对象
		cameraAngle:90,//相机角度
		isCameraChange: false
	}
},
// updated () {
    // console.log(123,this.isCameraChange)
// },
computed: {
	...mapState(['componentData','subsidiaryComponentData','_PageCustomStatus']),
	// 嵌入页面的参数获取
	subComponentData() {
		if (this.EDITOR_pageUUID) {
			return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
		}
		return this.componentList || this.componentData || [];
	},
	//判断标记容器是bim还是gis
	signType () {
		return this.element.signType || this.element.statusConfig.signType;
	},
	isBim () {
		let bimComponent = this.findComponentWithField(this.subComponentData, 'ViewerBIM')
		if(bimComponent?.statusConfig?.displayShow && bimComponent?.statusConfig?.isShow){
			return true
		}else{
			return false
		}
	},
	//是否是gis页面显示
	isGis () {
		let gisComponent = this.findComponentWithField(this.subComponentData, 'ViewerGIS')
		if(gisComponent?.statusConfig?.displayShow && gisComponent?.statusConfig?.isShow){
			return true
		} else {
			return false
		}
	},
	//是否显示行政区域
	showAreaLevel () {
		return this.element.statusConfig.areaLevel || false
	},
	//点击标记陆续缩放到最小视图高度才能展开标记
	isSignScale () {
		return this.element.statusConfig.isSignScale || false
	}
},
created(){
	if(this.element.children?.length){
		this.allSignStyle = [...this.element.children]
  } else {
		//兼容之前数据
		this.getSignList()
	}
	/**
 * @description: 解决标记阻挡鼠标mousewheel事件，pointerEvents：none
 * @param {*} event
 * @return {*}
 */		
 window.addEventListener('wheel', () => {
		if (this.signLists?.length || this.aggregationData.length) {
			this.isPointerNone = true
		}
		clearTimeout(this.timeOuter);
		this.timeOuter = setTimeout(() => {
			// 执行相关代码，表示滚动已经结束
			window.requestAnimationFrame(()=> {
				this.isPointerNone = false
      });
		}, 500);
	});
},
mounted() {
	//监听点击标记暴露的值
	eventBus.$on('signResolve',(data)=>{
		this.$store.commit('modifyComponent', {
			component: {
				...this.element,
				resolveData: data.resolveData
			},
			containerId: null,
			isModify: true
		});
	})
	//把容器pointEvent设置为none
	const aElements=document.getElementsByTagName('div');
	for(let i=0;i<aElements.length;i++){
		if(aElements[i].getAttribute('data-id') === this.element.id){
			aElements[i].style.pointerEvents = 'none';
			break;
		}
	}
	//获取模型构件id以及模型style
	let bimComponent = this.findComponentWithField(this.subComponentData,'ViewerBIM');
	if(bimComponent){
		this.bimComponentId = bimComponent.id;
	}
	let gisComponent = this.findComponentWithField(this.subComponentData,'ViewerGIS');
	if(gisComponent){
		this.gisComponentId = gisComponent.id;
	}
	//监听行为
	const doComponentBehavior = {
		[this.element.id]: (config) => {
			//区分gis和bim行为
			if(this.signType && this.signType === 'bim'){
				this.cacheBehavior = []
				if(!this.isBim){
					return false
				}
				this.behaviorFun(config)
			} else 	if(this.signType && this.signType === 'gis'){
				if(!this.isGis){
					return false
				} else{
					if(!this.gisInit) {
						this.cacheBehavior.push(config)
					} else {
						this.behaviorFun(config)
					}
				}
			}
			
		}
	}
	eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id]);
	/**
	 * @description: 监听坐标
	 * @param {*} 
	 * @return {*}
	 */		
	if(this.signType && this.signType === 'bim'){
		eventBus.$on('Viewer.onCameraChange', (componentId, res) => {
			if (!res) {
				return
			}
			Object.keys(res).forEach((key) => {
				const list  = this.signLists.filter(v=>v.statusConfig && v.statusConfig.isShow && (this.showAreaLevel? this.viewHeight < 1.5:true))
				const index = list.findIndex( (v) => v.id.includes(key));
				//zoom值计算,只计算第一个，减少计算量
				// if(index === 0){
				// 	let dis = position.x*position.x + position.y*position.y + position.z*position.z;
				// 	if(this.firstLevel === 1 && dis){
				// 		this.firstLevel = dis;
				// 	}else{
				// 		let a = this.firstLevel/dis;
				// 		if (a < 1) {
				// 			if (a.toFixed(1) > 0) {
				// 				this.ratio = a.toFixed(1);
				// 			} else {
				// 				this.ratio = 0.001
				// 			}
				// 		} else {
				// 			this.ratio = 1;
				// 		}
				// 	}
				// }
				if (index !== -1) {
					this.signLists[index].style.zoom = this.ratio;
					this.signLists[index].style.left = res[key].point.x - (this.signLists[index].style.width * this.ratio) / 2;
					this.signLists[index].style.top = res[key].point.y -  this.signLists[index].style.height * this.ratio;
				}
			})
		})
	}
	if(this.signType && this.signType === 'gis'){
		eventBus.$emit("ViewerGIS.onViewerInited",this.gisComponentId);
		eventBus.$on("ViewerGIS.onViewerInited",()=>{
			console.log('模型加载完毕')
			this.gisInit = true
			if(this.cacheBehavior?.length){
				this.cacheBehavior.forEach((item)=>{
					this.behaviorFun(item)
				})
				this.cacheBehavior = []
			}
		});
		//注册监听相机视角变化事件
		eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
		eventBus.$on('ViewerGIS.onCameraChange', () =>{
			this.isCameraChange = true
			clearTimeout(this.timer)
			this.timer = setTimeout(() =>{
				this.isCameraChange = false
			},50)
			// console.time()
			this.resetAggregationData()
			// console.timeEnd()
			
			//获取视图高度/相机角度
			eventBus.$emit('ViewerGIS.getCameraView', this.gisComponentId, (res) => {
				if(res){
					if(res.pitch){
						this.cameraAngle = Math.abs(res.pitch).toFixed(0)
					}
					if(this.cameraHeight !== res.height){
						this.viewHeight = Number(res.height / 1000000).toFixed(1)
						this.cameraHeight = res.height
					}
				}
			})
			
		});
	}
},
methods: {
	judgingArchiAPermission,
/**调用行为方法
 * @description: 
 * @return {*}
 */	

	behaviorFun(config){
		const { component, list = [] } = config;
		let componentName = component.split('-')[0]
		if (!this.element.id.includes(componentName) || list.length === 0) return false;
		list.forEach(ele => {
			const { behaviors, params } = ele;
			//获取携带的参数id
			const { param = {}, canPost, search = [] } = initParams(params, false, this.subComponentData, []);
			//获取标记的signConfig
			let signContent = null;
			let type = '';
			if(params?.length){
				for(let i = 0; i < params.length; i++) {
					if(params[i].componentId){
						const paramComponent = getComponentById(this.subComponentData, params[i].componentId);
						if(paramComponent?.signConfig?.show && paramComponent?.signConfig?.pageUuid){
							if(paramComponent.signConfig?.pageUuid){
								//将标记page作为判断类别的依据
								type = paramComponent.signConfig.pageUuid
							} else if(paramComponent.database?.viewData!==null){
								//将视图作为判断类别的依据
								type = paramComponent.database.viewData.uuid
							}else if(paramComponent.contentConfig?.viewUUID){
								type = paramComponent.contentConfig.viewUUID
							}
							const findData = this.allSignStyle.filter((v)=>v.pageUUid === paramComponent.signConfig?.pageUuid) || []
							if(findData?.length){
								signContent = findData
							}
							break;
						} else if(params[i].key === 'sign_uuid' && params[i].paramKey){
							//标记取值来之组件列表，显示多个不同类别的标记
							if(paramComponent?.database?.objectData?.uuid && paramComponent.database?.viewData?.uuid){
								dataInterface({
									object_uuid: paramComponent.database?.objectData?.uuid,
									page: 1,
									size: 1000,
									view_uuid: paramComponent.database?.viewData?.uuid,
									__method_name__: "dataList",
								}).then((res) => {
									if(res?.data?.code === 200 && res.data.data?.data?.length){
										const list = res.data.data.data
										const signField = params[i].paramKey
										for(var j = 0; j < list.length; j++) {
											if (list[j][signField] && typeof list[j][signField] === "string" && list[j][signField].includes('page')) {
													const findData = this.allSignStyle.filter((v)=>v.pageUUid === list[j][signField] ) || []
													if(findData?.length){
														signContent = findData
														param.sign_uuid = list[j][signField]
														if (canPost) {
															// 调用行为方法
															behaviors.forEach(funName => {
																try {
																	eval(this[funName])(param,signContent,list[j][signField],true)
																} catch (err) {
																	console.log(err);
																}
															});
														}
													}
											}
										}
									}
								})
							}
							return
						}
					} else if(params[i].sourceType === 'fixed' && params[i].key === 'sign_uuid'){
						const findData = this.allSignStyle.filter((v)=>v.pageUUid === params[i].fixedValue) || []
						if(findData?.length){
							signContent = findData
						}
						type = param.sign_uuid
					}else if(params[i].key === 'view_uuid'){
						type = params[i].fixedValue
					}else if(params[i].key === 'sign_uuid'){
						type = param.sign_uuid
					}
					
				}
			}
			if (canPost) {
				// 调用行为方法
				behaviors.forEach(funName => {
					try {
						this.signListsLen = 0
						window.$EditorDebug.startBehavior(
							this.element,
							modelGis.componentList.find(ele => ele.component === 'ConsoleSignCont'),
							funName,
							param
						);
						eval(this[funName])(param,signContent,type,search)
					} catch (err) {
						console.log(err);
					}
				});
			}
		})
	},
	
 /**
  * @description: 格式化经纬度格式
  * @return {*}
  */	
	formatPosition(gis_info){
		if (!gis_info) return 
		let gisPosition = null
		let signContInfo = null
		let positionName = ''
		if(gis_info.lat && gis_info.lng){
			gisPosition = gis_info
		}else if(gis_info.position){
			gisPosition = gis_info.position
		} else if(gis_info.latitude && gis_info.longitude){
			gisPosition = {lat:gis_info.latitude,lng:gis_info.longitude}
		} else if(gis_info.data?.position){
			signContInfo = gis_info.data
			gisPosition = gis_info.data.position
		} else if(Array.isArray(gis_info) && gis_info.length){
			if(gis_info[0].location_gis){
				//新统一的绑定数据格式
				signContInfo = gis_info
				gisPosition = gis_info[0].location_gis
				positionName = gis_info[0].location_name
			} else if(gis_info[0].model_id && gis_info[0].dbids?.length){
				//构件转经纬度@蒲亚军
				eventBus.$emit('ViewerGIS.getPolygonByMultiKeys', this.gisComponentId, [{modelId:gis_info[0].model_id,dbids:gis_info[0].dbids}], (position) => {
					if(position?.length){
						let positionArr = position.map((item)=>{
							return {lat:item[1],lng:item[0]}
						})
						gisPosition = positionArr[0]
						eventBus.$emit('ViewerGIS.removePlot', this.gisComponentId, 'section')
						eventBus.$emit('ViewerGIS.createPlot', this.gisComponentId, 'section', {
							plotType: window.BIMCC_GIS_Base.PlotType.POLYGON,
							positions: positionArr,
							options: {
								fillColor:'#0F4880',
								outline: true
							},
						})
					}
				})
			}
		}  else if (typeof gis_info == "string") {
			const infoArr = JSON.parse(gis_info)
			if(infoArr?.length && infoArr[0].location_gis){
				//新统一的绑定数据格式
				signContInfo = infoArr
				gisPosition = infoArr[0].location_gis
			}
		}
		return {gisPosition,signContInfo,positionName}
	},

	/**
	 * @description: 刷新某一类标记
	 * @return {*}
	 */	
	refreshTypeSigns(signParam){
		if (!signParam) return
		//关闭还打开所有类型的标记结束
		if (signParam?.view_uuid && signParam?.object_uuid && signParam?.sign_uuid && signParam.sign_field) {
			let obj = {
				object_uuid:signParam.object_uuid,
				view_uuid:signParam.view_uuid,
				__method_name__: "dataList",
				page:1,
				size:1000,
				transcode: 0
			}
			dataInterface(obj).then((res) => {
				if (res.data?.code === 200 && res.data?.data?.length) {
					this.loopChangePosition(res.data.data, signParam)
				}
			})
		} else if(signParam?.graph){
			let graphParam = {}
			for(let key in signParam){
				if(key!== 'graph' && key!== 'sign_field' && key!== 'sign_uuid'){
					graphParam[key] = signParam[key]
				}
			}
			//数据来自蓝图
			dataInterface(graphParam, `api/graph/${signParam.graph}`).then((res) => {
				if (res.data?.code === 200 && res.data?.data?.length) {
					this.loopChangePosition(res.data.data, signParam)
				}
			})
		}
	},

	loopChangePosition(data, signParam){
		if(data?.length && signParam.sign_field){
			for(let i = 0; i < data.length; i++){
				let signType = `${signParam.sign_uuid}-${data[i].id}`
				let formatPositionVal = this.formatPosition(data[i][signParam.sign_field])
				let findData = this.signLists.find(v=>v.id.includes(signType))
				if(formatPositionVal.gisPosition && findData){
					eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, formatPositionVal.gisPosition, (position) => {
						if(position){
							findData.style.left = position.x - (Number(findData.style.width) / 2);
							findData.style.top = position.y - Number(findData.style.height);
							// findData.style.left = findData.style.left + 5;
							// findData.style.top = findData.style.top + 5;
						}
					})
				}
			}
		}
	},
	
 /**
  * @description: 移除当前更新
  */
	removeCurrentUpdate (page = null) {
		if (!page) return;
		const index = this.currentUpdateSigns.findIndex(ele => ele === page);
		if (index !== -1) {
			this.currentUpdateSigns.splice(index, 1);
		}
	},
	/**
	 * @description: 刷新聚合数据
	 * @return {*}
	 */		
	resetAggregationData(){
		if(this.showAreaLevel){
			let newPoint = []
			//显示行政级别
			if(this.signLists?.length){
				for(let i=0;i<this.signLists.length;i++){	
					if(!this.isCameraChange){
						break
					} else {
						const val = this.signLists[i]
						const ele = document.getElementById(`cont-${val.id}`)
						if(this.viewHeight < 1.5){
							if(val.gisPosition){
								eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, val.gisPosition, (position) => {
									if(position && ele){
										ele.style.left = position.x - (Number(val.style.width) / 2);
										ele.style.top = position.y - (Number(val.style.height ));
									}
								})
							}		
						} else {
							let areaArr = []
							if(val.positionName){
								if(val.positionName.includes('/')){
									let positionNameArr = val.positionName.split('/')
									if(positionNameArr[0] && positionNameArr[0].includes('省') || positionNameArr[0].includes('市') || positionNameArr[0].includes('自治区') || positionNameArr[0].includes('行政区')){
										areaArr.push('中国')
										areaArr.push(positionNameArr[0])
									}
								} else if(val.positionName.includes('|')){
									areaArr = val.positionName.split('|')
								}
							}
							if(val.gisPosition){
								eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, val.gisPosition, (position) => {
									if(position){
										if(areaArr.length){
											let areaIndex = 0
											if(this.viewHeight < 9){
												areaIndex = 1
											}
											if(newPoint.length === 0){
												if(ele){
													ele.style.display = 'block'
												}
												newPoint.push({id:val.id,position:position,num:1,areaArr:areaArr,areaLevel:areaArr[areaIndex],latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]})
											} else {
												const findIndexNum = newPoint.findIndex(v=>v.areaLevel  === areaArr[areaIndex])
												if(findIndexNum === -1){
													if(ele){
														ele.style.display = 'block'
													}
													newPoint.push({id:val.id,position:position,num:1,areaArr:areaArr, areaLevel:areaArr[areaIndex],latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]})
												} else {
													newPoint[findIndexNum].num++
													newPoint[findIndexNum].latArr.push(Number(val.gisPosition.lat))
													newPoint[findIndexNum].lngArr.push(Number(val.gisPosition.lng))
												}
											}
											if(ele){
												ele.style.left = position.x - (Number(val.style.width) / 2);
												ele.style.top = position.y - (Number(val.style.height ));
											}
											
										}
									}
								});
							}
							this.aggregationData = [...newPoint]	
						}
					}
				}
			}
		} else {
			let newPoint = {}
			//不显示行政区域
			if(this.signLists?.length){
				const len = this.signLists.length
				let i,name,ele,val,color
				for(i=0;i<len;i++){	
					if(!this.isCameraChange){
						break
					} else {
						val = this.signLists[i]
						ele = document.getElementById(`cont-${val.id}`)
						name = val.name.slice(0,2)
						color = val.aggregationColor ||'#2771FF'
						if(val.gisPosition){
							eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, val.gisPosition, (position) => {
									if(position){
										if(Object.keys(newPoint).length === 0 || !newPoint[val.pageUUid]){
											if(ele){
												ele.style.display = 'block'
											}
											newPoint[val.pageUUid] = [{id:val.id,color:color,name:name,position:position,num:1,latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]}]
										} else {
											const findIndex = newPoint[val.pageUUid].findIndex(v=>Math.abs(v.position.x - position.x) < 10 && Math.abs(v.position.y - position.y) < 10)
											if(findIndex === -1){
												if(ele){
													ele.style.display = 'block'
												}
												newPoint[val.pageUUid].push({id:val.id,color:color,name:name,position:position,num:1,latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]})
											} else {
												if(Math.abs(newPoint[val.pageUUid][findIndex].position.x - position.x) < 10 && Math.abs(newPoint[val.pageUUid][findIndex].position.y - position.y) < 10){
													newPoint[val.pageUUid][findIndex].num++
													newPoint[val.pageUUid][findIndex].latArr.push(Number(val.gisPosition.lat))
													newPoint[val.pageUUid][findIndex].lngArr.push(Number(val.gisPosition.lng))
													if(ele){
														ele.style.display = 'none'
													}
													const index = this.signLists.findIndex(v=>v.id === newPoint[val.pageUUid][findIndex].id)
													const findEle = document.getElementById(`cont-${this.signLists[index].id}`)
													if(findEle){
														findEle.style.display = 'none'
													}
												} else {
													if(ele){
														ele.style.display = 'block'
													}
													newPoint[val.pageUUid].push({id:val.id,color:color,name:name,position:position,num:1,latArr:[Number(val.gisPosition.lat)],lngArr:[Number(val.gisPosition.lng)]})
												}
											}
										}
										if(ele){
											ele.style.top = position.y - (Number(val.style.height )) + 'px'
											ele.style.left = position.x - (Number(val.style.width ) / 2) + 'px'
										}
									}
							});
						}
					}
				}
				if(Object.keys(newPoint).length){
					for(let key in newPoint){
						if(newPoint[key].length){
							for(let j=0;j<newPoint[key].length;j++){
								if(newPoint[key][j].num===1){
									const index = this.signLists.findIndex(v=>v.id === newPoint[key][j].id)
									const findEle = document.getElementById(`cont-${this.signLists[index].id}`)
									if(findEle){
										findEle.style.display = 'block'
									}
									newPoint[key].splice(j,1)
									j--
								}
							}

						}
					}
					this.aggregationData = newPoint
				}
			}
		}
	},
	/**
	 * @description: 点击标记
	 * @param {*} box
	 * @param {*} ele
	 * @return {*}
	 */		
  clickBox(box){
		if (box?.resolveData && box?.resolveData!==null) {
			//resolveData参数传入
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData: box.resolveData
				},
				containerId: null,
				isModify: true
			});
    }
		//ele第二个传入参数
		if(this.signLists.length){
			for(let i = 0; i < this.signLists.length;i++) {
				if(this.signLists[i].id === box.id){
					//回显区域
					if(this.signLists[i].signContInfo?.coverData || Array.isArray(this.signLists[i].signContInfo)){
						eventBus.$emit('ViewerGIS.removeAllPlot', this.gisComponentId);
						this.createPlot(this.signLists[i].signContInfo, this.signLists[i].id)
					}
					if (this.element?.actionConfig?.signClick?.eventList?.length) {     
						const eventList = this.element.actionConfig.signClick.eventList;
						if (eventList && eventList.length) {
							eventList.forEach((el) => {
								el.eventList.forEach(ele => {
									if (ele.key === 'click') {
										ele.effects.forEach((effect) => {
											this.$store.commit('triggerEvents', {
												config: {
													...ele,
												...effect
												},
												element: this.element
											});
										});
									}
								})
							});
						}
					}
				}
			}
		}
	},
 /**
  * @description: 特殊情况下的点击标记
  * @param {*} item
  * @return {*}
  */	
	onClickSign(item){
		let height = this.gisMinHeight
		if(this.cameraHeight > 150000){
			height = 150000
		} else if (this.cameraHeight  > this.gisMinHeight){
			height = this.cameraHeight / 10
		}
		if(height < this.cameraHeight){
			eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, item.gisPosition, height, 90, 2);
		}
	},
	/**
	 * @description: 定位到
	 * @return {*}
	 */		
	flyTo(id,data){
		if(this.isGis){
			if(data){
				if(data.latArr.length === 2){
					//定位到多坐标的范围
					if(data.latArr?.length && data.lngArr?.length){
						eventBus.$emit('ViewerGIS.flyToBoundHeading', this.gisComponentId, {
							west:Math.min(...data.lngArr),
							east:Math.max(...data.lngArr),
							south:Math.min(...data.latArr),
							north:Math.max(...data.latArr)
						}, 90, 2, 700);
					}
				} else {
					//定位到多坐标的范围
					if(data.latArr?.length && data.lngArr?.length){
						eventBus.$emit('ViewerGIS.flyToBoundHeading', this.gisComponentId, {
							west:Math.min(...data.lngArr),
							east:Math.max(...data.lngArr),
							south:Math.min(...data.latArr),
							north:Math.max(...data.latArr)
						}, 90, 2);
					}
				}
				
			} else {
				const filterData = this.signLists.filter(v=> v.id === id)
				if(filterData?.length){
					eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, filterData[0].gisPosition, 4000, 90, 2);
				}
			}
			
		}
	},
	/**
	 * @desc: 根据字段查找组件
	 */
	findComponentWithField(componentList, field, type = 'component'){
		for (let i = 0, len = componentList.length; i < len; i++) {
			const item = componentList[i];
			if (item[type] === field) {
				return item;
			}
			if (item.children && Array.isArray(item.children) && item.children.length) {
				const result = this.findComponentWithField(item.children, field, type) || null;
				if (result) {
					return result;
				}
			}
		}
		return null;
	},
	/**
	 * @desc: 请求获取标记绑定的模板
	 */
	getSignList(){
		dataInterface({
			object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
			page: 1,
			search: [
				// {field_uuid: "field61bc41f645e17", ruleType: "like", value: "console"},
				{field_uuid: "d1f7e149-2af7-468e-85be-5ef9bd97f634", ruleType: "like", value: "样式"}
			],
			size: 200,
			transcode: 0,
			view_uuid: "view6311790375749",
			__method_name__: "dataList",
		}).then((res) => {
			if(res.data.code === 200){
				let data = res.data.data.data
				if(data?.length){
					for(let i = 0; i < data.length; i++){
						this.allSignStyle.push({
							...data[i].page_data.componentData[0],
							name: data[i]?.name || '',
              pageUUid: data[i].uuid
						})
					}
				}
			}
		})
	},
	/**
	 * @desc: 清除所有标记
	 */
	hideAll(){
		this.$nextTick(()=>{
			this.signLists = [];
			this.loadedSignsType = null
			this.$store.commit('setSignComponentData',[])
			if(this.signType === 'gis'){
				this.aggregationData = []
				this.isCameraChange = true
				this.resetAggregationData()
			}
			if(this.socketObj){
				for(let key in this.socketObj){
					this.onCloseSocket({sign_uuid: key})
				}
			}
		})
	},
	/**
	 * @desc: 获取id集合
	 */
	getAllIds (data = [], arr){
		if(data?.length){
			for(let i=0;i<data.length;i++){
				if(data[i]?.id){
					arr.push(data[i].id)
				}
				if(data[i]?.children){
					this.getAllIds(data[i].children, arr)
				}
			}
		}
		return arr
	},
	/**
	 * @desc: 格式化数据，解决data-id重复得问题
	 */
	formatContent(arr, data, code){
		if(arr?.length){
			for(let j = 0; j < arr.length; j++){
				if(data.length){
					for(let i = 0; i < data.length; i++){
						arr[j] = JSON.parse(JSON.stringify(arr[j]).replaceAll(data[i],data[i] + '-' + code))
					}
				}
			}
		}
		return arr
	},
	/**
	 * @desc: 显示单个标记
	 */
	showSign(param,content,type){
		this.isCameraChange = true
		let data = [...content]
		if((param?.data_id  && content && type && ((param.model_info?.length && this.bimComponentId) || (this.isGis && this.gisComponentId && param.gis_info)))) {
			//格式化数据
			let code = `${type}-${param.data_id}`
			let findData = this.signLists.filter(v => v.id.includes(code))
			//已渲染该标记
			if(findData.length){
				for (let i = 0; i < this.signLists.length; i++){
					if(this.signLists[i].id.includes(code)){
						this.signLists[i].statusConfig.displayShow = true
						this.signLists[i].statusConfig.isShow = true
						if(this.signType && this.signType === 'gis'){
							let gisPosition = this.formatPosition(param.gis_info).gisPosition || null
							if(gisPosition){
								// 取消@林冉
								// eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, gisPosition, 1000, 90, 2);
							}
						}
					}
				}
			} else {
				let newContent = null
				let idArr = []
				let flyTo = false
				this.getAllIds([...content], idArr)
				let formatContent = this.formatContent([...content], idArr, code)
				//标记样式元素不是在一个容器中需要手动添加个容器
				if(data.length !== 1){
					this.$message.warning('标记模板请用大屏容器套入！')
					return
				} else {
					newContent = formatContent[0]
				}
				if(this.signType && this.signType === 'bim'&& param.model_info?.length){
					//bim构件转换世界坐标
					if(param.model_info[0].xyz){
						eventBus.$emit("ViewerBIM.addPosition",this.bimComponentId, type +'-'+param.data_id ,param.model_info[0].xyz, async(re)=>{
							let position = await re
							newContent.style.left = position.x - (Number(newContent.style.width) / 2);
							newContent.style.top = position.y - (Number(newContent.style.height ));
							newContent.signType = 'bim'
							newContent.signPageUuid = type
							this.signLists.push(newContent)
						})
					} else {
						eventBus.$emit("ViewerBIM.getBoundingSphere", this.bimComponentId, param.model_info[0].dbids[0],param.model_info[0].model_id, async(res)=>{
							let point = await res
							//传值给黄旭凯监听模型的坐标
							eventBus.$emit("ViewerBIM.addPosition",this.bimComponentId, type +'-'+param.data_id ,point.center, async(re)=>{
								let position = await re
								newContent.style.left = position.x - (Number(newContent.style.width) / 2);
								newContent.style.top = position.y - (Number(newContent.style.height ));
								newContent.signType = 'bim'
								newContent.signPageUuid = type
								this.signLists.push(newContent)
							})
						})
					}
				}
				if(this.signType && this.signType === 'gis'){
					let models = []
					let formatPositionVal = this.formatPosition(param.gis_info)
					let positionName = formatPositionVal.positionName || ''
					let gisPosition = formatPositionVal.gisPosition || null
					if(!flyTo && gisPosition!==null){
						//防止ViewerGIS.getCanvasByWgs方法不返回数据
						// eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, gisPosition, 20000, 90);
						// flyTo = true
					}
					if(param.gis_info.models){
						models = param.gis_info.models
					} else if(param.gis_info.data?.models){
						models = param.gis_info.data.models
					} else if(Array.isArray(param.gis_info) && param.gis_info.length){
						//新统一的绑定数据格式
						param.gis_info.forEach((v)=>{
							if(v.model_id && v.select?.length ){
								models.push({model_id:v.model_id,dbids:v.select})
							}
						})
					}
					//选中gis中的构件
					if(models?.length){
						for(let i = 0; i < models.length;i++){
							const item = models[i]
							eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
						}
					}
					//经纬度转屏幕坐标，参数为经纬度对象，以及转换后的回调
					eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, gisPosition, (position) => {
							if(position){
								newContent.style.left = position.x - (Number(newContent.style.width) / 2);
								newContent.style.top = position.y - (Number(newContent.style.height ));
								this.$set(newContent,'gisPosition',gisPosition)
								this.$set(newContent,'positionName',positionName)
								newContent.signType = 'gis'
								newContent.signPageUuid = type
								if(!this.isAccumulate){
									eventBus.$emit("ViewerBIM.Part.ClearAllColor",this.bimComponentId)
									//清除其他的数据方法
									this.hideOtherSign({type:code})
								}
								let findSameComponent = this.signLists.filter(v => v.id.includes(code))
								if(findSameComponent.length === 0){
									this.signLists.push(newContent)
									eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
									// eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, gisPosition, 400, 45, 2);
								}
								this.isNoPositionInfo = false
							} else {
								if(!this.isNoPositionInfo){
									this.isNoPositionInfo = true
									// eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, gisPosition, 400, 45, 2);
									setTimeout(()=>{
										this.showSign(param,content,type)
									},2000)
								}
							}
					});
				}
			}
		}
	},
 /**
  * @description: 隐藏其他的标记组件
  * @param {Object} data 参数
  * @param {Function/Null} updateFun 后续的操作——重新显示标记
  */
	hideOtherSign(data, updateFun = null){
		if(this.signLists?.length){
			for(var i=0; i <this.signLists.length; i++){
				if(data?.bol === undefined){
					if (!this.signLists[i].id.includes(data.type)) {
						if(this.signLists[i].signPageUuid){
							this.loadedSignsType[this.signLists[i].signPageUuid] = false
						}
						this.signLists.splice(i, 1);
						i--
					}
					//销毁长连接
					if(this.socketObj){
						for(let key in this.socketObj){
							if(key !== data.type){
								this.onCloseSocket({sign_uuid: key})
							}
						}
					}
				} else if(data?.bol === 'false' || data?.bol === false){
					//有bol且为false隐藏type下的标记
					if (this.signLists[i].id.includes(data.type)) {
						if(this.signLists[i].signPageUuid){
							if(this.loadedSignsType && this.loadedSignsType[this.signLists[i].signPageUuid]){
								this.loadedSignsType[this.signLists[i].signPageUuid] = false
							}
						}
						this.signLists.splice(i, 1);
						i--
					}
					//销毁长连接
					if(this.socketObj){
						for(let key in this.socketObj){
							if(key === data.type){
								this.onCloseSocket({sign_uuid: data.key})
							}
						}
					}
				}
			}
			if(this.signType === 'gis'){
				this.aggregationData = []
				this.isCameraChange = true
				this.resetAggregationData()
			}
		} else {
			this.loadedSignsType = null
		}
		if (Object.prototype.toString.call(updateFun) === '[object Function]') {
			setTimeout(() => {
				updateFun();
			}, 1000);
		}
	},
	//移除标记
	hideSign(param,content,type){
		if(param?.data_id && type){
			let code =`${type}-${param.data_id}`
			for(var i=0; i <this.signLists.length; i++){
				if (this.signLists[i].id.includes(code)) {
					this.signLists.splice(i, 1);
					i-- 
				}
			}
			if(this.socketObj && this.socketObj[type]){
				this.onCloseSocket({sign_uuid:type})
			}
			if(this.signType === 'gis'){
				this.aggregationData = []
				this.isCameraChange = true
				this.resetAggregationData()
			}
		}
	},
	/**
	 * @desc: 标记连续显示
	 */
	onAccumulate(){
		this.isAccumulate = !this.isAccumulate;
	},
	/**
	 * @desc: 缓存隐藏gis标记
	 */
	onHideGisSigns(bol = false){
		if(this.signLists?.length){
			this.signLists.forEach((val,i)=>{
				if(val.gisPosition){
					this.signLists[i].statusConfig.isShow = bol
				}
			})
			if(this.signType === 'gis'){
				this.aggregationData = []
				this.isCameraChange = true
				this.resetAggregationData()
			}
		}
		if(this.socketObj){
			for(let key in this.socketObj){
				this.onCloseSocket({sign_uuid: key})
			}
		}
	},
	/**
	 * @desc: 缓存隐藏bim标记
	 */
	onHideBimSigns(bol = false){
		if(this.signLists?.length){
			this.signLists.forEach((val,i)=>{
				if(val.statusConfig?.signType === 'bim'){
					this.signLists[i].statusConfig.isShow = bol
				} else {
					this.aggregationData = []
					this.isCameraChange = true
					this.resetAggregationData()
				}
			})
		}
	},
 /**
  * @description: 长连接显示标记
  * @return {*}
  */	
	onInitSocket(signParam,content){
		const { socket_path,sign_uuid,sign_field, type } = signParam
		if(socket_path && sign_uuid && sign_field && content?.length && type){
			if(typeof WebSocket != 'undefined'){
				if(this.socketObj && this.socketObj[sign_uuid]){
					return
				}
				this.initSocket(signParam,content)
			}else{
				this.$message.warning('该浏览器不支持webSocket通信')
			}
		} else {
			this.$message.warning('长连接参数不全，请检查参数！')
		}
	},
 /**
  * @description: 关闭长连接标记
  * @return {*}
  */	
	onCloseSocket(data){
		if(data.sign_uuid && this.socketObj && this.socketObj[data.sign_uuid]){
			this.socketObj[data.sign_uuid].close()
			delete this.socketObj[data.sign_uuid]
		}
	},
 /**
  * @description: 初始化socket
  * @return {*}
  */	
	initSocket(signParam,content){
		const { socket_path, sign_uuid, sign_field, type, archi_id } = signParam
		const newSocket = new WebSocket(`${socket_path}?types=${type}&archiId=${archi_id}`)
		newSocket.onopen = () => {
			newSocket.send(type)
			this.onWebSocketOpen(sign_uuid)
		}
		newSocket.onmessage = (event) => {
			if(this.signType === 'gis'){
				//长连接暂时只支持gis
				let data = event.data
				if(typeof(event.data) === 'string'){
					data = JSON.parse(event.data)
				}
				this.onWebSocketMessage(data, sign_field, sign_uuid, content, type)
			}
		} 
		newSocket.type = sign_uuid
		newSocket.isLoadSign = false
		newSocket.field = sign_field
		if(!this.socketObj){
			this.socketObj = new Object()
		}
		this.socketObj[sign_uuid] = newSocket
		if(!this.loadedSignsType){
			this.loadedSignsType = new Object()
		}
		this.loadedSignsType[sign_uuid] = true

	},
 /**
  * @description: 监听socket返回数据
  * @param {*} data
  * @param {*} sign_field
  * @return {*}
  */	
	onWebSocketMessage (data, sign_field, sign_uuid, content, type) {
		const formatPositionVal = this.formatPosition(data[sign_field])
		const gisPosition = formatPositionVal?.gisPosition || ''
		if(this.socketObj && this.socketObj[sign_uuid] && gisPosition ){
			let code = `${sign_uuid}-${data.machineid}`
			if(type === 'roller'){
				//机械不能取machineid,取sn值
				code = `${sign_uuid}-${data.sn}`
			}
			eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, {...gisPosition,height:0}, async(position) => {
				if (position) {
					const findSign = this.signLists.filter(v=>v.id.includes(code))
					if(!findSign?.length){
						//初始化分类标记
						let idArr = []
						this.getAllIds([...content], idArr)
						const formatContent = this.formatContent([...content], idArr, code)
						if(formatContent?.length){
							const leftComponentItem = this.findComponentWithField(this.subComponentData, sign_uuid, 'pageUUid')
							let metadata = {}
							Object.keys(data).forEach((item)=>{
								metadata[item] = item
							})
							if(leftComponentItem){
								this.$store.commit('modifyComponent', {
									component: {
										...leftComponentItem,
										metadata: metadata,
										database:{
											...leftComponentItem.database,
											metadata:data
										},
										resolveData: data
									},
									containerId: null,
									isModify: true
								});
							}
							const newContent = formatContent[0]
							newContent.aggregationColor = '#20AB6A'
							if(type === 'car'){
								newContent.aggregationColor = '#BF771E'
							} else if(type === 'roller'){
								newContent.aggregationColor = '#FFA41C'
							}
							newContent.style.left = position.x - (Number(newContent.style.width) / 2);
							newContent.style.top = position.y - Number(newContent.style.height );
							this.$set(newContent,'gisPosition',{...gisPosition,height:0})
							this.$set(newContent,'positionName','')
							this.$set(newContent,'resolveData',data)
							newContent.signType = 'gis'
							newContent.signPageUuid = sign_uuid
							this.signLists.push(newContent)
							this.isCameraChange = true
							eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
							this.resetAggregationData()
						}
					} else {
						const findIndex= this.signLists.findIndex(v=>v.id.includes(code))
						//标记已显示，直接改变屏幕坐标
						if(findIndex !== -1 && (this.signLists[findIndex].gisPosition.lat !== gisPosition.lat || this.signLists[findIndex].gisPosition.lng !== gisPosition.lng)){
							this.isCameraChange = true
							this.signLists[findIndex].gisPosition = {...gisPosition,height:0};
							this.signLists[findIndex].resolveData = data;
							this.resetAggregationData()
						}
					}
				}
			})
			
		}
	},
	onWebSocketOpen (sign_uuid) {
		console.log(`${sign_uuid}链接成功`)
	},
	/**
	 * @desc: 显示所有列表标记
	 */
	onShowTypeAllSigns(signParam,content,type, search, bol=false, updateTargetPage = null){
		const allSignParams = {
			signParam,
			content,
			type,
			search,
			bol
		};
		if (!signParam || !content) return
		let copyContent = [...content]
		//关闭还打开所有类型的标记
		if(!search.length){
			if(this.loadedSignsType === null || !Object.keys(this.loadedSignsType).length){
				this.loadedSignsType = {}
				this.$set(this.loadedSignsType,signParam.sign_uuid,true)
			} else {
				if(this.loadedSignsType[signParam.sign_uuid] === undefined || !this.loadedSignsType[signParam.sign_uuid]){
					this.$set(this.loadedSignsType,signParam.sign_uuid,true)
				} else  {
					for (let i = 0; i < this.signLists.length; i++){
						if(this.signLists[i].id.includes(type) && this.signLists[i].signPageUuid === signParam.sign_uuid){
							this.signLists.splice(i,1)
							i--
						}
					}	
					delete this.loadedSignsType[signParam.sign_uuid]
					this.isCameraChange = true
					this.resetAggregationData()
					return false
				}
			}
		}
		const paramObj = {...signParam}
		//关闭还打开所有类型的标记结束
		if (signParam?.view_uuid && signParam?.object_uuid && signParam?.sign_uuid && signParam.sign_field) {
			// let flyTo = false
			let obj = {
				object_uuid:signParam.object_uuid,
				view_uuid:signParam.view_uuid,
				search: search,
				__method_name__: "dataList",
				page:1,
				size:1000,
				transcode: 0
			}
			
			if(content?.length && content[0]?.database?.paramsConfig?.length){
				//所有标记的请求筛选放在了左侧标记模板内，不分是否是列表或详情
				const { param } = initParams(content[0].database.paramsConfig, false, this.subComponentData, []);
				if(param){
					obj = Object.assign(obj, param);
				}
			}
			dataInterface(obj).then((res) => {
				if(res.data.code === 200){
					let result = []
					let idTypeArr = []
					if(res.data.data && Object.prototype.toString.call(res.data.data) === '[object Array]') {
						result = res.data.data
					} else if(res.data.data.data && Object.prototype.toString.call(res.data.data.data) === '[object Array]'){
						result = res.data.data.data
					}
					for(let i = 0; i < result.length; i++){
						idTypeArr.push( `${type}-${result[i].id}`)
					}
					this.setSignFunction(result,paramObj,copyContent,type,bol,search, idTypeArr, allSignParams, updateTargetPage)
				}
			})
		}else if(signParam?.graph){
			let graphParam = {}
			for(let key in signParam){
				if(key!== 'graph' && key!== 'sign_field' && key!== 'sign_uuid'){
					graphParam[key] = signParam[key]
				}
			}
			//数据来自蓝图
			dataInterface(graphParam, `api/graph/${signParam.graph}`).then((res) => {
				if(res.data.code === 200){
					let idTypeArr = []
					for(let i = 0; i < res.data.data.length; i++){
						idTypeArr.push( `${type}-${res.data.data[i].id}`)
					}
					this.setSignFunction(res.data.data,paramObj,copyContent,type,bol,search, idTypeArr, allSignParams, updateTargetPage)
				}
			})
		}
	},

	/**
	 * @description: 标记定时刷新
	 */
	setIntervalSign (interfaceInterval,timerType, _signParams = null) {
		if (!_signParams) return false;
		// 清除定时器
		if (this.intervalObj[timerType]) {
			clearInterval(this.intervalObj[timerType]);
		}
		const {
			useInterval = false,
			type = 'timeout',
			timeout = 3000,
			times = -1,
			unit = 'day',
			weekDay = '',
			month = '',
			day = '',
			hms = '06:00'
		} = interfaceInterval;
		if (!useInterval) return false;
		this.intervalObj[timerType] = null;
		this.intervalTimes = 0;
		// 定时循环
		if (type === 'timeout') {
			if (timeout <= 0) return;
			this.intervalObj[timerType] = setInterval(() => {
				if (times !== -1) {
					this.intervalTimes++;
				}
				this.refreshTypeSigns(_signParams);
				if (times !== -1 && this.intervalTimes >= times) {
					clearInterval(this.intervalObj[timerType]);
				}
			}, timeout * 1000);
			return;
		}
		// 周期_时分秒
		if (['second', 'minutes', 'hour'].includes(unit)) {
			let count = 1;
			if (unit === 'minutes') {
				count = 60;
			} else if (unit === 'hour') {
				count = 60 * 60;
			}
			this.intervalObj[timerType] = setInterval(() => {
				this.intervalTimes++;
				this.refreshTypeSigns(_signParams);
				if (times !== -1 && this.intervalTimes >= times) {
					clearInterval(this.intervalObj[timerType]);
				}
			}, count * 1000);
		} else {
			// 每天 每分钟循环一次
			this.intervalObj[timerType] = setInterval(() => {
				const date = new Date();
				const currentMonth = date.getMonth() + 1;
				const currentDay = date.getDate();
				const currentWeekDay = date.getDay();
				const hours = date.getHours();
				const minutes = date.getMinutes();
				this.intervalTimes++;
				if (unit === 'day' && hms === `${hours}:${minutes}`) {
					// 日
					this.refreshTypeSigns(_signParams);
				} else if (unit === 'week') {
					// 周
					if (currentWeekDay === weekDay && hms === `${hours}:${minutes}`) {
						this.refreshTypeSigns(_signParams);
					}
				} else if (unit === 'month') {
					// 月
					if (day === currentDay && hms === `${hours}:${minutes}`) {
						this.refreshTypeSigns(_signParams);
					}
				} else if (unit === 'year') {
					// 年
					if (month === currentMonth && day === currentDay && hms === `${hours}:${minutes}`) {
						this.refreshTypeSigns(_signParams);
					}
				}
				if (times !== -1 && this.intervalTimes >= times) {
					clearInterval(this.intervalObj[timerType]);
				}
			}, 59 * 1000);
		}
	},
	
	setSignFunction(result, paramObj, copyContent,type, bol, search=[], idTypeArr=[], _signLevelParams={}, updateTargetPage=null){
		if(copyContent?.length && copyContent[0].interfaceInterval){
			//有定时任务的组件执行定时任务
			this.setIntervalSign(copyContent[0].interfaceInterval, type, _signLevelParams?.signParam || null)
		}
		// 带search数据的直接过滤
		if(this.signLists?.length && search.length){
			for(let i = 0; i < this.signLists.length; i++){
				if(idTypeArr.length){
					const itemIdArr = this.signLists[i].id.split('-')
					if(!idTypeArr.includes(`${itemIdArr[itemIdArr.length - 2]}-${itemIdArr[itemIdArr.length - 2]}`)){
						this.signLists.splice(i, 1);
						i--
					}
				} else {
					// this.loadedSignsType[type] = false
					if(this.signLists[i].id.includes(type)){
						this.signLists.splice(i, 1);
						i--
					}
				}
			}
		}
		if(result.length){
			result.forEach((item) => {
				if((!bol && item[paramObj.sign_field] && item[paramObj.sign_field] !== null) || (bol && JSON.stringify(item).includes(paramObj.sign_uuid))){
					const code = `${type}-${item.id}`
					let newContent = null
					let idArr = []
					this.getAllIds([...copyContent], idArr)
					let formatContent = this.formatContent([...copyContent], idArr, code)
					//标记样式元素不是在一个容器中需要手动添加个容器
					if(copyContent?.length !== 1){
						this.$message.warning('标记模板请用大屏容器套入！')
						this.removeCurrentUpdate(updateTargetPage);
						return
					} else {
						newContent = formatContent[0]
					}
					//获取枚举的聚合颜色
					if(newContent.name){
						for(let j=0;j<this.aggregationColorData.length;j++){
							for(let k =0;k<this.aggregationColorData[j].label.length;k++){
								if(newContent.name.includes(this.aggregationColorData[j].label[k])){
									newContent.aggregationColor = this.aggregationColorData[j].color
								}
							}
						}
					}
					if(this.signType && this.signType === 'bim' && item[paramObj.sign_field]?.length){
						if(item[paramObj.sign_field][0].xyz){
							eventBus.$emit("ViewerBIM.addPosition",this.bimComponentId, type +'-'+ item.id ,item[paramObj.sign_field][0].xyz, async(re)=>{
								let position = await re
								newContent.style.left = position.x - (Number(newContent.style.width) / 2);
								newContent.style.top = position.y - (Number(newContent.style.height ));
								newContent.signType = 'bim'
								newContent.signPageUuid = type
								newContent = {
									...newContent,
									_signLevelParams
								}
								this.signLists.push(newContent)
							})
						} else if(item[paramObj.sign_field][0]?.dbids?.length){
							//bim构件转换世界坐标
							eventBus.$emit("ViewerBIM.getBoundingSphere", this.bimComponentId, item[paramObj.sign_field][0].dbids[0],item[paramObj.sign_field][0].model_id, async(position)=>{
								let point = await position
								//传值给黄旭凯监听模型的坐标
								// eventBus.$emit('ViewerBIM.setSelect', this.bimComponentId, item[paramObj.sign_field]);
								eventBus.$emit("ViewerBIM.addPosition",this.bimComponentId, type +'-'+ item.id ,point.center, async(re)=>{
									let position = await re
									newContent.style.left = position.x - (Number(newContent.style.width) / 2);
									newContent.style.top = position.y - (Number(newContent.style.height ));
									newContent.signType = 'bim'
									newContent.signPageUuid = paramObj.sign_uuid
									newContent.resolveData = item
									newContent = {
										...newContent,
										_signLevelParams
									}
									this.signLists.push(newContent)
								})
							})
						}
						
					}
					if(this.signType && this.signType === 'gis'){
						let models = []
						let formatPositionVal = this.formatPosition(item[paramObj.sign_field])
						let gisPosition = formatPositionVal.gisPosition
						let positionName = formatPositionVal.positionName
						if(item[paramObj.sign_field].models){
							models = item[paramObj.sign_field].models
						} if(Array.isArray(item[paramObj.sign_field]) && item[paramObj.sign_field].length){
							//新统一的绑定数据格式
							item[paramObj.sign_field].forEach((v)=>{
								if(v.model_id && v.select?.length ){
									models.push({model_id:v.model_id, dbids:v.select})
								}
							})
						}
						//选中gis中的构件
						if(models?.length){
							for(let i = 0; i < models.length;i++){
								const item = models[i]
								eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
							}
						}
						//经纬度转屏幕坐标，参数为经纬度对象，以及转换后的回调
						eventBus.$emit('ViewerGIS.getCanvasByWgs', this.gisComponentId, gisPosition, (position) => {
							if (position) {
								newContent.style.left = position.x - (Number(newContent.style.width) / 2);
								newContent.style.top = position.y - Number(newContent.style.height );
								this.$set(newContent,'gisPosition',gisPosition)
								this.$set(newContent,'positionName',positionName)
								newContent.signType = 'gis'
								newContent.signPageUuid = paramObj.sign_uuid
								newContent.resolveData = item
								newContent = {
									...newContent,
									_signLevelParams
								}
								this.signLists.push(newContent)
								// eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, gisPosition, 400, 45, 2);
								eventBus.$emit('ViewerGIS.registerCameraChange', this.gisComponentId);
							}
						})
					}
				}
			})
			setTimeout(()=>{
				this.isCameraChange = true
				this.resetAggregationData()
			},0)
		}
		this.removeCurrentUpdate(updateTargetPage);
	},
 /**
   * @description: 绘制区域和清除所有区域绘制
   * @return {*}
   */		
	createPlot(param,id){
		if (param?.clearPlot) {
			eventBus.$emit('ViewerGIS.removeAllPlot', this.gisComponentId);
		} else {
			const findId = this.plotIdArr.filter(v=>v === id)
			if(param?.coverData?.length && !findId.length){
				this.plotFun(id, param.coverData[0])//目前只支持一次只绘制一个面
			} else if(Array.isArray(param) && param.length){
				param.forEach((item, i)=>{
					this.plotFun(id.toString() + i, item.polygon[0].position)
				})
			}
		}
	},
	plotFun(id,arr){
		const obj = {
			plotType: "polygon",
			positions: arr, 
			options: {
				plotType: "polygon",
				perPositionHeight: true,
				height: 5,
				fill: true,
				fillType: "color",
				fillColor: "#eabebf",
				alpha: 50,
				outline: true,
				outlineColor: "#dc2e41",
				outlineWidth: 1,
				isClampToGround: false,
				stRotation: 0,
			}
		}
		eventBus.$emit('ViewerGIS.createPlot', this.gisComponentId, id, obj);
		eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, arr[0], 2000, 45, 2);
	},
 /**
  * @description: 开启动画
  * @return {*}
  */	
	changeAnimationUsed(arr,bol){
		if(arr?.length){
			for(let i = 0; i < arr.length; i++){
				if(arr[i].animations?.length){
					for(let j = 0; j < arr[i].animations?.length;j++){
						arr[i].animations[j].used = bol
					}
				}
				if(arr[i]?.children?.length){
					this.changeAnimationUsed(arr[i].children,bol)
				}
			}
		}
	},
 /**
  * @description: 触发存入的动画
  * @return {*}
  */	
	async signShake(param){
		if(param.sign_uuid && this.signLists?.length){
			let type = `${param.sign_uuid}`
			if(param.data_id){
				type = `${param.sign_uuid}-${param.data_id}`
			}
			let findSigns = this.signLists.filter(v=>v.id.includes(type))
			if(findSigns?.length){
				await this.changeAnimationUsed(findSigns, true)
				for(let i=0;i<findSigns.length;i++){
					findSigns[i].statusConfig.isShow = false
					this.$nextTick(()=>{
						findSigns[i].statusConfig.isShow = true
					})
				}
				
			}
		}
	},
 /**
  * @description: 聚焦标记
  * @return {*}
  */	
	locationSign(param){
		if(this.signType === 'gis' && param.sign_uuid && param.data_id ){
			const str = `${param.sign_uuid}-${param.data_id}`
			const findSign = this.signLists.filter(v=>v.id.includes(str))
			if(findSign.length && findSign[0].gisPosition){
				eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, findSign[0].gisPosition, 800, 90);
			}
		} else if(param.position){
			let position = null
			if(typeof param.position === 'string'){
				const positionInfo = JSON.parse(param.position)
				if(Array.isArray(positionInfo)){
					position = positionInfo[0].location_gis
				}
			} else if(Array.isArray(param.position)){
				position = param.position[0].location_gis
			}
			eventBus.$emit('ViewerGIS.flyToHeading', this.gisComponentId, position, 800, 90);
		}
	},
	onClearPlot(param){
		if(param?.removeAll){
			eventBus.$emit('ViewerGIS.removeAllPlot', this.gisComponentId);
		} else {
			eventBus.$emit('ViewerGIS.removePlot', this.gisComponentId, 'section')	
		}
	}
},
beforeDestroy() {
	eventBus.$off('doComponentBehavior',this.element.id);
	eventBus.$off('Viewer.onCameraChange');
	eventBus.$off('ViewerGIS.onViewerInited');
	eventBus.$emit('ViewerGIS.logoutCameraChange' , this.gisComponentId);
	// window.removeEventListener('mousewheel');
	clearTimeout(this.timeOuter)
	if(Object.keys(this.intervalObj).length){
		for(let key in this.intervalObj){
			clearTimeout(this.intervalObj[key])
		}
	}
	//关闭长连接
	window.removeEventListener('beforeunload', () => {
		console.log(this.socketObj,'this.socketObj')
		if(this.socketObj){
			for(let key in this.socketObj){
				this.onCloseSocket({sign_uuid: key})
			}
		}
	})
}
}
</script>

<style lang="less" scoped>
.console-sign-cont {
	user-select: none;
	position: absolute;
}
.sign-item-scale{
	position: absolute;
	pointer-events: auto;
	height: auto;
}
.sign-item-scale .sign-container > :deep(div){
	user-select: none;
	pointer-events: none;
} 
.sign-item {
	position: absolute;
	z-index:0;
	height: auto;
}
.sign-item:hover{
	z-index: 1;
}
.pointer-events-auto{
	pointer-events: auto!important;
}
.pointer-events-auto > :deep(div){
	pointer-events: auto!important;
} 
.pointer-events-none{
	pointer-events: none!important;
}
.pointer-events-none > :deep(div){
	pointer-events: none!important;
} 
.aggregation-item{
	position: absolute;
	width: 22px;
	height: 22px;
	background: #2771FF;
	color: #fff;
	border-radius: 50%;
	margin:-18px 0 0 -18px;
	font-size: 12px;
	line-height: 22px;
	// pointer-events: auto!important;
	pointer-events: auto;
	text-align: center!important;
	.aggregation-area-name{
		position: relative;
		width: 100px;
		//pointer-events:none!important;
		width: 100px;
		top: 0px;
		left:-39px;
		text-align: center;
	}
	.aggregation-name{
		position: relative;
		width: 100px;
		left:-39px;
		top: 0px;
		text-align: center;
		height: 24px;
		line-height:24px;
	}
}
</style>
