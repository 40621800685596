<!--
 * @Author: lisushuang
 * @Date: 2021-10-31 18:44:57
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 15:46:47
 * @Description: 全局配置页
 * @FilePath: /dataview-viewer-test/src/custom-component/special/CommonConfigPage.vue
-->

<template>
  <div
    class="common-config-page scroller"
    v-loading="loading"
  >
    <el-button
      type="primary"
      class="action-btn"
      @click="btnClick"
      style="margin-right: 20px; width: 80px"
      v-if="
        (
          actionConfig.needAction
          && judgingArchiAPermission(actionConfig.archiLimit, actionConfig.permission)
        )
        || actionConfig.needAction === undefined
      "
    >
      {{ formDisabled ? $t('special.CommonConfigPage.371916-0') : $t('special.CommonConfigPage.371916-1') }}
    </el-button>
    <PcParser
      :displayData="showData"
      :form-data="formDesignData"
      ref="dialogForm"
      v-if="company_info_id && showData"
      @submit="onFormSubmit"
      :disabled="formDisabled"
      :moduleUuid="contentConfig.formUUID || contentConfig.objectUUID"
      style="margin-top: 50px; padding-right: 20px"
    />
  </div>
</template>

<script>
import { dataInterface, transFieldsUUid } from '@/apis/data/index';
import PcParser from '@/custom-component/form/newParser/PcParser';
import { judgingArchiAPermission } from '@/utils/tools';

export default {
  // 公司概况页面
  name: 'CommonConfigPage',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: { PcParser },
  data() {
    return {
      company_info_id: 0, // 公司概况的表id
      showData: null, // 表单展示的数据
      isEdit: true, // 是否在编辑中
      formDesignData: {}, // 表单自定义数据
      loading: true, // loading 状态
      formDisabled: true // 表单是否禁止填报
    };
  },
  computed: {
    // 内容配置
    contentConfig() {
      return this.element && this.element.contentConfig;
    },
    // 对象UUID
    objectUUID() {
      return this.contentConfig?.objectUUID;
    },
    // 视图UUID
    viewUUID() {
      return this.contentConfig?.viewUUID;
    },
    // 操作配置
    actionConfig() {
      return this.element?.actionConfig || {};
    }
  },
  methods: {
    judgingArchiAPermission,
    /**
     * @description: 获取当前所在公司id
     */
    getNowCompanyId() {
      let id = this.$GetTargetArchi();
      if (id.archiType == 'project') {
        id = id.company_id;
      } else {
        id = id.id;
      }
      return id;
    },
    /**
     * @description: 根据架构id查找内容
     * @param {Number} archi_id 架构ID
     */
    findCompanyInfoId(archi_id) {
      if (!archi_id) {
        this.showErrorMsg(this.$t('special.CommonConfigPage.371916-2'));
        return;
      }
      dataInterface({
        __method_name__: 'dataList',
        object_uuid: this.objectUUID,
        view_uuid: this.viewUUID,
        transcode: 0,
        search: [
          // {
          //   code: "archi_id",
          //   ruleType: "eq",
          //   value: archi_id,
          // },
        ]
      })
        .then(res => {
          if (res.data.code == 200) {
            // 有数据
            // 兼容分页与未分页
            let resData = res.data.data;
            if (!Array.isArray(resData) && Array.isArray(resData?.data)) {
              resData = resData.data;
            }
            if (resData?.[0]?.id) {
              this.company_info_id = resData[0].id;
              this.initFormDesign();
            } else {
              // 没数据就要新增
              this.createNewCompanyInfo(this.$GetTargetArchi());
            }
          }
        })
        .catch(err => {
          console.log(err, '777777');
          this.showErrorMsg();
        });
    },
    /**
     * @description: 显示错误消息
     * @param {String} msg 显示的消息
     */
    showErrorMsg(msg = this.$t('special.CommonConfigPage.371916-3')) {
      this.$message.error(msg);
    },
    /**
     * @description: 新增一个简单的公司概况信息
     * company_id, archiType, project_id, archi_id, archi_table
     */
    createNewCompanyInfo({ company_id, id, archiType, archi_id, archi_table }) {
      dataInterface({
        __method_name__: 'createData',
        object_uuid: this.objectUUID,
        transcode: 0,
        company_id,
        archi_type: id,
        archi_id,
        project_id: archiType === 'project' ? archi_id : '',
        archi_table
      })
        .then(res => {
          if (res.data.code == 200) {
            this.company_info_id = res.data.data.id;
            this.initFormDesign();
          } else {
            this.showErrorMsg(this.$t('special.CommonConfigPage.371916-4'));
          }
        })
        .catch(() => {
          this.showErrorMsg();
        });
    },

    /**
     * @description: 接收自定义表单抛出的事件
     * @param {Object} formData 自定义表单抛出的已验证的数据
     */
    onFormSubmit(formData) {
      this.loading = true;
      dataInterface({
        __method_name__: 'updateData',
        data_id: this.company_info_id,
        object_uuid: this.objectUUID,
        ...formData
      }).then(res => {
        this.loading = false;
        this.formDisabled = true;
        if (res.data.code == 200) {
          this.$message.success(this.$t('special.CommonConfigPage.371916-5'));
        }
      });
    },
    /**
     * @description: 初始化表单数据
     */
    initFormDesign() {
      transFieldsUUid(this.contentConfig.formUUID || this.objectUUID).then(res => {
        if (res.data.code == 200) {
          this.formDesignData = res.data.data;
          this.loading = false;
          dataInterface({
            __method_name__: 'dataInfo',
            data_id: this.company_info_id,
            object_uuid: this.objectUUID,
            view_uuid: this.viewUUID
          }).then(res => {
            this.showData = res.data.data;
          });
        }
      });
    },
    /**
     * @description: 提交按钮点击，切换disable状态，看情况触发表单提交行为
     */
    btnClick() {
      if (this.formDisabled) {
        this.formDisabled = false;
        const eventList = this.element.actionConfig?.edit?.eventList || [];
        if (eventList && eventList.length) {
          eventList.forEach((el) => {
            el.eventList.forEach(ele => {
              if (ele.key === 'click') {
                ele.effects.forEach((effect) => {
                  this.$store.commit('triggerEvents', {
                    config: {
                      ...ele,
                    ...effect
                    },
                    element: this.element,
                    EDITOR_pageUUID: this.EDITOR_pageUUID
                  });
                });
                // 触发组件行为
                const { behaviors } = ele;
                  behaviors.forEach(behavior => {
                    this.$store.commit('triggerEvents', {
                      config: {
                        behavior,
                        isBehavior: true
                      },
                      element: this.element,
                      EDITOR_pageUUID: this.EDITOR_pageUUID
                    });
                  });
              }
            })
          });
        }		
      } else {
        this.$refs.dialogForm.handleSubmit();
      }
    }
  },
  mounted() {
    this.findCompanyInfoId(this.getNowCompanyId());
  }
};
</script>

<style lang="less" scoped>
.common-config-page {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  .action-btn {
    float: right;
    position: sticky;
    top: 8px;
    right: 20px;
    z-index: 210;
  }
}
:deep(.common-config-page) {
  .type-label {
    border-left: 3px solid var(--themeColor)!important;
    padding: 0 10px;
    line-height: 1;
    font-size: 14px;
    margin: 0 0 16px;
    font-weight: 600;
    text-align: left;
  }
}
</style>
