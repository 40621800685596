/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-21 17:24:37
 * @FilePath: \dataview-viewer-test\src\views\appEntry\lang\en.js
 */
export default {
  "components.Cooperation.059629-0": "Not Specified",
  "components.Cooperation.059629-1": "Construction Unit",
  "components.Cooperation.059629-2": "Survey and Design Unit",
  "components.Cooperation.059629-3": "General Contractor",
  "components.Cooperation.059629-4": "Consultancy/Supervision Unit",
  "components.Cooperation.059629-5": "BIM Consultancy Unit",
  "components.NavHistory.022115-0": "Close Others",
  "components.NavHistory.022115-1": "Close Left",
  "components.NavHistory.022115-2": "Close Right",
  "components.NavHistory.022115-3": "Close All"
}