import i18n from '@/locale/index'
/* eslint-disable */
import {formValidate, getSubmitData} from "@/custom-component/form/newParser/scripts/itemDataCopy";
import {calcImpl} from "@/plugins/calcRuleEngine";
import {formRequest} from "@/apis/data/form";
import {getSearchData, getRequestData, optionData, formatValue} from "./tools";
import {flowEngine} from '@/apis/data/workflow'

export default {
  validateFormInput() {
    for (const index in this.$refs.childParser) {
      this.$refs.childParser[index].validateFormInput()
    }
    this.$refs.formParser.validate((valid) => {
      if (!valid) {
        throw new Error(i18n.t('scripts.parserMethods.537613-0'))
      }
    })
  },
  // 初始触发交互事件（step,radio,select）默认值
  triggerEventCtrl(element, val) {
    const interact = element.config.__config__.interact;
    const effect = interact.effect;
    const condition = interact.condition;
    if (effect === 'showhide') {
      let trueData = condition.filter(item => {
        if (typeof item.condV === 'string' && item.condV.indexOf(',')) {
          let arr = item.condV.split(',');
          return arr.includes(val) || arr.includes(val.toString())
        }
        return item.condV == val
      })
      let compList = Array.from(condition, item => item.comp).flat();
      let compShowHide = {};
      for (let item of compList) {
        compShowHide[item] = false;
      }
      if (trueData.length) {
        for (let eleItem of trueData) {
          let compShow = eleItem.comp;
          for (let item of compShow) {
            compShowHide[item] = true;
          }
        }
      }
      this.triggerActive([compShowHide])
    }
  },
  onRefresh() {
    this.$emit('on-close');
  },
  flowDesign(param) {
    flowEngine.designShow(param).then((res) => {
      this.flowEngineId = res.data.data.id
      if (this.initCanvasData !== undefined) {
        this.initCanvasData = res.data.data.canvas_data
      }
    })
  },
  // 选择退回步骤
  getSelectStep(nodeId) {
    this.selectStep = nodeId
  },
  // 流程人员
  dealWithPicker(selected, total) {
    // 实时验证选人bug，先选人，后验证，验证通过后需要再次选人bug
    if (
      (selected.target_users && selected.target_users.length) ||
      (selected.send_users && selected.send_users.length)
    ) {
      this.selectedUsers = selected
    }
    this.checkPickerNum = total
  },
  // 填充数据匹配
  fillMatchData() {
    let reduceData = {};
    const fillRules = this.formData.fillMatch || '';
    if (fillRules && fillRules?.manual) {
      const manualRules = fillRules.manual;
      const selectRules = fillRules.select;
      for (let item of manualRules) {
        reduceData[item.label] = item.value
      }
      for (let item of selectRules) {
        for (let ele of item.codeMatch) {
          if (ele.label && ele.value)
            reduceData[ele.label] = ele.value
        }
      }
    }
    return reduceData
  },
  // 子表下拉选择依赖关联数据ID过滤
  async dynamicSelectData(conf, relateFormId, relateId) {
    const eleConfig = conf.config;
    if (
      conf.type === 'select' &&
      eleConfig.__config__.dataType === 'dynamic' &&
      eleConfig.__config__.openFilter
    ) {
      let searchData = []; //过滤条件
      const formIdField = this.getFormIdField();
      const formSubmitData = this.getFormData();
      for (let item of eleConfig.__config__.filterRule) {
        let itemData = {ruleType: item.ruleType};
        if (item.paramType === 2) {
          itemData.field_uuid = item.field_uuid;
        } else {
          itemData.code = item.code;
        }
        if (item.valueType === 2) {
          const fieldKey = formIdField[item.value] || ''
          itemData.value = formSubmitData[fieldKey] || ''
          if (item.value === relateFormId) {
            itemData.value = relateId
          }
        } else {
          itemData.value = item.value
        }
        searchData.push(itemData)
      }
      let url, params, options = [];
      url = '/api/mapi'
      params = {
        __method_name__: 'dataList',
        object_uuid: eleConfig.__config__.objectUuid,
        view_uuid: eleConfig.__config__.viewUuid,
        size: 1000,
        __now_archi_type: this.nowArchiType,
        // transcode: 0,
      }
      if (searchData.length) params.search = searchData
      let response = await formRequest('post', url, params);
      let userData;
      if (response.data.data.data) {
        userData = response.data.data.data
      } else {
        userData = response.data.data
      }
      let label = eleConfig.props.props.label;
      let value = eleConfig.props.props.value;
      if (userData instanceof Array) {
        for (let element of userData) {
          if (element[label] && element[value]) {
            let item = {label: element[label], value: element[value], allField: element};
            options.push(item);
          }
        }
      }
      return options
    }
    return false;
  },
  /**
   * 事件交互处理 -- 显示隐藏（需配合控件可见性配置）
   * 1.主表控件控制主表控件 2.主表控制子表控件（布局内控件）
   * 3.子表控件控制子表控件 4.子表控件控制主表控件
   * @param params 控件显示隐藏
   */
  triggerActive(params) {
    const compShowHide = params[0]; // 受控控件
    const ctrlType = params[1] ?? ''// 交互类型 - 默认显示隐藏
    const respData = params[2] ?? '' // 级联数据
    this.getFormIdData(); // 交互事件--依赖表单数据
    if (ctrlType === 'cascader') {
      // 级联数据交互
      for (let item of this.formValidate.items) {
        let formId = item.config.__config__.formId;
        for (let val of compShowHide) {
          if (val === formId && item.type === 'position') {
            // 修改部位级联参数值
            item.config.__config__.cascade = respData
          }
          if (val === formId) {
            if (item.type === 'treeSelect') {
              const tag = item.config.__config__.tagIcon;
              const propLabel = item.config.props.props.label;
              const propValue = item.config.props.props.value;
              const propChild = item.config.props.props.children || [];
              const options = optionData(respData, tag, propLabel, propValue, propChild)
              if (options) {
                item.config.options = options
              }
            } else if (['select', 'cascader'].includes(item.type)) {
              item.config.__slot__.options = respData;
            }
          }
        }
      }
    } else if (ctrlType === 'selectFill') {
      // 下拉选择数据填充
      this.afterSelectFill(respData)
    } else if (ctrlType === 'positionModel') {
      this.positionModel(respData)
    } else {
      // 显示隐藏交互
      const ctrlFormId = Object.keys(compShowHide);
      for (let item of this.formValidate.items) {
        const formId = item.config.__config__.formId;
        if (ctrlFormId.includes(formId)) {
          item.config.__config__.addShow = compShowHide[formId]
        }
        if (item.type === 'row') {
          for (let eleItem of item.value) {
            const childFormId = eleItem.config.__config__.formId;
            if (ctrlFormId.includes(childFormId)) {
              eleItem.config.__config__.addShow = compShowHide[childFormId]
            }
          }
        }
      }
    }
  },
  // 部位改变（部位绑定模型）联动模型
  positionModel(model) {
    for (const element of this.formValidate.items) {
      if (element.type === 'bimList') {
        let arr = []
        if (model.length != 0) {
          for (let i = 0; i < model.length; i++) {
            arr = arr.concat(model[i])
          }
          this.$nextTick(() => {
            element.value = arr
          })
        } else {
          this.$nextTick(() => {
            element.value = []
          })
        }
      }
    }
  },
  // 下拉选择填充数据
  afterSelectFill(fillData) {
    const fillMatch = this.fillMatchData();
    const fillMatchKeys = Object.keys(fillMatch)
    const fillDataKeys = Object.keys(fillData)
    for (let item of this.formValidate.items) {
      if (item.config.__config__.display === 'table-relate') {

      } else if (item.type === 'form' || item.type === 'tab') {
        for (let element of item.value) {
          for (let dd of element) {
            if (fillMatchKeys.length > 0 && fillDataKeys.length > 0) {
              const formId = dd.config?.__config__.formId;
              if (fillMatchKeys.includes(formId)) {
                const matchVal = fillMatch[formId]
                if (fillDataKeys.includes(matchVal)) {
                  dd.value = fillData[matchVal]
                }
              }
            }
          }
        }
      } else if (item.type === 'table') {
        for (let rows of item.config.widget.rows) {
          for (let row of rows.cols) {
            for (let eleItem of row.widgetList) {
              if (fillMatchKeys.length > 0 && fillDataKeys.length > 0) {
                const formId = eleItem.config.__config__.formId;
                if (fillMatchKeys.includes(formId)) {
                  const matchVal = fillMatch[formId]
                  if (fillDataKeys.includes(matchVal)) {
                    eleItem.value = fillData[matchVal]
                  }
                }
              }
            }
          }
        }
      }
      const bool = (item.value instanceof Array || item.value instanceof Object)
      if (fillMatchKeys.length > 0 && fillDataKeys.length > 0 && !bool) {
        const formId = item.config.__config__.formId;
        if (fillMatchKeys.includes(formId)) {
          const matchVal = fillMatch[formId]
          if (matchVal.indexOf('.')) {
            // 多层级数据填充
            let arr = matchVal.split('.')
            if (fillDataKeys.includes(arr[0])) {
              const goal = fillData[arr[0]]
              if (goal instanceof Array && goal.length > 0) {
                let goalKeys = Object.keys(goal[0]);
                if (goalKeys.includes(arr[1])) {
                  item.value = goal[0][arr[1]]
                }
              } else if (goal instanceof Object) {
                let goalKeys = Object.keys(goal);
                if (goalKeys.includes(arr[1])) {
                  item.value = goal[0][arr[1]]
                }
              }
            }
          }
          if (fillDataKeys.includes(matchVal)) {
            item.value = fillData[matchVal]
          }
        }
      } else {
        // 之前没做上传组件返回事件？先用之前代码赋值
        if (item.type === 'upload') {
          const formId = item.config.__config__.formId;
          if (fillMatchKeys.includes(formId)) {
            const matchVal = fillMatch[formId]
            if (matchVal.indexOf('.')) {
              // 多层级数据填充
              let arr = matchVal.split('.')
              if (fillDataKeys.includes(arr[0])) {
                const goal = fillData[arr[0]]
                if (goal instanceof Array && goal.length > 0) {
                  let goalKeys = Object.keys(goal[0]);
                  if (goalKeys.includes(arr[1])) {
                    item.value = goal[0][arr[1]]
                  }
                } else if (goal instanceof Object) {
                  let goalKeys = Object.keys(goal);
                  if (goalKeys.includes(arr[1])) {
                    item.value = goal[0][arr[1]]
                  }
                }
              }
            }
            if (fillDataKeys.includes(matchVal)) {
              item.value = fillData[matchVal]
            }
          }
        }
      }
    }
  },
  //初始化表单验证数据
  async getFormValidate(fields) {
    const fillMatch = this.fillMatchData(); // 获取填充规则
    let fillData = Object.assign(this.fillData, this.currentInfo); //附加填充数据（当前登录数据）
    const tempFillData = sessionStorage.getItem("_formFilleData");
    if (tempFillData) {
      fillData = Object.assign(fillData, JSON.parse(tempFillData));
    }
    const data = formValidate(fields, this.displayData, fillMatch, fillData);
    this.formValidate.items = this.formValidate.items.concat(data);
    this.getFormIdData();
    this.$emit('changeSkeleton', false)
    this.skeleton = false;
  },
  // 下拉选择后自动填充关联数据
  async selectAutoFillRelate(selectFormId) {
    for (let item of this.formValidate.items) {
      const eleConf = item.config.__config__;
      if (item.type === "form" && eleConf.display === 'table-relate') {
        const autoFill = eleConf.relateConfig.__config__.autoFill || '';
        // 下拉选择时，不依赖此控件值时不请求填充子表数据，子表数据置空bug
        const filterRule = eleConf.relateConfig.__config__.filterRule;
        const hasFilter = Array.from(filterRule, item => item.value)
        if (!hasFilter.includes(selectFormId)) return;
        /***************************子表数据置空bug End************************/
        // Object.keys(this.displayData) === 0 有详情数据时不再自动加载关联数据
        if (autoFill) {
          let valueData = await this.autoFillRelateData(eleConf, this.formValidate.items, item.field)
          valueData.length ? item.value = valueData : '';
        }
      }
    }
  },
  // 自动填充关联子表数据
  async autoFillRelateData(eleConf, formData, field) {
    //关联数据不点击选择按钮，筛选条件自动填充
    const dataType = eleConf.relateConfig.__config__.dataType;
    const objectUuid = eleConf.relateConfig.__config__.objectUuid || '';
    const viewUuid = eleConf.relateConfig.__config__.viewUuid || '';
    const openFilter = eleConf.relateConfig.__config__.openFilter || false;
    const relateFilterRule = eleConf.relateConfig.__config__.filterRule || [];

    let searchData = []; //过滤条件
    if (openFilter) {
      for (let item of relateFilterRule) {
        let searchItem = {ruleType: item.ruleType, value: item.value};
        if (item.valueType === 2) {
          let formIdField = '';
          for (let dataItem of formData) {
            if (dataItem.config.__config__.formId === item.value) {
              if (['in', 'notin'].includes(item.ruleType) && !(item.value instanceof Array)) {
                searchItem.value = [dataItem.value]
              } else {
                searchItem.value = dataItem.value
              }
              formIdField = dataItem.field //关联数据依赖表单参数过滤
            }
          }
          // item.value = this.displayData[formIdField] || ''
        }
        if (item.code) searchItem.code = item.code
        if (item.field_uuid) searchItem.code = item.field_uuid
        searchData.push(searchItem)
      }
    }

    let url, params;
    if (objectUuid && viewUuid) {
      url = '/api/mapi'
      params = {
        __method_name__: 'dataList',
        object_uuid: objectUuid,
        view_uuid: viewUuid,
        search: searchData,
        __now_archi_type: this.nowArchiType,
        // transcode: 0,
      }
    } else if (dataType === 'interface') {
      url = '/api/form4/funcDataList'
      let param = {};
      let paramData = eleConf.relateConfig.__config__.params;
      for (let item of paramData) {
        param[item.name] = item.value
      }
      params = {
        function: eleConf.relateConfig.__config__.function,
        params: param,
        search: searchData, //通用搜索
      }
    }
    if (url && params) {
      let response = await formRequest('post', url, params);
      let responseData;
      if (response.data.data.data) {
        responseData = response.data.data.data
      } else {
        responseData = response.data.data
      }
      const metadata = response.data.metadata
      let idKey = ''; //查找id的键字段
      for (let key in metadata) {
        if (metadata[key] === 'id') {
          idKey = key;
          break;
        }
      }
      let rows = responseData
      const configData = eleConf.relateConfig.__slot__.options;
      let itemValue = [];
      if (rows instanceof Array && rows.length) {
        rows.forEach((itemC, index) => {
          let itemData = [];
          let fillData = {};
          for (let item of configData) {
            if (item.element) {
              let conf = JSON.parse(JSON.stringify(item.element))
              // 关联数回显 - 存在详情数据时
              const relateIdKey = eleConf.relateConfig.__vModel__;
              const recoverKeys = Object.keys(this.displayData);
              if (recoverKeys.includes(field)) {
                const cycleData = this.displayData[field]
                const goal = cycleData.filter(el => el[relateIdKey] == itemC[idKey])
                if (goal.length) {
                  conf.value = goal[0][conf.field]
                }
              }
              // conf.other = row
              itemData.push(conf)
            } else {
              let conf = JSON.parse(JSON.stringify(item))
              const confR = eleConf.relateConfig;
              conf.text = itemC[conf.value];
              conf.field = confR.__vModel__;
              fillData[idKey] = conf.text //关联数据项子表数据填充
              conf.value = itemC[idKey]
              itemData.push(conf)
            }
          }
          /******************子表数据填充start*****************/
          const fillMatch = this.fillMatchData();
          const fillMatchKeys = Object.keys(fillMatch)
          const fillDataKeys = Object.keys(fillData)
          for (let ele of itemData) {
            if (ele.config) {
              const formId = ele.config.__config__.formId;
              if (fillMatchKeys.includes(formId)) {
                const matchVal = fillMatch[formId]
                if (fillDataKeys.includes(matchVal)) {
                  ele.value = fillData[matchVal]
                }
              }
            }
          }
          /******************子表数据填充end*****************/
          itemValue.push(itemData)
        })
        return itemValue
      }
    }
    return [];
  },
  // 获取所有输入表单值
  getFormData() {
    // 采集表单输入数据
    let formSubmitData = getSubmitData(this.formValidate.items);
    // 流程附加人员数据（审批人，抄送人，退回选择步骤）
    if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
      formSubmitData.target_users = this.selectedUsers.target_users
    }
    if (this.selectedUsers.send_users && this.selectedUsers.send_users.length > 0) {
      formSubmitData.send_users = this.selectedUsers.send_users
    }
    if (this.selectStep) formSubmitData.select_step = this.selectStep
    if (this.flowEngineId) formSubmitData.flow_engine_id = this.flowEngineId
    if (this.newFlow) formSubmitData.new_flow = this.newFlow

    return formSubmitData;
  },
  // 获取表单ID对应输入值
  getFormIdData() {
    const formSubmitData = getSubmitData(this.formValidate.items, {}, {}, 'formId');
    return formSubmitData;
  },
  // 流程中编辑字段值
  editFieldsInput() {
    let formSubmitData = getSubmitData(this.formValidate.items);
    this.editFieldsValue = formSubmitData;
    return formSubmitData
  },
  // 获取formId与字段Field匹配值
  getFormIdField() {
    let formSubmitData = {};
    for (let ele of this.formValidate.items) {
      if (ele.type === "form") {
        let formId = ele.config.__config__.formId;
        if (ele.config.__config__?.relateConfig) {
          const relateConfig = ele.config.__config__.relateConfig
          let relateFormId = relateConfig.__config__.formId
          let relateField = relateConfig.__vModel__
          formSubmitData[relateFormId] = ele.config.__vModel__ + ";" + relateField;
        }
        for (let eleData of ele.value) {
          for (let eleItem of eleData) {
            if (eleItem.config) {
              let itemFormId = eleItem.config.__config__.formId;
              formSubmitData[itemFormId] = eleItem.field;
            }
          }
        }
        formSubmitData[formId] = ele.field;
      } else if (ele.type === "row") {
        if (ele.value) {
          for (let eleItem of ele.value) {
            let formId = ele.config.__config__.formId;
            formSubmitData[formId] = eleItem.field;
          }
        }
      } else {
        if (ele.field) {
          let formId = ele.config.__config__.formId;
          formSubmitData[formId] = ele.field;
        }
      }
    }
    return formSubmitData;
  },
  // 根据formId获取表单值
  getFieldValue(formId) {
    let formSubmitData = {}
    formSubmitData = this.getFormData();
    let keys = Object.keys(formSubmitData);
    const formIdField = this.getFormIdField()
    const newFormId = formIdField[formId] || ''
    const fieldValue = formSubmitData[newFormId] || 0
    let number = keys.includes(newFormId)
      ? (parseFloat(fieldValue) ? parseFloat(fieldValue) : 0)
      : 0;
    return number;
  },
  // 执行计算函数
  carryCalculate() {
    let formSubmitData = this.getFormData();
    let formIdData = this.getFormIdData();
    let formSubmitDataStr = JSON.stringify(formSubmitData)
    let formSubmitFormIdStr = JSON.stringify(formIdData)
    localStorage.setItem('TEMP_formData', formSubmitDataStr) // 字段UUID或控件UUID兼容
    localStorage.setItem('TEMP_formIdData', formSubmitFormIdStr) // 字段UUID或控件UUID兼容
    for (let item of this.formValidate.items) {
      if (item.type === 'input' && item.config.__config__.openRule) {
        const calcRules = item.config.__config__.calcRules
        let express = '';
        if (calcRules instanceof Array) {
          for (let ele of calcRules) {
            if (ele.ruleType === 'FUNCTION') {
              express += ele.funcName
            } else if (ele.ruleType === 'COMPONENT_VALUE') {
              express += `calcField('` + ele.value + `')`
            } else if (ele.ruleType === 'STATIC') {
              if (parseInt(ele.value)) {
                express += ele.value
              } else {
                express += `'` + ele.value + `'`
              }
            } else {
              express += ele.displayName
            }
          }
        } else {
          express = calcRules.express
        }
        try {
          let value = calcImpl(express)
          if (value && item.config?.precision) {
            value = parseFloat(value).toFixed(item.config.precision);
          }
          if (value) item.value = '' + value
        } catch (e) {
          console.error(e, i18n.t('scripts.parserMethods.537613-1'))
          this.$message.error(i18n.t('scripts.parserMethods.537613-2'));
          return
        }
      }
    }
  },
  handleReset() {
    this.$emit('on-cancel');
  },
  handleSubmit() {
    try {
      for (const index in this.$refs.childParser) {
        this.$refs.childParser[index].validateFormInput()
      }
    } catch (e) {
      this.$message.error(i18n.t('scripts.parserMethods.537613-3'))
      return
    }
    const targetUsers = this.selectedUsers?.target_users || []
    const sendUsers = this.selectedUsers?.send_users || []
    //流程审批人员判断提示
    if (
      this.needFlow &&
      this.checkPickerNum > 0 &&
      this.checkPickerNum !== targetUsers.length
    ) {
      this.$message.error(i18n.t('scripts.parserMethods.537613-4'))
      return false
    }
    this.$refs.formParser.validate((valid) => {
      if (valid) {
        let formSubmitData = getSubmitData(this.formValidate.items);
        // 流程附加人员数据（审批人，抄送人，退回选择步骤）
        if (targetUsers.length > 0) formSubmitData.target_users = targetUsers
        if (sendUsers.length > 0) formSubmitData.send_users = sendUsers
        if (this.selectStep) formSubmitData.select_step = this.selectStep
        if (this.flowEngineId) formSubmitData.flow_engine_id = this.flowEngineId
        if (this.newFlow) formSubmitData.new_flow = this.newFlow
        if (Object.keys(this.otherParam).length > 0) {
          formSubmitData = Object.assign(this.otherParam, formSubmitData)
        }
        this.formSubmitData = formSubmitData;
        console.log(i18n.t('scripts.parserMethods.537613-5'), formSubmitData)
        this.$emit("submit", formSubmitData);
      } else {
        this.formSubmitData = null;
        this.$message({
          showClose: true,
          message: i18n.t('scripts.parserMethods.537613-3'),
          type: "error",
        });
        this.$emit("closeLoading");
      }
    });
  },
  formParserValid() {
    return new Promise((resolve, reject) => {
      for (const index in this.$refs.childParser) {
        this.$refs.childParser[index].validateFormInput()
      }
      this.$refs.formParser.validate((valid) => {
        resolve(valid)
      })
    })
  },
  // 根据表单ID获取控件值 -- 只能获取主表字段值
  getFormIdValue(formId) {
    const formIdField = this.getFormIdField();
    const formSubmitData = this.getFormData();
    const fieldKey = formIdField[formId] || ''
    let fieldValue = ''
    if (fieldKey.includes(';')) {
      // 子表关联数据 - 作为过滤条件
      const k = fieldKey.split(';')
      const child = formSubmitData[k[0]]
      fieldValue = Array.from(child, t => t[k[1]])
    } else {
      fieldValue = formSubmitData[fieldKey] || ''
    }
    return fieldValue;
  },
  // 控件值改变请求依赖数据（数据筛选联动）
  async setDynamicData() {
    const dynamicFormId = this.getDynamicFilter(this.formData.fields);
    for (let item of this.formValidate.items) {
      let formId = item.config.__config__.formId;
      if (dynamicFormId.includes(formId)) {
        if (item.config.__config__.filterRule && item.config.__config__.filterRule instanceof Array) {
          let searchData = getSearchData(item.config.__config__.filterRule, this.getFormIdValue);
          let respData = await getRequestData(item.config.__config__.objectUuid, item.config.__config__.viewUuid, searchData)
          if (respData.length) {
            let tag = item.config.__config__.tagIcon;
            let propLabel = item.config.props.props.label;
            let propValue = item.config.props.props.value;
            let propChild = item.config.props.props.children || '';
            let options = optionData(respData, tag, propLabel, propValue, propChild)
            if (item.config.__slot__.options) {
              item.config.__slot__.options = options;
            }
          }
        }
      }
      if (item.value instanceof Array) {
        for (let itemValue of item.value) {
          for (let ele of itemValue) {
            let childFormId = ele.config.__config__.formId;
            if (dynamicFormId.includes(childFormId)) {
              if (ele.config.__config__.filterRule && ele.config.__config__.filterRule instanceof Array) {
                let searchData = getSearchData(ele.config.__config__.filterRule, this.getFormIdValue);
                let respData = await getRequestData(ele.config.__config__.objectUuid, ele.config.__config__.viewUuid, searchData)
                if (respData.length) {
                  let tag = ele.config.__config__.tagIcon;
                  let propLabel = ele.config.props.props.label;
                  let propValue = ele.config.props.props.value;
                  let propChild = ele.config.props.props.children || '';
                  let options = optionData(respData, tag, propLabel, propValue, propChild)
                  if (ele.config.__slot__.options) {
                    ele.config.__slot__.options = options;
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  // 获取动态筛选条件
  getDynamicFilter(formData, goalData = []) {
    for (let item of formData) {
      if (['database', 'select', 'treeSelect'].indexOf(item.__config__.tagIcon) > -1) {
        if (item.__config__.filterRule && item.__config__.filterRule instanceof Array) {
          goalData.push(item.__config__.formId)
        }
      }
      if (item.__config__.children && item.__config__.children.length > 0) {
        this.getDynamicFilter(item.__config__.children, goalData)
      }
    }
    return goalData;
  },
  hidOperate() {
    this.operateDrawer = false
  }
}
