/*
 * @Author: qinmengyuan
 * @Date: 2024-08-27 10:03:07
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-27 10:03:34
 * @FilePath: \dataview-viewer-test\src\views\todo\lang\index.js
 */
import zh from "./zh";
import en from "./en";

export default {
  zh,
  en,
};
