<template>
    <div v-show="show">
        <ul class="vertical-flex moduleUl">
            <template>
                <li v-for="(item, index) in moduleList" :key="index"
                    :class="['vertical-flex moduleLi', {'moduleLi_active': activeModule === item.id}]"
                    @click="onSelectModule(item)"
                >
                    <a :class="['iconfont', item.icon]"></a>
                    <span>{{item.name}}</span>
                </li>
            </template>
        </ul>

        <div class="modulePanel" v-show="activeModule!='none'">
            <ul class="tabUl">
                    <li v-for="(item, key) in tabList[activeModule]" :key="key"
                        :class="['vertical-flex tabLi', {'tabLi_active': activeTab === item.id}]"
                    >
                        <a :class="['iconfont', item.icon]"></a>
                        <span>{{item.name}}</span>
                    </li>
                </ul>

                <viewerBIMModel
                        ref="modelList"
                        v-show="activeModule==='model'"
                        class="moduleWrap"
                        :element.sync="element"
                        :bindViewerId="bindViewerId"
                        :bindDataMainId="bindDataMainId"
                        @registerBehaviors="registerBehaviors"
                ></viewerBIMModel>


                <viewerBIMScene
                        ref="scenes"
                        v-show="activeModule==='scene'"
                        class="moduleWrap"
                        :element.sync="element"
                        :bindViewerId="bindViewerId"
                        :bindDataMainId="bindDataMainId"
                        @registerBehaviors="registerBehaviors"
                ></viewerBIMScene>
        </div>
    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import BehaviorHandlerMixin from '@/custom-component/mixins/BehaviorHandlerMixin.js';
    // import { dataInterface } from '@/apis/data/index';
    import viewerBIMModel from "./components/viewerBIMModel";
    import viewerBIMScene from "./components/viewerBIMScene";
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';

    export default {
        name: "viewerBIMModule",
        components: {
            viewerBIMModel,
            viewerBIMScene
        },
        data(){
            return{
                show : true,
                moduleList: [
                    {id: 'model', name: '模型', icon: 'icongis_moxingshu'},
                    {id: 'scene', name: '场景', icon: 'icongis_changjing'},
                ],
                activeModule: 'model',
                bindViewerId : null, //绑定viewer的ID
                bindDataMainId : '',

                tabList : {
                    scene : [
                        {id: 'viewPoint', name: '视点', icon: 'iconbim_shidianliebiao'}
                    ],
                    model : [
                        {id: 'modelTree', name: '模型', icon: 'icongis_tuceng'}
                    ]
                },
                activeTab: 'modelTree',
                /* eslint-disable-next-line */
                _EventBus : {},
            }
        },
        computed: {
            ...mapState(['componentData', 'originComponentData']),
        },
        props :{
                element: {
                    type: Object,
                    required: true,
                    default: () => {
                        return {
                        }
                    }
                },
                // 是否在组合内
                isGroup: {
                    type: Boolean,
                },
                // 组合内组件列表
                groupComponents: {
                    type: Array,
                    default: () => []
                }
            },
        mixins : [CustomComponentMixin,BehaviorHandlerMixin],
        methods: {
            initEventListener(){

                this._EventBus = {
                    "Viewer.onViewerInited" : (  )=>{
                        this.refreshDataMainId();
                    },
                    "BIMModule.GetSource" :  ( eid ,  cb )=>{
                        if( eid !== this.element.id)return;

                        const loop = (array = [], resList = []) => {
                            for (let item of array) {
                                if (item.component !== 'ViewerBIM') {
                                    if (item.children && item.children.length > 0)
                                        loop(item.children, resList);
                                    continue;
                                }

                                if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                                resList.push({
                                    id: item.id,
                                    name: item.name
                                });
                            }

                            return resList;
                        };

                        const comps = [];
                        loop(this.subComponentData, comps);
                        // loop(this.subComponentData, comps);

                        cb({
                            list : comps,
                            bindId : this.bindViewerId,
                        });
                    },
                    "BIMModule.BindViewer" :  ( eid , bindId )=>{
                        if( eid !== this.element.id) return;
                        if( !this.checkBind(bindId) ) return;

                        this.element.modelBindId = bindId;
                        this.bindViewerId = bindId;
                        this.refreshDataMainId();
                        this.$message.success(`[${this.element.name}] 绑定成功`);
                    }
                }

                for( let id in this._EventBus){
                    eventBus.$on(id , this._EventBus[id]);
                }
            },
            onSelectModule(item){
                if(this.activeModule == item.id){
                    this.activeModule = 'none'
                }else{
                    this.activeModule = item.id;
                    this.activeTab = this.tabList[item.id][0].id
                }
            },
            checkBind( bindId = this.bindViewerId ){
                const checkFunc = ( bid , list )=>{
                    let hasComp = false;
                    if(!list) return hasComp;

                    for(let comp of list){
                        if( comp.children ){
                            const flag = checkFunc( bid , comp.children);

                            if(flag){
                                hasComp = true;
                                break
                            }
                        }
                        if( comp.id !== bid ) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp
                };

                const hasComp = checkFunc(bindId , this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp)
                    return this.$message.error(
                        `[${this.element.name}] 绑定失效，组件可能已经被删除`
                    );

                return hasComp;
            },
            initBehaviors(){
                this.element.behaviors = [];
                this.registerBehaviors([
                    {
                        label : "[基础]切换显示",
                        desc : `
                            调用后就会切换显示和隐藏
                        `,
                        value : "BIMModule-switchDisplay",
                        func : ()=>{
                            this.show = !this.show;
                        },
                    },
                ]);

                for(let eid in this.$refs){
                    const ele = this.$refs[eid];
                    if(ele.initBehaviors){
                        ele.initBehaviors();
                    }
                }
            },
            refreshDataMainId(){
                if (!this.bindViewerId) return false;
                eventBus.$emit('ViewerBIM.getDataMainId', this.bindViewerId , ( id ) =>{
                    this.bindDataMainId = id;

                    this.$nextTick(()=>{
                        for(let cid in this.$refs){
                            const comp = this.$refs[cid];
                            if(!comp || !comp.onChangeDataMainId) continue;

                            comp.onChangeDataMainId( id );
                        }
                    });
                });
            },
        },
        mounted(){
            this.initEventListener();
            this.initBehaviors();

            if(this.element.modelBindId){
                if( this.checkBind( this.element.modelBindId) ){
                    this.bindViewerId = this.element.modelBindId;
                }
            }

        },
        destroyed(){
            for( let id in this._EventBus){
                eventBus.$off(id , this._EventBus[id]);
            }
        },
};
</script>

<style lang="less" scoped>
.vertical-flex {
  display: inline-flex;
  flex-direction: column;
  line-height: initial;
  vertical-align: middle;
}

.moduleUl{
    float: right;
    width: 48px;
    height: 100%;
    box-sizing: border-box;
    border-left: 1px solid rgba(255,255,255,0.3);
    background-color: inherit;
    color: inherit;

    .moduleLi{
        width: 48px;
        height: 56px;
    }

    .moduleLi:hover{
        color: var(--themeColor);
    }

    .moduleLi_active{
        color: var(--themeColor);
    }

    .moduleLi a{
        padding-top: 10px;
        font-size: 18px;
        color: inherit;
    }

    .moduleLi span{
        padding-bottom: 10px;
        font-size: 14px;
        color: inherit;
    }
}

.modulePanel{
    float: left;
    padding: 0 !important;
    overflow: hidden;
    width: calc(100% -  48px);
    height: 100%;
    background-color: inherit;
    color: inherit;

    .tabUl{
        width: 100%;
        height: 56px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        .tabLi{
            float: left;
            width: 70px;
            height: 56px;
        }

        .tabLi_active{
            background-color: #8d8d8d;
        }

        .tabLi a{
            padding-top: 10px;
            font-size: 18px;
            color: inherit;
        }

        .tabLi span{
            padding-bottom: 10px;
            font-size: 14px;
            color: inherit;
        }
    }

    .moduleWrap{
        width: 100%;
        height: calc(100% - 56px);
    }
}
</style>
