/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-06-21 14:10:53
 * @FilePath: \dataview-viewer-test\src\custom-component\functionCom\lang\en.js
 */
export default {  
  "functionCom.WeatherComNew.411871-0": "humidity:",
  "functionCom.WeatherComNew.411871-1": "wind speed:",
  "functionCom.LiveCom.207042-0": "No content available!",
  "functionCom.LiveCom.207042-1": "Video loading",
  "functionCom.LiveCom.207042-2": "Missing video link",
  "functionCom.LiveCom.207042-3": "Parameter mandatory verification failed",
  "functionCom.LiveCom.207042-4": "Non current component or non updated",
  "functionCom.LiveCom.207042-5": "Configuration error!",
  "functionCom.LiveCom.207042-6": "Request configuration error",
  "functionCom.LiveCom.207042-7": "No cover"
}