/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-13 17:26:12
 * @FilePath: \dataview-next\src\custom-component\button\lang\en.js
 */
export default {
  "button.DropDownButton.627052-0": "delete",
  "button.DropDownButton.627052-1": "Are you sure you want to delete?",
  "button.DropDownButton.627052-2": "Prompt",
  "button.DropDownButton.627052-3": "OK",
  "button.DropDownButton.627052-4": "Cancel"
}