/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-13 17:22:05
 * @FilePath: \dataview-next\src\custom-component\button\lang\zh.js
 */

export default {
  "button.DropDownButton.627052-0": "删除",
  "button.DropDownButton.627052-1": "是否确认删除?",
  "button.DropDownButton.627052-2": "提示",
  "button.DropDownButton.627052-3": "确定",
  "button.DropDownButton.627052-4": "取消"
}


