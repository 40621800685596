<template>
    <div class="moduleWrap">
        <div class="load-wrap">
            <div class="title-wrap">
                <span>图层</span>

                <el-tooltip content="添加分组" placement="bottom" v-if="isCreateFile">
                    <a class="iconfont iconxinzengfenlei" @click="onClickFile"></a>
                </el-tooltip>
            </div>

            <el-tree ref="layerTree"
                     v-loading="isLoading"
                     class="layerTree-wrap"
                     empty-text=""
                     :data="layerList"
                     :props="treeProps"
                     node-key="id"
                     :expand-on-click-node="false"
                     :default-expand-all="true"
                     :highlight-current="true"
                     :check-on-click-node="false"
                     :check-strictly="false"
                     :show-checkbox="true"
                     @current-change="onCurrentChange"
                     @check=onClickNode
            >
                <template #default="{ node, data }">
                    <div :class="['horizontal-flex tree-node']">
                        <a :class="['tree-node-icon iconfont', getIconByType(data)]" @click="onClickIcon(data)" @dblclick="onDblClickNode(data)"></a>
                        <span class="tree-node-content" :title="data.name" @dblclick="onDblClickNode(data)">{{ data.name}}</span>
<!--                        <a v-if="data.type!=='module'&& data.module!=='mapData'" class="iconfont icongis_dingwei tree-node-location" @click="onClickIcon(data)"></a>-->
                        <el-dropdown
                                trigger="click"
                                hide-on-click
                                class="tree-node-more"
                                v-if="data.type!=='module' && data.module!=='model'"
                                @command="onClickCommand($event, data)"
                        >
                            <a class="iconfont icongengduomore"></a>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="location">定位</el-dropdown-item>
                                <el-dropdown-item v-if="data.tag==='CustomMap'&&!data.isSave" command="save">保存</el-dropdown-item>
                                <el-dropdown-item command="rename">重命名</el-dropdown-item>
                                <el-dropdown-item command="delete">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-tree>

            <el-empty v-if="layerList.length === 0" description="什么都没有" class="empty-wrap"></el-empty>
        </div>
    </div>
</template>

<script>
    import {Empty, Tree, Tooltip, Dropdown, DropdownMenu, DropdownItem} from 'element-ui';

    export default {
        name: "LayerSingle",
        components: {
            'el-empty': Empty,
            'el-tree': Tree,
            'el-tooltip': Tooltip,
            'el-dropdown': Dropdown,
            'el-dropdown-menu': DropdownMenu,
            'el-dropdown-item': DropdownItem,
        },
        data() {
            return {
                isLoading: false,
                isCreateFile: false,
                layerList: [
                    {id: 'mapData', name: '底图数据', type: 'module', module: 'mapData', tag: 'mapData', children: [
                            {id: 'imagery', key: 'imagery', name: '影像底图', type: 'leaf', module: 'mapData', tag: '', pId: 'mapData', group_id: -1, isBase: true}
                        ]},
                    {id: 'gis', key: 'gis', name: 'GIS数据', type: 'module', module: 'gis', tag: 'gis', children: []},
                    {id: 'model', key: 'model', name: '模型数据', type: 'module', module: 'model', tag: 'model', children: []}
                ],
                treeProps: {
                    label: 'name',
                    children: 'children',
                    isLeaf: "isLeaf"
                },
                currentNode: null
            }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            bindViewerId: {
                type: String,
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            getIconByType(row){
                if(row.type === 'module'){
                    switch (row.id) {
                        case 'mapData':
                            return 'icontupian';
                        case 'gis':
                            return 'iconGIS';
                        case 'model':
                            return 'iconbiaogerongqi';
                    }
                }else if(row.type === 'file'){
                    return 'tree-node-icon-file icona-wenjianjiadakai';
                }else{
                    switch (row.tag) {
                        case 'TianDiTu':
                            return 'iconquanjing1';
                        case 'AMapStreets':
                            return 'iconquanjing1';
                        case 'ArcGIS':
                            return 'iconquanjing1';
                        case 'MapBox':
                            return 'iconquanjing1';
                        case 'CustomMap':
                            return 'iconquanjing1';
                        case 'IonWorldTerrain':
                            return 'icongis_dixing';

                        case 'pinIcon':
                            return 'icongis_dingwei';
                        case 'pinSolid':
                            return 'icongis_dingwei';
                        case 'wavePointIcon':
                            return 'icongis_dingwei';
                        case 'pointIcon':
                            return 'icongis_dingwei';

                        case 'icon':
                            return 'iconbimgis_biaoji';
                        case 'blackMarker':
                            return 'iconbimgis_biaoji';
                        case 'greenGradient':
                            return 'iconbimgis_biaoji';
                        case 'blueGradient':
                            return 'iconbimgis_biaoji';
                        case 'textMarker':
                            return 'iconbimgis_biaoji';

                        case 'polyline':
                            return 'iconxiantiao';
                        case 'curve':
                            return 'iconquxian1';
                        case 'rectangle':
                            return 'iconjuxing1';
                        case 'circle':
                            return 'iconyuanxing';
                        case 'polygon':
                            return 'iconduobianxing';

                        case 'kml':
                            return 'icongis_kml';

                        case 'singleBox':
                            return 'icongis_dantihua';
                        case 'scanLine':
                            return 'iconbim_jiaojuchangdu';

                        case 'bim':
                            return 'iconBIM1';
                        case 'osgb':
                            return 'iconosgb';
                        case 'gltf':
                            return 'icongltf';
                        default:
                            return 'icongis_biaohui';
                    }
                }
            },
            getNodeLeaf(row, list = []){
              if(row.type === 'module' || row.type === 'file'){
                  row.children.map((item) => {
                      this.getNodeLeaf(item, list);
                  })
              }else if(row.type === 'leaf'){
                  list.push(row);
                  return list;
              }
            },
            getNode(key){
                const el = this.$refs.layerTree;

                let node = el.getNode(key);
                if (!node) return false;

                return node;
            },
            getCheckedNodes(module){
                const el = this.$refs.layerTree;

                let nodes = el.getCheckedNodes();
                let keys = el.getCheckedKeys();

                if(!module) return {nodes, keys};

                let list = [];
                let keyList = [];
                for (let node of nodes){
                    if(node.module === module){
                        list.push(node);
                        keyList.push(node.id);
                    }
                }

                return {
                    nodes: list,
                    keys: keyList
                }
            },

            setIsLoading(isShow = false) {
                this.isLoading = isShow;
            },
            setTreeNodeCheck(keys, isChecked = true) {
                const el = this.$refs.layerTree;
                for (let item of keys) {
                    let node = el.getNode(item);
                    if (!node) continue;

                    el.setChecked(item, isChecked, true);
                }
            },
            setTreeNodeProps(key, props) {
                const el = this.$refs.layerTree;
                let node = el.getNode(key);
                if (!node) return;

                if (!props) return;
                for (let key in props) {
                    if (typeof node.data[key] === "undefined") {
                        this.$set(node.data, key, props[key]);
                    } else {
                        node.data[key] = props[key];
                    }
                }
            },

            appendTreeData(data, pId = null, isChecked = false){
                const el = this.$refs.layerTree;
                let node = el.getNode(data.id);

                if (node) return false;
                el.append(data, pId === -1 ? null : pId);

                if(isChecked) {
                    this.$nextTick(() => {
                        this.setTreeNodeCheck([data.id]);
                    });
                }
                return true;
            },
            updateTreeData(id, name, value){
                const el = this.$refs.layerTree;
                let node = el.getNode(id);
                if (!node) return false;

                node.data[name] = value;
                return true;
            },
            deleteTreeData(id){
                const el = this.$refs.layerTree;
                let node = el.getNode(id);
                if (!node) return false;

                el.remove(id);
                return true;
            },
            emptyTreeData(){
                let keys = [];
                for (let module of this.layerList){
                    module.children.map((item) => {
                        if(item.type === 'file') {
                            keys.push(item.id);
                        }else if (item.type === 'leaf'){
                            if(item.module === 'gis'){
                                keys.push(item.id);
                            }
                        }
                    })
                }

                const el = this.$refs.layerTree;
                for(let id of keys){
                    let node = el.getNode(id);
                    if (!node) continue;

                    el.remove(id);
                }
            },

            onClickFile(){
                let row = this.currentNode ? this.currentNode.data : null;
                if(!row) return this.$message.success(`请选择树节点！`);

                if(row.type === 'leaf'){
                    let parent = row.parent;
                    this.$emit('createFile', parent);
                }else{
                    this.$emit('createFile', this.currentNode);
                }
            },
            onClickCommand(command, data){
                switch (command) {
                    case 'location':
                        this.onClickIcon(data);
                        break;
                    case 'save':
                        this.$emit('saveRow', data);
                        break;
                    case 'rename':
                        this.$emit('renameRow', data);
                        break;
                    case 'delete':
                        this.$emit('deleteRow', data);
                        break;
                }
            },

            onCurrentChange(row, node){
                this.currentNode = node;

                if(row.module === 'gis') {
                    this.isCreateFile = true;
                }else{
                    this.isCreateFile = false;
                }

                this.$emit('clickCurrent', node);
            },
            onClickIcon(data){
                this.$emit('locationNode', data);
            },
            onDblClickNode(data){
                this.setTreeNodeCheck([data.id], true);
                this.onClickNode(data);
                this.onClickIcon(data);
            },
            onClickNode(data){
                const el = this.$refs.layerTree;
                const node = el.getNode(data.id);
                const isChecked = node.checked;

                let leafList = [];
                this.getNodeLeaf(data, leafList);
                this.$emit('checkNode', data, leafList, isChecked);
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree-node__content>label.el-checkbox) {
            position: absolute !important;
            right: 0;
            margin-right: 13px !important;
            color: inherit;
        }

        :deep(.el-checkbox__inner) {
            background-color: transparent !important;
            border: none;
            height: 35px;
            color: inherit;
        }

        :deep(.el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e942";  //隐藏
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e941"; //显示
            color: var(--themeColor);
            font-size: 16px;
            background-color: transparent !important;
            transform: none !important;
            /*transition: none !important;*/
        }

        :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner::before) {
            font-family: "iconfont";
            content: "\e942";  //隐藏
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
            background-color: transparent !important;
        }
    }

    .load-wrap {
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
    }

    .title-wrap {
        width: 100%;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .title-wrap span {
        float: left;
        padding-left: 10px;
        font-size: 14px;
        color: inherit;
    }

    .title-wrap a {
        float: right;
        padding-right: 10px;
        font-size: 18px;
        color: inherit;
    }

    .layerTree-wrap {
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);
        user-select: none;
    }

    .tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 80%;
        height: 35px;
        line-height: 35px;
        padding-right: 33px;
    }

    .tree-node-icon {
        margin-right: 3px;
        margin-left: -5px;
        margin-top: 2px;
        width: 20px;
    }

    .tree-node-icon-file {
        color: #6DD3FD;
    }

    .tree-node-content {
        margin-right: 5px;
        text-align: left;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tree-node-more{
        width: 20px;
        color: inherit;
    }

    .tree-node-more:hover {
        color: var(--themeColor) !important;
    }

    .tree-node-location {
        margin-right: 5px;
        width: 20px;
        color: inherit;
    }

    .tree-node-location:hover {
        color: var(--themeColor) !important;
    }
</style>
