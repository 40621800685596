/*
 * @Author: hw
 * @Date: 2023-12-11 16:23:18
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-05-09 18:21:47
 * @FilePath: \dataview-viewer-test\src\components\todoList\lang\en.js
 */
export default {
  "todoList.Aside.523548-0": "search",
  "calendar.Header.008155-0": "Year",
  "calendar.Header.008155-1": "Month",
  "main.Header.362249-0": "Responsible Person",
  "main.Header.362249-1": "Department",
  "main.Header.362249-2": "Start Time",
  "main.Header.362249-3": "Please select",
  "main.Header.362249-4": "End Time",
  "main.Header.362249-5": "Filter",
  "main.Header.362249-6": "Clear",
  "main.Header.362249-7": "Default",
  "main.Header.362249-8": "Priority",
  "main.Header.362249-9": "Deadline",
  "main.Header.362249-10": "Creation Date",
  "main.Header.362249-11": "List",
  "main.Header.362249-12": "Day",
  "main.Header.362249-13": "Week",
  "main.Header.362249-14": "Month",
  "main.Header.362249-15": "All",
  "main.Header.362249-16": "Security Management",
  "main.Header.362249-17": "Log",
  "main.Header.362249-18": "Quality Inspection",
  "main.Header.362249-19": "Task",
  "main.Header.362249-20": "Incomplete",
  "main.Header.362249-21": "Completed",
  "main.Header.362249-22": "Filter",
  "main.Header.362249-23": "Reset",
  "ListView.index.595688-0": "CC",
  "ListView.index.595688-1": "No content available!",
  "ListView.index.595688-2": "Completed",
  "ListView.index.595688-3": "Rejected",
  "ListView.index.595688-4": "Void",
  "ListView.index.595688-5": "Returned",
  "ListView.index.595688-6": "Initiate",
  "ListView.index.595688-7": "Under Review",
  "ListView.index.595688-8": "Me",
  "ListView.index.595688-9": "Overdue",
  "ListView.index.595688-99": "{0} days Overdue",
  "ListView.index.595688-10": "days",
  "ListView.index.595688-11": "As of {0} today",
  "ListView.index.595688-12": "{0} deadline",
  "ListView.index.595688-13": "Due {0}",
  "ListView.index.595688-14": "Due {0}",
  "ListView.index.595688-15": "As of {0} today",
  "ListView.index.595688-16": "Yesterday {0}",
  "datePicker.Index.826859-0": "Year",
  "datePicker.Index.826859-1": "Month",
  "datePicker.Index.826859-2": "{2} {1}, {0}",
  "datePicker.Index.826859-3": "Month {0}",
  "datePicker.Index.826859-4": "Week {2} of {1}, {0}",
  "datePicker.Index.826859-5": "{1}, {0}",
  "datePicker.Index.826859-6": "Back to Today",
  "datePicker.Index.826859-7": "Back to This Week",
  "datePicker.Index.826859-8": "Back to This Month",
  "datePicker.Index.826859-9": "Back to Current",
  "dayView.AllDay.217772-0": "All Day",
  "weekView.Index.395484-0": "All Day",
  "weekView.Index.395484-1": "Mon",
  "weekView.Index.395484-2": "Tue",
  "weekView.Index.395484-3": "Wed",
  "weekView.Index.395484-4": "Thu",
  "weekView.Index.395484-5": "Fri",
  "weekView.Index.395484-6": "Sat",
  "weekView.Index.395484-7": "Sun",
  "weekView.Index.947093-0": "January",
  "weekView.Index.947093-1": "February",
  "weekView.Index.947093-2": "March",
  "weekView.Index.947093-3": "April",
  "weekView.Index.947093-4": "May",
  "weekView.Index.947093-5": "June",
  "weekView.Index.947093-6": "July",
  "weekView.Index.947093-7": "August",
  "weekView.Index.947093-8": "September",
  "weekView.Index.947093-9": "October",
  "weekView.Index.947093-10": "November",
  "weekView.Index.947093-11": "December",
  "weekView.Index.947093-12": "{0} Month",
  "monthView.Index.152266-0": "No Subject",
  "monthView.Index.152266-1": "Remaining",
  "monthView.Index.152266-2": "Items",
  "monthView.Index.152266-3": "Monday",
  "monthView.Index.152266-4": "Tuesday",
  "monthView.Index.152266-5": "Wednesday",
  "monthView.Index.152266-6": "Thursday",
  "monthView.Index.152266-7": "Friday",
  "monthView.Index.152266-8": "Saturday",
  "monthView.Index.152266-9": "Sunday",
  "taskList.index.979107-0": "Schedule List",
  "taskList.index.979107-1": "Details",
  "taskList.index.979107-2": "\"{0}\", This task does not belong to the current organizational structure. Please switch organizational structure to view!",
  "taskList.index.979107-3": "Read carbon copy",
  "taskList.TaskList.305558-0": "Me",
  "taskList.TaskList.305558-1": "Overdue",
  "taskList.TaskList.305558-2": "Days",
  "taskList.TaskList.305558-3": "Due Today {0}",
  "taskList.TaskList.305558-4": "Due Tomorrow {0}",
  "taskList.TaskList.305558-5": "Due {0}",
  "taskList.TaskList.305558-6": "Due {0}",
  "taskList.TaskList.305558-7": "Today {0}",
  "taskList.TaskList.305558-8": "Yesterday {0}"
}