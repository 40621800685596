<!--
 * @Description: 重命名
 * @Author: luocheng
 * @Date: 2022-03-02 15:50:16
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-08 19:08:42
-->
<template>
  <div class="create-placeholder-wrap" v-if="value">
    <el-dialog
      :title="title"
      :visible.sync="value"
      :show-close="false"
      :before-close="handleClose"
      append-to-body
      width="400px">
      <el-input v-model="name" :placeholder="$t('components.CreatePlaceholder.193460-0')" clearable></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">{{ $t('components.CreatePlaceholder.193460-1') }}</el-button>
        <el-button type="primary" @click="onConfirm">{{ $t('components.CreatePlaceholder.193460-2') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CreatePlaceholder',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    fileName: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'Rename'
    }
  },
  data() {
    return {
      name: ''
    }
  },
  watch: {
    value: {
      handler() {
        this.name = this.fileName
      }
    }
  },
  computed: {
    title() {
      return this.type === 'createPlaceholder' ? this.$t('components.CreatePlaceholder.193460-3') : this.$t('components.CreatePlaceholder.193460-4');
    }
  },
  created() {
    this.name = this.fileName
  },
  methods: {
    handleClose(done) {
      this.onClose();
      done();
    },
    onClose() {
      this.name = '';
      this.$emit('input', false)
    },
    onConfirm() {
      if(this.name && this.name.trim()) {
        this.$emit('nameResult', this.name.trim());
      } else {
        this.$message.info(this.$t('components.CreatePlaceholder.193460-0'));
      }
    }
  },
}
</script>

<style lang="less" scoped>
.create-placeholder-wrap{
  :deep(.el-dialog){
    .el-dialog__header{
      text-align: left;
      border-bottom: 1px solid #f5f5f5;
      .el-dialog__title{
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
</style>