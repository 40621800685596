<!--
 * @Description: 筛选时间组
 * @Author: luocheng
 * @Date: 2022-03-28 11:44:06
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-14 22:34:37
-->
<template>
  <div class="common-date-filter" :class="{
    'transparent-bg': statusConfig.transparent
  }">
    <el-radio-group v-model="quickType" @change="onQuick">
      <el-radio-button v-if="shortcutsVisible('day')" label="day">{{ $t('form.CommonDateFilter.730870-0') }}</el-radio-button>
      <el-radio-button v-if="shortcutsVisible('threeDays')" label="threeDays">{{ $t('form.CommonDateFilter.730870-1') }}</el-radio-button>
      <el-radio-button v-if="shortcutsVisible('week')" label="week">{{ $t('form.CommonDateFilter.730870-2') }}</el-radio-button>
      <el-radio-button v-if="shortcutsVisible('month')" label="month">{{ $t('form.CommonDateFilter.730870-3') }}</el-radio-button>
      <el-radio-button v-if="shortcutsVisible('year')" label="year">{{ $t('form.CommonDateFilter.730870-4') }}</el-radio-button>
    </el-radio-group>
    <el-date-picker
      v-model="dateValue"
      type="daterange"
      range-separator="-"
      @change="onChange"
      :value-format="statusConfig.valueFormat || 'yyyy-MM-dd'"
      :start-placeholder="$t('form.CommonDateFilter.730870-5')"
      :end-placeholder="$t('form.CommonDateFilter.730870-6')">
    </el-date-picker>
  </div>
</template>

<script>
import { DatePicker, RadioButton, RadioGroup } from 'element-ui';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'CommonDateFilter',
  components: {
    'el-date-picker': DatePicker,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      quickType: 'week',
      // 时间选择结果
      dateValue: ''
    }
  },
  computed: {
    statusConfig() {
      return this.element?.statusConfig || {};
    },
    hideBorder() {
      return this.element?.hideBorder;
    },
    hideIcon() {
      return this.element?.hideIcon;
    },
    selectHeight() {
      return this.element?.selectHeight;
    }
  },
  created() {
    const keys = Array.isArray(this.statusConfig.shortcuts) && this.statusConfig.shortcuts.length ? this.statusConfig.shortcuts : ['week', 'month', 'year'];
    switch(true) {
      case keys.includes('day'): {
        this.quickType = 'day';
        break;
      }
      case keys.includes('threeDays'): {
        this.quickType = 'threeDays';
        break;
      }
      case keys.includes('week'): {
        this.quickType = 'week';
        break;
      }
      case keys.includes('month'): {
        this.quickType = 'month';
        break;
      }
      case keys.includes('year'): {
        this.quickType = 'year';
        break;
      }
    }
    // 默认快捷方式
    if(this.statusConfig && this.statusConfig.defaultShortcut) {
      this.quickType = this.statusConfig.defaultShortcut;
    }
    this.onQuick();
  },
  methods: {
    /**
     * @description: 查询快捷按钮是否可见
     * @param {*} key
     * @return {*}
     */    
    shortcutsVisible(key) {
      if(Array.isArray(this.statusConfig.shortcuts) && this.statusConfig.shortcuts.length) {
        return this.statusConfig.shortcuts.includes(key);
      }
      return ['week', 'month', 'year'].includes(key);
    },
    /**
     * @desc: 选择时间
     */
    onChange(val) {
      this.quickType = '';
      let result = val;
      // 暴露数据
      const { valueFormat = 'yyyy-MM-dd' } = this.statusConfig;
      try {
        let endTime = result[1] || '';
        if (valueFormat.includes('HH:mm') || valueFormat.includes('H:m')) {
          const times = endTime.toString().split(' ');
          let timeEnd = times[1];
          if (valueFormat === 'yyyy-MM-dd HH:mm' || valueFormat === 'yyyy-M-d H:m') {
            timeEnd = '23:59'
          } else if (valueFormat === 'yyyy-MM-dd HH:mm:ss' || valueFormat === 'yyyy-M-d H:m:s') {
            timeEnd = '23:59:59'
          }
          endTime =  `${times[0]} ${timeEnd}`;
        }
        result.splice(1, 1, endTime);
      } catch (e) {
        console.log(e);
      }
			this.element.resolveData ={
        date: result || []
      };
      this.onSearch();
    },
    /**
     * @desc: 快捷选择
     */
    onQuick() {
      if (!this.quickType) return;
      const today = (new Date()).getTime();
      let startDate = '';
      if(this.quickType === 'day') {
        // 一天
        startDate = today - 1 * 24 * 60 * 60 * 1000;
      } else if(this.quickType === 'threeDays') {
        // 三天
        startDate = today - 3 * 24 * 60 * 60 * 1000;
      } else if (this.quickType === 'week') {
        // 周
        startDate = today - 7 * 24 * 60 * 60 * 1000;
      } else if (this.quickType === 'month') {
        // 月
        const { monthDay = 31 } = this.statusConfig;
        startDate = today - (+monthDay - 1) * 24 * 60 * 60 * 1000;
      } else if (this.quickType === 'year') {
        // 年
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        startDate = new Date(`${year - 1}-${month}-${day} 00:00:00`);
      }
      if (today && startDate) {
        this.dateValue = [this.formatVal(startDate), this.formatVal(today)];
      } else {
        this.dateValue = '';
      }
      // 暴露数据
			this.element.resolveData = {
        date: this.dateValue
      };
      this.onSearch();
    },
    /**
     * @desc: 搜索
     */
    onSearch() {
      eventBus.$emit('databaseTrigger', {
        componentId: this.element.id,
        action: 'any',
        output: this.dateValue,
        isInit: false,
        noUpdate: false
      });
    },
    /**
     * @desc: 格式化时间
     * @param {Number} value 时间戳
     */
    formatVal(value) {
      if (!value || isNaN(value)) return '';
			let dateObj = '';
			if (value instanceof Date) {
				dateObj = value;
			} else if (isNaN(value)) {
				dateObj = new Date(value);
			} else {
				dateObj = new Date(value);
			}
			if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
      const { connector = '-', valueFormat = 'yyyy-MM-dd' } = this.statusConfig;
      if (valueFormat === 'timestamp') {
        return dateObj.getTime();
      }
			const year = dateObj.getFullYear();
			// 无效的时间
			if (year === 1970) return '';
			let month = dateObj.getMonth() + 1;
			const day = dateObj.getDate();
			const hour = dateObj.getHours();
			const minute = dateObj.getMinutes();
			const second = dateObj.getSeconds();
			let resultStr = `${year}-${this.addZero(month)}-${this.addZero(day)}`;
			switch (valueFormat) {
        case 'yyyy-M-d':
          resultStr = `${year}-${month}-${day}`;
          break;
        case 'yyyy-MM-dd HH:mm':
          resultStr = `${resultStr} ${this.addZero(hour)}:${this.addZero(minute)}`;
          break;
        case 'yyyy-M-d H:m':
          resultStr = `${year}-${month}-${day} ${hour}:${minute}`
          break;
        case 'yyyy-MM-dd HH:mm:ss':
          resultStr = `${resultStr} ${this.addZero(hour)}:${this.addZero(minute)}:${this.addZero(second)}`;
          break;
        case 'yyyy-M-d H:m:s':
          resultStr = `${year}-${month}-${day} ${hour}:${minute}:${second}`
          break;
        default:
          break;
			}
      return resultStr.replace(/-/g, connector);
    },
    /**
		 * @desc: 补零
		 * @param {Number} val 需要补零的值
		 */
		addZero(val) {
			if (isNaN(val) || val < 0 || val >= 10) return val;
			return `0${val}`;
		},
  }
}
</script>

<style lang="less" scoped>
.common-date-filter{
  width: 100%;
  display: flex;
  .el-radio-group{
    margin-right: 8px;
  }
  :deep(.el-date-editor) {
    padding: 3px 5px;
    .el-range-input{
      width: 90px!important;
    }
    .el-range-separator{
      padding: 0;
    }
  }
  .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: 240px;
  }
  &.transparent-bg{
    :deep(.el-date-editor,.el-range-editor, .el-input__inner, .el-date-editor--monthrange, .el-range-editor--small) {
      background: transparent;
      color: #fff;
      .el-range-input{
        color: #fff;
        background: transparent;
      }
      &::-webkit-input-placeholder{
          color:#fff;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
          color:#fff;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
          color:#fff;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
          color:#fff;
      }
      .el-range-separator{
        color: #fff;
        font-weight: bold;
      }
      .el-input__prefix,.el-input__suffix, i{
        color: #fff;
      }
    }
    :deep(.el-radio-group) {
      .el-radio-button__inner{
        background: transparent;
        color: #fff;
        overflow: hidden;
      }
      .is-active{
        .el-radio-button__inner{
          background: #409EFF!important;
        }
      }
    }
  }
}
</style>