<!--
    @name: widget-password
    @description：widget-password
    @author: ZengWei
    @date: 2022-03-25 09:29
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ element.value ? element.value : '-' }}</div>
    <el-input
      v-else
      v-model="element.value"
      type="password"
      :placeholder="$getLocalizedLabel({'zhLabel': element.config.zhPlaceholder, 'enLabel': element?.config.enPlaceholder})"
      :disabled="disabled || element.config.disabled"
    ></el-input>
  </div>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetPassword",
  components: {},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.password-show {
  width: 100%;
  padding: 5px 12px;
  box-sizing: border-box;
  background: #F2F4F7;
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--systemFontFamily);
  color: #2A2F3D;
  line-height: 22px;
  cursor: default;
}
</style>
