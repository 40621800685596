<template>
    <div class="moduleWrap">
        <div class="load-wrap">
            <div class="title-wrap">
                <el-dropdown v-if="viewFilter.length>1" trigger="click"  hide-on-click  class="title-wrap_select" @command="onViewChange">
                    <div class="horizontal-flex select-wrap-title">
                        <div class="select-wrap-title_span">{{getSelectViewName()}}</div>
                        <a class="select-wrap-title_a iconfont iconditushaixuanjiantou_1"></a>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in viewFilter" :key="index" :command="index">
                            <div class="horizontal-flex select-wrap-content">
                                <a v-if="viewFilterIndexPop === index" class="select-wrap-content_a iconfont iconpage-correct-16"></a>
                                <div class="select-wrap-content_span">{{item.name}}</div>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div class="title-wrap_select" v-if="viewFilter.length===1" v-text="viewFilter[0].name"></div>

                <el-tooltip content="添加分组" placement="bottom">
                    <a class="title-wrap_btn iconfont iconbim_jia" style="margin-right: 10px" @click="onAddGroup"></a>
                </el-tooltip>
            </div>

            <div class="tree-wrap">
                    <el-collapse v-loading="isLoading" :element-loading-background="loadingBackColor" v-model="expandNodes">
                        <el-collapse-item v-for="item in viewPointList" :key="item.id" :name="item.id">
                            <template #title>
                                <div class="horizontal-flex tree-group-wrap">
                                    <div class="tree-group-wrap__title">{{ item.name }}</div>
                                    <a class="tree-group-wrap__add iconfont iconxinzeng2" @click.stop="onAddViewPoint(item)"></a>
                                    <el-dropdown
                                            trigger="click"
                                            hide-on-click
                                            class="tree-group-wrap__btns"
                                            @command="onClickCommand($event, item)"
                                    >
                                        <a class="iconfont icongengduomore" @click.stop></a>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="renameGroup">重命名</el-dropdown-item>
                                            <el-dropdown-item command="deleteGroup">删除组</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </template>

                            <div v-for="child in item.children" :key="child.id" class="horizontal-flex tree-obj-wrap">
                                <img class="tree-obj-wrap__img" :src="child.img" @click="onApplyViewPoint(child)"/>
                                <div class="vertical-flex tree-obj-wrap__content">
                                    <span class="tree-obj-wrap__title">{{ child.name }}</span>

                                    <div class="horizontal-flex">
                                        <div class="tree-obj-wrap__tip">{{ child.created_at.substring(0, 10) }}</div>
                                        <el-tooltip content="更新视点" placement="bottom">
                                            <a class="tree-obj-wrap__btns iconfont iconshuaxin1" @click="onClickCommand('refresh', child)"></a>
                                        </el-tooltip>
                                        <el-dropdown trigger="click" hide-on-click class="tree-obj-wrap__more" @command="onClickCommand($event, child)">
                                            <a class="iconfont icongengduomore" @click.stop></a>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item command="view">查看视点</el-dropdown-item>
                                                <el-dropdown-item command="rename">重命名视点</el-dropdown-item>
                                                <el-dropdown-item command="delete">删除视点</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                </div>
                                <el-popconfirm v-show="useDefaultView" :title="getConfirmTitle(child.isDefault)"
                                               confirm-button-text="是"
                                               cancel-button-text="否"
                                               :class="[child.isDefault ? 'tree-obj-wrap__defaultSelect': 'tree-obj-wrap__default']"
                                               @confirm="onClickCommand('default', child)">
                                    <template #reference>
                                        <a :class="['iconfont iconmoren tree-obj-wrap__defaultIcon']" @click.stop></a>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
            </div>

            <el-empty v-if="viewPointList.length === 0" description="什么都没有" class="empty-wrap"></el-empty>
        </div>
    </div>
</template>

<script>
    import { Empty, Tooltip, Dropdown, DropdownMenu, DropdownItem, Collapse, CollapseItem, Popconfirm } from 'element-ui';

    export default {
        name: "ViewPointSingle",
        components: {
            'el-empty': Empty,
            'el-tooltip': Tooltip,
            'el-dropdown': Dropdown,
            'el-dropdown-menu': DropdownMenu,
            'el-dropdown-item': DropdownItem,
            'el-collapse': Collapse,
            'el-collapse-item': CollapseItem,
            'el-popconfirm': Popconfirm
        },
        data(){
            return {
                isLoading: false,
                loadingBackColor: 'rgba(0, 0, 0, 0.1)',
                expandNodes: [],

                viewFilterIndexPop: this.viewFilterIndex,

                viewPointList: []
            }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            },
            bindViewerId: {
                type: String,
            },
            bindViewerType: {
                type: String,
                default: 'GIS'
            },
            viewFilter: {
                type: Array,
                default: () => []
            },
            viewFilterIndex: {
                type: Number,
                default: 0
            },
            useDefaultView : {
                type : Boolean,
                default : true,
            }
        },
        methods: {
            /**
             * @description: 获取视图下拉弹出框名称信息
             */
            getSelectViewName(){
              return this.viewFilter[this.viewFilterIndexPop].name;
            },
            /**
             * @description: 获取下拉弹出框名称信息
             * @param {String} isDefault 是否是默认视点
             */
            getConfirmTitle(isDefault){
                if(isDefault){
                    return '是否取消默认视点？';
                }else{
                    return '是否设为默认视点？';
                }
            },
            /**
             * @description: 获取设为默认的对象
             */
            getDefaultItem(){
                for (let item of this.viewPointList){
                    if(!item.children || item.children.length === 0) continue;

                    for (let obj of item.children){
                        if(obj.isDefault) return obj;
                    }
                }

                return false;
            },

            /**
             * @description: 设置是否Loading
             * @param {Boolean} isShow 是否显示隐藏
             */
            setIsLoading(isShow = false) {
                this.isLoading = isShow;
            },
            /**
             * @description: 设置默认展开第一项
             */
            setDefaultExpand(){
                this.expandNodes = [];
                if(this.viewPointList.length === 0) return;

                this.expandNodes.push(this.viewPointList[0].id);
            },

            /**
             * @description: 添加树节点对象
             * @param {String} data 数据对象
             * @param {String} pId 数据对象父节点Id
             */
            appendTreeData(data, pId = null){
                if(!pId){
                    this.viewPointList.push(data);
                }else{
                    let index = this.viewPointList.findIndex(x => x.id === pId);
                    if(index !== -1){
                        this.viewPointList[index].children.push(data);
                    }
                }
            },
            /**
             * @description: 更新树节点对象
             * @param {String} id 数据对象id
             * @param {String} name 修改键名称
             * @param {String} value 修改键值
             * @param {String} pId 数据对象父节点id
             */
            updateTreeData(id, name, value, pId = null){
                if(!pId){
                    let index = this.viewPointList.findIndex(x => x.id === id);
                    if(index !== -1){
                        this.viewPointList[index][name] = value;
                    }
                }else{
                    let groupIndex = this.viewPointList.findIndex(x => x.id === pId);
                    if(groupIndex !== -1){
                        let index = this.viewPointList[groupIndex].children.findIndex(x => x.id === id);
                        if(index !== -1){
                            this.viewPointList[groupIndex].children[index][name] = value;
                        }
                    }
                }
            },
            /**
             * @description: 删除树节点对象
             * @param {String} id 数据对象id
             * @param {String} pId 数据对象父节点id
             */
            deleteTreeData(id, pId = null){
                if(!pId){
                    let index = this.viewPointList.findIndex(x => x.id === id);
                    if(index !== -1){
                        this.viewPointList.splice(index, 1);
                    }
                }else{
                    let groupIndex = this.viewPointList.findIndex(x => x.id === pId);
                    if(groupIndex !== -1){
                        let index = this.viewPointList[groupIndex].children.findIndex(x => x.id === id);
                        if(index !== -1){
                            this.viewPointList[groupIndex].children.splice(index, 1);
                        }
                    }
                }
            },
            /**
             * @description: 清空树节点对象
             */
            emptyTreeData(){
                this.viewPointList = [];
            },

            /**
             * @description: 数据视图修改
             * param {Number} type 视图下拉框选中索引值
             */
            onViewChange(type){
                this.viewFilterIndexPop = type;
                this.$emit('dataChange', this.viewFilterIndexPop);
            },
            /**
             * @description: 添加分组
             */
            onAddGroup(){
                this.$emit('addGroup');
            },
            /**
             * @description: 添加视点
             * @param {Object} data 分组数据对象
             */
            onAddViewPoint(data){
                this.$emit('addViewPoint', data);
            },

            /**
             * @description: 视点命令点击事件
             * @param {String} command 命令类型
             * @param {Object} data 数据对象
             */
            onClickCommand(command, data){
                switch (command) {
                    case 'renameGroup':
                        this.$emit('renameGroup', data);
                        break;
                    case 'deleteGroup':
                        this.$emit('deleteGroup', data);
                        break;
                    case 'default':
                        if(data.isDefault){
                            this.$emit('undefaultViewPoint', data);
                        }else{
                            this.$emit('defaultViewPoint', data);
                        }
                        break;
                    case 'view':
                        this.$emit('setViewPoint', data);
                        break;
                    case 'rename':
                        this.$emit('renameViewPoint', data);
                        break;
                    case 'refresh':
                        this.$emit('refreshViewPoint', data);
                        break;
                    case 'delete':
                        this.$emit('deleteViewPoint', data);
                        break;
                }
            },
            /**
             * @description: 复现视点
             * @param {Object} data 数据对象
             */
            onApplyViewPoint(data){
                this.$emit('setViewPoint', data);
            }
        }
    }
</script>

<style lang="less" scoped>
    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .vertical-flex {
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .load-wrap {
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
    }

    .title-wrap {
        width: 100%;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .title-wrap_content {
        float: left;
        padding-left: 10px;
        font-size: 14px;
        color: inherit;
    }

    .title-wrap_select {
        float: left;
        margin-left: 10px;
        color: inherit;
    }

    .select-wrap-title{
        height: 38px;
        line-height: 38px;
        color: inherit;
    }

    .select-wrap-title_span{
        font-size: 14px;
        color: inherit;
    }

    .select-wrap-title_a{
        margin-top: 7px;
        margin-left: 10px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: inherit;
    }

    .select-wrap-content{
        height: 25px;
        line-height: 25px;
        color: inherit;
    }

    .select-wrap-content_a{
        margin-left: -20px;
        width: 20px;
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: inherit;
    }

    .select-wrap-content_span{
        font-size: 14px;
        color: inherit;
    }

    .title-wrap_btn {
        float: right;
        margin-top: 7px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: inherit;
        border-radius: 4px;
    }

    .title-wrap_btn:hover{
        background-color: rgba(0, 118, 255, 0.1);
    }

    .tree-wrap{
        position: absolute;
        top: 38px;
        width: 100%;
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);

        :deep(.el-collapse) {
            border-top: none !important;
            border-bottom: none !important;
        }

        :deep(.el-collapse-item__header) {
            flex: 1 0 auto;
            order: -1;
            padding-left: 10px;
            height: 40px;
            line-height: 40px;
            border-bottom: none;
            /*border-bottom: 1px solid rgba(255, 255, 255, 0.3);*/
            background-color: transparent;
            color: inherit;
        }

        :deep(.el-collapse-item__wrap) {
            border-bottom: none;
            /*border-bottom: 1px solid rgba(255, 255, 255, 0.3);*/
            background-color: transparent;
            color: inherit;
        }

        :deep(.el-collapse-item__content) {
            color: inherit;
        }

        :deep(.el-collapse-item__arrow){
            position: absolute;
            left: 0;
            width: 24px;
            height: 24px;
            line-height: 24px;
            color: inherit;
            font-size: 14px;
        }

        :deep(.el-icon-arrow-right:before){
            content: "\e791";
            color: inherit;
        }

        .tree-group-wrap {
            margin-left: 15px;
            width: 100%;
            height: 40px;
            line-height: 40px;
        }

        .tree-group-wrap__title {
            width: calc(100% - 58px);
            text-align: left;
        }

        .tree-group-wrap__add{
            margin-top: 8px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 18px;
            color: inherit;
        }

        .tree-group-wrap__btns {
            margin-top: 8px;
            margin-right: 10px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 18px;
            color: inherit;
        }

        .tree-group-wrap__btns:hover {
            color: var(--themeColor);
        }

        .tree-obj-wrap {
            width: 100%;
            height: 90px;
            color: inherit;
        }

        .tree-obj-wrap:hover {
            background-color: #C1C1C1;
        }

        .tree-obj-wrap:hover .tree-obj-wrap__default{
            display: block;
        }

        .tree-obj-wrap__img {
            margin-left: 10px;
            margin-top: 10px;
            width: 100px;
            height: 68px;
            background-color: #909399;
            object-fit: fill;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 6px;
        }

        .tree-obj-wrap__content {
            margin-left: 10px;
            width: 100%;
            color: inherit;
        }

        .tree-obj-wrap__title {
            padding-top: 12px;
            height: 45px;
            text-align: left;
            color: inherit;
            font-size: 14px;
        }

        .tree-obj-wrap__tip {
            width: calc(100% - 48px);
            height: 24px;
            line-height: 24px;
            text-align: left;
            color: #989898;
            font-size: 12px;
        }

        .tree-obj-wrap__btns {
            float: right;
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 18px;
            color: inherit;
        }

        .tree-obj-wrap__more {
            float: right;
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 18px;
            color: inherit;
        }

        .tree-obj-wrap__btns:hover {
            color: #0076ff;
        }

        .tree-obj-wrap__default{
            display: none;
            height: 36px;
            margin-top: 10px;
            margin-left: -36px;
            margin-right: 10px;
            font-size: 36px;
            transform: rotate(90deg);
            color: #666666;
        }

        .tree-obj-wrap__defaultSelect{
            display: block;
            height: 36px;
            margin-top: 10px;
            margin-left: -36px;
            margin-right: 10px;
            font-size: 36px;
            transform: rotate(90deg);
            color: red;
        }

        .tree-obj-wrap__defaultIcon{
            font-size: inherit;
            color: inherit;
        }
    }

    .empty-wrap {
        position: absolute;
        top: 38px;
        width: 100%;
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);

        :deep(.el-empty__description p) {
            color: inherit !important;
        }
    }
</style>
