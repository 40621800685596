/*
 * @Author: hw
 * @Date: 2024-03-07 11:33:32
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-05-09 18:21:40
 * @FilePath: \dataview-viewer-test\src\components\todoList\lang\zh.js
 */

export default {
  "todoList.Aside.523548-0": "输入关键字搜索",
  "calendar.Header.008155-0": "年",
  "calendar.Header.008155-1": "月",
  "main.Header.362249-0": "责任人",
  "main.Header.362249-1": "部门",
  "main.Header.362249-2": "开始时间",
  "main.Header.362249-3": "请选择",
  "main.Header.362249-4": "结束时间",
  "main.Header.362249-5": "筛 选",
  "main.Header.362249-6": "清 空",
  "main.Header.362249-7": "默认",
  "main.Header.362249-8": "优先级",
  "main.Header.362249-9": "截止日期",
  "main.Header.362249-10": "创建日期",
  "main.Header.362249-11": "列表",
  "main.Header.362249-12": "日",
  "main.Header.362249-13": "周",
  "main.Header.362249-14": "月",
  "main.Header.362249-15": "全部",
  "main.Header.362249-16": "安全管理",
  "main.Header.362249-17": "日志",
  "main.Header.362249-18": "质量巡检",
  "main.Header.362249-19": "任务",
  "main.Header.362249-20": "未完成",
  "main.Header.362249-21": "已完成",
  "main.Header.362249-22": "筛选",
  "main.Header.362249-23": "重置",
  "ListView.index.595688-0": "抄送",
  "ListView.index.595688-1": "暂无内容!",
  "ListView.index.595688-2": "完成",
  "ListView.index.595688-3": "拒绝",
  "ListView.index.595688-4": "作废",
  "ListView.index.595688-5": "退回",
  "ListView.index.595688-6": "发起",
  "ListView.index.595688-7": "审核中",
  "ListView.index.595688-8": "我",
  "ListView.index.595688-9": "逾期",
  "ListView.index.595688-99": "逾期{0}天",
  "ListView.index.595688-10": "天",
  "ListView.index.595688-11": "今天{0}截止",
  "ListView.index.595688-12": "{0}截止",
  "ListView.index.595688-13": "{0}截止",
  "ListView.index.595688-14": "{0}截止",
  "ListView.index.595688-15": "今天{0}截止",
  "ListView.index.595688-16": "昨天{0}",
  "datePicker.Index.826859-0": "年$",
  "datePicker.Index.826859-1": "月$",
  "datePicker.Index.826859-2": "{0}年{1}月{2}日",
  "datePicker.Index.826859-3": "月 第$",
  "datePicker.Index.826859-4": "{0}年{1}月 第{2}周",
  "datePicker.Index.826859-5": "{0}年{1}月",
  "datePicker.Index.826859-6": "回到今天",
  "datePicker.Index.826859-7": "回到本周",
  "datePicker.Index.826859-8": "回到本月",
  "datePicker.Index.826859-9": "回到当前",
  "dayView.AllDay.217772-0": "全天",
  "weekView.Index.395484-0": "全天",
  "weekView.Index.395484-1": "周一",
  "weekView.Index.395484-2": "周二",
  "weekView.Index.395484-3": "周三",
  "weekView.Index.395484-4": "周四",
  "weekView.Index.395484-5": "周五",
  "weekView.Index.395484-6": "周六",
  "weekView.Index.395484-7": "周日",
  "weekView.Index.947093-0": "一",
  "weekView.Index.947093-1": "二",
  "weekView.Index.947093-2": "三",
  "weekView.Index.947093-3": "四",
  "weekView.Index.947093-4": "五",
  "weekView.Index.947093-5": "六",
  "weekView.Index.947093-6": "七",
  "weekView.Index.947093-7": "八",
  "weekView.Index.947093-8": "九",
  "weekView.Index.947093-9": "十",
  "weekView.Index.947093-10": "十一",
  "weekView.Index.947093-11": "十二",
  "weekView.Index.947093-12": "{0}月",
  "monthView.Index.152266-0": "无主题",
  "monthView.Index.152266-1": "还有",
  "monthView.Index.152266-2": "项",
  "monthView.Index.152266-3": "周一",
  "monthView.Index.152266-4": "周二",
  "monthView.Index.152266-5": "周三",
  "monthView.Index.152266-6": "周四",
  "monthView.Index.152266-7": "周五",
  "monthView.Index.152266-8": "周六",
  "monthView.Index.152266-9": "周日",
  "taskList.index.979107-0": "日程列表",
  "taskList.index.979107-1": "详情",
  "taskList.index.979107-2": "\"{0}\", 该待办不属于当前组织架构，请切换组织架构后查看！",
  "taskList.index.979107-3": "读抄送",
  "taskList.TaskList.305558-0": "我",
  "taskList.TaskList.305558-1": "逾期",
  "taskList.TaskList.305558-2": "天",
  "taskList.TaskList.305558-3": "今天{0}截止",
  "taskList.TaskList.305558-4": "明天{0}截止",
  "taskList.TaskList.305558-5": "{0}截止",
  "taskList.TaskList.305558-6": "{0}截止",
  "taskList.TaskList.305558-7": "今天{0}",
  "taskList.TaskList.305558-8": "昨天{0}"
}


